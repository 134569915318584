import React, { PureComponent } from 'react';
import './request-timeslot-dialog.scss';
import { connect } from '../../../../../..';
import Dialog from '../../../../../../common/modals/Dialog';
import Translate from '../../../../../../common/Translate';
import Form, { IFormRenderProps } from '../../../../../../common/forms/Form';
import SubmitButton from '../../../../../../common/buttons/SubmitButton';
import Loader from '../../../../../../common/waiting/Loader';
import { createFormTextInput } from '../../../../../../common/forms/FormTextInput';
import { schema, fields, FormValues } from './requestTimeslotSchema';
import FloatableTextInputWrapper from '../../../../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../../../../common/input/TextInput';
import TranslatorContext from '../../../../../../appShell/contexts/TranslatorContext';
import { ITranslator } from '../../../../../../../models/general/i18n';
import { IEmployeeDetails } from '../../../../../../../models/admin/employee';
import { formatPersonName } from '../../../../../../../utils/formatting/formatPerson';
import { getMyUserProfile } from '../../../../../../../redux/auth/selectors';
import { getSelectedEmployee } from '../../../../../../../redux/employee/info/selectors';
import { formatDateForDisplay } from '../../../../../../../utils/formatting/formatDate';
import MedicalCenterTypeahead from '../../../../../../administration/Employees/shared/MedicalCenterTypeahead';
import {
    getPlanMedicalExaminationWizardReason,
    getRequestMedicalExaminationAsyncInfo,
    getPlanMedicalExaminationWizardEntity,
} from '../../../../../../../redux/medicalExamination/selectors';
import {
    IExaminationReason,
    IRequestMedicalExaminationPayload,
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
} from '../../../../../../../models/interventions/medicalExaminations';
import { requestMedicalExaminationActions } from '../../../../../../../redux/medicalExamination/actions';
import { IAsyncFieldInfo } from '../../../../../../../models/general/redux';
import ErrorPlaceholder from '../../../../../../common/error/ErrorPlaceholder';
import { getCompanyMedicalCenters } from '../../../../../../../redux/company/info/selectors';
import { ICompanyMedicalCenter } from '../../../../../../../models/admin/companyInfo';
import Checkbox from '../../../../../../common/input/Checkbox';
import { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';

const CLASS_NAME = 'RequestTimeslotDialog';
const FORM_NAME = 'request-timeslot-form';
const TRANSLATION_PREFIX = 'interventions.medical_examinations.new.steps.select_timeslot.request_timeslot';
const FIELD_NAME_PREFIX = `${TRANSLATION_PREFIX}.form`;

interface IPublicProps {
    show: boolean;
    onClose: () => void;
}

interface IPrivateProps {
    selectedEmployee: IEmployeeDetails;
    nameOfUser: string;
    examinationReason: IExaminationReason;
    medicalCenterFromFilter: ICompanyMedicalCenter;
    resumptionDate?: string;
    requestMedExam: (payload: IRequestMedicalExaminationPayload) => void;
    resetAsyncInfo: () => void;
    asyncInfo: IAsyncFieldInfo;
}

interface IContextProps {
    translator: ITranslator;
}

type TProps = IPublicProps & IContextProps & IPrivateProps;

const FormTextInput = createFormTextInput<FormValues>();

class RequestTimeslotDialogComp extends PureComponent<TProps> {
    private formRenderProps: IFormRenderProps<FormValues>;

    constructor(props: TProps) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.getInitialValues = this.getInitialValues.bind(this);
        this.onToggleDay = this.onToggleDay.bind(this);
    }

    public componentDidUpdate(prevProps: TProps) {
        if (this.props.show && !prevProps.show && this.formRenderProps) {
            this.props.resetAsyncInfo();
            this.formRenderProps.resetForm(this.getInitialValues());
        }

        if (
            prevProps.asyncInfo.status === AsyncStatus.Busy &&
            this.props.asyncInfo.status === AsyncStatus.Success
        ) {
            this.props.onClose();
        }
    }

    private getInitialValues(): FormValues {
        return {
            contactPhoneNumber: '',
            medicalCenterId: this.props.medicalCenterFromFilter && this.props.medicalCenterFromFilter.id,
            medicalCenterName: this.props.medicalCenterFromFilter && this.props.medicalCenterFromFilter.name || '',
            planningPreference: '',
            preferredTime: '',
            resumptionDate: '',
            weekDays: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            },
        };
    }

    public render() {
        const { show, onClose, selectedEmployee, nameOfUser, examinationReason, asyncInfo } = this.props;

        return (
            <Dialog
                className={CLASS_NAME}
                show={show}
                onCloseIntent={onClose}
            >
                <h1><Translate msg={`${TRANSLATION_PREFIX}.title`} /></h1>
                <Form
                    name={FORM_NAME}
                    handleSubmit={this.onSubmit}
                    schema={schema}
                    initialValues={this.getInitialValues()}
                    render={(formRenderProps: IFormRenderProps<FormValues>) => {
                        const { errors, values, handleChange, setFieldValue } = formRenderProps;

                        this.formRenderProps = formRenderProps;

                        const weekDays = values.weekDays;

                        return (
                            <>
                                <Loader show={asyncInfo.status} />
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="request-timeslot-planning-preference"
                                        name={fields.planningPreference}
                                        value={values.planningPreference || ''}
                                        isInvalid={!!errors.planningPreference}
                                        onChange={handleChange}
                                        multiLine={true}
                                        disableMultilineResize={true}
                                        rows={3}
                                        disableAutoComplete={true}
                                    />
                                    <label htmlFor="request-timeslot-planning-preference">
                                        <Translate msg={`${FIELD_NAME_PREFIX}.planning_preference`} />
                                    </label>
                                </FloatableTextInputWrapper>
                                <div className={`${CLASS_NAME}__day-select`}>
                                    <label htmlFor="request-timeslot-select-days">
                                        <Translate msg={`${FIELD_NAME_PREFIX}.week_days.label`} />
                                    </label>
                                    <div className={`${CLASS_NAME}__day-select__days`}>
                                        {Object.keys(weekDays).map((key) => {
                                            return (
                                                <Checkbox
                                                    key={key}
                                                    name={`day-select-${key}`}
                                                    checked={values.weekDays[key]}
                                                    onChange={() => this.onToggleDay(key)}
                                                >
                                                    <Translate msg={`${FIELD_NAME_PREFIX}.week_days.${key}`} />
                                                </Checkbox>
                                            );
                                        })}
                                    </div>
                                </div>
                                <FormTextInput
                                    name="preferredTime"
                                    baseId={FORM_NAME}
                                    baseTranslationKey={FIELD_NAME_PREFIX}
                                    fields={fields}
                                    formRenderProps={formRenderProps}
                                />
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="request-timeslot-employee-name"
                                        name="request-timeslot-employee-name"
                                        value={selectedEmployee && formatPersonName(selectedEmployee) || '-'}
                                        onChange={() => null}
                                        disabled={true}
                                    />
                                    <label htmlFor="request-timeslot-employee-name">
                                        <Translate msg={`${FIELD_NAME_PREFIX}.employee_name`} />
                                    </label>
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="request-timeslot-employee-birth-date"
                                        name="request-timeslot-employee-birth-date"
                                        value={
                                            selectedEmployee &&
                                            selectedEmployee.birthDate &&
                                            formatDateForDisplay(selectedEmployee.birthDate) || '-'
                                        }
                                        onChange={() => null}
                                        disabled={true}
                                    />
                                    <label htmlFor="request-timeslot-employee-birth-date">
                                        <Translate msg={`${FIELD_NAME_PREFIX}.employee_birth_date`} />
                                    </label>
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="request-timeslot-employee-seat"
                                        name="request-timeslot-employee-seat"
                                        value={
                                            selectedEmployee &&
                                            selectedEmployee.company &&
                                            selectedEmployee.company.name || '-'
                                        }
                                        onChange={() => null}
                                        disabled={true}
                                    />
                                    <label htmlFor="request-timeslot-employee-seat">
                                        <Translate msg={`${FIELD_NAME_PREFIX}.employee_seat`} />
                                    </label>
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="request-timeslot-examination-reason"
                                        name="request-timeslot-examination-reason"
                                        value={
                                            examinationReason &&
                                            examinationReason.title || '-'
                                        }
                                        onChange={() => null}
                                        disabled={true}
                                    />
                                    <label htmlFor="request-timeslot-examination-reason">
                                        <Translate msg={`${FIELD_NAME_PREFIX}.examination_reason`} />
                                    </label>
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper floatLabel>
                                    <MedicalCenterTypeahead
                                        id="request-timeslot-medical-center"
                                        name="request-timeslot-medical-center"
                                        value={values.medicalCenterId}
                                        onItemSelected={(medicalCenterId, medicalCenter) => {
                                            setFieldValue('medicalCenterId', medicalCenterId);
                                            setFieldValue('medicalCenterName', medicalCenter ? medicalCenter.name : '');
                                        }}
                                        disableReset={true}
                                        placeholderTranslationKey={`${FIELD_NAME_PREFIX}.medical_center`}
                                        includeBufferzones={true}
                                    />
                                    <label htmlFor="request-timeslot-medical-center">
                                        <Translate msg={`${FIELD_NAME_PREFIX}.medical_center`} />
                                    </label>
                                </FloatableTextInputWrapper>
                                <FloatableTextInputWrapper>
                                    <TextInput
                                        id="request-timeslot-contact-name"
                                        name="request-timeslot-contact-name"
                                        value={nameOfUser || '-'}
                                        onChange={() => null}
                                        disabled={true}
                                    />
                                    <label htmlFor="request-timeslot-contact-name">
                                        <Translate msg={`${FIELD_NAME_PREFIX}.contact_name`} />
                                    </label>
                                </FloatableTextInputWrapper>
                                <FormTextInput
                                    name="contactPhoneNumber"
                                    baseId={FORM_NAME}
                                    baseTranslationKey={FIELD_NAME_PREFIX}
                                    fields={fields}
                                    formRenderProps={formRenderProps}
                                />
                                {asyncInfo.error && <ErrorPlaceholder apiError={asyncInfo.error} />}
                                <div className={`${CLASS_NAME}__actions`}>
                                    <SubmitButton
                                        id="edit-external-employee-submit-button"
                                        formName={FORM_NAME}
                                        alwaysEnabled
                                    >
                                        <Translate msg={`${TRANSLATION_PREFIX}.submit`} />
                                    </SubmitButton>
                                </div>
                            </>
                        );
                    }}
                />
            </Dialog>
        );
    }

    private onToggleDay(dayKey: string) {
        if (this.formRenderProps) {
            const newWeekDays = { ...this.formRenderProps.values.weekDays };
            newWeekDays[dayKey] = !newWeekDays[dayKey];
            this.formRenderProps.setFieldValue('weekDays', newWeekDays);
        }
    }

    private onSubmit(values: FormValues) {
        const { requestMedExam, selectedEmployee, nameOfUser, examinationReason, resumptionDate } = this.props;

        requestMedExam({
            birthDateEmployee:
                selectedEmployee &&
                selectedEmployee.birthDate &&
                formatDateForDisplay(selectedEmployee.birthDate),
            contactName: nameOfUser,
            contactPhoneNumber: values.contactPhoneNumber,
            examinationReason: examinationReason && examinationReason.title,
            medicalCenterName: values.medicalCenterName,
            nameEmployee: selectedEmployee && formatPersonName(selectedEmployee),
            planningPreference: values.planningPreference,
            preferredTime: values.preferredTime,
            resumptionDate: resumptionDate && formatDateForDisplay(resumptionDate),
            weekDays: values.weekDays,
        });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const entity = getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
        const medicalCenterIdFromFilter = entity && entity.selectTime && entity.selectTime.filter &&
            entity.selectTime.filter.selectedMedicalCenterId;
        const medicalCenterFromFilter = getCompanyMedicalCenters(state)
            .find((item) => item.id === medicalCenterIdFromFilter);
        const resumptionDate = !!entity.startDate ? entity.startDate : null;

        return {
            selectedEmployee: getSelectedEmployee(state),
            nameOfUser: formatPersonName(getMyUserProfile(state)),
            examinationReason: getPlanMedicalExaminationWizardReason(state),
            asyncInfo: getRequestMedicalExaminationAsyncInfo(state),
            medicalCenterFromFilter,
            resumptionDate,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            requestMedExam: (payload: IRequestMedicalExaminationPayload) => {
                dispatch(requestMedicalExaminationActions.trigger(payload));
            },
            resetAsyncInfo: () => {
                dispatch(requestMedicalExaminationActions.reset({}));
            },
        };
    },
})(RequestTimeslotDialog);

function RequestTimeslotDialog(props: IPublicProps & IPrivateProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <RequestTimeslotDialogComp {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
}
