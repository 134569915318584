import React from 'react';
import './employee-details.scss';
import { connect } from '../../../index';
import {
    IEmployeeDetails,
    TEmployeeUpdateFields,
    IPersonalRisk,
    IEmployee,
    IMedicalCenter,
} from '../../../../models/admin/employee';
import {
    getEmployeePersonalRisks,
    getEmployeePersonalRisksAsyncInfo,
    isSelectedEmployeeEmployedInSelectedCompanySeat,
    getSelectedEmployee,
    getEmployeeFunctionRisksAndResearches,
    getEmployeeFunctionRisksAndResearchesAsyncInfo,
} from '../../../../redux/employee/info/selectors';
import Translate from '../../../common/Translate';
import CollapsibleItem from '../../../common/widget/CollapsibleItem';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import Button from '../../../common/buttons/Button';
import Icon from '../../../common/icons/Icon';
import DetailFields from './DetailFields';
import EditSeatAndFunction from '../shared/EditSeatAndFunction/employee';
import OutOfService from '../shared/OutOfService';
import Alert from '../../../common/widget/Alert';
import EditStatutes from '../shared/EditStatutes';
import EditAbsences from '../shared/EditAbsences';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import {
    IRenderDetailContentProps,
    IRenderDetailHeaderProps,
} from '../../../common/widget/MasterWithDetail/typings';
import TinyLoader from '../../../common/waiting/TinyLoader';
import isEmptyObject from '../../../../utils/core/object/isEmptyObject';
import AddPersonalRisk from '../shared/AddPersonalRisk';
import ShowIfAllowed from '../../../auth/ShowIfAllowed';
import EditEmployeeAddress from '../shared/EditEmployeeAddress';
import ListItem from '../../../common/list/ListItem';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../models/general/i18n';
import TooltipWithIcon from '../../../common/widget/TooltipWithIcon';
import ROUTE_KEYS from '../../../../routeKeys';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { joinParts } from '../../../../utils/formatting/formatHelper';
import EditCostCenter from '../shared/EditCostCenter';
import {
    getCompanyMedicalCenters,
    getFetchCompanyMedicalCentersAsyncInfo,
} from '../../../../redux/company/info/selectors';
import { getRoutePayload, getRouteKey } from '../../../../redux/location/selectors';
import { EmployeeDetailsOverlayType } from './common';
import { isOnboardingWizardRoute } from '../../../../redux/employee/employees/selectors';
import { getEmployeeCourses } from '../../../../redux/documentCenter/courses/selectors';
import { IEmployeeCourse } from '../../../../models/documentCenter/courses';
import EmployeeMedicalExaminations from './DetailComponents/EmployeeMedicalExaminations';
import EmployeeCourses from './DetailComponents/EmployeeCourses';
import EditJobStudent from '../shared/EditJobStudent';
import { isInThePast } from '../../../../utils/core/date/isInThePast';
import { IRisk } from '../../../../models/admin/risks';
import { IResearch } from '../../../../models/admin/researches';
import { NO_RERENDER } from '../../../../redux';
import { sortFunctionRisk, sortFunctionResearch } from '../../Functions/detail';

interface IContentPrivateProps {
    translator: ITranslator;
    employeeFunctionRisks: IRisk[];
    employeeFunctionResearches: IResearch[];
    employeePersonalRisks: IPersonalRisk[];
    employeeCourses: IEmployeeCourse[];
    employeeMedicalCenter: IMedicalCenter;
    isNotPartOfCurrentCompanySeat: boolean;
    selectedPlannedExaminationId: number;
    selectedExecutedExaminationId: number;
    isOnboardingWizardRoute?: boolean;
}

interface IContentPublicProps {
    plannedCoursesRouteKey?: ROUTE_KEYS;
    followedCoursesRouteKey?: ROUTE_KEYS;
    executedMedicalExaminationRouteKey?: ROUTE_KEYS;
    plannedMedicalExaminationRouteKey?: ROUTE_KEYS;
    extraRoutePayload?: object;
}

const CLASS_NAME = 'EmployeeDetails';

export function EmployeeDetailsHeader(props: IRenderDetailHeaderProps<IEmployeeDetails>) {
    const {
        detailAsyncInfo,
        detailData: employee,
    } = props;

    return (
        <h1>
            {detailAsyncInfo.error
                ? <Translate msg="error.title" />
                : employee && formatPersonName(employee)
            }
        </h1>
    );
}

function EmployeeDetailsContentComp(
    props: IRenderDetailContentProps<IEmployeeDetails & IEmployee> & IContentPrivateProps & IContentPublicProps,
) {
    const {
        detailData: employee,
        isNotPartOfCurrentCompanySeat,
    } = props;

    if (!employee) {
        return null;
    }

    return (
        <section className={`${CLASS_NAME}__content`}>
            {
                renderContent(props, {
                    onOpenOverlay: props.onOpenOverlay,
                    isNotPartOfCurrentCompanySeat,
                })
            }
        </section>
    );
}

export const EmployeeDetailsContent =
    connect<IContentPrivateProps, IRenderDetailContentProps<IEmployee & IEmployeeDetails> & IContentPublicProps>({
        statePropsPerInstance: (state, publicProps) => {
            return (state) => {
                const employeePersonalRisks = getEmployeePersonalRisks(state);
                const employeeCourses = getEmployeeCourses(state);
                const selectedEmployee = getSelectedEmployee(state);
                const employeeMedicalCenter = selectedEmployee &&
                    getCompanyMedicalCenters(state).find((item) => item.id === selectedEmployee.medicalCenterId);

                const routeKey = getRouteKey(state);
                const { examinationId } = getRoutePayload<{ examinationId: number }>(state);
                return {
                    isOnboardingWizardRoute: isOnboardingWizardRoute(state),
                    translator: getTranslatorDeprecated(state),
                    employeeFunctionRisks:
                        getEmployeeFunctionRisksAndResearches(state).risks || NO_RERENDER.EMPTY_LIST,
                    employeeFunctionResearches:
                        getEmployeeFunctionRisksAndResearches(state).researches || NO_RERENDER.EMPTY_LIST,
                    employeePersonalRisks,
                    employeeMedicalCenter,
                    employeeCourses,
                    isNotPartOfCurrentCompanySeat: !isSelectedEmployeeEmployedInSelectedCompanySeat(state),
                    selectedPlannedExaminationId:
                        routeKey === publicProps.plannedMedicalExaminationRouteKey && examinationId,
                    selectedExecutedExaminationId:
                        routeKey === publicProps.executedMedicalExaminationRouteKey && examinationId,
                };
            };
        },
    })(EmployeeDetailsContentComp);

export function EmployeeDetailsOverlay(props: {
    overlayType: EmployeeDetailsOverlayType,
    closeOverlay: () => void,
    isOnboardingWizardRoute?: boolean;
    onSave?: (data?: object) => void;
}) {
    switch (props.overlayType) {
        case EmployeeDetailsOverlayType.EditFunction:
            return (
                <EditSeatAndFunction
                    isEditFunction={true}
                    onClose={props.closeOverlay}
                    isOnboardingWizardRoute={props.isOnboardingWizardRoute}
                />
            );
        case EmployeeDetailsOverlayType.EditSeat:
            return (
                <EditSeatAndFunction
                    onClose={props.closeOverlay}
                    isOnboardingWizardRoute={props.isOnboardingWizardRoute}
                />
            );
        case EmployeeDetailsOverlayType.EditDateInFunction:
            return (
                <EditSeatAndFunction
                    isEditDateInFunction={true}
                    onClose={props.closeOverlay}
                    isOnboardingWizardRoute={props.isOnboardingWizardRoute}
                />
            );
        case EmployeeDetailsOverlayType.SetOutOfService:
            return <OutOfService onClose={props.closeOverlay} />;
        case EmployeeDetailsOverlayType.EditOutOfService:
            return <OutOfService onClose={props.closeOverlay} />;
        case EmployeeDetailsOverlayType.EditStatutes:
            return <EditStatutes onClose={props.closeOverlay} />;
        case EmployeeDetailsOverlayType.EditAbsenses:
            return <EditAbsences onClose={props.closeOverlay} />;
        case EmployeeDetailsOverlayType.AddPersonalRisk:
            return <AddPersonalRisk onClose={props.closeOverlay} />;
        case EmployeeDetailsOverlayType.EditCostCenter:
            return <EditCostCenter onClose={props.closeOverlay} />;
        case EmployeeDetailsOverlayType.EditJobStudent:
            return <EditJobStudent onClose={props.closeOverlay} onSave={props.onSave} />;
        default:
            return <EditEmployeeAddress onClose={props.closeOverlay} />;
    }
}

function renderContent(
    props: IRenderDetailContentProps<IEmployeeDetails> & IContentPrivateProps & IContentPublicProps,
    contentProps: { // eslint-disable-next-line max-len
        onOpenOverlay: (overlayType: EmployeeDetailsOverlayType, onSave?: (data: object) => void, formValues?: object) => void;
        isNotPartOfCurrentCompanySeat: boolean;
    },
) {
    const {
        detailData: employee,
        employeeFunctionRisks,
        employeeFunctionResearches,
        employeePersonalRisks,
        employeeMedicalCenter,
        selectedPlannedExaminationId,
        selectedExecutedExaminationId,
        executedMedicalExaminationRouteKey,
        plannedMedicalExaminationRouteKey,
        extraRoutePayload,
        isOnboardingWizardRoute: fromOnboardingWizard,
        followedCoursesRouteKey,
        plannedCoursesRouteKey,
    } = props;

    const isGoingOutOfService = employee.dateOutOfService && !employee.futureEmployment &&
        !employee.newCompany && !employee.newFunction;

    const infoIcon = (
        <Icon typeName="info" circle />
    );

    const isNewFutureEmployee = employee && !isInThePast(employee.dateInService);

    return (
        <>
            <div className={`${CLASS_NAME}__info`}>
                <p>
                    <Translate
                        // eslint-disable-next-line max-len
                        msg={`administration.employees.detail.header.${isNewFutureEmployee ? 'start_date_future' : 'start_date'}`}
                        placeholders={{ date: formatDateForDisplay(employee.dateInService) }}
                    />
                    <br />
                    {isGoingOutOfService ?
                        <>
                            <Alert type="warning" small={true}>
                                <Translate
                                    msg="administration.employees.detail.header.alerts.out_of_service"
                                    placeholders={{
                                        date: formatDateForDisplay(employee.dateOutOfService),
                                    }}
                                />
                            </Alert>
                            <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }}>
                                <a
                                    onClick={() =>
                                        contentProps.onOpenOverlay(EmployeeDetailsOverlayType.EditOutOfService)
                                    }
                                >
                                    <Translate msg="administration.employees.detail.header.edit_quit" />
                                </a>
                            </ShowIfAllowed>
                        </> :
                        <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }}>
                            <a
                                onClick={() =>
                                    contentProps.onOpenOverlay(EmployeeDetailsOverlayType.SetOutOfService)
                                }
                            >
                                {/* eslint-disable-next-line max-len */}
                                <Translate msg={`administration.employees.detail.header.${isNewFutureEmployee ? 'cancel_future_employment' : 'quit'}`} />
                            </a>
                        </ShowIfAllowed>
                    }
                </p>

                {contentProps.isNotPartOfCurrentCompanySeat && !fromOnboardingWizard &&
                    <Alert small={true} type="info">
                        <p>
                            <Translate
                                msg={'administration.employees.detail.header.alerts.active_in_another_company_seat'}
                            />
                        </p>
                    </Alert>
                }
                {employee.absent &&
                    <Alert small={true} type="info">
                        <p>
                            <Translate
                                msg={'administration.employees.detail.header.alerts.absent'}
                            />
                        </p>
                    </Alert>
                }
                {employee.newFunction &&
                    <Alert small={true} type="info">
                        <p>
                            <Translate
                                msg={'administration.employees.detail.header.alerts.new_function'}
                                placeholders={{
                                    date: formatDateForDisplay(employee.dateOutOfService),
                                    name: employee.newFunction.description,
                                }}
                            />
                        </p>
                    </Alert>
                }
                {employee.newCompany &&
                    <Alert small={true} type="info">
                        <p>
                            <Translate
                                msg={'administration.employees.detail.header.alerts.new_seat'}
                                placeholders={{
                                    date: formatDateForDisplay(employee.dateOutOfService),
                                    name: employee.newCompany.name,
                                }}
                            />
                        </p>
                    </Alert>
                }
            </div>

            <CollapsibleItem
                trigger="administration.employees.detail.employee_details.title"
                initialOpen={!selectedExecutedExaminationId && !selectedPlannedExaminationId}
            >
                <DetailFields
                    employee={employee}
                    getRequestId={getUpdateEmployeeRequestId}
                    onOpenOverlay={contentProps.onOpenOverlay}
                />
            </CollapsibleItem>

            {!fromOnboardingWizard &&
                <CollapsibleItem trigger="administration.employees.detail.risks.title">
                    <TinyLoader asyncInfoSelector={getEmployeeFunctionRisksAndResearchesAsyncInfo}>
                        {employeeFunctionRisks.length > 0 ?
                            <>
                                {
                                    employeeFunctionRisks.sort(sortFunctionRisk).map((risk, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                title={risk.description}
                                                text={risk.riskCode}
                                                arrow={true}
                                            />
                                        );
                                    })
                                }
                            </> :
                            <p>
                                <Translate
                                    msg="administration.employees.detail.risks.no_results"
                                />
                            </p>
                        }
                    </TinyLoader>
                    <h4>
                        <Translate msg="administration.employees.detail.risks.individual_risks.title" />
                    </h4>
                    <TinyLoader asyncInfoSelector={getEmployeePersonalRisksAsyncInfo}>
                        {employeePersonalRisks.length > 0 ?
                            employeePersonalRisks.map((item, index) =>
                                <ListItem
                                    key={index}
                                    title={item.description}
                                    text=""
                                />,
                            )
                            :
                            <p>
                                <Translate
                                    // eslint-disable-next-line max-len
                                    msg="administration.employees.detail.risks.individual_risks.no_results"
                                />
                            </p>
                        }
                        <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }}>
                            <Button
                                id="add-individual-risk"
                                typeName="text"
                                // eslint-disable-next-line max-len
                                onClick={() => contentProps.onOpenOverlay(EmployeeDetailsOverlayType.AddPersonalRisk)}
                            >
                                <Icon typeName="plus" circle />
                                <span>
                                    <Translate msg="administration.employees.detail.risks.individual_risks.add" />
                                </span>
                            </Button>
                        </ShowIfAllowed>
                    </TinyLoader>
                </CollapsibleItem>
            }

            {!fromOnboardingWizard &&
                <CollapsibleItem trigger="administration.employees.detail.researches.title">
                    <TinyLoader asyncInfoSelector={getEmployeeFunctionRisksAndResearchesAsyncInfo}>
                        {employeeFunctionResearches.length > 0 ?
                            <>
                                {
                                    employeeFunctionResearches.sort(sortFunctionResearch).map((research, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                title={research.researchDescription}
                                                text={research.periodicityDescription}
                                                arrow={true}
                                            />
                                        );
                                    })
                                }
                            </> :
                            <p>
                                <Translate
                                    msg="administration.employees.detail.researches.no_results"
                                />
                            </p>
                        }
                    </TinyLoader>
                </CollapsibleItem>
            }

            <ShowIfAllowed requiredAccessLevels={{ planning: 'R' }}>
                <CollapsibleItem
                    trigger="administration.employees.detail.medical_examination.title"
                    initialOpen={(!!selectedExecutedExaminationId || !!selectedPlannedExaminationId)
                        || fromOnboardingWizard}
                >
                    <h4>
                        <Translate
                            msg="administration.employees.detail.medical_examination.medical_center.title"
                        />
                        <TooltipWithIcon icon={infoIcon} typeName="info-bubble" iconSize="small">
                            <Translate
                                msg="administration.employees.detail.medical_examination.medical_center.help"
                            />
                        </TooltipWithIcon>
                    </h4>
                    <TinyLoader
                        asyncInfoSelector={getFetchCompanyMedicalCentersAsyncInfo}
                        // eslint-disable-next-line max-len
                        errorPlaceholderTranslationKey="administration.employees.detail.medical_examination.medical_center.not_found"
                    >
                        {
                            employeeMedicalCenter && !isEmptyObject(employeeMedicalCenter) ?
                                <p>
                                    <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }}>
                                        <span className="address-wrapped">
                                            {
                                                joinParts(
                                                    [
                                                        employeeMedicalCenter.name,
                                                        formatAddress(employeeMedicalCenter.address),
                                                    ],
                                                    '\n',
                                                )
                                            }
                                        </span>
                                    </ShowIfAllowed>
                                </p>
                                : (
                                    <>
                                        <p>
                                            <Translate
                                                // eslint-disable-next-line max-len
                                                msg="administration.employees.detail.medical_examination.medical_center.empty"
                                            />
                                        </p>
                                    </>
                                )
                        }
                    </TinyLoader>
                    {!fromOnboardingWizard &&
                        <EmployeeMedicalExaminations
                            employee={employee}
                            executedMedicalExaminationRouteKey={executedMedicalExaminationRouteKey}
                            plannedMedicalExaminationRouteKey={plannedMedicalExaminationRouteKey}
                            extraRoutePayload={extraRoutePayload}
                        />
                    }
                </CollapsibleItem>
                {!fromOnboardingWizard &&
                    <EmployeeCourses
                        employee={employee}
                        followedCoursesRouteKey={followedCoursesRouteKey}
                        plannedCoursesRouteKey={plannedCoursesRouteKey}
                        extraRoutePayload={extraRoutePayload}
                    />
                }
            </ShowIfAllowed>
        </>
    );
}

export function getUpdateEmployeeRequestId(id: number | string, fieldName: keyof TEmployeeUpdateFields): string {
    return `${id}_${fieldName}`;
}
