import React, { PureComponent } from 'react';
import './edit-appointment-dialog.scss';
import Dialog from '../../../../common/modals/Dialog';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';
import Button from '../../../../common/buttons/Button';
import EmployeeTypeahead from '../../../../administration/employee/EmployeeTypeahead';
import { connect } from '../../../../index';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    IPlannedMedicalExamination,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    IExaminationReason,
    PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID,
} from '../../../../../models/interventions/medicalExaminations';
import {
    triggerReplaceEmployeeUpdateTimeslot,
    removeTimeslot,
    skipToPlanMedicalExaminationWizardStepActions,
    validateEmployeeToPlanActions,
} from '../../../../../redux/medicalExamination/actions';
import { IState } from '../../../../../redux';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import {
    getUpdateTimeslotAsyncInfo,
    getRemoveTimeslotAsyncInfo,
    getPlannedMedicalExaminations,
    getLastUpdatedTimeslotPlanningId,
    getValidateEmployeeToPlanAsyncInfo, getMedicalExaminationToPlanInfoByEmployeeId,
} from '../../../../../redux/medicalExamination/selectors';
import { IEmployee } from '../../../../../models/admin/employee';
import Loader from '../../../../common/waiting/Loader';
import FormError from '../../../../common/forms/FormError';
import { ITraceableApiError } from '../../../../../models/general/error';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import GoBack from '../../../../common/navigation/GoBack';
import CancelExamination from '../../../shared/CancelExamination';
import isCancelExaminationAllowed
    from '../../../../../utils/interventions/medicalExaminations/isCancelExaminationAllowed';
import isChangeExaminationEmployeeAllowed
    from '../../../../../utils/interventions/medicalExaminations/isChangeExaminationEmployeeAllowed';
import { NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED } from '../../../../../config/medicalExamination.config';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import { getCaseManagerAsyncInfo, getCaseManager } from '../../../../../redux/contact/selectors';
import { ICaseManager } from '../../../../../models/contact/caseManager';
import ContactInformation from '../../../../contact/shared/ContactInformation';
import ValidateEmployeeToPlanDialog from
    '../../../PlanMedicalExamination/PlanMedicalExaminationWizard/shared/ValidateEmployeeToPlanDialog';

// Remove this const to re-enable change employee option
const TEMPORARILY_DISABLE_CHANGE_EMPLOYEE = true;

interface IPrivateProps {
    translator: ITranslator;
    plannedMedicalExamination: IPlannedMedicalExamination;
    updateTimeslotReplaceEmployee: (employee: IEmployee) => void;
    cancelTimeslot: () => void;
    updateTimeslotAsyncInfo: IAsyncFieldInfo;
    removeTimeslotAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
    triggerReplanWithSelectedExamination: () => void;
    navigateToConvocationStepOfPlanning: (newEmployee: IEmployee) => void;
    caseManager: ICaseManager;
    validateSelectedEmployee: (employeeToPlan: IEmployee, onValidationPassed: () => void) => void;
    validateEmployeeToPlanAsyncInfo: IAsyncFieldInfo;
}

interface IEditAppointmentDialogProps {
    show: boolean;
    onCloseIntent: () => void;
    plannedMedicalExaminationId: number;
}

interface IComponentState {
    isChangeEmployeeOpen: boolean;
    isCancelOpen: boolean;
    selectedEmployee: IEmployee;
    replanSelected: boolean;
}

const CLASS_NAME = 'EditAppointmentDialog';
const INITIAL_STATE: IComponentState = {
    isCancelOpen: false,
    isChangeEmployeeOpen: false,
    selectedEmployee: null,
    replanSelected: false,
};

class EditAppointmentDialog extends PureComponent<IPrivateProps & IEditAppointmentDialogProps, IComponentState> {

    constructor(props: IPrivateProps & IEditAppointmentDialogProps) {
        super(props);

        this.state = INITIAL_STATE;

        this.onClose = this.onClose.bind(this);

        this.onCancelHandler = this.onCancelHandler.bind(this);
        this.onChangeEmployeeHandler = this.onChangeEmployeeHandler.bind(this);
        this.onReplanHandler = this.onReplanHandler.bind(this);
        this.onGoBackHandler = this.onGoBackHandler.bind(this);
        this.onCancelAppointmentHandler = this.onCancelAppointmentHandler.bind(this);
        this.onUpdateTimeslotReplaceEmployeeHandler = this.onUpdateTimeslotReplaceEmployeeHandler.bind(this);
        this.onCancelAndReplanAppointmentHandler = this.onCancelAndReplanAppointmentHandler.bind(this);

        this.isCancelOrReplanAllowed = this.isCancelOrReplanAllowed.bind(this);
        this.isChangeEmployeeAllowed = this.isChangeEmployeeAllowed.bind(this);
    }

    public render() {
        const { show } = this.props;

        return (
            <>
                <Dialog show={show} onCloseIntent={this.onClose}>
                    <div className={CLASS_NAME}>
                        {this.renderContent()}
                    </div>
                </Dialog>
                <ValidateEmployeeToPlanDialog onConfirm={this.onUpdateTimeslotReplaceEmployeeHandler} />
            </>
        );
    }

    public componentDidMount() {
        const { clearError, updateTimeslotAsyncInfo, removeTimeslotAsyncInfo } = this.props;
        clearError(updateTimeslotAsyncInfo.error);
        clearError(removeTimeslotAsyncInfo.error);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IEditAppointmentDialogProps) {
        if (
            prevProps.removeTimeslotAsyncInfo.status === AsyncStatus.Busy &&
            this.props.removeTimeslotAsyncInfo.status === AsyncStatus.Success
        ) {
            this.onClose();
        }

        if (
            prevProps.updateTimeslotAsyncInfo.status === AsyncStatus.Busy &&
            this.props.updateTimeslotAsyncInfo.status === AsyncStatus.Success
        ) {
            this.props.navigateToConvocationStepOfPlanning(this.state.selectedEmployee);
        }

        if (!prevProps.show && this.props.show) {
            this.setState(INITIAL_STATE);
        }
    }

    private renderContent() {
        const {
            translator, plannedMedicalExamination, updateTimeslotAsyncInfo, caseManager, validateSelectedEmployee,
            validateEmployeeToPlanAsyncInfo,
        } = this.props;
        const {
            isCancelOpen, isChangeEmployeeOpen, selectedEmployee, replanSelected,
        } = this.state;

        if (isCancelOpen) {
            return (
                <CancelExamination
                    onGoBack={this.onGoBackHandler}
                    onCancel={replanSelected ?
                        this.onCancelAndReplanAppointmentHandler :
                        this.onCancelAppointmentHandler}
                    isReplan={replanSelected}
                />
            );
        }

        if (isChangeEmployeeOpen) {
            const showLoader =
                validateEmployeeToPlanAsyncInfo.status === AsyncStatus.Busy ||
                updateTimeslotAsyncInfo.status === AsyncStatus.Busy;

            return (
                <>
                    <Loader show={showLoader} />
                    <GoBack id="edit-appointment-change-employee-go-back" onClick={this.onGoBackHandler} />
                    <h4>
                        <Translate msg="interventions.medical_examinations.edit_appointment.change_employee.title" />
                    </h4>
                    <span>
                        <Translate msg="interventions.medical_examinations.edit_appointment.change_employee.text" />
                    </span>
                    <EmployeeTypeahead
                        id="edit-appointment-employee"
                        name="employee"
                        value={selectedEmployee && selectedEmployee.id}
                        onItemSelected={(value, employee) => this.setState({ selectedEmployee: employee })}
                        placeholder={
                            // eslint-disable-next-line max-len
                            translator('interventions.medical_examinations.edit_appointment.change_employee.employee_placeholder')
                        }
                        employeeIdsToExcludeFromData={
                            plannedMedicalExamination && [plannedMedicalExamination.employee.employeeId]
                        }
                    />
                    <FormError error={updateTimeslotAsyncInfo.error} />
                    <FormError error={validateEmployeeToPlanAsyncInfo.error} />
                    <div className={`${CLASS_NAME}__submit-button`}>
                        <Button
                            id="edit-appointment-change-employee-submit-button"
                            typeName="secondary"
                            onClick={
                                () => validateSelectedEmployee(
                                    selectedEmployee,
                                    this.onUpdateTimeslotReplaceEmployeeHandler,
                                )
                            }
                            disabled={!selectedEmployee}
                        >
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.change_employee.submit"
                            />
                        </Button>
                    </div>
                </>
            );
        }

        const isCancelOrReplanAllowed = this.isCancelOrReplanAllowed();
        const isChangeEmployeeAllowed = this.isChangeEmployeeAllowed();

        return (
            <>
                <Icon typeName="warning" circle />
                <h3>
                    <Translate msg="interventions.medical_examinations.edit_appointment.title" />
                </h3>
                {((!TEMPORARILY_DISABLE_CHANGE_EMPLOYEE && !isChangeEmployeeAllowed) || !isCancelOrReplanAllowed) && (
                    <>
                        <p>
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.text.warning"
                                placeholders={{
                                    hours: (!TEMPORARILY_DISABLE_CHANGE_EMPLOYEE && !isChangeEmployeeAllowed) ?
                                        NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED.CHANGE_EMPLOYEE :
                                        NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED.CANCEL,
                                }}
                            />
                        </p>
                        <TinyLoader asyncInfoSelector={getCaseManagerAsyncInfo}>
                            <ContactInformation contact={caseManager} />
                        </TinyLoader>
                    </>
                )}
                {!TEMPORARILY_DISABLE_CHANGE_EMPLOYEE && isChangeEmployeeAllowed && !isCancelOrReplanAllowed && (
                    <p>
                        <Translate
                            msg="interventions.medical_examinations.edit_appointment.text.warning_prevent_cost"
                        />
                    </p>
                )}
                <div className={`${CLASS_NAME}__actions`}>
                    {isChangeEmployeeAllowed && !TEMPORARILY_DISABLE_CHANGE_EMPLOYEE && (
                        <Button
                            id="edit-appointment-change-employee-button"
                            typeName="secondary"
                            onClick={this.onChangeEmployeeHandler}
                        >
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.actions.change_employee"
                            />
                        </Button>
                    )}
                    {isCancelOrReplanAllowed && (
                        <>
                            <Button
                               id="edit-appointment-cancel-replan-button"
                               typeName="secondary"
                               onClick={this.onReplanHandler}
                            >
                               <Translate
                                   msg="interventions.medical_examinations.edit_appointment.actions.cancel_and_replan"
                               />
                            </Button>
                            <Button
                                id="edit-appointment-cancel-button"
                                typeName="secondary"
                                outline={true}
                                onClick={this.onCancelHandler}
                            >
                                {/*eslint-disable-next-line max-len*/}
                                <Translate msg="interventions.medical_examinations.edit_appointment.actions.cancel" />
                            </Button>
                        </>
                    )}

                </div>
            </>
        );
    }

    private isCancelOrReplanAllowed() {
        const { plannedMedicalExamination } = this.props;

        return plannedMedicalExamination && isCancelExaminationAllowed(plannedMedicalExamination.time);
    }

    private isChangeEmployeeAllowed() {
        const { plannedMedicalExamination } = this.props;

        return plannedMedicalExamination && isChangeExaminationEmployeeAllowed(plannedMedicalExamination.time);
    }

    private onChangeEmployeeHandler() {
        if (this.isChangeEmployeeAllowed()) {
            this.setState({ isChangeEmployeeOpen: true });
        }
    }

    private onReplanHandler() {
        if (this.isCancelOrReplanAllowed()) {
            this.setState({ isCancelOpen: true, replanSelected: true });
        }
    }

    private onCancelHandler() {
        if (this.isCancelOrReplanAllowed()) {
            this.setState({ isCancelOpen: true });
        }
    }

    private onGoBackHandler() {
        this.setState({ isCancelOpen: false, isChangeEmployeeOpen: false, replanSelected: false });
    }

    private onClose() {
        const { clearError, onCloseIntent, updateTimeslotAsyncInfo, removeTimeslotAsyncInfo } = this.props;
        clearError(updateTimeslotAsyncInfo.error);
        clearError(removeTimeslotAsyncInfo.error);
        onCloseIntent();
    }

    private onCancelAndReplanAppointmentHandler() {
        if (this.isCancelOrReplanAllowed()) {
            this.props.triggerReplanWithSelectedExamination();
        }
    }

    private onCancelAppointmentHandler() {
        if (this.isCancelOrReplanAllowed()) {
            this.props.cancelTimeslot();
        }
    }

    private onUpdateTimeslotReplaceEmployeeHandler() {
        if (this.isChangeEmployeeAllowed()) {
            this.props.updateTimeslotReplaceEmployee(this.state.selectedEmployee);
        }
    }
}

export default connect<IPrivateProps, IEditAppointmentDialogProps>({
    statePropsDeprecated: (state, publicProps) => {
        return {
            translator: getTranslatorDeprecated(state),
            plannedMedicalExamination: getPlannedMedicalExamination(state, publicProps.plannedMedicalExaminationId),
            updateTimeslotAsyncInfo: getUpdateTimeslotAsyncInfo(state),
            removeTimeslotAsyncInfo: getRemoveTimeslotAsyncInfo(state),
            caseManager: getCaseManager(state),
            validateEmployeeToPlanAsyncInfo: getValidateEmployeeToPlanAsyncInfo(state),
        };
    },
    dispatchPropsDeprecated: (dispatch, getState, publicProps) => {
        return {
            updateTimeslotReplaceEmployee: (employee: IEmployee) => {
                const state = getState();
                const plannedMedicalExamination =
                    getPlannedMedicalExamination(state, publicProps.plannedMedicalExaminationId);
                const optionalToPlanInfoOfNewEmployee = employee &&
                    getMedicalExaminationToPlanInfoByEmployeeId(
                        state,
                        {
                            employeeId: employee.id,
                            examinationReason: { id: plannedMedicalExamination.examinationReason.id },
                        },
                    );

                dispatch(triggerReplaceEmployeeUpdateTimeslot({
                    remarks: '',
                    employee: {
                        id: employee.id,
                        employeeId: employee.employeeId,
                    },
                    examinationReason: {
                        id: plannedMedicalExamination.examinationReason.id,
                    },
                    timeCellId: plannedMedicalExamination.timeCell.id,
                    planningEntityId: plannedMedicalExamination.planningEntityId,
                    company: {
                        id: employee.company.id,
                        companyCode: employee.company.companyCode,
                    },
                    planningRecordId: optionalToPlanInfoOfNewEmployee
                        && optionalToPlanInfoOfNewEmployee.planningRecordId,
                }));
            },
            cancelTimeslot: () => {
                const state = getState();
                const plannedMedicalExamination =
                    getPlannedMedicalExamination(state, publicProps.plannedMedicalExaminationId);

                dispatch(removeTimeslot({
                    timeCellId: plannedMedicalExamination.timeCell.id,
                    employee: {
                        id: plannedMedicalExamination.employee.id,
                    },
                    cancelDate: plannedMedicalExamination.time,
                }));
            },
            triggerReplanWithSelectedExamination: () => {
                const state = getState();
                const plannedMedicalExamination: IPlannedMedicalExamination =
                    getPlannedMedicalExamination(state, publicProps.plannedMedicalExaminationId);

                dispatch(skipToPlanMedicalExaminationWizardStepActions.trigger({
                    wizardPayload: {
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.MOVE_PLANNING,
                        reason: plannedMedicalExamination.examinationReason as IExaminationReason,
                        step: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME,
                    },
                    entity: {
                        searchEmployee: {
                            autoSelected: true,
                            searchValue: '',
                            selectedEmployee: {
                                id: plannedMedicalExamination.employee.id,
                                employeeId: plannedMedicalExamination.employee.employeeId,
                                firstName: plannedMedicalExamination.firstName,
                                name: plannedMedicalExamination.name,
                            },
                        },
                        selectTime: undefined,
                        movePlanning: {
                            timeCellId: plannedMedicalExamination.timeCell.id,
                            planningRecordId: plannedMedicalExamination.id,
                        },
                    },
                }));
            },
            navigateToConvocationStepOfPlanning: (newEmployee: IEmployee) => {
                const state = getState();
                const plannedMedicalExamination: IPlannedMedicalExamination =
                    getPlannedMedicalExamination(state, publicProps.plannedMedicalExaminationId);

                const planningId = getLastUpdatedTimeslotPlanningId(state);

                dispatch(skipToPlanMedicalExaminationWizardStepActions.trigger({
                    wizardPayload: {
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.MOVE_PLANNING,
                        reason: plannedMedicalExamination.examinationReason as IExaminationReason,
                        step: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_NOTIFY,
                    },
                    entity: {
                        searchEmployee: {
                            autoSelected: true,
                            searchValue: '',
                            selectedEmployee: {
                                id: newEmployee.id,
                                employeeId: newEmployee.employeeId,
                                firstName: newEmployee.firstName,
                                name: newEmployee.name,
                            },
                        },
                        selectTime: {
                            planningId,
                        },
                    },
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
            validateSelectedEmployee: (employeeToPlan: IEmployee, onValidationPassed: () => void) => {
                const plannedMedicalExamination: IPlannedMedicalExamination =
                    getPlannedMedicalExamination(getState(), publicProps.plannedMedicalExaminationId);

                dispatch(validateEmployeeToPlanActions.trigger({
                    employeeToPlan,
                    examinationReason: plannedMedicalExamination.examinationReason,
                    onValidationPassed,
                }));
            },
        };
    },
})(EditAppointmentDialog);

function getPlannedMedicalExamination(state: IState, plannedMedicalExaminationId: number) {
    const plannedMedicalExaminations = getPlannedMedicalExaminations(state);
    return plannedMedicalExaminations.find((item) => plannedMedicalExaminationId === item.id);
}
