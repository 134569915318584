import {
    IFetchMedicalCenterTimeslotsPayload,
    IMedicalCenterFreeSlotsPayload,
} from '../../../models/interventions/medicalExaminations';
import {
    IAddTimeslotByPlanningRecordIdPayload,
    IAddTimeslotPayload,
    IAutoPlanEmployeePayload,
    IAutoPlanEmployeeSuccessPayload,
    IRemoveTimeslotPayload,
    TUpdateTimeslotPayload,
    IFetchTimeCellPlanningConfigurationPayload,
} from '../../../models/interventions/timeslots';
import ROUTE_KEYS from '../../../routeKeys';
import isMainSeatCompanyCode from '../../../utils/administration/companyInfo/isMainSeatCompanyCode';
import { getMedExamToAddId } from '../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import {
    getSelectedCompanyCode,
    getSelectedSeatCompanyCode,
    isAllSeatsSelected,
} from '../../company/selected/selectors';
import { createEpic } from '../../index';
import { getQueryParams, getRouteKey } from '../../location/selectors';
import {
    addTimeslotActions,
    autoPlanEmployeesFailed,
    autoPlanEmployeesSucceeded,
    fetchCompanyMedicalCentersFreeSlotsFailed,
    fetchCompanyMedicalCentersFreeSlotsSucceeded,
    fetchCompanyMedicalCenterTimeslotsActions,
    fetchPlannedMedicalExaminationsActions,
    removeTimeslotFailed,
    removeTimeslotSucceeded,
    updateTimeslotActions,
    fetchTimeCellPlanningConfigurationActions,
} from '../actions';
import {
    ADD_TIMESLOT,
    AUTO_PLAN_EMPLOYEES,
    FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS,
    REMOVE_TIMESLOT,
    UPDATE_TIMESLOT,
    FETCH_TIME_CELL_PLANNING_CONFIG,
} from '../types';

// updateTimeslotEpic
createEpic<TUpdateTimeslotPayload>({
    onActionType: UPDATE_TIMESLOT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const { payload } = action;

            const { planningId } = await api.interventions.timeslots.updateTimeslot(payload);
            if (getRouteKey(state) === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED) {
                const filter = getQueryParams(state);
                dispatch(fetchPlannedMedicalExaminationsActions.trigger(filter));
            }
            dispatch(updateTimeslotActions.succeeded({ planningId }));
            done();
        } catch (error) {
            dispatch(updateTimeslotActions.failed(error));
            done();
        }
    },
    latest: false,
});

// removeTimeslotEpic
createEpic<IRemoveTimeslotPayload>({
    onActionType: REMOVE_TIMESLOT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.interventions.timeslots.removeTimeslot(payload);
            const state = getState();
            if (getRouteKey(state) === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED) {
                const filter = getQueryParams(state);
                dispatch(fetchPlannedMedicalExaminationsActions.trigger(filter));
            }
            dispatch(removeTimeslotSucceeded());
            done();
        } catch (error) {
            dispatch(removeTimeslotFailed(error));
            done();
        }
    },
    latest: false,
});

// fetchCompanyMedicalCentersFreeSlotsEpic
createEpic<IMedicalCenterFreeSlotsPayload>({
    onActionType: FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS,
    latest: false, // because each fetch has it's own async status
    async processReturn({ api, action }) {
        const payload = action.payload;

        try {
            const response = await api.interventions.timeslots.fetchCompanyMedicalCentersFreeSlots(payload);

            return fetchCompanyMedicalCentersFreeSlotsSucceeded({
                medicalCenters: response,
                requestId: payload.requestId,
            });

        } catch (error) {
            return fetchCompanyMedicalCentersFreeSlotsFailed({
                ...error,
                requestId: payload.requestId,
            });
        }
    },
});

// autoPlanEmployeesEpic
createEpic<IAutoPlanEmployeePayload[]>({
    onActionType: AUTO_PLAN_EMPLOYEES,
    async processReturn({ api, action, getState }) {
        try {
            const response =
                await api.interventions.timeslots.autoPlanEmployees(action.payload);

            const fullResponse = addPlanParametersToResponse(response, action.payload);

            return autoPlanEmployeesSucceeded(fullResponse);
        } catch (error) {
            return autoPlanEmployeesFailed(error);
        }
    },
    latest: false,
});

function addPlanParametersToResponse(
    response: IAutoPlanEmployeeSuccessPayload[],
    payload: IAutoPlanEmployeePayload[],
) {
    return response.map((plannedExam) => {
        const fullPlannedExamination = { ...plannedExam };
        const examinationToPlan = payload.find((examToPlan) => (
            getMedExamToAddId(examToPlan) === getMedExamToAddId(fullPlannedExamination)
        ));

        if (examinationToPlan) {
            fullPlannedExamination.startDate = examinationToPlan.startDate;
            fullPlannedExamination.startTime = examinationToPlan.startTime;
            fullPlannedExamination.endTime = examinationToPlan.endTime;
            fullPlannedExamination.locationId = examinationToPlan.locationId;
            fullPlannedExamination.examinationReasonId = examinationToPlan.examinationReason.id;
        }

        return fullPlannedExamination;
    });
}

// fetchCompanyMedicalCenterTimeslotsEpic
createEpic<IFetchMedicalCenterTimeslotsPayload>({
    onActionType: FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const payload = action.payload;
            const seat = getSelectedSeatCompanyCode(state);
            const companyCode = getSelectedCompanyCode(state);
            const allSeatsSelected = isAllSeatsSelected(state);

            const timeCells = await api.interventions.timeslots
                .fetchCompanyMedicalCenterTimeslots(seat, {
                    ...payload,
                    showFullFamily: allSeatsSelected,
                    includeMainSeat: !isMainSeatCompanyCode(seat),
                });

            if (seat === companyCode) {
                return fetchCompanyMedicalCenterTimeslotsActions.succeeded(timeCells);
            }

            // Do some extra calls when you are on a specific seat to also get the CBZ cells from the main seat
            const timeCellsReserved = await api.interventions.timeslots
                .fetchCompanyMedicalCenterTimeslots(seat, {
                    ...payload,
                    showOnlyReserved: true,
                    showFullFamily: allSeatsSelected,
                    includeMainSeat: !isMainSeatCompanyCode(seat),
                });

            const allTimeCells = [...timeCells, ...timeCellsReserved];
            const uniqueTimeCells = [];
            const map = new Map();
            for (const timeCell of allTimeCells) {
                if (!map.has(timeCell.id)) {
                    map.set(timeCell.id, true);
                    uniqueTimeCells.push({ ...timeCell });
                }
            }

            return fetchCompanyMedicalCenterTimeslotsActions.succeeded(uniqueTimeCells);
        } catch (error) {
            return fetchCompanyMedicalCenterTimeslotsActions.failed(error);
        }
    },
    latest: false,
});

// addTimeslotEpic
createEpic<IAddTimeslotPayload | IAddTimeslotByPlanningRecordIdPayload>({
    onActionType: ADD_TIMESLOT,
    async processReturn({ api, action }) {
        try {
            const { payload } = action;
            if (isAddTimeslotByPlanningRecordIdPayload(payload)) {
                const { planningId } =
                    await api.interventions.timeslots.addTimeslotByPlanningRecordId(payload);
                return addTimeslotActions.succeeded({ planningId });
            }
            const { planningId } = await api.interventions.timeslots.addTimeslot(payload);
            return addTimeslotActions.succeeded({ planningId });
        } catch (error) {
            return addTimeslotActions.failed(error);
        }
    },
    latest: false,
});

function isAddTimeslotByPlanningRecordIdPayload(
    payload: IAddTimeslotPayload | IAddTimeslotByPlanningRecordIdPayload,
): payload is IAddTimeslotByPlanningRecordIdPayload {
    const payloadWithPlanningRecord = payload as IAddTimeslotByPlanningRecordIdPayload;
    return (
        payloadWithPlanningRecord &&
        typeof payloadWithPlanningRecord.planningRecordId === 'number'
    );
}

// fetchTimeCellPlanningConfigurationEpic
createEpic<IFetchTimeCellPlanningConfigurationPayload>({
    onActionType: FETCH_TIME_CELL_PLANNING_CONFIG,
    latest: false,
    async processReturn({ api, action }) {
        const payload = action.payload;

        try {
            const config = await api.interventions.timeslots.fetchTimeCellPlanningConfiguration(payload);

            return fetchTimeCellPlanningConfigurationActions.succeeded(config);
        } catch (error) {
            return fetchTimeCellPlanningConfigurationActions.failed(error);
        }
    },
});
