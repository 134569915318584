import { path } from 'ramda';

import {
    IPlanBufferzoneWizardPayload,
    PLAN_BUFFERZONE_WIZARD_STEP_ID,
    ISkipToPlanBufferzoneWizardStepPayload,
    IPlanBufferzoneWizardEntity,
    IBufferzonePlanningCreateNewPlanningPayload,
    IBufferzonePlanningMoveExistingPlanningPayload,
    IBufferzonePlanningCancelExistingPlanningPayload,
} from '../../../models/interventions/bufferzones';
import { createEpic } from '../..';
import ROUTE_KEYS from '../../../routeKeys';
import { getPlanBufferzoneWizardSteps } from '../../../config/navigation/wizardStepsMap';
import {
    triggerPlanBufferzoneWizard,
    fetchBufferzoneTimeslotsActions,
    skipToPlanBufferzoneWizardStepActions,
    resetPlanBufferzoneWizardEntity,
    createPlanBufferzoneWizardEntity,
    navigateToPlanBufferzoneWizardStep,
    bufferzonePlanningCreateNewPlanningActions,
    bufferzonePlanningCancelExistingPlanningActions,
    bufferzonePlanningMoveExistingPlanningActions,
    updatePlanBufferzoneWizardEntity,
} from './actions';
import { getPlanBufferzoneWizardStepId, getPlanBufferzoneWizardEntity } from './selectors';
import { fetchCompanyBufferzones } from '../../company/info/actions';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
    isAllSeatsSelected,
} from '../../company/selected/selectors';
import {
    fetchEmployeesToPlan,
    fetchEmployeesToPlanSucceeded,
    fetchEmployeesToPlanFailed,
    fetchExaminationReasons,
} from '../../medicalExamination/actions';
import { areExaminationReasonsAvailable } from '../../medicalExamination/selectors';
import {
    FETCH_BUFFERZONE_TIMESLOTS,
    SKIP_TO_PLAN_BUFFERZONE_WIZARD_STEP,
    BUFFERZONE_PLANNING_CREATE_NEW_PLANNING,
    BUFFERZONE_PLANNING_MOVE_EXISTING_PLANNING,
    BUFFERZONE_PLANNING_CANCEL_EXISTING_PLANNING,
} from './types';
import { getLocationState } from '../../location/selectors';
import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import { DEFAULT_COMPANY_BUFFERZONES_FILTERS } from '../../../api/admin/companyInfo.api';
import { IState } from '../../IState';
import { ArgumentAction } from 'redux-logic/definitions/action';
import { IFetchBufferzoneTimeslotsPayload } from '../../../models/interventions/timeslots';
import {
    IMedicalExaminationToAdd,
    IMedicalExaminationToPlan,
    IPlannedMedicalExamination,
} from '../../../models/interventions/medicalExaminations';
import {
    mapEmployeeToPlanToMedicalExaminationToAdd,
} from '../../../utils/interventions/medicalExaminations/mapEmployeesToPlanToMedicalExaminationsToAdd';
import { getMedExamToAddId } from '../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import { fetchCaseManagerActions } from '../../contact/actions';
import { isCaseManagerAvailable } from '../../contact/selectors';
import Api from '../../../api';
import { ITraceableApiError } from '../../../models/general/error';
import generateErrorId from '../../../utils/api/error/generateErrorId';
import HTTP_STATUS from '../../../utils/api/httpStatus';
import { formatTimeOfDateForDisplay } from '../../../utils/formatting/formatTime';
import isMainSeatCompanyCode from '../../../utils/administration/companyInfo/isMainSeatCompanyCode';
import { fetchConvocationRecipientsActions } from '../../employee/documents/actions';
import { createNotFoundError } from '../../../utils/api/error/createNotFoundError';

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the first step of the flow.
 */
// validatePlanBufferzoneWizardStepIdEpic
createEpic<IPlanBufferzoneWizardPayload>({
    onActionType: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const requestedStep = action.payload.step;
        const PLAN_BUFFERZONE_STEP_IDS = getPlanBufferzoneWizardSteps().stepIds;
        if (!PLAN_BUFFERZONE_STEP_IDS.includes(requestedStep)) {
            return next(triggerPlanBufferzoneWizard());
        }

        // check no step skipped
        const currentStep = getPlanBufferzoneWizardStepId(getState());
        const currentStepIndex = PLAN_BUFFERZONE_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = PLAN_BUFFERZONE_STEP_IDS.indexOf(requestedStep);
        if (!action.payload.skipToStep && requestedStepIndex > currentStepIndex + 1) {
            return next(triggerPlanBufferzoneWizard());
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringPlanBufferzoneWizardEpic
createEpic<IPlanBufferzoneWizardPayload>({
    onActionType: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
    async processMultiple({ action, getState, api }, dispatch, done) {
        const { step } = action.payload;
        const state = getState();

        if (step === PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_BUFFERZONE) {
            // do not refresh if only clientside (query) filtering changed
            const {
                type: prevActionType,
                payload: prevActionPayload,
                query: prevQuery,
            } = getLocationState(state).prev as {
                type: string;
                payload: IPlanBufferzoneWizardPayload;
                query: object;
            };

            const prevQueryWithDefaults = {
                ...DEFAULT_COMPANY_BUFFERZONES_FILTERS,
                ...prevQuery,
            };

            if (
                prevActionType !== ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES &&
                (
                    action.type !== prevActionType ||
                    step !== prevActionPayload.step ||
                    !areObjectParamsEqual(prevQueryWithDefaults, action.meta.query, ['startDate', 'endDate'])
                )
            ) {
                dispatch(fetchCompanyBufferzones.trigger({}));
            }

            return done();
        }

        if (step === PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_EMPLOYEES) {
            if (!areExaminationReasonsAvailable(state)) {
                dispatch(fetchExaminationReasons());
            }

            // do not refresh if only clientside (query) filtering changed
            const {
                type: prevActionType,
                payload: prevActionPayload,
            } = getLocationState(state).prev as {
                type: string;
                payload: IPlanBufferzoneWizardPayload;
            };

            if (action.type !== prevActionType || step !== prevActionPayload.step) {
                try {
                    const wizardEntity = getPlanBufferzoneWizardEntity(state);

                    const allSeatsSelected = isAllSeatsSelected(state);
                    const entity = getPlanBufferzoneWizardEntity(getState());

                    const bufferzoneBranchCode = path<string>(['selectedBufferzone', 'company', 'companyCode'], entity);

                    if (!bufferzoneBranchCode) {
                        console.warn('No bufferzone branch code could be found');
                        fetchEmployeesToPlanFailed(createNotFoundError());
                        return;
                    }

                    /*
                        During bufferzone planning, we only want to fetch the employees from the
                        logged in seat.
                        The only exception being when "all seats" are selected: Then we want to
                        retrieve the employees from the seat for which the bufferzone is ordered.
                    */
                    const companyCode = allSeatsSelected
                        ? bufferzoneBranchCode
                        : getSelectedSeatCompanyCode(state);

                    /*  During bufferzone planning, we only want to show all employees (showFullFamily)
                        if we are planning on a bufferzone created from the main seat when we are
                        logged in for all seats.
                        In all other cases we only want the selected seats employees
                    */
                    const showFullFamily = allSeatsSelected && isMainSeatCompanyCode(bufferzoneBranchCode);

                    const allowedExaminationReasonIds = (wizardEntity.selectedBufferzone.allowedExaminations
                        && wizardEntity.selectedBufferzone.allowedExaminations.length > 0)
                        ? wizardEntity.selectedBufferzone.allowedExaminations
                            .map((allowedExaminationReason) => allowedExaminationReason.id)
                        : null;

                    // Tirgger loading state
                    dispatch(fetchEmployeesToPlan({ companyCode, allowedExaminationReasonIds }));

                    const employeesToPlan = await api.interventions.medicalExaminations.fetchToPlan({
                        companyCode,
                        showFullFamily,
                        allowedExaminationReasonIds,
                        toBePlanned: true,
                    });

                    dispatch(fetchEmployeesToPlanSucceeded(employeesToPlan));
                } catch (error) {
                    dispatch(fetchEmployeesToPlanFailed(error));
                }
            }

            return done();
        }

        if (step === PLAN_BUFFERZONE_WIZARD_STEP_ID.PLANNING) {
            dispatch(bufferzonePlanningCreateNewPlanningActions.reset({}));
            dispatch(bufferzonePlanningMoveExistingPlanningActions.reset({}));
            dispatch(bufferzonePlanningCancelExistingPlanningActions.reset({}));

            if (!areExaminationReasonsAvailable(state)) {
                dispatch(fetchExaminationReasons());
            }
            if (!isCaseManagerAvailable(state)) {
                dispatch(fetchCaseManagerActions.trigger({}));
            }

            dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            return done();
        }

        if (step === PLAN_BUFFERZONE_WIZARD_STEP_ID.OVERVIEW) {
            const {
                payload: prevActionPayload,
            } = getLocationState(state).prev as {
                payload: IPlanBufferzoneWizardPayload;
            };

            dispatch(fetchConvocationRecipientsActions.trigger({}));

            if (
                prevActionPayload && prevActionPayload.step !== PLAN_BUFFERZONE_WIZARD_STEP_ID.PLANNING
            ) {
                dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            }

            return done();
        }

        done();
    },
    latest: false,
});

// FetchBufferzoneTimeslots
createEpic<IFetchBufferzoneTimeslotsPayload>({
    onActionType: FETCH_BUFFERZONE_TIMESLOTS,
    async processReturn({ api, action, getState }) {
        try {
            const response = await api.interventions.timeslots.fetchBufferzoneTimeslots(action.payload);
            return fetchBufferzoneTimeslotsActions.succeeded(response);
        } catch (error) {
            return fetchBufferzoneTimeslotsActions.failed(error);
        }
    },
    latest: true,
});

// Skip to plan bufferzone wizard step
createEpic<ISkipToPlanBufferzoneWizardStepPayload>({
    onActionType: SKIP_TO_PLAN_BUFFERZONE_WIZARD_STEP,
    async processMultiple({ action }, dispatch, done) {
        try {
            const payload = action.payload;

            dispatch(resetPlanBufferzoneWizardEntity());
            dispatch(createPlanBufferzoneWizardEntity(payload.entity));
            dispatch(navigateToPlanBufferzoneWizardStep({
                step: getWizardStepFromPayloadOrGetFirstVisibleStepFromFlow(payload, payload.entity),
                skipToStep: true,
                resetDataEntity: false,
            }));
            dispatch(skipToPlanBufferzoneWizardStepActions.succeeded({}));
        } catch (error) {
            dispatch(skipToPlanBufferzoneWizardStepActions.failed(error));
        }
        done();
    },
    latest: false,
});

function getWizardStepFromPayloadOrGetFirstVisibleStepFromFlow(
    payload: ISkipToPlanBufferzoneWizardStepPayload,
    entityData: IPlanBufferzoneWizardEntity,
) {
    if (payload.wizardPayload.step) {
        return payload.wizardPayload.step;
    }
    const wizardSteps = getPlanBufferzoneWizardSteps().steps;
    const firstVisibleStep = wizardSteps.find((step) => {
        if (typeof step.hide === 'function') {
            return !step.hide(entityData);
        }
        return true;
    });
    return firstVisibleStep && firstVisibleStep.id as PLAN_BUFFERZONE_WIZARD_STEP_ID;
}

// Create new planning
createEpic<IBufferzonePlanningCreateNewPlanningPayload>({
    onActionType: BUFFERZONE_PLANNING_CREATE_NEW_PLANNING,
    async processMultiple({ action, getState, api }, dispatch, done) {
        try {
            const { employeeToPlan, timeslot } = action.payload;

            const state = getState();
            const entity = getPlanBufferzoneWizardEntity(state);

            const planningRecordId = await determineThePlanningRecordIdWhichPossiblyGetsOverruled({
                employeeToPlan,
                state,
                api,
            });

            const baseParams = {
                company: {
                    companyCode: employeeToPlan.company.companyCode,
                    id: employeeToPlan.company.id,
                },
                employee: {
                    employeeId: employeeToPlan.employee.employeeId,
                    id: employeeToPlan.employee.id,
                },
                planningEntityId: timeslot.planningEntityId,
                remarks: '',
                timeCellId: timeslot.id,
            };

            if (planningRecordId) {
                await api.interventions.timeslots.addTimeslotByPlanningRecordId({
                    ...baseParams,
                    planningRecordId,
                });
            } else {
                await api.interventions.timeslots.addTimeslot({
                    ...baseParams,
                    examinationReason: {
                        id: employeeToPlan.examinationReason.id,
                    },
                });
            }

            const newEmployeesToPlan = entity && entity.employeesToPlan ? [...entity.employeesToPlan] : [];

            const indexOfEmployee = newEmployeesToPlan.findIndex((item) =>
                getMedExamToAddId(item) === getMedExamToAddId(employeeToPlan));

            if (indexOfEmployee !== -1) {
                newEmployeesToPlan.splice(indexOfEmployee, 1);
                dispatch(updatePlanBufferzoneWizardEntity({
                    employeesToPlan: entity.employeesToPlan ? newEmployeesToPlan : [],
                }));
            }

            dispatchUpdateWizardEntityWithModifiedTimeCellIdAction({ getState, timeCellId: timeslot.id, dispatch });
            dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            dispatch(bufferzonePlanningCreateNewPlanningActions.succeeded({}));
        } catch (error) {
            dispatch(bufferzonePlanningCreateNewPlanningActions.failed(error));
        }
        done();
    },
    latest: false,
});

/**
 * When planning, we possibly have to overrule the planningRecordId.
 * Reason:
 *   - when cancelling, a new planning record id is always created, but this is asynchronously,
 *     so we don't know this yet (the cancel call does not return it)
 *   - so when we re-plan that block, it would be with an old planningRecordId, or without
 *     a planningRecordId in case the employee was manually added
 * Therefore we fetch the 'to-plan' records for the employee to compare.
 * See KZUAT-1368
 */
async function determineThePlanningRecordIdWhichPossiblyGetsOverruled({
    employeeToPlan,
    state,
    api,
}: {
    employeeToPlan: IMedicalExaminationToAdd;
    state: IState;
    api: typeof Api;
}): Promise<number> {
    const origPlanningRecordId = employeeToPlan.planningRecordId;

    const toPlanRecordsForEmployee = await api.interventions.medicalExaminations.fetchToPlan({
        companyCode: getSelectedSeatCompanyCode(state),
        showFullFamily: getSelectedCompanySeat(state).isAllSeatsSelected,
        employeeCustomerId: employeeToPlan.employee.id,
    });
    const areTherePlanRecordsForEmployee = toPlanRecordsForEmployee
        && Array.isArray(toPlanRecordsForEmployee)
        && toPlanRecordsForEmployee.length > 0;

    if (areTherePlanRecordsForEmployee) {
        if (origPlanningRecordId) {
            const matchOnPlanningRecordId = toPlanRecordsForEmployee.find((examinationToPlan) =>
                examinationToPlan.planningRecordId === origPlanningRecordId);

            if (matchOnPlanningRecordId) {
                /* The initial planningRecordId is still to-be-planned */
                return origPlanningRecordId;
            }
        }

        const matchOnEmployeeAndReason = toPlanRecordsForEmployee.find((examinationToPlan) =>
            examinationToPlan.employee.id === employeeToPlan.employee.id
            && examinationToPlan.examinationReason.id === employeeToPlan.examinationReason.id);

        if (matchOnEmployeeAndReason) {
            /* We take another planningRecordId that matched the employee and reason
               - either there was initially no planningRecordId (manually added employee) but after
                 canceling an "unexpected" planningRecordId was created by the mensura backend
               - OR either the initial planningRecordId is not valid anymore because the planned
                 examination was cancelled again (which results in a new planningRecord) */
            return matchOnEmployeeAndReason.planningRecordId;
        }
    }

    if (origPlanningRecordId) {
        /* The initial planningRecordId is not to-be-planned anymore + no valid replacement found */
        const error: ITraceableApiError = {
            id: generateErrorId(),
            code: HTTP_STATUS.NOT_FOUND,
            message: 'interventions.plan_bufferzone.steps.planning.error.planning_record_id_not_found',
            extraData: {},
            requestMethod: '-',
            wasCancelled: false,
            type: 'api',
        };
        throw error;
    }

    /* No initial planningRecordId + no matching planningRecord found */
    return null;
}

// Move existing planning
createEpic<IBufferzonePlanningMoveExistingPlanningPayload>({
    onActionType: BUFFERZONE_PLANNING_MOVE_EXISTING_PLANNING,
    async processMultiple({ action, getState, api }, dispatch, done) {
        try {
            const { examination, newTimeslot } = action.payload;

            await api.interventions.timeslots.updateTimeslot({
                company: {
                    companyCode: examination.company.companyCode,
                    id: null,
                },
                remarks: '',
                newTimeCellId: newTimeslot.id,
                examinationReason: {
                    id: examination.examinationReason.id,
                },
                timeCellId: examination.timeCell.id,
            });

            dispatchUpdateWizardEntityWithModifiedTimeCellIdAction({ getState, timeCellId: newTimeslot.id, dispatch });
            dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            dispatch(bufferzonePlanningMoveExistingPlanningActions.succeeded({}));
        } catch (error) {
            dispatch(bufferzonePlanningMoveExistingPlanningActions.failed(error));
        }
        done();
    },
    latest: false,
});

// Cancel existing planning
createEpic<IBufferzonePlanningCancelExistingPlanningPayload>({
    onActionType: BUFFERZONE_PLANNING_CANCEL_EXISTING_PLANNING,
    async processMultiple({ action, api, getState }, dispatch, done) {
        try {
            const state = getState();
            const entity = getPlanBufferzoneWizardEntity(state);
            const { examination } = action.payload;

            await api.interventions.timeslots.removeTimeslot({
                cancelDate: examination.time,
                employee: {
                    id: examination.employee.id,
                },
                timeCellId: examination.timeCell.id,
            });

            const cancelledPlanningToMedExamToPlan = mapPlannedExaminationToMedicalExaminationToPlan(examination);
            const cancelledPlanningToMedExamToAdd =
                mapEmployeeToPlanToMedicalExaminationToAdd(cancelledPlanningToMedExamToPlan);

            dispatch(updatePlanBufferzoneWizardEntity({
                addedEmployees: addCancelledPlanningToAddedEmployeesIfNotAlreadyThere({
                    addedEmployees: entity.addedEmployees,
                    cancelledPlanning: cancelledPlanningToMedExamToPlan,
                }),
                employeesToPlan: entity.employeesToPlan ? [
                    ...entity.employeesToPlan,
                    cancelledPlanningToMedExamToAdd,
                ] : [cancelledPlanningToMedExamToAdd],
            }));

            dispatchRefetchBufferzoneTimeslotsActions({ getState, dispatch });
            dispatch(bufferzonePlanningCancelExistingPlanningActions.succeeded({}));
        } catch (error) {
            dispatch(bufferzonePlanningCancelExistingPlanningActions.failed(error));
        }
        done();
    },
    latest: false,
});

function addCancelledPlanningToAddedEmployeesIfNotAlreadyThere({
    addedEmployees,
    cancelledPlanning,
}: {
    addedEmployees: IMedicalExaminationToPlan[];
    cancelledPlanning: IMedicalExaminationToPlan;
}): IMedicalExaminationToPlan[] {
    if (addedEmployees) {
        if (addedEmployees.find((alreadyAddedEmployee) =>
            alreadyAddedEmployee.employee.id === cancelledPlanning.employee.id
            && alreadyAddedEmployee.examinationReason.id === cancelledPlanning.examinationReason.id)
        ) {
            return addedEmployees;
        }

        return [
            ...addedEmployees,
            cancelledPlanning,
        ];
    }

    return [cancelledPlanning];
}

function dispatchRefetchBufferzoneTimeslotsActions({
    getState,
    dispatch,
}: {
    getState: () => IState;
    dispatch: (action: ArgumentAction) => void;
}) {
    const state = getState();
    const entity = getPlanBufferzoneWizardEntity(state);
    const selectedBufferzone = entity.selectedBufferzone;
    const companyCode = selectedBufferzone && selectedBufferzone.company.companyCode;

    dispatch(fetchBufferzoneTimeslotsActions.trigger({
        /*
            During bufferzone planning, we want to fetch timeslots for the bufferzones seat,
            not the seat selected in the application
        */
        companyCode,
        /*
            During bufferzone planning, we only want to show all timeslots (showFullFamily)
            if we are planning on a bufferzone created from the main seat.
            In all other cases we only want the selected seats timeslots
            (including all timeslots from its underlying divisions)
        */
        showFullFamily: isMainSeatCompanyCode(companyCode),
        medicalCenterId: selectedBufferzone && selectedBufferzone.medicalCenter.id,
        startDate: selectedBufferzone && selectedBufferzone.date,
        endDate: selectedBufferzone && selectedBufferzone.date,
        startTime: selectedBufferzone && formatTimeOfDateForDisplay(selectedBufferzone.startTime),
        endTime: selectedBufferzone && formatTimeOfDateForDisplay(selectedBufferzone.endTime),
        duration: selectedBufferzone && selectedBufferzone.duration,
        planningEntityId: selectedBufferzone && selectedBufferzone.planningEntityId,
    }));
}

function dispatchUpdateWizardEntityWithModifiedTimeCellIdAction({
    timeCellId,
    getState,
    dispatch,
}: {
    timeCellId: number;
    getState: () => IState;
    dispatch: (action: ArgumentAction) => void;
}) {
    const state = getState();
    const wizardEntity = getPlanBufferzoneWizardEntity(state);
    const currentModifiedPlanningEntityIds = wizardEntity.modifiedTimeCellIds || [];

    if (currentModifiedPlanningEntityIds.find((id) => id === timeCellId)) {
        return null;
    }

    dispatch(updatePlanBufferzoneWizardEntity({
        modifiedTimeCellIds: [
            ...currentModifiedPlanningEntityIds,
            timeCellId,
        ],
    }));
}

function mapPlannedExaminationToMedicalExaminationToPlan(examination: IPlannedMedicalExamination) {
    const cancelledEmployeeToEmployeeToPlan: IMedicalExaminationToPlan = {
        employee: {
            id: examination.employee.id,
            employeeId: examination.employee.employeeId,
            firstName: examination.firstName,
            name: examination.name,
            birthDate: null,
            dateOutOfService: null,
        },
        company: {
            name: examination.company.name,
            companyCode: examination.company.companyCode,
            id: examination.company.id,
        },
        function: {
            id: null,
            description: null,
        },
        planningRecordId: null,
        medicalCenter: null,
        examinationReason: examination.examinationReason,
        duration: examination.duration && Number(examination.duration),
        rescheduleAbsent: false,
        toBePlannedDate: null,
        toBePlanned: true,
        absent: null,
        absentDescription: null,
    };
    return cancelledEmployeeToEmployeeToPlan;
}
