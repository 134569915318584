import React, { PureComponent } from 'react';
import clone from 'ramda/src/clone';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IShouldRenderShowAllButtonProps,
    IRenderSearchContentProps,
    ITransformToActiveFiltersProps,
    IRenderDetailContentProps,
    IRenderDetailHeaderProps,
    IRenderFilterContentProps,
    IClientSideFilterOfListDataProps,
    IDetailConfig,
    IRenderDetailFooterProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../../routeKeys';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import ListWithSorting from '../../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import {
    getFetchCompanyBufferzonesAsyncInfo,
    getCompanyBufferzones,
    getSelectedCompanyBufferzone,
    getSelectedBufferzonePlanningEntityId,
} from '../../../../redux/company/info/selectors';
import { ICompanyBufferzone } from '../../../../models/admin/company';
import { formatStartEndTimeOfDatesForDisplay } from '../../../../utils/formatting/formatTime';
import BufferzoneDetailContent, { BufferzoneDetailHeader } from './detail';
import FreeSlotsIndicator from '../../../common/widget/FreeSlotsIndicator';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { DEFAULT_COMPANY_BUFFERZONES_FILTERS } from '../../../../api/admin/companyInfo.api';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import {
    getSelectedEmployeeAsyncInfo,
    getSelectedEmployeeId,
    getSelectedEmployee,
} from '../../../../redux/employee/info/selectors';
import { IEmployeeDetails, IEmployee } from '../../../../models/admin/employee';
import {
    EmployeeDetailsHeader,
    EmployeeDetailsContent,
    EmployeeDetailsOverlay,
} from '../../../administration/Employees/EmployeeDetails';
import { connect } from '../../..';
import { EmployeeDetailsOverlayType } from '../../../administration/Employees/EmployeeDetails/common';
import {
    getSelectedPlannedMedicalExaminationAsyncInfo,
    getSelectedPlannedMedicalExamination,
    getSelectedExecutedMedicalExaminationAsyncInfo,
    getSelectedExecutedMedicalExamination,
} from '../../../../redux/medicalExamination/selectors';
import {
    IPlannedMedicalExamination,
    IExecutedMedicalExamination,
} from '../../../../models/interventions/medicalExaminations';
import {
    DetailHeader as DetailHeaderPlanned,
    DetailContent as DetailContentPlanned,
} from '../Planned/detail';
import {
    DetailHeader as DetailHeaderExecuted,
    DetailContent as DetailContentExecuted,
} from '../Executed/detail';
import {
    getCoursesPlannedAsyncInfo,
    getSelectedPlannedCourse,
    getCoursesFollowedAsyncInfo,
    getSelectedFollowedCourse,
} from '../../../../redux/documentCenter/courses/selectors';
import { ICourse } from '../../../../models/documentCenter/courses';
import DetailContentCoursesPlanned from '../../../documentCenter/Courses/Planned/detail/content';
import DetailFooterCoursesPlanned from '../../../documentCenter/Courses/Planned/detail/footer';
import DetailHeaderCourses from '../../../documentCenter/Courses/shared/header';
import DetailContentCoursesFollowed from '../../../documentCenter/Courses/Followed/detail/content';
import DetailFooterCoursesFollowed from '../../../documentCenter/Courses/Followed/detail/footer';
import { skipToPlanBufferzoneWizardStepActions } from '../../../../redux/intervention/bufferzones/actions';
import {
    PLAN_BUFFERZONE_WIZARD_STEP_ID,
    IPlanBufferzoneWizardEntity,
} from '../../../../models/interventions/bufferzones';
import ShowIfAllowed from '../../../auth/ShowIfAllowed';
import ListItemActions from '../../../common/list/ListItemActions';
import Button from '../../../common/buttons/Button';
import Icon from '../../../common/icons/Icon';
import { hoursOffsetFromNow, getDate } from '../../../../utils/core/date/getSpecificDate';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../config/medicalExamination.config';
import { formatMedicalCenterAddress } from '../../../../utils/formatting/formatAddress';

const TRANSLATION_PREFIX = 'interventions.medical_examinations.bufferzones';
const BASE_NAME = 'bufferzones';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

const PLANNED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL,
    asyncInfoSelector: getSelectedPlannedMedicalExaminationAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: getSelectedPlannedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IPlannedMedicalExamination>) =>
        <DetailHeaderPlanned {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IPlannedMedicalExamination>) =>
        <DetailContentPlanned {...renderProps} />,
};
const EXECUTED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL,
    asyncInfoSelector: getSelectedExecutedMedicalExaminationAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: getSelectedExecutedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IExecutedMedicalExamination>) =>
        <DetailHeaderExecuted {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IExecutedMedicalExamination>) =>
        <DetailContentExecuted {...renderProps} />,
};

const PLANNED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL,
    asyncInfoSelector: getCoursesPlannedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: getSelectedPlannedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) =>
        <DetailContentCoursesPlanned {...renderProps} />,
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesPlanned {...renderProps} />,
};

const FOLLOWED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL,
    asyncInfoSelector: getCoursesFollowedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: getSelectedFollowedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) => (
        <DetailContentCoursesFollowed
            {...renderProps}
            followedCoursesAttendantRouteKey={ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL_ATTENDANT}
        />),
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesFollowed {...renderProps} />,
};

interface IPrivateProps {
    selectedPlanningEntityId: string;
    triggerPlanBufferzoneWizardWithSelectedBufferzone: (planningEntityId: number) => void;
}

interface IColumnNames {
    location: string;
    locationId: string;
    seat: string;
    seatCode: string;
    date: string;
    dateSort: string;
    time: string;
    freeSlots: string;
    totalSlots: string;
    actions: string;
    endTime: string;
}

type TFilterColumnNames =
    Pick<
        IColumnNames,
        'location' | 'locationId' | 'seat' | 'seatCode' | 'date' | 'dateSort' |
        'time' | 'freeSlots' | 'totalSlots'>;

export interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
    locationIds: string;
    seatCodes: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'date',
    sortOrder: SortOrder.Ascending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    location: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.location`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 36,
    },
    locationId: {
        hide: true,
        percentWidth: null,
    },
    seat: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    seatCode: {
        hide: true,
        percentWidth: null,
    },
    date: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem) => listItem.columns.dateSort,
        percentWidth: 18,
    },
    dateSort: {
        hide: true,
        percentWidth: null,
    },
    time: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.time`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    freeSlots: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.free_slots`} />,
        sortable: true,
        sortType: SortType.Number,
        sortValue: (listItem) => listItem.columns.freeSlots,
        percentWidth: null,
        minWidth: 150,
    },
    totalSlots: {
        hide: true,
        percentWidth: null,
    },
    actions: {
        percentWidth: 6,
        sortable: false,
    },
    endTime: {
        percentWidth: null,
        sortable: false,
    },
};

function Bufferzones(props: IPrivateProps) {
    const { selectedPlanningEntityId, triggerPlanBufferzoneWizardWithSelectedBufferzone } = props;
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES,
                asyncInfoSelector: getFetchCompanyBufferzonesAsyncInfo,
                dataSelector: getCompanyBufferzones,
                transformData: mapBufferzonesToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                    <BufferzonesList {...renderProps} {...props} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                clientSideFilterOfListData,
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'bufferzones',
                    listItemIdExtractor: toListId,
                    mapListRowForExport,
                },
            }}
            detailConfig={{
                levels: [
                    {
                        level: 1,
                        details: [{
                            routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL,
                            asyncInfoSelector: getFetchCompanyBufferzonesAsyncInfo,
                            idRouteParamName: 'planningEntityId',
                            dataSelector: getSelectedCompanyBufferzone,
                            renderHeader: (renderProps: IRenderDetailHeaderProps<ICompanyBufferzone>) =>
                                <BufferzoneDetailHeader {...renderProps} />,
                            renderContent: (renderProps: IRenderDetailContentProps<ICompanyBufferzone>) =>
                                <BufferzoneDetailContent
                                    {...renderProps}
                                    triggerPlanBufferzoneWizardWithSelectedBufferzone={
                                        triggerPlanBufferzoneWizardWithSelectedBufferzone
                                    }
                                />,
                        }],
                    },
                    {
                        level: 2,
                        details: [{
                            routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL,
                            asyncInfoSelector: getSelectedEmployeeAsyncInfo,
                            idSelector: getSelectedEmployeeId,
                            dataSelector: getSelectedEmployee,
                            renderHeader: (
                                renderProps: IRenderDetailHeaderProps<IEmployee & IEmployeeDetails>,
                            ) =>
                                <EmployeeDetailsHeader {...renderProps} />,
                            renderContent: (
                                renderProps: IRenderDetailContentProps<IEmployee & IEmployeeDetails>,
                            ) => selectedPlanningEntityId &&
                                <EmployeeDetailsContent
                                    {...renderProps}
                                    // eslint-disable-next-line max-len
                                    plannedMedicalExaminationRouteKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL}
                                    // eslint-disable-next-line max-len
                                    executedMedicalExaminationRouteKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL}
                                    // eslint-disable-next-line max-len
                                    followedCoursesRouteKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL}
                                    // eslint-disable-next-line max-len
                                    plannedCoursesRouteKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL}
                                    extraRoutePayload={{
                                        planningEntityId: selectedPlanningEntityId,
                                    }}
                                />,
                            renderOverlay: ({
                                overlayType, closeOverlay, onSave,
                            }) =>
                                <EmployeeDetailsOverlay
                                    overlayType={overlayType as EmployeeDetailsOverlayType}
                                    closeOverlay={closeOverlay}
                                    onSave={onSave}
                                />,
                        }],
                    },
                    {
                        level: 3,
                        details: [
                            PLANNED_EXAMINATION_DETAIL_CONFIG,
                            EXECUTED_EXAMINATION_DETAIL_CONFIG,
                            PLANNED_COURSES_DETAIL_CONFIG,
                            FOLLOWED_COURSES_DETAIL_CONFIG,
                        ],
                    },
                ],
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            selectedPlanningEntityId: getSelectedBufferzonePlanningEntityId(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            triggerPlanBufferzoneWizardWithSelectedBufferzone: (planningEntityId: number) => {
                const bufferzones = getCompanyBufferzones(getState());
                const bufferzone = bufferzones.find((bufferzone) => bufferzone.planningEntityId === planningEntityId);

                if (!bufferzone) {
                    return;
                }

                dispatch(skipToPlanBufferzoneWizardStepActions.trigger({
                    wizardPayload: {
                        step: PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_EMPLOYEES,
                    },
                    entity: {
                        selectedBufferzone: {
                            ...bufferzone,
                            selectedFromOverview: true,
                        },
                    } as IPlanBufferzoneWizardEntity,
                }));
            },
        };
    },
})(Bufferzones);

function toListId(bufferzone: ICompanyBufferzone) {
    return bufferzone.planningEntityId;
}

function mapBufferzonesToListItems(masterData: ICompanyBufferzone[]): ListItem<IColumnNames>[] {
    return masterData
        .map((bufferzone) => ({
            id: toListId(bufferzone),
            columns: {
                location: formatMedicalCenterAddress(bufferzone.medicalCenter, true),
                locationId: bufferzone.medicalCenter.id,
                seat: bufferzone.company.name,
                seatCode: bufferzone.company.companyCode,
                date: formatDateInLongFormat(bufferzone.date),
                dateSort: bufferzone.startTime,
                time: formatStartEndTimeOfDatesForDisplay(bufferzone.startTime, bufferzone.endTime),
                endTime: bufferzone.endTime,
                freeSlots: bufferzone.medicalCenter.freeSlots,
                totalSlots: bufferzone.medicalCenter.totalSlots,
                actions: null,
            },
        }));
}

function mapListRowForExport(bufferzone: ICompanyBufferzone) {
    const {
        date,
        startTime,
        endTime,
        company: { name: companyName },
        company: { companyCode },
        medicalCenter: {
            name: medicalCenterName,
            freeSlots,
            totalSlots,
            address: {
                city,
                postcode,
                street,
            },
        },
    } = bufferzone;

    return {
        company: {
            companyCode,
            name: companyName,
        },
        medicalCenter: {
            name: medicalCenterName,
            address: {
                city,
                postcode,
                street,
            },
            freeSlots,
            totalSlots,
        },
        date,
        startTime,
        endTime,
    };
}

class BufferzonesList extends
    PureComponent<IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues> & IPrivateProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues> & IPrivateProps) {
        super(props);

        this.renderActions = this.renderActions.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
            onItemSelected,
        } = this.props;

        this.columns.freeSlots.render = this.renderFreeSlots;
        this.columns.actions.render = this.renderActions;

        return (
            <ListWithSorting
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemIds={selectedItemId ? [selectedItemId] : []}
                initialSort={INITIAL_SORT}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                footer={footer}
                onItemRowClicked={onItemSelected}
            />
        );
    }

    private renderActions(listItem: ListItem<IColumnNames>) {
        const { triggerPlanBufferzoneWizardWithSelectedBufferzone } = this.props;

        if (
            hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() >
            getDate(listItem.columns.endTime as string)
        ) {
            return <span/>;
        }

        return (
            <ListItemActions>
                <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                    <Button
                        id={`plan-bufferzone-${listItem.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            triggerPlanBufferzoneWizardWithSelectedBufferzone(listItem.id as number);
                        }}
                    >
                        <span>
                            <Translate msg={`${TRANSLATION_PREFIX}.actions.plan_bufferzone`} />
                        </span>
                        <Icon circle typeName="calendar" />
                    </Button>
                </ShowIfAllowed>
            </ListItemActions>
        );
    }

    private renderFreeSlots(item: ListItem<IColumnNames>) {
        return (
            <FreeSlotsIndicator
                maxSlots={item.columns.totalSlots as number}
                freeSlots={item.columns.freeSlots as number}
                showMaxSlots
            />
        );
    }
}

export function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.locationIds)) {
                return true;
            }

            const locationIds = separateStringList(filterValues.locationIds);
            return locationIds.includes(listItem.columns.locationId.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.seatCodes)) {
                return true;
            }

            const reasonIds = separateStringList(filterValues.seatCodes);
            return reasonIds.includes(listItem.columns.seatCode.toString());
        });
}

export function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.medical_examinations.bufferzones.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.medical_examinations.bufferzones.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

export function FilterContent(renderProps: IRenderFilterContentProps<ListItem<TFilterColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        // translator,
        masterData: allListItems,
    } = renderProps;

    const possibleLocations = getUniqueTypeaheadFilterValuesFromListItems<TFilterColumnNames>(
        allListItems,
        'locationId',
        'location',
    );
    const possibleSeats = getUniqueTypeaheadFilterValuesFromListItems<TFilterColumnNames>(
        allListItems,
        'seatCode',
        'seat',
    );

    return (
        <div>
            <CheckboxesOrTypeaheadFilter
                filterName="location"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.location`}
                possibleFilterItems={possibleLocations}
                actualFilterValue={formRenderProps.values.locationIds}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'locationIds',
                    newFilterValue,
                )}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="reason"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.seat`}
                possibleFilterItems={possibleSeats}
                actualFilterValue={formRenderProps.values.seatCodes}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'seatCodes',
                    newFilterValue,
                )}
            />

            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
            />
        </div>
    );
}

export function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_COMPANY_BUFFERZONES_FILTERS,
        isShowAll,
    } : DEFAULT_COMPANY_BUFFERZONES_FILTERS;
}

export function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.medical_examinations.bufferzones.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            locationIds: {
                show: true,
                translationKeySuffixOverride: 'location',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'location',
                        searchColumnName: 'locationId',
                    },
                },
            },
            seatCodes: {
                show: true,
                translationKeySuffixOverride: 'seat',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'seat',
                        searchColumnName: 'seatCode',
                    },
                },
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_COMPANY_BUFFERZONES_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_COMPANY_BUFFERZONES_FILTERS.startDate,
            },
        },
    });
}

export function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}
