import React, { Component, ReactElement } from 'react';
import clone from 'ramda/src/clone';
import { ICompanySeat } from '../../../models/admin/company';
import { ITranslator } from '../../../models/general/i18n';
import { ListColumns, SortType, SortOrder, ISortedColumn, ListItem } from '../../../models/general/list';
import { IAsyncFieldInfo, AsyncStatus } from '../../../models/general/redux';
import { connect } from '../../index';
import { getSelectedCompany } from '../../../redux/company/selected/selectors';
import { getTranslatorDeprecated } from '../../../redux/i18n/selectors';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import ListActions from '../../common/list/ListActions';
import ListWithRadioButtonSelect from '../../common/list/ListWithRadioButtonSelect';
import Loader from '../../common/waiting/Loader';
import Translate from '../../common/Translate';
import { formatAddressStreet, formatAddressCity } from '../../../utils/formatting/formatAddress';
import filterListItems from '../../../utils/list/filterListItems';
import { IListFooterProps } from '../../common/list/ListFooter';
import ListWithSorting from '../../common/list/ListWithSorting';

interface IPrivateProps {
    companyCode: string;
    listItems: ListItem<IColumnNames>[];
    translator: ITranslator;
}

interface ISeatsListProps {
    name: string;
    selectedSeatCompanyCode: string;
    isAllSeatsSelected?: boolean;
    onSeatSelected: (companyCode: string, isAllSeatsSelected?: boolean) => void;
    withSelectAll?: boolean;
    initialSearchValue?: string;
    companySeats: ICompanySeat[];
    fetchAsyncInfo: IAsyncFieldInfo;
    onSearchInput?: (searchValue: string) => void;
    footer?: ReactElement<IListFooterProps>;
    hideListActions?: boolean;
    listWithoutRadioButtonSelect?: boolean;
}

interface IState {
    filteredListItems: ListItem<IColumnNames>[];
    searchValue: string;
}

interface IColumnNames {
    name: string;
    companyCode: string;
    street: string;
    city: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    name: {
        label: <Translate msg="common.seats_list.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 38,
    },
    companyCode: {
        label: <Translate msg="common.seats_list.columns.company_code" />,
        sortable: true,
        sortType: SortType.DotSeparatedNumber,
        percentWidth: 14,
    },
    street: {
        label: <Translate msg="common.seats_list.columns.street" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 26,
    },
    city: {
        label: <Translate msg="common.seats_list.columns.city" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'name',
    sortOrder: SortOrder.Ascending,
};

const CLASS_NAME = 'SeatsList';

class Seat extends Component<IPrivateProps & ISeatsListProps, IState> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);
    constructor(props: IPrivateProps & ISeatsListProps) {
        super(props);

        this.state = {
            filteredListItems: props.listItems,
            searchValue: props.initialSearchValue,
        };

        this.onSearchInput = this.onSearchInput.bind(this);
        this.onToggleSelectAll = this.onToggleSelectAll.bind(this);
    }

    public render() {
        const {
            name,
            translator, selectedSeatCompanyCode, onSeatSelected,
            withSelectAll, isAllSeatsSelected, initialSearchValue,
            fetchAsyncInfo, footer, hideListActions, listWithoutRadioButtonSelect,
        } = this.props;
        const { filteredListItems } = this.state;

        return (
            <>
                {!hideListActions && <ListActions
                    name={name}
                    withSelectAll={withSelectAll}
                    onToggleSelectAll={this.onToggleSelectAll}
                    selectAllLabel={<Translate msg="common.seats_list.select_all" />}
                    selectAllChecked={isAllSeatsSelected}
                    selectAllDisabled={
                        !!fetchAsyncInfo.error ||
                        fetchAsyncInfo.status === AsyncStatus.Busy ||
                        filteredListItems.length === 0
                    }
                    withSearch={true}
                    searchPlaceholder={translator('common.seats_list.search_placeholder')}
                    onSearchInput={this.onSearchInput}
                    initialSearchValue={initialSearchValue}
                    searchDisabled={!!fetchAsyncInfo.error}
                />}
                <div className={`${CLASS_NAME}__list`}>
                    {listWithoutRadioButtonSelect ? (
                        <ListWithSorting
                            initialSort={INITIAL_SORT}
                            name={name}
                            columns={this.columns}
                            items={filteredListItems}
                            onItemRowClicked={onSeatSelected}
                            selectedItemIds={!isAllSeatsSelected && selectedSeatCompanyCode ?
                                [selectedSeatCompanyCode] : []}
                            errorMessage={fetchAsyncInfo.error &&
                                <ErrorPlaceholder
                                    typeName="block"
                                    apiError={fetchAsyncInfo.error}
                                />
                            }
                            fadeOutListItems={isAllSeatsSelected}
                            footer={footer}
                        />
                    ) : (
                            <ListWithRadioButtonSelect
                                withSorting={true}
                                initialSort={INITIAL_SORT}
                                name={name}
                                columns={this.columns}
                                items={filteredListItems}
                                onItemSelected={onSeatSelected}
                                selectedItemId={!isAllSeatsSelected && selectedSeatCompanyCode ?
                                    selectedSeatCompanyCode : null}
                                errorMessage={fetchAsyncInfo.error &&
                                    <ErrorPlaceholder
                                        typeName="block"
                                        apiError={fetchAsyncInfo.error}
                                    />
                                }
                                fadeOutListItems={isAllSeatsSelected}
                                footer={footer}
                            />
                        )}

                    <Loader show={fetchAsyncInfo.status} />
                </div>
            </>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps & ISeatsListProps) {
        const { listItems } = this.props;
        if (prevProps.listItems !== listItems) {
            const newFilteredListItems = filterListItems(listItems, this.state.searchValue);
            this.setState({
                filteredListItems: newFilteredListItems,
            });
        }
    }

    private onSearchInput(searchValue: string) {
        const newFilteredListItems = filterListItems(this.props.listItems, searchValue);
        this.setState({
            filteredListItems: newFilteredListItems,
            searchValue,
        });
        if (typeof this.props.onSearchInput === 'function') {
            this.props.onSearchInput(searchValue);
        }
    }

    private onToggleSelectAll(checked: boolean) {
        const { companyCode, companySeats, onSeatSelected } = this.props;
        if (checked) {
            const mainSeat: ICompanySeat = companySeats.find((seat) => {
                return seat.company.companyCode === companyCode;
            });
            onSeatSelected(mainSeat && mainSeat.company.companyCode, true);
        } else {
            onSeatSelected(null, false);
        }
    }
}

export default connect<IPrivateProps, ISeatsListProps>({
    statePropsDeprecated: (state, pulicProps) => {
        const selectedCompany = getSelectedCompany(state);
        return {
            companyCode: selectedCompany && selectedCompany.companyCode,
            listItems: mapCompanySeats(pulicProps.companySeats),
            translator: getTranslatorDeprecated(state),
        };
    },
})(Seat);

function mapCompanySeats(companySeats: ICompanySeat[]) {
    return companySeats.map((companySeat) => {
        return {
            id: companySeat.company.companyCode,
            columns: {
                name: companySeat.company.name,
                companyCode: companySeat.company.companyCode,
                street: formatAddressStreet(companySeat.address),
                city: formatAddressCity(companySeat.address),
            },
        };
    });
}
