import React, { PureComponent, MouseEvent } from 'react';
import './employee.scss';
import { IEmployee, IEmployeeDetails } from '../../../../../../models/admin/employee';
import Translate from '../../../../../common/Translate';
import Icon from '../../../../../common/icons/Icon';
import
SearchEmployeeToPlanOverlay
    // eslint-disable-next-line max-len
    from '../../../../../interventions/PlanMedicalExamination/PlanMedicalExaminationWizard/automatic/SearchEmployeeToPlanOverlay/index';
import { connect } from '../../../../../index';
import { ListColumns, ListItem } from '../../../../../../models/general/list';
import clone from 'ramda/src/clone';
import List from '../../../../../common/list/List';
import { formatPersonNameFormal } from '../../../../../../utils/formatting/formatPerson';
import {
    ICourseExternalEmployee, ICourseEnrollWizardEntity,
    ICourseSessionAttendee,
} from '../../../../../../models/documentCenter/courses';
import ListItemActions from '../../../../../common/list/ListItemActions';
import AddExternalEmployee from './AddExternalEmployee';
import Dialog from '../../../../../common/modals/Dialog';
import {
    getCourseEnrollWizardEntity, getCourseSessionAttendees,
} from '../../../../../../redux/documentCenter/courses/selectors';
import CourseNotVacantDialog from '../CourseNotVacantDialog';
import { doesCompanyCurrentlyUsesPreventionUnits } from '../../../../../../redux/preventionUnits/selectors';
import EditEmployeeContactData from './EditEmployeeContactData';

const CLASS_NAME = 'CourseEnrollment__EmployeeList';

export interface IContentValues {
    selectedEmployees: IEmployee[];
    selectedExternalEmployees: ICourseExternalEmployee[];
}

interface IComponentState {
    isCourseNotVacant: boolean;
    isAddEmployeeDialogOpen: boolean;
    isAddExternalEmployeeDialogOpen: boolean;
    externalEmployeeToEdit: ICourseExternalEmployee;
    employeeToEdit: IEmployee;
}

interface IPrivateProps {
    wizardCourseEntity: Partial<ICourseEnrollWizardEntity>;
    courseSessionAttendees: ICourseSessionAttendee[];
    hidePE: boolean;
}

interface IColumnNames {
    name: string;
    function: string;
    employeeId: string;
    external: boolean;
    actions: string;
}

export const COLUMNS: ListColumns<IColumnNames> = {
    name: {
        label: <Translate msg="document_center.courses.new.steps.employee.columns.name" />,
        sortable: false,
        percentWidth: 35,
    },
    function: {
        label: <Translate msg="document_center.courses.new.steps.employee.columns.function" />,
        sortable: false,
        percentWidth: 30,
    },
    employeeId: {
        label: <Translate msg="document_center.courses.new.steps.employee.columns.employee_id" />,
        sortable: false,
        percentWidth: 25,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
    external: {
        sortable: false,
        percentWidth: null,
        hide: true,
    },
};

interface IPublicProps {
    values: IContentValues;
    onChangeInput: (values: IContentValues) => void;
    onEmployeeSelected?: (selectedEmployee: IEmployee) => void;
}

class EmployeeList extends PureComponent<IPublicProps & IPrivateProps, IComponentState> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.state = {
            isAddEmployeeDialogOpen: false,
            employeeToEdit: null,
            isAddExternalEmployeeDialogOpen: false,
            externalEmployeeToEdit: null,
            isCourseNotVacant: false,
        };

        this.onToggleAddEmployeeOverlay = this.onToggleAddEmployeeOverlay.bind(this);
        this.onToggleAddExternalEmployeeDialog = this.onToggleAddExternalEmployeeDialog.bind(this);
        this.onAddEmployeeHandler = this.onAddEmployeeHandler.bind(this);
        this.onCheckEmployeeToAddHandler = this.onCheckEmployeeToAddHandler.bind(this);
        this.onAddExternalEmployeeHandler = this.onAddExternalEmployeeHandler.bind(this);
        this.isCourseVacant = this.isCourseVacant.bind(this);
        this.onCloseCourseNotVacantDialogHandler = this.onCloseCourseNotVacantDialogHandler.bind(this);
        this.columns.actions.render = this.renderListItemActions.bind(this);
        this.onCloseAddExternalEmployeeDialog = this.onCloseAddExternalEmployeeDialog.bind(this);
        this.onCloseAddEmployeeContactDataDialogOpen = this.onCloseAddEmployeeContactDataDialogOpen.bind(this);
    }

    public render() {
        const {
            isAddEmployeeDialogOpen, isAddExternalEmployeeDialogOpen,
            employeeToEdit, externalEmployeeToEdit, isCourseNotVacant,
        } = this.state;

        const {
            values, wizardCourseEntity, courseSessionAttendees, hidePE,
        } = this.props;

        const countEmployees = values.selectedEmployees.length + values.selectedExternalEmployees.length;
        const employeeIdsThatCanNotBeEnrolledAgain = getEmployeeIdsThatCanNotBeEnrolledAgain({
            alreadySelectedEmployees: values.selectedEmployees,
            courseSessionAttendees,
        });

        return (
            <div className={CLASS_NAME}>
                <List
                    name={`${CLASS_NAME}__list`}
                    columns={this.columns}
                    items={this.mapValuesToListItems()}
                    selectedItemIds={[]}
                />
                <div className={`${CLASS_NAME}__list-actions`}>
                    <Translate
                        msg="document_center.courses.new.steps.employee.add.label"
                        placeholders={{
                            icon: (
                                <Icon
                                    typeName="plus-circle"
                                    className={`${CLASS_NAME}__add-employees-icon`}
                                />
                            ),
                            employee: (
                                <a
                                    onClick={() => this.onToggleAddEmployeeOverlay()}
                                    id={`${CLASS_NAME}__add-employee`}
                                >
                                    <Translate msg="document_center.courses.new.steps.employee.add.employee" />
                                </a>
                            ),
                            external: (
                                <a
                                    onClick={() => this.onToggleAddExternalEmployeeDialog()}
                                    id={`${CLASS_NAME}__add-external-employee`}
                                >
                                    <Translate msg="document_center.courses.new.steps.employee.add.external" />
                                </a>
                            ),
                        }}
                    />
                </div>
                <SearchEmployeeToPlanOverlay
                    show={isAddEmployeeDialogOpen}
                    onClose={this.onToggleAddEmployeeOverlay}
                    onSelect={this.onCheckEmployeeToAddHandler}
                    overrideTitle="document_center.courses.new.steps.employee.add.title"
                    overrideText="document_center.courses.new.steps.employee.add.text"
                    resetOnSelect={true}
                    disabledEmployeeIds={employeeIdsThatCanNotBeEnrolledAgain}
                    disabledEmployeeReason={
                        <Translate msg="document_center.courses.new.steps.employee.add.disabled_employee_reason" />
                    }
                    searchInputTooltipTranslationKey="document_center.courses.new.steps.employee.add.search_tooltip"
                    enableEmployeeToPlanValidation={false}
                />
                <Dialog
                    show={isAddExternalEmployeeDialogOpen}
                    onCloseIntent={this.onCloseAddExternalEmployeeDialog}
                >
                    {isAddExternalEmployeeDialogOpen && <AddExternalEmployee
                        onClose={this.onCloseAddExternalEmployeeDialog}
                        onSucces={this.onAddExternalEmployeeHandler}
                        employee={externalEmployeeToEdit}
                    />}
                </Dialog>
                <Dialog
                    show={!!employeeToEdit}
                    onCloseIntent={this.onCloseAddEmployeeContactDataDialogOpen}
                >
                    {employeeToEdit && <EditEmployeeContactData
                        onSuccess={this.onAddEmployeeHandler}
                        onClose={this.onCloseAddEmployeeContactDataDialogOpen}
                        employee={employeeToEdit}
                    />}
                </Dialog>
                <CourseNotVacantDialog
                    open={isCourseNotVacant}
                    onClose={this.onCloseCourseNotVacantDialogHandler}
                />
                {!wizardCourseEntity.location.selectedClosedCourseFromOverview && countEmployees > 0 && (
                    <div className={`${CLASS_NAME}__price`}>
                        <Translate
                            msg={`document_center.courses.new.steps.employee.price${hidePE ? '_no_pe' : ''}`}
                            placeholders={{
                                count: countEmployees,
                                price: wizardCourseEntity.session.price * countEmployees,
                                pe: Math.round(wizardCourseEntity.session.amountPE * countEmployees * 100) / 100,
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }

    private onCloseCourseNotVacantDialogHandler() {
        this.setState({ isCourseNotVacant: false });
    }

    private onToggleAddEmployeeOverlay() {
        this.setState({
            isAddEmployeeDialogOpen: !this.state.isAddEmployeeDialogOpen,
        });
    }

    private onToggleAddExternalEmployeeDialog() {
        this.setState({
            externalEmployeeToEdit: null,
            isAddExternalEmployeeDialogOpen: !this.state.isAddExternalEmployeeDialogOpen,
        });
    }

    private onCloseAddExternalEmployeeDialog() {
        this.setState({
            externalEmployeeToEdit: null,
            isAddExternalEmployeeDialogOpen: false,
        });
    }

    private onCloseAddEmployeeContactDataDialogOpen() {
        this.setState({
            employeeToEdit: null,
        });
    }

    private onCheckEmployeeToAddHandler(employee: IEmployeeDetails) {
        const { wizardCourseEntity } = this.props;
        const selectedClosedCourse = wizardCourseEntity.location.selectedClosedCourseFromOverview;

        // only ask for email if not set already & course is not of type 'closed'
        if (!!employee.email || selectedClosedCourse) {
            this.onAddEmployeeHandler(employee);
        } else {
            this.setState({
                employeeToEdit: employee,
            });
        }
    }

    private onAddEmployeeHandler(employee: IEmployeeDetails) {
        const { values } = this.props;

        if (this.isCourseVacant()) {
            this.props.onChangeInput({
                selectedEmployees: [
                    ...values.selectedEmployees,
                    employee,
                ],
                selectedExternalEmployees: values.selectedExternalEmployees,
            });
        } else {
            this.setState({
                isCourseNotVacant: true,
            });
        }

        this.onToggleAddEmployeeOverlay();
    }

    private onAddExternalEmployeeHandler(employee: ICourseExternalEmployee) {
        const { values } = this.props;

        if (this.isCourseVacant()) {
            const index = values.selectedExternalEmployees.findIndex((e) => e.email === employee.email);
            if (index !== -1) {
                values.selectedExternalEmployees[index] = employee;
            } else {
                values.selectedExternalEmployees.push(employee);
            }

            this.props.onChangeInput({
                selectedExternalEmployees: values.selectedExternalEmployees,
                selectedEmployees: values.selectedEmployees,
            });
        } else {
            this.setState({
                isCourseNotVacant: true,
            });
        }

        this.onToggleAddExternalEmployeeDialog();
    }

    private renderListItemActions(listItem: ListItem<IColumnNames>) {
        const isExternal = listItem.columns.external as boolean;

        return (
            <ListItemActions>
                {isExternal &&
                    <Icon circle typeName="pencil" onClick={(e) => this.onEditExternalEmployee(e, listItem)} />
                }
                <Icon circle typeName="bin" onClick={(e) => this.onDeleteEmployee(e, listItem)} />
            </ListItemActions>
        );
    }

    private onDeleteEmployee(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const { values } = this.props;
        const external = listItem.columns.external as boolean;

        if (!external) {
            values.selectedEmployees = values.selectedEmployees
                .filter((employee: IEmployee) =>
                    employee.employeeId !== listItem.columns.employeeId as number);
        } else {
            values.selectedExternalEmployees = values.selectedExternalEmployees
                .filter((employee: ICourseExternalEmployee) => employee.name !== listItem.id as string);
        }

        this.props.onChangeInput({
            selectedEmployees: values.selectedEmployees,
            selectedExternalEmployees: values.selectedExternalEmployees,
        });

        this.setState({
            isCourseNotVacant: !this.isCourseVacant(values),
        });
    }

    private onEditExternalEmployee(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        const { values } = this.props;

        this.setState({
            isAddExternalEmployeeDialogOpen: true,
            externalEmployeeToEdit: values.selectedExternalEmployees.find((item: ICourseExternalEmployee) => {
                return item.name === listItem.id;
            }),
        });
    }

    private mapValuesToListItems(): ListItem<IColumnNames>[] {
        const { values } = this.props;

        const employees = this.mapEmployeesToListItems(values.selectedEmployees, false);
        const externalEmployees = this.mapEmployeesToListItems(values.selectedExternalEmployees, true);

        return [
            ...employees,
            ...externalEmployees,
        ];
    }

    private mapEmployeesToListItems(employees, external: boolean) {
        return employees
            .map((employee) => {
                return {
                    id: employee.name,
                    columns: {
                        name: formatPersonNameFormal(employee),
                        function: employee.function && employee.function.description,
                        employeeId: employee.employeeId,
                        external,
                    },
                };
            });
    }

    private isCourseVacant(values?: IContentValues) {
        const { wizardCourseEntity } = this.props;
        const wizardValues = values ? values : this.props.values;

        const capacity = wizardCourseEntity.session.maxAmount;
        const freeSlots = capacity - wizardCourseEntity.session.attendees;

        return freeSlots - wizardValues.selectedEmployees.length - wizardValues.selectedExternalEmployees.length > 0;
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            wizardCourseEntity: getCourseEnrollWizardEntity(state),
            courseSessionAttendees: getCourseSessionAttendees(state),
            hidePE: !doesCompanyCurrentlyUsesPreventionUnits(state),
        };
    },
})(EmployeeList);

function getEmployeeIdsThatCanNotBeEnrolledAgain({
    alreadySelectedEmployees = [],
    courseSessionAttendees = [],
}: {
    alreadySelectedEmployees: IEmployee[];
    courseSessionAttendees: ICourseSessionAttendee[];
}) {
    const alreadySelectedEmployeeIds = alreadySelectedEmployees
        .map((alreadySelectedEmployee) => alreadySelectedEmployee.employeeId);
    return courseSessionAttendees
        .map((attendee) => attendee.employeeId)
        .concat(alreadySelectedEmployeeIds);
}
