import React, { Component, MouseEvent } from 'react';
import './bufferzones.scss';
import { IRenderDetailHeaderProps, IRenderDetailContentProps } from '../../../common/widget/MasterWithDetail/typings';
import { ICompanyBufferzone } from '../../../../models/admin/company';
import Translate from '../../../common/Translate';
import CollapsibleItem from '../../../common/widget/CollapsibleItem';
import { formatDateInFullFormat } from '../../../../utils/formatting/formatDate';
import {
    formatStartEndTimeOfDatesForDisplay,
    formatTimeOfDateForDisplay,
} from '../../../../utils/formatting/formatTime';
import DetailItem from '../../../common/widget/DetailItem';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { getIndicatorStatus } from '../../../common/widget/FreeSlotsIndicator';
import StatusIndicator from '../../../common/widget/StatusIndicator';
import { connect } from '../../..';
import { IPlannedMedicalExamination } from '../../../../models/interventions/medicalExaminations';
import {
    getPlannedMedicalExaminations,
    getPlannedMedicalExaminationsAsyncInfo,
} from '../../../../redux/medicalExamination/selectors';
import TinyLoader from '../../../common/waiting/TinyLoader';
import List from '../../../common/list/List';
import { ListColumns, ListItem } from '../../../../models/general/list';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import { createSelector } from 'reselect';
import { navigateTo } from '../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../routeKeys';
import clone from 'ramda/src/clone';
import ListItemActions from '../../../common/list/ListItemActions';
import Button from '../../../common/buttons/Button';
import Icon from '../../../common/icons/Icon';
import ShowIfAllowed from '../../../auth/ShowIfAllowed';
import EditAppointmentDialog from '../Planned/EditAppointmentDialog';
import { createConvocationsActions } from '../../../../redux/employee/documents/actions';
import { AsyncStatus } from '../../../../models/general/redux';
import { getCreateConvocationsAsyncInfo } from '../../../../redux/employee/documents/selectors';
import Loader from '../../../common/waiting/Loader';
import ListActionButton from '../../../common/buttons/ListActionButton';
import {
    mapListRowForExport as mapPlannedMedicalExaminationForExport,
} from '../Planned/index/PlannedMedicalExaminations.helper';
import exportListDataToCsv from '../../../../utils/file/csv/exportListDataToCsv';
import { hoursOffsetFromNow, getDate } from '../../../../utils/core/date/getSpecificDate';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../config/medicalExamination.config';
import { stringComparerAscending } from '../../../../utils/list/comparerUtils';

const TRANSLATION_PREFIX = 'interventions.medical_examinations.bufferzones.detail';
const CLASS_NAME = 'BufferzonesDetail';

interface IPublicProps {
    triggerPlanBufferzoneWizardWithSelectedBufferzone: (planningEntityId: number) => void;
}

interface IPrivateProps {
    plannedMedicalExaminations: IPlannedMedicalExamination[];
    listItems: ListItem<IColumnNames>[];
    isDownloadingDocument: boolean;
    navigateToEmployeeDetails: (planningEntityId: number, idOfEmployee: number) => void;
    onDownloadClick: (item: ListItem<IColumnNames>) => void;
}

export const BufferzoneDetailHeader = (renderProps: IRenderDetailHeaderProps<ICompanyBufferzone>) => {
    const {
        detailAsyncInfo,
    } = renderProps;

    return (
        <h1>
            {detailAsyncInfo.error
                ? <Translate msg="error.title" />
                : <Translate msg={`${TRANSLATION_PREFIX}.title`} />
            }
        </h1>
    );
};

const BufferzoneDetailContent = (
    props: IPrivateProps & IRenderDetailContentProps<ICompanyBufferzone> & IPublicProps,
) => {
    const {
        detailData: selectedBufferzone,
        triggerPlanBufferzoneWizardWithSelectedBufferzone,
    } = props;

    if (!selectedBufferzone) {
        return null;
    }

    const isPlanningAllowed =
        hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() <=
        getDate(selectedBufferzone.endTime);

    const areReasonsRestricted = selectedBufferzone.allowedExaminations
        && selectedBufferzone.allowedExaminations.length > 0;
    const allowedExaminationReasonsInfoTranslationKey = areReasonsRestricted
        ? `${TRANSLATION_PREFIX}.details.allowed_examination_reasons.info`
        : `${TRANSLATION_PREFIX}.details.allowed_examination_reasons.info_all_allowed`;

    return (
        <div className={CLASS_NAME}>
            <CollapsibleItem
                trigger={`${TRANSLATION_PREFIX}.details.title`}
                initialOpen={true}
            >
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.location`} /></h6>
                    <span>
                        {selectedBufferzone.medicalCenter.name}
                        {selectedBufferzone.medicalCenter.address && (
                            <>
                                <br />
                                {formatAddress(selectedBufferzone.medicalCenter.address, true)}
                            </>
                        )}
                    </span>
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.seat`} /></h6>
                    <span>
                        {`${selectedBufferzone.company.name} (${selectedBufferzone.company.companyCode})`}
                    </span>
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.date`} /></h6>
                    <span>{formatDateInFullFormat(selectedBufferzone.date)}</span>
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.start_and_end_hour`} /></h6>
                    <span>
                        {formatStartEndTimeOfDatesForDisplay(
                            selectedBufferzone.startTime,
                            selectedBufferzone.endTime,
                        )}
                    </span>
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.availability.title`} /></h6>
                    <div className={`${CLASS_NAME}__availability`}>
                        <span>
                            <Translate
                                msg={`${TRANSLATION_PREFIX}.details.availability.text`}
                                placeholders={{
                                    freeSlots: <strong>{selectedBufferzone.medicalCenter.freeSlots}</strong>,
                                    totalSlots: selectedBufferzone.medicalCenter.totalSlots,
                                }}
                            />
                        </span>
                        <StatusIndicator
                            value={
                                getIndicatorStatus(
                                    selectedBufferzone.medicalCenter.freeSlots,
                                    selectedBufferzone.medicalCenter.totalSlots,
                                ).statusIndicatorValue
                            }
                            className={`${CLASS_NAME}__status-indicator`}
                        />
                    </div>
                    {isPlanningAllowed &&
                        <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                            <div className={`${CLASS_NAME}__availability__plan-bufferzone`}>
                                <a
                                    onClick={() => triggerPlanBufferzoneWizardWithSelectedBufferzone(
                                        selectedBufferzone.planningEntityId,
                                    )}
                                >
                                    <Icon typeName="calendar" circle />
                                    <span>
                                        <Translate msg={`${TRANSLATION_PREFIX}.details.availability.plan_bufferzone`} />
                                    </span>
                                </a>
                            </div>
                        </ShowIfAllowed>
                    }
                </DetailItem>
                <DetailItem noBorder>
                    <h6><Translate msg={`${TRANSLATION_PREFIX}.details.allowed_examination_reasons.title`} /></h6>
                    <div className={`${CLASS_NAME}__allowed_examination_reasons`}>
                        <span className="info-text">
                            <Translate msg={allowedExaminationReasonsInfoTranslationKey} />
                        </span>
                        {areReasonsRestricted &&
                            <ul className="reasons">
                                {selectedBufferzone.allowedExaminations
                                    .sort((reasonA, reasonB) =>
                                        stringComparerAscending(reasonA.title, reasonB.title))
                                    .map((allowedExaminationReason) => (
                                        <li key={`allowedExaminationReason_${allowedExaminationReason.id}`}>
                                            {allowedExaminationReason.title}
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                    </div>
                </DetailItem>
            </CollapsibleItem>
            <CollapsibleItem
                trigger={`${TRANSLATION_PREFIX}.planned.title`}
                initialOpen={true}
            >
                <TinyLoader asyncInfoSelector={getPlannedMedicalExaminationsAsyncInfo}>
                    <PlannedMedicalExaminationsList {...props} planningEntityId={selectedBufferzone.planningEntityId} />
                </TinyLoader>
            </CollapsibleItem>
        </div>
    );
};

interface IColumnNames {
    time: string;
    name: string;
    actions: string;
    idOfEmployee: string;
    examinationReasonCode: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    time: {
        label: '',
        percentWidth: 20,
    },
    name: {
        label: '',
        percentWidth: 50,
    },
    actions: {
        label: '',
        percentWidth: 30,
    },
    idOfEmployee: {
        hide: true,
        percentWidth: null,
    },
    examinationReasonCode: {
        hide: true,
        percentWidth: null,
    },
};

interface IListState {
    isEditAppointmentDialogOpen: boolean;
    selectedItemId: number;
}

class PlannedMedicalExaminationsList extends Component<IPrivateProps & { planningEntityId: number }, IListState> {
    private columns = clone(COLUMNS);

    public constructor(props: IPrivateProps & { planningEntityId: number }) {
        super(props);

        this.state = {
            isEditAppointmentDialogOpen: false,
            selectedItemId: null,
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);

        this.columns.actions.render = (listItem: ListItem<IColumnNames>, index) => {

            const isExaminationCancellable = (code) => {
                const blockedExaminations = [
                    'REINT',
                    'REINTOPV',
                    'REINTTEL',
                    'ART34',
                    'ART34OPV',
                    'ART34TEL',
                    'ART34VWD',
                    'ART34ADM',
                    'REINTADM',
                    'REINTCON',
                ];
                return blockedExaminations.indexOf(code) === -1;
            };

            return (
                <ListItemActions>
                    <Button
                        id={`download-file-button-${listItem.id}`}
                        onClick={(e) => this.onDownloadClick(e, listItem)}
                    >
                        <span><Translate msg={`${TRANSLATION_PREFIX}.actions.download_invitation`} /></span>
                        <Icon circle typeName="download-file" />
                    </Button>
                    {isExaminationCancellable(listItem.columns.examinationReasonCode) &&
                        <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                            <Icon
                                circle
                                typeName="pencil"
                                onClick={(e) => this.onEditClick(e, listItem)}
                            />
                        </ShowIfAllowed>
                    }
                </ListItemActions>
            );
        };
    }

    private onEditClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isEditAppointmentDialogOpen: true,
            selectedItemId: listItem.id as number,
        });
    }

    private onDownloadClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadClick,
        } = this.props;

        onDownloadClick(listItem);
    }

    public render() {
        const {
            listItems,
            navigateToEmployeeDetails,
            planningEntityId,
            isDownloadingDocument,
            plannedMedicalExaminations,
        } = this.props;

        function onItemRowClicked(id: number) {
            const listItem = listItems.find((item) => item.id === id);
            navigateToEmployeeDetails(planningEntityId, listItem.columns.idOfEmployee as number);
        }

        function onExportClicked() {
            const exportItems = plannedMedicalExaminations.map(mapPlannedMedicalExaminationForExport);
            exportListDataToCsv({
                baseFilename: 'MyMensura-bufferzone-planned-medical-examinations-export',
                listData: exportItems,
            });
        }

        return (
            <>
                <EditAppointmentDialog
                    show={this.state.isEditAppointmentDialogOpen}
                    onCloseIntent={() => {
                        this.setState({ isEditAppointmentDialogOpen: false });
                    }}
                    plannedMedicalExaminationId={this.state.selectedItemId}
                />
                <div className={`${CLASS_NAME}__list`}>
                    <ListActionButton
                        id="bufferzone-planned-medexams-export"
                        type="text"
                        iconTypeName="excel"
                        translationKey={`${TRANSLATION_PREFIX}.planned.export`}
                        onClick={onExportClicked}
                    />
                    <Loader show={isDownloadingDocument} />
                    <List
                        items={listItems}
                        columns={this.columns}
                        name="bufferzone-planned-list"
                        selectedItemIds={[]}
                        hideHeaders={true}
                        onItemRowClicked={onItemRowClicked}
                    />
                </div>
            </>
        );
    }

}

function sortAndMapPlannedExaminationsToListData(examinations: IPlannedMedicalExamination[]): ListItem<IColumnNames>[] {
    return examinations
        .map((exam) => ({
            id: exam.id,
            columns: {
                time: formatTimeOfDateForDisplay(exam.time),
                name: formatPersonName(exam),
                actions: null,
                idOfEmployee: exam.employee.id,
                examinationReasonCode: exam.examinationReason.code,
            },
        }))
        .sort((a, b) => stringComparerAscending(a.columns.time, b.columns.time));
}

const getListItemsMemoized = createSelector(
    getPlannedMedicalExaminations,
    (exams) => sortAndMapPlannedExaminationsToListData(exams),
);

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            plannedMedicalExaminations: getPlannedMedicalExaminations(state),
            listItems: getListItemsMemoized(state),
            isDownloadingDocument: getCreateConvocationsAsyncInfo(state).status === AsyncStatus.Busy,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            navigateToEmployeeDetails: (planningEntityId: number, idOfEmployee: number) => {
                dispatch(navigateTo(
                    ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL,
                    {
                        planningEntityId,
                        id: idOfEmployee,
                    },
                ));
            },
            onDownloadClick: (item: ListItem<IColumnNames>) => {
                dispatch(createConvocationsActions.trigger(
                    [{

                        employeeId: item.columns.idOfEmployee as number,
                        planningId: item.id as number,
                        downloadOnly: true,
                    }],
                ));
            },
        };
    },
})(BufferzoneDetailContent);
