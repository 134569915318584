import React, { Component } from 'react';
import { Manager } from 'react-popper';
import BodySwitcher from '../../BodySwitcher';
import ErrorBoundary from '../../../common/error/ErrorBoundary';
import NotificationsBar from '../../NotificationsBar';
import FlashMessages from '../../FlashMessages';
import { ITranslator } from '../../../../models/general/i18n';
import { connect } from '../../../index';
import { getLocale, getTranslatorForContext } from '../../../../redux/i18n/selectors';
import { Locales } from '../../../../config/i18n.config';
import TranslatorContext from '../../contexts/TranslatorContext';
// import CookieConsent from '../../CookieConsent';
import PrivacyPolicyModal from '../../PrivacyPolicy/PrivacyPolicyModal';
import DisclaimerModal from '../../Disclaimer/DisclaimerModal';
import PerformanceDashboardDialog from '../../../administration/PerformanceDashboard/PerformanceDashboardDialog';

interface IPrivateProps {
    translator: ITranslator;
    locale: Locales;
}

class ConnectedApp extends Component<IPrivateProps> {
    constructor(props) {
        super(props);
    }

    public render() {
        const { translator, locale } = this.props;
        return (
            <TranslatorContext.Provider value={{ translator, locale }}>
                <Manager>
                    <ErrorBoundary>
                        <NotificationsBar />
                        <FlashMessages />
                        <BodySwitcher />
                        {/* <CookieConsent /> */}
                        <PrivacyPolicyModal />
                        <DisclaimerModal />
                        <PerformanceDashboardDialog />
                        {renderDevPanel()}
                    </ErrorBoundary>
                </Manager>
            </TranslatorContext.Provider>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorForContext(state),
            locale: getLocale(state),
        };
    },
})(ConnectedApp);

function renderDevPanel() {
    if (process.env.NODE_ENV === 'development') {
        const DevPanel = require('../../../development/DevPanel').default;
        return <DevPanel />;
    }
    return null;
}
