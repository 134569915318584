import { getReducerState, IState } from '../../IState';
import { IReducerState, reducerKey } from './reducer';
import { NO_RERENDER, makeAsyncFetchInfoSelector, makeAsyncDoInfoSelector } from '../..';
import { IPlanBufferzoneWizardData } from '../../../models/interventions/bufferzones';
import { createSelector } from 'reselect';
import {
    getActiveExaminationReasons,
    getEmployeesToPlan,
    getExaminationReasons,
} from '../../medicalExamination/selectors';
import { AsyncStatus } from '../../../models/general/redux';
import { ITimeCell } from '../../../models/interventions/timeslots';
import { IPlannedMedicalExamination } from '../../../models/interventions/medicalExaminations';
import { EXAMINATION_REASON_CODE } from '../../../config/navigation/interventions.config';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Plan bufferzone wizard
const getPlanBufferzoneWizardData = (state: IState) =>
    (reducerState(state).planBufferzoneWizardData || NO_RERENDER.EMPTY_OBJECT) as IPlanBufferzoneWizardData;

export const getPlanBufferzoneWizardStepId = (state: IState) =>
    getPlanBufferzoneWizardData(state).stepId;

export const getPlanBufferzoneWizardEntity = (state: IState) =>
    getPlanBufferzoneWizardData(state).entity;

export const getPlanBufferzoneSelectedBufferzone = (state: IState) => {
    const entity = getPlanBufferzoneWizardEntity(state);
    if (entity) {
        return entity.selectedBufferzone;
    }

    return null;
};

export const getPlanBufferzoneModifiedTimeCellIds = (state: IState) => {
    const entity = getPlanBufferzoneWizardEntity(state);
    return entity && entity.modifiedTimeCellIds || NO_RERENDER.EMPTY_LIST;
};

export const getPlanBufferzoneWizardEntityEmployeesToPlan = (state: IState) => {
    const entity = getPlanBufferzoneWizardEntity(state);

    if (entity) {
        return entity.employeesToPlan || NO_RERENDER.EMPTY_LIST;
    }
    return NO_RERENDER.EMPTY_LIST;
};

export const getAddedEmployees = (state: IState) => {
    const entity = getPlanBufferzoneWizardEntity(state);

    if (entity) {
        return entity.addedEmployees || NO_RERENDER.EMPTY_LIST;
    }
    return NO_RERENDER.EMPTY_LIST;
};

export const getPlanBufferzoneEmployeesToPlanCombinedWithAddedEmployees = createSelector(
    getEmployeesToPlan,
    getAddedEmployees,
    (employeesToPlan, addedEmployees) => employeesToPlan.concat(addedEmployees),
);

// Fetch Bufferzone Timeslots
const getBufferzoneTimeslotsAsyncField = (state: IState) =>
    reducerState(state).bufferzoneTimeslots;

export const getBufferzoneTimeslotsAsyncInfo = makeAsyncFetchInfoSelector(getBufferzoneTimeslotsAsyncField);

const getBufferzoneTimeslots = (state: IState) =>
    getBufferzoneTimeslotsAsyncField(state).data;

export const getBufferzoneFreeTimeslots = (state: IState) => {
    const timeslots = getBufferzoneTimeslots(state);
    return timeslots && timeslots.freeTimeslots ? timeslots.freeTimeslots : NO_RERENDER.EMPTY_LIST as ITimeCell[];
};

export const getBufferzonePlannedTimeslots = (state: IState) => {
    const timeslots = getBufferzoneTimeslots(state);
    return timeslots && timeslots.plannedExaminations ?
        timeslots.plannedExaminations : NO_RERENDER.EMPTY_LIST as IPlannedMedicalExamination[];
};

// Bufferzone planning actions
const getPlanBufferzoneCreateNewPlanningAsyncField = (state: IState) =>
    reducerState(state).createNewPlanning;

export const getPlanBufferzoneCreateNewPlanningAsyncInfo =
    makeAsyncDoInfoSelector(getPlanBufferzoneCreateNewPlanningAsyncField);

const getPlanBufferzoneMoveExistingPlanningAsyncField = (state: IState) =>
    reducerState(state).moveExistingPlanning;

export const getPlanBufferzoneMoveExistingPlanningAsyncInfo =
    makeAsyncDoInfoSelector(getPlanBufferzoneMoveExistingPlanningAsyncField);

const getPlanBufferzoneCancelExistingPlanningAsyncField = (state: IState) =>
    reducerState(state).cancelExistingPlanning;

export const getPlanBufferzoneCancelExistingPlanningAsyncInfo =
    makeAsyncDoInfoSelector(getPlanBufferzoneCancelExistingPlanningAsyncField);

export const getBufferzonePlanningStepAsyncInfo = (state: IState) => {
    const fetchBufferzoneTimeslotsAsyncInfo = getBufferzoneTimeslotsAsyncInfo(state);
    const cancelPlanningAsyncInfo = getPlanBufferzoneCancelExistingPlanningAsyncInfo(state);
    const movePlanningAsyncInfo = getPlanBufferzoneMoveExistingPlanningAsyncInfo(state);
    const createPlanningAsyncInfo = getPlanBufferzoneCreateNewPlanningAsyncInfo(state);

    // Errors
    if (fetchBufferzoneTimeslotsAsyncInfo.status === AsyncStatus.Error) {
        return fetchBufferzoneTimeslotsAsyncInfo;
    }
    if (cancelPlanningAsyncInfo.status === AsyncStatus.Error) {
        return cancelPlanningAsyncInfo;
    }
    if (movePlanningAsyncInfo.status === AsyncStatus.Error) {
        return movePlanningAsyncInfo;
    }
    if (createPlanningAsyncInfo.status === AsyncStatus.Error) {
        return createPlanningAsyncInfo;
    }

    // Busy
    if (fetchBufferzoneTimeslotsAsyncInfo.status === AsyncStatus.Busy) {
        return fetchBufferzoneTimeslotsAsyncInfo;
    }
    if (cancelPlanningAsyncInfo.status === AsyncStatus.Busy) {
        return cancelPlanningAsyncInfo;
    }
    if (movePlanningAsyncInfo.status === AsyncStatus.Busy) {
        return movePlanningAsyncInfo;
    }
    return createPlanningAsyncInfo;
};

export const getPlanBufferzoneExaminationReasons = createSelector(
    getActiveExaminationReasons,
    (reasons) => reasons.filter((reason) => reason.code !== EXAMINATION_REASON_CODE.RE_INTEGRATION),
);

export const getPlanBufferzoneExaminationReasonsForSelectedBufferzone = createSelector(
    getPlanBufferzoneExaminationReasons,
    getPlanBufferzoneSelectedBufferzone,
    (potentialReasons, selectedBufferzone) => {
        if (selectedBufferzone
            && selectedBufferzone.allowedExaminations
            && selectedBufferzone.allowedExaminations.length > 0) {
            const allowedExaminationIdsOnBufferzone = selectedBufferzone.allowedExaminations
                .map((allowedExaminationReason) => allowedExaminationReason.id);

            return potentialReasons
                .filter((potentialReason) => allowedExaminationIdsOnBufferzone.includes(potentialReason.id));
        }

        // no allowed examinations configured on the bufferzone,
        // so all reasons (without the already filtered ones like RE_INTEGRATION) are allowed.
        return potentialReasons;
    },
);

export const getPlanBufferzoneExaminationReasonsInEmployeesToPlanMemoized = createSelector(
    getPlanBufferzoneWizardEntity,
    getExaminationReasons,
    getBufferzonePlannedTimeslots,
    (entity, examinationReasons, plannedTimeslots) => {
        if (entity && entity.employeesToPlan) {
            const examinationReasonIdsFromEmployees = entity.employeesToPlan.reduce(
                (accumulator, employeeToPlan) => {
                    if (!accumulator.includes(employeeToPlan.examinationReason.id)) {
                        accumulator.push((employeeToPlan.examinationReason.id));
                    }
                    return accumulator;
                },
                [],
            );
            const modifiedPlannedTimeslots = plannedTimeslots.filter((exam) =>
                entity.modifiedTimeCellIds && entity.modifiedTimeCellIds.includes(exam.timeCell.id));

            const examinationReasonsToIncludeInLegend =
                modifiedPlannedTimeslots.reduce(
                    (accumulator, exam) => {
                        if (!accumulator.includes(exam.examinationReason.id)) {
                            accumulator.push((exam.examinationReason.id));
                        }
                        return accumulator;
                    },
                    examinationReasonIdsFromEmployees,
                );
            return examinationReasons.filter((reason) => examinationReasonsToIncludeInLegend.includes(reason.id));
        }
        return NO_RERENDER.EMPTY_LIST;
    },
);
