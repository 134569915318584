import { ICompanyBufferzone } from '../../../models/admin/company';
import isArray from '@snipsonian/core/es/is/isArray';
import isSet from '@snipsonian/core/es/is/isSet';
import { ITimeCell } from '../../../models/interventions/timeslots';
import { isDateBetweenInclusive } from '../../../utils/core/date/isDateBetween';

/**
 * allowedExaminations
 * - if empty array --> all reasons allowed
 * - if array --> the included reasons are the allowed ones
 *       p.s. There is a potential configuration problem where in H@W both the include- as the exclude-list
 *            has been configured so that it's not really clear which are the allowed reasons. In this case,
 *            the mensura rest service will only pass the 'include' reasons, so that we don't have to do
 *            anything special for that exception case.
 */

export function canBePlannedOnBufferzone({
    bufferzone,
}: {
    bufferzone: ICompanyBufferzone;
}): boolean {
    if (!isSet(bufferzone.allowedExaminations)) {
        return false;
    }

    return true;
}

export function canExaminationReasonBePlannedOnBufferzone({
    bufferzone,
    examinationReasonId,
}: {
    bufferzone: ICompanyBufferzone;
    examinationReasonId: number;
}): boolean {
    if (!canBePlannedOnBufferzone({ bufferzone })) {
        return false;
    }

    if (hasBufferzoneAnExaminationReasonRestriction({ bufferzone })) {
        if (!bufferzone.allowedExaminations.some(
            (allowedExamination) => allowedExamination.id === examinationReasonId)
        ) {
            return false;
        }
    }

    return true;
}

function hasBufferzoneAnExaminationReasonRestriction({
    bufferzone,
}: {
    bufferzone: ICompanyBufferzone;
}): boolean {
    if (isArray(bufferzone.allowedExaminations) && bufferzone.allowedExaminations.length > 0) {
        return true;
    }

    return false;
}

export function isReservedTimeCellPartOfOneOfTheseBufferzones({
    timeCell,
    bufferzones,
}: {
    timeCell: ITimeCell,
    bufferzones: ICompanyBufferzone[];
}): boolean {
    if (!timeCell || !timeCell.reserved) {
        throw new Error('This check can only be used for a reserved timecell.');
    }

    return bufferzones.some((bufferzone) => {
        /**
         * Although both the bufferzone and the timeCell have a company(code) attribute,
         * we can't use that to do the check because the companyCode of the timeCell does not reflect the
         * seat of the bufferzone to which the timecell belongs to.
         */

        return isDateBetweenInclusive(
            timeCell.time, bufferzone.startTime, bufferzone.endTime,
            { startInclusive: true, endInclusive: false },
        );
    });
}
