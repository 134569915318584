import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import TinyLoader from '../../common/waiting/TinyLoader';
import Translate from '../../common/Translate';
import LinkToRoute from '../../common/navigation/LinkToRoute';
import { getNextAppointment, getNextAppointmentAsyncInfo } from '../../../redux/planning/selectors';
import { connect } from '../../index';
import {
    formatDateToDayMonth,
    dateToDayOfWeek,
    formatDateForBackend,
} from '../../../utils/formatting/formatDate';
import ROUTE_KEYS from '../../../routeKeys';
import { navigateTo } from '../../../redux/location/actions';
import { INextAppointment } from '../../../models/planning/appointments';
import { IAgendaQueryParams, IAgendaRoutePayload } from '../../../models/planning/agenda';
import { ILocationAction } from '../../../models/general/redux';
import { getDate } from '../../../utils/core/date/getSpecificDate';

const PLAN_INTERVENTION_ROUTE_KEY = ROUTE_KEYS.R_PLAN_INTERVENTION;

interface IPrivateProps {
    hasNextAppointment: boolean;
    nextAppointment: INextAppointment;
    planIntervention: () => void;
}

const AGENDA_ROUTE: ILocationAction<IAgendaRoutePayload, IAgendaQueryParams> = {
    type: ROUTE_KEYS.R_AGENDA,
    payload: {
        viewType: 'calendar',
    },
};

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const nextAppointment = getNextAppointment(state);
        return {
            hasNextAppointment: nextAppointment && isSet(nextAppointment.appointment),
            nextAppointment,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            planIntervention: () => {
                dispatch(navigateTo(PLAN_INTERVENTION_ROUTE_KEY));
            },
        };
    },
})(AgendaDashboardBlock);

function AgendaDashboardBlock(props: IPrivateProps) {
    return (
        <ShowIfAllowed routeKey={AGENDA_ROUTE.type}>
            <div className="section section--agenda">
                <ShowIfAllowed routeKey={PLAN_INTERVENTION_ROUTE_KEY}>
                    <PlanInterventionButton {...props} />
                </ShowIfAllowed>
                <MiniAgendaHeader />
                <MiniAgendaContent {...props} />
            </div>
        </ShowIfAllowed>
    );
}

function PlanInterventionButton(props: IPrivateProps) {
    return (
        <Button
            id="agenda-dashboard-plan-intervention"
            typeName="secondary"
            onClick={props.planIntervention}
        >
            <Icon typeName="calendar" />
            <Translate msg="home.dashboard.agenda.plan_intervention" />
        </Button>
    );
}

function MiniAgendaHeader() {
    return (
        <header className="header">
            <div>
                <h4><Translate msg="home.dashboard.agenda.title" /></h4>
                <LinkToRoute
                    id="to-agenda-overview"
                    className="icon-link"
                    to={AGENDA_ROUTE}
                >
                    <span><Translate msg="home.dashboard.agenda.link_overview" /></span>
                    <Icon typeName="chevron-right" />
                </LinkToRoute>
            </div>
        </header>
    );
}

function MiniAgendaContent(props: IPrivateProps) {
    const {
        hasNextAppointment,
        nextAppointment,
    } = props;

    return (
        <div className="content">
            <div className="content-inner">
                <h6><Translate msg="home.dashboard.agenda.next_title" /></h6>
                <TinyLoader asyncInfoSelector={getNextAppointmentAsyncInfo}>
                    {
                        hasNextAppointment && <NextAppointmentBlock nextAppointment={nextAppointment} />}
                    {
                        !hasNextAppointment &&
                        <div className="appointment">
                            <div className="description">
                                <span><Translate msg="home.dashboard.agenda.no_appointments" /></span>
                            </div>
                        </div>
                    }
                </TinyLoader>
                <div className="appointment-footer">
                    <LinkToRoute id="agenda-footer-link" className="icon-link" to={AGENDA_ROUTE}>
                        <span><Translate msg="home.dashboard.agenda.footer_link" /></span>
                        <Icon typeName="chevron-right" />
                    </LinkToRoute>
                </div>
            </div>
        </div>
    );
}

function NextAppointmentBlock(props: { nextAppointment: INextAppointment }) {
    const { nextAppointment } = props;
    return (
        <div className="appointment">
            {nextAppointment.appointment.startDate && (
                <div className="date">
                    <span className="day">
                        <Translate
                            // eslint-disable-next-line max-len
                            msg={`app_shell.dates.days.${dateToDayOfWeek(
                                getDate(nextAppointment.appointment.startDate))}`}
                        />
                    </span>
                    {isSet(nextAppointment.appointment.afterNoon) && (
                        <span className="timing">
                            <Translate // eslint-disable-next-line max-len
                                msg={`common.calendar.${nextAppointment.appointment.afterNoon ? 'afternoon' : 'morning'}`}
                            />
                        </span>
                    )}
                    <span>{formatDateToDayMonth(getDate(nextAppointment.appointment.startDate))}</span>
                </div>
            )}
            <div className="description">
                <LinkToRoute
                    id="agenda-appointment-link"
                    className="icon-link"
                    to={{
                        ...AGENDA_ROUTE,
                        meta: {
                            query: {
                                selectedDate: formatDateForBackend(nextAppointment.appointment.startDate),
                            } as IAgendaQueryParams,
                            location: null,
                        },
                    }}
                >
                    <NextAppointmentTitle nextAppointment={nextAppointment} />
                    <Icon typeName="chevron-right" />
                </LinkToRoute>
            </div>
        </div>
    );
}

function NextAppointmentTitle(props: { nextAppointment: INextAppointment }) {
    const { nextAppointment } = props;
    if (nextAppointment.count > 1) {
        return (
            <span>
                <Translate
                    msg={`home.dashboard.agenda.multiple.${nextAppointment.type.replace('-', '_')}`}
                    placeholders={{
                        count: nextAppointment.count,
                    }}
                />
            </span>
        );
    }

    return (
        <span>
            <Translate msg={`home.dashboard.agenda.type.${nextAppointment.type.replace('-', '_')}`} />
            {nextAppointment.appointment.description && `: ${nextAppointment.appointment.description}`}
            {nextAppointment.appointment.firstName && nextAppointment.appointment.lastName && (
                <>
                    <br />
                    {`${nextAppointment.appointment.firstName} ${nextAppointment.appointment.lastName}`}
                </>
            )}
        </span>
    );
}
