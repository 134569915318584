import { IRoutes } from '../../utils/routing/typings';
import templates from '../appShell/templates';
import AdministrationDashboard from './AdministrationDashboard';
import Employees from './Employees';
import { AddEmployeeWizard } from './Employees/AddEmployee/AddEmployeeWizard.component';
import ROUTE_KEYS from '../../routeKeys';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import Risks from './Risks';
import Functions from './Functions/index';
import CompanyInfoTemplate from './CompanyInfo/template';
import CompanyInfoGeneral from './CompanyInfo/General';
import CompanyInfoSeats from './CompanyInfo/Seats';
import CompanyInfoContacts from './CompanyInfo/Contacts';
import InvoicesTemplate from './Invoices/template';
import OpenInvoices from './Invoices/Open';
import PaidInvoices from './Invoices/Paid';
import PreventionUnitsTemplate from './PreventionUnits/template';
import FlatFee from './FlatFee';
import Rates from './Rates';
import { IWizardTemplateProps } from '../appShell/templates/WizardTemplate';
import { getPropertyFromRoutePayload } from '../../redux/location/selectors';
import PreventionUnitsArchive from './PreventionUnits/Archive';
import QuestionnairesTemplate from './Questionnaires/template';
import QuestionnairesScreenwork from './Questionnaires/Screenwork';
import QuestionnairesJobStudents from './Questionnaires/JobStudents';
import Parameters from './Rates/parameters';
import RatesTemplate from './Rates/template';
import { overrideParentRouteKeyToHomeDashboardIfDismissedCompany } from '../overrideParentRouteKey';
import PEOverviewCurrentYear from './PreventionUnits/Overview/PEOverviewCurrentYear';
import PEOverviewPreviousYear from './PreventionUnits/Overview/PEOverviewPreviousYear';
import AddEmails from './Employees/AddEmails';
import PerformanceDashboard from './PerformanceDashboard';
import PerformanceTemplate from './PerformanceDashboard/template';

export const BASE_ROUTE = '/administration';
const COMPANY_INFO_ROUTE = `${BASE_ROUTE}/company-info`;
const INVOICES_ROUTE = `${BASE_ROUTE}/invoices`;

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_ADMINISTRATION]: {
        path: BASE_ROUTE,
        page: {
            key: 'ADMINISTRATION',
            component: AdministrationDashboard,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'administration',
        requiredAccessLevels: [
            { company: 'R' },
            { employee: 'R' },
            { planning: 'R' },
            { invoicing: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'administration.overview.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_DASHBOARD,
        ],
    },

    [ROUTE_KEYS.R_EMPLOYEES]: {
        path: `${BASE_ROUTE}/employees`,
        page: {
            key: 'EMPLOYEES',
            component: Employees,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'employees',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_EMPLOYEE_DETAILS]: {
        path: '/administration/employees/details/:id',
        page: {
            key: 'EMPLOYEE_DETAILS',
            component: Employees,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'employee_details',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.EMPLOYEES_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL]: {
        path: '/administration/employees/details/:id/medical-examinations/executed/:examinationId',
        page: {
            key: 'EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
            component: Employees,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'employee_details_medical_examination_executed_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.medical_examination.executed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.EMPLOYEES_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EXECUTED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_PLANNED_DETAIL]: {
        path: '/administration/employees/details/:id/medical-examinations/planned/:examinationId',
        page: {
            key: 'EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
            component: Employees,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'employee_details_medical_examination_planned_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.medical_examination.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.EMPLOYEES_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL]: {
        path: '/administration/employees/details/:id/courses/followed/:coursesOrganizedId',
        page: {
            key: 'EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL',
            component: Employees,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'employee_details_courses_followed_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.followed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.EMPLOYEES_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL_ATTENDANT]: {
        path: '/administration/employees/details/:id/courses/followed/:coursesOrganizedId/attendance/:employeeId',
        page: {
            key: 'EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL_ATTENDANT',
            component: Employees,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'employee_details_courses_followed_detail_attendant',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.followed.attendant_detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.EMPLOYEES_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.FOLLOWED_COURSE_DETAIL_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_PLANNED_DETAIL]: {
        path: '/administration/employees/details/:id/courses/planned/:coursesOrganizedId',
        page: {
            key: 'EMPLOYEE_DETAILS_COURSES_PLANNED_DETAIL',
            component: Employees,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'employee_details_courses_planned_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.EMPLOYEES_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_EMPLOYEES_ADD_WIZARD]: {
        path: `${BASE_ROUTE}/employees/add/:step`,
        page: {
            key: 'EMPLOYEES_ADD_WIZARD',
            component: AddEmployeeWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'administration.employees.add.title',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'employees_add',
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        requiredAccessLevels: [
            { employee: 'W' },
        ],
        breadcrumbsLabel: {
            translationKey: 'administration.employees.add.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_EMPLOYEES_ADD_EMAILS]: {
        path: `${BASE_ROUTE}/employees/add-emails`,
        page: {
            key: 'EMPLOYEES_ADD_EMAILS',
            component: AddEmails,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'administration.employees.add_emails.title',
            },
            hideExitLink: true,
        } as IWizardTemplateProps,
        virtualPage: 'employees_add_emails',
        requiredAccessLevels: [
            { employee: 'W' },
        ],
        breadcrumbsLabel: {
            translationKey: 'administration.employees.add_emails.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_FUNCTIONS]: {
        path: `${BASE_ROUTE}/company-functions`,
        page: {
            key: 'COMPANY_FUNCTIONS',
            component: Functions,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'company_functions',
        breadcrumbsLabel: {
            translationKey: 'administration.company_functions.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_FUNCTIONS_ADD]: {
        path: `${BASE_ROUTE}/company-functions/add`,
        page: {
            key: 'COMPANY_FUNCTIONS_ADD',
            component: Functions,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'W' },
        ],
        virtualPage: 'company_functions_add',
        breadcrumbsLabel: {
            translationKey: 'administration.company_functions_add.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL]: {
        path: `${BASE_ROUTE}/company-functions/:functionId`,
        page: {
            key: 'COMPANY_FUNCTION_DETAIL',
            component: Functions,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'company_function_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.company_function.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL]: {
        path: `${BASE_ROUTE}/company-functions/:functionId/employee/:id`,
        page: {
            key: 'COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL',
            component: Functions,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'company_function_detail_employee_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.company_function.employees.employee_detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL]: {
        path: `${BASE_ROUTE}/company-functions/:functionId/employee/:id/medical-examinations/executed/:examinationId`,
        page: {
            key: 'COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
            component: Functions,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'company_function_medical_examination_executed_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.medical_examination.executed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EXECUTED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL]: {
        path: `${BASE_ROUTE}/company-functions/:functionId/employee/:id/medical-examinations/planned/:examinationId`,
        page: {
            key: 'COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
            component: Functions,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'company_function_medical_examination_planned_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.medical_examination.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_COURSES_PLANNED_DETAIL]: {
        path: `${BASE_ROUTE}/company-functions/:functionId/employee/:id/courses/planned/:coursesOrganizedId`,
        page: {
            key: 'COMPANY_FUNCTION_DETAIL_COURSES_PLANNED',
            component: Functions,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'company_function_courses_planned_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_COURSES_FOLLOWED_DETAIL]: {
        path: `${BASE_ROUTE}/company-functions/:functionId/employee/:id/courses/followed/:coursesOrganizedId`,
        page: {
            key: 'COMPANY_FUNCTION_DETAIL_COURSES_FOLLOWED',
            component: Functions,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'company_function_courses_followed_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.followed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_COMPANY_RISKS]: {
        path: `${BASE_ROUTE}/company-risks`,
        page: {
            key: 'COMPANY_RISKS',
            component: Risks,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'company_risks',
        breadcrumbsLabel: {
            translationKey: 'administration.company_risks_and_dangers.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_INFO_GENERAL]: {
        path: `${COMPANY_INFO_ROUTE}/general`,
        page: {
            key: 'COMPANY_INFO_GENERAL',
            component: CompanyInfoGeneral,
        },
        template: CompanyInfoTemplate,
        requiredAccessLevels: [
            { company: 'R' },
        ],
        virtualPage: 'company_info_general',
        breadcrumbsLabel: {
            translationKey: 'administration.company_info.general.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_INFO_SEATS]: {
        path: `${COMPANY_INFO_ROUTE}/seats`,
        page: {
            key: 'COMPANY_INFO_SEATS',
            component: CompanyInfoSeats,
        },
        template: CompanyInfoTemplate,
        requiredAccessLevels: [
            { company: 'R' },
        ],
        virtualPage: 'company_info_seats',
        breadcrumbsLabel: {
            translationKey: 'administration.company_info.seats.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL]: {
        path: `${COMPANY_INFO_ROUTE}/seats/:companyCode`,
        page: {
            key: 'COMPANY_INFO_SEATS_DETAIL',
            component: CompanyInfoSeats,
        },
        template: CompanyInfoTemplate,
        requiredAccessLevels: [
            { company: 'R' },
        ],
        virtualPage: 'company_info_seat_details',
        breadcrumbsLabel: {
            translationKey: 'administration.company_info.seats.detail.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL]: {
        path: `${COMPANY_INFO_ROUTE}/seats/:companyCode/contacts/:id`,
        page: {
            key: 'COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL',
            component: CompanyInfoSeats,
        },
        template: CompanyInfoTemplate,
        requiredAccessLevels: [
            { company: 'R' },
        ],
        virtualPage: 'company_info_seat_detail_contact_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.company_info.seats.contacts_detail.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_INFO_CONTACTS]: {
        path: `${COMPANY_INFO_ROUTE}/contacts`,
        page: {
            key: 'COMPANY_INFO_CONTACTS',
            component: CompanyInfoContacts,
        },
        template: CompanyInfoTemplate,
        requiredAccessLevels: [
            { company: 'R' },
        ],
        virtualPage: 'company_info_contacts',
        breadcrumbsLabel: {
            translationKey: 'administration.company_info.contacts.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_INFO_CONTACT_DETAIL]: {
        path: `${COMPANY_INFO_ROUTE}/contacts/:id`,
        page: {
            key: 'COMPANY_INFO_SEATS_CONTACT_DETAIL',
            component: CompanyInfoContacts,
        },
        template: CompanyInfoTemplate,
        requiredAccessLevels: [
            { company: 'R' },
        ],
        virtualPage: 'company_info_contact_details',
        breadcrumbsLabel: {
            translationKey: 'administration.company_info.contacts.detail.breadcrumbs_label',
        },
        groups: [ROUTE_GROUP.COMPANY_CONTACTS_FETCH_IF_NOT_AVAILABLE],
    },

    [ROUTE_KEYS.R_INVOICES_OPEN]: {
        path: `${INVOICES_ROUTE}/open`,
        page: {
            key: 'OPEN_INVOICES',
            component: OpenInvoices,
        },
        template: InvoicesTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'open_invoices',
        breadcrumbsLabel: {
            translationKey: 'administration.invoices.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_INVOICES_PAID]: {
        path: `${INVOICES_ROUTE}/paid`,
        page: {
            key: 'PAID_INVOICES',
            component: PaidInvoices,
        },
        template: InvoicesTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'paid_invoices',
        breadcrumbsLabel: {
            translationKey: 'administration.invoices.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_COMPANY_RATES]: {
        path: `${BASE_ROUTE}/rates/standard`,
        page: {
            key: 'RATES',
            component: Rates,
        },
        template: RatesTemplate,
        requiredAccessLevels: [
            { company: 'R' },
        ],
        virtualPage: 'company_rates',
        breadcrumbsLabel: {
            translationKey: 'administration.rates.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_RATES_PARAMETERS]: {
        path: `${BASE_ROUTE}/rates/parameters`,
        page: {
            key: 'RATES_PARAMETERS',
            component: Parameters,
        },
        template: RatesTemplate,
        requiredAccessLevels: [
            { company: 'R' },
        ],
        virtualPage: 'company_rates_parameters',
        breadcrumbsLabel: {
            translationKey: 'administration.rates.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_VALID_SITUATION_HISTORY,
        ],
    },

    [ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW]: {
        path: `${BASE_ROUTE}/prevention-units/overview`,
        page: {
            key: 'PREVENTION_UNITS_OVERVIEW',
            component: PEOverviewCurrentYear,
        },
        template: PreventionUnitsTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'prevention_units_overview',
        breadcrumbsLabel: {
            translationKey: 'administration.prevention_units.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_PREVENTION_UNITS,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_PREVENTION_UNITS_OVERVIEW_DETAIL]: {
        path: `${BASE_ROUTE}/prevention-units/overview/:type`,
        page: {
            key: 'PREVENTION_UNITS_OVERVIEW_DETAIL',
            component: PEOverviewCurrentYear,
        },
        template: PreventionUnitsTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'prevention_units_overview_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.prevention_units.overview.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_PREVENTION_UNITS,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR]: {
        path: `${BASE_ROUTE}/prevention-units/previous-year`,
        page: {
            key: 'PREVENTION_UNITS_PREVIOUS_YEAR',
            component: PEOverviewPreviousYear,
        },
        template: PreventionUnitsTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'prevention_units_previous_year',
        breadcrumbsLabel: {
            translationKey: 'administration.prevention_units.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_PREVENTION_UNITS,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_PREVENTION_UNITS_PREVIOUS_YEAR_DETAIL]: {
        path: `${BASE_ROUTE}/prevention-units/previous-year/:type`,
        page: {
            key: 'PREVENTION_UNITS_PREVIOUS_YEAR_DETAIL',
            component: PEOverviewPreviousYear,
        },
        template: PreventionUnitsTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'prevention_units_previous_year_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.prevention_units.previous_year.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_PREVENTION_UNITS,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_PREVENTION_UNITS_ARCHIVE]: {
        path: `${BASE_ROUTE}/prevention-units/archive`,
        page: {
            key: 'PREVENTION_UNITS_ARCHIVE',
            component: PreventionUnitsArchive,
        },
        template: PreventionUnitsTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'prevention_units_archive',
        breadcrumbsLabel: {
            translationKey: 'administration.prevention_units.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_PREVENTION_UNITS,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_FLAT_FEE_OVERVIEW]: {
        path: `${BASE_ROUTE}/flat-fee`,
        page: {
            key: 'FLAT_FEE',
            component: FlatFee,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'flat_fee',
        breadcrumbsLabel: {
            translationKey: 'administration.flat_fee.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_FLAT_FEE,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_FLAT_FEE_DETAIL]: {
        path: `${BASE_ROUTE}/flat-fee/details/:yearAndType`,
        page: {
            key: 'FLAT_FEE_DETAIL',
            component: FlatFee,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { invoicing: 'R' },
        ],
        virtualPage: 'flat_fee_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.flat_fee.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_FLAT_FEE,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK]: {
        path: `${BASE_ROUTE}/questionnaires/screenwork`,
        page: {
            key: 'QUESTIONNAIRES_SCREENWORK',
            component: QuestionnairesScreenwork,
        },
        template: QuestionnairesTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'questionnaires_screenwork',
        breadcrumbsLabel: {
            translationKey: 'administration.questionnaires.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_QUESTIONNAIRES_SCREENWORK_DETAIL]: {
        path: `${BASE_ROUTE}/questionnaires/screenwork/:id`,
        page: {
            key: 'QUESTIONNAIRES_SCREENWORK_DETAIL',
            component: QuestionnairesScreenwork,
        },
        template: QuestionnairesTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'questionnaires_screenwork_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.questionnaires.screenwork.detail.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS]: {
        path: `${BASE_ROUTE}/questionnaires/job-students`,
        page: {
            key: 'QUESTIONNAIRES_JOB_STUDENTS',
            component: QuestionnairesJobStudents,
        },
        template: QuestionnairesTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'questionnaires_job_students',
        breadcrumbsLabel: {
            translationKey: 'administration.questionnaires.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_QUESTIONNAIRES_JOB_STUDENTS_DETAIL]: {
        path: `${BASE_ROUTE}/questionnaires/job-students/:id`,
        page: {
            key: 'QUESTIONNAIRES_JOB_STUDENTS_DETAIL',
            component: QuestionnairesJobStudents,
        },
        template: QuestionnairesTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'questionnaires_job_students_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.questionnaires.job_students.detail.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_PERFORMANCE_DASHBOARD]: {
        path: '/performance-dashboard',
        page: {
            key: 'PERFORMANCE_DASHBOARD',
            component: PerformanceDashboard,
        },
        template: PerformanceTemplate,
        virtualPage: 'performance_dashboard_tutorial',
        breadcrumbsLabel: {
            translationKey: 'administration.overview.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_DASHBOARD,
        ],
    },
};

export default ROUTES;
