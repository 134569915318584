/**
 * Wizard steps can be registered here to avoid circular dependencies.
 * Otherwise configs or actions - which may need the first stepId of a wizard to be able
 * to navigate to that first step - would have a reference to the wizard component/view.
 */

import { INTERVENTION_REQUEST_WIZARD_STEP_ID } from '../../models/interventions/requestIntervention';
import { COMPANY_VISITS_REQUEST_WIZARD_STEP_ID } from '../../models/interventions/companyVisits';
import { FLU_VACCINES_ORDER_WIZARD_STEP_ID } from '../../models/interventions/fluVaccines';
import { WORK_POST_CARD_WIZARD_STEP_ID } from '../../models/documentCenter/workPostCards';
import { COURSE_ENROLL_WIZARD_STEP_ID, ICourseEnrollWizardStep } from '../../models/documentCenter/courses';
import { IStepperStep } from '../../models/general/stepper';
import { ONBOARDING_WIZARD_STEP_ID } from '../../models/onboarding/wizard';
import {
    IPlanMedicalExaminationWizardStep,
    PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../models/interventions/medicalExaminations';
import { PLAN_BUFFERZONE_WIZARD_STEP_ID, IPlanBufferzoneWizardStep } from '../../models/interventions/bufferzones';

export enum WIZARD_CODE {
    ADD_EMPLOYEE = 'ADD_EMPLOYEE',
    CREATE_WORK_POST_CARD = 'CREATE_WORK_POST_CARD',
    ENROLL_COURSE = 'ENROLL_COURSE',
    ORDER_FLU_VACCINES = 'ORDER_FLU_VACCINES',
    REQUEST_INTERVENTION = 'REQUEST_INTERVENTION',
    REQUEST_COMPANY_VISIT = 'REQUEST_COMPANY_VISIT',
    ONBOARDING = 'ONBOARDING',

    PLAN_MED_EXAM_PO_MANUAL = 'PLAN_MED_EXAM_PO_MANUAL',
    PLAN_MED_EXAM_PO_AUTOMATIC = 'PLAN_MED_EXAM_PO_AUTOMATIC',
    PLAN_MED_EXAM_INTERIM = 'PLAN_MED_EXAM_INTERIM',
    PLAN_MED_EXAM_RESUMPTION_OF_WORK = 'PLAN_MED_EXAM_RESUMPTION_OF_WORK',
    PLAN_MED_EXAM_RE_INTEGRATION = 'PLAN_MED_EXAM_RE_INTEGRATION',
    PLAN_MED_EXAM_OTHER = 'PLAN_MED_EXAM_OTHER',
    PLAN_MED_EXAM_MOVE_PLANNING = 'PLAN_MED_EXAM_MOVE_PLANNING',

    PLAN_BUFFERZONE = 'PLAN_BUFFERZONE',
}

interface IRegisteredWizardSteps<Step, StepId> {
    stepIds: string[];
    firstStepId: StepId;
    steps: Step[];
}

interface IWizardStepsMap<Step extends IStepperStep, StepId = string> {
    [wizardCode: string]: IRegisteredWizardSteps<Step, StepId>;
}

const wizardStepsMap: IWizardStepsMap<IStepperStep> = {};

export function registerWizardSteps({
    wizardCode,
    steps,
}: {
    wizardCode: WIZARD_CODE,
    steps: IStepperStep[],
}) {
    wizardStepsMap[wizardCode] = {
        stepIds: steps.map((step) => step.id),
        firstStepId: steps[0].id,
        steps,
    };
}

function getWizardSteps<Step extends IStepperStep, StepId = string>({
    wizardCode,
}: {
    wizardCode: WIZARD_CODE,
}) {
    return (wizardStepsMap[wizardCode] || {}) as IRegisteredWizardSteps<Step, StepId>;
}

export function getRequestInterventionWizardSteps() {
    return getWizardSteps<IStepperStep, INTERVENTION_REQUEST_WIZARD_STEP_ID>({
        wizardCode: WIZARD_CODE.REQUEST_INTERVENTION,
    });
}

export function getAddEmployeeWizardSteps() {
    return getWizardSteps<IStepperStep>({
        wizardCode: WIZARD_CODE.ADD_EMPLOYEE,
    });
}

export function getRequestCompanyVisitWizardSteps() {
    return getWizardSteps<IStepperStep, COMPANY_VISITS_REQUEST_WIZARD_STEP_ID>({
        wizardCode: WIZARD_CODE.REQUEST_COMPANY_VISIT,
    });
}

export function getOrderFluVaccinesWizardSteps() {
    return getWizardSteps<IStepperStep, FLU_VACCINES_ORDER_WIZARD_STEP_ID>({
        wizardCode: WIZARD_CODE.ORDER_FLU_VACCINES,
    });
}

export function getCreateWorkPostCardWizardSteps() {
    return getWizardSteps<IStepperStep, WORK_POST_CARD_WIZARD_STEP_ID>({
        wizardCode: WIZARD_CODE.CREATE_WORK_POST_CARD,
    });
}

export function getEnrollCourseWizardSteps() {
    return getWizardSteps<ICourseEnrollWizardStep, COURSE_ENROLL_WIZARD_STEP_ID>({
        wizardCode: WIZARD_CODE.ENROLL_COURSE,
    });
}

export function getOnboardingWizardSteps() {
    return getWizardSteps<IStepperStep, ONBOARDING_WIZARD_STEP_ID>({
        wizardCode: WIZARD_CODE.ONBOARDING,
    });
}

export function getPlanBufferzoneWizardSteps() {
    return getWizardSteps<IPlanBufferzoneWizardStep, PLAN_BUFFERZONE_WIZARD_STEP_ID>({
        wizardCode: WIZARD_CODE.PLAN_BUFFERZONE,
    });
}

const PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE_2_WIZARD_CODE = {
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL]:
        WIZARD_CODE.PLAN_MED_EXAM_PO_MANUAL,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC]:
        WIZARD_CODE.PLAN_MED_EXAM_PO_AUTOMATIC,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.INTERIM_MANUAL]:
        WIZARD_CODE.PLAN_MED_EXAM_INTERIM,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RESUMPTION_OF_WORK_MANUAL]:
        WIZARD_CODE.PLAN_MED_EXAM_RESUMPTION_OF_WORK,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.RE_INTEGRATION_MANUAL]:
        WIZARD_CODE.PLAN_MED_EXAM_RE_INTEGRATION,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.OTHER_MANUAL]:
        WIZARD_CODE.PLAN_MED_EXAM_OTHER,
    [PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.MOVE_PLANNING]:
        WIZARD_CODE.PLAN_MED_EXAM_MOVE_PLANNING,
};

export function getPlanMedicalExaminatonWizardSteps(planMedExamWizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE) {
    const wizardCode = PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE_2_WIZARD_CODE[planMedExamWizardType];

    return getWizardSteps<IPlanMedicalExaminationWizardStep, PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID>({
        wizardCode,
    });
}
