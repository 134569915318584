import { ICompanyBufferzone } from '../admin/company';
import {
    IMedicalExaminationToAdd,
    IMedicalExaminationToPlan,
    IPlannedMedicalExamination,
} from './medicalExaminations';
import { IStepperStep } from '../general/stepper';
import { ITimeCell } from './timeslots';

export interface IPlanBufferzoneWizardStep extends IStepperStep {
    hide?: (entity?: IPlanBufferzoneWizardEntity) => boolean;
}

export enum PLAN_BUFFERZONE_WIZARD_STEP_ID {
    SELECT_BUFFERZONE = 'select-bufferzone',
    SELECT_EMPLOYEES = 'select-employees',
    PLANNING = 'planning',
    OVERVIEW = 'overview',
    TEST = 'test',
}

export interface IPlanBufferzoneWizardPayload {
    step: PLAN_BUFFERZONE_WIZARD_STEP_ID;
    resetDataEntity: boolean;
    skipToStep?: boolean;
}

export interface IPlanBufferzoneWizardEntity {
    selectedBufferzone: {
        selectedFromOverview?: boolean;
    } & ICompanyBufferzone;
    employeesToPlan: IMedicalExaminationToAdd[];
    addedEmployees: IMedicalExaminationToPlan[];
    modifiedTimeCellIds: number[]; // Used to toggle list view on overview step
}

export interface IPlanBufferzoneWizardData {
    stepId: string;
    entity: Partial<IPlanBufferzoneWizardEntity>;
}

export interface ISkipToPlanBufferzoneWizardStepPayload {
    entity?: IPlanBufferzoneWizardEntity;
    wizardPayload: Pick<IPlanBufferzoneWizardPayload, 'step'>;
}

// Plan Bufferzone Actions
export interface IBufferzonePlanningCreateNewPlanningPayload {
    employeeToPlan: IMedicalExaminationToAdd;
    timeslot: ITimeCell;
}

export interface IBufferzonePlanningMoveExistingPlanningPayload {
    examination: IPlannedMedicalExamination;
    newTimeslot: ITimeCell;
}

export interface IBufferzonePlanningCancelExistingPlanningPayload {
    examination: IPlannedMedicalExamination;
}
