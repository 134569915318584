import { IAsyncEntityKey2ApiConfigMap } from '@snipsonian/redux-features/es/entities/types';
import { ASYNC_ENTITY_KEYS } from '../../models/entities/entities.models';
import { IFetchTemplatesCategories } from '../../models/documentCenter/templates';
import api from '../../api';
import { IState } from '../../redux';
import { getSelectedCompanyCode, getSelectedSeatCompanyCode } from '../../redux/company/selected/selectors';
import { IFetchCookiesConfigurationInput } from '../../models/general/cookies';
import { getLocale } from '../../redux/i18n/selectors';

const entityKey2FetchApiConfigMap: IAsyncEntityKey2ApiConfigMap<IState> = {
    [ASYNC_ENTITY_KEYS.companyAdministrators]: {
        fetch: {
            api: api.user.admin.fetchAdministrators,
            apiInputSelector: ({ state }) => getSelectedSeatCompanyCode(state),
        },
    },
    [ASYNC_ENTITY_KEYS.maintenanceMessages]: {
        fetch: {
            api: api.general.maintenance.fetchMaintenanceMessages,
        },
    },
    [ASYNC_ENTITY_KEYS.spotlightCourse]: {
        fetch: {
            api: api.documentCenter.courses.fetchSpotlightCourse,
        },
    },
    [ASYNC_ENTITY_KEYS.templates]: {
        fetch: {
            api: api.documentCenter.templates.fetchTemplatesCategories,
            apiInputSelector: fetchTemplatesCategoriesApiInputSelector,
        },
    },
    [ASYNC_ENTITY_KEYS.templatesCategory]: {
        fetch: {
            api: api.documentCenter.templates.fetchTemplatesCategoryDocuments,
        },
    },
    [ASYNC_ENTITY_KEYS.themeOfTheMonth]: {
        fetch: {
            api: api.general.cms.fetchThemeOfTheMonth,
        },
    },
    [ASYNC_ENTITY_KEYS.courseLinks]: {
        fetch: {
            api: api.general.cms.fetchCourseLinks,
        },
    },
};

export default entityKey2FetchApiConfigMap;

export function fetchTemplatesCategoriesApiInputSelector({ state }: { state: IState }): IFetchTemplatesCategories {
    return {
        companyCode: getSelectedCompanyCode(state),
    };
}

export function fetchCookiesConfigurationApiInputSelector(
    { state }: { state: IState },
): IFetchCookiesConfigurationInput {
    return {
        locale: getLocale(state),
    };
}
