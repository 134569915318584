import { get, IResponseType, post } from '../../utils/api/requestWrapper';
import {
    IDocument,
    IFetchDocumentPayload,
    ICreateConvocationsPayload,
    IDocumentSkippableDownload,
    IUploadEmployeeDocumentPayload,
    IUploadEmployeeDocumentSuccessPayload,
    IFetchActivityReportsPayload,
    IActivityReport,
    IGenerateActivityReportPayload,
    IGenerateCompanyAndEmployeeReportPayload,
    IFetchActivityReportDetailPayload,
    IConvocationRecipient,
} from '../../models/general/documents';
import getFilenameFromHeaders from './getFilenameFromHeaders';
import buildMultiPartForm from '../../utils/api/buildMultiPartForm';
import { HEADER_NAME } from '../../config/api.config';
import { ONE_MINUTE } from '../../utils/core/time/periodsInMillis';
import { ICompanyCodePayload } from '../../models/admin/company';
import { reportErrorMessage } from '../../utils/logging/errorReporter';
import ROUTE_KEYS from '../../routeKeys';

export const URL = {
    DOCUMENT: '/documents',
    CONVOCATIONS: '/convocations',
    CONVOCATION_RECIPIENTS: '/companies/{companyCode}/convocation-recipients',
    UPLOAD_EMPLOYEE_DOCUMENT: '/uploads/employees/{id}/documents',
    ACTIVITY_REPORTS: '/companies/{companyCode}/activity-report/{year}',
    ACTIVITY_REPORT_GENERATE: '/companies/{companyCode}/activity-report',
    ACTIVITY_REPORT_DETAIL: '/companies/{companyCode}/activity-report/{year}/{id}',
    COMPANY_INFO_REPORT: '/companies/{companyCode}/info-report',
};

export function fetchDocuments(
    { ids, defaultFilename }: IFetchDocumentPayload,
    { routeKeyForErrorLogging }: { routeKeyForErrorLogging: ROUTE_KEYS },
) {
    // KZUAT 1902: We're getting unknown BE errors for the /documents call because the ids parameter is empty
    // We're not sure where the error occurs, thats why we're logging this specific error.
    if (!isIdsPayloadValid()) {
        reportErrorMessage(
            'Trying to fetch documents with empty or invalid ids parameter',
            {
                extra: {
                    routeKey: routeKeyForErrorLogging,
                },
            },
        );
    }

    return get<IDocument>({
        url: URL.DOCUMENT,
        timeoutInMillis: 5 * ONE_MINUTE,
        queryParams: {
            ids: ids.map(item => item).join(','),
        },
        responseType: IResponseType.blob,
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, defaultFilename),
            };
        },
    });

    function isIdsPayloadValid() {
        if (!ids || ids.length <= 0) {
            return false;
        }
        return ids.findIndex((id) => !id) === -1;
    }
}


export function createConvocations(payload: ICreateConvocationsPayload[], seatId: number) {
    return post<IDocumentSkippableDownload>({
        responseType: IResponseType.blob,
        url: URL.CONVOCATIONS,
        timeoutInMillis: 5 * ONE_MINUTE,
        body: payload.map((item) => ({
            ...item,
            seatId,
        })),
    });
}

export function uploadEmployeeDocument(payload: IUploadEmployeeDocumentPayload) {
    const { file } = payload;
    const formData = buildMultiPartForm({
        mediaUpload: file,
        description: file.name,
    });

    return post<IUploadEmployeeDocumentSuccessPayload>({
        url: URL.UPLOAD_EMPLOYEE_DOCUMENT,
        pathParams: {
            id: payload.id,
        },
        body: formData,
        mapResponse: (response) => {
            return {
                savedDocumentId: response.savedDocumentId,
            } as IUploadEmployeeDocumentSuccessPayload;
        },
    });
}

export function fetchActivityReports(payload: IFetchActivityReportsPayload) {
    return get<IActivityReport[]>({
        url: URL.ACTIVITY_REPORTS,
        pathParams: {
            companyCode: payload.companyCode,
            year: payload.year,
        },
        queryParams: {
            showFullFamily: payload.showFullFamily,
        },
        mapResponse: (response) => response['activity-reports'],
        timeoutInMillis: ONE_MINUTE,
    });
}

export function generateActivityReport(payload: IGenerateActivityReportPayload) {
    return post({
        url: URL.ACTIVITY_REPORT_GENERATE,
        pathParams: {
            companyCode: payload.companyCode,
        },
        queryParams: {
            showFullFamily: payload.showFullFamily,
            individualSeats: payload.individualSeats,
        },
        headers: {
            [HEADER_NAME.LOCALE]: payload.locale,
        },
    });
}

export function generateCompanyAndEmployeeReport(payload: IGenerateCompanyAndEmployeeReportPayload) {
    const { companyCode, locale, showFullFamily, ...body } = payload;
    return post({
        url: URL.COMPANY_INFO_REPORT,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily,
        },
        body,
        headers: {
            [HEADER_NAME.LOCALE]: locale,
        },
    });
}

export function fetchActivityReportDetail(payload: IFetchActivityReportDetailPayload) {
    const { companyCode, showFullFamily, id, year } = payload;
    return get<IDocument>({
        url: URL.ACTIVITY_REPORT_DETAIL,
        responseType: IResponseType.blob,
        timeoutInMillis: 2 * ONE_MINUTE,
        pathParams: {
            companyCode,
            year,
            id,
        },
        queryParams: {
            showFullFamily,
        },
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, 'activity-report-detail.xls'),
            };
        },
    });
}

export function fetchConvocationRecipients(payload: ICompanyCodePayload) {
    return get<IConvocationRecipient[]>({
        url: URL.CONVOCATION_RECIPIENTS,
        pathParams: {
            companyCode: payload.companyCode,
        },
        mapResponse: (response) => response['recipients'],
    });
}
