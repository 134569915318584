import React, { PureComponent, ComponentType, ReactElement } from 'react';
import './filterable-calendar.scss';
import classNames from 'classnames';
import Calendar, { IHeaderItemProps } from '../Calendar';
import DatePicker from '../DateTimePicker/DatePicker';
import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../config/calendar.config';
import { CalendarEventType, ICalendarEvent } from '../../../../models/ui/calendar';
import { IAsyncFieldInfo } from '../../../../models/general/redux';
import { IState, NO_RERENDER } from '../../../../redux';
import connect from '../../../../utils/libs/redux/connect';
import { getEventTitle } from '../Calendar/EventTitle';
import { ITranslator } from '../../../../models/general/i18n';
import Loader from '../../waiting/Loader';
import ErrorPlaceholder from '../../error/ErrorPlaceholder';
import {
    getDate, getDateWithoutTime,
    getFirstWorkday, getNextWorkday,
} from '../../../../utils/core/date/getSpecificDate';
import getDaysInMonthView from '../../../../utils/libs/flatpickr/getDaysInMonthView';
import debounce, { TDebounced } from '../../../../utils/core/debounce';
import Sticky from '../../technical/Sticky';
import { createSelector } from 'reselect';
import * as screenSizeUtils from '../../../../utils/dom/screenSize';
import getOverlappingEventsCount from '../../../../utils/calendar/getOverlappingEventsCount';
import { ICustomDayStyle } from '../DateTimePicker/DatePicker/customDayPlugin';
import EventTypeFilter from '../Calendar/EventTypeFilter';
import { View } from 'react-big-calendar';
import { Locales } from '../../../../config/i18n.config';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';

const CLASS_NAME = 'FilterableCalendar';
const CLASS_NAME_DATEPICKER_FREE_TIMESLOTS = `${CLASS_NAME}__DatePicker__day--with-free-timeslots`;
const CLASS_NAME_DATEPICKER_WITH_EVENTS = `${CLASS_NAME}__DatePicker__day--with-events`;

interface IComponentState {
    eventTypesIncludedInFilter: CalendarEventType[];
    calendarEvents: ICalendarEvent[];
    selectedEvent: ICalendarEvent;
    customDayStyles: ICustomDayStyle[];
    isSmallScreen: boolean;
}

interface IFilterableCalendarProps {
    asyncInfoSelector: (state: IState) => IAsyncFieldInfo;
    dataSelector: (state: IState) => ICalendarEvent[];
    allowFilteringOnEventType?: boolean;
    filterChildrenComponent?: ComponentType<{}>;
    view?: 'day' | 'agenda' | 'week';
    hideTodayButton?: boolean;
    hideViewToggle?: boolean;
    hideAllDaySection?: boolean;
    toolbarChildrenComponent?: ComponentType<{}>;
    headerItemComponent?: ComponentType<IHeaderItemProps>;
    selectedDate: string;
    onSelectedDateChanged: (selectedDate: string, datesShownInMonthView: {
        start: string;
        end: string;
    }) => void;
    selectedEventId: string;
    onEventSelected: (event: ICalendarEvent) => void;
    hideCurrentTimeIndicator?: boolean;
    minDate?: string;
    maxDate?: string;
    hideDatepicker?: boolean;
    highlightDatepickerType?: 'free-timeslots' | 'event-types';
    onViewChanged?: (view: View) => void;
    renderLegend?: () => ReactElement<{}>;
}

interface IPrivateProps {
    events: ICalendarEvent[];
    asyncInfo: IAsyncFieldInfo;
}

interface IContextProps {
    translator: ITranslator;
    locale: Locales;
}

type IProps = IPrivateProps & IFilterableCalendarProps & IContextProps;

// eslint-disable-next-line max-len
class FilterableCalendarComp extends PureComponent<IProps, IComponentState> {
    private onMontChangeDebounced: TDebounced<[number, number]>;
    private onResizeDebounced: TDebounced;

    constructor(props: IProps) {
        super(props);

        const eventTypesIncludedInFilter = Object.values(CalendarEventType);
        const calendarEvents = this.getEventsForCalendar(eventTypesIncludedInFilter);
        this.state = {
            eventTypesIncludedInFilter,
            calendarEvents,
            selectedEvent: this.getSelectedEvent(calendarEvents),
            customDayStyles: this.getCustomDayStyles(props, calendarEvents),
            isSmallScreen: screenSizeUtils.isSmallScreen(),
        };

        this.onEventTypeFilterChange = this.onEventTypeFilterChange.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onResize = this.onResize.bind(this);

        this.onMontChangeDebounced = debounce(this.onMonthChange, 500);
        this.onResizeDebounced = debounce(this.onResize, 100);

        window.addEventListener('resize', this.onResizeDebounced);
    }

    public render() {
        return (
            <div className={CLASS_NAME}>
                {this.props.asyncInfo.error
                    ? this.renderError()
                    : this.renderContent()
                }
            </div>
        );
    }

    public componentDidUpdate(prevProps: IProps, prevState: IComponentState) {
        const filterOrEventsChanged = (
            prevProps.events !== this.props.events ||
            prevProps.allowFilteringOnEventType !== this.props.allowFilteringOnEventType ||
            prevState.eventTypesIncludedInFilter !== this.state.eventTypesIncludedInFilter
        );
        if (
            filterOrEventsChanged ||
            prevProps.selectedEventId !== this.props.selectedEventId ||
            prevProps.translator !== this.props.translator
        ) {
            return this.updateEventsDataState(filterOrEventsChanged);
        }
    }

    public componentWillUnmount() {
        this.onMontChangeDebounced.cancel();
        this.onResizeDebounced.cancel();
        window.removeEventListener('resize', this.onResizeDebounced);
    }

    private renderContent() {
        const {
            allowFilteringOnEventType, view, onViewChanged,
            toolbarChildrenComponent, hideViewToggle,
            hideTodayButton, asyncInfo, selectedDate,
            onEventSelected, hideCurrentTimeIndicator,
            minDate, maxDate, headerItemComponent, hideAllDaySection,
            hideDatepicker, filterChildrenComponent: FilterChildrenComponent,
            renderLegend,
        } = this.props;
        const {
            customDayStyles, calendarEvents,
            selectedEvent, isSmallScreen,
            eventTypesIncludedInFilter,
        } = this.state;
        const topOffset = isSmallScreen ? 10 : 60;
        return (
            <>
                <Loader show={asyncInfo.status} />
                <div className={`${CLASS_NAME}__content-left`}>
                    <Sticky id="FilterableCalendarLeft" topOffset={topOffset}>
                        {!hideDatepicker && !isSmallScreen && <DatePicker
                            id="agenda-date-picker"
                            value={selectedDate}
                            inlineCalendar={true}
                            hideTextInput={true}
                            onChange={this.onNavigate}
                            onMonthChange={this.onMontChangeDebounced}
                            customDayStyles={customDayStyles}
                            minDate={minDate}
                            maxDate={maxDate}
                            highlightWeek={true}
                        />}
                        {typeof renderLegend === 'function' ? renderLegend() : null}
                        {FilterChildrenComponent && <FilterChildrenComponent />}
                        {allowFilteringOnEventType && (
                            <EventTypeFilter
                                eventTypesIncludedInFilter={eventTypesIncludedInFilter}
                                onFilterChange={this.onEventTypeFilterChange}
                            />
                        )}
                    </Sticky>
                </div>
                <div className={`${CLASS_NAME}__content-right`}>
                    <Calendar
                        events={calendarEvents}
                        selectable={false}
                        selectedEvent={selectedEvent}
                        selectedDate={selectedDate || formatDateForBackend(new Date())}
                        onNavigate={this.onNavigate}
                        onViewChanged={onViewChanged}
                        onSelectEvent={onEventSelected}
                        hideTodayButton={hideTodayButton}
                        hideViewToggle={hideViewToggle}
                        minDate={minDate}
                        maxDate={maxDate}
                        toolbarChildrenComponent={toolbarChildrenComponent}
                        headerItemComponent={headerItemComponent}
                        hideCurrentTimeIndicator={hideCurrentTimeIndicator}
                        hideAllDaySection={hideAllDaySection}
                        view={view}
                        inlineDatePicker={isSmallScreen}
                        customDayStyles={customDayStyles}
                    />
                </div>
            </>
        );
    }

    private renderError() {
        const { asyncInfo } = this.props;

        return (
            <ErrorPlaceholder apiError={asyncInfo.error} />
        );
    }

    private onEventTypeFilterChange(newSelectedEventTypes: CalendarEventType[]) {
        this.setState({
            eventTypesIncludedInFilter: newSelectedEventTypes,
        });
    }

    private onNavigate(selectedDate: Date | string) {
        const { locale, onSelectedDateChanged, minDate, maxDate } = this.props;
        const dateObj = typeof selectedDate === 'string' ? getDate(selectedDate) : selectedDate;
        const daysInMonthView = getDaysInMonthView({
            month: dateObj && dateObj.getMonth(),
            year: dateObj && dateObj.getFullYear(),
            locale,
            minDate: minDate && getDateWithoutTime(minDate),
            maxDate: maxDate && getDateWithoutTime(maxDate),
        });
        onSelectedDateChanged(
            formatDateForBackend(selectedDate),
            {
                start: formatDateForBackend(daysInMonthView[0]),
                end: formatDateForBackend(daysInMonthView[daysInMonthView.length - 1]),
            },
        );
    }

    private onMonthChange(month: number, year: number) {
        const { locale, onSelectedDateChanged, minDate, maxDate, selectedDate } = this.props;

        if (getDate(selectedDate).getMonth() === month) {
            return;
        }

        let newSelectedDate = getFirstWorkday(month, year);
        const minDateValue = minDate && getDate(minDate);
        const daysInMonthView = getDaysInMonthView({
            month, year, locale,
            minDate: minDateValue, maxDate: maxDate && getDate(maxDate),
        });

        if (minDateValue && minDateValue.getTime() > newSelectedDate.getTime()) {
            newSelectedDate = getNextWorkday(minDateValue);
        }

        onSelectedDateChanged(
            formatDateForBackend(newSelectedDate),
            {
                start: formatDateForBackend(daysInMonthView[0]),
                end: formatDateForBackend(daysInMonthView[daysInMonthView.length - 1]),
            },
        );
    }

    private updateEventsDataState(filterOrEventsChanged: boolean) {
        const calendarEvents = this.getEventsForCalendar(this.state.eventTypesIncludedInFilter);
        const selectedEvent = this.getSelectedEvent(calendarEvents);
        if (filterOrEventsChanged) {
            this.setState({
                calendarEvents,
                selectedEvent,
                customDayStyles: this.getCustomDayStyles(this.props, calendarEvents),
            });
        } else {
            this.setState({
                calendarEvents,
                selectedEvent,
            });
        }
    }

    private getEventsForCalendar(eventTypesIncludedInFilter: CalendarEventType[]) {
        const { allowFilteringOnEventType, events, translator } = this.props;
        const filteredEvents = allowFilteringOnEventType
            ? events.filter((event) => eventTypesIncludedInFilter.includes(event.type))
            : events;
        return filteredEvents.map((event) => ({
            ...event,
            title: getEventTitle({ event, translator, titleType: 'calendar-event' }),
            overlappingEventsCount: getOverlappingEventsCount(event, filteredEvents),
        }));
    }

    private getSelectedEvent(calendarEvents: ICalendarEvent[]) {
        return calendarEvents.find((item) => item.id === this.props.selectedEventId) || null;
    }

    private getCustomDayStyles(props: IFilterableCalendarProps, calendarEvents: ICalendarEvent[]) {
        const { highlightDatepickerType } = props;

        if (highlightDatepickerType === 'free-timeslots') {
            return this.getDaysWithFreeTimeslotsCustomDayStyles(calendarEvents);
        }
        if (highlightDatepickerType === 'event-types') {
            return this.getEventTypesCustomDayStyles(calendarEvents);
        }

        return NO_RERENDER.EMPTY_LIST;
    }

    private getDaysWithFreeTimeslotsCustomDayStyles(calendarEvents: ICalendarEvent[]) {
        const calendarEventsMappedToDays = calendarEvents.reduce(
            (accumulator, event) => {
                const dateKey = getDateWithoutTime(event.start).getTime();
                // Only show freeTimeslots in datepicker
                if (event.type === CalendarEventType.FreeTimeslot) {
                    accumulator[dateKey] = {
                        date: getDateWithoutTime(event.start),
                        reserved: accumulator[dateKey] && accumulator[dateKey].reserved ? true : event.reserved,
                    };
                }
                return accumulator;
            },
            {},
        ) as {[date: string]: { date: Date; reserved: boolean }};
        return Object.keys(calendarEventsMappedToDays).map((dateKey) => {
            const day = calendarEventsMappedToDays[dateKey];
            return {
                date: day.date,
                className: classNames(CLASS_NAME_DATEPICKER_FREE_TIMESLOTS, {
                    reserved: day.reserved,
                }),
            };
        });
    }

    private getEventTypesCustomDayStyles(calendarEvents: ICalendarEvent[]) {
        return calendarEvents.reduce(
            (accumulator, event) => {
                const eventDate = getDateWithoutTime(event.start);
                const existing = accumulator.find((item) => item.date.getTime() === eventDate.getTime());
                const typeClassName = CALENDAR_EVENT_TYPES_CONFIG[event.type].cssClassName;
                if (!existing) {
                    const el = document.createElement('span');
                    el.classList.add(CLASS_NAME_DATEPICKER_WITH_EVENTS);
                    const eventDot = document.createElement('span');
                    eventDot.classList.add(typeClassName);
                    el.appendChild(eventDot);
                    accumulator.push({
                        date: getDateWithoutTime(event.start),
                        childEl: el,
                    });
                } else if (!existing.childEl.querySelector(`.${typeClassName}`)) {
                    const eventDot = document.createElement('span');
                    eventDot.classList.add(typeClassName);
                    existing.childEl.appendChild(eventDot);
                }
                return accumulator;

            },
            [] as ICustomDayStyle[],
        );
    }

    private onResize() {
        this.setState({
            isSmallScreen: screenSizeUtils.isSmallScreen(),
        });
    }
}

function FilterableCalendar(props: IPrivateProps & IFilterableCalendarProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator, locale }) => <FilterableCalendarComp {...props} translator={translator} locale={locale} />}
        </TranslatorContext.Consumer>
    );
}

export default connect<IPrivateProps, IFilterableCalendarProps>({
    statePropsPerInstance: (state, publicProps) => {
        const eventsMemoizedSelector = makeEventsMemoizedSelector(publicProps);
        return (state) => ({
            asyncInfo: publicProps.asyncInfoSelector(state),
            events: eventsMemoizedSelector(state),
        });
    },
})(FilterableCalendar);

function makeEventsMemoizedSelector(props: IFilterableCalendarProps) {
    return createSelector(
        (state: IState) => props.dataSelector(state),
        (eventsData) => eventsData,
    );
}
