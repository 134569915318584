import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    IAsyncDoField,
    getAsyncFetchInitialState,
    getAsyncDoInitialState,
    createAsyncFetchActionHandlers,
    createAsyncDoActionHandlers,
    registerReducer,
} from '../../index';
import {
    FETCH_EMPLOYEE_DETAILS,
    FETCH_EMPLOYEE_STATUTES,
    FETCH_EMPLOYEE_ABSENCES,
    UPDATE_EMPLOYEE,
    UPDATE_EMPLOYEE_EMPLOYMENT,
    SET_EMPLOYEE_OUT_OF_SERVICE,
    UPDATE_EMPLOYEE_RISK,
    REMOVE_EMPLOYEE_RISK,
    ADD_EMPLOYEE_STATUTE,
    ADD_EMPLOYEE_ABSENCE,
    UPDATE_EMPLOYEE_ABSENCE,
    REMOVE_EMPLOYEE_ABSENCE,
    UPDATE_EMPLOYEE_ALL_FIELDS,
    FETCH_SMALL_EMPLOYEE_DETAILS,
    FETCH_EMPLOYEE_RISKS_AND_RESEARCHES,
    FETCH_EMPLOYEE_PERSONAL_RISKS,
    ADD_EMPLOYEE_PERSONAL_RISK,
    CHANGE_EMPLOYEE_OUT_OF_SERVICE,
    CLEAR_EMPLOYEE_OUT_OF_SERVICE,
    FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS,
    UPDATE_EMPLOYEE_COST_CENTER,
    UPDATE_EMPLOYEE_STATUTE,
    REMOVE_EMPLOYEE_STATUTE,
    FETCH_EMPLOYEE_JOB_STUDENT,
    UPDATE_EMPLOYEE_JOB_STUDENT,
    ADD_EMPLOYEE_JOB_STUDENT,
    FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ,
    SET_JOB_STUDENT_REQUEST_FAILED_WARNING,
    FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES,
    FETCH_EMPLOYEE_STATUS,
} from './types';
import {
    IEmployeeDetails,
    IEmployeeStatute,
    IEmployeeAbsence,
    IUpdateEmployeeSucceededPayload,
    IUpdateEmployeePayload,
    IEmployeeNewRecordPayload,
    IEmployeeRisksAndResearches,
    IPersonalRisk,
    IEmployeeMedicalExaminations,
    IEmployeeJobStudent,
    ISetJobStudentRequestFailedWarning,
    IEmployeeFunctionRisksAndResearches,
    ISelectedEmployeeStatus,
} from '../../../models/admin/employee';
import { ITraceableApiError } from '../../../models/general/error';
import { ITraceableAsyncRequestPayload } from '../../../models/general/redux';
import isTraceableApiError from '../../../utils/api/isTraceableApiError';
import ROUTE_KEYS from '../../../routeKeys';
import { REDUCER_KEYS } from '../../../config/redux.config';
import { createActionHandler } from '../../../utils/libs/redux/createActionHandler';

export const reducerKey = REDUCER_KEYS.EMPLOYEE_INFO;

export interface IReducerState {
    selectedEmployee: IAsyncFetchField<IEmployeeDetails>;
    selectedEmployeeStatus: IAsyncFetchField<ISelectedEmployeeStatus>;
    employeeDetail: IAsyncFetchField<IEmployeeDetails>;
    selectedEmployeeStatutes: IAsyncFetchField<IEmployeeStatute[]>;
    selectedEmployeeAbsences: IAsyncFetchField<IEmployeeAbsence[]>;
    updateEmployeeRequests: { [requestId: string]: IAsyncDoField };
    updateEmployeeEmployment: IAsyncDoField;
    updateEmployeeCostCenter: IAsyncDoField;
    setEmployeeOutOfService: IAsyncDoField;
    changeEmployeeOutOfService: IAsyncDoField;
    clearEmployeeOutOfService: IAsyncDoField;
    updateEmployeeRisk: IAsyncDoField;
    removeEmployeeRisk: IAsyncDoField;
    updateEmployeeStatute: IAsyncDoField;
    removeEmployeeStatute: IAsyncDoField;
    addEmployeeStatute: IAsyncDoField;
    addEmployeePersonalRisk: IAsyncDoField;
    addEmployeeAbsence: IAsyncDoField;
    updateEmployeeAbsence: IAsyncDoField;
    removeEmployeeAbsence: IAsyncDoField;
    employeeRisksAndResearches: IAsyncFetchField<IEmployeeRisksAndResearches>;
    employeeFunctionRisksAndResearches: IAsyncFetchField<IEmployeeFunctionRisksAndResearches>;
    updateEmployeeAllFields: IAsyncDoField;
    employeePersonalRisks: IAsyncFetchField<IPersonalRisk[]>;
    employeeMedicalExaminations: IAsyncFetchField<IEmployeeMedicalExaminations>;
    employeeJobStudent: IAsyncFetchField<IEmployeeJobStudent>;
    addEmployeeJobStudent: IAsyncDoField;
    updateEmployeeJobStudent: IAsyncDoField;
    jobStudentRequestFailedWarning: boolean;
}

const initialState: IReducerState = {
    selectedEmployee: getAsyncFetchInitialState(),
    selectedEmployeeStatus: getAsyncFetchInitialState(),
    employeeDetail: getAsyncFetchInitialState(),
    selectedEmployeeStatutes: getAsyncFetchInitialState(),
    selectedEmployeeAbsences: getAsyncFetchInitialState(),
    updateEmployeeRequests: {},
    updateEmployeeEmployment: getAsyncDoInitialState(),
    updateEmployeeCostCenter: getAsyncDoInitialState(),
    setEmployeeOutOfService: getAsyncDoInitialState(),
    changeEmployeeOutOfService: getAsyncDoInitialState(),
    clearEmployeeOutOfService: getAsyncDoInitialState(),
    updateEmployeeRisk: getAsyncDoInitialState(),
    removeEmployeeRisk: getAsyncDoInitialState(),
    updateEmployeeStatute: getAsyncDoInitialState(),
    removeEmployeeStatute: getAsyncDoInitialState(),
    addEmployeeStatute: getAsyncDoInitialState(),
    addEmployeeAbsence: getAsyncDoInitialState(),
    addEmployeePersonalRisk: getAsyncDoInitialState(),
    updateEmployeeAbsence: getAsyncDoInitialState(),
    removeEmployeeAbsence: getAsyncDoInitialState(),
    updateEmployeeAllFields: getAsyncDoInitialState(),
    employeeRisksAndResearches: getAsyncFetchInitialState(),
    employeeFunctionRisksAndResearches: getAsyncFetchInitialState(),
    employeePersonalRisks: getAsyncFetchInitialState(),
    employeeMedicalExaminations: getAsyncFetchInitialState(),
    employeeJobStudent: getAsyncFetchInitialState(),
    addEmployeeJobStudent: getAsyncDoInitialState(),
    updateEmployeeJobStudent: getAsyncDoInitialState(),
    jobStudentRequestFailedWarning: false,
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IEmployeeDetails, IReducerState, IEmployeeDetails>({
        baseActionType: FETCH_EMPLOYEE_DETAILS,
        fieldName: 'selectedEmployee',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_EMPLOYEE_DETAILS,
            ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL,
            ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL,
            FETCH_EMPLOYEE_DETAILS,
            FETCH_SMALL_EMPLOYEE_DETAILS,
            ROUTE_KEYS.R_ONBOARDING_EMPLOYEE_DETAIL,
            FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ,
            ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL,
        ],
        extraAsyncFetchFieldNamesToResetOnTrigger: [
            'selectedEmployeeStatutes', 'selectedEmployeeAbsences',
            'employeeFunctionRisksAndResearches', 'employeePersonalRisks',
            'employeeMedicalExaminations', 'employeeJobStudent',
        ],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IEmployeeStatute[], IReducerState, IEmployeeStatute[]>({
        baseActionType: FETCH_EMPLOYEE_STATUTES,
        fieldName: 'selectedEmployeeStatutes',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IEmployeeAbsence[], IReducerState, IEmployeeAbsence[]>({
        baseActionType: FETCH_EMPLOYEE_ABSENCES,
        fieldName: 'selectedEmployeeAbsences',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE,
        fieldName: 'updateEmployeeRequests',
        transformStateOnTrigger: transformUpdateEmployeeRequestsState,
        transformStateOnFail: transformUpdateEmployeeRequestsState,
        transformStateOnReset: ({ oldState }) => {
            return {
                ...oldState,
                selectedEmployee: initialState.selectedEmployee,
            };
        },
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IUpdateEmployeeSucceededPayload;
            const requestId = successPayload.requestId;
            delete successPayload.requestId; // Don't store the request id with the data further on

            return {
                ...oldState,
                updateEmployeeRequests: {
                    ...oldState.updateEmployeeRequests,
                    [requestId]: undefined,
                },
                selectedEmployee: {
                    ...oldState.selectedEmployee,
                    data: {
                        ...oldState.selectedEmployee.data,
                        ...successPayload,
                    },
                },
            };
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE_ALL_FIELDS,
        fieldName: 'updateEmployeeAllFields',
    }),

    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE_EMPLOYMENT,
        fieldName: 'updateEmployeeEmployment',
        transformStateOnSuccess: ({ oldState, payload }) => {
            const { id, shouldUseNewId } = payload as IEmployeeNewRecordPayload;
            if (shouldUseNewId) {
                return {
                    ...oldState,
                    selectedEmployee: {
                        ...oldState.selectedEmployee,
                        id,
                    },
                };
            }
            return oldState;
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: SET_EMPLOYEE_OUT_OF_SERVICE,
        fieldName: 'setEmployeeOutOfService',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CHANGE_EMPLOYEE_OUT_OF_SERVICE,
        fieldName: 'changeEmployeeOutOfService',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CLEAR_EMPLOYEE_OUT_OF_SERVICE,
        fieldName: 'clearEmployeeOutOfService',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE_RISK,
        fieldName: 'updateEmployeeRisk',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: REMOVE_EMPLOYEE_RISK,
        fieldName: 'removeEmployeeRisk',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE_STATUTE,
        fieldName: 'updateEmployeeStatute',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: REMOVE_EMPLOYEE_STATUTE,
        fieldName: 'removeEmployeeStatute',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: ADD_EMPLOYEE_STATUTE,
        fieldName: 'addEmployeeStatute',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: ADD_EMPLOYEE_PERSONAL_RISK,
        fieldName: 'addEmployeePersonalRisk',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: ADD_EMPLOYEE_ABSENCE,
        fieldName: 'addEmployeeAbsence',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE_ABSENCE,
        fieldName: 'updateEmployeeAbsence',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE_COST_CENTER,
        fieldName: 'updateEmployeeCostCenter',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: REMOVE_EMPLOYEE_ABSENCE,
        fieldName: 'removeEmployeeAbsence',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: ADD_EMPLOYEE_JOB_STUDENT,
        fieldName: 'addEmployeeJobStudent',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_EMPLOYEE_JOB_STUDENT,
        fieldName: 'updateEmployeeJobStudent',
    }),
    ...createAsyncFetchActionHandlers<IEmployeeRisksAndResearches, IReducerState, IEmployeeRisksAndResearches>({
        baseActionType: FETCH_EMPLOYEE_RISKS_AND_RESEARCHES,
        fieldName: 'employeeRisksAndResearches',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    // eslint-disable-next-line max-len
    ...createAsyncFetchActionHandlers<IEmployeeFunctionRisksAndResearches, IReducerState, IEmployeeFunctionRisksAndResearches>({
        baseActionType: FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES,
        fieldName: 'employeeFunctionRisksAndResearches',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IPersonalRisk[], IReducerState, IPersonalRisk[]>({
        baseActionType: FETCH_EMPLOYEE_PERSONAL_RISKS,
        fieldName: 'employeePersonalRisks',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IEmployeeMedicalExaminations[], IReducerState, IEmployeeMedicalExaminations[]>({
        baseActionType: FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS,
        fieldName: 'employeeMedicalExaminations',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IEmployeeJobStudent, IReducerState, IEmployeeJobStudent>({
        baseActionType: FETCH_EMPLOYEE_JOB_STUDENT,
        fieldName: 'employeeJobStudent',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    [SET_JOB_STUDENT_REQUEST_FAILED_WARNING]: createActionHandler<IReducerState, ISetJobStudentRequestFailedWarning>(
        ({ oldState, payload }) => {
            return {
                ...oldState,
                jobStudentRequestFailedWarning: payload.showWarning,
            };
        },
    ),
    ...createAsyncFetchActionHandlers<ISelectedEmployeeStatus, IReducerState, ISelectedEmployeeStatus>({
        baseActionType: FETCH_EMPLOYEE_STATUS,
        fieldName: 'selectedEmployeeStatus',
        resetDataOnTrigger: false,
        reducerKey,
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.NO_STORAGE,
});

function transformUpdateEmployeeRequestsState({
    oldState,
    payload,
}: {
    oldState: IReducerState,
    payload: IUpdateEmployeePayload | (ITraceableApiError & ITraceableAsyncRequestPayload),
}): IReducerState {
    const requestId = payload.requestId;
    let requestStatus: IAsyncDoField;

    if (isTraceableApiError(payload)) {
        requestStatus = {
            isDoing: false,
            isDone: true,
            error: payload,
        } as IAsyncDoField;
    } else {
        requestStatus = {
            isDoing: true,
            isDone: false,
            error: null,
        } as IAsyncDoField;
    }

    return {
        ...oldState,
        updateEmployeeRequests: {
            ...oldState.updateEmployeeRequests,
            [requestId]: requestStatus,
        },
    };
}
