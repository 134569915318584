import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.scss';
import Translate from '../../common/Translate';
import Icon from '../../common/icons/Icon';
import Button from '../../common/buttons/Button';
import { isExtraSmallScreen } from '../../../utils/dom/screenSize';
import { connect } from '../..';
import {
    setSkipPerformanceDialogTutorialAction,
    navigateToPerformanceDashboardAction,
} from '../../../redux/auth/actions';
import { getLocale } from '../../../redux/i18n/selectors';
import { Locales } from '../../../config/i18n.config';
import InlineSVG from '../../common/icons/InlineSVG';
import LevelIcon from '../../assets/img/general/performance_dashboard/level.svg';
import ZoomIcon from '../../assets/img/general/performance_dashboard/zoom.svg';
import TableIcon from '../../assets/img/general/performance_dashboard/table.svg';

const SLIDES = [{
    id: 'one',
    images: {
        nl: require('../../assets/img/general/performance_dashboard/Overview_NL.png'),
        fr: require('../../assets/img/general/performance_dashboard/Overview_FR.png'),
    },
}, {
    id: 'two',
    images: {
        nl: require('../../assets/img/general/performance_dashboard/Option_NL.png'),
        fr: require('../../assets/img/general/performance_dashboard/Option_FR.png'),
    },
}, {
    id: 'three',
    images: {
        nl: require('../../assets/img/general/performance_dashboard/Filter_NL.png'),
        fr: require('../../assets/img/general/performance_dashboard/Filter_FR.png'),
    },
}];

interface ICarouselState {
    mediaSliderState: Slider;
    contentSliderState: Slider;
    currentSlideId: string;
    isChangingSlide: boolean;
}

interface IPrivateProps {
    setSkipTutorial: () => void;
    locale: Locales;
    navigateToPerformanceDashboard: () => void;
}

const TRANSLATION_PREFIX = 'administration.performance_dashboard.tutorial';
const CLASS_NAME = 'PerformanceDashboard';

class PerformanceDashboard extends PureComponent<IPrivateProps, ICarouselState> {
    private mediaSlider: Slider;
    private contentSlider: Slider;

    constructor(props) {
        super(props);

        this.mediaSlider = null;
        this.contentSlider = null;

        this.state = {
            mediaSliderState: null,
            contentSliderState: null,
            currentSlideId: SLIDES[0].id,
            isChangingSlide: false,
        };

        this.goToNextSlide = this.goToNextSlide.bind(this);
        this.goToPrevSlide = this.goToPrevSlide.bind(this);
        this.handleAfterChange = this.handleAfterChange.bind(this);
        this.handleBeforeChange = this.handleBeforeChange.bind(this);
        this.navigateToDashboard = this.navigateToDashboard.bind(this);
    }

    componentDidMount() {
        this.setState({
            mediaSliderState: this.mediaSlider,
            contentSliderState: this.contentSlider,
        });
    }

    render() {
        const {
            mediaSliderState, contentSliderState,
            currentSlideId, isChangingSlide,
        } = this.state;

        const { locale } = this.props;

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__inner`}>
                    <Button
                        id="carousel-close"
                        className={`${CLASS_NAME}__back-button`}
                        onClick={this.navigateToDashboard}
                        typeName="text"
                    >
                        <Icon typeName="cross" />
                    </Button>
                    <div className={`${CLASS_NAME}__top`}>
                        {currentSlideId && (
                            <h1 className={isChangingSlide ? 'animate' : ''}>
                                <Translate msg={`${TRANSLATION_PREFIX}.slides.${currentSlideId}.title`} />
                            </h1>
                        )}
                    </div>
                    <div className={`${CLASS_NAME}__bottom`}>
                        <figure className={`${CLASS_NAME}__media-wrapper`}>
                            <Slider
                                ref={(slider) => (this.mediaSlider = slider)}
                                asNavFor={contentSliderState}
                                dots
                                arrows={false}
                                infinite={false}
                                draggable={false}
                                swipe
                                className={`${CLASS_NAME}__media-slider`}
                            >
                                {SLIDES.map((slide, i) => (
                                    <div key={`video-${slide.id}`} className={`${CLASS_NAME}__media-slider__slide`}>
                                        <figure>
                                            <img src={locale === Locales.fr_BE ? slide.images.fr : slide.images.nl} />
                                        </figure>
                                        {isExtraSmallScreen() && (
                                            <div className={`${CLASS_NAME}__content-slider__slide__footer`}>
                                                {this.renderSlideFooterActions(slide.id, i)}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        </figure>
                        <div className={`${CLASS_NAME}__content-wrapper`}>
                            <Slider
                                ref={(slider) => (this.contentSlider = slider)}
                                asNavFor={mediaSliderState}
                                dots={true}
                                infinite={false}
                                arrows={false}
                                draggable={false}
                                swipe
                                beforeChange={this.handleBeforeChange}
                                afterChange={this.handleAfterChange}
                                className={`${CLASS_NAME}__content-slider`}
                            >
                                {SLIDES.map((slide, i) => {
                                    if (i === 1) {
                                        return (
                                            <div
                                                key={`content-${slide.id}`}
                                                className={
                                                    classNames(`${CLASS_NAME}__content-slider__slide`, 'slide-two')}
                                            >
                                                <Translate
                                                    msg={`${TRANSLATION_PREFIX}.slides.${slide.id}.content`}
                                                    raw
                                                />
                                                <div className={`${CLASS_NAME}__list-item`}>
                                                    <InlineSVG svg={LevelIcon} className="list-icon" />
                                                    <span>
                                                        <Translate
                                                            msg={`${TRANSLATION_PREFIX}.slides.${slide.id}.list.level`}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={`${CLASS_NAME}__list-item`}>
                                                    <InlineSVG svg={ZoomIcon} className="list-icon" />
                                                    <span>
                                                        <Translate
                                                            msg={`${TRANSLATION_PREFIX}.slides.${slide.id}.list.zoom`}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={`${CLASS_NAME}__list-item`}>
                                                    <InlineSVG svg={TableIcon} className="list-icon" />
                                                    <span>
                                                        <Translate // eslint-disable-next-line max-len
                                                            msg={`${TRANSLATION_PREFIX}.slides.${slide.id}.list.table_view`}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={`${CLASS_NAME}__content-slider__slide__footer`}>
                                                    {this.renderSlideFooterActions(slide.id, i)}
                                                </div>
                                            </div>
                                        );
                                    }

                                    return (
                                        <div
                                            key={`content-${slide.id}`}
                                            className={`${CLASS_NAME}__content-slider__slide`}
                                        >
                                            <Translate msg={`${TRANSLATION_PREFIX}.slides.${slide.id}.content`} raw />
                                            <div className={`${CLASS_NAME}__content-slider__slide__footer`}>
                                                {this.renderSlideFooterActions(slide.id, i)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                    <p className={`${CLASS_NAME}__footer`}>
                        <Translate
                            msg={`${TRANSLATION_PREFIX}.footer.text`}
                            placeholders={{
                                link: (
                                    <a href="#" onClick={this.navigateToDashboard}>
                                        <Translate msg={`${TRANSLATION_PREFIX}.footer.link_text`} />
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </div>
            </div>
        );
    }

    private goToNextSlide() {
        this.contentSlider.slickNext();
    }

    private goToPrevSlide() {
        this.contentSlider.slickPrev();
    }

    private handleAfterChange(index: number) {
        const currentSlideId = SLIDES[index].id;

        this.setState({
            currentSlideId,
            isChangingSlide: false,
        });
    }

    private handleBeforeChange() {
        this.setState({ isChangingSlide: true });
    }

    private renderSlideFooterActions(id: string, i: number) {
        return (
            <>
                {i !== 0 &&
                    <Button
                        id={`carousel-go-to-prev-slide-${id}`}
                        onClick={this.goToPrevSlide}
                        typeName="white"
                        outline
                    >
                        <Translate msg={`${TRANSLATION_PREFIX}.previous`} />
                    </Button>
                }
                {SLIDES.length === i + 1 ? (
                    // stop on last slide
                    <Button
                        id={`carousel-go-to-next-slide-${id}`}
                        onClick={this.navigateToDashboard}
                        typeName="secondary"
                    >
                        <Translate msg={`${TRANSLATION_PREFIX}.start`} />
                    </Button>
                ) : (
                        <Button
                            id={`carousel-go-to-next-slide-${id}`}
                            onClick={this.goToNextSlide}
                            typeName="secondary"
                        >
                            <Translate msg={`${TRANSLATION_PREFIX}.next`} />
                        </Button>
                    )}
            </>
        );
    }

    private navigateToDashboard() {
        const { setSkipTutorial, navigateToPerformanceDashboard } = this.props;

        setSkipTutorial();
        navigateToPerformanceDashboard();
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            locale: getLocale(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            setSkipTutorial: () => {
                dispatch(setSkipPerformanceDialogTutorialAction());
            },
            navigateToPerformanceDashboard: () => {
                dispatch(navigateToPerformanceDashboardAction.trigger({}));
            },
        };
    },
})(PerformanceDashboard);
