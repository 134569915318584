import React, { PureComponent } from 'react';
import './book-timeslot.scss';
import SelectFreeTimeslot from '../../shared/SelectFreeTimeslot';
import { connect } from '../../../../..';
import FlipDialog from '../../../../../common/modals/FlipDialog';
import {
    getPlanMedicalExaminationWizardEntity,
    getAddTimeslotAsyncInfo,
    getPlanMedicalExaminationWizardReason,
    getTimeCellConfigurationAsyncInfo,
    getTimeCellConfiguration,
} from '../../../../../../redux/medicalExamination/selectors';
import {
    IExaminationReason,
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
} from '../../../../../../models/interventions/medicalExaminations';
import {
    ISelectedTimeCell, ITimeCellPlanningConfiguration,
} from '../../../../../../models/interventions/timeslots';
import {
    updatePlanMedicalExaminationWizardEntity, fetchTimeCellPlanningConfigurationActions,
} from '../../../../../../redux/medicalExamination/actions';
import { IMedicalCenter, IEmployee } from '../../../../../../models/admin/employee';
import TimeslotRemarks from '../TimeslotRemarks';
import { getCompanyMedicalCenters } from '../../../../../../redux/company/info/selectors';
import { IDialogHeaderProps } from '../../../../../common/modals/Dialog/DialogHeader';
import { formatDateInLongFormat } from '../../../../../../utils/formatting/formatDate';
import { formatAddressStreet, formatAddressCity } from '../../../../../../utils/formatting/formatAddress';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import Translate from '../../../../../common/Translate';
import Icon from '../../../../../common/icons/Icon';
import Button from '../../../../../common/buttons/Button';
import RisksOverview from '../RisksOverview';
import { formatTimeOfDateForDisplay } from '../../../../../../utils/formatting/formatTime';
import { minutesOffsetFromDate, getDate } from '../../../../../../utils/core/date/getSpecificDate';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard';
import StickyFooter from '../../../../../common/widget/StickyFooter';
import { DEFAULT_MEDICAL_EXAMINATION_DURATION } from '../../../../../../config/planning.config';
import TinyLoader from '../../../../../common/waiting/TinyLoader';

type TWizardValues = Partial<Pick<IPlanMedicalExaminationSingleEmployeeBaseEntity, 'selectTime'>>;

interface IPrivateProps {
    selectedEmployee: Pick<IEmployee, 'id' | 'employeeId' | 'name' | 'firstName'>;
    selectedEventId: string;
    selectedTimeslot: ISelectedTimeCell;
    employeeName: string;
    mainMedicalCenter: IMedicalCenter;
    updateWizardData: (values: Partial<TWizardValues>) => void;
    addTimeslotAsyncInfo: IAsyncFieldInfo;
    examinationReason: IExaminationReason;
    fetchTimeCellPlanningConfiguration: (planningEntityId: number) => void;
    fetchTimeCellPlanningConfigurationAsyncInfo: IAsyncFieldInfo;
    timeCellPlanningConfiguration: ITimeCellPlanningConfiguration;
}

const CLASS_NAME = 'BookTimeslot';
interface IState {
    flipDialog: boolean;
}

class BookTimeslot extends PureComponent<IStepperStepRenderProps & IPrivateProps, IState> {
    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            flipDialog: false,
        };

        this.toggleFlipDialog = this.toggleFlipDialog.bind(this);
    }

    public componentDidMount() {
        if (this.props.selectedTimeslot) {
            this.props.fetchTimeCellPlanningConfiguration(this.props.selectedTimeslot.planningEntityId);
        }
    }

    public componentDidUpdate(prevProps: IPrivateProps) {
        if (this.props.selectedTimeslot && prevProps.selectedTimeslot !== this.props.selectedTimeslot) {
            this.props.fetchTimeCellPlanningConfiguration(this.props.selectedTimeslot.planningEntityId);
        }
    }

    public render() {
        const {
            updateWizardData,
            selectedEventId,
            selectedTimeslot,
            addTimeslotAsyncInfo,
            goToNextStep,
            selectedEmployee,
            renderStepButtons,
        } = this.props;

        const { flipDialog } = this.state;

        return (
            <>
                <SelectFreeTimeslot
                    selectedEmployee={selectedEmployee}
                />
                <div className="container">
                    <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                        {renderStepButtons({
                            nextButton: {
                                hide: true,
                            },
                        })}
                    </StickyFooter>
                </div>
                {selectedTimeslot &&
                    <FlipDialog
                        show={!!selectedEventId}
                        onCloseIntent={() => {
                            if (addTimeslotAsyncInfo.status === AsyncStatus.Busy) {
                                return;
                            }
                            updateWizardData({
                                selectTime: {
                                    selectedEventId: null,
                                    selectedTimeslot: null,
                                },
                            });
                            this.setState({
                                flipDialog: false,
                            });
                        }}
                        backContent={<RisksOverview />}
                        onFlipIntent={this.toggleFlipDialog}
                        flip={flipDialog}
                        header={this.getDialogHeader()}
                    >
                        <TimeslotRemarks onTimeslotPlanned={goToNextStep} />
                    </FlipDialog>
                }
            </>
        );
    }

    private toggleFlipDialog() {
        this.setState(prevState => ({
            flipDialog: !prevState.flipDialog,
        }));
    }

    private getDialogHeader(): IDialogHeaderProps {
        const {
            selectedTimeslot,
            mainMedicalCenter,
            employeeName,
            examinationReason,
            fetchTimeCellPlanningConfigurationAsyncInfo,
            timeCellPlanningConfiguration,
        } = this.props;

        const medicalCenter = (selectedTimeslot && selectedTimeslot.medicalCenter) || mainMedicalCenter;
        const duration = (examinationReason && examinationReason.duration) || DEFAULT_MEDICAL_EXAMINATION_DURATION;
        const endTime = selectedTimeslot && minutesOffsetFromDate(getDate(selectedTimeslot.time), duration).toDate();
        const risksTranslationKey =
            'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.view_risks';

        if (selectedTimeslot && medicalCenter) {
            return {
                color: 'primary',
                children: (
                    <div className={`${CLASS_NAME}__dialog-header`}>
                        <h4>
                            <Translate
                                msg="interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.title"
                                placeholders={{
                                    date: formatDateInLongFormat(selectedTimeslot.time),
                                    startTime: formatTimeOfDateForDisplay(selectedTimeslot.time),
                                    endTime: formatTimeOfDateForDisplay(endTime),
                                }}
                            />
                        </h4>
                        <div>
                            {medicalCenter.name}<br />
                            <TinyLoader asyncInfoSelector={fetchTimeCellPlanningConfigurationAsyncInfo}>
                                {timeCellPlanningConfiguration && timeCellPlanningConfiguration.address && (
                                    <>
                                        {formatAddressStreet(timeCellPlanningConfiguration.address)},
                                        {formatAddressCity(timeCellPlanningConfiguration.address)}
                                    </>
                                )}
                            </TinyLoader>
                        </div>
                        <p>
                            {employeeName}<br />
                            <Button
                                id="flip-dialog"
                                typeName="text"
                                onClick={this.toggleFlipDialog}
                            >
                                <Icon typeName="arrow-right" />
                                <Translate msg={risksTranslationKey} />
                            </Button><br />
                            {/* eslint-disable-next-line max-len */}
                            {selectedTimeslot.medicalCenter.id !== medicalCenter.id && selectedTimeslot.medicalCenter.name}
                        </p>
                    </div>
                ),
            };
        }
        return null;
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity =
            getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
        const examinationReason = getPlanMedicalExaminationWizardReason(state);

        const selectedEmployee = wizardEntity && wizardEntity.searchEmployee
            && wizardEntity.searchEmployee.selectedEmployee;
        const selectedEventId = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.selectedEventId;
        const selectedTimeslot = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.selectedTimeslot;
        const selectedMedicalCenterId = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.filter.selectedMedicalCenterId;

        const mainMedicalCenter = getCompanyMedicalCenters(state)
            .find((item) => item.id === selectedMedicalCenterId) || null;

        return {
            selectedEmployee,
            selectedEventId,
            selectedTimeslot,
            employeeName: selectedEmployee && formatPersonName(selectedEmployee),
            mainMedicalCenter,
            addTimeslotAsyncInfo: getAddTimeslotAsyncInfo(state),
            examinationReason,
            fetchTimeCellPlanningConfigurationAsyncInfo: getTimeCellConfigurationAsyncInfo(state),
            timeCellPlanningConfiguration: getTimeCellConfiguration(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardData: (values) => {
                const state = getState();
                const wizardEntity =
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
                const selectTimeData = wizardEntity && wizardEntity.selectTime;
                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>({
                    selectTime: {
                        ...selectTimeData,
                        ...values.selectTime,
                    },
                }));
            },
            fetchTimeCellPlanningConfiguration: (planningEntityId: number) => {
                dispatch(fetchTimeCellPlanningConfigurationActions.trigger({ planningEntityId }));
            },
        };
    },
})(BookTimeslot);
