import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    registerReducer,
    createActionHandler,
} from '../../index';
import {
    UPDATE_PLAN_BUFFERZONE_WIZARD_ENTITY,
    FETCH_BUFFERZONE_TIMESLOTS,
    SKIP_TO_PLAN_BUFFERZONE_WIZARD_STEP,
    RESET_PLAN_BUFFERZONE_WIZARD_ENTITY,
    CREATE_PLAN_BUFFERZONE_WIZARD_ENTITY,
    BUFFERZONE_PLANNING_CREATE_NEW_PLANNING,
    BUFFERZONE_PLANNING_MOVE_EXISTING_PLANNING,
    BUFFERZONE_PLANNING_CANCEL_EXISTING_PLANNING,
} from './types';
import ROUTE_KEYS from '../../../routeKeys';
import { getPlanBufferzoneWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { REDUCER_KEYS } from '../../../config/redux.config';
import { IPlanBufferzoneWizardData, IPlanBufferzoneWizardPayload } from '../../../models/interventions/bufferzones';
import {
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    getAsyncDoInitialState,
    createAsyncDoActionHandlers,
} from '../../../utils/libs/redux/async/asyncReducerUtils';
import { IAsyncFetchField, IAsyncDoField } from '../../../models/general/redux';
import { IBufferzoneTimeslots } from '../../../models/interventions/timeslots';

export const reducerKey = REDUCER_KEYS.INTERVENTION_BUFFERZONES;

export interface IReducerState {
    planBufferzoneWizardData: IPlanBufferzoneWizardData;
    bufferzoneTimeslots: IAsyncFetchField<IBufferzoneTimeslots>;
    skipToPlanBufferzoneWizardStep: IAsyncDoField;
    createNewPlanning: IAsyncDoField;
    moveExistingPlanning: IAsyncDoField;
    cancelExistingPlanning: IAsyncDoField;
}

const initialState: IReducerState = {
    planBufferzoneWizardData: null,
    bufferzoneTimeslots: getAsyncFetchInitialState(),
    skipToPlanBufferzoneWizardStep: getAsyncDoInitialState(),
    createNewPlanning: getAsyncDoInitialState(),
    moveExistingPlanning: getAsyncDoInitialState(),
    cancelExistingPlanning: getAsyncDoInitialState(),
};

const actionHandlers = {
    [ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW]:
        createActionHandler<IReducerState, IPlanBufferzoneWizardPayload>(
            ({ oldState, payload }) => {
                const stepId = payload.step;
                return {
                    ...oldState,
                    planBufferzoneWizardData: (stepId === getPlanBufferzoneWizardSteps().firstStepId)
                        ? {
                            stepId,
                            entity: payload.resetDataEntity
                                ? {}
                                // eslint-disable-next-line max-len
                                : (oldState.planBufferzoneWizardData && oldState.planBufferzoneWizardData.entity) || {},
                        }
                        : {
                            ...oldState.planBufferzoneWizardData,
                            stepId,
                        },
                };
            },
        ),
    [UPDATE_PLAN_BUFFERZONE_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    planBufferzoneWizardData: {
                        ...oldState.planBufferzoneWizardData,
                        entity: {
                            ...oldState.planBufferzoneWizardData.entity,
                            ...payload,
                        },
                    },
                };
            },
        ),
    ...createAsyncFetchActionHandlers<IBufferzoneTimeslots, IReducerState, IBufferzoneTimeslots>({
        baseActionType: FETCH_BUFFERZONE_TIMESLOTS,
        fieldName: 'bufferzoneTimeslots',
        /* KZUAT-1385 reset has to be 'true' otherwise issues after planning an examination that uses 2 timeslots */
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: SKIP_TO_PLAN_BUFFERZONE_WIZARD_STEP,
        fieldName: 'skipToPlanBufferzoneWizardStep',
    }),
    [RESET_PLAN_BUFFERZONE_WIZARD_ENTITY]:
        createActionHandler<IReducerState>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    planBufferzoneWizardData: null,
                };
            },
        ),
    [CREATE_PLAN_BUFFERZONE_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    planBufferzoneWizardData: {
                        ...oldState.planBufferzoneWizardData,
                        entity: {
                            ...payload,
                        },
                    },
                };
            },
        ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: BUFFERZONE_PLANNING_CREATE_NEW_PLANNING,
        fieldName: 'createNewPlanning',
        extraAsyncDoFieldNamesToResetOnTrigger: ['moveExistingPlanning', 'cancelExistingPlanning'],
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: BUFFERZONE_PLANNING_MOVE_EXISTING_PLANNING,
        fieldName: 'moveExistingPlanning',
        extraAsyncDoFieldNamesToResetOnTrigger: ['createNewPlanning', 'cancelExistingPlanning'],
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: BUFFERZONE_PLANNING_CANCEL_EXISTING_PLANNING,
        fieldName: 'cancelExistingPlanning',
        extraAsyncDoFieldNamesToResetOnTrigger: ['createNewPlanning', 'moveExistingPlanning'],
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that wizard steps are not lost when refreshing
        planBufferzoneWizardData: reducerState.planBufferzoneWizardData,
    }),
    resetStateOnLocaleSwitch: true,
});
