import { IAddress, IAddressSave } from '../general/address';
import { Locales } from '../../config/i18n.config';
import { ICompany } from '../admin/company';

export interface IWorkPostCardBaseData {
    identificationDate: string;
    identificationIdentification: string;
    userPhoneContact: string;
    userName: string;
    userExternalPbw: string;
    userAddress: string;
}

export interface IIdentification {
    agencyAddress: IAddress;
    agencyDateOfIssue: string;
    agencyPbw: string;
    agencyName: string;
    agencyPhoneContact: string;
    workerDateOfBirth: string;
    workerFirstName: string;
    workerName: string;
    workerPhone: string;
    workerQualification: string;
}

export interface IWorkPostProperties {
    forbiddenForYoungPeople: string;
    jobFunction: string;
    localization: string;
    qualification: string;
    usedWorkTools: string;
}

export interface IMaternityProtection {
    breastfeedingAdjustment: string;
    breastfeedingRemovalPeriod: string;
    pregnantAdjustment: string;
    pregnantRemovalPeriod: string;
}

export interface IWorkPostCardRisks {
    healthSurveillance: boolean;
    safetyFunction: boolean;
    increasedVigilanceFunction: boolean;
    chemical: string;
    checkNoise: boolean;
    noise: string;
    checkTemperature: boolean;
    temperature: string;
    checkIonizing: boolean;
    ionizing: string;
    checkOtherPhysicalResources: boolean;
    otherPhysicalResources: string;
    biologicalResources: string;
    vaccinations: string;
    checkWorkStationLoad: boolean;
    workStationLoad: string;
    checkLiftingLoad: boolean;
    liftingLoad: string;
    checkOthersLoad: boolean;
    othersLoad: string;
    nightOrShiftWork: string;
    nightOrShiftWorkRisks: string;
    psychosocialLoad: string;
    associatedActivities: string;
    youngPeople: string;
}

export interface IWorkClothes {
    trousers: boolean;
    overall: boolean;
    keel: boolean;
    shoes: boolean;
    seatbelt: boolean;
    gloves: boolean;
    helmet: boolean;
    mask: boolean;
    glasses: boolean;
    shells: boolean;
    ointments: boolean;
    specificEquipment: boolean;
    otherProtection: string;
}

export interface IEducation {
    acquiredTraining: string;
    priorInstruction: string;
    requiredTraining: string;
}

export interface IWorkPostCardWizardData {
    stepId: string;
    entity: Partial<IWorkPostCardWizardEntity>;
    functionId: number; // = function for which the WorkPostCard is being created
    locale: Locales; // = language in which the WorkPostCard should be created
}

export interface IWorkPostCardWizardEntity {
    education: IEducation;
    identification: IIdentification;
    baseData: IWorkPostCardBaseData;
    maternityProtection: IMaternityProtection;
    risks: IWorkPostCardRisks;
    workClothes: IWorkClothes;
    workPostProperties: IWorkPostProperties;
}

interface IBaseWorkPostCardValuesForRequest {
    employmentAgency: {
        name: string,
        phone: string,
        edpbw: string,
        dateOfIssue: string,
        address: IAddressSave;
    };
    temporaryWorker: {
        name: string,
        firstName: string,
        phone: string,
        birthDate: string,
        qualification: string,
    };
    workPostProperties: {
        jobFunction: string,
        qualification: string,
        localization: string,
        usedWorkTools: string,
        forbiddenForYoungPeople: string,
    };
    maternityProtection: {
        breastfeedingAdjustmentWorkstation: string,
        breastfeedingRemovalPeriod: string,
        pregnantAdjustmentWorkstation: string,
        pregnantRemovalPeriod: string,
    };
    workClothes: {
        checkTrousers: boolean,
        checkOverall: boolean,
        checkKeel: boolean,
        checkShoe: boolean,
        checkSeatBelt: boolean,
        checkHelmet: boolean,
        checkGlove: boolean,
        checkMask: boolean,
        checkGlasses: boolean,
        checkShells: boolean,
        checkOintments: boolean,
        checkSpecificEquipment: boolean,
        checkOthers: boolean,
        others: string,
    };
    education: {
        priorInstruction: string,
        acquiredTraining: string,
        requiredTraining: string,
    };
    healthSurveillance: {
        checkYes: boolean,
        checkNo: boolean,
        checkSafetyFunction: boolean,
        checkIncreasedVigilanceFunction: boolean,
        chemical: string,
        checkNoisePhysicalResources: boolean,
        noisePhysicalResources: string,
        checkTemperaturePhysicalResources: boolean,
        temperaturePhysicalResources: string,
        checkIonizingPhysicalResources: boolean,
        ionizingPhysicalResources: string,
        checkOtherPhysicalResources: boolean,
        otherPhysicalResources: string,
        biologicalResources: string,
        vaccinations: string,
        checkWorkStationLoad: boolean,
        workStationLoad: string,
        checkLiftingLoad: boolean,
        liftingLoad: string,
        checkOthersLoad: boolean,
        othersLoad: string,
        nightOrShiftWork: string,
        nightOrShiftWorkExtra: string,
        psychosocialLoad: string,
        associatedActivities: string,
        youngPeople: string,
    };
}

export interface ICreateWorkPostCardFormPayload extends IBaseWorkPostCardValuesForRequest {
    company: {
        companyCode: string;
        phone: string;
    };
    companyFunction: {
        id: number;
        description: string;
    };
}

export interface IWorkPostCardDefaultValue {
    editable: string;
    value: string;
}

export interface IWorkPostCardDefaultValues {
    identification: {
        identification: IWorkPostCardDefaultValue;
        date: IWorkPostCardDefaultValue;
    };
    user: {
        name: IWorkPostCardDefaultValue;
        address: IWorkPostCardDefaultValue;
        phoneContactPerson: IWorkPostCardDefaultValue;
        edpbw: IWorkPostCardDefaultValue;
    };
    healthSurveillance: IWorkPostCardDefaultRiskValues;
    workPostProperties: {
        jobFunction: IWorkPostCardDefaultValue;
    };
}

export interface IWorkPostCardDefaultRiskValues {
    checkYes: IWorkPostCardDefaultValue;
    checkNo: IWorkPostCardDefaultValue;
    checkSafetyFunction: IWorkPostCardDefaultValue;
    checkIncreasedVigilanceFunction: IWorkPostCardDefaultValue;
    chemical: IWorkPostCardDefaultValue;
    checkNoisePhysicalResources: IWorkPostCardDefaultValue;
    noisePhysicalResources: IWorkPostCardDefaultValue;
    checkTemperaturePhysicalResources: IWorkPostCardDefaultValue;
    temperaturePhysicalResources: IWorkPostCardDefaultValue;
    checkIonizingPhysicalResources: IWorkPostCardDefaultValue;
    ionizingPhysicalResources: IWorkPostCardDefaultValue;
    checkOtherPhysicalResources: IWorkPostCardDefaultValue;
    otherPhysicalResources: IWorkPostCardDefaultValue;
    biologicalResources: IWorkPostCardDefaultValue;
    vaccinations: IWorkPostCardDefaultValue;
    checkWorkStationLoad: IWorkPostCardDefaultValue;
    workStationLoad: IWorkPostCardDefaultValue;
    checkLiftingLoad: IWorkPostCardDefaultValue;
    liftingLoad: IWorkPostCardDefaultValue;
    checkOthersLoad: IWorkPostCardDefaultValue;
    othersLoad: IWorkPostCardDefaultValue;
    nightOrShiftWork: IWorkPostCardDefaultValue;
    nightOrShiftWorkExtra: IWorkPostCardDefaultValue;
    psychosocialLoad: IWorkPostCardDefaultValue;
    associatedActivities: IWorkPostCardDefaultValue;
    youngPeople: IWorkPostCardDefaultValue;
}

export interface IWorkPostCardWizardPayload {
    step: WORK_POST_CARD_WIZARD_STEP_ID;
    resetDataEntity: boolean;
    functionId?: number;
    locale?: Locales;
}

export enum WORK_POST_CARD_WIZARD_STEP_ID {
    EDUCATION = 'education',
    IDENTIFICATION = 'identification',
    DATA = 'data',
    PROPERTIES = 'properties',
    MATERNITY = 'maternity',
    RISKS = 'risks',
    PROTECTION = 'protection',
    VALIDATE = 'validate',
}

export interface IWorkPostCardBlankForms {
    urls: {
        [locale: string]: string; // each locale maps to a pdf url
    };
}

export interface IFetchWorkPostCardDefaultValuesRequestPayload {
    company: Pick<ICompany, 'companyCode'>;
    companyFunction: {
        id: number;
    };
}

export interface IWorkPostFile {
    fileName: string;
    documentId: number;
    dateModified: string;
    dateCreated: string;
}

export interface IFetchWorkPostFileOfEmployeePayload {
    companyCode: string;
    employeeId: number;
}
