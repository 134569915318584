import isObjectPure from '@snipsonian/core/es/is/isObjectPure';
import {
    PagePartType, IPagePart,
    IHeaderPagePartData,
    ITextPagePartData,
    ITextBlockPagePartData,
    TPagePartData,
    ICourseAnchorPagePartData,
    IButtonPagePartData,
} from '../../../../../models/general/pageParts';
import isSetString from '../../../../../utils/core/string/isSetString';
import { ICMSCourseDetail, ICMSCourse } from '../../../../../models/documentCenter/courses';

const POSSIBLY_PAGE_PART_TYPES = Object.values(PagePartType);
const NO_PAGE_PART_TITLE = null;
const ANCHOR_PREFIX = 'course-detail';

interface ICourseDetailPagePart extends IPagePart<TPagePartData> {
    anchorId?: string;
    hostname?: string;
}

export interface ICourseDetailInfo {
    pageParts: ICourseDetailPagePart[];
    anchors: {
        anchorId: string;
        text: string;
    }[];
}

export default function mapToCourseDetailInfo(courseDetail: ICMSCourseDetail): ICourseDetailInfo {
    if (!courseDetail) {
        return getEmptyCourseDetailInfo();
    }

    return toCourseDetailInfo(
        getValidPageParts(courseDetail.pageParts),
        courseDetail.course,
    );
}

function toCourseDetailInfo(
    pageParts: IPagePart[],
    course: ICMSCourse,
): ICourseDetailInfo {
    return pageParts.reduce(
        (accumulator, pagePart, index) => {
            const anchorText = getPagePartAnchorTitlesIfAny(pagePart);

            if (anchorText === NO_PAGE_PART_TITLE) {
                addPagePart(pagePart);
            } else {
                const anchorId = `${ANCHOR_PREFIX}-${index}`;

                addPagePartAndTitle(pagePart, anchorText, anchorId);
            }

            return accumulator;

            function addPagePart(pagePart: ICourseDetailPagePart) {
                accumulator.pageParts.push({
                    ...pagePart,
                    hostname: course.hostName,
                });
            }

            function addPagePartAndTitle(pagePart: IPagePart, title: string, anchorId: string) {
                accumulator.anchors.push({
                    anchorId,
                    text: title,
                });

                addPagePart({
                    ...pagePart,
                    anchorId,
                });
            }
        },
        getEmptyCourseDetailInfo(),
    );
}

function getValidPageParts(pageParts: IPagePart[]) {
    // const notValidPageparts = pageParts
    //     .filter((pp) => !isKnownPagePartType(pp))
    //     .filter((pp) => !isMandatoryPagePartDataSet(pp));

    // console.log({ notValidPageparts });

    return pageParts
        .filter(isKnownPagePartType)
        .filter(isMandatoryPagePartDataSet);
}

function isKnownPagePartType(pagePart: IPagePart): boolean {
    return POSSIBLY_PAGE_PART_TYPES.includes(pagePart.type);
}

function isMandatoryPagePartDataSet(pagePart: IPagePart): boolean {
    if (!pagePart.data || !isObjectPure(pagePart.data)) {
        return false;
    }

    switch (pagePart.type) {
        case PagePartType.HEADER: {
            const data = pagePart.data as IHeaderPagePartData;
            return isSetString(data.title);
        }
        case PagePartType.MENSURA_HEADER: {
            const data = pagePart.data as IHeaderPagePartData;
            return isSetString(data.title);
        }
        case PagePartType.KUMA_TEXT: {
            const data = pagePart.data as ITextPagePartData;
            return isSetString(data.content);
        }
        case PagePartType.MENSURA_WEBSITE_TEXT: {
            const data = pagePart.data as ITextPagePartData;
            return isSetString(data.content);
        }
        case PagePartType.TEXT_BLOCK: {
            const data = pagePart.data as ITextBlockPagePartData;
            return isSetString(data.text);
        }
        case PagePartType.MENSURA_COURSE_ANCHOR: {
            const data = pagePart.data as ICourseAnchorPagePartData;
            return isSetString(data.title);
        }
        case PagePartType.MENSURA_BUTTON: {
            const data = pagePart.data as IButtonPagePartData;
            return isSetString(data.linkText) && isSetString(data.linkUrl);
        }
        case PagePartType.MENSURA_VIDEO:
        case PagePartType.KUMA_LINE:
        case PagePartType.MENSURA_SPOTLIGHT:
        case PagePartType.MENSURA_TIMELINE:
        case PagePartType.MENSURA_TIP:
        case PagePartType.MENSURA_ICON_LEFT_WITH_TEXT: {
            return true;
        }
    }

    return false;
}

function getEmptyCourseDetailInfo(): ICourseDetailInfo {
    return {
        pageParts: [],
        anchors: [],
    };
}

function getPagePartAnchorTitlesIfAny(pagePart: IPagePart): string {
    if (pagePart.type === PagePartType.MENSURA_COURSE_ANCHOR) {
        return (pagePart.data as ICourseAnchorPagePartData).title;
    }

    return NO_PAGE_PART_TITLE;
}
