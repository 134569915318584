import { get, post } from '../../utils/api/requestWrapper';
import {
    IFetchExecutedCompanyVisitsPayload,
    IExecutedCompanyVisit,
    IExecutedCompanyVisitsFilter,
    IFetchPlannedCompanyVisitsPayload,
    IPlannedCompanyVisitsFilter,
    IPlannedCompanyVisit,
    IFetchCompanyVisitDocumentsPayload,
    ICompanyVisitDocument,
    ICreateCompanyVisitRequest,
} from '../../models/interventions/companyVisits';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, yearOffsetFromNow } from '../../utils/core/date/getSpecificDate';

export const URL = {
    COMPANY_VISITS_EXECUTED: '/companies/{companyCode}/executed-company-visits',
    COMPANY_VISITS_PLANNED: '/companies/{companyCode}/planned-company-visits',
    COMPANY_VISIT_DOCUMENTS: '/companies/{companyCode}/company-visit/{visitId}/documents',
    COMPANY_VISIT_REQUEST: '/companies/{companyCode}/company-visits',
};

export const DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export function fetchExecutedCompanyVisits(
    { companyCode, showFullFamily }: IFetchExecutedCompanyVisitsPayload,
    {
        startDate = DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS.startDate,
        endDate = DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS.endDate,
    }: IExecutedCompanyVisitsFilter,
) {
    return get<IExecutedCompanyVisit[]>({
        url: URL.COMPANY_VISITS_EXECUTED,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response.visits,
    });
}

export const DEFAULT_PLANNED_COMPANY_VISITS_FILTERS = {
    startDate: formatDateForBackend(now()),
    endDate: formatDateForBackend(yearOffsetFromNow(3)),
};

export function fetchPlannedCompanyVisits(
    { companyCode, showFullFamily }: IFetchPlannedCompanyVisitsPayload,
    {
        startDate = DEFAULT_PLANNED_COMPANY_VISITS_FILTERS.startDate,
        endDate = DEFAULT_PLANNED_COMPANY_VISITS_FILTERS.endDate,
    }: IPlannedCompanyVisitsFilter,
) {
    return get<IPlannedCompanyVisit[]>({
        url: URL.COMPANY_VISITS_PLANNED,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response.visits,
    });
}

export function fetchCompanyVisitDocuments(
    { companyCode, visitId }: IFetchCompanyVisitDocumentsPayload,
) {
    return get<ICompanyVisitDocument[]>({
        url: URL.COMPANY_VISIT_DOCUMENTS,
        pathParams: {
            companyCode,
            visitId,
        },
        mapResponse: (response) => response.documents,
    });
}

export function createCompanyVisitRequest(companyCode: string, body: ICreateCompanyVisitRequest) {
    return post({
        url: URL.COMPANY_VISIT_REQUEST,
        body,
        pathParams: {
            companyCode,
        },
    });
}
