import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    SWITCH_LOCALE,
    RESET_TRANSLATIONS_REFRESHED,
    REFRESH_TRANSLATIONS,
    TOGGLE_TRANSLATION_IDS,
    FETCH_TRANSLATIONS_AND_ALL_CONSTANTS,
} from './types';
import { ISwitchLocalePayload } from '../../models/general/i18n';
import { Locales, getDefaultLocale } from '../../config/i18n.config';
import {
    createActionHandlersForType,
    registerReducer,
    createAsyncDoActionHandlers,
} from '../index';
import { IAsyncDoField } from '../../models/general/redux';
import { getAsyncDoInitialState } from '../../utils/libs/redux/async/asyncReducerUtils';
import { REDUCER_KEYS } from '../../config/redux.config';

export const reducerKey = REDUCER_KEYS.I18N;

export interface IReducerState {
    locale: Locales;
    areTranslationsRefreshed: boolean;
    /**
     * Used in development to show translation ids instead of labels
     */
    showTranslationIds: boolean;
    fetchTranslationsAndAllConstants: IAsyncDoField;
}

const initialState: IReducerState = {
    locale: getDefaultLocale(),
    areTranslationsRefreshed: false,
    showTranslationIds: false,
    fetchTranslationsAndAllConstants: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(SWITCH_LOCALE)
        .onSuccess<ISwitchLocalePayload>(({ oldState, payload }) => {
            return {
                ...oldState,
                locale: payload.newLocale,
            };
        })
        .create(),

    ...createActionHandlersForType<IReducerState>(RESET_TRANSLATIONS_REFRESHED)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                areTranslationsRefreshed: false,
            };
        })
        .create(),

    ...createActionHandlersForType<IReducerState>(REFRESH_TRANSLATIONS)
        .onSuccess(({ oldState }) => {
            return {
                ...oldState,
                areTranslationsRefreshed: true,
            };
        })
        .create(),

    ...createActionHandlersForType<IReducerState>(TOGGLE_TRANSLATION_IDS)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                showTranslationIds: !oldState.showTranslationIds,
            };
        })
        .create(),

    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: FETCH_TRANSLATIONS_AND_ALL_CONSTANTS,
        fieldName: 'fetchTranslationsAndAllConstants',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.LOCAL,
    resetStateOnLogout: false,
    resetStateOnCompanySelection: false,
    resetStateOnSeatSelection: false,
    transformReducerStateForStorage: (reducerState: IReducerState) => ({
        locale: reducerState.locale,
    }),
    resetStateOnLocaleSwitch: false,
});
