import { API_URL } from '../../config/api.config';
import { DEFAULT_LOCALE, Locales } from '../../config/i18n.config';
import { IMessages } from '../../models/general/i18n';
import { get } from '../../utils/api/requestWrapper';

const KZ_WEBAPP_DOMAIN = 'webapp';

export const URL = {
    TRANSLATIONS: '/public/translations/{domain}/',
};

interface ITranslation {
    keyword: string;
    text: string;
}

export function getTranslations({
    locale = DEFAULT_LOCALE,
}: {
    locale: Locales;
}) {
    return get<IMessages>({
        url: URL.TRANSLATIONS,
        api: API_URL.CMS,
        pathParams: {
            domain: KZ_WEBAPP_DOMAIN,
        },
        queryParams: {
            locale,
        },
        mapResponse: mapTranslationsData,
        resetSessionTimerOnSuccess: false,
    });
}

function mapTranslationsData(translations: ITranslation[]) {
    const initialValue = {};
    return translations.reduce(
        (accumulator: typeof translations, translation: ITranslation) => {
            accumulator[translation.keyword] = translation.text;
            return accumulator;
        },
        initialValue,
    );
}
