import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from '../../index';
import PageHeader from '../../appShell/PageHeader';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import Translate from '../../common/Translate';
import ListWithSorting from '../../common/list/ListWithSorting';
import { ListItem, ListColumns, SortType, ISortedColumn, SortOrder } from '../../../models/general/list';
import {
    IEmployee,
    IFetchEmployeesFilters,
    IEmployeeDetails,
    EMPLOYEE_CONDITION,
} from '../../../models/admin/employee';
import {
    getEmployees,
    getEmployeesAsyncInfo,
    getTotalEmployeesCount,
    getEmployeesWithoutEmail,
} from '../../../redux/employee/employees/selectors';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import './employees.scss';
import { navigateTo, navigateToAddEmployee } from '../../../redux/location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import { getQueryParams } from '../../../redux/location/selectors';
import {
    SHOWALL_FETCH_EMPLOYEES_PARAMETERS,
} from '../../../config/administration.config';
import {
    EmployeeDetailsHeader, EmployeeDetailsContent,
    EmployeeDetailsOverlay,
} from './EmployeeDetails';
import EmployeeConditionTooltip from './shared/EmployeeConditionTooltip';
import { formatPersonNameFormal } from '../../../utils/formatting/formatPerson';
import formatNationalRegisterNumber from '../../../utils/formatting/formatNationalRegisterNumber';
import { AsyncStatus } from '../../../models/general/redux';
import { ADMINISTRATION_ITEMS } from '../../../config/navigation/administration.config';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, ITransformToActiveFiltersProps,
    IDetailConfig,
    IRenderDetailContentProps, IRenderDetailHeaderProps, IRenderDetailOverlayProps,
    IRenderSearchContentProps, IRenderFilterContentProps, IFetchAllExportDataProps, IRenderDetailFooterProps,
} from '../../common/widget/MasterWithDetail/typings';
import {
    getSelectedEmployeeAsyncInfo,
    getSelectedEmployee,
    getEmployeeMedicalExaminationsAsyncInfo,
    getSelectedEmployeeId,
} from '../../../redux/employee/info/selectors';
import { EmployeeSearch, EmployeeFilter, IFilterValues } from './EmployeeFilter';
import { schema as employeeSearchSchema } from './EmployeeFilter/searchMinNumberOfCharactersSchema';
import {
    DetailHeader as DetailHeaderExecuted,
    DetailContent as DetailContentExecuted,
} from '../../interventions/MedicalExaminations/Executed/detail';
import {
    DetailHeader as DetailHeaderPlanned,
    DetailContent as DetailContentPlanned,
} from '../../interventions/MedicalExaminations/Planned/detail';
import {
    IExecutedMedicalExamination,
    IPlannedMedicalExamination,
} from '../../../models/interventions/medicalExaminations';
import {
    getSelectedPlannedMedicalExaminationAsyncInfo,
    getSelectedPlannedMedicalExamination,
    getSelectedExecutedMedicalExamination,
    getSelectedExecutedMedicalExaminationAsyncInfo,
} from '../../../redux/medicalExamination/selectors';
import api from '../../../api';
import { doExportEmployeesCall } from '../../../redux/employee/employees/exportEmployeesHelper';
import { getStore } from '../../../redux/storeNoCircularDependencies';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { IState } from '../../../redux';
import { getCsvFilename } from '../../../utils/file/csv/exportListDataToCsv';
import { EmployeeDetailsOverlayType } from './EmployeeDetails/common';
import {
    getCoursesFollowedAsyncInfo,
    getSelectedFollowedCourse,
    getCoursesPlannedAsyncInfo,
    getSelectedPlannedCourse,
    getSelectedCourseSessionAttendant,
} from '../../../redux/documentCenter/courses/selectors';
import { ICourse, ICourseSessionAttendant } from '../../../models/documentCenter/courses';
import DetailContentCoursesFollowed from '../../documentCenter/Courses/Followed/detail/content';
import DetailFooterCoursesFollowed from '../../documentCenter/Courses/Followed/detail/footer';
import DetailHeaderCourses from '../../documentCenter/Courses/shared/header';
import DetailContentCoursesPlanned from '../../documentCenter/Courses/Planned/detail/content';
import DetailFooterCoursesPlanned from '../../documentCenter/Courses/Planned/detail/footer';
import {
    DetailContent as DetailContentAttendant,
    DetailHeader as DetailHeaderAttendant,
} from '../../documentCenter/Courses/Followed/attendant';
import { isInThePast } from '../../../utils/core/date/isInThePast';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

interface IPrivateProps {
    employees: IEmployee[];
    showAllEmployees: () => void;
    toAddEmployee: () => void;
    totalEmployeesCount: number;
    showShowAllButton: boolean;
    idOfSelectedEmployee: string;
    navigateToAddEmails: () => void;
    showAddEmailsNotification: boolean;
}

export interface IColumnNames {
    condition: string;
    name: string;
    nationalRegisterNumber: string;
    functionDescription: string;
    seat: string;
    newFutureEmployee: boolean;
}

// Initial sort must be defined, else we  sort on first column that contains the tooltip
const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'name',
    sortOrder: SortOrder.Ascending,
};

const CLASS_NAME = 'EmployeeOverview';
const BASE_NAME = 'employees';

const PLANNED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_PLANNED_DETAIL,
    asyncInfoSelector: getSelectedPlannedMedicalExaminationAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: getSelectedPlannedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IPlannedMedicalExamination>) =>
        <DetailHeaderPlanned {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IPlannedMedicalExamination>) =>
        <DetailContentPlanned {...renderProps} />,
};

const EXECUTED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL,
    asyncInfoSelector: executedMedicalExaminationDetailAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: getSelectedExecutedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IExecutedMedicalExamination>) =>
        <DetailHeaderExecuted {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IExecutedMedicalExamination>) =>
        <DetailContentExecuted {...renderProps} />,
};

const PLANNED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_PLANNED_DETAIL,
    asyncInfoSelector: getCoursesPlannedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: getSelectedPlannedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) =>
        <DetailContentCoursesPlanned {...renderProps} />,
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesPlanned {...renderProps} />,
};

const FOLLOWED_COURSES_ATTENDANT_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL_ATTENDANT,
    asyncInfoSelector: getCoursesFollowedAsyncInfo,
    idRouteParamName: 'employeeId',
    dataSelector: getSelectedCourseSessionAttendant,
    renderContent: (renderProps: IRenderDetailContentProps<ICourseSessionAttendant>) =>
        <DetailContentAttendant {...renderProps} />,
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourseSessionAttendant>) =>
        <DetailHeaderAttendant {...renderProps} />,
};

const EXPORT_BASE_FILENAME = 'employees';

export class Employees extends PureComponent<IPrivateProps> {
    private columns: ListColumns<IColumnNames>;
    private AddEmployeeButton;

    constructor(props: IPrivateProps) {
        super(props);

        this.columns = {
            condition: {
                label: <Icon typeName="warning" circle colorType="grey" />,
                sortable: true,
                sortType: SortType.String,
                render: this.renderEmployeeConditionTooltip.bind(this),
                percentWidth: 6,
            },
            newFutureEmployee: {
                hide: true,
                percentWidth: null,
            },
            name: {
                label: <Translate msg="administration.employees.columns.name" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 20,
            },
            nationalRegisterNumber: {
                label: <Translate msg="administration.employees.columns.national_register_number" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 15,
            },
            functionDescription: {
                label: <Translate msg="administration.employees.columns.function" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 25,
            },
            seat: {
                label: <Translate msg="administration.employees.columns.seat" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 34,
            },
        };

        this.AddEmployeeButton = (
            <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }}>
                <Button
                    id="page-header-calendar-button"
                    typeName="secondary"
                    onClick={props.toAddEmployee}
                >
                    <Icon typeName="plus-circle" />
                    <span><Translate msg="administration.overview.add_employee_button" /></span>
                </Button>
            </ShowIfAllowed>
        );

        this.isFetchAllExportDataNeeded = this.isFetchAllExportDataNeeded.bind(this);
    }

    public render() {
        const {
            toAddEmployee, showShowAllButton,
            showAllEmployees, totalEmployeesCount,
            idOfSelectedEmployee, navigateToAddEmails, showAddEmailsNotification,
        } = this.props;

        const CURRENT_ITEM = ADMINISTRATION_ITEMS.find((item) => {
            return item.linkTo === ROUTE_KEYS.R_EMPLOYEES;
        });

        const lazyLoadSvgId = CURRENT_ITEM
            ? CURRENT_ITEM.detailLazyLoadSvgId || CURRENT_ITEM.lazyLoadSvgId
            : undefined;

        return (
            <>
                <PageHeader
                    breadcrumbs={true}
                    title="administration.employees.title"
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.ADMINISTRATION,
                    }}
                    button={this.AddEmployeeButton}
                    type="grey"
                />
                {showAddEmailsNotification && (
                    <div className={`${CLASS_NAME}__missing-emails`}>
                        <div className={classNames('container', `${CLASS_NAME}__missing-emails__content`)}>
                            <Icon circle colorType="warning" typeName="warning" />
                            <div>
                                <p className={`${CLASS_NAME}__missing-emails__title`}>
                                    <Translate msg="administration.employees.missing_emails_notification.title" />
                                </p>
                                <p className={`${CLASS_NAME}__missing-emails__text`}>
                                    <Translate
                                        msg="administration.employees.missing_emails_notification.text"
                                        placeholders={{
                                            link: (
                                                <Button
                                                    id="goto-update-missing-emails"
                                                    typeName="text"
                                                    className={`${CLASS_NAME}__missing-emails__link`}
                                                    onClick={navigateToAddEmails}
                                                >
                                                    <Icon typeName="plus" circle />
                                                    <span> {/* eslint-disable-next-line max-len */}
                                                        <Translate msg="administration.employees.missing_emails_notification.text_link" />
                                                    </span>
                                                </Button>
                                            ),
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <MasterWithDetail
                    baseName={BASE_NAME}
                    masterConfig={{
                        routeKey: ROUTE_KEYS.R_EMPLOYEES,
                        asyncInfoSelector: getEmployeesAsyncInfo,
                        dataSelector: getEmployees,
                        transformData: mapEmployeesToListItems,
                        transformFilterValuesToActiveFilters,
                        renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[]>) => (
                            <>
                                <p className={`${CLASS_NAME}__pre-list`}>
                                    <Translate raw msg="administration.employees.pre_table" />
                                </p>
                                <div className={`${CLASS_NAME}__warning`}>
                                    <Icon typeName="warning" circle colorType="grey" />
                                    <span><Translate msg="administration.employees.condition_label" /></span>
                                </div>
                                <EmployeeList {...renderProps} columns={this.columns} />
                            </>
                        ),
                        filterValidationSchema: employeeSearchSchema,
                    }}
                    detailConfig={{
                        levels: [
                            {
                                level: 1,
                                details: [{
                                    routeKey: ROUTE_KEYS.R_EMPLOYEE_DETAILS,
                                    asyncInfoSelector: getSelectedEmployeeAsyncInfo,
                                    dataSelector: getSelectedEmployee,
                                    // eslint-disable-next-line max-len
                                    renderContent: (renderProps: IRenderDetailContentProps<IEmployee & IEmployeeDetails>) =>
                                        <EmployeeDetailsContent
                                            {...renderProps}
                                            // eslint-disable-next-line max-len
                                            plannedMedicalExaminationRouteKey={ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_PLANNED_DETAIL}
                                            // eslint-disable-next-line max-len
                                            executedMedicalExaminationRouteKey={ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL}
                                            // eslint-disable-next-line max-len
                                            followedCoursesRouteKey={ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL}
                                            // eslint-disable-next-line max-len
                                            plannedCoursesRouteKey={ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_PLANNED_DETAIL}
                                        />,
                                    renderHeader: (renderProps: IRenderDetailHeaderProps<IEmployeeDetails>) =>
                                        <EmployeeDetailsHeader {...renderProps} />,
                                    // eslint-disable-next-line max-len
                                    renderOverlay: ({ overlayType, closeOverlay, onSave }: IRenderDetailOverlayProps<EmployeeDetailsOverlayType>) =>
                                        <EmployeeDetailsOverlay
                                            overlayType={overlayType}
                                            closeOverlay={closeOverlay}
                                            onSave={onSave}
                                        />,
                                }],
                            },
                            {
                                level: 2,
                                details: [
                                    PLANNED_EXAMINATION_DETAIL_CONFIG,
                                    EXECUTED_EXAMINATION_DETAIL_CONFIG,
                                    PLANNED_COURSES_DETAIL_CONFIG, {
                                        routeKey: ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL,
                                        asyncInfoSelector: getCoursesFollowedAsyncInfo,
                                        idRouteParamName: 'coursesOrganizedId',
                                        dataSelector: getSelectedFollowedCourse,
                                        renderContent: (renderProps: IRenderDetailContentProps<ICourse>) => (
                                            <DetailContentCoursesFollowed
                                                {...renderProps}
                                                // eslint-disable-next-line max-len
                                                followedCoursesAttendantRouteKey={ROUTE_KEYS.R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL_ATTENDANT}
                                                extraRoutePayload={{ id: idOfSelectedEmployee }}
                                            />),
                                        renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
                                            <DetailHeaderCourses {...renderProps} />,
                                        renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
                                            <DetailFooterCoursesFollowed {...renderProps} />,
                                    },
                                ],
                            },
                            {
                                level: 3,
                                details: [
                                    FOLLOWED_COURSES_ATTENDANT_DETAIL_CONFIG,
                                ],
                            },
                        ],
                    }}
                    headerConfig={{
                        renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                            <EmployeeSearch {...renderProps} />,
                        // eslint-disable-next-line max-len
                        renderFilterContent: (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                            <EmployeeFilter {...renderProps} />,
                        exportButton: {
                            baseFilename: EXPORT_BASE_FILENAME,
                            fetchAllExportData: {
                                isFetchNeeded: this.isFetchAllExportDataNeeded,
                                apiCall: fetchAllExportDataApiCall,
                                willResultAlreadyBeAFile: true,
                            },
                        },
                    }}
                    footerConfig={{
                        renderActionsLeft: () => (
                            <ShowIfAllowed requiredAccessLevels={{ employee: 'W' }}>
                                <Button
                                    id={'administration_employees_add'}
                                    typeName="text"
                                    className="AddEmployeeButton"
                                    onClick={toAddEmployee}
                                >
                                    <Icon typeName="plus" circle />
                                    <span>
                                        <Translate msg={'administration.employees.actions.add_employee'} />
                                    </span>
                                </Button>
                            </ShowIfAllowed>
                        ),
                        renderActionsCenter: () => showShowAllButton && (
                            <Button
                                id={'administration_employees_showallemployees'}
                                onClick={showAllEmployees}
                                className="ShowAllEmployeesButton"
                                typeName="text"
                            >
                                <span>
                                    <Translate msg={'administration.employees.actions.show_all'} />
                                    {totalEmployeesCount > 0 ? ` (${totalEmployeesCount})` : ''}
                                </span>
                            </Button>
                        ),
                    }}
                />
            </>
        );
    }

    private renderEmployeeConditionTooltip(listItem: ListItem<IColumnNames>) {
        const employee = this.props.employees
            .find((employee) => employee.id === listItem.id);

        return (
            <EmployeeConditionTooltip employee={employee} />
        );
    }

    private isFetchAllExportDataNeeded(): boolean {
        return true;
    }
}

export function EmployeeList(
    props: IRenderMasterContentProps<ListItem<{}>[]> & { columns: ListColumns<{}> },
) {
    return (
        <ListWithSorting
            columns={props.columns}
            items={props.masterData}
            name={BASE_NAME}
            initialSort={INITIAL_SORT} // must be defined to prevent sort on tooltip column
            errorMessage={props.masterAsyncInfo.error && <ErrorPlaceholder apiError={props.masterAsyncInfo.error} />}
            selectedItemIds={props.selectedItemId ? [props.selectedItemId] : []}
            onItemRowClicked={props.onItemSelected}
            footer={props.footer}
            getCustomRowClasses={getCustomRowClasses}
        />
    );
}

function getCustomRowClasses(listItem: ListItem<IColumnNames>) {
    return listItem.columns && listItem.columns.newFutureEmployee
        ? 'no-filter-match'
        : null;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const employeesCount = getTotalEmployeesCount(state);
        const employees = getEmployees(state);
        const employeesAsyncInfo = getEmployeesAsyncInfo(state);
        const isFetchingEmployees = employeesAsyncInfo.status === AsyncStatus.Busy;

        return {
            employees,
            totalEmployeesCount: employeesCount,
            showShowAllButton:
                !isFetchingEmployees && employeesCount > employees.length,
            idOfSelectedEmployee: getSelectedEmployeeId(state),
            showAddEmailsNotification: getEmployeesWithoutEmail(state).length > 0,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            showAllEmployees: () => {
                const currentQuery = getQueryParams<IFetchEmployeesFilters>(getState());
                dispatch(
                    navigateTo(
                        ROUTE_KEYS.R_EMPLOYEES,
                        {},
                        {
                            // TODO: speed up list rendering by virtualizing the list (MENSKLNTZN-592)
                            ...currentQuery,
                            ...SHOWALL_FETCH_EMPLOYEES_PARAMETERS,
                        },
                    ));
            },
            toAddEmployee: () => {
                dispatch(navigateToAddEmployee());
            },
            navigateToAddEmails: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_EMPLOYEES_ADD_EMAILS));
            },
        };
    },
})(Employees);

function mapEmployeesToListItems(employees: IEmployee[]): ListItem<IColumnNames>[] {
    return employees.map((employee) => ({
        id: employee.id,
        columns: {
            name: formatPersonNameFormal(employee),
            nationalRegisterNumber: formatNationalRegisterNumber(employee.nationalRegisterNumber),
            functionDescription: employee.function.description,
            seat: employee.company.name,
            condition: getEmployeeConditionValue(employee),
            newFutureEmployee: !isInThePast(employee.dateInService),
            absent: !!employee.absent,
        },
    }));
}

function getEmployeeConditionValue(employee: IEmployee): EMPLOYEE_CONDITION {
    if (employee.absent) {
        return EMPLOYEE_CONDITION.ABSENT;
    }

    const newFutureEmployment = employee && employee.dateInService && !isInThePast(employee.dateInService);

    if (newFutureEmployment) {
        return EMPLOYEE_CONDITION.FUTURE_EMPLOYMENT;
    }

    if (employee.newEmployee) {
        return EMPLOYEE_CONDITION.NEW_EMPLOYEE;
    }

    const isGoingOutOfService = employee && employee.dateOutOfService &&
        !employee.newCompany && !employee.newFunction;

    if (isGoingOutOfService) {
        return EMPLOYEE_CONDITION.OUT_OF_SERVICE;
    }

    if (employee.newFunction) {
        return EMPLOYEE_CONDITION.NEW_FUNCTION;
    }

    if (employee.newCompany) {
        return EMPLOYEE_CONDITION.NEW_SEAT;
    }

    // TODO: other conditions

    return null;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'administration.employees.filter_buttons',
        filters: {
            search: {
                show: true,
            },
            functionFilter: {
                show: true,
            },
            refreshSources: {
                show: false,
            },
            seatFilter: {
                show: true,
            },
            conditionFilter: {
                show: true,
            },
        },
    });
}

async function fetchAllExportDataApiCall(fetchProps: IFetchAllExportDataProps<IFilterValues>) {
    const document = await doExportEmployeesCall({
        state: getStore().getState(),
        api,
        defaultFilename: getCsvFilename(EXPORT_BASE_FILENAME),
    });

    return document;
}

function executedMedicalExaminationDetailAsyncInfo(state: IState) {
    const employeeExaminationsAsyncInfo = getEmployeeMedicalExaminationsAsyncInfo(state);
    if (employeeExaminationsAsyncInfo.status === AsyncStatus.Busy) {
        return employeeExaminationsAsyncInfo;
    }
    return getSelectedExecutedMedicalExaminationAsyncInfo(state);
}
