import { IMedicalExaminationToPlan, IMedicalExaminationToAdd } from '../../../models/interventions/medicalExaminations';

export function mapEmployeesToPlanToMedicalExaminationsToAdd(
    employeesToPlan: IMedicalExaminationToPlan[],
): IMedicalExaminationToAdd[] {
    return employeesToPlan
        .map(mapEmployeeToPlanToMedicalExaminationToAdd);
}

export function mapEmployeeToPlanToMedicalExaminationToAdd(
    employeeToPlan: IMedicalExaminationToPlan,
): IMedicalExaminationToAdd {
    return {
        employee: {
            id: employeeToPlan.employee.id,
            employeeId: employeeToPlan.employee.employeeId,
            firstName: employeeToPlan.employee.firstName,
            name: employeeToPlan.employee.name,
            dateOutOfService: employeeToPlan.employee.dateOutOfService,
        },
        company: {
            name: employeeToPlan.company.name,
            companyCode: employeeToPlan.company.companyCode,
            id: employeeToPlan.company.id,
        },
        function: {
            id: employeeToPlan.function.id,
            description: employeeToPlan.function.description,
        },
        examinationReason: {
            id: employeeToPlan.examinationReason.id,
            title: employeeToPlan.examinationReason.title,
        },
        planningRecordId: employeeToPlan.planningRecordId,
        duration: employeeToPlan.duration,
        toBePlannedDate: employeeToPlan.toBePlannedDate,
        absent: employeeToPlan.absent,
    };
}
