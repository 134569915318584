import { get, remove, post, put } from '../../utils/api/requestWrapper';
import {
    CompanyAvailabilityType,
    ICompanyAvailabilities,
    ICompanyDetail,
    ICompanyMedicalCenter,
    IUpdateCompanyPayload,
    IUpdateCompanySingleFieldPayload,
    ICompanyContact,
    IRemoveCompanyContactPayload, IAddCompanyContactPayload, IUpdateCompanyContactPayload,
    ICompanyAddress, ICompanyHoliday, IFetchCompanyHolidaysPayload,
    IAddCompanySeatPayload, TCreateCompanyAvailabilities,
    ICompanyAvailabilityPeriod, IUpdateCompanyHolidaysPayload, IHolidayBase,
} from '../../models/admin/companyInfo';
import { ICostCenter } from '../../models/admin/employee';
import {
    ICompanyCodePayload,
    ICompanyBufferzone,
    IFetchCompanyBufferzoneAPIPayload,
} from '../../models/admin/company';
import { removeEmptyPropsFromObject } from '../../utils/core/object/objectProps';
import { ONE_SECOND } from '../../utils/core/time/periodsInMillis';
import { IFilterValues } from '../../views/interventions/MedicalExaminations/Bufferzones';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { now, monthOffsetFromNow } from '../../utils/core/date/getSpecificDate';

export const URL = {
    COMPANY: '/companies/{companyCode}',
    COMPANY_CONTACTS: '/companies/{companyCode}/contacts',
    CONTACT_REMOVE_EDIT: '/companies/{companyCode}/contacts/{customerContactId}',
    CONTACT_ADD: '/companies/{companyCode}/contacts',
    COMPANY_MEDICAL_CENTERS: '/companies/{companyCode}/medical-centers',
    COMPANY_COST_CENTERS: '/companies/{companyCode}/cost-centers',
    COMPANY_AVAILABILITIES: '/companies/{companyCode}/availabilities/{availabilityCode}',
    COMPANY_ADDRESSES: '/companies/{companyCode}/addresses',
    COMPANY_HOLIDAYS_SPECIFIC_YEAR: '/companies/{companyCode}/holidays/{year}',
    COMPANY_HOLIDAYS: '/companies/{companyCode}/holidays',
    COMPANY_CONFIRM_ONBOARDING_WIZARD: '/companies/{companyCode}/confirm-wizard',
    COMPANY_BUFFERZONES: '/companies/{companyCode}/buffer-zones',
};

export function fetchCompanyDetail(companyCode: string) {
    return get<ICompanyDetail>({
        url: URL.COMPANY,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => response.company,
    });
}

export function updateCompany(companyToUpdate: IUpdateCompanyPayload | IUpdateCompanySingleFieldPayload) {
    return put({
        url: URL.COMPANY,
        pathParams: {
            companyCode: companyToUpdate.companyCode,
        },
        body: companyToUpdate.companyData,
    });
}

export function addCompanySeat({ address, companyCode, name }: IAddCompanySeatPayload & ICompanyCodePayload) {
    return post({
        url: URL.COMPANY,
        pathParams: {
            companyCode,
        },
        body: {
            address,
            alternativeName: name,
        },
    });
}

export function fetchCompanyContacts({
    companyCode,
    showFullFamily = false,
    filterStrict = false,
}: ICompanyCodePayload & { showFullFamily?: boolean } & { filterStrict?: boolean }) {
    return get<ICompanyContact[]>({
        url: URL.COMPANY_CONTACTS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily,
            filterStrict,
        },
        mapResponse: (response) => response.contacts,
    });
}

export function removeContact(companyCode: string, payload: IRemoveCompanyContactPayload) {
    const { customerContactId } = payload;

    return remove({
        url: URL.CONTACT_REMOVE_EDIT,
        pathParams: {
            companyCode,
            customerContactId,
        },
    });
}

export function addContact(payload: IAddCompanyContactPayload) {
    const { companyCode, contactData } = payload;

    return post({
        url: URL.CONTACT_ADD,
        body: contactData,
        pathParams: {
            companyCode,
        },
    });
}

export function updateContact(payload: IUpdateCompanyContactPayload) {
    const { companyCode, contactData, customerContactId } = payload;

    return put({
        url: URL.CONTACT_REMOVE_EDIT,
        pathParams: {
            companyCode,
            customerContactId,
        },
        body: contactData,
    });
}

export function fetchCompanyMedicalCenters(companyCode: string) {
    return get<ICompanyMedicalCenter[]>({
        url: URL.COMPANY_MEDICAL_CENTERS,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => response.medicalCenters,
    });
}

export function fetchCompanyCostCenters(companyCode: string) {
    return get<ICostCenter[]>({
        url: URL.COMPANY_COST_CENTERS,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => response['cost-centers'],
    });
}

export function fetchCompanyAvailabilities({
    companyCode,
    availabilityType,
}: {
    companyCode: string,
    availabilityType: CompanyAvailabilityType,
}) {
    return get<ICompanyAvailabilities>({
        url: URL.COMPANY_AVAILABILITIES,
        pathParams: {
            companyCode,
            availabilityCode: mapAvailabilityTypeToBackendConstant(availabilityType),
        },
        mapResponse: (response) => makeSureBothAvailabilityPeriodsAreThere(response.availabilities),
    });
}

export function makeSureBothAvailabilityPeriodsAreThere(
    availabilities: ICompanyAvailabilities,
): ICompanyAvailabilities {
    if (!availabilities.morning) {
        availabilities.morning = ({
            days: [],
        } as ICompanyAvailabilityPeriod);
    }
    if (!availabilities.afternoon) {
        availabilities.afternoon = ({
            days: [],
        } as ICompanyAvailabilityPeriod);
    }
    return availabilities;
}

/**
 * Input:
 * - availabilities:
 *     The availabilities input should be the whole list of availabilities that the user wants.
 *     Our backend call will first delete all existing availabilities and then create the ones passed here as input.
 * - available:
 *     true for green (=available) blocks
 *     false for red (=not-available) blocks
 * - applyFullFamily:
 *     true if you want that these availabilities will also be used for the other seats
 */
export function replaceCompanyAvailabilities({
    companyCode,
    availabilityType,
    applyFullFamily = false,
    available = true,
    availabilities,
}: {
    companyCode: string,
    availabilityType: CompanyAvailabilityType,
    applyFullFamily?: boolean,
    available?: boolean,
    availabilities: TCreateCompanyAvailabilities,
}) {
    return put<ICompanyAvailabilities>({
        url: URL.COMPANY_AVAILABILITIES,
        pathParams: {
            companyCode,
            availabilityCode: mapAvailabilityTypeToBackendConstant(availabilityType),
        },
        queryParams: {
            applyFullFamily: applyFullFamily.toString(),
            available: available.toString(),
        },
        body: removeEmptyPropsFromObject(availabilities),
        mapResponse: (response) => makeSureBothAvailabilityPeriodsAreThere(response.availabilities),
    });
}

export function mapAvailabilityTypeToBackendConstant(availabilityType: CompanyAvailabilityType): string {
    return availabilityType === CompanyAvailabilityType.MedicalExaminations ? 'MT' : 'RB';
}

export function fetchCompanyAddresses(companyCode: string, showFullFamily: boolean = false) {
    return get<ICompanyAddress[]>({
        url: URL.COMPANY_ADDRESSES,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily,
        },
        mapResponse: (response) => response.addresses,
    });
}

export function fetchCompanyHolidays({ companyCode, year }: IFetchCompanyHolidaysPayload) {
    return get<ICompanyHoliday[]>({
        url: URL.COMPANY_HOLIDAYS_SPECIFIC_YEAR,
        pathParams: {
            companyCode,
            year,
        },
        mapResponse: (response) => mapHolidaysResponse(response.holidays),
    });
}

function mapHolidaysResponse(holidays: ICompanyHoliday[]) {
    /*
        When there are multiple custom holidays, backend puts them in a category with customTypeId.
        However if there is only one, they send it on the base level.
        We will put the base level holiday into a category for ease of use and rendering.
    */
    const singleCustomHolidayOnBaseLevel =
        holidays.find((holiday) =>
            !!holiday.customTypeId && (!!holiday.customerHolidayIdMT || !!holiday.customerHolidayIdRB),
        );

    if (!singleCustomHolidayOnBaseLevel) {
        return holidays;
    }

    const fixedHolidays = holidays.filter((holiday) => !holiday.customTypeId);

    const customHolidayCategory: ICompanyHoliday = {
        description: 'Company holidays', // Not displayed, but used to generate list ids in HolidaysOverview component
        isCustomerPeriod: true,
        customTypeId: singleCustomHolidayOnBaseLevel.customTypeId,
        holidayTypes: [singleCustomHolidayOnBaseLevel as IHolidayBase],
    };
    return [...fixedHolidays, customHolidayCategory];
}

export function updateCompanyHolidays({ companyCode, holidays }: IUpdateCompanyHolidaysPayload) {
    return post({
        url: URL.COMPANY_HOLIDAYS,
        pathParams: {
            companyCode,
        },
        body: {
            holidays,
        },
    });
}

export function confirmOnboardingWizard({ companyCode }: ICompanyCodePayload) {
    return put({
        url: URL.COMPANY_CONFIRM_ONBOARDING_WIZARD,
        pathParams: {
            companyCode,
        },
    });
}

export const DEFAULT_COMPANY_BUFFERZONES_FILTERS: Partial<IFilterValues> = {
    startDate: formatDateForBackend(now()),
    endDate: formatDateForBackend(monthOffsetFromNow(6)),
};

export function fetchCompanyBufferzones({
    companyCode,
    showFullFamily,
    startDate = DEFAULT_COMPANY_BUFFERZONES_FILTERS.startDate,
    endDate = DEFAULT_COMPANY_BUFFERZONES_FILTERS.endDate,
}: IFetchCompanyBufferzoneAPIPayload) {
    return get<ICompanyBufferzone[]>({
        url: URL.COMPANY_BUFFERZONES,
        pathParams: {
            companyCode,
        },
        queryParams: {
            /**
             * When getting the list of bufferzones, we should always do this FullFamily,
             * because we also always get the timecells fullfamily (default setting in back-end).
             * Otherwise the timecells call would return more cells than those that are
             * within the bufferzone (when the bufferzone was fetched for a single seat).
             * But we can not by default pass always 'true' from the front-end because that would
             * result in authorisation errors from the back-end.
             * So also here the back-end will by default pass 'true' to the mensura backend.
             * See KZUAT-1367.
             */
            showFullFamily: showFullFamily.toString(),
            startDate,
            endDate,
        },
        timeoutInMillis: 90 * ONE_SECOND,
        mapResponse: (response) => response['buffer-zones'],
    });
}
