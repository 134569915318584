import React, { Component } from 'react';
import './draggable-medical-examination.scss';
import classNames from 'classnames';
import { IEventProps } from '../../../TimeGridEvent';
import Icon from '../../../../../icons/Icon';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../../../../config/calendar.config';
import { CalendarEventType, ICalendarEvent } from '../../../../../../../models/ui/calendar';
import {
    createPrefixedDraggableId,
    createPrefixedDroppableId,
} from '../../../../../../../utils/libs/react-beautiful-dnd/reactBeautifulDndUtils';
import { DraggablePrefix, DroppablePrefix } from '../../../../../../../models/interventions/timeslots';
import DragAndDropWrapper from '../../../../dragAndDrop/DragAndDropWrapper';
import { hoursOffsetFromNow, getDate } from '../../../../../../../utils/core/date/getSpecificDate';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../../../config/medicalExamination.config';
import {
    IPlannedMedicalExamination,
    IExaminationReason,
} from '../../../../../../../models/interventions/medicalExaminations';
import { connect } from '../../../../../..';
import { getExaminationReasons } from '../../../../../../../redux/medicalExamination/selectors';
import { getPlanBufferzoneModifiedTimeCellIds } from '../../../../../../../redux/intervention/bufferzones/selectors';
import { getDraggableEmployeeActiveDraggable } from '../../../../../../../redux/ui/dragAndDrop/selectors';
// eslint-disable-next-line max-len
import PlannedExaminationDialog from '../../../../../../interventions/PlanMedicalExamination/PlanMedicalExaminationWizard/shared/SelectFreeTimeslot/PlannedExaminationDialog';
import { getTextColorBasedOnBackgroundColor } from '../../../../../../../utils/color/color';

const CLASS_NAME = 'DraggableMedicalExamination';

interface IPrivateProps {
    examinationReasons: IExaminationReason[];
    modifiedTimeslotIds: number[];
    isToPlanEmployeeDraggable: boolean;
}

interface IComponentState {
    selectedEvent: ICalendarEvent<IPlannedMedicalExamination>;
}

class DraggableMedicalExamination extends Component<IPrivateProps & IEventProps, IComponentState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedEvent: null,
        };

        this.onSelectEvent = this.onSelectEvent.bind(this);
        this.onCloseEventDetail = this.onCloseEventDetail.bind(this);
    }

    public render() {
        const { examinationReasons, modifiedTimeslotIds, isToPlanEmployeeDraggable } = this.props;
        const event = this.props.event as ICalendarEvent<IPlannedMedicalExamination>;
        const disableDrag = hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() >
            getDate(event.data.time);
        const isModified = event.data && modifiedTimeslotIds.includes(event.data.timeCell.id);

        function getBlockColorsFromExaminationReason() {
            const examinationReason = examinationReasons.find((reason) =>
                reason.id === (event.data && event.data.examinationReason && event.data.examinationReason.id),
            );

            return examinationReason && examinationReason.colour && {
                backgroundColor: examinationReason.colour,
                textColor: getTextColorBasedOnBackgroundColor(examinationReason.colour),
            };
        }

        return (
            <>
                <DragAndDropWrapper
                    draggableId={createPrefixedDraggableId(this.props.event.id, DraggablePrefix.PlannedExamination)}
                    droppableId={createPrefixedDroppableId(this.props.event.id, DroppablePrefix.PlannedExamination)}
                    disableMove
                    isDragDisabled={disableDrag}
                    isNotDraggable={isToPlanEmployeeDraggable}
                    render={(renderProps) => {
                        const className = classNames(
                            'rbc-event',
                            CALENDAR_EVENT_TYPES_CONFIG[CalendarEventType.MedicalExamination].cssClassName,
                            CLASS_NAME,
                            {
                                'rbc-selected': this.props.selected,
                                disabled: disableDrag,
                            },
                        );
                        const blockColors = getBlockColorsFromExaminationReason();

                        return (
                            <span
                                className={className}
                                style={{
                                    backgroundColor: isModified && blockColors && blockColors.backgroundColor,
                                    color: isModified && blockColors && blockColors.textColor,
                                }}
                                onClick={this.onSelectEvent}
                            >
                                <Icon typeName="check" />
                                <span>{this.props.event.title}</span>
                            </span>
                        );
                    }}
                />
                <PlannedExaminationDialog
                    plannedExaminations={this.state.selectedEvent ? [this.state.selectedEvent.data] : []}
                    unselectPlannedExaminations={this.onCloseEventDetail}
                />
            </>
        );
    }

    private onSelectEvent() {
        const { event } = this.props;
        if (event.type === CalendarEventType.DraggableMedicalExamination) {
            this.setState({ selectedEvent: event as ICalendarEvent<IPlannedMedicalExamination> });
        }
    }

    private onCloseEventDetail() {
        this.setState({ selectedEvent: null });
    }
}

export default connect({
    stateProps: (state) => {
        const toPlanEmployeeDraggable = getDraggableEmployeeActiveDraggable(state);
        return {
            examinationReasons: getExaminationReasons(state),
            modifiedTimeslotIds: getPlanBufferzoneModifiedTimeCellIds(state),
            isToPlanEmployeeDraggable: !!toPlanEmployeeDraggable,
        };
    },
})(DraggableMedicalExamination);
