import React, { Component, PureComponent } from 'react';
import clone from 'ramda/src/clone';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import { IEmployee } from '../../../../../../models/admin/employee';
import {
    ISortedColumn,
    ListColumns,
    ListItem,
    SortType,
    SortOrder,
    ListItemId, IColumn,
} from '../../../../../../models/general/list';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import {
    IMedicalExaminationToPlan,
    IPlanMedicalExaminationMultipleEmployeesBaseEntity,
    IPlanMedicalExaminationWizardPayload,
    IExaminationReason,
} from '../../../../../../models/interventions/medicalExaminations';
import {
    getEmployeesToPlanAsyncInfo,
    getEmployeesToPlan,
    getEmployeesToPlanCombinedWithAddedEmployees,
    getPlanMedicalExaminationWizardEntity,
    getPlanMedicalExaminationWizardReason,
} from '../../../../../../redux/medicalExamination/selectors';
import { updatePlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/actions';
import { getRoutePayload } from '../../../../../../redux/location/selectors';
import {
    DEFAULT_NR_OF_DAYS_IN_THE_FUTURE,
    MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN,
} from '../../../../../../config/medicalExamination.config';
import SearchEmployeeToPlanOverlay from '../SearchEmployeeToPlanOverlay';
import PageHeader from '../../../../../appShell/PageHeader';
import Button from '../../../../../common/buttons/Button';
import ErrorPlaceholder from '../../../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Icon from '../../../../../common/icons/Icon';
import CheckboxesOrTypeaheadFilter from '../../../../../common/input/CheckboxesOrTypeaheadFilter';
import TextInput from '../../../../../common/input/TextInput';
import ListWithCheckboxSelect from '../../../../../common/list/ListWithCheckboxSelect';
import Translate from '../../../../../common/Translate';
import MasterWithDetail from '../../../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, IRenderMasterActionsProps,
    ITransformToActiveFiltersProps,
    IClientSideFilterOfListDataProps, IOnSelectAllChangeProps,
    IRenderFilterContentProps, IRenderSearchContentProps, IRenderSelectAllTranslateProps,
} from '../../../../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { formatPersonNameFormal } from '../../../../../../utils/formatting/formatPerson';
import {
    formatDateForBackend, formatDateInLongFormat,
    formatDateForDisplay,
} from '../../../../../../utils/formatting/formatDate';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import { separateStringList } from '../../../../../../utils/core/string/separatedStringList';
import { dayOffsetFromNow } from '../../../../../../utils/core/date/getSpecificDate';
import { getColumnKeysToIgnore, doesItemMatchFilter } from '../../../../../../utils/list/filterListItems';
import { getMedExamToAddId } from '../../../../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import sortListItems from '../../../../../../utils/list/sortListItems';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import { IState, NO_RERENDER } from '../../../../../../redux';
import { createGenericActiveFilters } from '../../../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../../../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../../../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../../../../models/ui/form';
import ErrorDialog from '../../../../../common/modals/ErrorDialog';
import EmployeeConditionTooltip from '../../../../../administration/Employees/shared/EmployeeConditionTooltip';
import {
    mapEmployeesToPlanToMedicalExaminationsToAdd,
    mapEmployeeToPlanToMedicalExaminationToAdd,
} from '../../../../../../utils/interventions/medicalExaminations/mapEmployeesToPlanToMedicalExaminationsToAdd';
import {
    DEFAULT_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS,
} from '../../../../../../api/interventions/medicalExaminations.api';
import { isBefore } from '../../../../../../utils/core/date/isBefore';
import { isAfter } from '../../../../../../utils/core/date/isAfter';

const BASE_NAME = 'employees-to-plan';
const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'deadline',
    sortOrder: SortOrder.Ascending,
};
export const DEADLINE_FILTER = {
    FROM_DEFAULT: DEFAULT_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS.startDate,
    TO_DEFAULT: DEFAULT_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS.endDate,
};
const DEFAULT_QUERY_PARAMS: Pick<IFilterValues, 'startDate' | 'endDate'> = {
    startDate: DEADLINE_FILTER.FROM_DEFAULT,
    endDate: DEADLINE_FILTER.TO_DEFAULT,
};

interface ISearchAndFilterCustomData {
    selectedToPlanIds: string[];
}

interface IListItemCustomData {
    matchesClientSideFilterAndSearch?: boolean;
}

interface IColumnNames {
    condition: string;
    employee: string;
    birthDate: string;
    birthDateSort: string;
    function: string;
    functionId: number;
    seat: string;
    seatCode: string;
    deadline: string;
    deadlineSort: string;
    examinationReason: string;
    dateOutOfService: string;
    absent: string;
}

type TColumnNamesForFilter =
    Pick<
        IColumnNames,
        'absent' | 'condition' | 'dateOutOfService' | 'deadline' | 'deadlineSort' | 'employee' |
        'examinationReason' | 'function' | 'functionId' | 'seat' | 'seatCode'
    >;

const COLUMNS: ListColumns<IColumnNames> = {
    condition: {
        sortable: false,
        percentWidth: 5,
    },
    employee: {
        label: <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.columns.employee" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 17,
    },
    birthDate: {
        label: <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.columns.birth_date" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.birthDateSort,
        percentWidth: 14,
    },
    birthDateSort: {
        hide: true,
        percentWidth: null,
    },
    function: {
        label: <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.columns.function" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 17,
    },
    functionId: {
        hide: true,
        percentWidth: null,
    },
    seat: {
        label: <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.columns.seat" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    seatCode: {
        hide: true,
        percentWidth: null,
    },
    deadline: {
        label: <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.columns.deadline" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.deadlineSort,
        percentWidth: 14,
    },
    deadlineSort: {
        hide: true,
        percentWidth: null,
    },
    examinationReason: {
        label: <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.columns.reason" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 13,
    },
    dateOutOfService: {
        percentWidth: null,
        hide: true,
    },
    absent: {
        percentWidth: null,
        hide: true,
    },
};

export interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    functionIds: string;
    seatCodes: string;
    examinationReasons: string;
}

interface IPrivateProps {
    currentRoutePayload: object;
    isInitialLoad: boolean;
    selectedToPlanIds: string[];
    employeesToPlanAsyncInfo: IAsyncFieldInfo;
    setToPlanIds: (selectedToPlanIds: string[]) => void;
    onSelectAllChange: (props: IOnSelectAllChangeProps) => void;
    addEmployeeToList: (employee: IEmployee) => string;
}

interface IComponentState {
    showOverlay: boolean;
    idToScrollIntoView?: ListItemId;
    showMaxAmountDialog: boolean;
}

class EmployeesToPlan extends Component<IPrivateProps & IStepperStepRenderProps, IComponentState> {
    constructor(props: IPrivateProps & IStepperStepRenderProps) {
        super(props);
        this.state = {
            showOverlay: false,
            showMaxAmountDialog: false,
        };

        this.renderAddEmployeeButton = this.renderAddEmployeeButton.bind(this);
        this.renderSelectAllTranslate = this.renderSelectAllTranslate.bind(this);
        this.onToggleAddEmployeeOverlay = this.onToggleAddEmployeeOverlay.bind(this);
        this.onAddEmployeeHandler = this.onAddEmployeeHandler.bind(this);
        this.onClickNextHandler = this.onClickNextHandler.bind(this);
        this.jumpToRowWithId = this.jumpToRowWithId.bind(this);
        this.onToggleShowMaxAmountDialog = this.onToggleShowMaxAmountDialog.bind(this);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IStepperStepRenderProps) {
        const { selectedToPlanIds, isInitialLoad, setToPlanIds, employeesToPlanAsyncInfo } = this.props;
        if (prevProps.employeesToPlanAsyncInfo.status === AsyncStatus.Busy &&
            employeesToPlanAsyncInfo.status === AsyncStatus.Success) {
            if (isInitialLoad) {
                // the selectedToPlanIds are in this case the initialSelectedPlanIds
                const selectedToPlanIdsMax =
                    selectedToPlanIds.length > MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN ?
                        selectedToPlanIds.slice(0, MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN) :
                        selectedToPlanIds;
                setToPlanIds(selectedToPlanIdsMax);
            }
        }
    }

    public render() {
        const {
            currentRoutePayload, selectedToPlanIds, renderStepButtons, onSelectAllChange,
        } = this.props;

        const AddEmployeeShortLink = (
            <a onClick={this.onToggleAddEmployeeOverlay}>
                <Icon typeName="plus-circle" />
                <span>
                    <Translate
                        msg="interventions.medical_examinations.new.steps.employees_to_plan.actions.add_employee_short"
                    />
                </span>
            </a>
        );

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.employees_to_plan.title"
                    text="interventions.medical_examinations.new.steps.employees_to_plan.text"
                    textPlaceholders={{
                        addActionShort: AddEmployeeShortLink,
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <MasterWithDetail
                        baseName={BASE_NAME}
                        getDefaultQueryParams={getDefaultQueryParams}
                        masterConfig={{
                            routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD,
                            routePayload: currentRoutePayload,
                            asyncInfoSelector: getEmployeesToPlanAsyncInfo,
                            dataSelector: getEmployeesToPlanCombinedWithAddedEmployees,
                            transformData: mapEmployeesToPlanToListItems,
                            renderContent: (
                                renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>,
                            ) =>
                                <EmployeesToPlanList {...renderProps} {...this.props} {...this.state} />,
                            clientSideFilterOfListData: clientSideSearchAndFilterOfListData,
                            clientSideFilterCustomDataSelector,
                            transformFilterValuesToActiveFilters,
                            filterValidationSchema: startEndDateSchema,
                        }}
                        headerConfig={{
                            selectAllCheckbox: {
                                renderTranslateComponent: this.renderSelectAllTranslate,
                                isSelected: ({ masterData: clientSideFilteredlistItems }) => {
                                    if (selectedToPlanIds.length <= 0) {
                                        return false;
                                    }
                                    if (clientSideFilteredlistItems['length'] < MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN) {
                                        return selectedToPlanIds.length === clientSideFilteredlistItems['length'];
                                    }
                                    return selectedToPlanIds.length === MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN;
                                },
                                onSelectionChange: onSelectAllChange,
                            },
                            renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                                <SearchContent {...renderProps} />,
                            renderFilterContent:
                                // eslint-disable-next-line max-len
                                (renderProps: IRenderFilterContentProps<ListItem<TColumnNamesForFilter>[], IFilterValues>) =>
                                    <FilterContent {...renderProps} />,
                        }}
                        footerConfig={{
                            // TODO: remove 'add employee' after confirmation from Mensura
                            // renderActionsLeft: this.renderAddEmployeeButton,
                            renderActionsRight: () =>
                                renderStepButtons({
                                    nextButton: {
                                        onClick: this.onClickNextHandler,
                                        disabled: selectedToPlanIds.length < 1,
                                    },
                                }),
                        }}
                    />
                </div>
                <SearchEmployeeToPlanOverlay
                    show={this.state.showOverlay}
                    onClose={this.onToggleAddEmployeeOverlay}
                    onSelect={this.onAddEmployeeHandler}
                />
                <ErrorDialog
                    showOverride={this.state.showMaxAmountDialog}
                    onCloseDialog={this.onToggleShowMaxAmountDialog} // eslint-disable-next-line max-len
                    titleTranslationKey="interventions.medical_examinations.new.steps.employees_to_plan.max_amount_dialog.title"
                    titlePlaceholders={{
                        maxAmount: MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN,
                    }}
                    hideRealErrorMessage={true}
                />
            </>
        );
    }

    private renderSelectAllTranslate(props: IRenderSelectAllTranslateProps) {
        const {
            masterData: clientSideFilteredlistItems,
        } = props;
        if (clientSideFilteredlistItems['length'] <= MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN) {
            return (
                <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.actions.select_all" />
            );
        }
        return (
            <Translate
                msg="interventions.medical_examinations.new.steps.employees_to_plan.actions.select_all_with_max"
                placeholders={{
                    amount: MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN,
                }}
            />
        );
    }

    private renderAddEmployeeButton(renderProps: IRenderMasterActionsProps) {
        const {
            isFetchingMaster,
        } = renderProps;

        return (
            <Button
                id="add-employee-to-plan"
                typeName="text"
                className="AddButton"
                disabled={isFetchingMaster}
                onClick={this.onToggleAddEmployeeOverlay}
            >
                <Icon typeName="plus" circle />
                <span>
                    {/*eslint-disable-next-line max-len*/}
                    <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.actions.add_employee" />
                </span>
            </Button>
        );
    }

    private onToggleAddEmployeeOverlay() {
        if (this.props.selectedToPlanIds.length >= MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN) {
            this.onToggleShowMaxAmountDialog();
        } else {
            this.setState({
                showOverlay: !this.state.showOverlay,
            });
        }
    }

    private onToggleShowMaxAmountDialog() {
        this.setState({
            showMaxAmountDialog: !this.state.showMaxAmountDialog,
        });
    }

    private onAddEmployeeHandler(employee: IEmployee) {
        const uniqueId = this.props.addEmployeeToList(employee);

        this.onToggleAddEmployeeOverlay();

        this.jumpToRowWithId(uniqueId);
    }

    private jumpToRowWithId(uniqueId: string) {
        this.setState({
            idToScrollIntoView: uniqueId,
        });
    }

    private onClickNextHandler() {
        if (this.props.isInitialLoad) {
            /**
             * On the initial load, an initial selection is made that is not yet stored in the redux state.
             * The selected examinations are only stored after a user changes a selection (causing 'isInitialLoad'
             * to be false).
             * Therefore we make sure that if a user clicks on next, without first changing the initial
             * selection, that that initial selection gets saved.
             */
            this.props.setToPlanIds(this.props.selectedToPlanIds);
        }
        this.props.goToNextStep();
    }
}

/**
 * Search is also done here (instead of using the masterConfig.clientSideSearchOfListData) because we want to
 * always show - regardless of search/filter input - the selected items.
 * This makes it clear to the user at all times which employees are selected.
 */
export function clientSideSearchAndFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<
        ListItem<IColumnNames, ListItemId, IListItemCustomData>[],
        IFilterValues,
        ISearchAndFilterCustomData
    >,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;
    const { selectedToPlanIds } = filterProps.customData;

    const searchFilter = filterValues.search;
    const columnKeysToIgnoreForSearch = getColumnKeysToIgnore({ columnsConfig: COLUMNS });

    return listItems
        .reduce(
            (listItemAccumulator, listItem) => {
                if (
                    doesListItemMatchClientSideSearch({
                        listItem,
                        searchFilter,
                        isFilterSet,
                        columnKeysToIgnoreForSearch,
                    })
                    && doesListItemMatchClientSideFilters({
                        listItem,
                        filterValues,
                        isFilterSet,
                    })
                ) {
                    listItem.data = {
                        matchesClientSideFilterAndSearch: true,
                    };
                    listItemAccumulator.push(listItem);
                } else if (selectedToPlanIds.includes(listItem.id as string)) {
                    /**
                     * Selected items that do not match the filter/search, are still returned so that it's always
                     * clear to the user which items are selected when proceeding to the next step (but they will
                     * sorted to the bottom so that - especially on large lists with lots of selected items - the
                     * search/filter still is usable for the user)
                     */
                    listItem.data = {
                        matchesClientSideFilterAndSearch: false,
                    };
                    listItemAccumulator.push(listItem);
                }
                return listItemAccumulator;
            },
            [],
        );
}

function doesListItemMatchClientSideSearch({ listItem, searchFilter, isFilterSet, columnKeysToIgnoreForSearch }: {
    listItem: ListItem<IColumnNames>,
    searchFilter: string,
    isFilterSet: (filterValue: string) => boolean,
    columnKeysToIgnoreForSearch: string[],
}) {
    if (isFilterSet(searchFilter)) {
        if (!doesItemMatchFilter(listItem, searchFilter, columnKeysToIgnoreForSearch)) {
            return false;
        }
    }

    return true;
}

function doesListItemMatchClientSideFilters({ listItem, filterValues, isFilterSet }: {
    listItem: ListItem<IColumnNames>,
    filterValues: IFilterValues,
    isFilterSet: (filterValue: string) => boolean,
}) {
    if (isFilterSet(filterValues.functionIds)) {
        const functionIds = separateStringList(filterValues.functionIds);
        if (!listItem.columns.functionId || !functionIds.includes(listItem.columns.functionId.toString())) {
            return false;
        }
    }

    if (isFilterSet(filterValues.seatCodes)) {
        const seatCodes = separateStringList(filterValues.seatCodes);
        if (!seatCodes.includes(listItem.columns.seatCode as string)) {
            return false;
        }
    }

    if (isFilterSet(filterValues.examinationReasons)) {
        const examinationReasons = separateStringList(filterValues.examinationReasons);
        if (!examinationReasons.includes(listItem.columns.examinationReason as string)) {
            return false;
        }
    }

    if (isFilterSet(filterValues.startDate)) {
        if (listItem.columns.deadlineSort < filterValues.startDate) {
            return false;
        }
    }

    if (isFilterSet(filterValues.endDate)) {
        if (listItem.columns.deadlineSort > filterValues.endDate) {
            return false;
        }
    }

    return true;
}

export default connect<IPrivateProps, IStepperStepRenderProps>({
    stateProps: (state) => {
        // It's also possible for an added employee to not have a planningRecordId, later in the wizard we will need
        // to check if the employee has a planningRecordId and do a different call accordingly

        return {
            currentRoutePayload: unsetResetDataEntityFlag(getRoutePayload(state)),
            isInitialLoad: isInitialLoad(getWizardEntity(state)),
            selectedToPlanIds: getSelectedToPlanIds(state),
            employeesToPlanAsyncInfo: getEmployeesToPlanAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            setToPlanIds: (selectedToPlanIds: string[]) => {
                const employeesToPlan = getEmployeesToPlanCombinedWithAddedEmployees(getState());
                const selectedEmployeesToPlan = employeesToPlan
                    .filter((employeeToPlan) => selectedToPlanIds.includes(getMedExamToAddId(employeeToPlan)));
                const valuesToUpdate = mapEmployeesToPlanToMedicalExaminationsToAdd(selectedEmployeesToPlan);

                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>({
                    medicalExaminationsToAdd: valuesToUpdate,
                }));
            },
            onSelectAllChange: (selectAllProps: IOnSelectAllChangeProps<ListItem<IColumnNames>[]>) => {
                const employeesToPlan = getEmployeesToPlanCombinedWithAddedEmployees(getState());
                const clientSideFilteredItemIds = selectAllProps.masterData
                    .sort(sortByDeadline)
                    .map((listItem) => listItem.id);

                const clientSideFilteredItemIdsMax =
                    clientSideFilteredItemIds.length > MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN ?
                        clientSideFilteredItemIds.slice(0, MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN) :
                        clientSideFilteredItemIds;

                const selectedEmployeesToPlan = selectAllProps.isSelected ?
                    employeesToPlan.filter((employeeToPlan) =>
                        clientSideFilteredItemIdsMax.includes(getMedExamToAddId(employeeToPlan)),
                    ) : [];
                const valuesToUpdate = mapEmployeesToPlanToMedicalExaminationsToAdd(selectedEmployeesToPlan);

                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>({
                    medicalExaminationsToAdd: valuesToUpdate,
                }));
            },
            addEmployeeToList: (employee: IEmployee) => {
                const state = getState();
                const wizardEntity = getWizardEntity(state);
                const medicalExaminationsToAdd = wizardEntity.medicalExaminationsToAdd ?
                    wizardEntity.medicalExaminationsToAdd.slice() : [];
                const addedEmployees = wizardEntity.addedEmployees ?
                    wizardEntity.addedEmployees.slice() : [];

                const employeesToPlan = getEmployeesToPlan(state);
                let matchingEmployeeToPlan = findEmployeeInList(employeesToPlan, employee);

                if (!matchingEmployeeToPlan) {
                    matchingEmployeeToPlan = toMedicalExaminationToPlan(
                        employee,
                        getPlanMedicalExaminationWizardReason(state),
                    );

                    if (!findEmployeeInList(addedEmployees, employee)) {
                        addedEmployees.push(matchingEmployeeToPlan);
                    }
                }

                const uniqueId = getMedExamToAddId(matchingEmployeeToPlan);
                if (!medicalExaminationsToAdd.find((medExamToAdd) => getMedExamToAddId(medExamToAdd) === uniqueId)) {
                    medicalExaminationsToAdd.push(
                        mapEmployeeToPlanToMedicalExaminationToAdd(matchingEmployeeToPlan),
                    );
                }

                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>({
                    medicalExaminationsToAdd,
                    addedEmployees,
                }));

                return uniqueId;
            },
        };
    },
})(EmployeesToPlan);

function sortByDeadline(a: ListItem<IColumnNames>, b: ListItem<IColumnNames>) {
    return (
        a.columns.deadlineSort < b.columns.deadlineSort ?
            -1 : a.columns.deadlineSort > b.columns.deadlineSort ?
                1 : 0
    );
}

function clientSideFilterCustomDataSelector(state: IState) {
    return {
        selectedToPlanIds: getSelectedToPlanIds(state),
    };
}

function getSelectedToPlanIds(state: IState) {
    const wizardEntity = getWizardEntity(state);

    if (isInitialLoad(wizardEntity)) {
        const initialSelectedPlanIds = getInitialSelectedPlanIds(getEmployeesToPlan(state));
        return initialSelectedPlanIds.length > MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN ?
            initialSelectedPlanIds.slice(0, MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN) :
            initialSelectedPlanIds;
    }

    const currentSelectedMedicalExaminations = wizardEntity.medicalExaminationsToAdd || NO_RERENDER.EMPTY_LIST;

    return currentSelectedMedicalExaminations
        .map((medExam) => getMedExamToAddId(medExam));
}

function getWizardEntity(state: IState) {
    return getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>(state);
}

function isInitialLoad(wizardEntity: Partial<IPlanMedicalExaminationMultipleEmployeesBaseEntity>) {
    // wizardEntity is resetted to an empty object when resetDataEntity=true
    return (typeof wizardEntity.medicalExaminationsToAdd === 'undefined');
}

function getInitialSelectedPlanIds(masterData: IMedicalExaminationToPlan[]) {
    const deadlineThreshold = dayOffsetFromNow(DEFAULT_NR_OF_DAYS_IN_THE_FUTURE.TO_INITIALLY_SELECT_EMPLOYEES_TO_PLAN);

    return masterData
        .filter((item) => {
            if (item.toBePlannedDate > formatDateForBackend(deadlineThreshold)) {
                return false;
            }
            if (
                isBefore(item.toBePlannedDate, DEADLINE_FILTER.FROM_DEFAULT) ||
                isAfter(item.toBePlannedDate, DEADLINE_FILTER.TO_DEFAULT)
            ) {
                return false;
            }
            return true;
        })
        .sort((a, b) => {
            return (
                a.toBePlannedDate < b.toBePlannedDate ?
                    -1 : a.toBePlannedDate > b.toBePlannedDate ?
                        1 : 0
            );
        })
        .map((item) => getMedExamToAddId(item));
}

export function toMedicalExaminationToPlan(
    employee: IEmployee,
    examinationReason: IExaminationReason,
    planningRecordId?: number,
): IMedicalExaminationToPlan {
    return {
        employee: {
            id: employee.id,
            employeeId: employee.employeeId,
            firstName: employee.firstName,
            name: employee.name,
            birthDate: null,
            dateOutOfService: employee.dateOutOfService,
        },
        company: {
            name: employee.company.name,
            companyCode: employee.company.companyCode,
            id: employee.company.id,
        },
        function: {
            id: employee.function.id,
            description: employee.function.description,
        },
        planningRecordId,
        medicalCenter: null,
        examinationReason: {
            id: examinationReason.id,
            title: examinationReason.title,
        },
        duration: examinationReason.duration,
        rescheduleAbsent: false, // ???
        toBePlannedDate: null,
        toBePlanned: true,
        absent: employee.absent,
        absentDescription: null,
    };
}

export function findEmployeeInList(list: IMedicalExaminationToPlan[], employee: IEmployee): IMedicalExaminationToPlan {
    return list.find((medicalExaminationToPlan) =>
        medicalExaminationToPlan.employee.id === employee.id);
}

// eslint-disable-next-line max-len
export function filterEmployeesInList(list: IMedicalExaminationToPlan[], employee: IEmployee): IMedicalExaminationToPlan[] {
    return list.filter((medicalExaminationToPlan) =>
        medicalExaminationToPlan.employee.id === employee.id);
}

export function getDefaultQueryParams() {
    return DEFAULT_QUERY_PARAMS;
}

function mapEmployeesToPlanToListItems(masterData: IMedicalExaminationToPlan[]): ListItem<IColumnNames>[] {
    return masterData
        .map((toPlan) => ({
            id: getMedExamToAddId(toPlan),
            columns: {
                condition: '',
                employee: formatPersonNameFormal(toPlan.employee),
                birthDate: formatBirthDateIfExists(toPlan),
                birthDateSort: getBirthDateIfExists(toPlan),
                function: toPlan.function.description,
                functionId: toPlan.function.id,
                seat: toPlan.company.name,
                seatCode: toPlan.company.companyCode,
                deadline: formatToBePlannedDateIfExists(toPlan),
                deadlineSort: getToBePlannedDateIfExists(toPlan),
                examinationReason: toPlan.examinationReason.title,
                absent: toPlan.absent,
                dateOutOfService: toPlan.employee.dateOutOfService,
            },
        }));
}

function formatBirthDateIfExists(toPlan: IMedicalExaminationToPlan) {
    if (toPlan.employee && toPlan.employee.birthDate) {
        return formatDateInLongFormat(toPlan.employee.birthDate);
    }

    return '';
}

function getBirthDateIfExists(toPlan: IMedicalExaminationToPlan) {
    if (toPlan.employee && toPlan.employee.birthDate) {
        return toPlan.employee.birthDate;
    }

    return '1900-01-01';
}

function formatToBePlannedDateIfExists(toPlan: IMedicalExaminationToPlan) {
    if (toPlan.toBePlannedDate) {
        return formatDateInLongFormat(toPlan.toBePlannedDate);
    }

    return '';
}

function getToBePlannedDateIfExists(toPlan: IMedicalExaminationToPlan) {
    if (toPlan.toBePlannedDate) {
        return toPlan.toBePlannedDate;
    }

    return '1900-01-01';
}

type IProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues> & IPrivateProps & IComponentState;

class EmployeesToPlanList extends PureComponent<IProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props) {
        super(props);

        this.renderEmployeeConditionTooltip = this.renderEmployeeConditionTooltip.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedToPlanIds,
            setToPlanIds,
            footer,
            idToScrollIntoView,
        } = this.props;

        this.columns.condition.render = this.renderEmployeeConditionTooltip;

        return (
            <ListWithCheckboxSelect
                name={BASE_NAME}
                withSorting={true}
                initialSort={INITIAL_SORT}
                columns={this.columns}
                items={clientSideFilteredlistItems}
                selectedItemIds={selectedToPlanIds}
                onItemSelected={setToPlanIds}
                sortableOnSelected={true}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                footer={footer}
                idToScrollIntoView={idToScrollIntoView}
                customSorter={customSorter}
                getCustomRowClasses={getCustomRowClasses}
                maxSelectedItems={MAX_NR_OF_EMPLOYEES_TO_AUTO_PLAN}
                selectOnItemRowClicked
            />
        );
    }

    private renderEmployeeConditionTooltip(listItem: ListItem<IColumnNames>) {
        return (
            <EmployeeConditionTooltip
                employee={{
                    absent: listItem.columns.absent as boolean,
                    dateOutOfService: listItem.columns.dateOutOfService as string,
                }}
            />
        );
    }
}

export function customSorter(
    listItems: ListItem<IColumnNames, ListItemId, IListItemCustomData>[],
    sortedColumn: ISortedColumn<IColumnNames>,
    columnConfig: IColumn<IColumnNames>,
): ListItem<IColumnNames>[] {
    // matchesClientSideFilterAndSearch
    // sortAsBoolean

    const matchingListItems = sortListItems(
        listItems.filter((listItem) => listItem.data.matchesClientSideFilterAndSearch),
        sortedColumn,
        columnConfig,
    );

    const nonMatchingButSelectedListItems = sortListItems(
        listItems.filter((listItem) => !listItem.data.matchesClientSideFilterAndSearch),
        sortedColumn,
        columnConfig,
    );

    // show the non-matching items at the bottom
    return matchingListItems.concat(nonMatchingButSelectedListItems);
}

export function getCustomRowClasses(listItem: ListItem<IColumnNames, ListItemId, IListItemCustomData>) {
    return listItem.data && !listItem.data.matchesClientSideFilterAndSearch
        ? 'no-filter-match'
        : null;
}

export function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.medical_examinations.new.steps.employees_to_plan.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

export function FilterContent(
    renderProps: IRenderFilterContentProps<ListItem<TColumnNamesForFilter>[], IFilterValues>,
) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleFunctions = getUniqueTypeaheadFilterValuesFromListItems<TColumnNamesForFilter>(
        allListItems,
        'functionId',
        'function',
    );
    const possibleSeats = getUniqueTypeaheadFilterValuesFromListItems<TColumnNamesForFilter>(
        allListItems,
        'seatCode',
        'seat',
    );
    const possibleReasons = getUniqueTypeaheadFilterValuesFromListItems<TColumnNamesForFilter>(
        allListItems,
        'examinationReason',
        'examinationReason',
    );

    return (
        <div>
            <CheckboxesOrTypeaheadFilter
                filterName="function"
                labelTranslationKey="interventions.medical_examinations.new.steps.employees_to_plan.filter.function"
                possibleFilterItems={possibleFunctions}
                actualFilterValue={formRenderProps.values.functionIds}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'functionIds',
                    newFilterValue,
                )}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="seat"
                labelTranslationKey="interventions.medical_examinations.new.steps.employees_to_plan.filter.seat"
                possibleFilterItems={possibleSeats}
                actualFilterValue={formRenderProps.values.seatCodes}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'seatCodes',
                    newFilterValue,
                )}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="seat"
                labelTranslationKey="interventions.medical_examinations.new.steps.employees_to_plan.filter.reason"
                possibleFilterItems={possibleReasons}
                actualFilterValue={formRenderProps.values.examinationReasons}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'examinationReasons',
                    newFilterValue,
                )}
            />

            <h6><Translate msg="interventions.medical_examinations.new.steps.employees_to_plan.filter.deadline" /></h6>
            <StartEndDateFilter
                formRenderProps={formRenderProps}
                translationKeyPrefix="interventions.medical_examinations.new.steps.employees_to_plan.filter"
                hideTitle={true}
            />
        </div>
    );
}

export function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.medical_examinations.new.steps.employees_to_plan.active_filter',
        groupConfig: {
            filterKeys: ['startDate', 'endDate'],
            translationKeySuffix: 'deadline',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            search: {
                show: true,
            },
            startDate: {
                show: true,
                defaultValue: DEADLINE_FILTER.FROM_DEFAULT,
            },
            endDate: {
                show: true,
                defaultValue: DEADLINE_FILTER.TO_DEFAULT,
            },
            functionIds: {
                show: true,
                translationKeySuffixOverride: 'function',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'function',
                        searchColumnName: 'functionId',
                    },
                },
            },
            examinationReasons: {
                show: true,
                translationKeySuffixOverride: 'reason',
                multiple: {
                    enable: true,
                },
            },
            seatCodes: {
                show: true,
                translationKeySuffixOverride: 'seat',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'seat',
                        searchColumnName: 'seatCode',
                    },
                },
            },
        },
    });
}

function unsetResetDataEntityFlag(routePayload: IPlanMedicalExaminationWizardPayload) {
    return {
        ...routePayload,
        resetDataEntity: false,
    };
}
