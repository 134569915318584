import React, { Component } from 'react';
import { connect } from '../../../../index';
import './onboarding-wizard-employees.scss';
import classNames from 'classnames';
import { IOnboardingWizardEntity, IOnboardingWizardPayload } from '../../../../../models/onboarding/wizard';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import {
    getOnboardingWizardEntity,
    getConfirmOnboardingWizardAsyncInfo,
} from '../../../../../redux/onboarding/selectors';
import PageHeader from '../../../../appShell/PageHeader';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';
import MasterWithDetail from '../../../../common/widget/MasterWithDetail';
import ROUTE_KEYS from '../../../../../routeKeys';
import { getEmployeesAsyncInfo, getEmployees } from '../../../../../redux/employee/employees/selectors';
import { IEmployee, IEmployeeDetails } from '../../../../../models/admin/employee';
import { ListColumns, SortType, ListItem } from '../../../../../models/general/list';
import clone from 'ramda/src/clone';
import {
    IRenderMasterContentProps, IRenderDetailContentProps,
    IRenderDetailOverlayProps, IRenderDetailHeaderProps, IRenderMasterActionsProps,
} from '../../../../common/widget/MasterWithDetail/typings';
import { EmployeeList } from '../../../../administration/Employees';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import formatNationalRegisterNumber from '../../../../../utils/formatting/formatNationalRegisterNumber';
import {
    getSelectedEmployeeAsyncInfo,
    getSelectedEmployee,
    getJobStudentRequestFailedWarning,
} from '../../../../../redux/employee/info/selectors';
import {
    EmployeeDetailsContent, EmployeeDetailsHeader,
    EmployeeDetailsOverlay,
} from '../../../../administration/Employees/EmployeeDetails';
import { EmployeeDetailsOverlayType } from '../../../../administration/Employees/EmployeeDetails/common';
import NationalRegisterNumber from './AddEmployee/NationalRegisterNumber';
import PersonalData from './AddEmployee/PersonalData';
import { getRoutePayload } from '../../../../../redux/location/selectors';
import { confirmOnboardingWizardActions } from '../../../../../redux/onboarding/actions';
import Loader from '../../../../common/waiting/Loader';
import { IAsyncFieldInfo } from '../../../../../models/general/redux';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import ErrorDialog from '../../../../common/modals/ErrorDialog';
import { setJobStudentRequestFailedWarningActions } from '../../../../../redux/employee/info/actions';

interface IPrivateProps {
    wizardEntity: Partial<IOnboardingWizardEntity>;
    finishOnboardingWizard: () => void;
    clearJobStudenRequestFailedWarning: () => void;
    currentRoutePayload: IOnboardingWizardPayload;
    confirmWizardAsyncInfo: IAsyncFieldInfo;
    showJobStudentRequestFailedWarning: boolean;
}

interface IComponentState {
    isNationalRegisterNumberDialogOpen: boolean;
    isPersonalDataDialogOpen: boolean;
}

interface IColumnNames {
    name: string;
    nationalRegisterNumber: string;
    functionDescription: string;
    seat: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    name: {
        label: <Translate msg="administration.employees.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    nationalRegisterNumber: {
        label: <Translate msg="administration.employees.columns.national_register_number" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    functionDescription: {
        label: <Translate msg="administration.employees.columns.function" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
    seat: {
        label: <Translate msg="administration.employees.columns.seat" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
};

const CLASS_NAME = 'OnboardingEmployees';
const BASE_NAME = 'onboarding-employees';
const TRANSLATION_PREFIX = 'onboarding.wizard.steps.employees';

class Employees extends Component<IStepperStepRenderProps & IPrivateProps, IComponentState> {
    private columns = clone(COLUMNS);

    constructor(props: IStepperStepRenderProps & IPrivateProps) {
        super(props);

        this.state = {
            isNationalRegisterNumberDialogOpen: false,
            isPersonalDataDialogOpen: false,
        };

        this.toggleNationalRegisterNumberDialog = this.toggleNationalRegisterNumberDialog.bind(this);
        this.togglePersonalDataDialog = this.togglePersonalDataDialog.bind(this);
        this.showPersonalDataDialog = this.showPersonalDataDialog.bind(this);
        this.renderJobStudentRequestFailedWarningDialog = this.renderJobStudentRequestFailedWarningDialog.bind(this);
        this.closeJobStudentRequestFailedDialog = this.closeJobStudentRequestFailedDialog.bind(this);
    }

    public render() {
        const { isNationalRegisterNumberDialogOpen, isPersonalDataDialogOpen } = this.state;
        const {
            renderStepButtons,
            finishOnboardingWizard: navigateToFinishOnboardingStep,
            currentRoutePayload,
            confirmWizardAsyncInfo,
            showJobStudentRequestFailedWarning,
        } = this.props;

        return (
            <div
                className={classNames(
                    'container',
                    CLASS_NAME,
                )}
            >
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    text={`${TRANSLATION_PREFIX}.text`}
                    textPlaceholders={{
                        button: this.renderTextButton({}),
                    }}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <Loader show={confirmWizardAsyncInfo.status} />
                    {showJobStudentRequestFailedWarning && this.renderJobStudentRequestFailedWarningDialog()}
                    {confirmWizardAsyncInfo.error && <ErrorPlaceholder apiError={confirmWizardAsyncInfo.error} />}
                    <MasterWithDetail
                        baseName={BASE_NAME}
                        masterConfig={{
                            routeKey: ROUTE_KEYS.R_ONBOARDING_NEW,
                            asyncInfoSelector: getEmployeesAsyncInfo,
                            routePayload: currentRoutePayload,
                            dataSelector: getEmployees,
                            transformData: mapEmployeesToListItems,
                            renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[]>) => (
                                <EmployeeList {...renderProps} columns={this.columns} />
                            ),
                        }}
                        detailConfig={{
                            routeKey: ROUTE_KEYS.R_ONBOARDING_EMPLOYEE_DETAIL,
                            asyncInfoSelector: getSelectedEmployeeAsyncInfo,
                            dataSelector: getSelectedEmployee,
                            renderContent: (renderProps: IRenderDetailContentProps<IEmployee & IEmployeeDetails>) =>
                                <EmployeeDetailsContent
                                    {...renderProps}
                                />,
                            renderHeader: (renderProps: IRenderDetailHeaderProps<IEmployeeDetails>) =>
                                <EmployeeDetailsHeader {...renderProps} />,
                            // eslint-disable-next-line max-len
                            renderOverlay: ({ overlayType, closeOverlay, onSave }: IRenderDetailOverlayProps<EmployeeDetailsOverlayType>) =>
                                <EmployeeDetailsOverlay
                                    overlayType={overlayType}
                                    closeOverlay={closeOverlay}
                                    isOnboardingWizardRoute={true}
                                    onSave={onSave}
                                />,
                        }}
                        footerConfig={{
                            renderActionsLeft: (renderProps: IRenderMasterActionsProps) =>
                                this.renderTextButton({ inFooter: true }),
                            renderActionsRight: () =>
                                renderStepButtons({
                                    nextButton: {
                                        onClick: navigateToFinishOnboardingStep,
                                    },
                                }),
                        }}
                    />
                    <NationalRegisterNumber
                        show={isNationalRegisterNumberDialogOpen}
                        onCloseIntent={this.toggleNationalRegisterNumberDialog}
                        onSuccess={this.showPersonalDataDialog}
                    />
                    <PersonalData
                        show={isPersonalDataDialogOpen}
                        onCloseIntent={this.togglePersonalDataDialog}
                    />
                </div>
            </div>
        );
    }

    private renderTextButton({ inFooter }: { inFooter?: boolean }) {
        return (
            <a
                id="onboarding-wizard-add-employee-header-button"
                className={`${CLASS_NAME}__add-employee`}
                onClick={this.toggleNationalRegisterNumberDialog}
            >
                <Icon typeName="plus-circle" />
                <Translate
                    msg={
                        !inFooter ? `${TRANSLATION_PREFIX}.text_button` : `${TRANSLATION_PREFIX}.text_button_footer`}
                />
            </a>
        );
    }

    private toggleNationalRegisterNumberDialog() {
        this.setState({ isNationalRegisterNumberDialogOpen: !this.state.isNationalRegisterNumberDialogOpen });
    }

    private showPersonalDataDialog() {
        this.setState({
            isNationalRegisterNumberDialogOpen: false,
            isPersonalDataDialogOpen: true,
        });
    }

    private togglePersonalDataDialog(restart: boolean) {
        this.setState({
            isPersonalDataDialogOpen: !this.state.isPersonalDataDialogOpen,
            isNationalRegisterNumberDialogOpen: restart,
        });
    }

    private closeJobStudentRequestFailedDialog() {
        this.props.clearJobStudenRequestFailedWarning();
    }

    private renderJobStudentRequestFailedWarningDialog() {
        return (
            <ErrorDialog
                showOverride={true}
                titleTranslationKey={`${TRANSLATION_PREFIX}.job_student_request_failed_dialog.title`}
                infoTranslationKey={`${TRANSLATION_PREFIX}.job_student_request_failed_dialog.text`}
                onCloseDialog={this.closeJobStudentRequestFailedDialog}
                hideRealErrorMessage={true}
                hideCloseIcon={true}
            />
        );
    }
}

function mapEmployeesToListItems(employees: IEmployee[]): ListItem<IColumnNames>[] {
    return employees.map((employee) => {
        return {
            id: employee.id,
            columns: {
                name: formatPersonName(employee),
                nationalRegisterNumber: formatNationalRegisterNumber(employee.nationalRegisterNumber),
                functionDescription: employee.function.description,
                seat: employee.company.name,
            },
        };
    });
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity = getOnboardingWizardEntity(state);

        return {
            wizardEntity,
            currentRoutePayload: getRoutePayload<IOnboardingWizardPayload>(state),
            confirmWizardAsyncInfo: getConfirmOnboardingWizardAsyncInfo(state),
            showJobStudentRequestFailedWarning: getJobStudentRequestFailedWarning(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            finishOnboardingWizard: () => {
                dispatch(confirmOnboardingWizardActions.trigger({}));
            },
            clearJobStudenRequestFailedWarning: () => {
                dispatch(setJobStudentRequestFailedWarningActions.trigger({ showWarning: false }));
            },
        };
    },
})(Employees);
