import React from 'react';
import {
    ICalendarEvent, CalendarEventType, CalendarEventDataType,
    TPlannedMedicalExaminationCalendarEvent,
    TExecutedMedicalExaminationCalendarEvent,
    TExecutedCompanyVisitCalendarEvent,
    TPlannedCompanyVisitCalendarEvent,
} from '../../../../../models/ui/calendar';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../../config/calendar.config';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { ITranslator } from '../../../../../models/general/i18n';
import { IExecutedIntervention, IPlannedIntervention } from '../../../../../models/interventions/interventions';
import { IPlannedMedicalExamination } from '../../../../../models/interventions/medicalExaminations';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';

interface IContextProps {
    translator: ITranslator;
}

interface IEventTitleProps {
    event: ICalendarEvent;
    titleType: 'calendar-event' | 'list-event' | 'appointment-detail';
}

function EventTitleComp(props: IEventTitleProps & IContextProps) {
    return (
        <>
            {getEventTitle(props)}
        </>
    );
}

export default function EventTitle(props: IEventTitleProps) {
    return (
        <TranslatorContext.Consumer>
            {({ translator }) => <EventTitleComp {...props} translator={translator} />}
        </TranslatorContext.Consumer>
    );
}

export function getEventTitle(props: IEventTitleProps & IContextProps): string {
    if (props.event.type === CalendarEventType.CompanyVisit) {
        const translationKey = props.titleType === 'list-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.companyVisit.listEventTranslationKey
            : props.titleType === 'calendar-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.companyVisit.calendarEventTranslationKey
            : CALENDAR_EVENT_TYPES_CONFIG.companyVisit.appointmentDetailTitleTranslationKey;

        const data = (props.event as TPlannedCompanyVisitCalendarEvent).data;
        return props.translator({
            msg: translationKey,
            placeholders: {
                name: formatName(props.event),
                seat: data.company.name,
            },
        });
    }
    if (props.event.type === CalendarEventType.Intervention) {
        const translationKey = props.titleType === 'calendar-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.intervention.calendarEventTranslationKey
            : CALENDAR_EVENT_TYPES_CONFIG.intervention.appointmentDetailTitleTranslationKey;

        const title = props.event.dataType === CalendarEventDataType.PlannedIntervention
            ? (props.event.data as IPlannedIntervention).visitKind
            : (props.event.data as IExecutedIntervention).description;

        return props.translator({
            msg: translationKey,
            placeholders: { title },
        });
    }
    if (
        props.event.type === CalendarEventType.MedicalExamination ||
        props.event.type === CalendarEventType.MedicalExaminationInCompany
    ) {
        const translationKey = props.titleType === 'calendar-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.medicalExamination.calendarEventTranslationKey
            : CALENDAR_EVENT_TYPES_CONFIG.medicalExamination.appointmentDetailTitleTranslationKey;
        return props.translator({
            msg: translationKey,
            placeholders: {
                name: formatName(props.event),
            },
        });
    }
    if (props.event.type === CalendarEventType.Course) {
        const translationKey = props.titleType === 'calendar-event'
            ? CALENDAR_EVENT_TYPES_CONFIG.course.calendarEventTranslationKey
            : CALENDAR_EVENT_TYPES_CONFIG.course.appointmentDetailTitleTranslationKey;
        return props.translator({
            msg: translationKey,
            placeholders: {
                title: props.event.title,
            },
        });
    }
    if (props.event.type === CalendarEventType.FreeTimeslot) {
        return props.translator({
            msg: CALENDAR_EVENT_TYPES_CONFIG.freeTimeslot.calendarEventTranslationKey,
        });
    }
    return null;
}

function formatName(event: ICalendarEvent): string {
    if (Array.isArray(event.data) && event.dataType === CalendarEventDataType.PlannedMedicalExamination) {
        return (event.data as IPlannedMedicalExamination[])
            .map((data) => formatPersonName(data))
            .join(', ');
    }
    if (event.dataType === CalendarEventDataType.PlannedMedicalExamination) {
        const data = (event as TPlannedMedicalExaminationCalendarEvent).data;
        return formatPersonName(data.employee);
    }
    if (event.dataType === CalendarEventDataType.ExecutedMedicalExamination) {
        const data = (event as TExecutedMedicalExaminationCalendarEvent).data;
        return formatPersonName(data.employee);
    }
    if (event.dataType === CalendarEventDataType.ExecutedCompanyVisit) {
        const data = (event as TExecutedCompanyVisitCalendarEvent).data;
        return formatPersonName(data.employee);
    }
    if (event.dataType === CalendarEventDataType.PlannedCompanyVisit) {
        const data = (event as TPlannedCompanyVisitCalendarEvent).data;
        return formatPersonName(data.employee);
    }
    return event.type;
}
