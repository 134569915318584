import { ONE_SECOND } from '../utils/core/time/periodsInMillis';

export enum API_URL {
    BASE = '/rest',
    CMS = '/api',
    BFF = '/bff',
}

export const DEFAULT_TIMEOUT_IN_MILLIS = 25 * ONE_SECOND;

export const HEADER_NAME = {
    CONTENT_DISPOSITION: 'content-disposition',
    CONTENT_TYPE: 'Content-Type',
    CSRF_TOKEN: 'x-csrf-token',
    LOCALE: 'x-locale',
    APP_VERSION: 'x-webapp-version-number',
    ACCEPT_LANGUAGE: 'Accept-Language',
    AUTHORIZATION: 'Authorization',
    CORRELATION_ID: 'X-Correlation-ID',
};

export const DEFAULT_HEADERS = {
    [HEADER_NAME.CONTENT_TYPE]: 'application/json',
};

export enum REQUEST_METHOD {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}
