import React, { PureComponent } from 'react';
import clone from 'ramda/src/clone';
import './functions.scss';
import { connect } from '../../index';
import Translate from '../../common/Translate';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../models/general/list';
import ROUTE_KEYS from '../../../routeKeys';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, ITransformToActiveFiltersProps,
    IClientSideFilterOfListDataProps,
    IRenderDetailHeaderProps, IRenderDetailContentProps,
    IRenderSearchContentProps, IRenderFilterContentProps, IDetailConfig, IRenderDetailFooterProps,
} from '../../common/widget/MasterWithDetail/typings';
import {
    ICompanyFunction,
    ICompanyFunctionsFilter,
    IFetchCompanyFunctionsPayload,
} from '../../../models/admin/companyFunctions';
import ListWithSorting from '../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import PageHeader from '../../appShell/PageHeader/index';
import {
    getSelectedEmployee, getSelectedEmployeeId,
    getSelectedEmployeeAsyncInfo,
} from '../../../redux/employee/info/selectors';
import {
    getSelectedCompanyFunctionId, getSelectedCompanyFunction,
    getFetchCompanyFunctionsAsyncInfo,
    getCompanyFunctions,
    getAddedCompanyFunction,
} from '../../../redux/company/functions/selectors';
import { CompanyFunctionDetailHeader, CompanyFunctionDetailContent } from './detail';
import {
    EmployeeDetailsContent,
    EmployeeDetailsHeader,
    EmployeeDetailsOverlay,
} from '../Employees/EmployeeDetails';
import { IEmployee, IEmployeeDetails } from '../../../models/admin/employee';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../common/input/TextInput';
import Checkbox from '../../common/input/Checkbox';
import { DEFAULT_COMPANY_FUNCTIONS_FILTERS } from '../../../api/admin/functions.api';
import Dialog from '../../common/modals/Dialog';
import AddFunction from '../Employees/shared/AddFunction';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import { getSelectedSeatCompanyCode, getSelectedCompanySeat } from '../../../redux/company/selected/selectors';
import { getQueryParams, getRouteKey } from '../../../redux/location/selectors';
import { navigateTo } from '../../../redux/location/actions';
import { ADMINISTRATION_ITEMS } from '../../../config/navigation/administration.config';
import { Permission } from '../../../models/auth/authorisation';
import {
    getSelectedPlannedMedicalExaminationAsyncInfo,
    getSelectedExecutedMedicalExaminationAsyncInfo,
    getSelectedPlannedMedicalExamination,
    getSelectedExecutedMedicalExamination,
} from '../../../redux/medicalExamination/selectors';
import {
    IExecutedMedicalExamination,
    IPlannedMedicalExamination,
} from '../../../models/interventions/medicalExaminations';
import {
    DetailHeader as DetailHeaderExecuted,
    DetailContent as DetailContentExecuted,
} from '../../interventions/MedicalExaminations/Executed/detail';
import {
    DetailHeader as DetailHeaderPlanned,
    DetailContent as DetailContentPlanned,
} from '../../interventions/MedicalExaminations/Planned/detail';
import { AsyncStatus } from '../../../models/general/redux';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { EmployeeDetailsOverlayType } from '../Employees/EmployeeDetails/common';
import {
    getCoursesPlannedAsyncInfo,
    getSelectedPlannedCourse,
    getCoursesFollowedAsyncInfo,
    getSelectedFollowedCourse,
} from '../../../redux/documentCenter/courses/selectors';
import { ICourse } from '../../../models/documentCenter/courses';
import DetailContentCoursesPlanned from '../../documentCenter/Courses/Planned/detail/content';
import DetailFooterCoursesPlanned from '../../documentCenter/Courses/Planned/detail/footer';
import DetailHeaderCourses from '../../documentCenter/Courses/shared/header';
import DetailContentCoursesFollowed from '../../documentCenter/Courses/Followed/detail/content';
import DetailFooterCoursesFollowed from '../../documentCenter/Courses/Followed/detail/footer';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const BASE_NAME = 'functions';
const CLASS_NAME = 'FunctionOverview';
const PLANNED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL,
    asyncInfoSelector: getSelectedPlannedMedicalExaminationAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: getSelectedPlannedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IPlannedMedicalExamination>) =>
        <DetailHeaderPlanned {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IPlannedMedicalExamination>) =>
        <DetailContentPlanned {...renderProps} />,
};
const EXECUTED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL,
    asyncInfoSelector: getSelectedExecutedMedicalExaminationAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: getSelectedExecutedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IExecutedMedicalExamination>) =>
        <DetailHeaderExecuted {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IExecutedMedicalExamination>) =>
        <DetailContentExecuted {...renderProps} />,
};

const PLANNED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_COURSES_PLANNED_DETAIL,
    asyncInfoSelector: getCoursesPlannedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: getSelectedPlannedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) =>
        <DetailContentCoursesPlanned {...renderProps} />,
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesPlanned {...renderProps} />,
};

const FOLLOWED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_COURSES_FOLLOWED_DETAIL,
    asyncInfoSelector: getCoursesFollowedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: getSelectedFollowedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) => (
        <DetailContentCoursesFollowed
            {...renderProps}
            followedCoursesAttendantRouteKey={ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL_ATTENDANT}
        />),
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesFollowed {...renderProps} />,
};

interface IColumnNames {
    function: string;
    employeesAssigned: number;
}

const COLUMNS: ListColumns<IColumnNames> = {
    function: {
        label: <Translate msg="administration.company_functions.columns.function" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 85,
    },
    employeesAssigned: {
        label: <Translate msg="administration.company_functions.columns.employees_assigned" />,
        sortable: true,
        sortType: SortType.Number,
        percentWidth: 15,
        align: 'right',
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'function',
    sortOrder: SortOrder.Ascending,
};

interface IFilterValues {
    search: string;
    showUnmanned: 'true' | 'false';
}

interface IPrivateProps {
    isAddFunctionRoute: boolean;
    selectedCompanyFunctionId: string;
    navigateToListAndForceRefreshFunctions: () => void;
    navigateToRouteWithCurrentQueryParams: (routeKey: ROUTE_KEYS) => void;
    addedFunctionId: number;
}

class Functions extends PureComponent<IPrivateProps> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            isAddFunctionDialogOpen: this.props.isAddFunctionRoute,
        };

        this.onOpenAddFunction = this.onOpenAddFunction.bind(this);
        this.onCloseAddFunction = this.onCloseAddFunction.bind(this);
        this.onFunctionAdded = this.onFunctionAdded.bind(this);
    }

    public render() {
        const { isAddFunctionRoute, selectedCompanyFunctionId } = this.props;

        const AddFunctionButton = (
            <ShowIfAllowed
                requiredAccessLevels={{ employee: 'W', extraPermissions: [Permission.CAN_CREATE_TEMP_FUNC] }}
            >
                <Button
                    id="page-header-calendar-button"
                    typeName="secondary"
                    onClick={this.onOpenAddFunction}
                >
                    <Icon typeName="plus-circle" />
                    <span><Translate msg="administration.company_functions.add_button" /></span>
                </Button>
            </ShowIfAllowed>
        );

        const CURRENT_ITEM = ADMINISTRATION_ITEMS.find((item) => {
            return item.linkTo === ROUTE_KEYS.R_COMPANY_FUNCTIONS;
        });

        const lazyLoadSvgId = CURRENT_ITEM
            ? CURRENT_ITEM.detailLazyLoadSvgId || CURRENT_ITEM.lazyLoadSvgId
            : undefined;

        return (
            <div className={`${CLASS_NAME}`}>
                <PageHeader
                    breadcrumbs={true}
                    title="administration.company_functions.title"
                    text="administration.company_functions.text"
                    isTextRaw={true}
                    lazyLoadSvg={lazyLoadSvgId && {
                        id: lazyLoadSvgId,
                        group: SVG_GROUP_NAME.ADMINISTRATION,
                    }}
                    button={AddFunctionButton}
                    type="grey"
                />
                <div className={`${CLASS_NAME}__content container`}>
                    <div className={`${CLASS_NAME}__list`}>
                        <MasterWithDetail
                            baseName={BASE_NAME}
                            getDefaultQueryParams={getDefaultQueryParams}
                            masterConfig={{
                                routeKey: ROUTE_KEYS.R_COMPANY_FUNCTIONS,
                                asyncInfoSelector: getFetchCompanyFunctionsAsyncInfo,
                                dataSelector: getCompanyFunctions,
                                transformData: mapCompanyFunctionsToListItems,
                                transformFilterValuesToActiveFilters:
                                    companyFunctionTransformFilterValuesToActiveFilters,
                                renderContent: (
                                    renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                    <FunctionList {...renderProps} {...this.props} />,
                                clientSideSearchOfListData: {
                                    searchFilterName: 'search',
                                    columnsConfig: COLUMNS,
                                },
                                clientSideFilterOfListData: companyFunctionClientSideFilterOfListData,
                            }}
                            headerConfig={{
                                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                                    <CompanyFunctionSearchContent {...renderProps} />,
                                renderFilterContent:
                                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                        <CompanyFunctionFilterContent {...renderProps} />,
                                exportButton: {
                                    baseFilename: 'functions',
                                },
                            }}
                            detailConfig={{
                                levels: [
                                    {
                                        level: 1,
                                        details: [{
                                            routeKey: ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL,
                                            asyncInfoSelector: getFetchCompanyFunctionsAsyncInfo,
                                            idSelector: getSelectedCompanyFunctionId,
                                            idRouteParamName: 'functionId',
                                            dataSelector: getSelectedCompanyFunction,
                                            renderHeader: (renderProps: IRenderDetailHeaderProps<ICompanyFunction>) =>
                                                <CompanyFunctionDetailHeader {...renderProps} />,
                                            renderContent: (
                                                renderProps: IRenderDetailContentProps<ICompanyFunction>,
                                            ) =>
                                                <CompanyFunctionDetailContent
                                                    {...renderProps}
                                                    // eslint-disable-next-line max-len
                                                    employeeDetailRouteKey={ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL}
                                                />,
                                        }],
                                    },
                                    {
                                        level: 2,
                                        details: [{
                                            routeKey: ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL,
                                            asyncInfoSelector: getSelectedEmployeeAsyncInfo,
                                            idSelector: getSelectedEmployeeId,
                                            dataSelector: getSelectedEmployee,
                                            renderHeader: (
                                                renderProps: IRenderDetailHeaderProps<IEmployee & IEmployeeDetails>,
                                            ) =>
                                                <EmployeeDetailsHeader {...renderProps} />,
                                            renderContent: (
                                                renderProps: IRenderDetailContentProps<IEmployee & IEmployeeDetails>,
                                            ) => selectedCompanyFunctionId &&
                                                <EmployeeDetailsContent
                                                    {...renderProps}
                                                    // eslint-disable-next-line max-len
                                                    plannedMedicalExaminationRouteKey={ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL}
                                                    // eslint-disable-next-line max-len
                                                    executedMedicalExaminationRouteKey={ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL}
                                                    // eslint-disable-next-line max-len
                                                    followedCoursesRouteKey={ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_COURSES_FOLLOWED_DETAIL}
                                                    // eslint-disable-next-line max-len
                                                    plannedCoursesRouteKey={ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_COURSES_PLANNED_DETAIL}
                                                    extraRoutePayload={{
                                                        functionId: selectedCompanyFunctionId,
                                                    }}
                                                />,
                                            renderOverlay: ({
                                                overlayType, closeOverlay, onSave,
                                            }) =>
                                                <EmployeeDetailsOverlay
                                                    overlayType={overlayType as EmployeeDetailsOverlayType}
                                                    closeOverlay={closeOverlay}
                                                    onSave={onSave}
                                                />,
                                        }],
                                    },
                                    {
                                        level: 3,
                                        details: [
                                            PLANNED_EXAMINATION_DETAIL_CONFIG,
                                            EXECUTED_EXAMINATION_DETAIL_CONFIG,
                                            PLANNED_COURSES_DETAIL_CONFIG,
                                            FOLLOWED_COURSES_DETAIL_CONFIG,
                                        ],
                                    },
                                ],
                            }}
                        />
                    </div>
                </div>
                <Dialog
                    show={isAddFunctionRoute}
                    onCloseIntent={this.onCloseAddFunction}
                >
                    <AddFunction
                        onClose={this.onCloseAddFunction}
                        onSuccess={this.onFunctionAdded}
                    />
                </Dialog>
            </div>
        );
    }

    private onOpenAddFunction() {
        this.props.navigateToRouteWithCurrentQueryParams(ROUTE_KEYS.R_COMPANY_FUNCTIONS_ADD);
    }

    private onCloseAddFunction() {
        this.props.navigateToRouteWithCurrentQueryParams(ROUTE_KEYS.R_COMPANY_FUNCTIONS);
    }

    private onFunctionAdded() {
        this.props.navigateToListAndForceRefreshFunctions();
    }
}

function getDefaultQueryParams() {
    return DEFAULT_COMPANY_FUNCTIONS_FILTERS;
}

class FunctionList extends PureComponent<IPrivateProps & IRenderMasterContentProps<ListItem<IColumnNames>[]>> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: IPrivateProps & IRenderMasterContentProps<ListItem<IColumnNames>[]>) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            onItemSelected,
            selectedItemId,
            addedFunctionId,
        } = this.props;

        const idToScrollIntoView = masterAsyncInfo.status === AsyncStatus.Success ? addedFunctionId : 0;

        return (
            <ListWithSorting
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemIds={selectedItemId ? [selectedItemId] : []}
                onItemRowClicked={onItemSelected}
                initialSort={INITIAL_SORT}
                maxNrOfRecordsToShow={undefined}
                idToScrollIntoView={idToScrollIntoView}
            />
        );
    }

}

function mapCompanyFunctionsToListItems(companyFunctions: ICompanyFunction[]): ListItem<IColumnNames>[] {
    return companyFunctions.map((companyFunction) => {
        return {
            id: companyFunction.id,
            columns: {
                function: companyFunction.description,
                employeesAssigned: companyFunction.employeesAssigned,
            },
        };
    });
}

export function companyFunctionTransformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'administration.company_functions.filter',
        filters: {
            showUnmanned: {
                show: true,
                type: 'boolean',
            },
            search: {
                show: true,
            },
        },
    });
}

export function CompanyFunctionSearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('administration.company_functions.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="administration.company_functions.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

export function companyFunctionClientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues } = filterProps;

    if (filterValues.showUnmanned === 'true') {
        return listItems;
    }

    return listItems.filter((listItem) => listItem.columns.employeesAssigned > 0);
}

export function CompanyFunctionFilterContent(
    renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        formRenderProps,
    } = renderProps;

    return (
        <div>
            <div>
                <FloatableTextInputWrapper>
                    <Checkbox
                        key="filter-unmanned"
                        name="showUnmanned"
                        toggleButton
                        checked={formRenderProps.values.showUnmanned === 'true'}
                        onChange={(e) => formRenderProps.setFieldValue(
                            'showUnmanned',
                            e.target.checked.toString(),
                        )}
                    >
                        <label htmlFor="filter-unmanned">
                            <Translate msg="administration.company_functions.filter.show_unmanned" />
                        </label>
                    </Checkbox>
                </FloatableTextInputWrapper>
            </div>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            isAddFunctionRoute: getRouteKey(state) === ROUTE_KEYS.R_COMPANY_FUNCTIONS_ADD,
            selectedCompanyFunctionId: getSelectedCompanyFunctionId(state),
            addedFunctionId: getAddedCompanyFunction(state).id,
        };
    },
    dispatchProps: (dispatch, getState) => ({
        navigateToRouteWithCurrentQueryParams: (routeKey: ROUTE_KEYS) => {
            const currentQuery = getQueryParams<ICompanyFunctionsFilter>(getState());

            dispatch(navigateTo(routeKey, {}, currentQuery));
        },
        navigateToListAndForceRefreshFunctions: () => {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
            const currentQuery = getQueryParams<ICompanyFunctionsFilter>(getState());

            const payload: IFetchCompanyFunctionsPayload = {
                companyCode,
                showFullFamily,
                keyword: '',
                forceRefresh: true,
            };

            dispatch(
                navigateTo(
                    ROUTE_KEYS.R_COMPANY_FUNCTIONS,
                    payload,
                    {
                        ...currentQuery && { showUnmanned: 'true' },
                    },
                ));
        },
    }),
})(Functions);
