import { HEADER_NAME } from '../../config/api.config';

export default function getFilenameFromHeaders(headers, defaultFilename = 'document.pdf') {
    if (headers && headers[HEADER_NAME.CONTENT_DISPOSITION]) {
        let matches = /"([^"]*)"/.exec(headers[HEADER_NAME.CONTENT_DISPOSITION]);
        if (matches != null && matches[1]) {
            return matches[1];
        }

        matches = /filename=([^"]*)/.exec(headers[HEADER_NAME.CONTENT_DISPOSITION]);
        return (matches != null && matches[1] ? matches[1] : defaultFilename);
    }

    return defaultFilename;
}
