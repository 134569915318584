import React, { ReactNode } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import Typeahead, { TTypeaheadData } from '../../../../common/input/Typeahead';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { ICostCenter } from '../../../../../models/admin/employee';
import {
    getCompanyCostCenters,
    getFetchCompanyCostCentersAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import { getDate } from '../../../../../utils/core/date/getSpecificDate';
import { isInThePast } from '../../../../../utils/core/date/isInThePast';

interface IPrivateProps {
    costCenters: ICostCenter[];
    costCentersTypeahead: TTypeaheadData;
    translator: ITranslator;
}

interface ICostCenterTypeaheadProps {
    id: string;
    value: string;
    name: string;
    onItemSelected: (name: string, costCenter: ICostCenter) => void;
    isInvalid?: boolean;
    children?: ReactNode;
    placeholderTranslationKey?: string;
    disabled?: boolean;
}

function CostCenterTypeahead(props: ICostCenterTypeaheadProps & IPrivateProps) {
    const {
        children, id, value,
        name, isInvalid, onItemSelected,
        costCenters, costCentersTypeahead,
        placeholderTranslationKey, disabled, translator,
    } = props;

    function onItemSelectedHandler(name: string) {
        const selectedCostCenter = costCenters.find((item) => item.name === name);

        onItemSelected(name, selectedCostCenter || null);
    }

    return (
        <Typeahead
            id={id}
            value={value}
            name={name}
            onItemSelected={onItemSelectedHandler}
            isInvalid={isInvalid}
            data={costCentersTypeahead}
            placeholder={placeholderTranslationKey ? translator(placeholderTranslationKey) : null}
            disabled={disabled}
            asyncInfoSelector={getFetchCompanyCostCentersAsyncInfo}
        >
            {children}
        </Typeahead>
    );
}

export default connect<IPrivateProps, ICostCenterTypeaheadProps>({
    stateProps: (state) => {
        const costCenters = getCompanyCostCenters(state);
        const activeCostCenters = costCenters.filter((item) => {
            if (!!item.dateInactive && isInThePast(getDate(item.dateInactive))){
                return null;
            } return item;
        });

        return {
            costCenters: activeCostCenters,
            costCentersTypeahead: mapCostCentersForTypeahead(activeCostCenters),
            translator: getTranslatorDeprecated(state),
        };
    },
})(CostCenterTypeahead);

function mapCostCentersForTypeahead(costCenters: ICostCenter[]): TTypeaheadData {
    return Array.isArray(costCenters) ? costCenters.map((item) => ({
        value: item.name,
        label: item.name,
    })) : [];
}
