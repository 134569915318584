import { ITimeCell } from '../../../models/interventions/timeslots';
import { minutesOffsetFromDate, getDate } from '../../core/date/getSpecificDate';
import { stringComparerAscending } from '../../list/comparerUtils';
import { getMinutesBetweenDates } from '../../core/date/getDifferenceBetweenDates';

export function getMinutesAvailableAfterTimeslot(timeCell: ITimeCell, timeCells: ITimeCell[]) {
    let freeMinutesAfterTimeslot = 0;

    const timeCellStart = getDate(timeCell.time);
    let latestEnd = minutesOffsetFromDate(timeCellStart, timeCell.duration).toDate();

    const timeCellsSortedByDate = timeCells.sort(sortTimeslotsByDate);
    const timeCellIndex = timeCellsSortedByDate.indexOf(timeCell);

    for (let i = timeCellIndex + 1; i < timeCellsSortedByDate.length; i = i + 1) {
        const otherTimeCell = timeCells[i];
        const otherStart = getDate(otherTimeCell.time);
        const otherEnd = minutesOffsetFromDate(otherStart, otherTimeCell.duration).toDate();

        if (latestEnd >= otherStart) {
            freeMinutesAfterTimeslot = getMinutesBetweenDates(otherEnd, timeCellStart) - timeCell.duration;

            latestEnd = otherEnd;
        }
    }

    return freeMinutesAfterTimeslot;
}

function sortTimeslotsByDate(a: ITimeCell, b: ITimeCell) {
    return stringComparerAscending(a.time, b.time);
}
