import React, { ReactNode } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import Typeahead, { TTypeaheadData } from '../../../../common/input/Typeahead';
import { ITranslator } from '../../../../../models/general/i18n';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import {
    getCompanyMedicalCenters,
    getFetchCompanyMedicalCentersAsyncInfo,
    getCompanyBufferzones,
} from '../../../../../redux/company/info/selectors';
import { IMedicalCenter } from '../../../../../models/admin/employee';
import { formatAddress } from '../../../../../utils/formatting/formatAddress';
import { createSelector } from 'reselect';
import { ICompanyBufferzone } from '../../../../../models/admin/company';
import { IN_COMPANY_MEDICAL_CENTER_ID } from '../../../../../config/planning.config';

interface IPrivateProps {
    medicalCenters: IMedicalCenter[];
    medicalCentersTypeahead: TTypeaheadData;
    translator: ITranslator;
    bufferzones: ICompanyBufferzone[];
}

interface IMedicalCenterTypeaheadProps {
    id: string;
    value?: number;
    name: string;
    onItemSelected: (medicalCenterId: number, medicalCenter: IMedicalCenter) => void;
    isInvalid?: boolean;
    children?: ReactNode;
    placeholderTranslationKey?: string;
    disabled?: boolean;
    disableReset?: boolean;
    includeBufferzones?: boolean;
    excludeInCompanyMedicalCenter?: boolean;
}

function MedicalCenterTypeahead(props: IMedicalCenterTypeaheadProps & IPrivateProps) {
    const {
        children, id, value, disableReset,
        name, isInvalid, onItemSelected,
        medicalCenters, medicalCentersTypeahead,
        placeholderTranslationKey, disabled, translator,
        bufferzones,
    } = props;

    function onItemSelectedHandler(medicalCenterId: number) {
        const selectedMedicalCenter = medicalCenters.find((item) => item.id === medicalCenterId);

        if (props.includeBufferzones) {
            const selectedCompanyBufferzone =
                bufferzones.find((item) => item.medicalCenter.id === medicalCenterId);
            const selectedBufferzoneMedicalCenter =
                selectedCompanyBufferzone && selectedCompanyBufferzone.medicalCenter;

            onItemSelected(
                medicalCenterId,
                selectedMedicalCenter || selectedBufferzoneMedicalCenter || null,
            );
        } else {
            onItemSelected(
                medicalCenterId,
                selectedMedicalCenter || null,
            );
        }
    }

    return (
        <Typeahead
            id={id}
            value={value}
            name={name}
            onItemSelected={onItemSelectedHandler}
            isInvalid={isInvalid}
            data={medicalCentersTypeahead}
            placeholder={placeholderTranslationKey ? translator(placeholderTranslationKey) : null}
            disabled={disabled}
            disableReset={disableReset}
            asyncInfoSelector={getFetchCompanyMedicalCentersAsyncInfo}
        >
            {children}
        </Typeahead>
    );
}

function makeMedicalCentersForTypeaheadMemoizedSelector({
    includeBufferzones,
    excludeInFirm,
}: {
    includeBufferzones: boolean,
    excludeInFirm: boolean,
}) {
    return createSelector(
        getCompanyMedicalCenters,
        getCompanyBufferzones,
        (medicalCenters, bufferzones) => {
            let medicalCentersToMap = [...medicalCenters];
            if (includeBufferzones) {
                bufferzones.forEach((bufferzone) => {
                    if (!medicalCentersToMap.find(
                        (medicalCenter) => (
                            bufferzone.medicalCenter.id === medicalCenter.id
                            || bufferzone.medicalCenter.code === medicalCenter.code
                        ))
                    ) {
                        medicalCentersToMap.push(bufferzone.medicalCenter);
                    }
                });
            }

            if (excludeInFirm) {
                medicalCentersToMap = filterInfirmMedicalCenter(medicalCentersToMap);
            }

            return mapMedicalCentersForTypeahead(medicalCentersToMap);
        },
    );
}

function filterInfirmMedicalCenter(medicalCenters: IMedicalCenter[]) {
    return medicalCenters.filter((item) => item.id !== IN_COMPANY_MEDICAL_CENTER_ID);
}

export default connect<IPrivateProps, IMedicalCenterTypeaheadProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const medicalCenters = getCompanyMedicalCenters(state);
            const typeaheadItems = makeMedicalCentersForTypeaheadMemoizedSelector({
                excludeInFirm: publicProps.excludeInCompanyMedicalCenter,
                includeBufferzones: publicProps.includeBufferzones,
            })(state);
            return {
                medicalCenters,
                bufferzones: getCompanyBufferzones(state),
                medicalCentersTypeahead: typeaheadItems,
                translator: getTranslatorDeprecated(state),
            };
        };
    },
})(MedicalCenterTypeahead);

function mapMedicalCentersForTypeahead(medicalCenters: IMedicalCenter[]): TTypeaheadData {
    return Array.isArray(medicalCenters) ? medicalCenters.map((item) => ({
        value: item.id,
        label: item.name,
        dropdownItemContent: item.name && (
            <>
                <strong>{item.name}</strong><br />
                {item.address && formatAddress(item.address)}
            </>
        ),
    })) : [];
}
