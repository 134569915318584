import React, { PureComponent } from 'react';
import './planned-examination-dialog-detail.scss';
import PersonalDetails from './PersonalDetails';
import RisksDetails from './RisksDetails';
import RemarksDetails from './RemarksDetails';
import * as employeeApi from '../../../../../../../../api/admin/employee.api';
import {
    IEmployeeDetails,
    IEmployeeRisksAndResearches,
} from '../../../../../../../../models/admin/employee';
import { IAsyncFetchState } from '../../../../../../../../models/general/redux';
import { IPlannedMedicalExamination } from '../../../../../../../../models/interventions/medicalExaminations';
import {
    getAsyncFetchStateInitial,
    getAsyncFetchStateBusy,
    getAsyncFetchStateSuccess,
    getAsyncFetchStateError,
} from '../../../../../../../../utils/api/async/asyncFetchState';

const CLASS_NAME = 'PlannedExaminationDialogDetail';

interface IPublicProps {
    plannedExamination: IPlannedMedicalExamination;
}

interface IComponentState {
    employeeDetails: IAsyncFetchState<IEmployeeDetails>;
    employeeRisksAndResearches: IAsyncFetchState<IEmployeeRisksAndResearches>;
}

export default class PlannedExaminationDialogDetail extends PureComponent<IPublicProps, IComponentState> {
    constructor(props: IPublicProps) {
        super(props);

        this.state = {
            employeeDetails: getAsyncFetchStateInitial(),
            employeeRisksAndResearches: getAsyncFetchStateInitial(),
        };
    }

    public componentDidMount() {
        this.fetchEmployeeDetails();
        this.fetchEmployeeRisksAndResearches();
    }

    public render() {
        const { plannedExamination } = this.props;
        const { employeeDetails, employeeRisksAndResearches } = this.state;

        return (
            <div className={CLASS_NAME}>
                <PersonalDetails
                    baseClassName={CLASS_NAME}
                    plannedExamination={plannedExamination}
                    employeeDetails={employeeDetails}
                />

                <RisksDetails
                    baseClassName={CLASS_NAME}
                    employeeRisksAndResearches={employeeRisksAndResearches}
                />

                <RemarksDetails
                    baseClassName={CLASS_NAME}
                    plannedExamination={plannedExamination}
                />
            </div>
        );
    }

    private async fetchEmployeeDetails() {
        const { plannedExamination } = this.props;

        try {
            this.setState({
                employeeDetails: getAsyncFetchStateBusy(),
            });

            const employeeDetailsData = await employeeApi.fetchEmployeeDetails(
                getEmployeeIdAsString(plannedExamination),
            );

            this.setState({
                employeeDetails: getAsyncFetchStateSuccess(employeeDetailsData),
            });
        } catch (error) {
            this.setState({
                employeeDetails: getAsyncFetchStateError(error),
            });
        }
    }

    private async fetchEmployeeRisksAndResearches() {
        const { plannedExamination } = this.props;

        try {
            this.setState({
                employeeRisksAndResearches: getAsyncFetchStateBusy(),
            });

            const employeeRisksAndResearchesData = await employeeApi.fetchEmployeeRisksAndResearches(
                getEmployeeIdAsString(plannedExamination),
            );

            this.setState({
                employeeRisksAndResearches: getAsyncFetchStateSuccess(employeeRisksAndResearchesData),
            });
        } catch (error) {
            this.setState({
                employeeRisksAndResearches: getAsyncFetchStateError(error),
            });
        }
    }
}

function getEmployeeIdAsString(plannedExamination: IPlannedMedicalExamination) {
    return plannedExamination.employee.id.toString();
}
