import {
    IFetchAppointmentsPayload,
    IFetchNextAppointmentPayload,
    INextAppointment,
    IQueryAppointmentsPayload,
    IFetchappointmentsResult,
} from '../../models/planning/appointments';
import { ICalendarEvent, CalendarEventType, CalendarEventDataType } from '../../models/ui/calendar';
import { get } from '../../utils/api/requestWrapper';
import { IExecutedCompanyVisit, IPlannedCompanyVisit } from '../../models/interventions/companyVisits';
import {
    IExecutedMedicalExamination,
    IPlannedMedicalExaminationAppointment,
} from '../../models/interventions/medicalExaminations';
import { getDate, minutesOffsetFromDate } from '../../utils/core/date/getSpecificDate';
import {
    DEFAULT_MEDICAL_EXAMINATION_DURATION,
    IN_COMPANY_MEDICAL_CENTER_ID,
} from '../../config/planning.config';
import { IExecutedIntervention, IPlannedIntervention } from '../../models/interventions/interventions';
import { IPlannedCourseAppointment, ICourse } from '../../models/documentCenter/courses';
import { ONE_MINUTE } from '../../utils/core/time/periodsInMillis';

interface IAppointmentsPerType {
    'executed-examination'?: IExecutedMedicalExamination[];
    'executed-intervention'?: IExecutedIntervention[];
    'planned-examination'?: IPlannedMedicalExaminationAppointment[];
    'planned-company-visit'?: IPlannedCompanyVisit[];
    'planned-intervention'?: IPlannedIntervention[];
    'planned-course'?: IPlannedCourseAppointment[];
    'executed-course'?: IPlannedCourseAppointment[];
}

const URL = {
    COMPANY_APPOINTMENTS: '/companies/{companyCode}/appointments',
    COMPANY_NEXT_APPOINTMENT: '/companies/{companyCode}/next-appointment',
    COMPANY_QUERY_APPOINTMENTS: '/companies/{companyCode}/appointments/{searchValue}',
};

export function fetchAppointments({
    companyCode, showFullFamily,
    dateFrom, dateUntil,
}: IFetchAppointmentsPayload) {
    return get<IFetchappointmentsResult>({
        url: URL.COMPANY_APPOINTMENTS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
            dateFrom,
            dateUntil,
        },
        mapResponse: (response) => {
            const events = mapAppointmentsResponse(response[0]);
            return {
                events,
                count: events.length || 0,
            };
        },
        timeoutInMillis: 1 * ONE_MINUTE,
    });
}

export function fetchNextAppointment({
    companyCode, showFullFamily,
}: IFetchNextAppointmentPayload) {
    return get<INextAppointment>({
        url: URL.COMPANY_NEXT_APPOINTMENT,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily,
        },
    });
}

export function queryAppointments({
    companyCode, showFullFamily,
    dateFrom, dateUntil, searchValue,
    maxResultsToReturn,
}: IQueryAppointmentsPayload) {
    return get<IFetchappointmentsResult>({
        url: URL.COMPANY_QUERY_APPOINTMENTS,
        pathParams: {
            companyCode,
            searchValue,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
            dateFrom,
            dateUntil,
            numRecords: maxResultsToReturn,
        },
        mapResponse: (response) => {
            const events = mapAppointmentsResponse(response.appointments);
            return {
                events,
                count: maxResultsToReturn ? response.count || 0 : events.length,
            };
        },
        timeoutInMillis: 1 * ONE_MINUTE,
    });
}

const mapAppointmentsResponse = (appointmentsPerDate): ICalendarEvent[] => {
    return Object.keys(appointmentsPerDate).reduce(
        (accumulator, date) => {
            const appointmentsPerType = appointmentsPerDate[date] as IAppointmentsPerType;
            const executedCompanyVisits = (
                validateIfArray(appointmentsPerType['executed-company-visit'])
                    ? appointmentsPerType['executed-company-visit']
                    : []
            ).map(mapExecutedCompanyVisitToCalendarEvent);
            const plannedCompanyVisits = (
                validateIfArray(appointmentsPerType['planned-company-visit'])
                    ? appointmentsPerType['planned-company-visit']
                    : []
            ).map(mapPlannedCompanyVisitToCalendarEvent);
            const plannedExaminations = (
                validateIfArray(appointmentsPerType['planned-examination'])
                    ? appointmentsPerType['planned-examination']
                    : []
            )
                .map(mapPlannedExaminationToCalendarEvent);
            const executedExaminations = (
                validateIfArray(appointmentsPerType['executed-examination'])
                    ? appointmentsPerType['executed-examination']
                    : []
            ).map(mapExecutedExaminationToCalendarEvent);
            const plannedInterventions = (
                validateIfArray(appointmentsPerType['planned-intervention'])
                    ? appointmentsPerType['planned-intervention']
                    : []
            ).map(mapPlannedIntervention);
            const executedInterventions = (
                validateIfArray(appointmentsPerType['executed-intervention'])
                    ? appointmentsPerType['executed-intervention']
                    : []
            ).map(mapExecutedInterventionToCalendarEvent);
            const plannedCourses = (
                validateIfArray(appointmentsPerType['planned-course'])
                    ? appointmentsPerType['planned-course']
                    : []
            ).map(mapCourseToCalenderEvent);
            const executedCourses = (
                validateIfArray(appointmentsPerType['executed-course'])
                    ? appointmentsPerType['executed-course']
                    : []
            ).map(mapCourseToCalenderEvent);
            return accumulator.concat([
                ...executedCompanyVisits,
                ...plannedCompanyVisits,
                ...plannedExaminations,
                ...executedExaminations,
                ...plannedInterventions,
                ...executedInterventions,
                ...plannedCourses,
                ...executedCourses,
            ]);
        },
        [],
    );
};

const mapExecutedCompanyVisitToCalendarEvent =
    (companyVisit: IExecutedCompanyVisit): ICalendarEvent<IExecutedCompanyVisit> => {
    const time = getDate(companyVisit.visitDate);
    const isStartAndEndTimeKnown = companyVisit.startTime && companyVisit.endTime
        && (companyVisit.startTime !== companyVisit.endTime);

    return {
        id: `${CalendarEventType.CompanyVisit}_${companyVisit.id}`,
        start: isStartAndEndTimeKnown ? getDate(companyVisit.startTime) : time,
        end: isStartAndEndTimeKnown ? getDate(companyVisit.endTime) : time,
        allDay: !isStartAndEndTimeKnown,
        type: CalendarEventType.CompanyVisit,
        data: companyVisit,
        dataType: CalendarEventDataType.ExecutedCompanyVisit,
        isConcatenated: false,
    };
};

const mapPlannedCompanyVisitToCalendarEvent =
    (companyVisit: IPlannedCompanyVisit): ICalendarEvent<IPlannedCompanyVisit> => {
    const time = getDate(companyVisit.visitDate);
    const isStartAndEndTimeKnown = companyVisit.startTime && companyVisit.endTime
        && (companyVisit.startTime !== companyVisit.endTime);

    return {
        id: `${CalendarEventType.CompanyVisit}_${companyVisit.id}`,
        start: isStartAndEndTimeKnown ? getDate(companyVisit.startTime) : time,
        end: isStartAndEndTimeKnown ? getDate(companyVisit.endTime) : time,
        allDay: !isStartAndEndTimeKnown,
        afterNoon: companyVisit.afterNoon,
        type: CalendarEventType.CompanyVisit,
        data: companyVisit,
        dataType: CalendarEventDataType.PlannedCompanyVisit,
        isConcatenated: false,
    };
};

const mapCourseToCalenderEvent =
    (plannedCourseAppointment: IPlannedCourseAppointment): ICalendarEvent<ICourse> => {
    const start = getDate(plannedCourseAppointment.startDate);
    const end = getDate(plannedCourseAppointment.endDate);

    return {
        id: `${CalendarEventType.Course}_${plannedCourseAppointment.coursePartId}`,
        start,
        end,
        allDay: false,
        type: CalendarEventType.Course,
        data: plannedCourseAppointment.course,
        dataType: CalendarEventDataType.Course,
        isConcatenated: false,
        title: plannedCourseAppointment.courseName,
    };
};

const mapPlannedExaminationToCalendarEvent = (
    examination: IPlannedMedicalExaminationAppointment,
): ICalendarEvent<IPlannedMedicalExaminationAppointment> => {
    const duration = DEFAULT_MEDICAL_EXAMINATION_DURATION;
    const start = getDate(examination.startTime);
    const end = minutesOffsetFromDate(start, duration).toDate();
    const isIncompany = examination.medicalCenter ?
        examination.medicalCenter.id === IN_COMPANY_MEDICAL_CENTER_ID : false;
    return {
        id: `${CalendarEventType.MedicalExamination}_${examination.timeCellId}`,
        start,
        end,
        allDay: false,
        type: isIncompany
            ? CalendarEventType.MedicalExaminationInCompany
            : CalendarEventType.MedicalExamination,
        data: examination,
        dataType: CalendarEventDataType.PlannedMedicalExamination,
        isConcatenated: false,
    };
};

const mapExecutedExaminationToCalendarEvent =
    (examination: IExecutedMedicalExamination): ICalendarEvent<IExecutedMedicalExamination> => {
    const day = getDate(examination.examinationDate);
    const duration = examination.duration || DEFAULT_MEDICAL_EXAMINATION_DURATION;
    const isIncompany = examination.medicalCenter ?
        examination.medicalCenter.id === IN_COMPANY_MEDICAL_CENTER_ID : false;
    const start = examination.startTime ? getDate(examination.startTime) : day;
    const end = examination.startTime ? minutesOffsetFromDate(start, duration).toDate() : day;
    const isAllDay = start === end;

    return {
        id: `${CalendarEventType.MedicalExamination}_${examination.id}`,
        start,
        end,
        allDay: isAllDay,
        type: isIncompany
            ? CalendarEventType.MedicalExaminationInCompany
            : CalendarEventType.MedicalExamination,
        data: examination,
        dataType: CalendarEventDataType.ExecutedMedicalExamination,
        isConcatenated: false,
    };
};

const mapPlannedIntervention =
    (intervention: IPlannedIntervention): ICalendarEvent<IPlannedIntervention> => ({
        id: `${CalendarEventType.Intervention}_${intervention.id}`,
        start: getDate(intervention.startTime),
        end: getDate(intervention.endTime),
        allDay: false,
        type: CalendarEventType.Intervention,
        data: intervention,
        dataType: CalendarEventDataType.PlannedIntervention,
        isConcatenated: false,
});

const mapExecutedInterventionToCalendarEvent =
    (intervention: IExecutedIntervention): ICalendarEvent<IExecutedIntervention> => ({
        id: `${CalendarEventType.Intervention}_${intervention.id}`,
        start: getDate(intervention.startTime),
        end: getDate(intervention.endTime),
        allDay: false,
        type: CalendarEventType.Intervention,
        data: intervention,
        dataType: CalendarEventDataType.ExecutedIntervention,
        isConcatenated: false,
});

function validateIfArray(appointments: object[]) {
    if (!Array.isArray(appointments)) {
        if (appointments) {
            console.warn(
                `Appointments were returned in an incorrect format, expected an array, got a ${typeof appointments}`,
                appointments,
            );
        }
        return false;
    }
    return true;
}
