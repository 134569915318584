import React, { Component } from 'react';
import { pathOr } from 'ramda';

import { IPlanMedicalExaminationBaseEntity } from '../../../../../../../models/interventions/medicalExaminations';
import {
    getBufferzonesWithinPlanningCalendarRangeMemoized,
    getDefaultSelectedMedicalCenterId,
    getPlanMedicalExaminationWizardEntity,
} from '../../../../../../../redux/medicalExamination/selectors';
import { updatePlanMedicalExaminationWizardEntity } from '../../../../../../../redux/medicalExamination/actions';
import connect from '../../../../../../../utils/libs/redux/connect';
import Button from '../../../../../../common/buttons/Button';
import Icon from '../../../../../../common/icons/Icon';
import MedicalCenterTypeahead from '../../../../../../administration/Employees/shared/MedicalCenterTypeahead';
import Translate from '../../../../../../common/Translate';
import { BASE_CLASS_NAME } from '../common';

import { TToolbarProps, IPrivateProps } from './toolbar.type';

class Toolbar extends Component<TToolbarProps> {
    constructor(props: TToolbarProps) {
        super(props);
    }

    public render() {
        const { selectedMedicalCenterId, onOpenBufferzones, showBufferzonesButton } = this.props;
        return (
            <span className={`${BASE_CLASS_NAME}__calendar-toolbar`}>
                {showBufferzonesButton && <Button
                    id="show-bufferzones-button"
                    className={`${BASE_CLASS_NAME}__calendar-toolbar-bufferzones-button`}
                    typeName="primary"
                    outline={true}
                    size="small"
                    onClick={onOpenBufferzones}
                >
                    <Icon typeName="eye" />
                    <Translate msg="interventions.medical_examinations.new.steps.select_timeslot.bufferzones.button" />
                </Button>}
                <MedicalCenterTypeahead
                    id="select-free-timeslot-medical-center"
                    name="select-medical-center"
                    value={selectedMedicalCenterId}
                    onItemSelected={this.onMedicalCenterSelected}
                    disableReset={true}
                    // eslint-disable-next-line max-len
                    placeholderTranslationKey="interventions.medical_examinations.new.steps.select_timeslot.medical_center_select.placeholder"
                    includeBufferzones={true}
                />
            </span>
        );
    }

    private onMedicalCenterSelected = (medicalCenterId: number) => {
        if (medicalCenterId && this.props.selectedMedicalCenterId !== medicalCenterId) {
            this.props.updateWizardData({
                selectTime: {
                    filter: {
                        selectedMedicalCenterId: medicalCenterId,
                    },
                },
            });
        }
    };
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const wizardEntity =
            getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);

        const selectedMedicalCenterId = pathOr(
            getDefaultSelectedMedicalCenterId(state),
            ['selectTime', 'filter', 'selectedMedicalCenterId'],
            wizardEntity,
        );

        return {
            selectedMedicalCenterId,
            showBufferzonesButton: getBufferzonesWithinPlanningCalendarRangeMemoized(state).length > 0,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardData: (values) => {
                const state = getState();
                const wizardEntity =
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
                const selectTimeData = wizardEntity && wizardEntity.selectTime;
                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>({
                    selectTime: {
                        ...selectTimeData,
                        ...values.selectTime,
                        filter: selectTimeData ? {
                            ...selectTimeData.filter,
                            ...values.selectTime.filter,
                        } : values.selectTime.filter,
                    },
                }));
            },
        };
    },
})(Toolbar);
