import { get, post, IResponseType } from '../../utils/api/requestWrapper';
import {
    IPlannedMedicalExamination,
    IExaminationReason,
    IMedicalExaminationToPlan,
    IExecutedMedicalExaminationsFilter,
    IExecutedMedicalExamination,
    IFetchMedicalExaminationDocumentsPayload,
    IMedicalExaminationDocuments,
    INoShowsMedicalExaminationsFilter,
    INoShowMedicalExamination,
    IRequestMedicalExaminationPayload,
    IPlannedMedicalExaminationsBackendFilter,
} from '../../models/interventions/medicalExaminations';
import {
    getCurrentMonth,
    getDateWithinCurrentYear,
    getDateWithinNextYear,
    monthOffsetFromNow,
    now,
    yearOffsetFromNow,
} from '../../utils/core/date/getSpecificDate';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { ONE_SECOND, ONE_MINUTE } from '../../utils/core/time/periodsInMillis';
import {
    IFilterValues,
} from '../../views/interventions/MedicalExaminations/Planned/index/PlannedMedicalExaminations.type';
import {
    ICompanyCodeWithShowFullFamilyPayload,
    ICompanyCodePayload,
    IStartEndDatePayload,
} from '../../models/admin/company';
import { concatenateStringList } from '../../utils/core/string/separatedStringList';
import { IDocument } from '../../models/general/documents';
import getFilenameFromHeaders from '../general/getFilenameFromHeaders';

export const URL = {
    COMPANY_MEDICAL_EXAMINATIONS_PLANNED: '/companies/{companyCode}/medical-examinations/planned',
    COMPANY_MEDICAL_EXAMINATIONS_PLANNED_EXPORT: '/companies/{companyCode}/medical-examinations/planned/export',
    COMPANY_MEDICAL_EXAMINATIONS_EXECUTED: '/companies/{companyCode}/medical-examinations/executed',
    COMPANY_MEDICAL_EXAMINATIONS_EXECUTED_EXPORT: '/companies/{companyCode}/medical-examinations/export',
    COMPANY_MEDICAL_EXAMINATIONS_NO_SHOWS: '/companies/{companyCode}/medical-examinations/no-shows',
    COMPANY_MEDICAL_EXAMINATIONS_TO_PLAN: '/companies/{companyCode}/medical-examinations/to-plan',
    COMPANY_EXAMINATION_REASONS: '/companies/{companyCode}/medical-examinations/reasons',
    MEDICAL_EXAMINATION_DOCUMENTS: '/employees/{id}/examination-documents/{examinationId}',
    REQUEST_MEDICAL_EXAMINATION: '/companies/{companyCode}/medical-examinations/request',
};

export const DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS: Partial<IFilterValues> = {
    startDate: formatDateForBackend(now()),
    endDate: formatDateForBackend(yearOffsetFromNow(1)),
};

export function fetchPlanned(
    companyCode: string,
    {
        startDate = DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
        endDate = DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
        medicalCenterCode,
        endTime,
        startTime,
        planningEntityId,
    }: IPlannedMedicalExaminationsBackendFilter,
    showFullFamily: boolean = true,
) {
    return get<IPlannedMedicalExamination[]>({
        url: URL.COMPANY_MEDICAL_EXAMINATIONS_PLANNED,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
            medicalCenterCode,
            startTime,
            endTime,
            planningEntityId,
        },
        mapResponse: (response) => {
            const examinations = response['planned-medical-examinations'];
            // filter out records that don't have an id (they also don't have an employeeId or examinationReason)
            // because of bad data following a wrongly used move api on the accept environment
            return examinations.filter(
                (examination: IPlannedMedicalExamination) => typeof examination.id !== 'undefined');
        },
        timeoutInMillis: 90 * ONE_SECOND,
    });
}

export function fetchExaminationReasons(companyCode: string) {
    return get<IExaminationReason[]>({
        url: URL.COMPANY_EXAMINATION_REASONS,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => response.reasons,
    });
}

const lastDayOfCurrentYear = formatDateForBackend(getDateWithinCurrentYear({ month: 12, day: 31 }));

const lastDayOfNextYear = formatDateForBackend(getDateWithinNextYear({month: 12, day: 31}));

export const DEFAULT_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS = {
    startDate: formatDateForBackend(yearOffsetFromNow(-10)),
    endDate: formatDateForBackend(lastDayOfCurrentYear),
};

export const MINMAX_TO_PLAN_MEDICAL_EXAMINATIONS_FILTERS = (
    getCurrentMonth() === 12
    ? { maxEndDate: formatDateForBackend(lastDayOfNextYear)}
    : { maxEndDate: formatDateForBackend(lastDayOfCurrentYear)}
);

interface IFetchToPlanPayload {
    allowedExaminationReasonIds?: number[];
    employeeCustomerId?: number;
    toBePlanned?: boolean;
}

/**
 * @param allowedExaminationReasonIds An optional list of examination-reason-id's. If provided, the backend
 *                                    will filter the result list based on these examinations.
 *                                    (automatically transformed to a "; separated" query param string)
 */
export function fetchToPlan({
    companyCode,
    showFullFamily,
    allowedExaminationReasonIds,
    employeeCustomerId,
    toBePlanned = undefined,
}: ICompanyCodeWithShowFullFamilyPayload & IFetchToPlanPayload) {
    return get<IMedicalExaminationToPlan[]>({
        url: URL.COMPANY_MEDICAL_EXAMINATIONS_TO_PLAN,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily,
            allowedExaminations: (allowedExaminationReasonIds && allowedExaminationReasonIds.length > 0)
                ? concatenateStringList(allowedExaminationReasonIds)
                : null,
            employeeCustomerId,
            ...(typeof toBePlanned === 'boolean') && {
                toBePlanned: toBePlanned.toString(),
            },
        },
        timeoutInMillis: 45 * ONE_SECOND,
        mapResponse: (response) => response['employees-to-plan'],
    });
}

export const DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS = {
    startDate: formatDateForBackend(monthOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export function fetchExecuted(
    companyCode: string,
    {
        startDate = DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
        endDate = DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
    }: IExecutedMedicalExaminationsFilter,
    showFullFamily: boolean = true,
) {
    return get<IExecutedMedicalExamination[]>({
        url: URL.COMPANY_MEDICAL_EXAMINATIONS_EXECUTED,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['examinations']
            // filter out records that don't have an id (they also don't have an employeeId or examinationReason)
            // because of bad data following a wrongly used move api on the accept environment
            .filter((examination: IExecutedMedicalExamination) => typeof examination.id !== 'undefined'),
    });
}

export function fetchExaminationDocuments({
    examinationId,
    employeeCustomerId,
}: IFetchMedicalExaminationDocumentsPayload) {
    return get<IMedicalExaminationDocuments>({
        url: URL.MEDICAL_EXAMINATION_DOCUMENTS,
        pathParams: {
            examinationId,
            id: employeeCustomerId,
        },
        mapResponse: (response) => response['documents'],
    });
}

export const DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS = {
    startDate: formatDateForBackend(monthOffsetFromNow(-3)),
    endDate: formatDateForBackend(now()),
};

export function fetchNoShows(
    companyCode: string,
    {
        startDate = DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS.startDate,
        endDate = DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS.endDate,
    }: INoShowsMedicalExaminationsFilter,
    showFullFamily: boolean = true,
) {
    return get<INoShowMedicalExamination[]>({
        url: URL.COMPANY_MEDICAL_EXAMINATIONS_NO_SHOWS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            endDate,
            showFullFamily: showFullFamily.toString(),
        },
        mapResponse: (response) => response['noshows'],
    });
}

export function requestMedicalExamination(payload: IRequestMedicalExaminationPayload & ICompanyCodePayload) {
    const { companyCode, ...requestBody } = payload;
    return post({
        url: URL.REQUEST_MEDICAL_EXAMINATION,
        pathParams: {
            companyCode,
        },
        body: requestBody,
    });
}

export function exportPlannedExaminations(
    {
        companyCode,
        showFullFamily,
        startDate = DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
        endDate = DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
    }: ICompanyCodeWithShowFullFamilyPayload & IStartEndDatePayload,
    defaultFilename: string,
) {
    return get<IDocument>({
        url: URL.COMPANY_MEDICAL_EXAMINATIONS_PLANNED_EXPORT,
        responseType: IResponseType.blob,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
            startDate,
            endDate,
        },
        timeoutInMillis: ONE_MINUTE,
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, defaultFilename),
            };
        },
    });
}

export function exportExecutedExaminations(
    {
        companyCode,
        showFullFamily,
        startDate = DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
        endDate = DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
    }: ICompanyCodeWithShowFullFamilyPayload & IStartEndDatePayload,
    defaultFilename: string,
) {
    return get<IDocument>({
        url: URL.COMPANY_MEDICAL_EXAMINATIONS_EXECUTED_EXPORT,
        responseType: IResponseType.blob,
        pathParams: {
            companyCode,
        },
        queryParams: {
            showFullFamily: showFullFamily.toString(),
            startDate,
            endDate,
        },
        timeoutInMillis: ONE_MINUTE,
        mapResponse: (response, headers) => {
            return {
                data: response,
                filename: getFilenameFromHeaders(headers, defaultFilename),
            };
        },
    });
}
