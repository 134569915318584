import { IReducerState, reducerKey } from './reducer';
import {
    getReducerState,
    IState,
    makeAsyncFetchInfoSelector,
    makeAsyncDoInfoSelector,
    getAsyncDoInfo,
    NO_RERENDER,
} from '../../index';
import { AsyncStatus } from '../../../models/general/redux';
import { getRoutePayload } from '../../location/selectors';
import { getSelectedCompanySeat } from '../../company/selected/selectors';
import {
    IEmployeeMedicalExaminations,
    IEmployeeRisksAndResearches,
    IEmployeeJobStudent,
    IEmployeeFunctionRisksAndResearches,
    ISelectedEmployeeStatus,
} from '../../../models/admin/employee';

const reducerState = (state: IState) => getReducerState<IReducerState>(state, reducerKey);

// Employee detail

const getSelectedEmployeeAsyncField = (state: IState) => reducerState(state).selectedEmployee;

export const getSelectedEmployeeId = (state: IState) => {
    const routePayload = getRoutePayload<{ id: string }>(state);
    return routePayload.id;
};

export const getSelectedEmployee = (state: IState) => getSelectedEmployeeAsyncField(state).data;
export const getSelectedEmployeeAsyncInfo = makeAsyncFetchInfoSelector(getSelectedEmployeeAsyncField);

export const isSelectedEmployeeEmployedInSelectedCompanySeat = (state: IState) => {
    const employee = getSelectedEmployee(state);
    const { isAllSeatsSelected, companySeat } = getSelectedCompanySeat(state);

    if (!companySeat || !companySeat.company) {
        return false;
    }

    // Take the companyNumber without the seat number
    const companyNumber = getCompanyCodeWithoutSeatNumber(companySeat.company.companyCode);
    // If all seats are selected we check if the company matches
    // If a specific seat is selected we check for the entire company code
    const companyCodePrefix = isAllSeatsSelected ? companyNumber : companySeat.company.companyCode;

    return employee && employee.company ? (
        employee.company.companyCode === companySeat.company.companyCode ||
        // indexOf is used because the employee could be working for a division
        employee.company.companyCode.indexOf(companyCodePrefix + '.') === 0
    ) : false;
};

export const getCompanyCodeWithoutSeatNumber = (companyCode: string) => {
    return companyCode.split('.')[0];
};

export const areCompanyCodesDifferent = (companyCode1: string, companyCode2: string) => {
    return getCompanyCodeWithoutSeatNumber(companyCode1) !== getCompanyCodeWithoutSeatNumber(companyCode2);
};

export const isEmployeeDetailsDataAvailable = (state: IState) => !!getSelectedEmployeeAsyncField(state).data;

// Statutes

const getSelectedEmployeeStatutesAsyncField = (state: IState) => reducerState(state).selectedEmployeeStatutes;

export const getSelectedEmployeeStatutes = (state: IState) => getSelectedEmployeeStatutesAsyncField(state).data;
export const getSelectedEmployeeStatutesAsyncInfo = makeAsyncFetchInfoSelector(getSelectedEmployeeStatutesAsyncField);

const getAddEmployeeStatuteAsyncField = (state: IState) => reducerState(state).addEmployeeStatute;

export const getAddEmployeeStatuteAsyncInfo = makeAsyncDoInfoSelector(getAddEmployeeStatuteAsyncField);

// Absences

const getSelectedEmployeeAbsencesAsyncField = (state: IState) => reducerState(state).selectedEmployeeAbsences;

export const getSelectedEmployeeAbsences = (state: IState) => getSelectedEmployeeAbsencesAsyncField(state).data;
export const getSelectedEmployeeAbsencesAsyncInfo = makeAsyncFetchInfoSelector(getSelectedEmployeeAbsencesAsyncField);

const getAddEmployeeAbsenceAsyncField = (state: IState) => reducerState(state).addEmployeeAbsence;
export const getAddEmployeeAbsenceAsyncInfo = makeAsyncDoInfoSelector(getAddEmployeeAbsenceAsyncField);

const getUpdateEmployeeAbsenceAsyncField = (state: IState) => reducerState(state).updateEmployeeAbsence;
export const getUpdateEmployeeAbsenceAsyncInfo = makeAsyncDoInfoSelector(getUpdateEmployeeAbsenceAsyncField);

const getRemoveEmployeeAbsenceAsyncField = (state: IState) => reducerState(state).removeEmployeeAbsence;
export const getRemoveEmployeeAbsenceAsyncInfo = makeAsyncDoInfoSelector(getRemoveEmployeeAbsenceAsyncField);

// Update employee

const getUpdateEmployeeRequestsAsyncField = (state: IState) => reducerState(state).updateEmployeeRequests;

// TODO memoization selector
export const getUpdateEmployeeAsyncInfo = (state: IState, requestId: string) => {
    const updateEmployeeRequestsAsyncField = getUpdateEmployeeRequestsAsyncField(state);
    const asynDoStatus = updateEmployeeRequestsAsyncField[requestId];
    if (asynDoStatus) {
        return getAsyncDoInfo(asynDoStatus);
    }
    return {
        status: AsyncStatus.Initial,
        error: null,
    };
};

const getUpdateEmployeeAllFieldsAsyncField = (state: IState) => reducerState(state).updateEmployeeAllFields;

export const getUpdateEmployeeAllFieldsAsyncInfo = makeAsyncDoInfoSelector(getUpdateEmployeeAllFieldsAsyncField);

// Update employee cost center
const getUpdateEmployeeCostCenterAsyncField = (state: IState) => reducerState(state).updateEmployeeCostCenter;
export const getUpdateEmployeeCostCenterAsyncInfo = makeAsyncDoInfoSelector(getUpdateEmployeeCostCenterAsyncField);

// Update employee employment

const getUpdateEmployeeEmploymentAsyncField = (state: IState) => reducerState(state).updateEmployeeEmployment;
export const getUpdateEmployeeEmploymentAsyncInfo = makeAsyncDoInfoSelector(getUpdateEmployeeEmploymentAsyncField);

const getSetEmployeeOutOfServiceAsyncField = (state: IState) => reducerState(state).setEmployeeOutOfService;
export const getSetEmployeeOutOfServiceAsyncInfo = makeAsyncDoInfoSelector(getSetEmployeeOutOfServiceAsyncField);

const getChangeEmployeeOutOfServiceAsyncField = (state: IState) => reducerState(state).changeEmployeeOutOfService;
export const getChangeEmployeeOutOfServiceAsyncInfo = makeAsyncDoInfoSelector(getChangeEmployeeOutOfServiceAsyncField);

const getClearEmployeeOutOfServiceAsyncField = (state: IState) => reducerState(state).clearEmployeeOutOfService;
export const getClearEmployeeOutOfServiceAsyncInfo = makeAsyncDoInfoSelector(getClearEmployeeOutOfServiceAsyncField);

export const getSetChangeOrClearOutOfServiceIsBusy = (state: IState) => (
    getSetEmployeeOutOfServiceAsyncInfo(state).status === AsyncStatus.Busy ||
    getChangeEmployeeOutOfServiceAsyncInfo(state).status === AsyncStatus.Busy ||
    getClearEmployeeOutOfServiceAsyncInfo(state).status === AsyncStatus.Busy
);

// Risk

const getUpdateEmployeeRiskAsyncField =
    (state: IState) => reducerState(state).updateEmployeeRisk;
export const getUpdateEmployeeRiskAsyncInfo = makeAsyncDoInfoSelector(getUpdateEmployeeRiskAsyncField);

const getRemoveEmployeeRiskAsyncField =
    (state: IState) => reducerState(state).removeEmployeeRisk;
export const getRemoveEmployeeRiskAsyncInfo = makeAsyncDoInfoSelector(getRemoveEmployeeRiskAsyncField);

// Statute

const getUpdateEmployeeStatuteAsyncField =
    (state: IState) => reducerState(state).updateEmployeeStatute;
export const getUpdateEmployeeStatuteAsyncInfo = makeAsyncDoInfoSelector(getUpdateEmployeeStatuteAsyncField);

const getRemoveEmployeeStatuteAsyncField =
    (state: IState) => reducerState(state).removeEmployeeStatute;
export const getRemoveEmployeeStatuteAsyncInfo = makeAsyncDoInfoSelector(getRemoveEmployeeStatuteAsyncField);

// Employee risks and researches

const getEmployeeRisksAndResearchesAsyncField = (state: IState) =>
    reducerState(state).employeeRisksAndResearches;

export const getEmployeeRisksAndResearchesAsyncInfo =
    makeAsyncFetchInfoSelector(getEmployeeRisksAndResearchesAsyncField);

export const getEmployeeRisksAndResearches = (state: IState): IEmployeeRisksAndResearches =>
    getEmployeeRisksAndResearchesAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT;

// Employee risks and researches
const getEmployeeFunctionRisksAndResearchesAsyncField = (state: IState) =>
    reducerState(state).employeeFunctionRisksAndResearches;

export const getEmployeeFunctionRisksAndResearchesAsyncInfo =
    makeAsyncFetchInfoSelector(getEmployeeFunctionRisksAndResearchesAsyncField);

export const getEmployeeFunctionRisksAndResearches = (state: IState): IEmployeeFunctionRisksAndResearches =>
    getEmployeeFunctionRisksAndResearchesAsyncField(state).data ||
    NO_RERENDER.EMPTY_OBJECT as IEmployeeFunctionRisksAndResearches;

// Employee personal risks and reasearches

const getEmployeePersonalRisksAsyncField = (state: IState) =>
    reducerState(state).employeePersonalRisks;

export const getEmployeePersonalRisksAsyncInfo = makeAsyncFetchInfoSelector(getEmployeePersonalRisksAsyncField);

export const getEmployeePersonalRisks = (state: IState) =>
    getEmployeePersonalRisksAsyncField(state).data || NO_RERENDER.EMPTY_LIST;

// Create employee personal risk
const getAddEmployeePersonalRiskAsyncField = (state: IState) => reducerState(state).addEmployeePersonalRisk;

export const getAddEmployeePersonalRiskAsyncInfo = makeAsyncDoInfoSelector(getAddEmployeePersonalRiskAsyncField);

// Employee medical examinations

const getEmployeeMedicalExaminationsAsyncField = (state: IState) =>
    reducerState(state).employeeMedicalExaminations;

export const getEmployeeMedicalExaminationsAsyncInfo =
    makeAsyncFetchInfoSelector(getEmployeeMedicalExaminationsAsyncField);

export const getEmployeeMedicalExaminations = (state: IState): IEmployeeMedicalExaminations =>
    getEmployeeMedicalExaminationsAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT;

export const areEmployeeMedicalExaminationsAvailable = (state: IState) =>
    getEmployeeMedicalExaminationsAsyncField(state).data !== null;

// Employee JobStudent

const getSelectedEmployeeJobStudentAsyncField = (state: IState) =>
    reducerState(state).employeeJobStudent;

export const getSelectedEmployeeJobStudentAsyncInfo =
    makeAsyncFetchInfoSelector(getSelectedEmployeeJobStudentAsyncField);

export const getSelectedEmployeeJobStudent = (state: IState) =>
    getSelectedEmployeeJobStudentAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT as IEmployeeJobStudent;

// Add employee JobStudent
const getAddEmployeeJobStudentAsyncField = (state: IState) => reducerState(state).addEmployeeJobStudent;

export const getAddEmployeeJobStudentAsyncInfo = makeAsyncDoInfoSelector(getAddEmployeeJobStudentAsyncField);

// Update employee JobStudent
const getUpdateEmployeeJobStudentAsyncField = (state: IState) => reducerState(state).updateEmployeeJobStudent;

export const getUpdateEmployeeJobStudentAsyncInfo = makeAsyncDoInfoSelector(getUpdateEmployeeJobStudentAsyncField);

// Job Student Request Failed Warning
export const getJobStudentRequestFailedWarning = (state: IState) => reducerState(state).jobStudentRequestFailedWarning;

// Employee status

const getSelectedEmployeeStatusAsyncField = (state: IState) =>
    reducerState(state).selectedEmployeeStatus;

export const getSelectedEmployeeStatusAsyncInfo =
    makeAsyncFetchInfoSelector(getSelectedEmployeeStatusAsyncField);

export const getSelectedEmployeeStatus = (state: IState): ISelectedEmployeeStatus =>
    getSelectedEmployeeStatusAsyncField(state).data || NO_RERENDER.EMPTY_OBJECT as ISelectedEmployeeStatus;
