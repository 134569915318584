import React, { PureComponent } from 'react';
import SelectFreeTimeslot from '../../../shared/SelectFreeTimeslot';
import connect from '../../../../../../../utils/libs/redux/connect';
import {
    fetchMedicalExaminationsToPlan,
    updatePlanMedicalExaminationWizardEntity,
    updateTimeslotActions,
    triggerMoveUpdateTimeslot,
    addTimeslotActions,
    fetchCompanyMedicalCenterTimeslotsActions,
    fetchPlannedMedicalExaminationsActions,
} from '../../../../../../../redux/medicalExamination/actions';
import {
    areMedicalExaminationsToPlanAvailable,
    getPlanMedicalExaminationWizardEntity,
    getPlanMedicalExaminationWizardReason,
    getUpdateTimeslotAsyncInfo,
    getAddTimeslotAsyncInfo,
} from '../../../../../../../redux/medicalExamination/selectors';
import { getSelectedEmployee } from '../../../../../../../redux/employee/info/selectors';
import { fetchSmallEmployeeDetails } from '../../../../../../../redux/employee/info/actions';
import {
    IMedicalExaminationToAdd,
    IPlanMedicalExaminationMultipleEmployeesBaseEntity,
    IPeriodicHealthAssessmentAutomaticEntity,
} from '../../../../../../../models/interventions/medicalExaminations';
import { IPerson } from '../../../../../../../models/admin/employee';
import {
    ITimeCell,
    IAddTimeslotByPlanningRecordIdPayload,
} from '../../../../../../../models/interventions/timeslots';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../../models/general/redux';
import Loader from '../../../../../../common/waiting/Loader';
import ErrorDialog from '../../../../../../common/modals/ErrorDialog';
import { getCompanyMedicalCenters } from '../../../../../../../redux/company/info/selectors';

interface IEditTimeslotProps {
    selectedMedicalExamination: IMedicalExaminationToAdd;
    onClose: () => void;
    hideDatepicker?: boolean;
    isActive: boolean;
}

interface IPrivateProps {
    updateAsyncInfo: IAsyncFieldInfo;
    addAsyncInfo: IAsyncFieldInfo;
    fetchDataIfNotavailable: (employee: {
        id: number;
        employeeId: number;
    } & IPerson) => void;
    addOrMoveTimeslot: (medicalExamination: IMedicalExaminationToAdd, newTimecell: ITimeCell) => void;
    updateMedicalExaminationTimecell: (medicalExamination: IMedicalExaminationToAdd, newTimecell: ITimeCell) => void;
    resetUpdateTimeslot: () => void;
    resetAddTimeslot: () => void;
    refreshCalendarData: (medicalExamination: IMedicalExaminationToAdd) => void;
    resetCalendarData: () => void;
}

interface IComponentState {
    selectedTimecell: ITimeCell;
}

class EditTimeslot extends PureComponent<IEditTimeslotProps & IPrivateProps, IComponentState> {

    constructor(props: IEditTimeslotProps & IPrivateProps) {
        super(props);

        this.state = {
            selectedTimecell: null,
        };

        this.onTimeslotSelected = this.onTimeslotSelected.bind(this);
        this.clearUpdateAsyncInfo = this.clearUpdateAsyncInfo.bind(this);
        this.clearAddAsyncInfo = this.clearAddAsyncInfo.bind(this);
    }

    public render() {
        const { selectedMedicalExamination, updateAsyncInfo, addAsyncInfo, hideDatepicker } = this.props;

        if (!selectedMedicalExamination) {
            return null;
        }

        return (
            <>
                <Loader show={addAsyncInfo.status} />
                <ErrorDialog
                    asyncInfo={addAsyncInfo}
                    // eslint-disable-next-line max-len
                    titleTranslationKey="interventions.medical_examinations.new.steps.validate_auto_plan.edit.error.title"
                    onCloseDialog={this.clearAddAsyncInfo}
                />
                <Loader show={updateAsyncInfo.status} />
                <ErrorDialog
                    asyncInfo={updateAsyncInfo}
                    // eslint-disable-next-line max-len
                    titleTranslationKey="interventions.medical_examinations.new.steps.validate_auto_plan.edit.error.title"
                    onCloseDialog={this.clearUpdateAsyncInfo}
                />
                <SelectFreeTimeslot
                    onTimeslotSelected={this.onTimeslotSelected}
                    selectedEmployee={selectedMedicalExamination.employee}
                    hideDatepicker={hideDatepicker}
                />
            </>
        );
    }

    public componentDidMount() {
        const { selectedMedicalExamination, fetchDataIfNotavailable, isActive } = this.props;
        if (isActive && selectedMedicalExamination) {
            fetchDataIfNotavailable(selectedMedicalExamination.employee);
        }
    }

    public componentDidUpdate(prevProps: IEditTimeslotProps & IPrivateProps) {
        const {
            selectedMedicalExamination, fetchDataIfNotavailable,
            updateAsyncInfo, onClose, updateMedicalExaminationTimecell,
            addAsyncInfo, refreshCalendarData, resetCalendarData, isActive,
        } = this.props;
        if (
            isActive && selectedMedicalExamination &&
            prevProps.selectedMedicalExamination !== selectedMedicalExamination
        ) {
            fetchDataIfNotavailable(selectedMedicalExamination.employee);
            resetCalendarData();
        }
        if (
            (
                prevProps.updateAsyncInfo.status === AsyncStatus.Busy &&
                updateAsyncInfo.status === AsyncStatus.Success
            ) ||
            (
                prevProps.addAsyncInfo.status === AsyncStatus.Busy &&
                addAsyncInfo.status === AsyncStatus.Success
            )
        ) {
            updateMedicalExaminationTimecell(selectedMedicalExamination, this.state.selectedTimecell);
            refreshCalendarData(selectedMedicalExamination);
            resetCalendarData();
            onClose();
        }
    }

    private onTimeslotSelected(timecell: ITimeCell) {
        const { addOrMoveTimeslot, selectedMedicalExamination } = this.props;
        addOrMoveTimeslot(selectedMedicalExamination, timecell);
        this.setState({
            selectedTimecell: timecell,
        });
    }

    private clearUpdateAsyncInfo() {
        this.props.resetUpdateTimeslot();
    }

    private clearAddAsyncInfo() {
        this.props.resetAddTimeslot();
    }
}

export default connect<IPrivateProps, IEditTimeslotProps>({
    stateProps: (state) => {
        return {
            updateAsyncInfo: getUpdateTimeslotAsyncInfo(state),
            addAsyncInfo: getAddTimeslotAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            fetchDataIfNotavailable: (employee) => {
                const state = getState();
                if (!areMedicalExaminationsToPlanAvailable(state)) {
                    dispatch(fetchMedicalExaminationsToPlan());
                }
                const selectedEmployee = getSelectedEmployee(state);
                if (!selectedEmployee || selectedEmployee.id !== employee.id) {
                    dispatch(fetchSmallEmployeeDetails({
                        id: employee.id,
                    }));
                }
            },
            addOrMoveTimeslot: (medicalExamination: IMedicalExaminationToAdd, newTimeCell: ITimeCell) => {
                const state = getState();

                const examinationReason = getPlanMedicalExaminationWizardReason(state);
                const selectedEmployee = getSelectedEmployee(state);

                if (medicalExamination && medicalExamination.timeCell) {
                    dispatch(triggerMoveUpdateTimeslot({
                        remarks: '',
                        examinationReason: {
                            id: examinationReason.id,
                        },
                        timeCellId: medicalExamination.timeCell.id,
                        newTimeCellId: newTimeCell.id,
                        company: {
                            id: selectedEmployee.company.id,
                            companyCode: selectedEmployee.company.companyCode,
                        },
                    }));
                } else if (medicalExamination) {
                    if (medicalExamination.planningRecordId) {
                        const payload: IAddTimeslotByPlanningRecordIdPayload = {
                            remarks: '',
                            employee: medicalExamination.employee,
                            timeCellId: newTimeCell.id,
                            planningEntityId: newTimeCell.planningEntityId,
                            planningRecordId: medicalExamination.planningRecordId,
                            company: {
                                id: selectedEmployee.company.id,
                                companyCode: selectedEmployee.company.companyCode,
                            },
                        };
                        dispatch(addTimeslotActions.trigger(payload));
                    } else {
                        dispatch(addTimeslotActions.trigger({
                            remarks: '',
                            employee: medicalExamination.employee,
                            examinationReason: {
                                id: examinationReason.id,
                            },
                            timeCellId: newTimeCell.id,
                            planningEntityId: newTimeCell.planningEntityId,
                            company: {
                                id: selectedEmployee.company.id,
                                companyCode: selectedEmployee.company.companyCode,
                            },
                        }));
                    }
                }
            },
            resetUpdateTimeslot: () => {
                dispatch(updateTimeslotActions.reset({}));
            },
            resetAddTimeslot: () => {
                dispatch(addTimeslotActions.reset({}));
            },
            updateMedicalExaminationTimecell:
                (medicalExamination: IMedicalExaminationToAdd, newTimeCell: ITimeCell) => {
                    const state = getState();
                    const wizardEntity = getPlanMedicalExaminationWizardEntity<
                        IPlanMedicalExaminationMultipleEmployeesBaseEntity>(state);
                    const planningId = wizardEntity && wizardEntity.selectTime.planningId;
                    const prevMedicalExaminationsToAdd =
                        wizardEntity ? wizardEntity.medicalExaminationsToAdd : [];
                    const newMedicalExaminationsToAdd = prevMedicalExaminationsToAdd.map((item) => {
                        if (
                            item.employee.id === medicalExamination.employee.id &&
                            item.planningRecordId === medicalExamination.planningRecordId
                        ) {
                            return {
                                ...medicalExamination,
                                timeCell: newTimeCell,
                                errorMessage: null,
                                planningId,
                                locationId: newTimeCell.medicalCenter.id,
                            };
                        }
                        return item;
                    });
                    dispatch(
                        updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>({
                            medicalExaminationsToAdd: newMedicalExaminationsToAdd,
                        }),
                    );
                },
            refreshCalendarData: (medicalExamination: IMedicalExaminationToAdd) => {
                const state = getState();
                const wizardEntity = getPlanMedicalExaminationWizardEntity<
                    IPlanMedicalExaminationMultipleEmployeesBaseEntity>(state);
                if (wizardEntity && wizardEntity.selectTime) {
                    const medicalCenterId = wizardEntity.selectTime.filter.selectedMedicalCenterId;
                    const { start, end } = wizardEntity.selectTime.filter.dateRangeToFetch;
                    const selectedMedicalCenterData = getCompanyMedicalCenters(state)
                        .find((item) => item.id === medicalCenterId);
                    const examinationReason = getPlanMedicalExaminationWizardReason(state);
                    if (selectedMedicalCenterData) {
                        dispatch(fetchCompanyMedicalCenterTimeslotsActions.trigger({
                            medicalCenterId,
                            endDate: end,
                            startDate: start,
                            hasCabinets: selectedMedicalCenterData.hasCabinets,
                            examinationReasonId: examinationReason.id,
                        }));
                        dispatch(fetchPlannedMedicalExaminationsActions.trigger({
                            endDate: end,
                            startDate: start,
                            medicalCenterCode: selectedMedicalCenterData.code,
                        }));
                    }
                }
            },
            resetCalendarData: () => {
                const state = getState();
                const wizardEntity =
                    // eslint-disable-next-line max-len
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity & IPeriodicHealthAssessmentAutomaticEntity>(state);
                if (wizardEntity) {
                    dispatch(
                        updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationMultipleEmployeesBaseEntity>({
                            selectTime: {
                                ...wizardEntity.selectTime,
                                filter: wizardEntity.selectTime ? {
                                    ...wizardEntity.selectTime.filter,
                                    selectedDate: wizardEntity.startDate,
                                    selectedMedicalCenterId: null,
                                } : {
                                    selectedDate: wizardEntity.startDate,
                                },
                            },
                        }),
                    );
                }
            },
        };
    },
})(EditTimeslot);
