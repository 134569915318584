import React from 'react';
import './plan-bufferzone-wizard.scss';
import Wizard from '../../common/navigation/Wizard/index';
import { registerWizardSteps, WIZARD_CODE } from '../../../config/navigation/wizardStepsMap';
import {
    PLAN_BUFFERZONE_WIZARD_STEP_ID,
    IPlanBufferzoneWizardStep,
    IPlanBufferzoneWizardEntity,
} from '../../../models/interventions/bufferzones';
import SelectBufferzone from './wizard/SelectBufferzone';
import { changePlanBufferzoneWizardStep } from '../../../redux/intervention/bufferzones/actions';
import {
    getPlanBufferzoneWizardStepId,
    getPlanBufferzoneWizardEntity,
} from '../../../redux/intervention/bufferzones/selectors';
import Overview from './wizard/Overview';
import SelectEmployees from './wizard/SelectEmployees';
import PlanBufferzone from './wizard/PlanBufferzone';
import { connect } from '../..';
import PageHeader from '../../appShell/PageHeader';
import ButtonLinkToRoute from '../../common/navigation/ButtonLinkToRoute';
import ROUTE_KEYS from '../../../routeKeys';
import Translate from '../../common/Translate';

const TRANSLATION_PREFIX = 'interventions.plan_bufferzone.steps';

const PLAN_BUFFERZONE_STEPS: IPlanBufferzoneWizardStep[] = [{
    id: PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_BUFFERZONE,
    translationKey: `${TRANSLATION_PREFIX}.select_bufferzone.step_label`,
    renderStepContent: (props) => <SelectBufferzone {...props}/>,
    hide: (entity) =>
        entity &&
        entity.selectedBufferzone &&
        entity.selectedBufferzone.selectedFromOverview,
}, {
    id: PLAN_BUFFERZONE_WIZARD_STEP_ID.SELECT_EMPLOYEES,
    translationKey: `${TRANSLATION_PREFIX}.select_employees.step_label`,
    renderStepContent: (props) => <SelectEmployees {...props} />,
}, {
    id: PLAN_BUFFERZONE_WIZARD_STEP_ID.PLANNING,
    translationKey: `${TRANSLATION_PREFIX}.planning.step_label`,
    renderStepContent: (props) => <PlanBufferzone {...props}/>,
}, {
    id: PLAN_BUFFERZONE_WIZARD_STEP_ID.OVERVIEW,
    translationKey: `${TRANSLATION_PREFIX}.overview.step_label`,
    renderStepContent: (props) => <Overview {...props}/>,
}];

registerWizardSteps({
    wizardCode: WIZARD_CODE.PLAN_BUFFERZONE,
    steps: PLAN_BUFFERZONE_STEPS,
});

interface IPrivateProps {
    wizardEntity: IPlanBufferzoneWizardEntity;
}

function PlanBufferzoneWizard(props: IPrivateProps) {
    const steps = PLAN_BUFFERZONE_STEPS.filter((step) => {
        if (typeof step.hide === 'function') {
            return !step.hide(props.wizardEntity);
        }
        return true;
    });

    return (
        <div className="PlanBufferzoneWizard">
            <Wizard
                activeStepIdSelector={getPlanBufferzoneWizardStepId}
                navigateToStepActionCreator={changePlanBufferzoneWizardStep}
                steps={steps}
            />
            <div className="PlanBufferzoneWizard__disabled">
                <PageHeader
                    title="interventions.plan_bufferzone.disabled.title"
                    text="interventions.plan_bufferzone.disabled.text"
                />
                <div className="container">
                    <ButtonLinkToRoute
                        id="go-to-manual-planning"
                        to={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON}
                        typeName="secondary"
                    >
                        <Translate msg="interventions.plan_bufferzone.disabled.button" />
                    </ButtonLinkToRoute>
                </div>
            </div>
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            wizardEntity: getPlanBufferzoneWizardEntity(state) as IPlanBufferzoneWizardEntity,
        };
    },
})(PlanBufferzoneWizard);
