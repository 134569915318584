import React from 'react';
import { connect } from '../../../../../index';
import TinyLoader from '../../../../../common/waiting/TinyLoader';
import {
    getEmployeeMedicalExaminationsAsyncInfo, getEmployeeMedicalExaminations,
} from '../../../../../../redux/employee/info/selectors';
import isEmptyObject from '../../../../../../utils/core/object/isEmptyObject';
import {
    IEmployeeMedicalExaminations, IEmployeeDetails, IEmployeeMedicalExamination,
} from '../../../../../../models/admin/employee';
import Translate from '../../../../../common/Translate';
import ListItem from '../../../../../common/list/ListItem';
import TranslatorContext from '../../../../../appShell/contexts/TranslatorContext';
import { formatDateForDisplay } from '../../../../../../utils/formatting/formatDate';
import ShowIfAllowed from '../../../../../auth/ShowIfAllowed';
import Button from '../../../../../common/buttons/Button';
import {
    getExaminationReasonsAsyncInfo, getDefaultSelectedMedicalCenterId, getExaminationReasonByCode,
} from '../../../../../../redux/medicalExamination/selectors';
import { skipToPlanMedicalExaminationWizardStepActions } from '../../../../../../redux/medicalExamination/actions';
import { PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE } from '../../../../../../models/interventions/medicalExaminations';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { getRouteKey, getRoutePayload } from '../../../../../../redux/location/selectors';
// eslint-disable-next-line max-len
import { toMedicalExaminationToPlan } from '../../../../../interventions/PlanMedicalExamination/PlanMedicalExaminationWizard/automatic/EmployeesToPlan';
import { getCompanyMedicalCenters } from '../../../../../../redux/company/info/selectors';
import { ITranslator } from '../../../../../../models/general/i18n';
import { monthOffsetFromDate, getDate } from '../../../../../../utils/core/date/getSpecificDate';

interface IPrivateProps {
    employeeMedicalExaminations: IEmployeeMedicalExaminations;
    triggerWizardWithSelectedEmployee: (
        employee: IEmployeeDetails,
        nextExamination: IEmployeeMedicalExaminations['next']
    ) => void;
    selectedPlannedExaminationId: number;
    selectedExecutedExaminationId: number;
}

interface IEmployeeMedicalExaminationsProps {
    employee: IEmployeeDetails;
    executedMedicalExaminationRouteKey: ROUTE_KEYS;
    plannedMedicalExaminationRouteKey: ROUTE_KEYS;
    extraRoutePayload?: object;
}

const renderNextExaminationNotPlannable = (
    examination: IEmployeeMedicalExaminations['next'],
    translator: ITranslator,
) => {
    const date = formatDateForDisplay(
        monthOffsetFromDate(getDate(examination.toBePlannedDate), -6),
    );

    return (
        <ListItem
            title={translator({
                // eslint-disable-next-line max-len
                msg: 'administration.employees.detail.medical_examination.to_plan.to_be_planned_from',
                placeholders: {
                    // eslint-disable-next-line max-len
                    date,
                },
            })}
            text={examination.examinationReason.title}
            arrow={true}
        />
    );
};

function EmployeeMedicalExaminationsComp(props: IPrivateProps & IEmployeeMedicalExaminationsProps) {
    const {
        employeeMedicalExaminations, triggerWizardWithSelectedEmployee,
        employee, plannedMedicalExaminationRouteKey, executedMedicalExaminationRouteKey,
        selectedExecutedExaminationId, selectedPlannedExaminationId, extraRoutePayload,
    } = props;

    return (
        <TranslatorContext.Consumer>
            {({ translator }) => (
                <TinyLoader asyncInfoSelector={getEmployeeMedicalExaminationsAsyncInfo}>
                    {!isEmptyObject(employeeMedicalExaminations) ?
                        <>
                            <h4>
                                <Translate
                                    msg="administration.employees.detail.medical_examination.to_plan.title"
                                />
                            </h4>
                            {
                                employeeMedicalExaminations.hasOwnProperty('next') ?
                                    !employeeMedicalExaminations.next.toBePlanned ?
                                        renderNextExaminationNotPlannable(
                                            employeeMedicalExaminations.next,
                                            translator,
                                        ) : (
                                        <ListItem
                                            title={translator({
                                                // eslint-disable-next-line max-len
                                                msg: 'administration.employees.detail.medical_examination.to_plan.deadline',
                                                placeholders: {
                                                    // eslint-disable-next-line max-len
                                                    date: formatDateForDisplay(employeeMedicalExaminations.next.toBePlannedDate),
                                                },
                                            })}
                                            text={employeeMedicalExaminations.next.examinationReason.title}
                                            arrow={true}
                                            button={
                                                <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                                                    <TinyLoader asyncInfoSelector={getExaminationReasonsAsyncInfo}>
                                                        <Button
                                                            id="plan-employee-button"
                                                            typeName="secondary"
                                                            size="small"
                                                            onClick={() => triggerWizardWithSelectedEmployee(
                                                                employee,
                                                                employeeMedicalExaminations.next,
                                                            )}
                                                        >
                                                            <Translate
                                                                // eslint-disable-next-line max-len
                                                                msg="administration.employees.detail.medical_examination.to_plan.plan"
                                                            />
                                                        </Button>
                                                    </TinyLoader>
                                                </ShowIfAllowed>}
                                        />
                                    )
                                    :
                                    <p>
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg="administration.employees.detail.medical_examination.to_plan.no_results"
                                        />
                                    </p>
                            }
                            <h4>
                                <Translate
                                    msg="administration.employees.detail.medical_examination.planned.title"
                                />
                            </h4>
                            {
                                employeeMedicalExaminations.hasOwnProperty('planned') &&
                                    employeeMedicalExaminations.planned.length > 0 ?
                                    renderExaminations(
                                        employee.id,
                                        plannedMedicalExaminationRouteKey,
                                        employeeMedicalExaminations.planned,
                                        selectedPlannedExaminationId,
                                        extraRoutePayload,
                                    )
                                    :
                                    <p>
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg="administration.employees.detail.medical_examination.planned.no_results"
                                        />
                                    </p>
                            }
                            <h4>
                                <Translate
                                    msg="administration.employees.detail.medical_examination.executed.title"
                                />
                            </h4>
                            {
                                employeeMedicalExaminations.hasOwnProperty('executed') &&
                                    employeeMedicalExaminations.executed.length > 0 ?
                                    renderExaminations(
                                        employee.id,
                                        executedMedicalExaminationRouteKey,
                                        employeeMedicalExaminations.executed,
                                        selectedExecutedExaminationId,
                                        extraRoutePayload,
                                    )
                                    :
                                    <p>
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg="administration.employees.detail.medical_examination.executed.no_results"
                                        />
                                    </p>
                            }
                        </> :
                        <p>
                            <Translate
                                msg="administration.employees.detail.risks.no_results"
                            />
                        </p>
                    }
                </TinyLoader>
            )}
        </TranslatorContext.Consumer>
    );
}

function renderExaminations(
    employeeId: number,
    routeKey: ROUTE_KEYS,
    examinations: IEmployeeMedicalExamination[],
    selectedExaminationId: number,
    routePayload: object = {},
) {
    return (
        <>
            {
                examinations.map((examination, index) => {
                    return (
                        <ListItem
                            id={`examination-detail-${examination.id}`}
                            key={index}
                            text={formatDateForDisplay(examination.examinationDate)}
                            to={{
                                type: routeKey,
                                payload: {
                                    ...routePayload,
                                    examinationId: examination.id,
                                    id: employeeId,
                                },
                            }}
                            selected={selectedExaminationId === examination.id}
                        />
                    );
                })
            }
        </>
    );
}

export default connect<IPrivateProps, IEmployeeMedicalExaminationsProps>({
    statePropsPerInstance: (state, publicProps) => {
        return (state) => {
            const routeKey = getRouteKey(state);
            const { examinationId } = getRoutePayload<{ examinationId: number }>(state);
            return {
                employeeMedicalExaminations: getEmployeeMedicalExaminations(state),
                selectedPlannedExaminationId:
                    routeKey === publicProps.plannedMedicalExaminationRouteKey && examinationId,
                selectedExecutedExaminationId:
                    routeKey === publicProps.executedMedicalExaminationRouteKey && examinationId,
            };
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            triggerWizardWithSelectedEmployee: (
                employee: IEmployeeDetails,
                nextMedicalExamination: IEmployeeMedicalExaminations['next'],
            ) => {
                const state = getState();

                if (!nextMedicalExamination.examinationReason.code) {
                    console.error(
                        'No examination reason found to plan in next medical examination',
                        nextMedicalExamination,
                    );
                    return;
                }

                const examinationReason = getExaminationReasonByCode(
                    state,
                    nextMedicalExamination.examinationReason.code,
                );

                if (!examinationReason) {
                    console.error(
                        'No examination reason found to plan in next medical examination',
                        nextMedicalExamination,
                    );
                    return;
                }

                const medicalCenterId = getDefaultSelectedMedicalCenterId(state);

                const medicalCenter = getCompanyMedicalCenters(state)
                    .find((item) => item.id === medicalCenterId);

                const selectedMedicalExaminationToPlan = toMedicalExaminationToPlan(
                    employee,
                    examinationReason,
                    nextMedicalExamination.id,
                );

                dispatch(skipToPlanMedicalExaminationWizardStepActions.trigger({
                    wizardPayload: {
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_MANUAL,
                        reason: examinationReason,
                    },
                    entity: {
                        searchEmployee: {
                            autoSelected: true,
                            searchValue: formatPersonName(employee),
                            selectedEmployee: employee,
                        },
                        selectTime: undefined,
                        // Parse selected medical examination to plan so
                        // planning flow uses planningRequestId
                        selectedMedicalExaminationToPlan: {
                            ...selectedMedicalExaminationToPlan,
                            employee: {
                                ...selectedMedicalExaminationToPlan.employee,
                                birthDate: employee.birthDate,
                            },
                            ...!!medicalCenter && {
                                medicalCenter,
                            },
                        },
                    },
                }));
            },
        };
    },
})(EmployeeMedicalExaminationsComp);
