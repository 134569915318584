import jwt_decode from 'jwt-decode';
import { createEpic } from '../index';
import {
    LOGIN, FORGOT_PASSWORD,
    FETCH_MY_USER_INFO,
    UPDATE_MY_USER_INFO, REQUEST_ACCOUNT,
    CREATE_ACCOUNT,
    UPDATE_MY_AVATAR,
    CHECK_SESSION_END_TIME,
    EXTEND_BACKEND_SESSION,
    UPDATE_SESSION_END_TIME,
    CLEAR_SESSION_END_TIME,
    FETCH_PERFORMANCE_DASHBOARD_ACCESS,
    NAVIGATE_TO_PERFORMANCE_DASHBOARD,
} from './types';
import {
    loginFailed,
    logoutSucceeded, logoutFailed,
    fetchMyUserInfoSucceeded, fetchMyUserInfoFailed,
    updateMyUserInfoSucceeded, updateMyUserInfoFailed, fetchMyUserInfo,
    requestAccountSucceeded, requestAccountFailed,
    createAccountSucceeded,
    createAccountFailed,
    updateMyAvatarActions,
    clearSessionEndTime,
    fetchPerformanceDashboardAccessActions,
    navigateToPerformanceDashboardAction,
    updateAuth0Session,
    forgotPasswordActions,
    loginSucceeded,
} from './actions';
import { fetchAllConstants } from '../constants/actions';
import { fetchFluVaccinesConfig } from '../intervention/fluVaccine/actions';
import {
    ILogoutPayload,
    IRequestAccountPayload,
    ICreateAccountPayload,
    IUpdateAvatarPayload,
} from '../../models/auth/authentication';
import { setUserContext, removeUserContext } from '../../utils/logging/errorReporter';
import { setCsrfToken, clearCsrfToken } from '../../utils/api/requestWrapper';
import { clearRequestCallLog } from '../../utils/api/requestLogOfSuccessfulGets';
import ROUTE_KEYS from '../../routeKeys';
import {
    isMyUserInfoDataAvailable,
    getFetchMyUserAsyncInfo,
    getRedirectToRoute,
    getPreviousUsername,
} from './selectors';
import { AsyncStatus, IInitAppActionPayload } from '../../models/general/redux';
import {
    resetCompanySelectedReducerToInitialState,
    fetchSelectedCompanySeatsAndDivisionsActions,
} from '../company/selected/actions';
import {
    getSelectedCompanyCode,
    getSelectedSeatCompanyCode,
    getSeatsAndDivisionsForSelectedCompanySeat,
} from '../company/selected/selectors';
import { fetchSmallCompanyDetail } from '../company/info/actions';
import { applicationInitializationFailed } from '../ui/form/actions';
import isApplicationInitializationError from '../../utils/api/isApplicationInitializationError';
import { publicUrl } from '../../utils/env';
import {
    clearCompanyRelatedCustomDimensions,
    logCompanyCode,
} from '../../utils/logging/analytics/eventLogger';
import { fetchCompanySituationHistoryActions } from '../preventionUnits/actions';
import Api from '../../api';
import { triggerFlashThatSessionIsAboutToExpire, dismissFlashMessage } from '../flashMessages/actions';
import { navigateTo, redirectToLogout } from '../location/actions';
import { getLocationState } from '../location/selectors';
import { isSessionWarningFlashMessageActive, getFlashMessages } from '../flashMessages/selectors';
import { FlashMessageType } from '../../models/general/flashMessages';
import sessionManager from '../../utils/auth/sessionManager';
import { INavigateToPerformanceDashboardPayload } from '../../models/general/performance';
import authRoutes from '../../views/auth/routes';
import { getBackendLocale, getLocale } from '../i18n/selectors';
import isSetString from '../../utils/core/string/isSetString';
import { IState } from '../IState';
import { IUpdateMyUserAccountInfoPayload } from '../../models/user/userAccount';

// auth0 login epic
createEpic({
    onActionType: LOGIN,
    async processMultiple({ api, getState }, dispatch, done) {
        try {
            const state = getState();

            await api.auth.auth0.redirectToAuth0({
                language: getBackendLocale(state),
                redirectUri: getReturnToLoginRedirectUrlWithSearch(state),
            });
            return done();
        } catch (error) {
            dispatch(loginFailed(error));
            return done();
        }
    },
    latest: false,
});

// auth0 login redirect epic
createEpic({
    onActionType: ROUTE_KEYS.R_LOGIN_REDIRECT,
    async processMultiple({ api, getState }, dispatch, done) {
        try {
            const state = getState();

            const authTokens = await api.auth.auth0.getTokensFromRedirect({
                language: getBackendLocale(state),
                redirectUri: getReturnToLoginRedirectUrlWithSearch(state),
            });

            const sessionData = api.auth.auth0.getSessionDataFromIdToken(
                authTokens.idToken,
                authTokens.accessToken,
                getRedirectToRoute(state),
            );

            dispatch(updateAuth0Session(sessionData));


            const { user, csrfToken } = await api.auth.authentication.login({
                auth0IdToken: sessionData.session.idToken,
            });

            const decodedAccess = jwt_decode<{
                'https://wg-ext.mensura.be/logins_count': number
            }>(sessionData.session.accessToken);
            const loginCount = decodedAccess['https://wg-ext.mensura.be/logins_count'];


            if (isSetString(user.redirectUrl) && window && window.location) {
                window.location.replace(user.redirectUrl);
            }

            const stateBeforeCompanyReset = getState();

            const prevUsername = getPreviousUsername(stateBeforeCompanyReset);

            if (prevUsername !== user.username) {
                dispatch(resetCompanySelectedReducerToInitialState());
            }

            const stateAfterCompanyReset = getState();
            const selectedSeatCompanyCode = getSelectedSeatCompanyCode(stateAfterCompanyReset);
            if (selectedSeatCompanyCode) {
                logCompanyCode({ companyCode: getSelectedCompanyCode(stateAfterCompanyReset) });
                dispatch(fetchSmallCompanyDetail({ companyCode: selectedSeatCompanyCode }));
                dispatch(fetchCompanySituationHistoryActions.trigger({}));
                dispatch(fetchPerformanceDashboardAccessActions.trigger({}));

                const seatsAndDivisions = getSeatsAndDivisionsForSelectedCompanySeat(stateAfterCompanyReset);
                if (!seatsAndDivisions || seatsAndDivisions.length <= 0) {
                    dispatch(fetchSelectedCompanySeatsAndDivisionsActions.trigger({}));
                }
            }

            setUserContext(user);
            setCsrfToken(csrfToken);
            dispatch(loginSucceeded({ user, csrfToken, loginCount }));
            dispatch(fetchAllConstants());
            dispatch(fetchFluVaccinesConfig());
            return done();
        } catch (error) {
            dispatch(loginFailed(error));
            dispatch(navigateTo(ROUTE_KEYS.R_LOGIN));
            return done();
        }
    },
    latest: false,
});

// logoutEpic
createEpic<ILogoutPayload>({
    onActionType: ROUTE_KEYS.R_LOGOUT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await doLogout({ api });

            dispatch(clearSessionEndTime());

            await api.auth.auth0.logout({ returnTo: getReturnToLoginUrlWithSearch(getState())});

            dispatch(logoutSucceeded());
        } catch (error) {
            dispatch(logoutFailed(error));
            dispatch(navigateTo(ROUTE_KEYS.R_LOGIN));
        }
        return done();
    },
    latest: false,
});

async function doLogout({ api }: { api: typeof Api }) {
    removeUserContext();

    await api.auth.authentication.logout();
    clearCsrfToken();
    clearRequestCallLog();

    clearCompanyRelatedCustomDimensions();
}

// auth0 forgot password epic
createEpic({
    onActionType: FORGOT_PASSWORD,
    async processMultiple({ api, getState }, dispatch, done) {
        try {
            const state = getState();

            await api.auth.auth0.redirectToAuth0({
                language: getBackendLocale(state),
                redirectUri: getReturnToLoginRedirectUrlWithSearch(state),
                initialScreen: 'forgotPassword', // Doesn't work in the new univseral login - only in classic version
            });
            dispatch(forgotPasswordActions.succeeded({}));
            return done();
        } catch (error) {
            dispatch(forgotPasswordActions.failed(error));
            return done();
        }
    },
    latest: false,
});

// fetchMyUserInfoEpic
createEpic<IInitAppActionPayload>({
    onActionType: FETCH_MY_USER_INFO,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const { user, csrfToken } = await api.user.me.fetchMyUserInfo();
            setUserContext(user);
            setCsrfToken(csrfToken);
            dispatch(fetchMyUserInfoSucceeded({ user, csrfToken }));
        } catch (error) {
            if (isApplicationInitializationError(action.payload, error)) {
                dispatch(applicationInitializationFailed());
            }
            dispatch(fetchMyUserInfoFailed(error));
        }
        done();
    },
    latest: false,
});

// fetchMyUserInfoEpicIfNotAvailable
createEpic({
    onActionType: ROUTE_KEYS.R_ACCOUNT_SETTINGS,
    processFilter: ({ getState }) => {
        const state = getState();
        const isBusy = getFetchMyUserAsyncInfo(state).status === AsyncStatus.Busy;
        return !isBusy && !isMyUserInfoDataAvailable(state);
    },
    processReturn: () => fetchMyUserInfo(),
    latest: false,
});

// updateMyUserInfoEpic
createEpic<IUpdateMyUserAccountInfoPayload>({
    onActionType: UPDATE_MY_USER_INFO,
    async processMultiple({ api, getState, action }, dispatch, done) {
        try {
            const state = getState();
            const locale = getLocale(state);

            await api.user.admin.updateUserInfo(action.payload, locale);
            dispatch(fetchMyUserInfo());
            dispatch(updateMyUserInfoSucceeded());
        } catch (error) {
            dispatch(updateMyUserInfoFailed(error));
        }
        done();
    },
    latest: false,
});

// requestAccountEpic
createEpic<IRequestAccountPayload>({
    onActionType: REQUEST_ACCOUNT,
    async processReturn({ action, api }) {
        try {
            const administrators = await api.auth.authentication.requestAccount(action.payload);
            return requestAccountSucceeded(administrators);
        } catch (error) {
            return requestAccountFailed(error);
        }
    },
    latest: false,
});

// createAccountEpic
createEpic<ICreateAccountPayload>({
    onActionType: CREATE_ACCOUNT,
    async processReturn({ action, getState, api }) {
        try {
            const state = getState();
            const locale = getLocale(state);

            await api.auth.authentication.createAccount(action.payload, locale);
            return createAccountSucceeded();
        } catch (error) {
            return createAccountFailed(error);
        }
    },
    latest: false,
});

// updateMyAvatarEpic
createEpic<IUpdateAvatarPayload>({
    onActionType: UPDATE_MY_AVATAR,
    async processMultiple({ action, api }, dispatch, done) {
        try {
            await api.user.me.updateMyAvatar(action.payload);
            dispatch(fetchMyUserInfo());
            dispatch(updateMyAvatarActions.succeeded({}));
        } catch (error) {
            dispatch(updateMyAvatarActions.failed(error));
        }
        done();
    },
    latest: false,
});

/* SESSION MANAGER EPICS */
// checkSessionEndTimeEpic
createEpic({
    onActionType: CHECK_SESSION_END_TIME,
    processReturn({ getState }) {
        const state = getState();

        if (sessionManager.isSessionExpired()) {
            return redirectToLogout({
                isSessionExpired: true,
                locationState: getLocationState(state),
            });
        }
        if (
            !isSessionWarningFlashMessageActive(state) &&
            sessionManager.isSessionAboutToExpire()
        ) {
            return triggerFlashThatSessionIsAboutToExpire({
                sessionEndTime: sessionManager.getSessionEndTime(),
            });
        }
        return null;
    },
    latest: false,
});

// extendBackEndSessionEpic
createEpic({
    onActionType: EXTEND_BACKEND_SESSION,
    processReturn() {
        // We extend the backend session by doing a call to BE
        // We chose the user info call because it is lightweight does not call any other webservices
        return fetchMyUserInfo();
    },
    latest: false,
});

// updateBackEndSessionEpic
createEpic({
    onActionType: UPDATE_SESSION_END_TIME,
    processMultiple({ getState }, dispatch, done) {
        getFlashMessages(getState()).forEach((message) => {
            if (message.type === FlashMessageType.SESSION_EXPIRE_WARNING) {
                dispatch(dismissFlashMessage(message));
            }
        });
        sessionManager.updateSessionEndTime();
        return done();
    },
    latest: false,
});

// clearSessionEndTimeEpic
createEpic({
    onActionType: CLEAR_SESSION_END_TIME,
    processReturn() {
        sessionManager.clearSessionEndTime();
        return null;
    },
    latest: false,
});

// fetchPerformanceDashboardAccessEpic
createEpic({
    onActionType: FETCH_PERFORMANCE_DASHBOARD_ACCESS,
    async processMultiple({ getState, api }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedCompanyCode(state);

            const access = await api.general.performance.fetchPerformanceDashboardAccess({ companyCode });
            dispatch(fetchPerformanceDashboardAccessActions.succeeded(access));
        } catch (error) {
            dispatch(fetchPerformanceDashboardAccessActions.failed(error));
        }
        done();
    },
    latest: false,
});

// navigateToPerformanceDashboardEpic
createEpic<INavigateToPerformanceDashboardPayload>({
    onActionType: NAVIGATE_TO_PERFORMANCE_DASHBOARD,
    async processMultiple({ api, getState, action }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedCompanyCode(state);

            const tokenData = await api.general.performance.fetchPerformanceDashboardToken({ companyCode });

            if (action.payload.openInNewTab) {
                window.open(tokenData.fullUrl, '_blank');
            } else {
                window.location.href = tokenData.fullUrl;
            }

            dispatch(navigateToPerformanceDashboardAction.succeeded({}));
        } catch (error) {
            dispatch(navigateToPerformanceDashboardAction.failed(error));
        }
        done();
    },
    latest: false,
});

function getReturnToLoginUrlWithSearch(state: IState) {
    const locationState = getLocationState(state);
    const search = locationState.search ? `?${locationState.search}` : '';
    return `${publicUrl}${authRoutes[ROUTE_KEYS.R_LOGIN].path}${search}`;
}

function getReturnToLoginRedirectUrlWithSearch(state: IState) {
    const locationState = getLocationState(state);
    const search = locationState.search ? `?${locationState.search}` : '';
    return `${publicUrl}${authRoutes[ROUTE_KEYS.R_LOGIN_REDIRECT].path}${search}`;
}
