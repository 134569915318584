import { FETCH_APPOINTMENTS, FETCH_NEXT_APPOINTMENT, SET_AGENDA_EXPORT_DATA } from './types';
import { createTypeActions } from '../../utils/libs/redux/createAction';
import { INextAppointment, IAppointmentsInfo } from '../../models/planning/appointments';
import { IAgendaExportData } from '../../models/planning/agenda';

export const fetchAppointmentsActions = createTypeActions<{}, IAppointmentsInfo>({
    type: FETCH_APPOINTMENTS,
});

export const fetchNextAppointmentActions = createTypeActions<{}, INextAppointment>({
    type: FETCH_NEXT_APPOINTMENT,
});

export const setAgendaExportDataActions = createTypeActions<IAgendaExportData[]>({
    type: SET_AGENDA_EXPORT_DATA,
});
