import React from 'react';
import classNames from 'classnames';
import './droppable-free-time-slot.scss';
import { IEventProps } from '../../../TimeGridEvent';
import DroppableWrapper from '../../../../dragAndDrop/DroppableWrapper';
import Translate from '../../../../../Translate';
import {
    createPrefixedDroppableId,
    getPrefixFromPrefixedDraggableId,
} from '../../../../../../../utils/libs/react-beautiful-dnd/reactBeautifulDndUtils';
import {
    DroppablePrefix,
    IDroppableFreeTimeslotData,
    DraggablePrefix,
} from '../../../../../../../models/interventions/timeslots';
import { ICalendarEvent } from '../../../../../../../models/ui/calendar';
import { connect } from '../../../../../..';
import { IActiveDraggable } from '../../../../../../../models/ui/dragAndDrop';
import { getActiveDraggable } from '../../../../../../../redux/ui/dragAndDrop/selectors';
import {
    IMedicalExaminationToAdd,
    IPlannedMedicalExamination,
} from '../../../../../../../models/interventions/medicalExaminations';
import { DEFAULT_MEDICAL_EXAMINATION_DURATION } from '../../../../../../../config/planning.config';

const CLASS_NAME = 'DroppableFreeTimeSlot';
const ALLOW_MOVE_PLANNING: boolean = true;

interface IPrivateProps {
    activeDraggable: IActiveDraggable;
    activeDraggablePrefix: DraggablePrefix;
}

function DroppableFreeTimeSlot(props: IEventProps & IPrivateProps) {
    const isDropDisabled = getIsDropDisabled(props);

    const event = props.event as ICalendarEvent<IDroppableFreeTimeslotData>;

    return (
        <DroppableWrapper
            droppableId={createPrefixedDroppableId(props.event.id, DroppablePrefix.FreeTimeslot)}
            className={`${CLASS_NAME}__wrapper`}
            isDropDisabled={isDropDisabled}
            disableMove
            render={(renderProps) => {
                const className = classNames('rbc-event', CLASS_NAME, {
                    selected: props.selected,
                    reserved: props.event.reserved,
                    'dragging-over': renderProps.dropState.isDraggingOver,
                    disabled: isDropDisabled,
                });
                return (
                    <span className={className}>
                        <span className="label">
                            <Translate msg="interventions.plan_bufferzone.calendar.free_time_slot_label" />
                            {event && event.data.amountOfFreeTimeslotsWithSameTime > 1 &&
                                ` (${event.data.amountOfFreeTimeslotsWithSameTime})`}
                        </span>
                    </span>
                );
            }}
        />
    );
}

function getIsDropDisabled(props: IEventProps & IPrivateProps) {
    const event = props.event as ICalendarEvent<IDroppableFreeTimeslotData>;

    const totalConsecutiveDurationAvailable =
        (event.data.timeCell.duration || DEFAULT_MEDICAL_EXAMINATION_DURATION) +
        event.data.minutesAvailableAfterTimeslot;

    if (props.activeDraggablePrefix === DraggablePrefix.Employee) {
        const typedActiveDraggable = props.activeDraggable as IActiveDraggable<IMedicalExaminationToAdd>;

        return !!(props.activeDraggable && (
            typedActiveDraggable.data.duration > totalConsecutiveDurationAvailable
        ));
    }

    if (props.activeDraggablePrefix === DraggablePrefix.PlannedExamination) {
        if (!ALLOW_MOVE_PLANNING) {
            return true;
        }

        const typedActiveDraggable = props.activeDraggable as IActiveDraggable<IPlannedMedicalExamination>;

        const plannedExamDuration = typedActiveDraggable.data.duration
            ? Number(typedActiveDraggable.data.duration)
            : DEFAULT_MEDICAL_EXAMINATION_DURATION;

        return !!(props.activeDraggable && (
            plannedExamDuration > totalConsecutiveDurationAvailable
        ));
    }

    return false;
}

export default connect({
    stateProps: (state) => {
        const activeDraggable = getActiveDraggable(state);
        return {
            activeDraggable,
            activeDraggablePrefix: activeDraggable && getPrefixFromPrefixedDraggableId(activeDraggable.draggableId),
        };
    },
})(DroppableFreeTimeSlot);
