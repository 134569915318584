import { EXAMINATION_REASON_CODE } from '../../../../../../../../config/navigation/interventions.config';
import { formatDateForDisplay } from '../../../../../../../../utils/formatting/formatDate';
import { formatMedicalCenterAddress } from '../../../../../../../../utils/formatting/formatAddress';
import {
    getEmployeesToPlan,
    getPlanMedicalExaminationWizardReason,
    getPlanMedicalExaminationWizardEntity,
} from '../../../../../../../../redux/medicalExamination/selectors';
import { getSelectedEmployee } from '../../../../../../../../redux/employee/info/selectors';
import { ICompanyBufferzone } from '../../../../../../../../models/admin/company';
import {
    IMedicalExaminationToPlan,
    IPlanMedicalExaminationBaseEntity,
} from '../../../../../../../../models/interventions/medicalExaminations';
import { IState } from '../../../../../../../../redux';
import { ListItem } from '../../../../../../../../models/general/list';
import {
    mapEmployeeToPlanToMedicalExaminationToAdd,
} from '../../../../../../../../utils/interventions/medicalExaminations/mapEmployeesToPlanToMedicalExaminationsToAdd';
import {
    PLAN_BUFFERZONE_WIZARD_STEP_ID,
    IPlanBufferzoneWizardEntity,
} from '../../../../../../../../models/interventions/bufferzones';
import { skipToPlanBufferzoneWizardStepActions } from '../../../../../../../../redux/intervention/bufferzones/actions';
import { updatePlanMedicalExaminationWizardEntity } from '../../../../../../../../redux/medicalExamination/actions';
import { filterEmployeesInList, toMedicalExaminationToPlan } from '../../../../automatic/EmployeesToPlan';

import { IColumnNames } from './reserved.type';

export const mapBufferzonesToListItems = (bufferzones: ICompanyBufferzone[]) => {
    return bufferzones.map((bufferzone) => {
        const listItem: ListItem<IColumnNames> = {
            id: getBufferzoneId(bufferzone),
            columns: {
                actions: null,
                date: formatDateForDisplay(bufferzone.date),
                dateSort: bufferzone.date,
                seat: bufferzone.company.name,
                companyCode: bufferzone.company.companyCode,
                freeSlots: bufferzone.medicalCenter.freeSlots,
                location: formatMedicalCenterAddress(bufferzone.medicalCenter, true),
            },
        };
        return listItem;
    });
};

export const getBufferzoneId = (bufferzone: ICompanyBufferzone) => bufferzone.planningEntityId;

export const triggerBufferzoneWizard = (bufferzone: ICompanyBufferzone, state: IState, dispatch) => {
    const selectedEmployee = getSelectedEmployee(state);
    const employeesToPlan = getEmployeesToPlan(state);
    const examinationsToPlanForEmployee = filterEmployeesInList(employeesToPlan, selectedEmployee);
    const examinationReason = getPlanMedicalExaminationWizardReason(state);
    const addedEmployees: IMedicalExaminationToPlan[] = [];

    let matchingEmployeeToPlan;
    if (examinationsToPlanForEmployee && examinationsToPlanForEmployee.length > 0) {
        const toPlanMatchedOnIdenticalReason = examinationsToPlanForEmployee
            .find((examination) => examination.examinationReason.id === examinationReason.id);
        if (toPlanMatchedOnIdenticalReason) {
            matchingEmployeeToPlan = toPlanMatchedOnIdenticalReason;
        } else if (examinationReason.code === EXAMINATION_REASON_CODE.PERIODIC_HEALTH_ASSESSMENT) {
            matchingEmployeeToPlan = examinationsToPlanForEmployee[0];
        }
    }

    if (!matchingEmployeeToPlan) {
        matchingEmployeeToPlan = toMedicalExaminationToPlan(
            selectedEmployee,
            examinationReason,
        );

        addedEmployees.push(matchingEmployeeToPlan);
    }

    dispatch(skipToPlanBufferzoneWizardStepActions.trigger({
        wizardPayload: {
            step: PLAN_BUFFERZONE_WIZARD_STEP_ID.PLANNING,
        },
        entity: {
            selectedBufferzone: {
                ...bufferzone,
                selectedFromOverview: true,
            },
            addedEmployees: [matchingEmployeeToPlan],
            employeesToPlan: [mapEmployeeToPlanToMedicalExaminationToAdd(matchingEmployeeToPlan)],
            modifiedTimeCellIds: [],
        } as IPlanBufferzoneWizardEntity,
    }));
};

export const updateWizardData = (values: Partial<IPlanMedicalExaminationBaseEntity>, state: IState, dispatch) => {
    const wizardEntity =
        getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
    const selectTimeData = wizardEntity && wizardEntity.selectTime;
    dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>({
        selectTime: {
            ...selectTimeData,
            ...values.selectTime,
            filter: selectTimeData ? {
                ...selectTimeData.filter,
                ...values.selectTime.filter,
            } : values.selectTime.filter,
        },
    }));
};
