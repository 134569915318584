import React, { PureComponent, Fragment } from 'react';
import clone from 'ramda/src/clone';
import './availability-overview-overlay.scss';
import classNames from 'classnames';
import {
    toAvailabilityTimeBlocks,
    IAvailabilityTimeBlock,
    IAvailabilityTimeBlocks,
    mapTimeBlocksToCompanyAvailabilities,
} from './availabilitiesMapper';
import {
    getFieldNameTranslationKey,
    ITimeBlocksValidationErrors,
    validateAvailabilityTimeBLocks,
    noTimeBlockDaysSelected,
} from './availabilitiesValidator';
import {
    AVAILABILITY_DAYS,
    AVILABILITY_MIN_TIME,
    AVILABILITY_MAX_TIME,
} from '../../../../../config/company/companyInfo.config';
import Translate from '../../../../common/Translate';
import Checkbox from '../../../../common/input/Checkbox';
import Button from '../../../../common/buttons/Button';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import FormFieldError from '../../../../common/forms/FormFieldError';
import TimePicker from '../../../../common/widget/DateTimePicker/TimePicker';
import {
    CompanyAvailabilityType, ICompanyAvailabilities, TCreateCompanyAvailabilities,
} from '../../../../../models/admin/companyInfo';
import Loader from '../../../../common/waiting/Loader';
import { IAsyncFieldInfo } from '../../../../../models/general/redux';
import { ITranslator } from '../../../../../models/general/i18n';
import OverlayContent from '../../../../common/modals/OverlayContent';
import GoBack from '../../../../common/navigation/GoBack';

const CLASS_NAME = 'AvailabilityOverviewOverlay';

interface IComponentPublicProps {
    id: string;
    show: boolean;
    isAlreadyWrappedInAnOverlayCurtain?: boolean;
    onCloseIntent: () => void;
    companyName: string;
    availabilityType: CompanyAvailabilityType;
    available: boolean;
    availabilities: ICompanyAvailabilities;
    onSubmitChanges: (applyFullFamily: boolean, availabilitiesToSave: TCreateCompanyAvailabilities) => void;
    saveAsyncInfo: IAsyncFieldInfo;
    translator: ITranslator;
    isCopyToAllSeatsAllowed: boolean;
}

interface IComponentState {
    prevAvailabilities: ICompanyAvailabilities;
    timeBlocks: IAvailabilityTimeBlocks;
    applyFullFamily: boolean;
    validationErrors: ITimeBlocksValidationErrors;
}

class AvailabilityOverviewOverlay extends PureComponent<IComponentPublicProps, IComponentState> {
    constructor(props: IComponentPublicProps) {
        super(props);

        this.state = {
            prevAvailabilities: props.availabilities,
            timeBlocks: toAvailabilityTimeBlocks(props.availabilities),
            applyFullFamily: false,
            validationErrors: {},
        };

        this.resetToOriginalAndClose = this.resetToOriginalAndClose.bind(this);
        this.prefixId = this.prefixId.bind(this);
        this.getClonedTimeBlocksSoThatComponentNoticesStateChange =
            this.getClonedTimeBlocksSoThatComponentNoticesStateChange.bind(this);
        this.toggleApplyFullFamily = this.toggleApplyFullFamily.bind(this);
        this.toggleTimeBlockDay = this.toggleTimeBlockDay.bind(this);
        this.setTimeOfTimeBlock = this.setTimeOfTimeBlock.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.days !== state.prevDays) {
            return {
                prevDays: props.days,
                timeBlocks: toAvailabilityTimeBlocks(props.availabilities),
            };
        }

        return null; // update nothing
    }

    public render() {
        const { show, isAlreadyWrappedInAnOverlayCurtain } = this.props;

        if (isAlreadyWrappedInAnOverlayCurtain) {
            return (
                <div className="container">
                    <GoBack
                        id="close-overlay"
                        onClick={this.resetToOriginalAndClose}
                        iconTypeName="arrow-left"
                    />
                    {this.renderOverlayContent()}
                </div>
            );
        }

        return (
            <OverlayContent
                show={show}
                onCloseIntent={this.resetToOriginalAndClose}
            >
                {this.renderOverlayContent()}
            </OverlayContent>
        );
    }

    private resetToOriginalAndClose() {
        this.props.onCloseIntent();

        this.setState({
            prevAvailabilities: this.props.availabilities,
            timeBlocks: toAvailabilityTimeBlocks(this.props.availabilities),
        });
    }

    /**
     * So that we have unique id's across the different availability types (which are displayed on the same screen)
     */
    private prefixId(suffix: string) {
        return `${this.props.availabilityType}_${suffix}`;
    }

    private renderOverlayContent() {
        const { saveAsyncInfo, isAlreadyWrappedInAnOverlayCurtain, isCopyToAllSeatsAllowed } = this.props;
        const { applyFullFamily } = this.state;

        const AvailabilityOverviewClass = classNames(CLASS_NAME, {
            [`${CLASS_NAME}--compact`]: !!isAlreadyWrappedInAnOverlayCurtain,
        });

        return (
            <div className={AvailabilityOverviewClass}>
                <Loader show={saveAsyncInfo.status} />
                {this.renderHeader()}

                {this.renderTimeBlocks()}

                {isCopyToAllSeatsAllowed &&
                    <div className={`${CLASS_NAME}__checkbox`}>
                        <Checkbox
                            name={`${this.props.id}_${this.props.availabilityType}_applyFullFamily`}
                            checked={applyFullFamily}
                            onChange={this.toggleApplyFullFamily}
                        >
                            <Translate
                                msg="administration.company_info.availability_overview.edit.checkbox_all_seats"
                            />
                        </Checkbox>
                    </div>
                }
                <div className={`${CLASS_NAME}__buttons`}>
                    <Button
                        id={this.prefixId('save')}
                        typeName="secondary"
                        onClick={this.onSaveChanges}
                    >
                        <Translate msg="administration.company_info.availability_overview.edit.actions.save" />
                    </Button>
                </div>
            </div>
        );
    }

    private renderHeader() {
        const { available, companyName, availabilityType } = this.props;
        const translationKeyPart = available ? 'available' : 'not_available';

        return (
            <>
                <h1>
                    <Translate
                        msg={`administration.company_info.availability_overview.edit.title.${translationKeyPart}`}
                        placeholders={{
                            companyName,
                        }}
                    />
                </h1>
                <p>
                    {/* eslint-disable-next-line max-len */}
                    <Translate msg={`administration.company_info.availability_overview.edit.help_text.${translationKeyPart}.${availabilityType}`} />
                </p>
            </>
        );
    }

    private renderTimeBlocks() {
        const { timeBlocks } = this.state;

        return (
            <div className={`${CLASS_NAME}__time-blocks`}>
                <div className="time-block-row labels">
                    <div className="time">
                        {/* eslint-disable-next-line max-len */}
                        <Translate msg="administration.company_info.availability_overview.edit.time_block.title" />
                    </div>
                    <div className="days">
                        {/* eslint-disable-next-line max-len */}
                        <Translate msg="administration.company_info.availability_overview.edit.active_days.title" />
                    </div>
                </div>

                {this.renderTimeBlock(timeBlocks.morning)}
                {this.renderTimeBlock(timeBlocks.afternoon)}
            </div>
        );
    }

    private renderTimeBlock(timeBlock: IAvailabilityTimeBlock) {
        const { validationErrors } = this.state;
        const { translator } = this.props;

        const blockValidationErrors = validationErrors[timeBlock.id];

        const periodTranslationKey =
            `administration.company_info.availability_overview.edit.time_block.${timeBlock.id}`;

        const noDaysSelected = noTimeBlockDaysSelected(timeBlock);

        return (
            <Fragment key={`time-block-row-${timeBlock.id}`}>
                <div
                    className={classNames('time-block-row', {
                        error: !!blockValidationErrors,
                        ['no-days-selected']: noDaysSelected,
                    })}
                >
                    <div className="period">
                        <strong>
                            <Translate msg={periodTranslationKey} />
                        </strong>
                    </div>
                    <div className="time">
                        {noDaysSelected ? (
                            <div className="no-time">
                                <Translate // eslint-disable-next-line max-len
                                    msg="administration.company_info.availability_overview.edit.no_days_selected"
                                />
                            </div>
                        ) : (
                                <>
                                    <div className="compact-label"> {/* eslint-disable-next-line max-len */}
                                        <Translate msg="administration.company_info.availability_overview.edit.time_block.title" />
                                    </div>
                                    <FloatableTextInputWrapper inline>
                                        <label htmlFor="start-time">
                                            {/* eslint-disable-next-line max-len */}
                                            <Translate msg="administration.company_info.availability_overview.edit.time_block.from" />
                                        </label>
                                        <TimePicker
                                            id={this.prefixId('start-time')}
                                            value={timeBlock.startTime}
                                            minTime={AVILABILITY_MIN_TIME}
                                            maxTime={AVILABILITY_MAX_TIME}
                                            onChange={(formattedTime) => this.setTimeOfTimeBlock(
                                                timeBlock.id,
                                                'startTime',
                                                formattedTime,
                                            )}
                                        />
                                    </FloatableTextInputWrapper>
                                    <FloatableTextInputWrapper inline>
                                        <label htmlFor="end-time"> {/* eslint-disable-next-line max-len */}
                                            <Translate msg="administration.company_info.availability_overview.edit.time_block.to" />
                                        </label>
                                        <TimePicker
                                            id={this.prefixId('end-time')}
                                            value={timeBlock.endTime}
                                            minTime={AVILABILITY_MIN_TIME}
                                            maxTime={AVILABILITY_MAX_TIME}
                                            onChange={(formattedTime) => this.setTimeOfTimeBlock(
                                                timeBlock.id,
                                                'endTime',
                                                formattedTime,
                                            )}
                                        />
                                    </FloatableTextInputWrapper>
                                </>
                            )}
                    </div>
                    <div className="days">
                        <div className="compact-label">
                            <Translate msg="administration.company_info.availability_overview.edit.active_days.title" />
                        </div>
                        {Object.keys(AVAILABILITY_DAYS).map((weekDay, index) => {
                            const dayName = AVAILABILITY_DAYS[weekDay];

                            return (
                                <Checkbox
                                    key={index}
                                    name={this.prefixId(`row-${this.props.id}-${timeBlock.id}-${dayName}`)}
                                    checked={timeBlock.enabledOnDays[weekDay]}
                                    onChange={() => this.toggleTimeBlockDay(
                                        timeBlock.id,
                                        parseInt(weekDay, 10),
                                    )}
                                >
                                    <Translate msg={`app_shell.dates.days_short.${dayName}`} />
                                </Checkbox>
                            );
                        })}
                    </div>
                </div>
                <div className={`${CLASS_NAME}__time-blocks__errors`}>
                    {blockValidationErrors && Object.keys(blockValidationErrors)
                        .map((blockFieldName) => (
                            <FormFieldError
                                key={`validationError_${timeBlock.id}_${blockFieldName}`}
                                error={blockValidationErrors[blockFieldName]}
                                placeholders={{
                                    fieldName: translator(getFieldNameTranslationKey(blockFieldName)),
                                }}
                            />
                        ))
                    }
                </div>
            </Fragment>
        );
    }

    private getClonedTimeBlocksSoThatComponentNoticesStateChange(): IAvailabilityTimeBlocks {
        return clone(this.state.timeBlocks);
    }

    private toggleApplyFullFamily() {
        const { applyFullFamily } = this.state;

        this.setState({
            applyFullFamily: !applyFullFamily,
        });
    }

    private toggleTimeBlockDay(timeBlockId: string, weekDay: number) {
        const timeBlocks = this.getClonedTimeBlocksSoThatComponentNoticesStateChange();

        const timeBlock: IAvailabilityTimeBlock = timeBlocks[timeBlockId];

        timeBlock.enabledOnDays[weekDay] = !timeBlock.enabledOnDays[weekDay];

        this.setState({
            timeBlocks,
        });
    }

    private setTimeOfTimeBlock(timeBlockId: string, timeFiledName: string, newFormattedTime: string) {
        const timeBlocks = this.getClonedTimeBlocksSoThatComponentNoticesStateChange();

        const timeBlock: IAvailabilityTimeBlock = timeBlocks[timeBlockId];

        timeBlock[timeFiledName] = newFormattedTime;

        this.setState({
            timeBlocks,
        });
    }

    private onSaveChanges() {
        const validationErrors = validateAvailabilityTimeBLocks(this.state.timeBlocks);

        this.setState({
            validationErrors,
        });

        if (Object.keys(validationErrors).length === 0) {
            this.props.onSubmitChanges(
                this.state.applyFullFamily,
                mapTimeBlocksToCompanyAvailabilities(this.state.timeBlocks),
            );
        }
    }
}

export default AvailabilityOverviewOverlay;
