import {
    TUpdateTimeslotPayload,
    IAddTimeslotPayload,
    IRemoveTimeslotPayload,
    IAutoPlanEmployeePayload,
    ITimeCell,
    IAddTimeslotByPlanningRecordIdPayload,
    IAutoPlanEmployeeSuccessPayload,
    IFetchBufferzoneTimeslotsPayload,
    IBufferzoneTimeslots,
    ITimeCellPlanningConfiguration,
    IFetchTimeCellPlanningConfigurationPayload,
} from '../../models/interventions/timeslots';
import { put, post, remove, get } from '../../utils/api/requestWrapper';
import {
    IMedicalCenterFreeSlotsPayload, IFetchMedicalCenterTimeslotsPayload, IPlannedMedicalExamination,
} from '../../models/interventions/medicalExaminations';
import { ONE_MINUTE, ONE_SECOND } from '../../utils/core/time/periodsInMillis';
import { dayOffsetFromDate, getDate } from '../../utils/core/date/getSpecificDate';
import { formatDateForBackend } from '../../utils/formatting/formatDate';

export const URL = {
    TIMESLOTS: '/medical-examinations/timeslots',
    TIMESLOT: '/medical-examinations/timeslots/{timeCellId}',
    TIMESLOT_BY_ID: '/medical-examinations/timeslots-by-id',
    AUTO_PLAN: '/medical-examinations/auto-plan',
    COMPANY_MEDICAL_CENTERS_FREESLOTS: '/medical-examinations/free-slots',
    COMPANY_MEDICAL_CENTER_TIME_CELLS: '/companies/{companyCode}/medical-examinations/time-cells/{medicalCenterId}',
    BUFFERZONE_TIMECELLS: '/companies/{companyCode}/medical-examinations/bufferzone-planning',
    TIME_CELL_PLANNING_CONFIG: '/time-cell/{planningEntityId}/planning-config',
};

export function addTimeslot(payload: IAddTimeslotPayload) {
    return post<{ planningId: number }>({
        url: URL.TIMESLOTS,
        body: {
            ...payload,
        },
        mapResponse: ({ planningId }) => ({ planningId: Number(planningId) }),
    });
}

export function addTimeslotByPlanningRecordId(payload: IAddTimeslotByPlanningRecordIdPayload) {
    return post<{ planningId: number }>({
        url: URL.TIMESLOT_BY_ID,
        body: {
            ...payload,
        },
        mapResponse: ({ planningId }) => ({ planningId: Number(planningId) }),
    });
}

export function updateTimeslot(payload: TUpdateTimeslotPayload) {
    const { timeCellId, ...requestBody } = payload;

    return put({
        url: URL.TIMESLOT,
        pathParams: {
            timeCellId,
        },
        body: {
            ...requestBody,
        },
        mapResponse: ({ planningId }) => ({ planningId: Number(planningId) }),
    });
}

export function removeTimeslot(payload: IRemoveTimeslotPayload) {
    const { timeCellId, employee, cancelDate } = payload;

    return remove({
        url: URL.TIMESLOT,
        pathParams: {
            timeCellId,
        },
        body: {
            cancelDate,
            employee,
        },
    });
}

export function autoPlanEmployees(payload: IAutoPlanEmployeePayload[]) {
    return post<IAutoPlanEmployeeSuccessPayload[]>({
        url: URL.AUTO_PLAN,
        body: payload,
        mapResponse: (response) => response.plannedEmployees,
        timeoutInMillis: 5 * ONE_MINUTE,
    });
}

export function fetchCompanyMedicalCentersFreeSlots(requestBody: IMedicalCenterFreeSlotsPayload) {
    return post({
        url: URL.COMPANY_MEDICAL_CENTERS_FREESLOTS,
        body: requestBody.medicalCenterData,
        mapResponse: (response) => response.timeCells,
    });
}

interface IFetchBufferzoneTimeslotsResponse {
    ['open-timecells']: {
        duration: number
        timeCells: ITimeCell[];
    };
    ['planned-medical-examinations']: {
        ['planned-medical-examinations']: IPlannedMedicalExamination[];
    };
}

interface IFetchCompanyMedicalCenterTimeslotsResponse {
    duration: number;
    timeCells: ITimeCell[];
}

export function fetchCompanyMedicalCenterTimeslots(
    companyCode: string,
    {
        medicalCenterId,
        examinationReasonId,
        startDate,
        endDate,
        hasCabinets,
        showOnlyReserved,
        showFullFamily = true,
        includeMainSeat,
    }: IFetchMedicalCenterTimeslotsPayload,
) {
    return get<ITimeCell[]>({
        url: URL.COMPANY_MEDICAL_CENTER_TIME_CELLS,
        pathParams: {
            companyCode,
            medicalCenterId,
        },
        queryParams: {
            startDate,
            endDate,
            examinationReasonId,
            hasCabinets,
            showOnlyReserved,
            showFullFamily,
            includeMainSeat,
        },
        mapResponse: (response: IFetchCompanyMedicalCenterTimeslotsResponse) => {
            return response.timeCells.map((timeCell) => {
                return {
                    ...timeCell,
                    duration: response.duration,
                };
            });
        },
        timeoutInMillis: 90 * ONE_SECOND,
    });
}

export function fetchBufferzoneTimeslots({
    companyCode,
    endDate,
    medicalCenterId,
    showFullFamily,
    startDate,
    startTime,
    endTime,
    duration,
    planningEntityId,
}: IFetchBufferzoneTimeslotsPayload) {
    return get<IBufferzoneTimeslots>({
        url: URL.BUFFERZONE_TIMECELLS,
        pathParams: {
            companyCode,
        },
        queryParams: {
            startDate,
            // We add 1 day to the endDate because Mensura WS filters on endDate non-inclusive
            endDate: formatDateForBackend(dayOffsetFromDate(getDate(endDate), 1)),
            medicalCenterId,
            showFullFamily,
            startTime,
            endTime,
            duration,
            planningEntityId,
        },
        mapResponse: (response: IFetchBufferzoneTimeslotsResponse) => {
            return {
                freeTimeslots: response['open-timecells'].timeCells.map((timeCell) => {
                    return {
                        ...timeCell,
                        duration: response['open-timecells'].duration,
                    };
                }),
                plannedExaminations: response['planned-medical-examinations']['planned-medical-examinations'],
            };
        },
    });
}

export function fetchTimeCellPlanningConfiguration({ planningEntityId }: IFetchTimeCellPlanningConfigurationPayload) {
    return get<ITimeCellPlanningConfiguration>({
        url: URL.TIME_CELL_PLANNING_CONFIG,
        pathParams: {
            planningEntityId,
        },
        mapResponse: (response) => response && response['planning-configuration'],
    });
}
