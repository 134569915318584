import React, { FC } from 'react';

// eslint-disable-next-line max-len
import getUniqueTypeaheadFilterValuesFromListItems from '../../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../../../common/input/CheckboxesOrTypeaheadFilter';
import StartEndDateFilter from '../../../../common/input/StartEndDateFilter';
import {
    IAdministeredVaccinationsListColumnNames,
} from '../AdministeredVaccinationsList/AdministeredVaccinationList.types';
import { TRANSLATION_PREFIX } from '../administered.const';

import { TAdministeredVaccinationsFilterContentProps } from './AdministeredVaccinationsFilters.type';

const AdministeredVaccinationsFilters: FC<TAdministeredVaccinationsFilterContentProps> = (
    { formRenderProps, masterData: allListItems },
) => {

    const employers = getUniqueTypeaheadFilterValuesFromListItems<IAdministeredVaccinationsListColumnNames>(
        allListItems,
        'employer',
        'employer',
    );

    const desccriptions = getUniqueTypeaheadFilterValuesFromListItems<IAdministeredVaccinationsListColumnNames>(
        allListItems,
        'description',
        'description',
    );

    return (
        <div>
            <CheckboxesOrTypeaheadFilter
                filterName="employer"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.employer`}
                possibleFilterItems={employers}
                actualFilterValue={formRenderProps.values.employer}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'employer',
                    newFilterValue,
                )}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="description"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.type`}
                possibleFilterItems={desccriptions}
                actualFilterValue={formRenderProps.values.type}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'type',
                    newFilterValue,
                )}
            />

            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
            />
        </div>
    );
};

export { AdministeredVaccinationsFilters };
