import React, { PureComponent } from 'react';
import './appointment-detail-dialog.scss';
import { ICourse, ICourseSessionAttendee } from '../../../../models/documentCenter/courses';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import { IExecutedCompanyVisit, IPlannedCompanyVisit } from '../../../../models/interventions/companyVisits';
import { IExecutedIntervention, IPlannedIntervention } from '../../../../models/interventions/interventions';
import {
    IExecutedMedicalExamination,
    IPlannedMedicalExaminationAppointment,
} from '../../../../models/interventions/medicalExaminations';
import { CalendarEventDataType, CalendarEventType, ICalendarEvent } from '../../../../models/ui/calendar';
import { getSelectedCompanySeat, getSelectedSeatCompanyCode } from '../../../../redux/company/selected/selectors';
import {
    fetchCourseSessionAttendeesActions,
    fetchCoursesOverviewDetailByHawActions,
} from '../../../../redux/documentCenter/courses/actions';
import {
    getCourseSessionAttendees,
    getCoursesOverviewDetail,
    getCoursesOverviewDetailByHawAsyncInfo,
    getFetchCourseSessionAttendeesAsyncInfo,
} from '../../../../redux/documentCenter/courses/selectors';
import { navigateTo } from '../../../../redux/location/actions';
import { removeTimeslot } from '../../../../redux/medicalExamination/actions';
import { fetchAppointmentsActions } from '../../../../redux/planning/actions';
import { getAppointmentsAsyncInfo } from '../../../../redux/planning/selectors';
import ROUTE_KEYS from '../../../../routeKeys';
import isEmptyString from '../../../../utils/core/string/isEmptyString';
import { formatAddress, formatMedicalCenterAddress } from '../../../../utils/formatting/formatAddress';
import { formatDateInLongFormat } from '../../../../utils/formatting/formatDate';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import isCancelExaminationAllowed from '../../../../utils/interventions/medicalExaminations/isCancelExaminationAllowed';
import connect from '../../../../utils/libs/redux/connect';
import ShowIfAllowed from '../../../auth/ShowIfAllowed';
import Button from '../../../common/buttons/Button';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import Dialog, { IDialogProps } from '../../../common/modals/Dialog';
import Translate from '../../../common/Translate';
import Loader from '../../../common/waiting/Loader';
import TinyLoader from '../../../common/waiting/TinyLoader';
import LabelInfo from '../../../common/widget/LabelInfo';
import CancelExamination from '../../../interventions/shared/CancelExamination';
import getAppointmentDetailHeaderProps from './getAppointmentDetailHeaderProps';

const CLASS_NAME = 'AppointmentDetailDialog';
const TRANSLATION_PREFIX = 'planning.agenda.appointment_detail';
const EMPTY_PLACEHOLDER = '-';

interface IAppointmentDetailDialogProps {
    show: boolean;
    selectedEvent: ICalendarEvent;
    onClose: () => void;
}

interface IComponentState {
    isDeleteDialogOpen: boolean;
    isClosing: boolean;
}

interface IPrivateProps {
    courseSessionAttendees: ICourseSessionAttendee[];
    asyncFetchInfo: IAsyncFieldInfo;
    cancelMedicalExamination: (
        medicalExamination: IPlannedMedicalExaminationAppointment,
    ) => void;
    refreshAppointmentsData: () => void;
    fetchPlannedCourseAttendees: (coursesOrganizedId: number) => void;
    courseAttendeesAsyncFetchInfo: IAsyncFieldInfo;
    navigateToCourseDetail: (nodeId: number) => void;
    courseDetailId: number;
    coursesOverviewDetailByHawAsyncInfo: IAsyncFieldInfo;
    fetchCourseDetailByHawId: (id: number) => void;
}

class AppointmentDetailDialog extends
    PureComponent<IAppointmentDetailDialogProps & IPrivateProps, IComponentState> {
    constructor(props: IPrivateProps & IAppointmentDetailDialogProps) {
        super(props);

        this.state = {
            isDeleteDialogOpen: false,
            isClosing: false,
        };

        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteCancelled = this.onDeleteCancelled.bind(this);
        this.onCancelMedicalExamination = this.onCancelMedicalExamination.bind(this);
        this.onCancelCompleted = this.onCancelCompleted.bind(this);
        this.canCancelExamination = this.canCancelExamination.bind(this);
    }

    public componentDidUpdate(prevProps: IAppointmentDetailDialogProps & IPrivateProps) {
        if (
            !prevProps.selectedEvent &&
            this.props.selectedEvent &&
            this.props.selectedEvent.type === CalendarEventType.Course
        ) {
            const data = this.props.selectedEvent.data as ICourse;
            this.props.fetchPlannedCourseAttendees(data.coursesOrganizedId);
            this.props.fetchCourseDetailByHawId(data.id);
        }
    }

    public render() {
        const { selectedEvent, show, asyncFetchInfo } = this.props;
        const { isDeleteDialogOpen } = this.state;

        const dialogProps: IDialogProps = isDeleteDialogOpen
            ? {
                show: true,
                onCloseIntent: this.onClose,
                children: (
                    <CancelExamination
                        onGoBack={this.onDeleteCancelled}
                        onCancel={this.onCancelMedicalExamination}
                        onCancelCompleted={this.onCancelCompleted}
                    />
                ),
            }
            : {
                show,
                header: getAppointmentDetailHeaderProps({ selectedEvent }),
                onCloseIntent: this.onClose,
                children: (
                    <div className={CLASS_NAME}>
                        <Loader show={asyncFetchInfo.status}>
                            {this.renderContent()}
                        </Loader>
                    </div>
                ),
            };

        return (
            <Dialog {...dialogProps} />
        );
    }

    private renderContent() {
        const {
            selectedEvent, asyncFetchInfo, courseAttendeesAsyncFetchInfo, courseSessionAttendees,
            navigateToCourseDetail,
        } = this.props;

        const { isClosing } = this.state;

        if (asyncFetchInfo.error) {
            return <ErrorPlaceholder apiError={asyncFetchInfo.error} />;
        }

        // When the dialog is in closing transition, we don't have a selected event anymore
        // so to prevent displaying this error, we added this state prop "isClosing".
        if (!selectedEvent && asyncFetchInfo.status === AsyncStatus.Success && !isClosing) {
            return <ErrorPlaceholder translationKey={getPrefixedTranslation('appointment_not_found')} />;
        }

        if (!selectedEvent) {
            return null;
        }

        if (selectedEvent.dataType === CalendarEventDataType.PlannedMedicalExamination) {
            const data = selectedEvent.data as IPlannedMedicalExaminationAppointment;

            const isExaminationCancellable = (code) => {
                const blockedExaminations = [
                    'REINT',
                    'REINTOPV',
                    'REINTTEL',
                    'ART34',
                    'ART34OPV',
                    'ART34TEL',
                    'ART34VWD',
                    'ART34ADM',
                    'REINTADM',
                    'REINTCON',
                ];
                return blockedExaminations.indexOf(code) === -1;
            };

            return (
                <>
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.where')}
                        info={formatMedicalCenterAddress(data.medicalCenter, true)}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.who')}
                        info={formatPersonName(data.employee)}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.examination_reason')}
                        info={data.examinationReason && data.examinationReason.title}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.doctor')}
                        info={data.doctor && `${data.doctor.lastName} ${data.doctor.firstName}`}
                        hideColon={true}
                    />
                    {isExaminationCancellable(data.examinationReason.code) &&
                    <div className={`${CLASS_NAME}__actions`}>
                        {this.canCancelExamination() &&
                            <ShowIfAllowed requiredAccessLevels={{ planning: 'W' }}>
                                <Button
                                    id="cancel-medical-examination"
                                    typeName="secondary"
                                    outline={true}
                                    onClick={this.onDelete}
                                >
                                    <Translate msg={getPrefixedTranslation('actions.cancel_examination')} />
                                </Button>
                            </ShowIfAllowed>
                        }
                    </div>
                    }
                </>
            );
        }

        if (selectedEvent.dataType === CalendarEventDataType.ExecutedMedicalExamination) {
            const data = selectedEvent.data as IExecutedMedicalExamination;
            return (
                <>
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.where')}
                        info={data.medicalCenter.name}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.who')}
                        info={formatPersonName(data.employee)}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.examination_reason')}
                        info={data.examinationReason.title}
                        hideColon={true}
                    />
                    {!isEmptyString(data.conclusion) && <LabelInfo
                        labelKey={getPrefixedTranslation('labels.examination_result.conclusion')}
                        info={data.conclusion}
                        hideColon={true}
                    />}
                    {!isEmptyString(data.conclusionSigns) && <LabelInfo
                        labelKey={getPrefixedTranslation('labels.examination_result.conclusion_signs')}
                        info={data.conclusionSigns}
                        hideColon={true}
                    />}
                    {(data.validUntil && !isEmptyString(data.validUntil)) && <LabelInfo
                        labelKey={getPrefixedTranslation('labels.examination_result.attest')}
                        info={formatDateInLongFormat(data.validUntil)}
                        hideColon={true}
                    />}
                    {!isEmptyString(data.conclusionPregnancySentences) && <LabelInfo
                        labelKey={getPrefixedTranslation('labels.examination_result.pregnancy')}
                        info={data.conclusionPregnancySentences}
                        hideColon={true}
                    />}
                </>
            );
        }

        if (
            selectedEvent.dataType === CalendarEventDataType.ExecutedCompanyVisit ||
            selectedEvent.dataType === CalendarEventDataType.PlannedCompanyVisit
        ) {
            const data = selectedEvent.data as IExecutedCompanyVisit | IPlannedCompanyVisit;
            return (
                <>
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.where')}
                        info={data.company.name}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.who')}
                        info={formatPersonName(data.employee)}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.company_visit_reason')}
                        info={data.visitKind}
                        hideColon={true}
                    />
                </>
            );
        }

        if (selectedEvent.dataType === CalendarEventDataType.ExecutedIntervention) {
            const data = selectedEvent.data as IExecutedIntervention;
            const presentationCodeTranslationPrefix = getPrefixedTranslation('intervention_types');
            return (
                <>
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.where')}
                        info={data.company.name}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.who')}
                        info={formatPersonName({ name: data.executedByLastName, firstName: data.executedByFirstName })}
                        hideColon={true}
                    />
                    {!isEmptyString(data.prestationCode) && <LabelInfo
                        labelKey={getPrefixedTranslation('labels.intervention_type')}
                        info={<Translate
                            msg={`${presentationCodeTranslationPrefix}.${data.prestationCode.toLowerCase()}`}
                        />}
                        hideColon={true}
                    />}
                </>
            );
        }

        if (selectedEvent.dataType === CalendarEventDataType.PlannedIntervention) {
            const data = selectedEvent.data as IPlannedIntervention;
            const presentationCodeTranslationPrefix = getPrefixedTranslation('intervention_types');
            return (
                <>
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.where')}
                        info={data.company.name}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.who')}
                        info={formatPersonName({ name: data.employee.name, firstName: data.employee.firstName })}
                        hideColon={true}
                    />
                    {data.prestationCode && !isEmptyString(data.prestationCode) && <LabelInfo
                        labelKey={getPrefixedTranslation('labels.intervention_type')}
                        info={<Translate
                            msg={`${presentationCodeTranslationPrefix}.${data.prestationCode.toLowerCase()}`}
                        />}
                        hideColon={true}
                    />}
                </>
            );
        }

        if (selectedEvent.dataType === CalendarEventDataType.Course) {
            const data = selectedEvent.data as ICourse;
            return (
                <Loader
                    show={courseAttendeesAsyncFetchInfo.status}
                >
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.where')}
                        info={data.address ? formatAddress(data.address) : EMPTY_PLACEHOLDER}
                        isInfoMultiLine={true}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.course.coordinator')}
                        info={data.coordinator}
                        hideColon={true}
                    />
                    <LabelInfo
                        labelKey={getPrefixedTranslation('labels.who')}
                        info={this.concatenateAttendees(courseSessionAttendees)}
                        isInfoMultiLine={true}
                        hideColon={true}
                    />
                    <div className={`${CLASS_NAME}__actions`}>
                            <TinyLoader
                                asyncInfoSelector={this.props.coursesOverviewDetailByHawAsyncInfo}
                                hideErrorOnError
                            >
                                {this.props.courseDetailId && (
                                    <Button
                                        id="course-appointment-to-detail-button"
                                        onClick={() => navigateToCourseDetail(this.props.courseDetailId)}
                                        typeName="secondary"
                                    >
                                        <Translate msg={getPrefixedTranslation('labels.course.to_info')} />
                                    </Button>
                                )}
                            </TinyLoader>
                    </div>
                </Loader>
            );
        }

        return <>Not implemented</>;
    }

    private concatenateAttendees(attendees: ICourseSessionAttendee[]) {
        if (attendees.length <= 0) {
            return '';
        }
        return attendees.reduce(
            (accumulator, attendee) => {
                return accumulator + `${formatPersonName(attendee)}, `;
            },
            '',
        ).slice(0, -2);
    }

    private onClose() {
        this.setState({
            isDeleteDialogOpen: false,
            isClosing: true,
        });
        this.props.onClose();
    }

    private onDelete() {
        this.setState({ isDeleteDialogOpen: true });
    }

    private onDeleteCancelled() {
        this.setState({ isDeleteDialogOpen: false });
    }

    private onCancelMedicalExamination() {
        if (this.canCancelExamination) {
            const { cancelMedicalExamination, selectedEvent } = this.props;
            const medicalExamination = selectedEvent.data as IPlannedMedicalExaminationAppointment;
            cancelMedicalExamination(medicalExamination);
        }
    }

    private onCancelCompleted() {
        this.setState({ isDeleteDialogOpen: false });
        this.props.refreshAppointmentsData();
        this.props.onClose();
    }

    private canCancelExamination() {
        const { selectedEvent } = this.props;
        const medicalExamination = selectedEvent.data as IPlannedMedicalExaminationAppointment;

        return medicalExamination && isCancelExaminationAllowed(medicalExamination.startTime);
    }
}

export default connect<IPrivateProps, IAppointmentDetailDialogProps>({
    stateProps: (state) => {
        const coursesOverviewDetailByHawAsyncInfo = getCoursesOverviewDetailByHawAsyncInfo(state);
        const coursesOverviewDetail = getCoursesOverviewDetail(state);
        const courseDetailId = coursesOverviewDetailByHawAsyncInfo.status === AsyncStatus.Success
            && coursesOverviewDetail
            && coursesOverviewDetail.course.nodeId;

        return {
            asyncFetchInfo: getAppointmentsAsyncInfo(state),
            courseSessionAttendees: getCourseSessionAttendees(state),
            courseAttendeesAsyncFetchInfo: getFetchCourseSessionAttendeesAsyncInfo(state),
            courseDetailId,
            coursesOverviewDetailByHawAsyncInfo,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            cancelMedicalExamination: (
                medicalExamination: IPlannedMedicalExaminationAppointment,
            ) => {
                dispatch(removeTimeslot({
                    timeCellId: medicalExamination.timeCellId,
                    employee: {
                        id: medicalExamination.employee && medicalExamination.employee.id,
                    },
                    cancelDate: medicalExamination.startTime,
                }));
            },
            refreshAppointmentsData: () => {
                dispatch(fetchAppointmentsActions.trigger({}));
            },
            navigateToCourseDetail: (nodeId: number) => {
                dispatch(navigateTo(ROUTE_KEYS.R_COURSES_DETAIL_INTRO, {
                    nodeId,
                }));
            },
            fetchPlannedCourseAttendees: (coursesOrganizedId: number) => {
                const state = getState();
                const companyCode = getSelectedSeatCompanyCode(state);
                const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

                dispatch(fetchCourseSessionAttendeesActions.trigger({
                    companyCode,
                    showFullFamily,
                    coursesOrganizedId,
                }));
            },
            fetchCourseDetailByHawId: (id: number) => {
                dispatch(fetchCoursesOverviewDetailByHawActions.trigger({ id }));
            },
        };
    },
})(AppointmentDetailDialog);

function getPrefixedTranslation(suffix: string) {
    return `${TRANSLATION_PREFIX}.${suffix}`;
}
