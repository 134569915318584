import React from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import SelectBufferzoneOverview, { IContentValues } from './overview';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import { getPlanBufferzoneWizardEntity } from '../../../../../redux/intervention/bufferzones/selectors';
import { updatePlanBufferzoneWizardEntity } from '../../../../../redux/intervention/bufferzones/actions';
import GenericWizardStep from '../../../../common/navigation/WizardStep';

const CLASS_NAME = 'PlanMedicalExamination__SelectBufferzone';

const SelectBufferzoneWizardStep = GenericWizardStep<IContentValues>();

export default function SelectBufferzone(stepperStepRenderProps: IStepperStepRenderProps) {
    return (
        <>
            <SelectBufferzoneWizardStep
                {...stepperStepRenderProps}
                baseClassName={CLASS_NAME}
                header={{
                    titleTranslationKey: 'interventions.plan_bufferzone.steps.select_bufferzone.title',
                }}
                content={{
                    initialValuesSelector: (state) => {
                        const wizardEntity = getPlanBufferzoneWizardEntity(state);

                        return {
                            selectedBufferzone: wizardEntity && wizardEntity.selectedBufferzone,
                        };
                    },
                    renderStepContent: (renderProps) =>
                        <SelectBufferzoneOverview {...renderProps} />,
                    onChangeInputActionCreator: ({ values }) =>
                        updatePlanBufferzoneWizardEntity({
                            selectedBufferzone: values.selectedBufferzone,
                        }),
                }}
                stepButtons={{
                    shouldDisableNextButton: (values) => !isBufferzoneSelected(values),
                    disbaleGoToNextStepByDefault: false,
                }}
            />
        </>
    );
}

export function isBufferzoneSelected(values: IContentValues) {
    return isSet(values.selectedBufferzone);
}
