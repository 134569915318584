import { joinParts } from './formatHelper';
import { IAddress, IAddressSave } from '../../models/general/address';
import { ICMSCourseAddress } from '../../models/documentCenter/courses';
import { TExaminationMedicalCenter } from '../../models/interventions/medicalExaminations';
import { ICompanyBufferzoneMedicalCenter } from '../../models/admin/company';
import { IMedicalCenter } from '../../models/admin/employee';
import { ILocation } from '../../models/general/locations';

type TAddressStreet = Pick<IAddress, 'street' | 'box'> & { number?: string };
type TAddressCity = Pick<IAddress, 'postcode' | 'city'>;

export function formatAddressStreet(address: TAddressStreet) {
    const { street, number, box } = address;
    const formattedBox = box ? `- ${box}` : '';
    return joinParts([street, number, formattedBox]);
}

export function formatAddressCity(address: TAddressCity | IAddressSave | ICMSCourseAddress) {
    const { postcode, city } = address;
    return joinParts([postcode, city]);
}

export function formatAddress(
    address: TAddressStreet & TAddressCity | IAddressSave | ICMSCourseAddress, onOneLine: boolean = false) {
    return joinParts(
        [
            formatAddressStreet(address),
            formatAddressCity(address),
        ],
        onOneLine ? ', ' : '\n',
    );
}

export function formatMedicalCenterAddress(
    medicalCenter: TExaminationMedicalCenter | ICompanyBufferzoneMedicalCenter | IMedicalCenter,
    onOneLine: boolean = false,
) {
    if (!medicalCenter.address) {
        return medicalCenter.name;
    }

    return joinParts(
        [
            medicalCenter.name,
            formatAddressStreet(medicalCenter.address),
            formatAddressCity(medicalCenter.address),
        ],
        onOneLine ? ', ' : '\n',
    );
}

export function formatCourseLocation(
    address: ICMSCourseAddress,
) {
    const unknownAddress = ' - ';

    const checkedStreet = (!address || (address.street === (null || ''))) ? ' ' : address.street;
    const checkedNumber = (!address || (address.number === (null || ''))) ? ' ' : address.number;
    const checkedPostcode = (!address || (address.postcode === (null || ''))) ? ' ' : address.postcode;
    const checkedCity = (!address || (address.city === (null || ''))) ? ' ' : address.city;


    if (!!address && (address !== (null || undefined))){
        if (checkedPostcode === '9999') {
            return joinParts([checkedPostcode, checkedCity]);
        }

        if (
            checkedStreet === ' '
            && checkedCity === ' '
            && checkedNumber === ' '
            && checkedPostcode === ' '
        ) {
            return unknownAddress;
        }

        return joinParts([checkedStreet, checkedNumber, ', ', checkedPostcode, checkedCity]);
    } return unknownAddress;
}

export const formatLocationAddress = (
    location: ILocation,
    onOneLine: boolean = false,
): string => {
    if (!location.address || location.address.street === '' || !location.address.street) {
        return location.name;
    }

    return joinParts(
        [
            location.name,
            formatAddressStreet({
                street: location.address.street,
                number: location.address.houseNumber,
                box: location.address.box,
            }),
            formatAddressCity({
                postcode: location.address.zipCode,
                city: location.address.city,
            }),
        ],
        onOneLine ? ', ' : '\n',
    );
};

export function formatAddressWithName(address: ICMSCourseAddress, onOneLine: boolean = false) {
    if (address.name) {
        return formatAddress(address, onOneLine) + ` (${address.name})`;
    }
    return formatAddress(address, onOneLine);
}

export function mapAddressToGoogleMapsUrl(address: Pick<IAddress, 'street' | 'number' | 'postcode' | 'city'>) {
    const { street, number, postcode, city } = address;

    const encodedAddressQuery = joinParts(
        [
            `${encodeURIComponent(street)}+${encodeURIComponent(number)}`,
            `+${encodeURIComponent(postcode)}+${encodeURIComponent(city)}`,
        ],
        ',',
    );

    return `https://www.google.com/maps/search/?api=1&query=${encodedAddressQuery}`;
}
