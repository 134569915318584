import { ITimeCell, IDroppableFreeTimeslotData } from '../interventions/timeslots';
import { IExecutedIntervention, IPlannedIntervention } from '../interventions/interventions';
import {
    IPlannedMedicalExamination,
    IExecutedMedicalExamination,
    IPlannedMedicalExaminationAppointment,
} from '../interventions/medicalExaminations';
import { IPlannedCompanyVisit, IExecutedCompanyVisit } from '../interventions/companyVisits';
import { ICourse } from '../documentCenter/courses';

export enum CalendarEventDataType {
    ExecutedCompanyVisit,
    ExecutedIntervention,
    ExecutedMedicalExamination,
    PlannedCompanyVisit,
    PlannedIntervention,
    PlannedMedicalExamination,
    DraggablePlannedMedicalExamination,
    TimeCell,
    Course,
    DroppableFreeTimeSlot,
}

type TCalendarEventData =
    // Used for planning
    IPlannedMedicalExamination[] | ITimeCell[] |
    // Used for draggable (bufferzone) planning
    IPlannedMedicalExamination | IDroppableFreeTimeslotData |
    // Used for agenda
    IPlannedMedicalExaminationAppointment | IExecutedMedicalExamination |
    IPlannedIntervention | IExecutedIntervention |
    IPlannedCompanyVisit | IExecutedCompanyVisit | ICourse;

export interface ICalendarEvent<Data = TCalendarEventData> {
    id: string;
    start: Date;
    end: Date;
    allDay: boolean;
    type: CalendarEventType;
    dataType: CalendarEventDataType;
    data?: Data;
    isConcatenated: boolean;
    title?: string;
    overlappingEventsCount?: number;
    reserved?: boolean;
    afterNoon?: boolean;
}

export type TExecutedCompanyVisitCalendarEvent = ICalendarEvent<IExecutedMedicalExamination>;
export type TExecutedInterventionCalendarEvent = ICalendarEvent<IExecutedIntervention>;
export type TExecutedMedicalExaminationCalendarEvent = ICalendarEvent<IExecutedMedicalExamination>;
export type TPlannedCompanyVisitCalendarEvent = ICalendarEvent<IPlannedCompanyVisit>;
export type TPlannedInterventionCalendarEvent = ICalendarEvent<IPlannedIntervention>;
export type TPlannedMedicalExaminationCalendarEvent = ICalendarEvent<IPlannedMedicalExaminationAppointment>;

export enum CalendarEventType {
    Course = 'course',
    MedicalExamination = 'medicalExamination',
    MedicalExaminationInCompany = 'medicalExaminationInCompany',
    CompanyVisit = 'companyVisit',
    Intervention = 'intervention',
    FreeTimeslot = 'freeTimeslot',
    DroppableFreeTimeslot = 'droppableFreeTimeslot',
    DraggableMedicalExamination = 'draggableMedicalExamination',
}
