import {
    FETCH_MEDICAL_EXAMINATIONS_TO_PLAN,
    FETCH_PLANNED_MEDICAL_EXAMINATIONS,
    FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL,
    FETCH_EXAMINATION_REASONS,
    UPDATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    FETCH_EMPLOYEES_TO_PLAN,
    FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
    FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL,
    UPDATE_TIMESLOT,
    AUTO_PLAN_EMPLOYEES,
    REMOVE_TIMESLOT,
    REMOVE_EXAMINATION_TO_ADD_FROM_ENTITY,
    FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS,
    FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP,
    RESET_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    REMOVE_UPLOADED_FILE_FROM_RE_INTEGRATION_ENTITY,
    CREATE_RE_INTEGRATION_REQUEST,
    ADD_TIMESLOT,
    FETCH_MEDICAL_EXAMINATION_DOCUMENTS,
    CREATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES,
    FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
    REMOVE_UPLOADED_WORK_POST_CARD_FROM_INTERIM_ENTITY,
    VALIDATE_EMPLOYEE_TO_PLAN,
    REQUEST_MEDICAL_EXAMINATION,
    FETCH_TIME_CELL_PLANNING_CONFIG,
} from './types';
import { createAction, createSuccessAction, createFailAction } from '../index';
import {
    IMedicalExaminationToPlan,
    IPlannedMedicalExamination,
    IExaminationReason,
    IPlanMedicalExaminationWizardPayload,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    IExecutedMedicalExamination,
    IMedicalExaminationToAdd,
    IMedicalCenterFreeSlotsPayload,
    IMedicalCentersFreeSlotsSuccessPayload,
    IFetchMedicalExaminationDetailPayload,
    IExecutedMedicalExaminationsFilter,
    IFetchMedicalCenterTimeslotsPayload,
    IFetchMedicalExaminationDocumentsPayload,
    IMedicalExaminationDocuments,
    ISkipToPlanMedicalExaminationWizardStepPayload,
    IFetchNoShowsMedicalExaminationsPayload,
    IRequestReIntegrationPayload,
    IValidateEmployeeToPlanPayload,
    IValidateEmployeeToPlanData,
    IRequestMedicalExaminationPayload,
    IPlannedMedicalExaminationsBackendFilter,
} from '../../models/interventions/medicalExaminations';
import {
    IRemoveTimeslotPayload,
    TUpdateTimeslotPayload, IReplaceEmployeeUpdateTimeslotPayload, IMoveUpdateTimeslotPayload,
    IAutoPlanEmployeeSuccessPayload,
    IAutoPlanEmployeePayload,
    ITimeCell,
    IAddTimeslotPayload,
    IAddTimeslotByPlanningRecordIdPayload,
    IAddTimeslotSuccessPayload,
    IUpdateTimeslotSuccessPayload,
    IFetchTimeCellPlanningConfigurationPayload,
} from '../../models/interventions/timeslots';
import { ITraceableApiError } from '../../models/general/error';
import ROUTE_KEYS from '../../routeKeys';
import { navigateTo, redirectToRoute } from '../location/actions';
import { createResetAction, createTypeActions } from '../../utils/libs/redux/createAction';
import { ITraceableAsyncRequestPayload } from '../../models/general/redux';
import { getPlanMedicalExaminatonWizardSteps } from '../../config/navigation/wizardStepsMap';

// To plan medical examinations
export function fetchMedicalExaminationsToPlan() {
    return createAction(FETCH_MEDICAL_EXAMINATIONS_TO_PLAN, {});
}

export function fetchMedicalExaminationsToPlanSucceeded(payload: IMedicalExaminationToPlan[]) {
    return createSuccessAction<IMedicalExaminationToPlan[]>(FETCH_MEDICAL_EXAMINATIONS_TO_PLAN, payload);
}

export function fetchMedicalExaminationsToPlanFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_MEDICAL_EXAMINATIONS_TO_PLAN, error);
}

// Planned medical examinations
export const fetchPlannedMedicalExaminationsActions =
    createTypeActions<IPlannedMedicalExaminationsBackendFilter, IPlannedMedicalExamination[]>({
        type: FETCH_PLANNED_MEDICAL_EXAMINATIONS,
    });

// Planned medical examination detail
export function fetchPlannedMedicalExaminationDetail(payload: IFetchMedicalExaminationDetailPayload) {
    return createAction<IFetchMedicalExaminationDetailPayload>(FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchPlannedMedicalExaminationDetailSucceeded(payload: IPlannedMedicalExamination) {
    return createSuccessAction<IPlannedMedicalExamination>(FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchPlannedMedicalExaminationDetailFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL, error);
}

// Executed medical examinations
export const fetchExecutedMedicalExaminationsActions =
    createTypeActions<IExecutedMedicalExaminationsFilter, IExecutedMedicalExamination[]>({
        type: FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
    });

// Executed medical examination detail
export function fetchExecutedMedicalExaminationDetail(payload: IFetchMedicalExaminationDetailPayload) {
    return createAction<IFetchMedicalExaminationDetailPayload>(FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchExecutedMedicalExaminationDetailSucceeded(payload: IExecutedMedicalExamination) {
    return createSuccessAction<IExecutedMedicalExamination>(FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL, payload);
}

export function fetchExecutedMedicalExaminationDetailFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL, error);
}

// Examination reasons
export function fetchExaminationReasons() {
    return createAction(FETCH_EXAMINATION_REASONS, {});
}

export function fetchExaminationReasonsSucceeded(payload: IExaminationReason[]) {
    return createSuccessAction<IExaminationReason[]>(FETCH_EXAMINATION_REASONS, payload);
}

export function fetchExaminationReasonsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EXAMINATION_REASONS, error);
}

// Plan wizard
export function navigateToPlanMedicalExaminationWizardStep(
    payload: IPlanMedicalExaminationWizardPayload<PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE>,
    isRedirect: boolean = false,
) {
    const wizardPayload = {
        ...payload,
        step: payload.step || getFirstPlanMedExamStepByWizardType(payload.wizardType),
    };

    if (isRedirect) {
        return redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD, wizardPayload);
    }

    return navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD, wizardPayload);
}

function getFirstPlanMedExamStepByWizardType(wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE) {
    return getPlanMedicalExaminatonWizardSteps(wizardType).firstStepId;
}

export function updatePlanMedicalExaminationWizardEntity<Entity>(payload: Partial<Entity>) {
    return createAction<object>(
        UPDATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
        payload,
    );
}

export function createPlanMedicalExaminationWizardEntity<Entity>(payload: Partial<Entity>) {
    return createAction<object>(
        CREATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
        payload,
    );
}

export function removeUploadedFileFromReIntegrationEntity<Entity>() {
    return createAction<object>(
        REMOVE_UPLOADED_FILE_FROM_RE_INTEGRATION_ENTITY,
        {},
    );
}

export function removeUploadedWorkPostCardFromInterimEntity<Entity>() {
    return createAction<object>(
        REMOVE_UPLOADED_WORK_POST_CARD_FROM_INTERIM_ENTITY,
        {},
    );
}

export function resetPlanMedicalExaminationWizardEntity() {
    return createAction(RESET_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY, {});
}

export function removeExaminationToAddFromEntity(payload: IMedicalExaminationToAdd) {
    return createAction<IMedicalExaminationToAdd>(REMOVE_EXAMINATION_TO_ADD_FROM_ENTITY, payload);
}

// Employees to plan

interface IFetchEmployeeToPlanPayload {
    companyCode: string;
    allowedExaminationReasonIds?: number[];
}

export function fetchEmployeesToPlan(payload: IFetchEmployeeToPlanPayload) {
    return createAction<IFetchEmployeeToPlanPayload>(FETCH_EMPLOYEES_TO_PLAN, payload);
}

export function fetchEmployeesToPlanSucceeded(payload: IMedicalExaminationToPlan[]) {
    return createSuccessAction<IMedicalExaminationToPlan[]>(FETCH_EMPLOYEES_TO_PLAN, payload);
}

export function fetchEmployeesToPlanFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEES_TO_PLAN, error);
}

export const skipToPlanMedicalExaminationWizardStepActions =
    createTypeActions<ISkipToPlanMedicalExaminationWizardStepPayload>({
        type: SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP,
    });

// Timeslots
export const addTimeslotActions =
    createTypeActions<
        IAddTimeslotPayload | IAddTimeslotByPlanningRecordIdPayload,
        IAddTimeslotSuccessPayload>({ type: ADD_TIMESLOT });

export const updateTimeslotActions = createTypeActions<TUpdateTimeslotPayload,
    IUpdateTimeslotSuccessPayload>({ type: UPDATE_TIMESLOT });

export function triggerReplaceEmployeeUpdateTimeslot(payload: IReplaceEmployeeUpdateTimeslotPayload) {
    return updateTimeslotActions.trigger(payload);
}

export function triggerMoveUpdateTimeslot(payload: IMoveUpdateTimeslotPayload) {
    return updateTimeslotActions.trigger(payload);
}

export function removeTimeslot(payload: IRemoveTimeslotPayload) {
    return createAction<IRemoveTimeslotPayload>(REMOVE_TIMESLOT, payload);
}

export function removeTimeslotSucceeded() {
    return createSuccessAction(REMOVE_TIMESLOT, {});
}

export function removeTimeslotFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(REMOVE_TIMESLOT, error);
}

export function removeTimeslotReset() {
    return createResetAction(REMOVE_TIMESLOT, {});
}

// Auto plan
export function autoPlanEmployees(payload: IAutoPlanEmployeePayload[]) {
    return createAction<IAutoPlanEmployeePayload[]>(AUTO_PLAN_EMPLOYEES, payload);
}

export function autoPlanEmployeesSucceeded(payload: IAutoPlanEmployeeSuccessPayload[]) {
    return createSuccessAction<IAutoPlanEmployeeSuccessPayload[]>(AUTO_PLAN_EMPLOYEES, payload);
}

export function autoPlanEmployeesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(AUTO_PLAN_EMPLOYEES, error);
}

export function autoPlanEmployeesReset() {
    return createResetAction(AUTO_PLAN_EMPLOYEES, {});
}

// Company medical centers freeSlots

export function fetchCompanyMedicalCentersFreeSlots(payload: IMedicalCenterFreeSlotsPayload) {
    return createAction<IMedicalCenterFreeSlotsPayload>(FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS, payload);
}

export function fetchCompanyMedicalCentersFreeSlotsSucceeded(payload: IMedicalCentersFreeSlotsSuccessPayload) {
    return createSuccessAction<IMedicalCentersFreeSlotsSuccessPayload>(
        FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS, payload,
    );
}

export function fetchCompanyMedicalCentersFreeSlotsFailed(error: ITraceableApiError & ITraceableAsyncRequestPayload) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS, error);
}

// Company medical center timeslot

export const fetchCompanyMedicalCenterTimeslotsActions =
    createTypeActions<IFetchMedicalCenterTimeslotsPayload, ITimeCell[]>({
        type: FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    });

// Request re-integration
export const createReIntegrationRequestActions = createTypeActions<IRequestReIntegrationPayload>({
    type: CREATE_RE_INTEGRATION_REQUEST,
});

// Executed examination documents
export const fetchMedicalExaminationDocumentsActions =
    createTypeActions<IFetchMedicalExaminationDocumentsPayload, IMedicalExaminationDocuments>({
        type: FETCH_MEDICAL_EXAMINATION_DOCUMENTS,
    });

// Executed examination documents and files
export const fetchExaminationDocumentsAndFilesActions =
    createTypeActions<IFetchMedicalExaminationDocumentsPayload>({
        type: FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES,
    });

// No shows medical examinations
export const fetchNoShowsExaminationsActions =
    createTypeActions<IFetchNoShowsMedicalExaminationsPayload>({
        type: FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
    });

// Validate employee to plan
export const validateEmployeeToPlanActions =
    createTypeActions<IValidateEmployeeToPlanPayload, IValidateEmployeeToPlanData>({
        type: VALIDATE_EMPLOYEE_TO_PLAN,
    });

// Request medical examination
export const requestMedicalExaminationActions =
    createTypeActions<IRequestMedicalExaminationPayload>({
        type: REQUEST_MEDICAL_EXAMINATION,
    });

export const fetchTimeCellPlanningConfigurationActions =
    createTypeActions<IFetchTimeCellPlanningConfigurationPayload>({
        type: FETCH_TIME_CELL_PLANNING_CONFIG,
    });
