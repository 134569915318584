import React from 'react';
import './agenda.scss';
import FilterableCalendar from '../../common/widget/FilterableCalendar';
import { getAppointmentsAsyncInfo, getAppointments } from '../../../redux/planning/selectors';
import connect from '../../../utils/libs/redux/connect';
import { getQueryParams, getRoutePayload, getRouteKey } from '../../../redux/location/selectors';
import { ICalendarEvent } from '../../../models/ui/calendar';
import { navigateTo } from '../../../redux/location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import AgendaFilterActions from './AgendaFilterActions';
import AppointmentDetailDialog from './AppointmentDetailDialog';
import { formatDateForBackend } from '../../../utils/formatting/formatDate';
import PageHeader from '../../appShell/PageHeader';
import SearchContent from './SearchContent';
import { View } from 'react-big-calendar';
import { IAgendaQueryParams, IAgendaRoutePayload } from '../../../models/planning/agenda';
import ListActionButton from '../../common/buttons/ListActionButton';
import AgendaExportButton from './AgendaExportButton';
import { getDefaultSearchDates } from '../../../config/calendar.config';

const CLASS_NAME = 'Agenda';

interface IPrivateProps {
    selectedDate: string;
    selectedEvent: ICalendarEvent;
    isSearching: boolean;
    listViewSelected: boolean;
    onSelectedDateChanged: (selectedDate: string) => void;
    onEventSelected: (event: ICalendarEvent) => void;
    navigateToSearch: () => void;
    onViewChanged: (view: View) => void;
    showDetailsDialog: boolean;
}

function Agenda(props: IPrivateProps) {
    return (
        <>
            <PageHeader
                breadcrumbs={true}
                title="planning.agenda.title"
            />
            <div className="container">
                {!props.isSearching ? (
                    <>
                        <div className={`${CLASS_NAME}__header`}>
                            <div className={`${CLASS_NAME}__filter-buttons`}>
                                <ListActionButton
                                    id="agenda-filter-button"
                                    iconTypeName="sliders"
                                    translationKey="planning.agenda.search.buttons.filter"
                                    onClick={props.navigateToSearch}
                                />
                                <ListActionButton
                                    id="agenda-search-button"
                                    iconTypeName="search"
                                    translationKey="planning.agenda.search.buttons.search"
                                    onClick={props.navigateToSearch}
                                />
                            </div>
                            {props.listViewSelected && (
                                <div className={`${CLASS_NAME}__export`}>
                                    <AgendaExportButton />
                                </div>
                            )}
                        </div>

                        <FilterableCalendar
                            allowFilteringOnEventType={true}
                            filterChildrenComponent={AgendaFilterActions}
                            asyncInfoSelector={getAppointmentsAsyncInfo}
                            dataSelector={getAppointments}
                            selectedDate={props.selectedDate}
                            onSelectedDateChanged={props.onSelectedDateChanged}
                            selectedEventId={props.selectedEvent && props.selectedEvent.id}
                            onEventSelected={props.onEventSelected}
                            highlightDatepickerType="event-types"
                            view={props.listViewSelected ? 'agenda' : undefined}
                            onViewChanged={props.onViewChanged}
                        />
                    </>
                ) : (
                        <>
                            <SearchContent
                                selectedEvent={props.selectedEvent}
                                onEventSelected={props.onEventSelected}
                            />
                        </>
                    )}
            </div>
            <AppointmentDetailDialog
                show={props.showDetailsDialog}
                selectedEvent={props.selectedEvent}
                onClose={() => props.onEventSelected(null)}
            />
        </>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const events = getAppointments(state);
        const routeKey = getRouteKey(state);
        const { viewType } = getRoutePayload<IAgendaRoutePayload>(state);
        const {
            selectedDate,
            search: searchValue = '',
        } = getQueryParams<IAgendaQueryParams>(state);
        const { eventId } = getRoutePayload<{ eventId: string }>(state);
        const selectedEvent = eventId
            ? events.find((event) => event.id === eventId)
            : null;

        return {
            selectedDate,
            selectedEvent,
            isSearching: isSearching(routeKey),
            searchValue,
            listViewSelected: viewType === 'list',
            showDetailsDialog: !!eventId,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onSelectedDateChanged: (selectedDate: string) => {
                const state = getState();
                const currentQuery = getQueryParams<IAgendaQueryParams>(state);
                const currentPayload = getRoutePayload<IAgendaRoutePayload>(state);
                dispatch(navigateTo(
                    ROUTE_KEYS.R_AGENDA,
                    { viewType: currentPayload.viewType } as IAgendaRoutePayload,
                    { ...currentQuery, selectedDate },
                ));
            },
            onEventSelected: (event: ICalendarEvent) => {
                const state = getState();
                const routeKey = getRouteKey(state);
                const currentQuery = getQueryParams<IAgendaQueryParams>(state);
                const currentPayload = getRoutePayload<IAgendaRoutePayload>(state);
                const searching = isSearching(routeKey);
                if (searching) {
                    const state = getState();
                    const currentQuery = getQueryParams<IAgendaQueryParams>(state);
                    if (event && event.id) {
                        const selectedDate = currentQuery.selectedDate || formatDateForBackend(event.start);
                        dispatch(navigateTo(
                            ROUTE_KEYS.R_AGENDA_SEARCH_EVENT_DETAILS,
                            { viewType: 'list', eventId: event.id } as IAgendaRoutePayload,
                            { ...currentQuery, selectedDate },
                        ));
                    } else {
                        dispatch(navigateTo(
                            ROUTE_KEYS.R_AGENDA_SEARCH,
                            { viewType: 'list' } as IAgendaRoutePayload,
                            { ...currentQuery },
                        ));
                    }
                } else {
                    if (event && event.id) {
                        const selectedDate = currentQuery.selectedDate || formatDateForBackend(event.start);
                        dispatch(navigateTo(
                            ROUTE_KEYS.R_AGENDA_EVENT_DETAILS,
                            { viewType: currentPayload.viewType, eventId: event.id } as IAgendaRoutePayload,
                            { ...currentQuery, selectedDate },
                        ));
                    } else {
                        dispatch(navigateTo(
                            ROUTE_KEYS.R_AGENDA,
                            { viewType: currentPayload.viewType } as IAgendaRoutePayload,
                            { ...currentQuery },
                        ));
                    }
                }
            },
            navigateToSearch: () => {
                const state = getState();
                const currentQuery = getQueryParams<IAgendaQueryParams>(state);
                const query = { ...currentQuery };
                if (!query.startDate) {
                    const { defaultSearchFrom, defaultSearchUntil } = getDefaultSearchDates(query.selectedDate);
                    query.startDate = defaultSearchFrom;
                    query.endDate = defaultSearchUntil;
                }
                dispatch(navigateTo(
                    ROUTE_KEYS.R_AGENDA_SEARCH,
                    { viewType: 'list' } as IAgendaRoutePayload,
                    query,
                ));
            },
            onViewChanged: (view: View) => {
                const state = getState();
                const { viewType } = getRoutePayload<IAgendaRoutePayload>(state);
                const currentQuery = getQueryParams<IAgendaQueryParams>(state);
                const newView = view === 'agenda' ? 'list' : 'calendar';
                if (viewType !== newView) {
                    dispatch(navigateTo(
                        ROUTE_KEYS.R_AGENDA,
                        { viewType: newView } as IAgendaRoutePayload,
                        { ...currentQuery }),
                    );
                }
            },
        };
    },
})(Agenda);

function isSearching(currentRouteKey: ROUTE_KEYS) {
    return (
        currentRouteKey === ROUTE_KEYS.R_AGENDA_SEARCH ||
        currentRouteKey === ROUTE_KEYS.R_AGENDA_SEARCH_EVENT_DETAILS
    );
}
