import { areObjectParamsEqual } from '../../../../utils/core/object/diffObjects';
import {
    DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS,
} from '../../../../views/interventions/Vaccinations/ToAdminister/to-administer.const';
import { formatDateForBackend } from '../../../../utils/formatting/formatDate';
import {
    IVaccinationsApiResponse,
    IMappedToAdministerVaccination,
} from '../../../../models/interventions/vaccines';
import { setAccessTokenGetter } from '../../../../utils/api/requestWrapper';
import ROUTE_KEYS from '../../../../routeKeys';
import { createEpic } from '../../../index';
import { getAccessToken } from '../../../auth/selectors';
import { getLocale } from '../../../i18n/selectors';
import { getLocationState, getQueryParams } from '../../../location/selectors';
import { getSelectedCompany, getSelectedCompanySeat } from '../../../company/selected/selectors';
import { areToAdministerVaccinationsAvailable } from '../selectors';
import { fetchToAdministerVaccinationsSucceeded, fetchToAdministerVaccinationsFailed } from '../actions';
import { FETCH_TO_ADMINISTER_VACCINATIONS } from '../types';
import { mapApiResponseToAdministeredVaccinations } from '../mappers';

createEpic({
    onActionType: [
        ROUTE_KEYS.R_VACCINATIONS_TO_ADMINISTER,
        FETCH_TO_ADMINISTER_VACCINATIONS,
    ],
    refreshDataIf: ({ getState, action }) => {
        // Always refresh data if not available.
        if (!areToAdministerVaccinationsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());

        const queryWithDefaults = {
            ...DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS,
            ...query,
        };

        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processMultiple({ getState, api }, dispatch, done) {
        try {
            const state = getState();
            const { isAllSeatsSelected, companySeat } = getSelectedCompanySeat(state);

            const { id } = getSelectedCompany(state);
            const { id: seatId } = companySeat.company;

            const {
                startDate = null,
                endDate = null,
            } = getQueryParams(state);

            const locale = getLocale(state);

            // Prevent error "getApiAccessToken is not a function"
            setAccessTokenGetter({
                getAccessToken: () => getAccessToken(state),
            });

            const vaccinations: IVaccinationsApiResponse[] =
                await api.interventions.vaccinations.fetchToAdministerVaccinations(
                    {
                        customerId: isAllSeatsSelected ? id : seatId,
                        mode: isAllSeatsSelected ? 'MainSeat' : 'Seat',
                        administerDateFrom: startDate
                            ? formatDateForBackend(startDate)
                            : DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS.startDate,
                        administerDateUntil: endDate
                            ? formatDateForBackend(endDate)
                            : DEFAULT_TO_ADMINISTER_VACCINATIONS_FILTERS.endDate,
                    },
                    locale,
                ) || [];

            dispatch(fetchToAdministerVaccinationsSucceeded(
                mapApiResponseToAdministeredVaccinations(vaccinations) as IMappedToAdministerVaccination[],
            ));
        } catch (error) {
            dispatch(fetchToAdministerVaccinationsFailed(error));
        }
        done();
    },
    latest: false,
});
