import React, { PureComponent } from 'react';
import { ICompanyBufferzone } from '../../../../../models/admin/company';
import { connect } from '../../../..';
import ListWithRadioButtonSelect from '../../../../common/list/ListWithRadioButtonSelect';
import { ListItem, ListColumns, ISortedColumn, SortOrder, SortType } from '../../../../../models/general/list';
import FreeSlotsIndicator from '../../../../common/widget/FreeSlotsIndicator';
import clone from 'ramda/src/clone';
import Translate from '../../../../common/Translate';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import {
    getCompanyBufferzones,
    getFetchCompanyBufferzonesAsyncInfo,
} from '../../../../../redux/company/info/selectors';
import { formatAddress } from '../../../../../utils/formatting/formatAddress';
import { formatDateInLongFormat } from '../../../../../utils/formatting/formatDate';
import { formatStartEndTimeOfDatesForDisplay } from '../../../../../utils/formatting/formatTime';
import MasterWithDetail from '../../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IRenderSearchContentProps,
    IRenderFilterContentProps,
} from '../../../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../../../routeKeys';
import {
    transformFilterValuesToActiveFilters,
    IFilterValues,
    clientSideFilterOfListData,
    shouldRenderShowAllButton,
    SearchContent,
    FilterContent,
    getDefaultQueryParams,
} from '../../../MedicalExaminations/Bufferzones';
import { startEndDateSchema } from '../../../../common/input/StartEndDateFilter/startEndDateSchema';
import { IPlanBufferzoneWizardPayload } from '../../../../../models/interventions/bufferzones';
import { getRoutePayload } from '../../../../../redux/location/selectors';
import { hoursOffsetFromNow, getDate } from '../../../../../utils/core/date/getSpecificDate';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../config/medicalExamination.config';
import Icon from '../../../../common/icons/Icon';
import TooltipWithIcon from '../../../../common/widget/TooltipWithIcon';

const CLASS_NAME = 'SelectBufferzoneOverview';
const BASE_NAME = 'SelectBufferzoneOverviewList';
const TRANSLATION_PREFIX = 'interventions.plan_bufferzone.steps.select_bufferzone';

export interface IContentValues {
    selectedBufferzone: ICompanyBufferzone;
}

interface IPublicProps {
    values: IContentValues;
    onChangeInput: (values: IContentValues) => void;
}

interface IPrivateProps {
    bufferzones: ICompanyBufferzone[];
    currentRoutePayload: IPlanBufferzoneWizardPayload;
}

interface IColumnNames {
    location: string;
    locationId: string;
    address: string;
    seat: string;
    seatCode: string;
    date: string;
    dateSort: string;
    time: string;
    freeSlots: string;
    totalSlots: string;
    endTime: string;
    startTime: string;
    warning: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'date',
    sortOrder: SortOrder.Ascending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    warning: {
        sortable: false,
        minWidth: 50,
        percentWidth: null,
        render: renderWarningTooltip,
    },
    location: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.location`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    locationId: {
        hide: true,
        percentWidth: null,
    },
    address: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.address`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    seat: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    seatCode: {
        hide: true,
        percentWidth: null,
    },
    date: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem) => listItem.columns.dateSort,
        percentWidth: 15,
    },
    dateSort: {
        hide: true,
        percentWidth: null,
    },
    time: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.time`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    freeSlots: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.free_slots`} />,
        sortable: true,
        sortType: SortType.Number,
        sortValue: (listItem) => listItem.columns.freeSlots,
        percentWidth: 15,
    },
    totalSlots: {
        hide: true,
        percentWidth: null,
    },
    endTime: {
        percentWidth: null,
        sortable: false,
    },
    startTime: {
        percentWidth: null,
        sortable: false,
    },
};

function renderWarningTooltip(item: ListItem<IColumnNames>) {
    const warningIcon = (
        <Icon typeName="warning" circle colorType="warning" />
    );

    const startsBeforePlanningDeadline =
        hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() >
        getDate(item.columns.startTime as string);

    if (!startsBeforePlanningDeadline) {
        return <span />;
    }

    return (
        <TooltipWithIcon icon={warningIcon} tooltipBubbleIcon={warningIcon} typeName="info-bubble">
            <div>
                <Translate
                    msg={`${TRANSLATION_PREFIX}.warning_tooltip`}
                    placeholders={{
                        hours: NR_OF_HOURS_BEFORE_EXAM_ALLOWED,
                    }}
                />
            </div>
        </TooltipWithIcon>
    );
}

function SelectBufferzoneOverview(props: IPublicProps & IPrivateProps) {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
                routePayload: props.currentRoutePayload,
                asyncInfoSelector: getFetchCompanyBufferzonesAsyncInfo,
                dataSelector: getCompanyBufferzones,
                transformData: mapBufferzonesToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                    <BufferzonesList {...renderProps} {...props} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                clientSideFilterOfListData,
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
}

type TListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues> & IPublicProps & IPrivateProps;

class BufferzonesList extends PureComponent<TListProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TListProps) {
        super(props);

        this.onItemSelected = this.onItemSelected.bind(this);
        this.getCustomRowClasses = this.getCustomRowClasses.bind(this);
        this.isItemClickable = this.isItemClickable.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            values,
        } = this.props;

        this.columns.freeSlots.render = this.renderFreeSlots;

        return (
            <div className={CLASS_NAME}>
                <ListWithRadioButtonSelect
                    withSorting={true}
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error && <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemId={values && values.selectedBufferzone && values.selectedBufferzone.planningEntityId}
                    initialSort={INITIAL_SORT}
                    onItemSelected={this.onItemSelected}
                    getCustomRowClasses={this.getCustomRowClasses}
                    isItemClickable={this.isItemClickable}
                />
            </div>
        );
    }

    private getCustomRowClasses(listItem: ListItem<IColumnNames>) {
        if (!this.isItemClickable(listItem)) {
            return 'disabled';
        }
        return '';
    }

    private isItemClickable(listItem: ListItem<IColumnNames>) {
        return (
            listItem.columns.freeSlots > 0 &&
            hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() <=
            getDate(listItem.columns.endTime as string)
        );
    }

    private onItemSelected(id: React.ReactText) {
        const { onChangeInput, bufferzones } = this.props;

        const bufferzone = bufferzones.find((bufferzone) => bufferzone.planningEntityId === id);
        if (bufferzone) {
            onChangeInput({ selectedBufferzone: bufferzone });
        }
    }

    private renderFreeSlots(item: ListItem<IColumnNames>) {
        return (
            <FreeSlotsIndicator
                maxSlots={item.columns.totalSlots as number}
                freeSlots={item.columns.freeSlots as number}
                showMaxSlots
            />
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const bufferzones = getCompanyBufferzones(state);
        return {
            bufferzones,
            currentRoutePayload: getRoutePayload<IPlanBufferzoneWizardPayload>(state),
        };
    },
})(SelectBufferzoneOverview);

function mapBufferzonesToListItems(masterData: ICompanyBufferzone[]): ListItem<IColumnNames>[] {
    return masterData
        .map((bufferzone) => ({
            id: bufferzone.planningEntityId,
            columns: {
                location: bufferzone.medicalCenter.name,
                locationId: bufferzone.medicalCenter.id,
                address: formatAddress(bufferzone.medicalCenter.address),
                seat: bufferzone.company.name,
                seatCode: bufferzone.company.companyCode,
                date: formatDateInLongFormat(bufferzone.date),
                dateSort: bufferzone.startTime,
                time: formatStartEndTimeOfDatesForDisplay(bufferzone.startTime, bufferzone.endTime),
                freeSlots: bufferzone.medicalCenter.freeSlots,
                totalSlots: bufferzone.medicalCenter.totalSlots,
                endTime: bufferzone.endTime,
                startTime: bufferzone.startTime,
                warning: null,
            },
        }));
}
