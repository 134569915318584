import React, { PureComponent } from 'react';
import { clone } from 'ramda';

import ROUTE_KEYS from '../../../routeKeys';
import { ListItem, ListColumns, SortType, ISortedColumn, SortOrder } from '../../../models/general/list';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IRenderDetailContentProps, IRenderDetailHeaderProps,
    IShouldRenderShowAllButtonProps,
    IClientSideFilterOfListDataProps, IRenderSearchContentProps,
    IRenderFilterContentProps, ITransformToActiveFiltersProps,
} from '../../common/widget/MasterWithDetail/typings';
import ListWithSorting from '../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import { IFluVaccinesOrder, IFluVaccinesConfig } from '../../../models/interventions/fluVaccines';
import Translate from '../../common/Translate';
import {
    getFluVaccinesOrdersAsyncInfo,
    getFluVaccinesOrders,
    getFluVaccinesConfig,
    getFluVaccinesConfigAsyncInfo,
    getEnrichedSelectedFluVaccineOrder,
    getUpdateFluVaccinesOrderAsyncInfo,
    showFluVaccinesCampaign,
} from '../../../redux/intervention/fluVaccine/selectors';
import {
    triggerFluVaccinesOrderWizard,
    updateFluVaccinesOrderActions,
} from '../../../redux/intervention/fluVaccine/actions';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../utils/formatting/formatDate';
import PageHeader from '../../appShell/PageHeader';
import syringeIllu from '../../assets/img/icons/syringe.svg';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import { formatPrice } from '../../../utils/formatting/formatPrice';
import ListItemActions from '../../common/list/ListItemActions';
import { connect } from '../../index';
import { DetailContent, DetailHeader, IFormValues } from './detail';
import DetailOverlay, { FluVaccinesOrdersDetailsOverlayType } from './detailOverlay';
import {
    calculateInitialFluVaccinesOrderPriceFromOrderData,
} from '../FluVaccinesOrderWizard/fluVaccinesOrderPriceCalculator';
import { navigateTo } from '../../../redux/location/actions';
import { isDateBetween } from '../../../utils/core/date/isDateBetween';
import { separateStringList } from '../../../utils/core/string/separatedStringList';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../common/input/TextInput';
import
getUniqueTypeaheadFilterValuesFromListItems
    from '../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../common/input/CheckboxesOrTypeaheadFilter';
import { DEFAULT_FLU_ORDERS_FILTERS } from '../../../api/interventions/fluVaccines.api';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { isBeforeOrEqual } from '../../../utils/core/date/isBeforeOrEqual';
import { isAfterOrEqual } from '../../../utils/core/date/isAfterOrEqual';
import StartEndDateFilter from '../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../models/ui/form';
import { IAsyncFieldInfo, AsyncStatus } from '../../../models/general/redux';
import TinyLoader from '../../common/waiting/TinyLoader';

const BASE_NAME = 'flu-orders-list';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IPrivateProps {
    fluVaccinesConfig: IFluVaccinesConfig;
    isFLuVaccinesOrderPeriodActive: boolean;
    goToOrderFluVaccines: () => void;
    getFluVaccinesConfigAsyncInfo: IAsyncFieldInfo;
    navigateToDetail: (fluVaccinesOrderId: number) => void;
}

type TOrdersListProps = IRenderMasterContentProps<
    ListItem<IColumnNames, string | number, IFluVaccinesOrder>[],
    IFilterValues
>;

const COLUMNS: ListColumns<IColumnNames> = {
    orderDate: {
        label: <Translate msg="interventions.flu_orders.columns.order_date" />,
        sortable: true,
        percentWidth: 15,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.orderDateSort,
    },
    orderDateSort: {
        hide: true,
        percentWidth: null,
    },
    company: {
        label: <Translate msg="interventions.flu_orders.columns.seat" />,
        sortable: true,
        percentWidth: 50,
        sortType: SortType.String,
    },
    companyCode: {
        hide: true,
        percentWidth: null,
    },
    amountVaccinesOrdered: {
        label: <Translate msg="interventions.flu_orders.columns.amount" />,
        sortable: true,
        percentWidth: 15,
        sortType: SortType.Number,
        align: 'right',
    },
    price: {
        label: <Translate msg="interventions.flu_orders.columns.price" />,
        sortable: true,
        percentWidth: 15,
        sortType: SortType.Number,
        align: 'right',
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.priceSort,
    },
    priceSort: {
        hide: true,
        percentWidth: null,
    },
    actions: {
        sortable: false,
        percentWidth: 5,
        align: 'center',
    },
};

interface IColumnNames {
    orderDate: string;
    orderDateSort: string;
    company: string;
    companyCode: string;
    amountVaccinesOrdered: number;
    price: string;
    priceSort: number;
    actions: string;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    seatCodes: string;
    isShowAll: boolean;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'orderDate',
    sortOrder: SortOrder.Descending,
};

function renderOrderFluVaccinesButton(props: IPrivateProps) {
    return (
        props.isFLuVaccinesOrderPeriodActive && (
            <ShowIfAllowed routeKey={ROUTE_KEYS.R_FLU_VACCINES_ORDERS_NEW}>
                <Button
                    id="page-header-plus-button"
                    typeName="secondary"
                    onClick={props.goToOrderFluVaccines}
                >
                    <Icon typeName="plus-circle" />
                    <span><Translate msg="interventions.flu_orders.action.order" /></span>
                </Button>
            </ShowIfAllowed>
        )
    );
}

class FluVaccinesOrders extends PureComponent<IPrivateProps> {

    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className={BASE_NAME}>
                <PageHeader
                    breadcrumbs={true}
                    title="interventions.flu_orders.title"
                    titleIcon={syringeIllu}
                    button={renderOrderFluVaccinesButton(this.props)}
                    type="green"
                />
                <MasterWithDetail
                    baseName={BASE_NAME}
                    getDefaultQueryParams={getDefaultQueryParams}
                    masterConfig={{
                        routeKey: ROUTE_KEYS.R_FLU_VACCINES_ORDERS,
                        asyncInfoSelector: getFluVaccinesOrdersAsyncInfo,
                        dataSelector: getFluVaccinesOrders,
                        transformData: mapFluVaccinesOrdersToListItems,
                        transformFilterValuesToActiveFilters,
                        renderContent: (renderProps: TOrdersListProps) =>
                            <OrdersList {...renderProps} {...this.props} />,
                        clientSideSearchOfListData: {
                            searchFilterName: 'search',
                            columnsConfig: COLUMNS,
                        },
                        clientSideFilterOfListData,
                        filterValidationSchema: startEndDateSchema,
                    }}
                    headerConfig={{
                        renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                            <SearchContent {...renderProps} />,
                        renderFilterContent:
                            (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                <FilterContent {...renderProps} />,
                        exportButton: {
                            baseFilename: 'flu-vaccine-orders',
                        },
                    }}
                    detailConfig={{
                        routeKey: ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL,
                        asyncInfoSelector: getFluVaccinesOrdersAsyncInfo,
                        updateInfo: {
                            updateAsyncInfoSelector: getUpdateFluVaccinesOrderAsyncInfo,
                            updateAsyncInfoResetAction: updateFluVaccinesOrderActions.reset({}),
                        },
                        dataSelector: getEnrichedSelectedFluVaccineOrder,
                        idRouteParamName: 'id',
                        isForm: true,
                        renderHeader: (renderProps: IRenderDetailHeaderProps<IFluVaccinesOrder>) =>
                            <DetailHeader {...renderProps} />,
                        renderContent: (renderProps: IRenderDetailContentProps<IFluVaccinesOrder>) =>
                            <DetailContent
                                {...renderProps}
                            />,
                        renderOverlay: ({
                            overlayType, closeOverlay, onSave, formValues,
                        }) =>
                            <DetailOverlay
                                overlayType={overlayType as FluVaccinesOrdersDetailsOverlayType}
                                closeOverlay={closeOverlay}
                                onSave={onSave}
                                formValues={formValues as IFormValues}
                            />,
                    }}
                    footerConfig={{
                        shouldRenderShowAllButton,
                        renderActionsRight: () =>
                            renderOrderFluVaccinesButton(this.props),
                    }}
                />
            </div>
        );
    }
}

function mapFluVaccinesOrdersToListItems(masterData: IFluVaccinesOrder[]): ListItem<IColumnNames>[] {
    return masterData
        .map((order) => {
            const price = calculateInitialFluVaccinesOrderPriceFromOrderData(order);
            return {
                id: order.id,
                columns: {
                    orderDate: formatDateInLongFormat(order.orderDate),
                    orderDateSort: order.orderDate,
                    company: order.company.name,
                    companyCode: order.company.companyCode,
                    amountVaccinesOrdered: order.amountVaccinesOrdered,
                    price: formatPrice(price),
                    priceSort: price,
                    actions: null,
                },
                data: order,
            };
        });
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.startDate)) {
                return true;
            }

            return isAfterOrEqual(listItem.columns.orderDateSort as string, filterValues.startDate);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.endDate)) {
                return true;
            }

            return isBeforeOrEqual(listItem.columns.orderDateSort as string, filterValues.endDate);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.seatCodes)) {
                return true;
            }

            const seatCodes = separateStringList(filterValues.seatCodes);
            return seatCodes.includes(listItem.columns.companyCode as string);
        });
}

export function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('administration.company_info.contacts.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="administration.company_info.contacts.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleSeats = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'companyCode',
        'company',
    );

    return (
        <div>
            <CheckboxesOrTypeaheadFilter
                filterName="seat"
                labelTranslationKey="administration.company_info.contacts.filter.seat"
                possibleFilterItems={possibleSeats}
                actualFilterValue={formRenderProps.values.seatCodes}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'seatCodes',
                    newFilterValue,
                )}
            />

            <StartEndDateFilter
                translationKeyPrefix="interventions.company_visits.planned.filter"
                formRenderProps={formRenderProps}
            />
        </div>
    );
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.flu_orders.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_FLU_ORDERS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_FLU_ORDERS_FILTERS.startDate,
            },
            seatCodes: {
                show: true,
                translationKeySuffixOverride: 'seat',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'company',
                        searchColumnName: 'companyCode',
                    },
                },
            },
        },
    });
}

class OrdersList extends PureComponent<TOrdersListProps & IPrivateProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TOrdersListProps & IPrivateProps) {
        super(props);

        this.columns.actions.render = this.renderActions.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            onItemSelected,
            filterValues,
            footer,
        } = this.props;

        return (
            <ListWithSorting
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemIds={selectedItemId ? [selectedItemId] : []}
                onItemRowClicked={onItemSelected}
                initialSort={INITIAL_SORT}
                footer={footer}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
            />
        );
    }

    private renderActions(listItem: ListItem<IColumnNames, string | number, IFluVaccinesOrder>, index) {
        const {
            fluVaccinesConfig,
            getFluVaccinesConfigAsyncInfo,
            isFLuVaccinesOrderPeriodActive,
        } = this.props;

        if (getFluVaccinesConfigAsyncInfo.status === AsyncStatus.Busy) {
            return <TinyLoader asyncInfoSelector={getFluVaccinesConfigAsyncInfo} />;
        }

        if (!fluVaccinesConfig) {
            return null;
        }

        const isEditable = isDateBetween(
            listItem.columns.orderDateSort as string,
            fluVaccinesConfig.startDate,
            fluVaccinesConfig.endDate,
        ) && !listItem.data.confirmed;

        return (
            <ListItemActions>
                {isFLuVaccinesOrderPeriodActive && isEditable &&
                    <ShowIfAllowed requiredAccessLevels={{ company: 'W' }}>
                        <Icon
                            circle
                            typeName="pencil"
                        />
                    </ShowIfAllowed>
                }
            </ListItemActions>
        );
    }
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_FLU_ORDERS_FILTERS,
        isShowAll,
    } : DEFAULT_FLU_ORDERS_FILTERS;
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<TOrdersListProps & IPrivateProps>({
    stateProps: (state) => {
        const fluVaccinesConfig = getFluVaccinesConfig(state);
        return {
            fluVaccinesConfig,
            getFluVaccinesConfigAsyncInfo: getFluVaccinesConfigAsyncInfo(state),
            isFLuVaccinesOrderPeriodActive: showFluVaccinesCampaign(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            goToOrderFluVaccines: () => {
                dispatch(triggerFluVaccinesOrderWizard());
            },
            navigateToDetail: (fluVaccinesOrderId: number) => {
                dispatch(navigateTo(ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL, { id: fluVaccinesOrderId }));
            },
        };
    },
})(FluVaccinesOrders);
