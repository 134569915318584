import React, { PureComponent } from 'react';
import clone from 'ramda/src/clone';

import ListWithSorting from '../../../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import { VACCINATIONS_TRANS_PREFIX } from '../../vaccines.const';
import {
    BASE_NAME,
    COLUMNS,
    DEFAULT_NR_OF_VACCINES_TO_SHOW,
    INITIAL_SORT,
} from '../administered.const';

import { IAdministeredVaccinationsListProps } from './AdministeredVaccinationList.types';

class AdministeredVaccinationsList extends PureComponent<IAdministeredVaccinationsListProps> {
    private columns = clone(COLUMNS);

    constructor(props: IAdministeredVaccinationsListProps) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            footer,
            filterValues,
        } = this.props;

        return (
            <ListWithSorting
                initialSort={INITIAL_SORT}
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder
                        bffErrorTranslationPrefix={`${VACCINATIONS_TRANS_PREFIX}.error_placeholder`}
                        apiError={masterAsyncInfo.error}
                    />
                }
                footer={footer}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_VACCINES_TO_SHOW}
            />
        );
    }
}

export { AdministeredVaccinationsList };
