import React from 'react';

import { getStore } from '../../../redux/storeNoCircularDependencies';
import { isDraggableSupported } from '../../../utils/browser/draggable';
import { navigateTo } from '../../../redux/location/actions';
import ROUTE_KEYS from '../../../routeKeys';
import HelloCustomerPopup from '../../appShell/HelloCustomer/HelloCustomer.component';
import OverlayContent from '../../common/modals/OverlayContent';

import AgendaDashboardBlock from './AgendaDashboardBlock';
import EditTopTasks from './EditTopTasks';
import FluVaccinesFooter from './FluVaccinesFooter';
import NavigationDashboardBlock from './NavigationDashboardBlock';
import SuggestionsDashboardBlock from './SuggestionsDashboardBlock';
import TopTasksDashboardBlock from './TopTasksDashboardBlock';
import WelcomeDashboardBlock from './WelcomeDashboardBlock';

import './home-dashboard.scss';
import './dashboard.scss';

export interface IHomeDashboardProps {
    showEditTopTasksDialog?: boolean;
}

const CLASS_NAME = 'HomeDashboard';

export default function HomeDashboard(props?: IHomeDashboardProps) {
    const { showEditTopTasksDialog = false } = props || {};

    return (
        <div className={CLASS_NAME}>
            <div className={`${CLASS_NAME}__top`}>
                <div className="container">
                    <div className="Dashboard">
                        <div className="welcome">
                            <WelcomeDashboardBlock />
                        </div>
                        <div className="top">
                            <TopTasksDashboardBlock />
                            <AgendaDashboardBlock />
                        </div>
                        <NavigationDashboardBlock />
                    </div>
                </div>
                {isDraggableSupported &&
                    <OverlayContent
                        show={showEditTopTasksDialog}
                        onCloseIntent={navigateToHome}
                        removeOpacity={true}
                    >
                        {showEditTopTasksDialog && <EditTopTasks />}
                    </OverlayContent>
                }
                <div className={`${CLASS_NAME}__subsection`}>
                    <div className="container">
                        <SuggestionsDashboardBlock />
                    </div>
                </div>
            </div>
            <div className={`${CLASS_NAME}__bottom`}>
                <FluVaccinesFooter/>
            </div>
            <HelloCustomerPopup />
        </div>
    );
}

function navigateToHome() {
    const store = getStore();
    store.dispatch(navigateTo(ROUTE_KEYS.R_HOME));
}
