import React, { PureComponent, MouseEvent } from 'react';
import clone from 'ramda/src/clone';

import { ListItem } from '../../../../../models/general/list';
import ROUTE_KEYS from '../../../../../routeKeys';
import Button from '../../../../common/buttons/Button';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import Icon from '../../../../common/icons/Icon';
import ListItemActions from '../../../../common/list/ListItemActions';
import ListWithSorting from '../../../../common/list/ListWithSorting';
import ShowIfAllowed from '../../../../auth/ShowIfAllowed';
import Translate from '../../../../common/Translate';
import { TRANSLATION_PREFIX } from '../../shared/RequestVaccinationDialog/RequestVaccinationDialog.const';
import { VACCINATIONS_TRANS_PREFIX } from '../../vaccines.const';
import {
    BASE_NAME,
    COLUMNS,
    INITIAL_SORT,
    DEFAULT_NR_OF_VACCINES_TO_SHOW,
} from '../to-administer.const';

import {
    IToAdministerVaccinationsListProps,
    IToAdministerVaccinationsListColumnNames,
} from './ToAdministerVaccinationList.types';

class ToAdministerVaccinationsList extends PureComponent<IToAdministerVaccinationsListProps> {
    private columns = clone(COLUMNS);

    constructor(props: IToAdministerVaccinationsListProps) {
        super(props);

        this.columns.actions.render = this.renderPlanEmployeeAction.bind(this);
    }

    private renderPlanEmployeeAction = (listItem: ListItem<IToAdministerVaccinationsListColumnNames>) => {
        const handleEmployeeClicked = (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            this.props.onPlanVaccination(listItem);
        };

        return (
            <ListItemActions>
                <ShowIfAllowed routeKey={ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD}>
                    <Button
                        id="plan-employee-button"
                        onClick={handleEmployeeClicked}
                    >
                        <span>
                            <Translate msg={`${TRANSLATION_PREFIX}.plan_vaccination`} />
                        </span>
                        <Icon circle typeName="calendar" />
                    </Button>
                </ShowIfAllowed>
            </ListItemActions>
        );
    };

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            footer,
            filterValues,
        } = this.props;

        return (
            <ListWithSorting
                initialSort={INITIAL_SORT}
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder
                        bffErrorTranslationPrefix={`${VACCINATIONS_TRANS_PREFIX}.error_placeholder`}
                        apiError={masterAsyncInfo.error}
                    />
                }
                footer={footer}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_VACCINES_TO_SHOW}
            />
        );
    }
}

export { ToAdministerVaccinationsList };
