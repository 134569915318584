import React, { PureComponent, MouseEvent } from 'react';
import clone from 'ramda/src/clone';
import { connect } from '../../../../index';
import ErrorPlaceholder from '../../../../common/error/ErrorPlaceholder';
import ListWithSorting from '../../../../common/list/ListWithSorting';
import ListItemActions from '../../../../common/list/ListItemActions';
import ConfirmationDialog from '../../../../common/modals/ConfirmationDialog';
import FormError from '../../../../common/forms/FormError';
import { IRenderMasterContentProps } from '../../../../common/widget/MasterWithDetail/typings';
import { ListItem, ListColumns, SortType } from '../../../../../models/general/list';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import {
    getRemoveContactAsyncInfo,
    mayUserManageCompanyInternalContacts,
} from '../../../../../redux/company/info/selectors';
import { removeContact } from '../../../../../redux/company/info/actions';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import { ITraceableApiError } from '../../../../../models/general/error';
import { ITranslator } from '../../../../../models/general/i18n';
import ShowIfAllowed from '../../../../auth/ShowIfAllowed';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';

interface ICompanyContactToRemove {
    customerContactId: number;
    employee: string;
}

interface IContactsListState {
    isConfirmationDialogOpen: boolean;
    isSuccessDialogOpen: boolean;
    contactToRemove: ICompanyContactToRemove;
}

interface IPrivateProps {
    removeContact: (contact: ICompanyContactToRemove) => void;
    removeAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
    translator: ITranslator;
    mayDelete: boolean;
}

type IColumnNames = IColumnNamesWhenNotMainSeat | IColumnNamesWhenMainSeat;

export interface IColumnNamesWhenNotMainSeat {
    type: string;
    company: string;
    companyCode: string;
    employee: string;
    phone: string;
    email: string;
    actions: string;
}

export interface IFilterValues {
    search?: string;
    contactTypes?: string;
    seatCodes?: string;
    isShowAll: boolean;
}

export interface IColumnNamesWhenMainSeat {
    type: string;
    employee: string;
    phone: string;
    email: string;
    language: string;
    actions: string;
}

export interface TContactsListProps
    extends IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues> {
    name: string;
    nrRecordsToShow: number;
    isMainSeat?: boolean;
}

const COLUMNS_WHEN_MAIN_SEAT: ListColumns<IColumnNamesWhenMainSeat> = {
    employee: {
        label: <Translate msg="administration.company_info.contacts.columns.employee" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    type: {
        label: <Translate msg="administration.company_info.contacts.columns.type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    language: {
        label: <Translate msg="administration.company_info.contacts.columns.language" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    phone: {
        label: <Translate msg="administration.company_info.contacts.columns.phone" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    email: {
        label: <Translate msg="administration.company_info.contacts.columns.email" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
};

export const COLUMNS: ListColumns<IColumnNames> = {
    employee: {
        label: <Translate msg="administration.company_info.contacts.columns.employee" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    type: {
        label: <Translate msg="administration.company_info.contacts.columns.type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    company: {
        label: <Translate msg="administration.company_info.contacts.columns.company" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    companyCode: {
        label: <Translate msg="administration.company_info.contacts.columns.company_code" />,
        sortable: true,
        sortType: SortType.DotSeparatedNumber,
        percentWidth: 13,
    },
    phone: {
        label: <Translate msg="administration.company_info.contacts.columns.phone" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 12,
    },
    email: {
        label: <Translate msg="administration.company_info.contacts.columns.email" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
};

class ContactsList extends
    PureComponent<TContactsListProps & IPrivateProps, IContactsListState> {

    private columns = clone(this.props.isMainSeat ? COLUMNS_WHEN_MAIN_SEAT : COLUMNS);

    constructor(props: TContactsListProps & IPrivateProps) {
        super(props);

        this.state = {
            isConfirmationDialogOpen: false,
            isSuccessDialogOpen: false,
            contactToRemove: null,
        };

        this.onDeleteClicked = this.onDeleteClicked.bind(this);
        this.onDeleteConfirmClicked = this.onDeleteConfirmClicked.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSuccessClick = this.onSuccessClick.bind(this);

        this.columns.actions.render = (listItem: ListItem<IColumnNames>, index) => {
            return (
                <ListItemActions>
                    <ShowIfAllowed requiredAccessLevels={{ company: 'W' }}>
                        <Icon
                            circle
                            typeName="bin"
                            onClick={(e) => this.onDeleteClicked(e, listItem)}
                        />
                    </ShowIfAllowed>
                </ListItemActions>
            );
        };

        this.adaptColumnWidthAccordingToAccessLevel();
    }

    private adaptColumnWidthAccordingToAccessLevel() {
        if (!this.props.mayDelete) {
            this.columns.actions.hide = true;
            this.columns.type.percentWidth = 20;
            this.columns.email.percentWidth = 25;
        }
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            onItemSelected,
            selectedItemId,
            filterValues,
            footer,
            translator,
            removeAsyncInfo,
            name,
            nrRecordsToShow,
        } = this.props;

        const { isConfirmationDialogOpen, contactToRemove, isSuccessDialogOpen } = this.state;

        return (
            <>
                <ListWithSorting
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={name}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onItemRowClicked={onItemSelected}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : nrRecordsToShow}
                    footer={footer}
                />
                <ConfirmationDialog
                    show={isConfirmationDialogOpen}
                    showSuccess={isSuccessDialogOpen}
                    onConfirm={this.onDeleteConfirmClicked}
                    onCancel={this.onCancelClick}
                    onSuccess={this.onSuccessClick}
                    showLoader={removeAsyncInfo.status === AsyncStatus.Busy}
                    type="error"
                    header={translator({
                        msg: 'administration.company_info.contacts.remove.confirm_delete.title',
                        placeholders: {
                            name: contactToRemove && contactToRemove.employee,
                        },
                    })}
                    successHeader={translator({
                        msg: 'administration.company_info.contacts.remove.confirm_delete_success.title',
                        placeholders: {
                            name: contactToRemove && contactToRemove.employee,
                        },
                    })}
                >
                    <p>
                        <Translate
                            msg="administration.company_info.contacts.remove.confirm_delete.subtitle"
                        />
                    </p>
                    {removeAsyncInfo.error &&
                        <FormError error={removeAsyncInfo.error} />
                    }
                </ConfirmationDialog>
            </>
        );
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IPrivateProps) {
        if (
            this.props.removeAsyncInfo.status === AsyncStatus.Busy &&
            nextProps.removeAsyncInfo.status === AsyncStatus.Success
        ) {
            this.setState({
                isSuccessDialogOpen: true,
            });
        }
    }

    private onDeleteClicked(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isConfirmationDialogOpen: true,
            contactToRemove: {
                customerContactId: listItem.id as number,
                employee: listItem.columns.employee as string,
            },
        });
    }

    private onCancelClick() {
        this.setState({ isConfirmationDialogOpen: false });
        const { clearError, removeAsyncInfo } = this.props;
        clearError(removeAsyncInfo.error);
    }

    private onDeleteConfirmClicked() {
        const { contactToRemove } = this.state;
        const { removeContact } = this.props;

        removeContact(contactToRemove);
    }

    private onSuccessClick() {
        this.setState({
            isConfirmationDialogOpen: false,
            isSuccessDialogOpen: false,
        });
    }
}

export default connect<IPrivateProps, TContactsListProps>({
    stateProps: (state) => {
        return {
            translator: getTranslatorDeprecated(state),
            removeAsyncInfo: getRemoveContactAsyncInfo(state),
            mayDelete: mayUserManageCompanyInternalContacts(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            removeContact: (contact: ICompanyContactToRemove) => {
                dispatch(removeContact({
                    customerContactId: contact.customerContactId,
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
        };
    },
})(ContactsList);
