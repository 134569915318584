import { IPerson } from '../admin/employee';
import { ICompanyContact } from '../admin/companyInfo';

export interface IFetchExecutedCompanyVisitsPayload {
    companyCode: string;
    showFullFamily: string;
}

export interface IExecutedCompanyVisit {
    city: string;
    company: {
        name: string,
        companyCode: string;
    };
    employee: IPerson;
    id: string;
    startTime: string;
    endTime: string;
    visitCode: string;
    visitDate: string; // example: "2017-10-10"
    visitKind: string;
}

export interface IExecutedCompanyVisitsFilter {
    startDate: string;
    endDate: string;
}

export interface IFetchPlannedCompanyVisitsPayload {
    companyCode: string;
    showFullFamily: string;
}

export interface IPlannedCompanyVisit {
    city: string;
    company: {
        name: string,
        companyCode: string;
    };
    employee: IPerson;
    id: string;
    startTime: string;
    endTime: string;
    visitDate: string; // example: "2017-10-10"
    visitKind: string;
    afterNoon?: boolean;
}

export interface IPlannedCompanyVisitsFilter {
    startDate: string;
    endDate: string;
}

export interface IFetchCompanyVisitDocumentsPayload {
    visitId: string;
    companyCode: string;
}

export interface ICompanyVisitDocument {
    id: string;
    filename: string;
    documentClass: string;
    documentClassId: string;
    description: string;
    dateCreated: string;
}

export interface ICreateCompanyVisitRequest {
    contact: Pick<ICompanyContact, 'firstName' | 'name' | 'email' | 'phone' | 'mobilePhone'>;
    remarks: string;
}

export enum COMPANY_VISITS_REQUEST_WIZARD_STEP_ID {
    CONTACT = 'contact',
    REMARKS = 'remarks',
    VALIDATE = 'validate',
}

export interface ICompanyVisitRequestWizardPayload {
    step: COMPANY_VISITS_REQUEST_WIZARD_STEP_ID;
    resetDataEntity: boolean;
}

export interface ICompanyVisitRequestWizardEntity {
    contact: ICompanyContact;
    remarks: {
        remark: string;
    };
}

export interface ICompanyVisitRequestWizardData {
    stepId: string;
    entity: Partial<ICompanyVisitRequestWizardEntity>;
}
