import { NOT_REFRESHED } from '../utils/api/requestWrapper';

import * as company from './admin/company.api';
import * as companyInfo from './admin/companyInfo.api';
import * as employee from './admin/employee.api';
import * as functions from './admin/functions.api';
import * as invoices from './admin/invoices.api';
import * as preventionUnits from './admin/preventionUnits.api';
import * as questionnaires from './admin/questionnaires.api';
import * as risks from './admin/risks.api';
import * as rates from './admin/rates.api';
import * as statutes from './admin/statutes.api';

import * as authentication from './auth/authentication.api';
import * as auth0 from './auth/auth0.api';

import * as caseManager from './contact/caseManager.api';
import * as faq from './contact/faq.api';
import * as feedback from './contact/feedback.api';
import * as mensuraContacts from './contact/mensuraContacts.api';

import * as accessionAgreement from './documentCenter/accessionAgreement.api';
import * as identificationDocument from './documentCenter/identificationDocument.api';
import * as medicalDocuments from './documentCenter/medicalDocuments.api';
import * as workPostCard from './documentCenter/workPostCard.api';
import * as riskManagementDocuments from './documentCenter/riskManagementDocuments.api';
import * as courses from './documentCenter/courses.api';
import * as templates from './documentCenter/templates.api';

import * as address from './general/address.api';
import * as constants from './general/constants.api';
import * as content from './general/content.api';
import * as documents from './general/documents.api';
import * as i18n from './general/i18n.api';
import * as maintenance from './general/maintenance.api';
import * as cms from './general/cms.api';
import * as webAppMessages from './general/webAppMessages.api';
import * as cookies from './general/cookies.api';
import * as performance from './general/performance.api';

import * as companyVisits from './interventions/companyVisits.api';
import * as medicalExaminations from './interventions/medicalExaminations.api';
import * as fluVaccines from './interventions/fluVaccines.api';
import * as timeslots from './interventions/timeslots.api';
import * as executedInterventions from './interventions/executedInterventions.api';
import * as requestIntervention from './interventions/requestIntervention.api';
import * as requestReIntegration from './interventions/requestReIntegration.api';
import * as workAccidents from './interventions/workAccidents.api';
import * as vaccinations from './interventions/vaccinations.api';
import * as policyAdvice from './interventions/policyAdvice.api';

import * as appointments from './planning/appointments.api';

import * as inbox from './user/inbox.api';
import * as me from './user/me.api';
import * as topTasks from './user/topTasks.api';
import * as admin from './user/admin.api';

export default {
    admin: {
        company,
        companyInfo,
        employee,
        functions,
        invoices,
        preventionUnits,
        questionnaires,
        risks,
        rates,
        statutes,
    },
    auth: {
        authentication,
        auth0,
    },
    contact: {
        caseManager,
        faq,
        feedback,
        mensuraContacts,
    },
    documentCenter: {
        accessionAgreement,
        identificationDocument,
        medicalDocuments,
        workPostCard,
        riskManagementDocuments,
        courses,
        templates,
    },
    general: {
        address,
        cms,
        constants,
        content,
        documents,
        i18n,
        maintenance,
        webAppMessages,
        cookies,
        performance,
    },
    interventions: {
        companyVisits,
        executedInterventions,
        fluVaccines,
        medicalExaminations,
        policyAdvice,
        timeslots,
        requestIntervention,
        requestReIntegration,
        workAccidents,
        vaccinations,
    },
    planning: {
        appointments,
    },
    user: {
        inbox,
        me,
        topTasks,
        admin,
    },
    isNotRefreshedError: (error) => error === NOT_REFRESHED,
};
