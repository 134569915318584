import React, { PureComponent } from 'react';
import './time-slot-remarks.scss';
import {
    getPlanMedicalExaminationWizardEntity, getAddTimeslotAsyncInfo,
    getMedicalExaminationToPlanInfoByEmployeeId, getPlanMedicalExaminationWizardReason, getUpdateTimeslotAsyncInfo,
} from '../../../../../../redux/medicalExamination/selectors';
import {
    IPlanMedicalExaminationSingleEmployeeBaseEntity, IExaminationReason, IMovePlanningData,
} from '../../../../../../models/interventions/medicalExaminations';
import { getCompanyMedicalCenters } from '../../../../../../redux/company/info/selectors';
import {
    updatePlanMedicalExaminationWizardEntity,
    addTimeslotActions,
    triggerMoveUpdateTimeslot,
} from '../../../../../../redux/medicalExamination/actions';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import FloatableTextInputWrapper from '../../../../../common/forms/FloatableTextInputWrapper';
import Translate from '../../../../../common/Translate';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import TextInput from '../../../../../common/input/TextInput';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import {
    ITimeCell,
    IAddTimeslotPayload,
    IAddTimeslotByPlanningRecordIdPayload,
    IMoveUpdateTimeslotPayload,
} from '../../../../../../models/interventions/timeslots';
import { IMedicalCenter, IEmployee, IEmployeeDetails } from '../../../../../../models/admin/employee';
import { ITranslator } from '../../../../../../models/general/i18n';
import { fields, schema } from './medicalExaminationRemarksSchema';
import connect from '../../../../../../utils/libs/redux/connect';
import SubmitButton from '../../../../../common/buttons/SubmitButton';
import FormError from '../../../../../common/forms/FormError';
import Loader from '../../../../../common/waiting/Loader';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import { getSelectedEmployee } from '../../../../../../redux/employee/info/selectors';

const FORM_NAME = 'medical-examination-request-remarks-form';

type TWizardValues = Partial<Pick<IPlanMedicalExaminationSingleEmployeeBaseEntity, 'selectTime'>>;

interface IFormValues {
    remarks: string;
}

interface IPrivateProps {
    selectedTimeslot: ITimeCell;
    mainMedicalCenter: IMedicalCenter;
    translator: ITranslator;
    updateWizardData: (values: Partial<TWizardValues>) => void;
    remarks: string;
    employee: Pick<IEmployee, 'id' | 'employeeId' | 'name' | 'firstName'>;
    employeeDetails: IEmployeeDetails;
    examinationReason: IExaminationReason;
    planningRecordId: number;
    addTimeslot: (payload: IAddTimeslotPayload | IAddTimeslotByPlanningRecordIdPayload) => void;
    addTimeslotAsyncInfo: IAsyncFieldInfo;
    moveTimeslot: (payload: IMoveUpdateTimeslotPayload) => void;
    moveTimeslotAsyncInfo: IAsyncFieldInfo;
    movePlanning: IMovePlanningData;
}

interface ITimeslotRemarksProps {
    onTimeslotPlanned: () => void;
}

const CLASS_NAME = 'TimeslotRemarks';

class TimeslotRemarks extends PureComponent<IPrivateProps & ITimeslotRemarksProps> {

    public render() {
        const {
            translator,
            remarks,
            updateWizardData,
            employee,
            addTimeslot,
            selectedTimeslot,
            examinationReason,
            planningRecordId,
            addTimeslotAsyncInfo,
            moveTimeslot,
            moveTimeslotAsyncInfo,
            movePlanning,
            employeeDetails,
        } = this.props;
        const initialValues: IFormValues = {
            remarks: remarks || '',
        };

        const remarksTranslationKey =
            'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.fields.remarks.label';
        const submitTranslationKey = movePlanning ?
            'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.submit_move' :
            'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.submit';

        return (
            <Form
                className={CLASS_NAME}
                name={FORM_NAME}
                schema={schema}
                initialValues={initialValues}
                handleSubmit={(values: IFormValues) => {
                    updateWizardData({
                        selectTime: {
                            remarks: values.remarks,
                        },
                    });
                    movePlanning ?
                        moveTimeslot({
                            remarks: values.remarks,
                            examinationReason: {
                                id: examinationReason.id,
                            },
                            newTimeCellId: selectedTimeslot.id,
                            timeCellId: movePlanning.timeCellId,
                            company: {
                                id: employeeDetails && employeeDetails.company.id,
                                companyCode: employeeDetails && employeeDetails.company.companyCode,
                            },
                        }) :
                        addTimeslot({
                            remarks: values.remarks,
                            employee: employee && {
                                id: employee.id,
                                employeeId: employee.employeeId,
                            },
                            examinationReason: {
                                id: examinationReason.id,
                            },
                            planningEntityId: selectedTimeslot.planningEntityId,
                            planningRecordId,
                            timeCellId: selectedTimeslot.id,
                            company: {
                                id: employeeDetails && employeeDetails.company.id,
                                companyCode: employeeDetails && employeeDetails.company.companyCode,
                            },
                        });
                }}
                render={(formRenderProps: IFormRenderProps<IFormValues>) => {
                    return <Loader show={showLoaderIfBusy(addTimeslotAsyncInfo, moveTimeslotAsyncInfo)}>
                        <FloatableTextInputWrapper>
                            <TextInput
                                id="examination-remark"
                                name={fields.remarks}
                                value={formRenderProps.values.remarks}
                                onChange={formRenderProps.handleChange}
                                isInvalid={formRenderProps.touched.remarks && !!formRenderProps.errors.remarks}
                                multiLine={true}
                                rows={6}
                                // eslint-disable-next-line max-len
                                placeholder={translator('interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.fields.remarks.placeholder')}
                            />
                            <label htmlFor="examination-remark">
                                <Translate msg={remarksTranslationKey} />
                            </label>
                            {formRenderProps.touched.remarks &&
                                <FormFieldError
                                    error={formRenderProps.errors.remarks}
                                    placeholders={{
                                        fieldName: translator(remarksTranslationKey),
                                    }}
                                />}
                            <p className="remark-warning">
                                {/* eslint-disable-next-line max-len */}
                                <Translate msg={'interventions.medical_examinations.new.steps.select_timeslot.remarks_dialog.fields.remarks.warning'} />
                            </p>
                        </FloatableTextInputWrapper>
                        <FormError error={addTimeslotAsyncInfo.error} />
                        <div className={`${CLASS_NAME}__buttons`}>
                            <SubmitButton id="submit-medical-examination" formName={FORM_NAME} enabledWhenValid={true}>
                                <Translate
                                    msg={submitTranslationKey}
                                />
                            </SubmitButton>
                        </div>
                    </Loader>;
                }}
            />);
    }

    public componentDidUpdate(prevProps: IPrivateProps & ITimeslotRemarksProps) {
        const { onTimeslotPlanned } = this.props;
        if (addOrMoveTimeslotSucceeded(prevProps, this.props)) {
            onTimeslotPlanned();
        }
    }
}

export default connect<IPrivateProps, ITimeslotRemarksProps>({
    stateProps: (state) => {
        const wizardEntity =
            getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);

        const selectedEventId = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.selectedEventId;
        const selectedTimeslot = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.selectedTimeslot;
        const selectedMedicalCenterId = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.filter.selectedMedicalCenterId;
        const selectedEmployee = wizardEntity && wizardEntity.searchEmployee
            && wizardEntity.searchEmployee.selectedEmployee;
        const remarks = wizardEntity && wizardEntity.searchEmployee
            && wizardEntity.selectTime.remarks;
        const movePlanning = wizardEntity && wizardEntity.movePlanning;

        const examinationReason = getPlanMedicalExaminationWizardReason(state);
        const mainMedicalCenter = getCompanyMedicalCenters(state)
            .find((item) => item.id === selectedMedicalCenterId) || null;
        const extraToPlanInfo = (wizardEntity && wizardEntity.selectedMedicalExaminationToPlan)
            || (selectedEmployee && getMedicalExaminationToPlanInfoByEmployeeId(
                state,
                { employeeId: selectedEmployee.id, examinationReason },
            ));

        return {
            selectedEventId,
            selectedTimeslot,
            mainMedicalCenter,
            translator: getTranslatorDeprecated(state),
            addTimeslotAsyncInfo: getAddTimeslotAsyncInfo(state),
            employee: selectedEmployee,
            employeeDetails: getSelectedEmployee(state),
            examinationReason,
            planningRecordId: extraToPlanInfo && extraToPlanInfo.planningRecordId,
            remarks,
            moveTimeslotAsyncInfo: getUpdateTimeslotAsyncInfo(state),
            movePlanning,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardData: (values) => {
                const state = getState();
                const wizardEntity =
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>(state);
                const selectTimeData = wizardEntity && wizardEntity.selectTime;
                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationSingleEmployeeBaseEntity>({
                    selectTime: {
                        ...selectTimeData,
                        ...values.selectTime,
                    },
                }));
            },
            addTimeslot: (payload: IAddTimeslotPayload | IAddTimeslotByPlanningRecordIdPayload) => {
                const state = getState();
                const addTimeslotAsyncInfo = getAddTimeslotAsyncInfo(state);
                if (addTimeslotAsyncInfo.status === AsyncStatus.Busy) {
                    return;
                }
                dispatch(addTimeslotActions.trigger(payload));
            },
            moveTimeslot: (payload: IMoveUpdateTimeslotPayload) => {
                dispatch(triggerMoveUpdateTimeslot(payload));
            },
        };
    },
})(TimeslotRemarks);

function addOrMoveTimeslotSucceeded(
    prevProps: IPrivateProps & ITimeslotRemarksProps,
    props: IPrivateProps & ITimeslotRemarksProps,
) {

    return (prevProps.addTimeslotAsyncInfo.status === AsyncStatus.Busy &&
        props.addTimeslotAsyncInfo.status === AsyncStatus.Success) ||
        (prevProps.moveTimeslotAsyncInfo.status === AsyncStatus.Busy &&
            props.moveTimeslotAsyncInfo.status === AsyncStatus.Success);
}

function showLoaderIfBusy(addTimeslotAsyncInfo: IAsyncFieldInfo, moveTimeslotAsyncInfo: IAsyncFieldInfo) {
    return addTimeslotAsyncInfo.status === AsyncStatus.Busy || moveTimeslotAsyncInfo.status === AsyncStatus.Busy;
}
