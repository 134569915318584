import React, { Component } from 'react';
import classNames from 'classnames';
import { FORM_NAME, TRANSLATIONS_PREFIX } from './shared';
import { fields, IFormValues } from './AddEmployeeFunctionSchema';
export { schema, IFormValues } from './AddEmployeeFunctionSchema';
import JobStudentSection from './JobStudentSection';
import { connect } from '../../../../../index';
import { getSelectedSeatHasStudentWorkers } from '../../../../../../redux/company/selected/selectors';
import FunctionTypeahead from '../../../shared/FunctionTypeahead';
import TranslatorContext from '../../../../../appShell/contexts/TranslatorContext';
import { IFormRenderProps } from '../../../../../common/forms/Form';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard';
import { ICompanyFunction, IAddedCompanyFunction } from '../../../../../../models/admin/companyFunctions';
import { getEmployeeToAdd } from '../../../../../../redux/employee/wizard/selectors';
import {
    getSelectedEmployeeJobStudentAsyncInfo,
    getSelectedEmployeeJobStudent,
} from '../../../../../../redux/employee/info/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import { IEmployeeJobStudent } from '../../../../../../models/admin/employee';
import { isFalsyOrEmptyObject } from '../../../../../../utils/core/object/isEmptyObject';
import { getLocale } from '../../../../../../redux/i18n/selectors';
import { Locales } from '../../../../../../config/i18n.config';

interface IPublicProps {
    formRenderProps: IFormRenderProps<IFormValues>;
    onChangeInput: (values: IFormValues) => void;
}

interface IPrivateProps {
    hasStudentWorkers: boolean;
    employeeToAddCompanyCode: string;
    fetchJobStudentAsyncInfo: IAsyncFieldInfo;
    jobStudent: IEmployeeJobStudent;
    locale: Locales;
}

class FunctionInput extends Component<IPublicProps & IPrivateProps> {
    constructor(props: IPublicProps & IPrivateProps) {
        super(props);

        this.onFunctionSelected = this.onFunctionSelected.bind(this);
        this.onFunctionAdded = this.onFunctionAdded.bind(this);
    }

    public render() {
        const { formRenderProps, hasStudentWorkers, employeeToAddCompanyCode } = this.props;
        const { values, touched, errors } = formRenderProps;

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <>
                        <div className={classNames('container', WIZARDFLOW_CLASSES.NARROW_FORM)}>
                            <FunctionTypeahead
                                id={`${FORM_NAME}_function`}
                                name={fields.functionId}
                                value={values.functionId}
                                initialFilter={values.functionDescription}
                                onItemSelected={this.onFunctionSelected}
                                onFunctionAdded={this.onFunctionAdded}
                                isInvalid={touched.functionId && !!errors.functionId}
                                showFullFamily={false}
                                companyCodeOverride={employeeToAddCompanyCode}
                                placeholder={translator(`${TRANSLATIONS_PREFIX}.search.placeholder`)}
                                immediatelyTriggerSearch={false}
                            />
                            {touched.functionId && (
                                <FormFieldError
                                    error={errors.functionId}
                                    placeholders={{ fieldName: translator(`${TRANSLATIONS_PREFIX}.search.label`) }}
                                />
                            )}

                            {hasStudentWorkers && (
                                <JobStudentSection formRenderProps={formRenderProps} />
                            )}
                        </div>
                    </>
                )}
            </TranslatorContext.Consumer>
        );
    }

    public componentDidUpdate(prevProps: IPublicProps & IPrivateProps) {
        const { setFieldValue } = this.props.formRenderProps;
        if (
            prevProps.fetchJobStudentAsyncInfo.status === AsyncStatus.Busy &&
            this.props.fetchJobStudentAsyncInfo.status === AsyncStatus.Success
        ) {
            if (isFalsyOrEmptyObject(this.props.jobStudent)) {
                return;
            }

            const { studentWorker, safetyFunction, employer, medicalFunction } = this.props.jobStudent;
            setFieldValue('studentWorker', studentWorker ? true : false);
            setFieldValue('safetyFunction', safetyFunction ? true : false);
            setFieldValue('medicalFunction', medicalFunction ? true : false);
            setFieldValue('employer', employer || '');
            setFieldValue('sendMail', true);
        }
    }

    private onFunctionSelected(functionId: number, companyFunction: ICompanyFunction) {
        const { setFieldValue } = this.props.formRenderProps;

        setFieldValue('functionId', functionId);
        setFieldValue('functionDescription', (companyFunction && companyFunction.description) || '');
    }

    private onFunctionAdded(addedFunction: IAddedCompanyFunction) {
        const { locale } = this.props;
        const { setFieldValue } = this.props.formRenderProps;

        setFieldValue('functionId', addedFunction.id);
        if (locale === Locales.nl_BE) {
            setFieldValue('functionDescription', addedFunction.descriptionNL || '');
        } else if (locale === Locales.fr_BE) {
            setFieldValue('functionDescription', addedFunction.descriptionFR || '');
        }
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const employeeToAdd = getEmployeeToAdd(state);
        return {
            hasStudentWorkers: getSelectedSeatHasStudentWorkers(state),
            employeeToAddCompanyCode: employeeToAdd && employeeToAdd.company.companyCode,
            fetchJobStudentAsyncInfo: getSelectedEmployeeJobStudentAsyncInfo(state),
            jobStudent: getSelectedEmployeeJobStudent(state),
            locale: getLocale(state),
        };
    },
})(FunctionInput);
