import { createAction, createSuccessAction, createFailAction } from '../../index';
import {
    FETCH_EMPLOYEE_DETAILS,
    FETCH_EMPLOYEE_STATUTES,
    FETCH_EMPLOYEE_ABSENCES,
    UPDATE_EMPLOYEE,
    UPDATE_EMPLOYEE_EMPLOYMENT,
    SET_EMPLOYEE_OUT_OF_SERVICE,
    UPDATE_EMPLOYEE_RISK,
    REMOVE_EMPLOYEE_RISK,
    ADD_EMPLOYEE_STATUTE,
    ADD_EMPLOYEE_ABSENCE,
    UPDATE_EMPLOYEE_ABSENCE,
    REMOVE_EMPLOYEE_ABSENCE,
    UPDATE_EMPLOYEE_ALL_FIELDS,
    FETCH_SMALL_EMPLOYEE_DETAILS,
    FETCH_EMPLOYEE_RISKS_AND_RESEARCHES,
    FETCH_EMPLOYEE_PERSONAL_RISKS,
    ADD_EMPLOYEE_PERSONAL_RISK,
    CHANGE_EMPLOYEE_OUT_OF_SERVICE,
    CLEAR_EMPLOYEE_OUT_OF_SERVICE,
    FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS,
    UPDATE_EMPLOYEE_COST_CENTER,
    UPDATE_EMPLOYEE_STATUTE,
    REMOVE_EMPLOYEE_STATUTE,
    FETCH_EMPLOYEE_JOB_STUDENT,
    UPDATE_EMPLOYEE_JOB_STUDENT,
    ADD_EMPLOYEE_JOB_STUDENT,
    FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ,
    SET_JOB_STUDENT_REQUEST_FAILED_WARNING,
    FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES,
    FETCH_EMPLOYEE_STATUS,
} from './types';
import {
    IEmployeeDetails,
    IEmployeeStatute,
    IEmployeeAbsence,
    IUpdateEmployeePayload,
    IUpdateEmployeeSucceededPayload,
    IUpdateEmployeeEmploymentPayload,
    IEmployeeNewRecordPayload,
    ISetEmployeeOutOfServicePayload,
    IFetchEmployeeDetailsPayload,
    IUpdateEmployeeRiskPayload,
    IRemoveEmployeeRiskPayload,
    IAddEmployeeStatutePayload,
    IAddEmployeeAbsencePayload,
    IUpdateEmployeeAbsencePayload,
    IRemoveEmployeeAbsencePayload,
    IFetchEmployeeRisksAndResearchesPayload,
    IEmployeeRisksAndResearches,
    IUpdateEmployeeAllFieldsPayload,
    IUpdateEmployeeAllFieldsSucceededPayload,
    IFetchEmployeePersonalRisksPayload,
    IPersonalRisk,
    IAddPersonalRiskPayload,
    IChangeEmployeeOutOfServicePayload,
    IClearEmployeeOutOfServicePayload,
    IFetchEmployeeMedicalExaminationsPayload,
    IEmployeeMedicalExaminations,
    IUpdateEmployeeCostCenterPayload,
    IUpdateEmployeeStatutePayload,
    IRemoveEmployeeStatutePayload,
    IEmployeeJobStudent,
    IUpdateEmployeeJobStudentPayload,
    IFetchEmployeeJobStudentPayload,
    IAddEmployeeJobStudentPayload,
    IFetchEmployeeDetailsByInszPayload,
    ISetJobStudentRequestFailedWarning,
    IFetchEmployeeFunctionRisksAndResearchesPayload,
    IEmployeeFunctionRisksAndResearches,
    IFetchEmployeeStatusPayload,
    ISelectedEmployeeStatus,
} from '../../../models/admin/employee';
import { ITraceableApiError } from '../../../models/general/error';
import { ITraceableAsyncRequestPayload } from '../../../models/general/redux';
import { createTypeActions, createResetAction } from '../../../utils/libs/redux/createAction';

// Employee detail

export function fetchSmallEmployeeDetails(payload: IFetchEmployeeDetailsPayload) {
    return createAction<IFetchEmployeeDetailsPayload>(FETCH_SMALL_EMPLOYEE_DETAILS, payload);
}

export function fetchEmployeeDetails(payload: IFetchEmployeeDetailsPayload) {
    return createAction<IFetchEmployeeDetailsPayload>(FETCH_EMPLOYEE_DETAILS, payload);
}

export function fetchEmployeeDetailsSucceeded(payload: IEmployeeDetails) {
    return createSuccessAction<IEmployeeDetails>(FETCH_EMPLOYEE_DETAILS, payload);
}

export function fetchEmployeeDetailsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEE_DETAILS, error);
}

export function fetchEmployeeDetailsReset() {
    return createResetAction(FETCH_EMPLOYEE_DETAILS, {});
}

export const fetchSmallEmployeeDetailsByInszActions = createTypeActions<IFetchEmployeeDetailsByInszPayload>({
    type: FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ,
});

// Statutes

export function fetchEmployeeStatutes(payload: IFetchEmployeeDetailsPayload) {
    return createAction<IFetchEmployeeDetailsPayload>(FETCH_EMPLOYEE_STATUTES, payload);
}

export function fetchEmployeeStatutesSucceeded(payload: IEmployeeStatute[]) {
    return createSuccessAction<IEmployeeStatute[]>(FETCH_EMPLOYEE_STATUTES, payload);
}

export function fetchEmployeeStatutesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEE_STATUTES, error);
}

export function addEmployeeStatute(payload: IAddEmployeeStatutePayload) {
    return createAction<IAddEmployeeStatutePayload>(ADD_EMPLOYEE_STATUTE, payload);
}

export function addEmployeeStatuteSucceeded() {
    return createSuccessAction(ADD_EMPLOYEE_STATUTE, {});
}

export function addEmployeeStatuteFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(ADD_EMPLOYEE_STATUTE, error);
}

// Absences

export function fetchEmployeeAbsences(payload: IFetchEmployeeDetailsPayload) {
    return createAction<IFetchEmployeeDetailsPayload>(FETCH_EMPLOYEE_ABSENCES, payload);
}

export function fetchEmployeeAbsencesSucceeded(payload: IEmployeeAbsence[]) {
    return createSuccessAction<IEmployeeAbsence[]>(FETCH_EMPLOYEE_ABSENCES, payload);
}

export function fetchEmployeeAbsencesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEE_ABSENCES, error);
}

export function addEmployeeAbsence(payload: IAddEmployeeAbsencePayload) {
    return createAction<IAddEmployeeAbsencePayload>(ADD_EMPLOYEE_ABSENCE, payload);
}

export function addEmployeeAbsenceSucceeded() {
    return createSuccessAction(ADD_EMPLOYEE_ABSENCE, {});
}

export function addEmployeeAbsenceFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(ADD_EMPLOYEE_ABSENCE, error);
}

export function updateEmployeeAbsence(payload: IUpdateEmployeeAbsencePayload) {
    return createAction<IUpdateEmployeeAbsencePayload>(UPDATE_EMPLOYEE_ABSENCE, payload);
}

export function updateEmployeeAbsenceSucceeded() {
    return createSuccessAction(UPDATE_EMPLOYEE_ABSENCE, {});
}

export function updateEmployeeAbsenceFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(UPDATE_EMPLOYEE_ABSENCE, error);
}

export function removeEmployeeAbsence(payload: IRemoveEmployeeAbsencePayload) {
    return createAction<IRemoveEmployeeAbsencePayload>(REMOVE_EMPLOYEE_ABSENCE, payload);
}

export function removeEmployeeAbsenceSucceeded() {
    return createSuccessAction(REMOVE_EMPLOYEE_ABSENCE, {});
}

export function removeEmployeeAbsenceFailed(error: ITraceableApiError) {
    return createFailAction(REMOVE_EMPLOYEE_ABSENCE, error);
}

// Update employee cost center

export function updateEmployeeCostCenter(payload: IUpdateEmployeeCostCenterPayload) {
    return createAction<IUpdateEmployeeCostCenterPayload>(UPDATE_EMPLOYEE_COST_CENTER, payload);
}

export function updateEmployeeCostCenterSucceeded() {
    return createSuccessAction(UPDATE_EMPLOYEE_COST_CENTER, {});
}

export function updateEmployeeCostCenterFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(UPDATE_EMPLOYEE_COST_CENTER, error);
}

// Update employee

export function updateEmployee(payload: IUpdateEmployeePayload) {
    return createAction<IUpdateEmployeePayload>(UPDATE_EMPLOYEE, payload);
}

export function updateEmployeeSucceeded(payload: IUpdateEmployeeSucceededPayload) {
    return createSuccessAction(UPDATE_EMPLOYEE, payload);
}

export function updateEmployeeFailed(error: ITraceableApiError & ITraceableAsyncRequestPayload) {
    return createFailAction<ITraceableApiError>(UPDATE_EMPLOYEE, error);
}

export function updateEmployeeReset() {
    return createResetAction(UPDATE_EMPLOYEE, {});
}

export function updateEmployeeAllFields(payload: IUpdateEmployeeAllFieldsPayload) {
    return createAction<IUpdateEmployeeAllFieldsPayload>(UPDATE_EMPLOYEE_ALL_FIELDS, payload);
}

export function updateEmployeeAllFieldsSucceeded(payload: IUpdateEmployeeAllFieldsSucceededPayload) {
    return createSuccessAction<IUpdateEmployeeAllFieldsSucceededPayload>(UPDATE_EMPLOYEE_ALL_FIELDS, payload);
}

export function updateEmployeeAllFieldsFailed(error: ITraceableApiError & ITraceableAsyncRequestPayload) {
    return createFailAction<ITraceableApiError>(UPDATE_EMPLOYEE_ALL_FIELDS, error);
}

// Update employee employment

export function updateEmployeeEmployment(payload: IUpdateEmployeeEmploymentPayload) {
    return createAction<IUpdateEmployeeEmploymentPayload>(UPDATE_EMPLOYEE_EMPLOYMENT, payload);
}

export function updateEmployeeEmploymentSucceeded(payload: IEmployeeNewRecordPayload) {
    return createSuccessAction(UPDATE_EMPLOYEE_EMPLOYMENT, payload);
}

export function updateEmployeeEmploymentFailed(error: ITraceableApiError) {
    return createFailAction(UPDATE_EMPLOYEE_EMPLOYMENT, error);
}

export function setEmployeeOutOfService(payload: ISetEmployeeOutOfServicePayload) {
    return createAction<ISetEmployeeOutOfServicePayload>(SET_EMPLOYEE_OUT_OF_SERVICE, payload);
}

export function setEmployeeOutOfServiceSucceeded() {
    return createSuccessAction(SET_EMPLOYEE_OUT_OF_SERVICE, {});
}

export function setEmployeeOutOfServiceFailed(error: ITraceableApiError) {
    return createFailAction(SET_EMPLOYEE_OUT_OF_SERVICE, error);
}

export function changeEmployeeOutOfService(payload: IChangeEmployeeOutOfServicePayload) {
    return createAction<IChangeEmployeeOutOfServicePayload>(CHANGE_EMPLOYEE_OUT_OF_SERVICE, payload);
}

export function changeEmployeeOutOfServiceSucceeded() {
    return createSuccessAction(CHANGE_EMPLOYEE_OUT_OF_SERVICE, {});
}

export function changeEmployeeOutOfServiceFailed(error: ITraceableApiError) {
    return createFailAction(CHANGE_EMPLOYEE_OUT_OF_SERVICE, error);
}

export function clearEmployeeOutOfService(payload: IClearEmployeeOutOfServicePayload) {
    return createAction<IClearEmployeeOutOfServicePayload>(CLEAR_EMPLOYEE_OUT_OF_SERVICE, payload);
}

export function clearEmployeeOutOfServiceSucceeded() {
    return createSuccessAction(CLEAR_EMPLOYEE_OUT_OF_SERVICE, {});
}

export function clearEmployeeOutOfServiceFailed(error: ITraceableApiError) {
    return createFailAction(CLEAR_EMPLOYEE_OUT_OF_SERVICE, error);
}

// Risk

export const updateEmployeeRiskActions =
    createTypeActions<IUpdateEmployeeRiskPayload>({
        type: UPDATE_EMPLOYEE_RISK,
    });

export const removeEmployeeRiskActions =
    createTypeActions<IRemoveEmployeeRiskPayload>({
        type: REMOVE_EMPLOYEE_RISK,
    });

// Statutes

export const updateEmployeeStatutesActions =
    createTypeActions<IUpdateEmployeeStatutePayload>({
        type: UPDATE_EMPLOYEE_STATUTE,
    });

export const removeEmployeeStatuteActions =
    createTypeActions<IRemoveEmployeeStatutePayload>({
        type: REMOVE_EMPLOYEE_STATUTE,
    });

// Employee risks and researches

export function fetchEmployeeRisksAndResearches(payload: IFetchEmployeeRisksAndResearchesPayload) {
    return createAction<IFetchEmployeeRisksAndResearchesPayload>(FETCH_EMPLOYEE_RISKS_AND_RESEARCHES, payload);
}

export function fetchEmployeeRisksAndResearchesSucceeded(payload: IEmployeeRisksAndResearches) {
    return createSuccessAction<IEmployeeRisksAndResearches>(FETCH_EMPLOYEE_RISKS_AND_RESEARCHES, payload);
}

export function fetchEmployeeRisksAndResearchesFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEE_RISKS_AND_RESEARCHES, error);
}

// Employee function risk and researches

export const fetchEmployeeFunctionRisksAndResearchesActions =
    createTypeActions<IFetchEmployeeFunctionRisksAndResearchesPayload, IEmployeeFunctionRisksAndResearches>({
        type: FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES,
    });

// Employee personal risks

export function fetchEmployeePersonalRisks(payload: IFetchEmployeePersonalRisksPayload) {
    return createAction<IFetchEmployeePersonalRisksPayload>(FETCH_EMPLOYEE_PERSONAL_RISKS, payload);
}

export function fetchEmployeePersonalRisksSucceeded(payload: IPersonalRisk[]) {
    return createSuccessAction<IPersonalRisk[]>(FETCH_EMPLOYEE_PERSONAL_RISKS, payload);
}

export function fetchEmployeePersonalRisksFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEE_PERSONAL_RISKS, error);
}

// Add personal risk to employee

export function addEmployeePersonalRisk(payload: IAddPersonalRiskPayload) {
    return createAction<IAddPersonalRiskPayload>(ADD_EMPLOYEE_PERSONAL_RISK, payload);
}

export function addEmployeePersonalRiskSucceeded() {
    return createSuccessAction(ADD_EMPLOYEE_PERSONAL_RISK, {});
}

export function addEmployeePersonalRiskFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(ADD_EMPLOYEE_PERSONAL_RISK, error);
}

// Employee medical examinations

export function fetchEmployeeMedicalExaminations(payload: IFetchEmployeeMedicalExaminationsPayload) {
    return createAction<IFetchEmployeeMedicalExaminationsPayload>(FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS, payload);
}

export function fetchEmployeeMedicalExaminationsSucceeded(payload: IEmployeeMedicalExaminations) {
    return createSuccessAction<IEmployeeMedicalExaminations>(FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS, payload);
}

export function fetchEmployeeMedicalExaminationsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS, error);
}

// Employee job student

export const fetchEmployeeJobStudentActions =
    createTypeActions<IFetchEmployeeJobStudentPayload, IEmployeeJobStudent>({
        type: FETCH_EMPLOYEE_JOB_STUDENT,
    });

export const addEmployeeJobStudentActions =
    createTypeActions<IAddEmployeeJobStudentPayload>({
        type: ADD_EMPLOYEE_JOB_STUDENT,
    });

export const updateEmployeeJobStudentActions =
    createTypeActions<IUpdateEmployeeJobStudentPayload>({
        type: UPDATE_EMPLOYEE_JOB_STUDENT,
    });

// Open job student request failed warning

export const setJobStudentRequestFailedWarningActions =
    createTypeActions<ISetJobStudentRequestFailedWarning>({
        type: SET_JOB_STUDENT_REQUEST_FAILED_WARNING,
    });

// Employee Status

export const fetchEmployeeStatusActions =
    createTypeActions<IFetchEmployeeStatusPayload, ISelectedEmployeeStatus>({
        type: FETCH_EMPLOYEE_STATUS,
    });
