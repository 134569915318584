import { CreateLogic } from 'redux-logic/definitions/logic';
import { createSelector } from 'reselect';
export { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';
import { IState } from './IState';
import Api from '../api';
import createEpicUtil, {
    IEpicConfig, EpicDepObj, ActionObj,
} from '../utils/libs/redux/epic/createEpic';
import {
    AsyncStatus,
    IAsyncFetchField, IAsyncDoField,
    IAsyncFieldInfo, IAction,
} from '../models/general/redux';
import {
    createAsyncFetchActionHandlers as createAsyncFetchActionHandlersUtil,
    createAsyncDoActionHandlers as createAsyncDoActionHandlersUtil,
    ICreateAsyncDoActionHandlersConfig,
    ICreateAsyncFetchActionHandlersConfig,
} from '../utils/libs/redux/async/asyncReducerUtils';
import { IRequestWrapperPromise } from '../utils/api/requestWrapper';
import { TProcessReturnHook, TProcessMultipleHook, TEpicDependencies } from '../utils/libs/redux/epic/typings';
import { triggerFlashThatDownloadToMessageCenterIsTriggered } from './flashMessages/actions';
import { getStore } from './storeNoCircularDependencies';

export { IState, getReducerState } from './IState';

export {
    createAction,
    createSuccessAction,
    createFailAction,
    createCancelAction,
    createResetAction,
    createTypeActions,
} from '../utils/libs/redux/createAction';
export { createActionHandler } from '../utils/libs/redux/createActionHandler';
export { registerReducer } from '../utils/libs/redux/registerReducer';

export { IAsyncFetchField, IAsyncDoField } from '../models/general/redux';
export { getAsyncFetchInitialState, getAsyncDoInitialState } from '../utils/libs/redux/async/asyncReducerUtils';

let epicsCreated = 0;
const apiCallsInProgress: { [epicId: string]: IRequestWrapperPromise<{}>[] } = {};

interface INoRerender {
    EMPTY_LIST: [];
    EMPTY_OBJECT: {};
    ASYNC_FETCH_BUSY: IAsyncFieldInfo;
}

/**
 * Constants to be returned by selectors if they have to return e.g. an empty list or object, otherwise re-creating
 * a new [] or {} - each time the selector gets called - would result in unnecessary re-renders.
 */
export const NO_RERENDER: INoRerender = {
    EMPTY_LIST: [],
    EMPTY_OBJECT: {},
    ASYNC_FETCH_BUSY: {
        status: AsyncStatus.Busy,
        error: null,
    },
};
Object.freeze(NO_RERENDER);
Object.freeze(NO_RERENDER.EMPTY_LIST);
Object.freeze(NO_RERENDER.EMPTY_OBJECT);
Object.freeze(NO_RERENDER.ASYNC_FETCH_BUSY);

interface ICustomEpicDependencies {
    api: typeof Api;
}

type TProjectEpicDependencies<Payload extends object, Query extends object> =
    TEpicDependencies<IState, ActionObj<Payload, Query>, ICustomEpicDependencies>;

type TEpicProcessReturnHook<Payload extends object, Query extends object>
    = TProcessReturnHook<IState, ActionObj<Payload, Query>, ICustomEpicDependencies>;
type TEpicProcessMultipleHook<Payload extends object, Query extends object>
    = TProcessMultipleHook<IState, ActionObj<Payload, Query>, ICustomEpicDependencies>;

export interface IParallelCallInput extends ICustomEpicDependencies {
    dispatch?: (action: IAction<{}>) => void;
}

export function createEpic<Payload extends object, Query extends object = {}>(
    config: IEpicConfig<ICustomEpicDependencies, IState, Payload, Query>,
    customConfig: Partial<CreateLogic.Config<
        IState,
        ActionObj<Payload, Query>,
        EpicDepObj<IState, Payload, Query, ICustomEpicDependencies>,
        {},
        string
        >> = {},
) {
    epicsCreated += 1;
    const epicId = `epic-${epicsCreated}`;

    const epic = createEpicUtil<ICustomEpicDependencies, IState, Payload, Query>(
        epicId,
        config,
        {
            ...customConfig,
        },
    );

    if (config.latest && typeof epic.process === 'function') {
        apiCallsInProgress[epicId] = [];
        epic.process = wrapApiDependencyToCancelPreviousEpic<Payload, Query>(
            epicId,
            epic.process as TEpicProcessMultipleHook<Payload, Query>,
        );
    }

    return epic;
}

/** Memoization selector */
export function makeAsyncFetchInfoSelector<Data>(asyncFetchFieldSelector: (state: IState) => IAsyncFetchField<Data>) {
    return createSelector(
        asyncFetchFieldSelector,
        (asyncFetchField) => getAsyncFetchInfo(asyncFetchField),
    );
}

export function getAsyncFetchInfo<Data>(asyncFetchField: IAsyncFetchField<Data>): IAsyncFieldInfo {
    return {
        status: asyncFetchField.error !== null ?
            AsyncStatus.Error : asyncFetchField.isFetching ?
                AsyncStatus.Busy : asyncFetchField.data !== null ?
                    AsyncStatus.Success : AsyncStatus.Initial,
        error: asyncFetchField.error,
    };
}

/** Memoization selector */
export function makeAsyncDoInfoSelector(asyncDoFieldSelector: (state: IState) => IAsyncDoField) {
    return createSelector(
        asyncDoFieldSelector,
        (asyncDoField) => getAsyncDoInfo(asyncDoField),
    );
}

export function getAsyncDoInfo(asyncDoField: IAsyncDoField): IAsyncFieldInfo {
    return {
        status: asyncDoField.error !== null ?
            AsyncStatus.Error : asyncDoField.isDoing ?
                AsyncStatus.Busy : asyncDoField.isDone ?
                    AsyncStatus.Success : AsyncStatus.Initial,
        error: asyncDoField.error,
    };
}

export function createAsyncFetchActionHandlers<Data, ReducerState, SuccessActionPayload extends object = {}>(
    config: ICreateAsyncFetchActionHandlersConfig<Data, ReducerState, SuccessActionPayload>,
) {
    return createAsyncFetchActionHandlersUtil<Data, ReducerState, SuccessActionPayload>(config);
}

export function createAsyncDoActionHandlers<ReducerState>(
    config: ICreateAsyncDoActionHandlersConfig<ReducerState>,
) {
    return createAsyncDoActionHandlersUtil<ReducerState>(config);
}

function wrapApiDependencyToCancelPreviousEpic<Payload extends object, Query extends object = {}>(
    epicId: string,
    process: TEpicProcessReturnHook<Payload, Query> | TEpicProcessMultipleHook<Payload, Query>) {

    return (
        deps: TProjectEpicDependencies<Payload, Query>,
        dispatch?: (action) => void,
        done?: () => void,
    ) => {
        stopPreviousApiRequests(epicId);

        const { api } = deps;
        const dependencies = {
            ...deps,
            api: wrapApiFunctionsRecursively(epicId, api) as typeof api,
        };

        if (dispatch || done) {
            return (process as TEpicProcessMultipleHook<Payload, Query>)(dependencies, dispatch, done);
        }
        return (process as TEpicProcessReturnHook<Payload, Query>)(dependencies);
    };
}

function wrapApiFunctionsRecursively(epicId: string, api: object) {
    return Object.keys(api).reduce(
        (accumulator, key) => {
            if (typeof api[key] === 'object') {
                accumulator[key] = wrapApiFunctionsRecursively(epicId, api[key]);
            } else if (typeof api[key] === 'function') {
                accumulator[key] = async (...args) => {
                    const apiCall = api[key](...args);
                    if (apiCall instanceof Promise) {
                        trackApiRequest(epicId, apiCall);
                        const result = await apiCall;
                        stopTrackingApiRequest(epicId, apiCall);
                        return result;
                    }
                    return apiCall;
                };
            } else {
                accumulator[key] = api[key];
            }
            return accumulator;
        },
        {},
    );
}

function trackApiRequest(epicId: string, apiCall: IRequestWrapperPromise<{}>) {
    apiCallsInProgress[epicId].push(apiCall);
}

function stopTrackingApiRequest(epicId: string, apiCall: IRequestWrapperPromise<{}>) {
    const index = apiCallsInProgress[epicId].indexOf(apiCall);
    if (index !== -1) {
        apiCallsInProgress[epicId].splice(index, 1);
    }
}

function stopPreviousApiRequests(epicId: string) {
    apiCallsInProgress[epicId].forEach((apiCall) => {
        if (typeof apiCall.cancelRequest === 'function') {
            apiCall.cancelRequest();
        } else {
            stopTrackingApiRequest(epicId, apiCall);
        }
    });
}

export function triggerDownloadToMessageCenterFlashIfDelayed(delayed: boolean) {
    if (delayed) {
        getStore().dispatch(triggerFlashThatDownloadToMessageCenterIsTriggered());
    }
}
