import { IAddress, IAddressSave } from '../general/address';
import { ICompany, ICompanyCodePayload } from './company';
import { ITraceableAsyncRequestPayload } from '../general/redux';
import { IRisk } from './risks';
import { IResearch } from './researches';
import { IBranch } from '../general/branch';

export interface IPerson {
    firstName: string;
    name: string;
}

export interface IEmployee extends IPerson {
    id: number; // = employeeCustomerId
    company: ICompany;
    nationalRegisterNumber: string;
    dateInFunction: string;
    dateInService: string;
    employeeCode: string;
    employeeId: number;
    employeeNumber: string;
    function: IFunction;
    absent: boolean;
    dateOutOfService: string;
    newFunction?: IFunction;
    newCompany?: ICompany;
    newEmployee?: boolean;
}

export interface IEmployeeDetails extends IEmployee {
    address: IAddress;
    birthDate: string;
    email: string;
    fitnessToDriveValidUntil: string;
    futureEmployment: boolean;
    languageCode: string;
    languageId: number;
    medicalCenterId: number;
    medicalCenterIdCompany: number;
    medicalCenterIdSeat: number;
    mobilePhone: string;
    nationalityId: number;
    phone: string;
    sexId: number;
    costCenter: ICostCenter;
    outOfServiceReason: string;
    toBePlanned: boolean;
}

export interface IEmployeeWithoutEmail extends IEmployee {
    email?: string; // Has email prop because it can be added (and saved in state) via the AddEmails list
}

export interface ICostCenter {
    dateInactive?: string;
    dateActive?: string;
    name: string;
    description: string;
}

export interface IFetchEmployeeDetailsPayload {
    id: number;
}

export interface IFetchEmployeeDetailsByInszPayload {
    nationalRegisterNumber: string;
    companyCode: string;
}

export interface IFetchEmployeesPayload extends IFetchEmployeesFilters {
    refreshSources: 'true' | 'false';
    showFutureEmployees?: boolean;
    maxRecordCount?: number;
    search?: string;
    showFullFamily?: boolean;
    companyCode?: string;
}

export interface IFetchEmployeesWithoutEmailPayload {
    showFullFamily?: boolean;
    refreshSources?: boolean;
    companyCode: string;
}

export interface IFetchEmployeesFilters {
    search?: string;
    functionFilter?: string;
    seatFilter?: string;
    nameFilter?: string;
    conditionFilter?: string;
}

export interface IFetchEmployeesData {
    employees: IEmployee[];
    count: number;
}

export interface IFunction {
    id: number;
    description: string;
}

export type TEmployeeToAddJobStudentData =
    Pick<IUpdateEmployeeJobStudentPayload, 'employer' | 'safetyFunction' | 'medicalFunction' | 'sendMail'>;

export interface IEmployeeToAdd<Address = IAddressSave> extends IPerson {
    nationalRegisterNumber?: string;
    birthDate?: string;
    isForeignEmployee: boolean;
    company: {
        companyCode: string;
        name?: string;
    };
    dateInFunction: string;
    dateInService: string;
    function: {
        id: number;
        // only for display
        description?: string;
    };
    id: number; // if employee exists, for redirecting to details page
    employeeId: number; // if employee exists
    address: Address;
    email: string;
    languageId: number;
    phone: string;
    mobilePhone: string;
    nationalityId: number;
    sexId: number;
    employeeNumber: string;
    medicalCenterId: number;
    medicalCenter?: {
        address: string;
        name: string;
    };
    jobStudent: TEmployeeToAddJobStudentData;
    studentWorker: boolean;
}

export interface IAddEmployeeSucceededPayload {
    id: number;
    employeeId: number;
}

export interface IFetchEmployeeByInszPayload {
    nationalRegisterNumber: string;
    isInterim?: boolean;
    isForeignEmployee?: string;
}

export interface IFetchEmployeeByInszRequestPayload {
    nationalRegisterNumber: string;
    selectedCompanyCompanyCode: string;
}

export type TEmployeeUpdateFields<Address = IAddressSave> = Pick<IEmployeeToAdd<Address>,
    'firstName' | 'name' | 'email' | 'languageId' |
    'nationalRegisterNumber' | 'phone' |
    'mobilePhone' | 'address' | 'medicalCenterId'
    | 'sexId' | 'nationalityId' | 'address' | 'employeeNumber'
>;

export interface IUpdateEmloyeeByIdPayload extends ITraceableAsyncRequestPayload {
    employeeData: Partial<TEmployeeUpdateFields>;
    id: number;
    updatedFromAddEmailList?: boolean;
}

export interface IUpdateEmloyeeByInszPayload extends ITraceableAsyncRequestPayload, ICompanyCodePayload {
    employeeData: Partial<TEmployeeUpdateFields>;
    nationalRegisterNumber: string;
}

export type IUpdateEmployeePayload = IUpdateEmloyeeByIdPayload | IUpdateEmloyeeByInszPayload;

export interface IUpdateEmployeeSucceededPayload extends
    Partial<TEmployeeUpdateFields<IAddress>>, ITraceableAsyncRequestPayload {
    absent: boolean;
    id: number;
}

export interface IUpdateEmployeeAllFieldsPayload {
    employeeData: Partial<TEmployeeUpdateFields>;
    id: number;
}

export interface IUpdateEmployeeAllFieldsSucceededPayload extends
    Partial<TEmployeeUpdateFields<IAddress>> {
}

export interface IUpdateEmployeeAllFieldsAndEmploymentPayload {
    employee: Partial<IEmployeeToAdd>;
    employeeInitial: Partial<IEmployeeToAdd>;
}

export interface IUpdateEmployeeAllFieldsAndEmploymentSucceededPayload {
    employee: Partial<IEmployeeToAdd>;
}

export interface IDescriptionPeriod {
    id: string;
    personalId: string;
    description: string;
    beginDate: string;
    endDate?: string;
}

export interface IEmployeeStatute extends IDescriptionPeriod {
    code: string;
}

export interface IEmployeeAbsence extends IDescriptionPeriod {
    code: string;
}

export interface IEmployeeJobStudent {
    dateLastSendPass: string;
    employer: string;
    interimEmployeeId: number;
    mailSent: boolean;
    medicalFunction: boolean;
    safetyFunction: boolean;
    studentWorker: boolean;
}

export interface IAddEmployeeJobStudentPayload
    extends Pick<IEmployeeJobStudent, 'employer' | 'safetyFunction' | 'medicalFunction'> {
    id: number;
    sendMail: boolean;
    emailAddressToUpdate: string;
}

export interface IUpdateEmployeeJobStudentPayload
    extends Partial<Pick<IEmployeeJobStudent, 'employer' | 'safetyFunction' | 'medicalFunction' | 'studentWorker'>> {
    id: number;
    interimEmployeeId: number;
    sendMail: boolean;
    emailAddressToUpdate: string;
}

export enum ADD_EMPLOYEE_WIZARD_STEP_ID {
    NATIONAL_REGISTER_NUMBER = 'insz',
    PERSONAL_DATA = 'data',
    STARTDATE = 'start',
    SEAT = 'seat',
    FUNCTION = 'function',
    VALIDATE = 'validate',
    INTERIM_STARTDATE = 'interim-start',
}

export interface IAddEmployeeWizardPayload {
    step: string;
}

export interface IUpdateEmployeeEmploymentPayload {
    id: number;
    customerFunctionId: number;
    dateInFunction: string;
    companyCode: string;
    dateInService: string;
    duplicate?: {
        risks: boolean;
    };
}

export interface IEmployeeNewRecordPayload {
    id: number;
    shouldUseNewId: boolean;
}

export interface ISetEmployeeOutOfServicePayload {
    id: number;
    reasonOutService: string;
    dateInFunction: string;
    dateInService: string;
    dateOutService: string;
}

export interface IChangeEmployeeOutOfServicePayload {
    id: number;
    reasonOutService: string;
    dateInFunction: string;
    dateInService: string;
    dateOutService: string;
}

export interface IClearEmployeeOutOfServicePayload {
    id: number;
    dateInFunction: string;
    dateInService: string;
}

export interface IUpdateEmployeeRiskPayload {
    employeeId: number;
    personalId: string;
    dateInactive: string;
}

export interface IUpdateEmployeeStatutePayload {
    employeeId: number;
    personalId: string;
    dateInactive: string;
}

export interface IUpdateEmployeeAbsencePayload {
    id: number;
    personalId: string;
    riskCode: string;
    startDate: string;
    endDate?: string;
}

export interface IUpdateEmployeeCostCenterPayload {
    id: number;
    costCenter: string;
}

export interface IRemoveEmployeeAbsencePayload {
    id: number;
    personalId: string;
    riskCode: string;
    startDate: string;
}

export interface IRemoveEmployeeRiskPayload {
    employeeId: number;
    personalId: string;
}

export interface IRemoveEmployeeStatutePayload {
    employeeId: number;
    personalId: string;
}

export interface IAddEmployeeStatutePayload {
    id: number;
    riskId: number;
    dateActive: string;
    dateInactive?: string;
}

export interface IAddEmployeeAbsencePayload {
    id: number;
    riskCode: string;
    startDate: string;
    endDate?: string;
}

// Employee risks and researches

export interface IFetchEmployeeFunctionRisksAndResearchesPayload {
    functionId: number;
}

export interface IEmployeeFunctionRisksAndResearches {
    risks: IRisk[];
    researches: IResearch[];
}

export interface IFetchEmployeeRisksAndResearchesPayload {
    id: number;
}

export interface IEmployeeRisksAndResearches {
    [key: string]: IEmployeeRiskAndResearch[];
}

export interface IEmployeeRiskAndResearch {
    riskCode: string;
    riskDescription: string;
    researchCode: string;
    researchDescription: string;
}

// Personal risk

export interface IFetchEmployeePersonalRisksPayload {
    id: number;
}

export interface IPersonalRisk {
    description: string;
    id: string;
    personalId: string;
    code: string;
    beginDate: string;
}

// Employee convocation

export interface IFetchEmployeeConvocationPayload {
    id: number;
}

// Personal risk
export interface IAddPersonalRiskPayload {
    employee: {
        id: number,
    };
    dateActive: string;
    riskId: string;
}

export interface IAddedPersonalRisk extends IAddPersonalRiskPayload {
    id: number;
}

export interface IDuplicateEmployeeRisksPayload {
    idFrom: number;
    idTo: number;
    absences: boolean;
    statutes: boolean;
    risks: boolean;
}

// Employee medical examinations

export interface IFetchEmployeeMedicalExaminationsPayload {
    id: number;
}

export interface IEmployeeMedicalExamination {
    id?: number;
    examinationReason: {
        title: string;
    };
    examinationDate: string;
}

export interface IEmployeeMedicalExaminations {
    next?: {
        id: number;
        toBePlanned: boolean;
        toBePlannedDate: string;
        examinationReason: {
            title: string;
            code: string;
        };
    };
    planned?: IEmployeeMedicalExamination[];
    executed?: IEmployeeMedicalExamination[];
}

export interface IEmployeeRiskAndResearch {
    riskCode: string;
    riskDescription: string;
    researchCode: string;
    researchDescription: string;
}

// Medical center

export interface IMedicalCenter {
    id: number;
    code: string;
    name: string;
    address?: Pick<IAddress, 'postcode' | 'number' | 'city' | 'street' | 'box'>;
    hasCabinets: boolean;
    prefered: boolean;
}

// Job Student
export interface IFetchEmployeeJobStudentPayload {
    id: number;
}

// Job student request failed dialog

export interface ISetJobStudentRequestFailedWarning {
    showWarning: boolean;
}

// Employee Status

export interface IFetchEmployeeStatusPayload {
    employeeId: number;
}

export interface IEmployeeStatusResponse {
    isActiveInEmployeeMyMensura: boolean;
}

export interface ISelectedEmployeeStatus {
    id: number;
    isActiveInEmployeeMyMensura: boolean;
}

export interface IEmployeeEmployment {
    id: number;
    branch: IBranch;
}

export enum EMPLOYEE_CONDITION {
    ABSENT = 'absent',
    FUTURE_EMPLOYMENT = 'future_employment',
    NEW_EMPLOYEE = 'new_employee',
    NEW_FUNCTION = 'new_function',
    NEW_SEAT = 'new_seat',
    OUT_OF_SERVICE = 'out_of_service',
}
