import React, { Component, MouseEvent } from 'react';
import './automatic-validate.scss';
import clone from 'ramda/src/clone';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import {
    IPeriodicHealthAssessmentAutomaticEntity,
    IMedicalExaminationToAdd,
    IPlanMedicalExaminationWizardPayload,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID,
    PLAN_MEDICAL_EXAMINATION_WIZARD_ACTION,
} from '../../../../../../models/interventions/medicalExaminations';
import ListWithSorting from '../../../../../common/list/ListWithSorting';
import { ListColumns, SortType, ListItem } from '../../../../../../models/general/list';
import { ITranslator } from '../../../../../../models/general/i18n';
import Translate from '../../../../../common/Translate';
import {
    getPlanMedicalExaminationWizardEntity,
} from '../../../../../../redux/medicalExamination/selectors';
import { formatPersonNameFormal } from '../../../../../../utils/formatting/formatPerson';
import { formatDateInLongFormat } from '../../../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../../../utils/formatting/formatTime';
import ListItemActions from '../../../../../common/list/ListItemActions';
import Icon from '../../../../../common/icons/Icon';
import Dialog from '../../../../../common/modals/Dialog';
import EditExaminationTime from './EditExaminationTime';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import AutomaticDeleteTimeSlot from './AutomaticDeleteTimeSlot';
import { getMedExamToAddId } from '../../../../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import EditExaminationStartDate from './EditExaminationStartDate';
import Tooltip from '../../../../../common/widget/Tooltip';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { getRoutePayload } from '../../../../../../redux/location/selectors';
import { navigateTo } from '../../../../../../redux/location/actions';
import WarningDialog from '../../../../../common/modals/WarningDialog';
import {
    resetPlanMedicalExaminationWizardEntity,
} from '../../../../../../redux/medicalExamination/actions';
import ListFooter from '../../../../../common/list/ListFooter';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import EditTimeslot from './EditTimeslot';
import OverlayContent from '../../../../../common/modals/OverlayContent';
import Button from '../../../../../common/buttons/Button';
import { NO_TIMECELLS_FOUND_ERROR_MESSAGE_KEY } from '../../../../../../config/medicalExamination.config';

const CLASS_NAME = 'AutomaticValidate';
const LIST_NAME = 'medical-examination-automatic-validate-list';

type editActionTranslationKey = 'location' | 'day' | 'start_hour';

const DEFAULT_ERROR_MESSAGE_KEY = 'interventions.medical_examinations.new.steps.validate_auto_plan.error.default';

const COLUMNS: ListColumns<IColumnNames> = {
    condition: {
        sortable: false,
        render: renderWarningIcon,
        percentWidth: null,
        minWidth: 50,
    },
    employee: {
        label: <Translate msg="interventions.medical_examinations.new.steps.validate_auto_plan.columns.employee" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
    location: {
        label: <Translate msg="interventions.medical_examinations.new.steps.validate_auto_plan.columns.location" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
        itemColumnCollSpan: (item) => {
            return item.columns.errorMessage ? {
                colSpan: 3,
                colSpanPercentWidth: 70,
            } : null;
        },
        render: (item) => {
            if (item.columns.errorMessage) {
                const errorMessageKey = item.columns.errorMessage === NO_TIMECELLS_FOUND_ERROR_MESSAGE_KEY ?
                    NO_TIMECELLS_FOUND_ERROR_MESSAGE_KEY :
                    DEFAULT_ERROR_MESSAGE_KEY;
                return <i><Translate msg={errorMessageKey} /></i>;
            }
            return item.columns.location;
        },
    },
    day: {
        label: <Translate msg="interventions.medical_examinations.new.steps.validate_auto_plan.columns.day" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
        hideItemColumn: (item) => {
            return !!item.columns.errorMessage;
        },
    },
    startHour: {
        label: <Translate msg="interventions.medical_examinations.new.steps.validate_auto_plan.columns.start_hour" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
        hideItemColumn: (item) => {
            return !!item.columns.errorMessage;
        },
    },
    actions: {
        sortable: false,
        percentWidth: null,
        minWidth: 160,
    },
    errorMessage: {
        hide: true,
        percentWidth: null,
    },
};

interface IColumnNames {
    condition: string;
    employee: string;
    location: string;
    day: string;
    startHour: string;
    actions: string;
    errorMessage: string;
}

interface IPrivateProps {
    medicalExaminationsToAdd: IMedicalExaminationToAdd[];
    listItems: ListItem<IColumnNames>[];
    isExitRoute: boolean;
    navigateToValidateStep: () => void;
    onExitWizard: () => void;
}

interface IState {
    selectedListItems: ListItem<IColumnNames>[];
    isDialogOpen: boolean;
    dialogType: 'day' | 'startHour' | 'delete' | 'editTimeslot';
}

class AutomaticValidate extends Component<IPrivateProps & IStepperStepRenderProps, IState> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props) {
        super(props);

        this.state = {
            selectedListItems: [],
            isDialogOpen: false,
            dialogType: undefined,
        };

        this.onClickNextHandler = this.onClickNextHandler.bind(this);
        this.onDeleteClicked = this.onDeleteClicked.bind(this);
        this.onEditStartHourClicked = this.onEditStartHourClicked.bind(this);
        this.onEditDayClicked = this.onEditDayClicked.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.getSelectedMedicalExaminationsFromSelectedListItem =
            this.getSelectedMedicalExaminationsFromSelectedListItem.bind(this);

        this.columns.day.headerRender = this.renderDayHeaderActions.bind(this);

        this.columns.startHour.headerRender = this.renderStartHourHeaderActions.bind(this);

        this.columns.actions.render = (listItem: ListItem<IColumnNames>, index: number) => {
            if (listItem.columns.errorMessage) {
                return (
                    <Button
                        id={`automatic-validate-manual-plan-button-${index}`}
                        onClick={(e) => this.onEditClicked(e, listItem)}
                        typeName="primary"
                        size="small"
                    >
                        <Translate
                            // eslint-disable-next-line max-len
                            msg="interventions.medical_examinations.new.steps.validate_auto_plan.error.manual_plan_button"
                        />
                    </Button>
                );
            }

            return (
                <ListItemActions>
                    <Icon circle typeName="pencil" onClick={(e) => this.onEditClicked(e, listItem)} />
                    <Icon circle typeName="bin" onClick={(e) => this.onDeleteClicked(e, listItem)} />
                </ListItemActions>
            );
        };
    }

    public render() {
        const {
            renderStepButtons, listItems, isExitRoute, navigateToValidateStep, onExitWizard,
        } = this.props;
        const { isDialogOpen, dialogType } = this.state;
        const isEditingTimeslot = isDialogOpen && dialogType === 'editTimeslot';

        const plannedListItems = listItems.filter((item) => !item.columns.errorMessage);

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.validate_auto_plan.title"
                    text="interventions.medical_examinations.new.steps.validate_auto_plan.text"
                />
                <div className={classNames('container', `${CLASS_NAME}__list`, WIZARDFLOW_CLASSES.CONTENT)}>
                    <ListWithSorting
                        columns={this.columns}
                        items={listItems}
                        name={LIST_NAME}
                        footer={
                            <ListFooter
                                right={renderStepButtons({
                                    nextButton: {
                                        onClick: this.onClickNextHandler,
                                        disabled: plannedListItems.length <= 0,
                                    },
                                    prevButton: {
                                        hide: true,
                                    },
                                })}
                            />
                        }
                    />
                </div>
                <AutomaticDeleteTimeSlot
                    onClose={this.onCloseDialog}
                    show={isDialogOpen && dialogType === 'delete'}
                    selectedMedicalExamination={this.getSelectedMedicalExaminationsFromSelectedListItem()[0]}
                />
                <Dialog
                    show={isDialogOpen && dialogType !== 'delete' && dialogType !== 'editTimeslot'}
                    onCloseIntent={this.onCloseDialog}
                    size="full-width"
                >
                    {dialogType === 'day' &&
                        <EditExaminationStartDate
                            selectedMedicalExaminations={this.getSelectedMedicalExaminationsFromSelectedListItem()}
                            onClose={this.onCloseDialog}
                        />
                    }
                    {dialogType === 'startHour' &&
                        <EditExaminationTime
                            selectedMedicalExaminations={this.getSelectedMedicalExaminationsFromSelectedListItem()}
                            onClose={this.onCloseDialog}
                        />
                    }
                </Dialog>
                <OverlayContent
                    show={isEditingTimeslot}
                    onCloseIntent={this.onCloseDialog}
                    removeOpacity={true}
                >
                    {({ isTransitionOnOpenFinished }) => (
                        <EditTimeslot
                            selectedMedicalExamination={this.getSelectedMedicalExaminationsFromSelectedListItem()[0]}
                            onClose={this.onCloseDialog}
                            hideDatepicker={!isTransitionOnOpenFinished}
                            isActive={isEditingTimeslot}
                        />
                    )}
                </OverlayContent>
                <WarningDialog
                    show={isExitRoute}
                    onConfirm={onExitWizard}
                    onCancel={navigateToValidateStep}
                    showLoader={false}
                    confirmTranslationKey="interventions.medical_examinations.new.steps.auto_plan_stop.confirm"
                    cancelTranslationKey="interventions.medical_examinations.new.steps.auto_plan_stop.cancel"
                    title="interventions.medical_examinations.new.steps.auto_plan_stop.title"
                >
                    <p>
                        <Translate msg={'interventions.medical_examinations.new.steps.auto_plan_stop.text'} />
                    </p>
                </WarningDialog>
            </>
        );
    }

    private onDeleteClicked(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isDialogOpen: true,
            dialogType: 'delete',
            selectedListItems: [listItem],
        });
    }

    private onEditDayClicked(e: MouseEvent<HTMLElement>, listItems: ListItem<IColumnNames>[]) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isDialogOpen: true,
            dialogType: 'day',
            selectedListItems: listItems,
        });
    }

    private onEditStartHourClicked(e: MouseEvent<HTMLElement>, listItems: ListItem<IColumnNames>[]) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isDialogOpen: true,
            dialogType: 'startHour',
            selectedListItems: listItems,
        });
    }

    private onEditClicked(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        this.setState({
            isDialogOpen: true,
            dialogType: 'editTimeslot',
            selectedListItems: [listItem],
        });
    }

    private onCloseDialog() {
        this.setState({
            isDialogOpen: false,
            dialogType: undefined,
        });
    }

    private onClickNextHandler() {
        this.props.goToNextStep();
    }

    private getSelectedMedicalExaminationsFromSelectedListItem() {
        const { selectedListItems } = this.state;
        if (selectedListItems.length > 0) {
            return this.props.medicalExaminationsToAdd.filter((exam) => (
                selectedListItems.map((listItem) => listItem.id).includes(getMedExamToAddId(exam))
            ));
        }
        return [];
    }

    private renderDayHeaderActions() {
        return renderEditActionHeader(this.onEditDayClicked, 'day', this.props.listItems);
    }

    private renderStartHourHeaderActions() {
        return renderEditActionHeader(this.onEditStartHourClicked, 'start_hour', this.props.listItems);
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const entity = getPlanMedicalExaminationWizardEntity(state) as IPeriodicHealthAssessmentAutomaticEntity;
        const medicalExaminationsToAdd = entity.medicalExaminationsToAdd;
        const translator = getTranslatorDeprecated(state);

        const routeAction = getRoutePayload<IPlanMedicalExaminationWizardPayload>(state).action;

        return {
            listItems: mapExams(medicalExaminationsToAdd, translator),
            medicalExaminationsToAdd,
            isExitRoute: routeAction === PLAN_MEDICAL_EXAMINATION_WIZARD_ACTION.exit,
        };
    },
    dispatchProps: (dispatch) => {
        return {
            navigateToValidateStep: () => {
                dispatch(navigateTo<IPlanMedicalExaminationWizardPayload>(
                    ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD,
                    {
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC,
                        step: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.AUTO_VALIDATE,
                        resetDataEntity: false,
                    },
                ));
            },
            onExitWizard: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN));
                dispatch(resetPlanMedicalExaminationWizardEntity());
            },
        };
    },
})(AutomaticValidate);

function mapExams(
    medicalExaminationsToAdd: IMedicalExaminationToAdd[],
    translator: ITranslator,
): ListItem<IColumnNames>[] {
    return medicalExaminationsToAdd.map((exam) => {
        const day = exam && exam.timeCell ?
            formatDateInLongFormat(exam.timeCell.time) : '-';

        const locationName = exam && exam.timeCell ?
            exam.timeCell.medicalCenter.name : '-';

        const startHour = exam && exam.timeCell ?
            formatTimeOfDateForDisplay(exam.timeCell.time) : '-';

        return {
            id: getMedExamToAddId(exam),
            columns: {
                employee: formatPersonNameFormal(exam.employee),
                day,
                location: locationName,
                startHour,
                errorMessage: exam.errorMessage,
            },
        } as ListItem<IColumnNames>;
    });
}

const renderEditActionHeader = (
    onClick: (e: MouseEvent<HTMLElement>, listItems: ListItem<IColumnNames>[]) => void,
    key: editActionTranslationKey,
    listItems: ListItem<IColumnNames>[],
) => (
        (
            listItemsNotEmptyAndNoPlannedAppointments(listItems) ? (
                <ListItemActions>
                    <Tooltip
                        placement="top-start"
                        target={
                            <div
                                className="Button Button--text ListActionButton"
                                onClick={(e) => onClick(e, listItems)}
                            >
                                <Icon typeName="pencil" />
                            </div>}
                        typeName="info-bubble"
                        spaceBetweenTarget={15}
                    >
                        <div>
                            <Translate
                                // eslint-disable-next-line max-len
                                msg={`interventions.medical_examinations.new.steps.validate_auto_plan.action_labels.edit_${key}`}
                            />
                        </div>
                    </Tooltip>
                </ListItemActions>
            ) : null
        )
    );

function listItemsNotEmptyAndNoPlannedAppointments(listItems: ListItem<IColumnNames>[]) {
    return listItems.length > 0 && !listItems.find((item) => !item.columns.errorMessage);
}

function renderWarningIcon(item: ListItem<IColumnNames>) {
    if (!item.columns.errorMessage) {
        return <span />;
    }

    return (
        <div className={`${CLASS_NAME}__list__warning`}>
            <Icon typeName="warning" circle colorType="warning" />
        </div>
    );
}
