import React, { Component, ReactElement } from 'react';
import isSet from '@snipsonian/core/es/is/isSet';
import { path } from 'ramda';
import './employee-radio-select-list.scss';
import { connect } from '../../../../../index';
import { IEmployee } from '../../../../../../models/admin/employee';
import { ITranslator } from '../../../../../../models/general/i18n';
import { ListItem, ISortedColumn, SortOrder, ListColumns, SortType } from '../../../../../../models/general/list';
import { AsyncStatus } from '../../../../../../models/general/redux';
import { createNotFoundError } from '../../../../../../utils/api/error/createNotFoundError';
import { isAllSeatsSelected, getSelectedSeatCompanyCode } from '../../../../../../redux/company/selected/selectors';
import { ITraceableApiError } from '../../../../../../models/general/error';
import Translate, { IPublicProps as ITranslateProps } from '../../../../../common/Translate';
import ErrorPlaceholder from '../../../../../common/error/ErrorPlaceholder';
import ListActions from '../../../../../common/list/ListActions';
import ListWithRadioButtonSelect from '../../../../../common/list/ListWithRadioButtonSelect';
import Loader from '../../../../../common/waiting/Loader';
import debounce, { TDebounced } from '../../../../../../utils/core/debounce';
import { formatPersonNameFormal } from '../../../../../../utils/formatting/formatPerson';
import formatNationalRegisterNumber from '../../../../../../utils/formatting/formatNationalRegisterNumber';
import { getTranslatorDeprecated } from '../../../../../../redux/i18n/selectors';
import { getEmployees, getEmployeesAsyncInfo } from '../../../../../../redux/employee/employees/selectors';
import { fetchEmployeesActions } from '../../../../../../redux/employee/employees/actions';
import { IListFooterProps } from '../../../../../common/list/ListFooter';
import EmployeeConditionTooltip from '../../../../../administration/Employees/shared/EmployeeConditionTooltip';
import { EMPLOYEE_SEARCH_MIN_NR_OF_CHARS } from '../../../../../../config/administration.config';
import FormFieldError from '../../../../../common/forms/FormFieldError';
import { EMPLOYEE_SEARCH_NOT_ENOUGH_INPUT_CHARS_WARNING }
    from '../../../../../administration/Employees/EmployeeFilter/searchMinNumberOfCharactersSchema';
import { getPlanBufferzoneWizardEntity } from '../../../../../../redux/intervention/bufferzones/selectors';
import isMainSeatCompanyCode from '../../../../../../utils/administration/companyInfo/isMainSeatCompanyCode';

const LIST_NAME = 'employee-radio-select-list';
const CLASS_NAME = 'EmployeeRadioSelectList';
const MIN_CHARS_TO_TRIGGER_SEARCH = EMPLOYEE_SEARCH_MIN_NR_OF_CHARS;

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'name',
    sortOrder: SortOrder.Ascending,
};

type TRefreshSources = 'true' | 'false';

interface EmployeeRadioSelectListProps {
    onSelect: (employee: IEmployee) => void;
    selectedEmployeeId: number;

    inOverlay?: boolean; // makes loader transparent
    footer?: ReactElement<IListFooterProps>;

    initialSearchValue?: string;
    searchPlaceholderTranslationKey?: string;
    searchInputTooltipTranslationKey?: string;
    onSearchInput?: (searchValue: string) => void;
    showEmployeeConditionTooltips?: boolean;

    disabledEmployeeIds?: number[];
    disabledEmployeeReason?: string | ReactElement<ITranslateProps>;

    isBufferzonePlanning?: boolean; // default false;
}

interface IPrivateProps {
    employees: IEmployee[];
    fetchEmployeesError: ITraceableApiError;
    fetchEmployeesAsyncStatus: AsyncStatus;
    listItems: ListItem<IColumnNames>[];
    translator: ITranslator;
    resetPreviousSearch: () => void;
    searchEmployees: (search: string, refreshSources: TRefreshSources, isBufferzonePlanning: boolean) => void;
}

interface IColumnNames {
    condition: string;
    name: string;
    nationalRegisterNumber: string;
    function: string;
    seat: string;
    employeeId: string;
}

interface IComponentState {
    refreshSources: TRefreshSources;
    showMinSearchCharsWarning: boolean;
}

class EmployeeRadioSelectList extends Component<IPrivateProps & EmployeeRadioSelectListProps, IComponentState> {
    private columnsConfig: ListColumns<IColumnNames>;
    private onSearchDebounced: TDebounced<[string]>;

    constructor(props: IPrivateProps & EmployeeRadioSelectListProps) {
        super(props);

        this.state = {
            refreshSources: 'true',
            showMinSearchCharsWarning: false,
        };

        this.columnsConfig = {
            condition: props.showEmployeeConditionTooltips ? {
                sortable: false,
                render: this.renderEmployeeConditionTooltip.bind(this),
                percentWidth: 5,
            } : {
                hide: true,
                percentWidth: null,
            },
            name: {
                label: <Translate msg="common.employee_list.columns.name" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: props.showEmployeeConditionTooltips ? 20 : 25,
            },
            nationalRegisterNumber: {
                label: <Translate msg="common.employee_list.columns.insz" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 15,
            },
            function: {
                label: <Translate msg="common.employee_list.columns.function" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 25,
            },
            seat: {
                label: <Translate msg="common.employee_list.columns.seat" />,
                sortable: true,
                sortType: SortType.String,
                percentWidth: 35,
            },
            employeeId: {
                hide: true,
                sortable: false,
                percentWidth: null,
            },
        };

        this.onItemSelected = this.onItemSelected.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSearchDebounced = debounce(this.onSearch, 500);
        this.onSearchInput = this.onSearchInput.bind(this);
        this.shouldDisableEmployeeCondition = this.shouldDisableEmployeeCondition.bind(this);
        this.getCustomRowClasses = this.getCustomRowClasses.bind(this);
    }

    public componentDidMount() {
        this.props.resetPreviousSearch();

        if (isValidSearchValue(this.props.initialSearchValue)) {
            this.onSearch(this.props.initialSearchValue);
        }
    }

    public componentWillUnmount() {
        this.onSearchDebounced.cancel();
    }

    public render() {
        const {
            selectedEmployeeId, inOverlay, footer,
            initialSearchValue, searchPlaceholderTranslationKey,
            fetchEmployeesAsyncStatus, listItems, fetchEmployeesError, translator,
            disabledEmployeeReason, searchInputTooltipTranslationKey,
        } = this.props;
        const { showMinSearchCharsWarning } = this.state;

        const searchPlaceholder = isSet(searchPlaceholderTranslationKey) ? searchPlaceholderTranslationKey
            : 'interventions.medical_examinations.new.steps.employees_to_plan.search_employee_overlay.search';

        return (
            <div className={CLASS_NAME}>
                <ListActions
                    name={LIST_NAME}
                    withSelectAll={false}
                    withSearch={true}
                    initialSearchValue={initialSearchValue}
                    searchPlaceholder={translator(searchPlaceholder)}
                    onSearchInput={this.onSearchInput}
                    isSearchInputInvalid={showMinSearchCharsWarning}
                    searchInputTooltipTranslationKey={searchInputTooltipTranslationKey}
                />
                {showMinSearchCharsWarning &&
                    <div className={`${CLASS_NAME}__search-input-warning`}>
                        <FormFieldError error={EMPLOYEE_SEARCH_NOT_ENOUGH_INPUT_CHARS_WARNING} />
                    </div>
                }
                {fetchEmployeesError &&
                    <div className={`${CLASS_NAME}__error`}>
                        <ErrorPlaceholder
                            typeName="block"
                            apiError={fetchEmployeesError}
                        />
                    </div>
                }
                <div className={`${CLASS_NAME}__list`}>
                    {fetchEmployeesAsyncStatus === AsyncStatus.Success &&
                        <ListWithRadioButtonSelect
                            withSorting={true}
                            initialSort={INITIAL_SORT}
                            name={LIST_NAME}
                            columns={this.columnsConfig}
                            items={listItems || []}
                            selectedItemId={selectedEmployeeId}
                            onItemSelected={this.onItemSelected}
                            footer={footer}
                            doNotRenderRadioButtonCondition={this.shouldDisableEmployeeCondition}
                            doNotRenderRadioButtonConditionReason={disabledEmployeeReason}
                            getCustomRowClasses={this.getCustomRowClasses}
                        />
                    }
                    <Loader
                        show={fetchEmployeesAsyncStatus}
                        transparentBackground={inOverlay}
                    />
                </div>
            </div>
        );
    }

    private getCustomRowClasses(listItem: ListItem<IColumnNames>) {
        if (this.shouldDisableEmployeeCondition(listItem)) {
            return 'disabled';
        }
        return '';
    }

    private shouldDisableEmployeeCondition(listItem: ListItem<IColumnNames>) {
        const { disabledEmployeeIds } = this.props;

        return disabledEmployeeIds && disabledEmployeeIds.some((employeeId: number) => {
            return employeeId === listItem.columns.employeeId as number;
        });
    }

    private onSearch(searchValue: string) {
        if (isValidSearchValue(searchValue)) {
            this.props.searchEmployees(searchValue, this.state.refreshSources, this.props.isBufferzonePlanning);
        } else {
            if (isSet(searchValue) && !isEnoughCharsToTriggerSearch(searchValue)) {
                this.setState({
                    showMinSearchCharsWarning: true,
                });
            }
            this.props.resetPreviousSearch();
        }

        if (this.state.refreshSources === 'true') {
            // do not refresh sources after the 1st call > faster calls
            this.setState({
                refreshSources: 'false',
            });
        }
    }

    private onSearchInput(searchValue: string) {
        if (this.props.onSearchInput) {
            this.props.onSearchInput(searchValue);
        }

        if (this.state.showMinSearchCharsWarning) {
            this.setState({
                showMinSearchCharsWarning: false,
            });
        }

        this.onSearchDebounced(searchValue);
    }

    private onItemSelected(itemId: number) {
        const { employees, onSelect } = this.props;

        const selectedEmployee = employees.find((employee) => employee.id === itemId);

        onSelect(selectedEmployee);
    }

    private renderEmployeeConditionTooltip(listItem: ListItem<IColumnNames>) {
        const employee = this.props.employees
            .find((employee) => employee.id === listItem.id);

        return (
            <EmployeeConditionTooltip employee={employee} />
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const employees = getEmployees(state);
        const employeesAsyncInfo = getEmployeesAsyncInfo(state);

        return {
            employees,
            fetchEmployeesError: employeesAsyncInfo.error,
            fetchEmployeesAsyncStatus: employeesAsyncInfo.status,
            listItems: mapEmployeesToListItems(employees),
            translator: getTranslatorDeprecated(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            resetPreviousSearch: () => {
                dispatch(fetchEmployeesActions.reset({}));
            },
            searchEmployees: (search: string, refreshSources: TRefreshSources, isBufferzonePlanning: boolean) => {
                if (isBufferzonePlanning) {
                    const state = getState();
                    const allSeatsSelected = isAllSeatsSelected(state);
                    const entity = getPlanBufferzoneWizardEntity(getState());

                    const bufferzoneBranchCode = path<string>(['selectedBufferzone', 'company', 'companyCode'], entity);

                    if (!bufferzoneBranchCode) {
                        console.warn('No bufferzone branch code could be found');
                        fetchEmployeesActions.failed(createNotFoundError());
                        return;
                    }

                    /*
                        During bufferzone planning, we only want to fetch the employees from the
                        logged in seat.
                        The only exception being when "all seats" are selected: Then we want to
                        retrieve the employees from the seat for which the bufferzone is ordered.
                    */
                    const companyCode = allSeatsSelected
                        ? bufferzoneBranchCode
                        : getSelectedSeatCompanyCode(state);

                    /*  During bufferzone planning, we only want to show all employees (showFullFamily)
                        if we are planning on a bufferzone created from the main seat when we are
                        logged in for all seats.
                        In all other cases we only want the selected seats employees
                    */
                    const showFullFamily = allSeatsSelected && isMainSeatCompanyCode(bufferzoneBranchCode);

                    dispatch(fetchEmployeesActions.trigger({
                        /*
                            Always refresh sources, to make sure we only get employees of the selected seat
                        */
                        refreshSources: 'true',
                        search,
                        showFutureEmployees: true,
                        companyCode,
                        showFullFamily,
                    }));
                } else {
                    dispatch(fetchEmployeesActions.trigger({
                        refreshSources,
                        search,
                        showFutureEmployees: true,
                    }));
                }
            },
        };
    },
})(EmployeeRadioSelectList);

function mapEmployeesToListItems(employees: IEmployee[]): ListItem<IColumnNames>[] {
    return employees.map((employee) => {
        return {
            id: employee.id,
            columns: {
                condition: null,
                name: formatPersonNameFormal(employee),
                nationalRegisterNumber: formatNationalRegisterNumber(employee.nationalRegisterNumber),
                function: employee.function.description,
                seat: employee.company.name,
                employeeId: employee.employeeId,
            },
        };
    });
}

function isValidSearchValue(searchValue: string) {
    if (!isSet(searchValue)) {
        return false;
    }

    return isEnoughCharsToTriggerSearch(searchValue);
}

function isEnoughCharsToTriggerSearch(searchValue: string) {
    return searchValue.length >= MIN_CHARS_TO_TRIGGER_SEARCH;
}
