import { AsyncOperation, IAsyncEntity, IBaseEntitiesReducerState } from '@snipsonian/redux-features/es/entities/types';
import { getAsyncEntityInitialState } from '@snipsonian/redux-features/es/entities/reducerBuildingBlocks';
import { IThemeOfTheMonth, ICourseLink } from '../general/cms';
import { ITraceableApiError } from '../general/error';
import { IMaintenanceMessage } from '../general/maintenance';
import { ICMSCourse } from '../documentCenter/courses';
import { ITemplatesCategory, ITemplatesCategoryDetail } from '../documentCenter/templates';
import { IUserAccount } from '../user/userAccount';

export interface ICustomAsyncEntity<Data> extends IAsyncEntity<Data, ITraceableApiError> {}

export enum ASYNC_ENTITY_KEYS {
    companyAdministrators = 'companyAdministrators',
    maintenanceMessages = 'maintenanceMessages',
    spotlightCourse = 'spotlightCourse',
    templates = 'templates',
    templatesCategory = 'templatesCategory',
    themeOfTheMonth = 'themeOfTheMonth',
    courseLinks = 'courseLinks',
}

/* Keep in sync with ASYNC_ENTITY_KEYS !! */
export interface IEntitiesReducerState extends IBaseEntitiesReducerState {
    companyAdministrators: ICustomAsyncEntity<IUserAccount[]>;
    maintenanceMessages: ICustomAsyncEntity<IMaintenanceMessage[]>;
    spotlightCourse: ICustomAsyncEntity<ICMSCourse>;
    templates: ICustomAsyncEntity<ITemplatesCategory[]>;
    templatesCategory: ICustomAsyncEntity<ITemplatesCategoryDetail>;
    themeOfTheMonth: ICustomAsyncEntity<IThemeOfTheMonth>;
    courseLinks: ICustomAsyncEntity<ICourseLink[]>;
}

export const ENTITIES_REDUCER_INITIAL_STATE: IEntitiesReducerState = {
    companyAdministrators: getAsyncEntityInitialState({ type: 'array', operations: [AsyncOperation.fetch] }),
    maintenanceMessages: getAsyncEntityInitialState({ type: 'array', operations: [AsyncOperation.fetch] }),
    spotlightCourse: getAsyncEntityInitialState({ type: 'object', operations: [AsyncOperation.fetch] }),
    templates: getAsyncEntityInitialState({ type: 'array', operations: [AsyncOperation.fetch] }),
    templatesCategory: getAsyncEntityInitialState({ type: 'array', operations: [AsyncOperation.fetch] }),
    themeOfTheMonth: getAsyncEntityInitialState({ type: 'object', operations: [AsyncOperation.fetch] }),
    courseLinks: getAsyncEntityInitialState({ type: 'object', operations: [AsyncOperation.fetch] }),
};
