import clone from 'ramda/src/clone';
import { IReducerConfig, registerReducer as register } from '@snipsonian/redux/es/reducer/reducerManager';
import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import isFunction from '@snipsonian/core/es/is/isFunction';
import { keepReducerStorageTypeIfNotDisabled } from './keepStorageTypeIfNotDisabled';
import { IActionHandler } from '../../../models/general/redux';
import { createActionHandler } from './createActionHandler';
import { CLEAR_ERRORS } from './generic/types';
import clearErrorsActionHandler from './generic/clearErrorsActionHandler';
import ROUTE_KEYS from '../../../routeKeys';
import { SELECT_COMPANY, SELECT_COMPANY_SEAT } from '../../../redux/company/selected/types';
import { SWITCH_LOCALE_SUCCEEDED } from '../../../redux/i18n/types';

interface IReducerConfigCustom<ReducerState> extends IReducerConfig<ReducerState> {
    resetStateOnLogout?: boolean;
    resetStateOnCompanySelection?: boolean | IResetStateActionHandlerFactory<ReducerState>;
    resetStateOnSeatSelection?: boolean | IResetStateActionHandlerFactory<ReducerState>;
    resetStateOnLocaleSwitch?: boolean | IResetStateActionHandlerFactory<ReducerState>;
}

export type IResetStateActionHandlerFactory<ReducerState> = (input: { initialStateClone: ReducerState })
    => IActionHandler<ReducerState, {}>;

export function registerReducer<ReducerState>({
    key,
    reducerStorageType = REDUCER_STORAGE_TYPE.INHERIT,
    transformReducerStateForStorage,
    initialState = {} as ReducerState,
    actionHandlers = {},
    resetStateOnLogout = true,
    resetStateOnCompanySelection = true,
    resetStateOnSeatSelection = true,
    resetStateOnLocaleSwitch = true,
}: IReducerConfigCustom<ReducerState>) {
    const initialStateClone = clone(initialState);

    byDefaultResetToTheInitialStateOnLogoutOrCompanySeatSelection(
        resetStateOnLogout, resetStateOnCompanySelection, resetStateOnSeatSelection, resetStateOnLocaleSwitch,
        actionHandlers, initialStateClone,
    );
    registerClearErrorsHandler(actionHandlers);

    return register({
        key,
        initialState: initialStateClone,
        actionHandlers,
        transformReducerStateForStorage,
        reducerStorageType: keepReducerStorageTypeIfNotDisabled(reducerStorageType),
    });
}

function byDefaultResetToTheInitialStateOnLogoutOrCompanySeatSelection(
    resetStateOnLogout, resetStateOnCompanySelection, resetStateOnSeatSelection, resetStateOnLocaleSwitch,
    actionHandlers, initialStateClone,
) {
    const initialStateActionHandler = createActionHandler(() => {
        return {
            ...initialStateClone,
        };
    });
    if (resetStateOnLogout && noActionHandlerForType(ROUTE_KEYS.R_LOGOUT, actionHandlers)) {
        actionHandlers[ROUTE_KEYS.R_LOGOUT] = initialStateActionHandler;
    }
    if (resetStateOnCompanySelection && noActionHandlerForType(SELECT_COMPANY, actionHandlers)) {
        actionHandlers[SELECT_COMPANY] = isFunction(resetStateOnCompanySelection) ?
            resetStateOnCompanySelection({ initialStateClone }) : initialStateActionHandler;
    }
    if (resetStateOnSeatSelection && noActionHandlerForType(SELECT_COMPANY_SEAT, actionHandlers)) {
        actionHandlers[SELECT_COMPANY_SEAT] = isFunction(resetStateOnSeatSelection) ?
            resetStateOnSeatSelection({ initialStateClone }) : initialStateActionHandler;
    }
    if (resetStateOnLocaleSwitch && noActionHandlerForType(SWITCH_LOCALE_SUCCEEDED, actionHandlers)) {
        actionHandlers[SWITCH_LOCALE_SUCCEEDED] = isFunction(resetStateOnLocaleSwitch) ?
            resetStateOnLocaleSwitch({ initialStateClone }) : initialStateActionHandler;
    }
}

function noActionHandlerForType(actionType: string, actionHandlers) {
    return !actionHandlers[actionType];
}

function registerClearErrorsHandler(actionHandlers) {
    actionHandlers[CLEAR_ERRORS] = (event) => clearErrorsActionHandler(event.state, event.action.payload);
}
