import React, { Component } from 'react';
import './automatic-notify.scss';
import clone from 'ramda/src/clone';
import PageHeader from '../../../../../appShell/PageHeader';
import { IStepperStepRenderProps } from '../../../../../../models/general/stepper';
import classNames from 'classnames';
import { connect } from '../../../../../index';
import { navigateTo } from '../../../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../../../routeKeys';
import { WIZARDFLOW_CLASSES } from '../../../../../common/navigation/Wizard/index';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../../../../models/general/list';
import Translate from '../../../../../common/Translate';
import ListFooter from '../../../../../common/list/ListFooter';
import {
    IPeriodicHealthAssessmentAutomaticEntity,
    IMedicalExaminationToAdd,
} from '../../../../../../models/interventions/medicalExaminations';
import { getPlanMedicalExaminationWizardEntity } from '../../../../../../redux/medicalExamination/selectors';
import { getMedExamToAddId } from '../../../../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import { formatPersonNameFormal } from '../../../../../../utils/formatting/formatPerson';
import Checkbox from '../../../../../common/input/Checkbox';
import { TextPropertyInput } from '../../../../../common/input/PropertyInput';
import Form, { IFormRenderProps } from '../../../../../common/forms/Form';
import { mapMessageToErrorType, mapFieldErrorToErrorType } from '../../../../../common/forms/Form/errorTypeMapper';
import { schema, fields, subfields } from './automaticNotifySchema';
import List from '../../../../../common/list/List';
import sortListItems from '../../../../../../utils/list/sortListItems';
import { validateYupSchema } from 'formik';
import { IValidationError } from '../../../../../../models/general/error';
import isArray from '@snipsonian/core/es/is/isArray';
import {
    createConvocationsActions,
} from '../../../../../../redux/employee/documents/actions';
import { ICreateConvocationsPayload, IConvocationRecipient } from '../../../../../../models/general/documents';
import {
    getCreateConvocationsAsyncInfo,
    getConvocationRecipients,
    getConvocationRecipientsAsyncInfo,
} from '../../../../../../redux/employee/documents/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../../models/general/redux';
import Loader from '../../../../../common/waiting/Loader';
import FormError from '../../../../../common/forms/FormError';
import FinishPlanningDialog from '../../shared/FinishPlanningDialog';
import ConvocationRecipients from '../../shared/ConvocationRecipients';

const LIST_NAME = 'medical-examination-automatic-notify-list';
const FORM_NAME = 'medical-examination-automatic-notify-form';
const CLASS_NAME = 'AutomaticNotify';

interface IPrivateProps {
    navigateToOverview: () => void;
    plannedMedicalExaminations: IMedicalExaminationToAdd[];
    createConvocations: (payload: ICreateConvocationsPayload[]) => void;
    createConvocationsAsyncInfo: IAsyncFieldInfo;
    convocationRecipients: IConvocationRecipient[];
    convocationRecipientsAsyncInfo: IAsyncFieldInfo;
}

interface IState {
    isFinishPlanningDialogOpen: boolean;
    numberOfEmployeesWithNewEmails: number;
    downloadAmount: number;
}

interface IColumnNames {
    employee: string;
    email: string;
    notifyByMail: string;
    emailReadOnly: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    employee: {
        label: <Translate msg="interventions.medical_examinations.new.steps.notify_auto_plan.columns.employee" />,
        sortType: SortType.String,
        percentWidth: 30,
    },
    email: {
        label: <Translate msg="interventions.medical_examinations.new.steps.notify_auto_plan.columns.email" />,
        percentWidth: 50,
    },
    notifyByMail: {
        label: <Translate msg="interventions.medical_examinations.new.steps.notify_auto_plan.columns.notify_by_mail" />,
        percentWidth: 20,
    },
    emailReadOnly: {
        hide: true,
        percentWidth: 0,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'employee',
    sortOrder: SortOrder.Ascending,
};

interface IFormValues {
    notifyValues: NotifyValue[];
}

interface NotifyValue {
    email: string;
    notifyByMail: boolean;
    id: string;
}

interface CustomValidationError extends IValidationError {
    path: string;
}

class AutomaticNotify extends Component<IPrivateProps & IStepperStepRenderProps, IState> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);
    private formRenderProps: IFormRenderProps<IFormValues, CustomValidationError[]>;

    constructor(props) {
        super(props);

        this.state = {
            isFinishPlanningDialogOpen: false,
            numberOfEmployeesWithNewEmails: 0,
            downloadAmount: 0,
        };

        // Map listitems
        this.mapExamsToAdd = this.mapExamsToAdd.bind(this);

        // Form handlers
        this.onSubmit = this.onSubmit.bind(this);

        // Render functions
        this.renderNotifyByMailToggle = this.renderNotifyByMailToggle.bind(this);
        this.renderEmailInputField = this.renderEmailInputField.bind(this);
        this.columns.notifyByMail.render = (listItem: ListItem<IColumnNames>, index: number) => (
            this.renderNotifyByMailToggle(index)
        );
        this.columns.email.render = (listItem: ListItem<IColumnNames>, index: number) => (
            this.renderEmailInputField(listItem, index)
        );

        // Convocations
        this.createCreateConvocationsPayload = this.createCreateConvocationsPayload.bind(this);
        this.didCreateConvocationsSucceed = this.didCreateConvocationsSucceed.bind(this);

        // Finish Planning Handlers
        this.onCloseFinishPlanningHandler = this.onCloseFinishPlanningHandler.bind(this);
    }

    public componentDidUpdate(
        prevProps: IPrivateProps & IStepperStepRenderProps,
    ) {
        if (this.didCreateConvocationsSucceed(prevProps, this.props)) {
            this.setState({ isFinishPlanningDialogOpen: true });
        }
    }

    public render() {
        const { renderStepButtons, createConvocationsAsyncInfo } = this.props;
        const { isFinishPlanningDialogOpen, downloadAmount } = this.state;

        const nextTranslationKey = 'interventions.medical_examinations.new.steps.notify_auto_plan.confirm';
        const listItems: ListItem<IColumnNames>[] =
            sortListItems(this.mapExamsToAdd(), INITIAL_SORT, this.columns.employee);

        const initialValues: IFormValues = {
            notifyValues: listItems.map((item) => ({
                id: item.id as string,
                email: item.columns.email as string,
                notifyByMail: item.columns.notifyByMail as boolean,
            })),
        };

        return (
            <>
                <PageHeader
                    title="interventions.medical_examinations.new.steps.notify_auto_plan.title"
                    text="interventions.medical_examinations.new.steps.notify_auto_plan.text_invitation"
                />

                <div className={classNames('container', CLASS_NAME, WIZARDFLOW_CLASSES.CONTENT)}>
                    <Form
                        name={FORM_NAME}
                        handleSubmit={this.onSubmit}
                        initialValues={initialValues}
                        customValidator={customNotifySchemaValidator}
                        render={(formRenderProps: IFormRenderProps<IFormValues, CustomValidationError[]>) => {
                            this.formRenderProps = formRenderProps;

                            return (
                                <Loader show={createConvocationsAsyncInfo.status} >
                                    <List
                                        selectedItemIds={[]}
                                        columns={this.columns}
                                        items={listItems}
                                        name={LIST_NAME}
                                        footer={
                                            <>
                                                <ConvocationRecipients />
                                                <ListFooter
                                                    right={renderStepButtons({
                                                        nextButton: {
                                                            onClick: () => null,
                                                            translationKey: nextTranslationKey,
                                                            isSubmit: true,
                                                            formName: FORM_NAME,
                                                            disabled: !!formRenderProps.errors,
                                                        },
                                                    })}
                                                    left={
                                                        <FormError
                                                            error={createConvocationsAsyncInfo.error}
                                                        />
                                                    }
                                                />
                                            </>
                                        }
                                    />
                                </Loader>
                            );
                        }}
                    />
                </div>
                <FinishPlanningDialog
                    open={isFinishPlanningDialogOpen}
                    onClose={this.onCloseFinishPlanningHandler}
                    downloadAmount={downloadAmount}
                    multipleConvocationsCreated={listItems.length > 1}
                />
            </>
        );
    }

    private mapExamsToAdd(): ListItem<IColumnNames>[] {
        const { plannedMedicalExaminations } = this.props;
        if (!plannedMedicalExaminations) {
            return [];
        }

        return plannedMedicalExaminations.map((exam) => {
            return {
                id: getMedExamToAddId(exam),
                columns: {
                    employee: formatPersonNameFormal(exam.employee),
                    email: exam.employee.email,
                    notifyByMail: !!exam.employee.email,
                    emailReadOnly: !!exam.employee.email,
                },
            } as ListItem<IColumnNames>;
        });
    }

    private onSubmit(values: IFormValues) {
        const payload = this.createCreateConvocationsPayload(values);
        this.props.createConvocations(payload);
    }

    private renderNotifyByMailToggle(index: number) {
        const { setFieldValue, values } = this.formRenderProps;
        return (
            <Checkbox
                name={`${fields.notifyValues}.${index}.${subfields.notifyByMail}`}
                toggleButton={true}
                checked={values.notifyValues[index].notifyByMail}
                onChange={(e) => {
                    const updatedValues = [...values.notifyValues];
                    updatedValues[index].notifyByMail = e.target.checked;
                    setFieldValue(
                        'notifyValues',
                        updatedValues,
                    );
                }}
            />
        );
    }

    private renderEmailInputField(listItem: ListItem<IColumnNames>, index: number) {
        const { errors: customValidationErrors, values, handleChange } = this.formRenderProps;

        const customValidationError = isArray(customValidationErrors) &&
            customValidationErrors.find((item) => item.path === `${fields.notifyValues}[${index}].${subfields.email}`);

        const notifyByEmail = values.notifyValues[index].notifyByMail;

        return (
            <div className={`${CLASS_NAME}__email-input`}>
                <TextPropertyInput
                    id={`${fields.notifyValues}.${index}.${subfields.email}`}
                    name={`${fields.notifyValues}.${index}.${subfields.email}`}
                    value={values.notifyValues[index].email}
                    onChange={handleChange}
                    labelKey={'interventions.medical_examinations.new.steps.notify_auto_plan.columns.email'}
                    hideLabel={true}
                    readonly={listItem.columns.emailReadOnly as boolean}
                    isInvalid={!!customValidationError}
                    disabled={!notifyByEmail}
                    disableAutoComplete={true}
                />
            </div>
        );
    }

    private createCreateConvocationsPayload(values: IFormValues): ICreateConvocationsPayload[] {
        const { plannedMedicalExaminations } = this.props;

        let downloadAmount = 0;

        const medExamsInList = plannedMedicalExaminations.filter((medExam) => {
            const medExamToAddId = getMedExamToAddId(medExam);
            return !!values.notifyValues.find((value) => value.id === medExamToAddId);
        });

        const payload: ICreateConvocationsPayload[] = medExamsInList.map((medExam) => {
            const medExamToAddId = getMedExamToAddId(medExam);
            const matchingFormValue = values.notifyValues.find((value) => value.id === medExamToAddId);

            const emailHasChanged =
                (medExam.employee.email !== matchingFormValue.email) && matchingFormValue.notifyByMail;

            if (!matchingFormValue.notifyByMail) {
                downloadAmount = downloadAmount + 1;
            }

            return {
                planningId: medExam.planningId,
                employeeId: medExam.employee.id,
                downloadOnly: !matchingFormValue.notifyByMail,
                email: matchingFormValue.notifyByMail ? matchingFormValue.email : undefined,
                updateEmail: emailHasChanged,

            } as ICreateConvocationsPayload;
        });

        this.setState({ downloadAmount });

        return payload;
    }

    private didCreateConvocationsSucceed(
        prevProps: IPrivateProps & IStepperStepRenderProps,
        props: IPrivateProps & IStepperStepRenderProps,
    ) {
        return (prevProps.createConvocationsAsyncInfo.status === AsyncStatus.Busy &&
            props.createConvocationsAsyncInfo.status === AsyncStatus.Success);
    }

    private onCloseFinishPlanningHandler() {
        this.setState({ isFinishPlanningDialogOpen: false });
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const entity = getPlanMedicalExaminationWizardEntity(state) as IPeriodicHealthAssessmentAutomaticEntity;
        const medicalExaminationsToAdd = entity.medicalExaminationsToAdd || [];
        const createConvocationsAsyncInfo = getCreateConvocationsAsyncInfo(state);

        return {
            plannedMedicalExaminations: medicalExaminationsToAdd.filter((exam) => !!exam.timeCell),
            createConvocationsAsyncInfo,
            convocationRecipients: getConvocationRecipients(state),
            convocationRecipientsAsyncInfo: getConvocationRecipientsAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            navigateToOverview: () => {
                dispatch(navigateTo(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN));
            },
            createConvocations: (payload: ICreateConvocationsPayload[]) => {
                dispatch(createConvocationsActions.trigger(payload));
            },
        };
    },
})(AutomaticNotify);

function customNotifySchemaValidator(values: IFormValues) {
    return validateYupSchema(values, schema, false, { abortEarly: false })
        .then(() => {
            return [];
        }).catch((validationError) => {
            const errors: CustomValidationError[] = validationError.inner.map((fieldError) => {
                return {
                    type: mapMessageToErrorType(fieldError.message) || mapFieldErrorToErrorType(fieldError),
                    message: fieldError.message,
                    path: fieldError.path,
                };
            });
            throw errors;
        });
}
