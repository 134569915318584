import { createEpic, IState, IParallelCallInput } from '../../index';
import {
    fetchRisksSucceeded, fetchRisksFailed,
    fetchCompanyMedicalCentersActions,
    fetchCompanyAddressesActions,
    fetchCompanyDetailSucceeded, fetchCompanyDetailFailed,
    updateCompanyActions,
    updateCompanySingleFieldSucceeded, updateCompanySingleFieldFailed,
    fetchContacts, fetchContactsSucceeded, fetchContactsFailed,
    fetchCompanyAvailabilities, fetchCompanyAvailabilitiesSucceeded, fetchCompanyAvailabilitiesFailed,
    replaceCompanyAvailabilitiesSucceeded, replaceCompanyAvailabilitiesFailed, replaceCompanyAvailabilitiesReset,
    fetchCompanyHolidaysActions,
    removeContactSucceeded, removeContactFailed,
    addContactSucceeded, addContactFailed, fetchCompanyCostCentersActions,
    updateContactActions,
    fetchCompanyRatesActions,
    fetchCompanySeatContactsActions,
    fetchCompanySeatsWithEmployeeCountActions,
    fetchCompanySeatDetailActions,
    addCompanySeatActions,
    fetchCompanyBufferzones,
    updateCompanyHolidaysActions,
} from './actions';
import {
    FETCH_COMPANY_MEDICAL_CENTERS,
    FETCH_COMPANY_ADDRESSES,
    UPDATE_COMPANY,
    UPDATE_COMPANY_SINGLE_FIELD,
    FETCH_COMPANY_AVAILABILITIES_MED_EXAMS, REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS,
    FETCH_COMPANY_AVAILABILITIES_RISK_MGMT, REPLACE_COMPANY_AVAILABILITIES_RISK_MGMT,
    FETCH_COMPANY_INTERNAL_CONTACTS,
    REMOVE_CONTACT,
    ADD_CONTACT,
    UPDATE_CONTACT,
    FETCH_COMPANY_COST_CENTERS,
    FETCH_COMPANY_RATES,
    FETCH_COMPANY_HOLIDAYS,
    FETCH_COMPANY_SEAT_INTERNAL_CONTACTS,
    FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT,
    FETCH_SMALL_COMPANY_DETAIL,
    FETCH_COMPANY_SEAT_DETAIL,
    ADD_COMPANY_SEAT,
    FETCH_COMPANY_BUFFERZONES,
    UPDATE_COMPANY_HOLIDAYS,
} from './types';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
    getSelectedCompany,
    isAllSeatsSelected,
    isCompanyAnInterimCompany,
} from '../selected/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import Api from '../../../api';
import {
    IUpdateCompanyPayload,
    IUpdateCompanySingleFieldPayload,
    IFetchCompanySeatContactsPayload, IRemoveCompanyContactPayload,
    IAddCompanyContactPayload, IUpdateCompanyContactPayload,
    CompanyAvailabilityType,
    IFetchCompanyAvailabilitiesPayload,
    IReplaceCompanyAvailabilitiesPayload,
    IFetchCompanyHolidaysPayload,
    IAddCompanySeatPayload,
    IUpdateCompanyHolidaysPayload, IFetchCompanyInternalContactsPayload,
} from '../../../models/admin/companyInfo';
import { ICompanyCodePayload, IFetchCompanyBufferzonePayload } from '../../../models/admin/company';
import {
    areCompanyContactsAvailable, areCompanyAddressesAvailable,
    areCompanyMedicalCentersAvailable, getCompanyContactsAsyncInfo,
    areCompanySeatContactsAvailable, getSelectedCompanyInfoSeatCompanyCode,
    areCompanyCostCentersAvailable, areCompanySeatsWithEmployeeCountAvailable,
    areCompanyRisksAvailable,
    getCompanyBufferzones,
    getSelectedCompanyBufferzone,
} from './selectors';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import { IFetchCompanyRatesPayload } from '../../../models/admin/rates';
import { getCurrentYear } from '../../../utils/core/date/getSpecificDate';
import { AsyncStatus, IInitAppActionPayload, IAction } from '../../../models/general/redux';
import { getRouteKey, getLocationState, getQueryParams } from '../../location/selectors';
import { hasPermission } from '../../auth/selectors';
import { Permission } from '../../../models/auth/authorisation';
import { applicationInitializationFailed } from '../../ui/form/actions';
import isApplicationInitializationError from '../../../utils/api/isApplicationInitializationError';
import { fetchPlannedMedicalExaminationsActions } from '../../medicalExamination/actions';
import { DEFAULT_COMPANY_BUFFERZONES_FILTERS } from '../../../api/admin/companyInfo.api';
import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import { IStartEndDateFilterValues } from '../../../models/ui/form';
import { ArgumentAction } from 'redux-logic/definitions/action';
import { redirectToRoute } from '../../location/actions';
import { formatTimeOfDateForDisplay } from '../../../utils/formatting/formatTime';

const ACTION_TYPES_THAT_FETCH_CONTACTS_IF_NOT_AVAILABLE_YET = [
    ...getRouteKeysThatBelongToGroup(ROUTE_GROUP.COMPANY_CONTACTS_FETCH_IF_NOT_AVAILABLE),
];

const ACTION_TYPES_THAT_FETCH_COMPANY_BUFFERZONE_DETAILS_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE);

// fetchRisksEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COMPANY_RISKS,
    refreshDataIf: ({ getState, action }) => {
        // Always refresh data if not available.
        if (!areCompanyRisksAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type } = getLocationState(getState());
        return type !== action.type;
    },
    processMultiple: fetchRisks,
    latest: false,
});

async function fetchRisks({ api, getState }: { api: typeof Api, getState }, dispatch, done) {
    try {
        const state = getState();

        let companyCode = getSelectedSeatCompanyCode(state);
        const companySeatPayload = getSelectedCompanySeat(state);

        if (companySeatPayload.isAllSeatsSelected) {
            companyCode = companySeatPayload.companySeat.company.companyCode;
        }

        const risks = await api.admin.risks.fetchRisks(companyCode, companySeatPayload.isAllSeatsSelected);
        dispatch(fetchRisksSucceeded(risks));
        done();
    } catch (error) {
        dispatch(fetchRisksFailed(error));
        done();
    }
}

// fetchCompanyMedicalCentersEpic
createEpic<ICompanyCodePayload>({
    onActionType: FETCH_COMPANY_MEDICAL_CENTERS,
    refreshDataIf: ({ getState, action }) => {
        return !areCompanyMedicalCentersAvailable(getState());
    },
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
            const companyMedicalCenters = await api.admin.companyInfo.fetchCompanyMedicalCenters(companyCode);

            return fetchCompanyMedicalCentersActions.succeeded(companyMedicalCenters);
        } catch (error) {
            return fetchCompanyMedicalCentersActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanyMedicalCentersIfNotAvailableEpic
createEpic<ICompanyCodePayload>({
    onActionType: [
        ROUTE_KEYS.R_FLU_VACCINES_ORDERS,
        ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL,
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD,
    ],
    processFilter: ({ getState }) => {
        return !areCompanyMedicalCentersAvailable(getState());
    },
    async processReturn({ api, action, getState }) {
        const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(getState());
        return fetchCompanyMedicalCentersActions.trigger({ companyCode });
    },
    latest: false,
});

// fetchCompanyCostCentersEpic
createEpic<ICompanyCodePayload>({
    onActionType: FETCH_COMPANY_COST_CENTERS,
    refreshDataIf: ({ getState, action }) => {
        if (areCompanyCostCentersAvailable(getState())) {
            return false;
        }

        return true;
    },
    async processReturn({ api, action }) {
        try {
            const { companyCode } = action.payload;
            const fetchRequest = api.admin.companyInfo.fetchCompanyCostCenters(companyCode);
            const companyCostCenters = await fetchRequest;
            return fetchCompanyCostCentersActions.succeeded(companyCostCenters);
        } catch (error) {
            return fetchCompanyCostCentersActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanyAddressesEpic
createEpic<ICompanyCodePayload>({
    onActionType: FETCH_COMPANY_ADDRESSES,
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const canSelectAllSeats = hasPermission(state, Permission.CAN_SELECT_ALL_SEATS);
            const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
            const companyAddresses = await api.admin.companyInfo.fetchCompanyAddresses(companyCode, canSelectAllSeats);
            return fetchCompanyAddressesActions.succeeded(companyAddresses);
        } catch (error) {
            return fetchCompanyAddressesActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanyAddressesIfNotAvailableEpic
createEpic<ICompanyCodePayload>({
    onActionType: [ROUTE_KEYS.R_FLU_VACCINES_ORDERS, ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL],
    processFilter: ({ getState }) => {
        return !areCompanyAddressesAvailable(getState());
    },
    async processReturn({ api, action, getState }) {
        const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(getState());
        return fetchCompanyAddressesActions.trigger({ companyCode });
    },
    latest: false,
});

// fetchCompanyDetailEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COMPANY_INFO_GENERAL,
    async processMultiple({ api, getState }, dispatch, done) {
        const companyCode = getSelectedSeatCompanyCode(getState());

        try {
            dispatch(fetchCompanyHolidaysActions.trigger({
                companyCode,
                year: getCurrentYear(),
            }));

            dispatch(fetchCompanyAvailabilities({
                availabilityType: CompanyAvailabilityType.MedicalExaminations,
                companyCode,
            }));
            dispatch(fetchCompanyAvailabilities({
                availabilityType: CompanyAvailabilityType.RiskManagement,
                companyCode,
            }));

            await fetchCompanyDetails(companyCode, { api, dispatch });

            done();
        } catch (error) {
            done();
        }
        done();
    },
    latest: false,
});

// fetchSmallCompanyDetailsEpic
createEpic<ICompanyCodePayload & IInitAppActionPayload>({
    onActionType: FETCH_SMALL_COMPANY_DETAIL,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            await fetchCompanyDetails(action.payload.companyCode, { api, dispatch });
        } catch (error) {
            if (isApplicationInitializationError(action.payload, error)) {
                dispatch(applicationInitializationFailed());
            }
        }
        done();
    },
    latest: false,
});

async function fetchCompanyDetails(companyCode: string, { api, dispatch }: IParallelCallInput) {
    try {
        const companyDetail = await api.admin.companyInfo.fetchCompanyDetail(companyCode);

        dispatch(fetchCompanyDetailSucceeded(companyDetail));
    } catch (error) {
        dispatch(fetchCompanyDetailFailed(error));
        throw error;
    }
}

// addCompanySeatEpic
createEpic<IAddCompanySeatPayload>({
    onActionType: ADD_COMPANY_SEAT,
    async processReturn({ api, action, getState }) {
        try {
            const selectedCompany = getSelectedCompany(getState());
            await api.admin.companyInfo.addCompanySeat({
                ...action.payload,
                companyCode: selectedCompany && selectedCompany.companyCode,
            });
            return addCompanySeatActions.succeeded({});
        } catch (error) {
            return addCompanySeatActions.failed(error);
        }
    },
    latest: false,
});

// updateCompanyEpic
createEpic<IUpdateCompanyPayload>({
    onActionType: UPDATE_COMPANY,
    async processReturn({ api, action }) {
        try {
            await api.admin.companyInfo.updateCompany(action.payload);
            return updateCompanyActions.succeeded({});
        } catch (error) {
            return updateCompanyActions.failed(error);
        }
    },
    latest: false,
});

// updateCompanySingleFieldEpic
createEpic<IUpdateCompanySingleFieldPayload>({
    onActionType: UPDATE_COMPANY_SINGLE_FIELD,
    latest: false, // because each update has it's own async status
    async processReturn({ api, action }) {
        const requestId = action.payload.requestId;

        try {
            await api.admin.companyInfo.updateCompany(action.payload);
            return updateCompanySingleFieldSucceeded({
                requestId,
                companyCode: action.payload.companyCode,
                companyData: action.payload.companyData,
            });
        } catch (error) {
            return updateCompanySingleFieldFailed({
                ...error,
                requestId,
            });
        }
    },
});

// fetchSeatDetailEpic
createEpic<{ companyCode: string }>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL,
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL,
    ],
    processMultiple({ action, getState }, dispatch, done) {
        const state = getState();
        const companyCode = action.payload.companyCode;

        const isOpenedFromDirectLink = !areCompanySeatsWithEmployeeCountAvailable(state);

        if (isOpenedFromDirectLink) {
            // direct link to the detail page, so first fetch the list
            dispatch(fetchCompanySeatsWithEmployeeCountActions.trigger({}));
        }

        if (isOpenedFromDirectLink || action.type === ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL) {
            dispatch(updateCompanyActions.reset({}));

            dispatch(fetchCompanyHolidaysActions.trigger({
                companyCode,
                year: getCurrentYear(),
            }));

            dispatch(fetchCompanyAvailabilities({
                availabilityType: CompanyAvailabilityType.MedicalExaminations,
                companyCode,
            }));
            dispatch(fetchCompanyAvailabilities({
                availabilityType: CompanyAvailabilityType.RiskManagement,
                companyCode,
            }));

            dispatch(fetchCompanySeatDetailActions.trigger({}));
        }

        done();
    },
    latest: true,
});

// fetchCompanyContactsEpic
createEpic<IFetchCompanyInternalContactsPayload>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_CONTACTS,
        FETCH_COMPANY_INTERNAL_CONTACTS,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();
        // do not refresh if only clientside (query) filtering changed
        const { type } = getLocationState(state);
        return !areCompanyContactsAvailable(state) || type !== action.type;
    },
    async processReturn({ api, getState, action }) {
        try {
            const state = getState();
            const selectedCompanySeat = getSelectedCompanySeat(state);
            const showFullFamily = selectedCompanySeat
                ? (action.type === ROUTE_KEYS.R_COMPANY_INFO_CONTACTS || action.payload.showFullFamilyIfApplicable)
                    ? selectedCompanySeat.isAllSeatsSelected
                    : false
                : false;
            let companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
            if (!companyCode) {
                companyCode = getSelectedCompany(state).companyCode;
            }

            const contacts = await api.admin.companyInfo.fetchCompanyContacts({
                companyCode,
                showFullFamily,
            });
            return fetchContactsSucceeded(contacts);
        } catch (error) {
            return fetchContactsFailed(error);
        }
    },
    latest: false,
});

// fetchCompanySeatContactsIfNotAvailableEpic
createEpic<IFetchCompanySeatContactsPayload>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL,
    ],
    processFilter: ({ getState }) => {
        const state = getState();
        return !areCompanySeatContactsAvailable(state);
    },
    processReturn({ action }) {
        return fetchCompanySeatContactsActions.trigger({
            companyCode: action.payload.companyCode,
        });
    },
    latest: true,
});

// fetchCompanySeatContactsEpic
createEpic<IFetchCompanySeatContactsPayload>({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL,
        FETCH_COMPANY_SEAT_INTERNAL_CONTACTS,
    ],
    async processReturn({ api, action }) {
        try {
            const companyCode = action.payload.companyCode;

            const contacts = await api.admin.companyInfo.fetchCompanyContacts({
                companyCode,
                filterStrict: true,
            });
            return fetchCompanySeatContactsActions.succeeded(contacts);
        } catch (error) {
            return fetchCompanySeatContactsActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanyContactsIfNotAvailableEpic
createEpic<IFetchCompanySeatContactsPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_CONTACTS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => {
        const state = getState();
        return !areCompanyContactsAvailable(state) && getCompanyContactsAsyncInfo(state).status !== AsyncStatus.Busy;
    },
    processReturn() {
        return fetchContacts();
    },
    latest: false,
});

// fetchCompanyContactDetailEpic
createEpic({
    onActionType: ROUTE_KEYS.R_COMPANY_INFO_CONTACT_DETAIL,
    processReturn() {
        return updateContactActions.reset({});
    },
    latest: false,
});

// removeContactEpic
createEpic<IRemoveCompanyContactPayload>({
    onActionType: REMOVE_CONTACT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            const state = getState();

            const companyCode = getSelectedSeatCompanyCode(getState()) || getSelectedCompany(getState()).companyCode;
            await api.admin.companyInfo.removeContact(companyCode, payload);
            dispatch(removeContactSucceeded());

            if (shouldRefreshCompanySeatContacts(state)) {
                const companySeatCode = getSelectedCompanyInfoSeatCompanyCode(state);
                dispatch(fetchCompanySeatContactsActions.trigger({ companyCode: companySeatCode }));
            } else {
                dispatch(fetchContacts({ showFullFamilyIfApplicable: true }));
            }

            done();
        } catch (error) {
            dispatch(removeContactFailed(error));
            done();
        }
    },
    latest: false,
});

// addContactEpic
createEpic<IAddCompanyContactPayload>({
    onActionType: ADD_CONTACT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            await api.admin.companyInfo.addContact(action.payload);
            dispatch(addContactSucceeded());

            if (shouldRefreshCompanySeatContacts(state)) {
                const companySeatCode = getSelectedCompanyInfoSeatCompanyCode(state);
                dispatch(fetchCompanySeatContactsActions.trigger({ companyCode: companySeatCode }));
            } else {
                dispatch(fetchContacts({ showFullFamilyIfApplicable: true }));
            }
        } catch (error) {
            dispatch(addContactFailed(error));
        }
        done();
    },
    latest: false,
});

// updateContactEpic
createEpic<IUpdateCompanyContactPayload>({
    onActionType: UPDATE_CONTACT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            await api.admin.companyInfo.updateContact(action.payload);
            dispatch(updateContactActions.succeeded({}));

            if (shouldRefreshCompanySeatContacts(state)) {
                const companySeatCode = getSelectedCompanyInfoSeatCompanyCode(state);
                dispatch(fetchCompanySeatContactsActions.trigger({ companyCode: companySeatCode }));
            } else {
                dispatch(fetchContacts({ showFullFamilyIfApplicable: true }));
            }

            done();
        } catch (error) {
            dispatch(updateContactActions.failed(error));
            done();
        }
    },
    latest: false,
});

// fetchCompanyAvailabilitiesEpic
createEpic<IFetchCompanyAvailabilitiesPayload>({
    onActionType: [FETCH_COMPANY_AVAILABILITIES_MED_EXAMS, FETCH_COMPANY_AVAILABILITIES_RISK_MGMT],
    latest: false, // because 2 simultaneous fetches will be executed for 2 different availability types
    async processMultiple({ api, action, getState }, dispatch, done) {
        const availabilityType = action.type === FETCH_COMPANY_AVAILABILITIES_MED_EXAMS ?
            CompanyAvailabilityType.MedicalExaminations : CompanyAvailabilityType.RiskManagement;

        try {
            // reset the update/replace flag so that we do not see a previous update/replace error
            dispatch(replaceCompanyAvailabilitiesReset(availabilityType));

            const companyCode = action.payload.companyCode;

            const availabilities = await api.admin.companyInfo.fetchCompanyAvailabilities({
                companyCode,
                availabilityType,
            });
            dispatch(fetchCompanyAvailabilitiesSucceeded(availabilityType, availabilities));
        } catch (error) {
            dispatch(fetchCompanyAvailabilitiesFailed(availabilityType, error));
        }

        done();
    },
});

// replaceCompanyAvailabilitiesEpic
createEpic<IReplaceCompanyAvailabilitiesPayload>({
    onActionType: [REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS, REPLACE_COMPANY_AVAILABILITIES_RISK_MGMT],
    async processMultiple({ api, action, getState }, dispatch, done) {
        const availabilityType = action.type === REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS ?
            CompanyAvailabilityType.MedicalExaminations : CompanyAvailabilityType.RiskManagement;

        try {
            const companyCode = action.payload.companyCode;

            const resultingAvailabilities = await api.admin.companyInfo.replaceCompanyAvailabilities({
                companyCode,
                availabilityType,
                applyFullFamily: action.payload.applyFullFamily,
                available: action.payload.availabilities.available,
                availabilities: action.payload.availabilities,
            });

            // 1) first refresh the availabilities ...
            dispatch(fetchCompanyAvailabilitiesSucceeded(availabilityType, resultingAvailabilities));
            // 2) ... and then mark the replace as done
            // (in that order so that when the loader stops, the new availabilities are already there)
            dispatch(replaceCompanyAvailabilitiesSucceeded(availabilityType));
        } catch (error) {
            dispatch(replaceCompanyAvailabilitiesFailed(availabilityType, error));
        }
        done();
    },
    latest: false,
});

// fetchCompanyHolidaysEpic
createEpic<IFetchCompanyHolidaysPayload>({
    onActionType: FETCH_COMPANY_HOLIDAYS,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const holidays = await api.admin.companyInfo.fetchCompanyHolidays(action.payload);
            dispatch(fetchCompanyHolidaysActions.succeeded(holidays));
        } catch (error) {
            dispatch(fetchCompanyHolidaysActions.failed(error));
        }

        done();
    },
    latest: false,
});

// updateCompanyHolidaysEpic
createEpic<IUpdateCompanyHolidaysPayload>({
    onActionType: UPDATE_COMPANY_HOLIDAYS,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            await api.admin.companyInfo.updateCompanyHolidays(action.payload);

            if (!action.payload.doNotRefreshAfterUpdate) {
                dispatch(fetchCompanyHolidaysActions.trigger({
                    companyCode: action.payload.companyCode,
                    year: getCurrentYear(),
                }));
            }

            dispatch(updateCompanyHolidaysActions.succeeded({}));
        } catch (error) {
            dispatch(updateCompanyHolidaysActions.failed(error));
        }

        done();
    },
    latest: false,
});

// fetchCompanyRatesEpic
createEpic<IFetchCompanyRatesPayload>({
    onActionType: [
        FETCH_COMPANY_RATES,
        ROUTE_KEYS.R_COMPANY_RATES,
    ],
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = action.payload.companyCode || getSelectedSeatCompanyCode(state);
            const rates = await api.admin.rates.fetchRates({ companyCode });

            return fetchCompanyRatesActions.succeeded(rates);
        } catch (error) {
            return fetchCompanyRatesActions.failed(error);
        }
    },
    latest: false,
});

// fetchCompanySeatsWithEmployeeCount
createEpic({
    onActionType: [
        ROUTE_KEYS.R_COMPANY_INFO_SEATS,
        FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();
        // do not refresh if only clientside (query) filtering changed
        const { type } = getLocationState(state);
        return !areCompanySeatsWithEmployeeCountAvailable(state) || type !== action.type;
    },
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const selectedCompany = getSelectedCompany(state);
            const { seats } = await api.admin.company.fetchCompanySeats({
                companyCode: selectedCompany.companyCode,
                showFullSeats: true,
                noEmployeeCount: false,
            });
            return fetchCompanySeatsWithEmployeeCountActions.succeeded(seats);
        } catch (error) {
            return fetchCompanySeatsWithEmployeeCountActions.failed(error);
        }
    },
    latest: true,
});

// fetchCompanySeatDetailsEpic
createEpic({
    onActionType: FETCH_COMPANY_SEAT_DETAIL,
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const selectedCompanySeatCompanyCode = getSelectedCompanyInfoSeatCompanyCode(state);
            const details = await api.admin.companyInfo.fetchCompanyDetail(selectedCompanySeatCompanyCode);
            return fetchCompanySeatDetailActions.succeeded(details);
        } catch (error) {
            return fetchCompanySeatDetailActions.failed(error);
        }
    },
    latest: true,
});

function shouldRefreshCompanySeatContacts(state: IState) {
    const routeKey = getRouteKey(state);
    return (
        routeKey === ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL ||
        routeKey === ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL
    );
}

// fetchCompanyBufferzones
createEpic<IFetchCompanyBufferzonePayload>({
    onActionType: [
        FETCH_COMPANY_BUFFERZONES,
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES,
    ],
    transform: ({ action, getState }, { next }) => {
        if (action.type === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES) {
            if (isCompanyAnInterimCompany(getState())) {
                return next(redirectToRoute(ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON, {}));
            }
        }

        return next(action);
    },
    async processMultiple({ action, getState, api }, dispatch, done) {
        dispatch(await asyncFetchCompanyBufferzones({ api, action, getState }));

        return done();
    },
    latest: false,
});

createEpic<IFetchCompanyBufferzonePayload>({
    onActionType: [
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES,
    ],
    refreshDataIf: ({ getState, action }) => {
        const state = getState();

        const bufferzones = getCompanyBufferzones(state);
        if (!bufferzones || bufferzones.length === 0) {
            return true;
        }

        const {
            type: prevRouteKey,
            query: prevFilter,
        } = getLocationState(state);

        if (prevRouteKey === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL) {
            /* No refresh if we navigate from details back to the overview */
            return false;
        }

        if (prevRouteKey === ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES) {
            /* No refresh if we stay on overview and only client side filtering changed */

            const prevFilterWithDefaults = {
                ...DEFAULT_COMPANY_BUFFERZONES_FILTERS,
                ...prevFilter,
            };

            return !areObjectParamsEqual(prevFilterWithDefaults, action.meta.query, ['startDate', 'endDate']);
        }

        return true;
    },
    processReturn: asyncFetchCompanyBufferzones,
    latest: false,
});

createEpic({
    onActionType: ACTION_TYPES_THAT_FETCH_COMPANY_BUFFERZONE_DETAILS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => !getSelectedCompanyBufferzone(getState()),
    processMultiple: fetchCompanyBufferzoneDetail,
    latest: false,
});

createEpic({
    onActionType: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL,
    processMultiple: fetchCompanyBufferzoneDetail,
    latest: false,
});

async function fetchCompanyBufferzoneDetail(
    // eslint-disable-next-line max-len
    { action, getState, api }: { action: IAction<IFetchCompanyBufferzonePayload>, getState: () => IState, api: typeof Api },
    dispatch: (action: ArgumentAction) => void,
    done: () => void,
) {
    const state = getState();
    const bufferzones = getCompanyBufferzones(state);
    if (!bufferzones || bufferzones.length === 0) {
        try {
            const fetchedBufferzones = await api.admin.companyInfo.fetchCompanyBufferzones({
                companyCode: getSelectedSeatCompanyCode(state),
                showFullFamily: isAllSeatsSelected(state),
            });
            dispatch(fetchCompanyBufferzones.succeeded(fetchedBufferzones));
        } catch (error) {
            dispatch(fetchCompanyBufferzones.failed(error));
        }
    } else {
        dispatch(fetchCompanyBufferzones.succeeded(bufferzones));
    }

    const updatedState = getState();

    const selectedBufferzone = getSelectedCompanyBufferzone(updatedState);
    if (selectedBufferzone) {
        dispatch(fetchPlannedMedicalExaminationsActions.trigger({
            medicalCenterCode: selectedBufferzone.medicalCenter.code,
            startDate: selectedBufferzone.date,
            endDate: selectedBufferzone.date,
            endTime: formatTimeOfDateForDisplay(selectedBufferzone.endTime),
            startTime: formatTimeOfDateForDisplay(selectedBufferzone.startTime),
            planningEntityId: selectedBufferzone.planningEntityId,
        }));
    } else {
        dispatch(fetchPlannedMedicalExaminationsActions.reset({}));
    }

    return done();
}

async function asyncFetchCompanyBufferzones({
    api,
    action,
    getState,
}: {
    api: typeof Api;
    action: IAction<IFetchCompanyBufferzonePayload>;
    getState: () => IState;
}) {
    try {
        // KZUAT-1790 & KZUAT-1763
        // Disabled companyCodeFilter because of production crash for non 'administrator' users,
        // We can't send showFullFamily 'true' for non-admin users, causes call to fail
        // We need to fix this later, commented this logic for now
        // const { companyCodeFilter } = action.payload;
        // const state = getState();

        // // If companyCode filter is specified, fetch all bufferzones and filter out
        // // non plannable ones with isPlanOnBufferzoneAllowedByCompanyCode()
        // const companyCode = companyCodeFilter ? getSelectedCompanyCode(state) : getSelectedSeatCompanyCode(state);
        // const showFullFamily = companyCodeFilter ? true : isAllSeatsSelected(state);
        // const filterFromQuery = getQueryParams(state) as IStartEndDateFilterValues;
        // const bufferzones = await api.admin.companyInfo.fetchCompanyBufferzones({
        //     companyCode,
        //     showFullFamily,
        //     startDate: action.payload.startDate || filterFromQuery.startDate,
        //     endDate: action.payload.endDate || filterFromQuery.endDate,
        // });

        // if (companyCodeFilter) {
        //     return fetchCompanyBufferzones.succeeded(bufferzones.filter((bufferzone) => {
        //         return isPlanOnBufferzoneAllowedByCompanyCode({
        //             bufferzoneCompanyCode: bufferzone.company.companyCode,
        //             employeeCompanyCode: companyCodeFilter,
        //         });
        //     }));
        // }

        // return fetchCompanyBufferzones.succeeded(bufferzones);

        const state = getState();
        const companyCode = getSelectedSeatCompanyCode(state);
        const showFullFamily = isAllSeatsSelected(state);
        const filterFromQuery = getQueryParams(state) as IStartEndDateFilterValues;
        const bufferzones = await api.admin.companyInfo.fetchCompanyBufferzones({
            companyCode,
            showFullFamily,
            startDate: action.payload.startDate || filterFromQuery.startDate,
            endDate: action.payload.endDate || filterFromQuery.endDate,
        });

        return fetchCompanyBufferzones.succeeded(bufferzones);
    } catch (error) {
        return fetchCompanyBufferzones.failed(error);
    }
}
