import { createEpic, IParallelCallInput } from '../../index';
import {
    UPDATE_EMPLOYEE,
    UPDATE_EMPLOYEE_EMPLOYMENT,
    SET_EMPLOYEE_OUT_OF_SERVICE,
    FETCH_EMPLOYEE_DETAILS,
    UPDATE_EMPLOYEE_RISK,
    REMOVE_EMPLOYEE_RISK,
    ADD_EMPLOYEE_STATUTE,
    FETCH_EMPLOYEE_STATUTES,
    FETCH_EMPLOYEE_ABSENCES,
    ADD_EMPLOYEE_ABSENCE,
    UPDATE_EMPLOYEE_ABSENCE,
    REMOVE_EMPLOYEE_ABSENCE,
    UPDATE_EMPLOYEE_ALL_FIELDS,
    FETCH_SMALL_EMPLOYEE_DETAILS,
    FETCH_EMPLOYEE_RISKS_AND_RESEARCHES,
    FETCH_EMPLOYEE_PERSONAL_RISKS,
    ADD_EMPLOYEE_PERSONAL_RISK,
    CHANGE_EMPLOYEE_OUT_OF_SERVICE,
    CLEAR_EMPLOYEE_OUT_OF_SERVICE,
    FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS,
    UPDATE_EMPLOYEE_COST_CENTER,
    UPDATE_EMPLOYEE_STATUTE,
    REMOVE_EMPLOYEE_STATUTE,
    FETCH_EMPLOYEE_JOB_STUDENT,
    UPDATE_EMPLOYEE_JOB_STUDENT,
    ADD_EMPLOYEE_JOB_STUDENT,
    FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ,
    FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES,
} from './types';
import {
    fetchEmployeeDetails as fetchEmployeeDetailsAction,
    fetchEmployeeAbsences as fetchEmployeeAbsencesAction,
    fetchEmployeeStatutes as fetchEmployeeStatutesAction,
    fetchEmployeeDetailsSucceeded, fetchEmployeeDetailsFailed,
    fetchEmployeeStatutesSucceeded, fetchEmployeeStatutesFailed,
    fetchEmployeeAbsencesSucceeded, fetchEmployeeAbsencesFailed,
    updateEmployeeSucceeded, updateEmployeeFailed,
    updateEmployeeEmploymentSucceeded, updateEmployeeEmploymentFailed,
    setEmployeeOutOfServiceSucceeded, setEmployeeOutOfServiceFailed,
    addEmployeeStatuteSucceeded, addEmployeeStatuteFailed,
    addEmployeeAbsenceSucceeded, addEmployeeAbsenceFailed,
    updateEmployeeAbsenceSucceeded, updateEmployeeAbsenceFailed,
    removeEmployeeAbsenceSucceeded, removeEmployeeAbsenceFailed,
    updateEmployeeAllFieldsSucceeded, updateEmployeeAllFieldsFailed,
    fetchEmployeeRisksAndResearches,
    fetchEmployeeRisksAndResearchesSucceeded, fetchEmployeeRisksAndResearchesFailed,
    fetchEmployeePersonalRisks,
    fetchEmployeePersonalRisksSucceeded, fetchEmployeePersonalRisksFailed,
    addEmployeePersonalRiskSucceeded, addEmployeePersonalRiskFailed,
    changeEmployeeOutOfServiceSucceeded, changeEmployeeOutOfServiceFailed,
    clearEmployeeOutOfServiceSucceeded, clearEmployeeOutOfServiceFailed,
    fetchEmployeeMedicalExaminations, fetchEmployeeMedicalExaminationsSucceeded,
    fetchEmployeeMedicalExaminationsFailed,
    updateEmployeeCostCenterSucceeded,
    updateEmployeeCostCenterFailed,
    updateEmployeeRiskActions,
    removeEmployeeRiskActions,
    updateEmployeeStatutesActions,
    removeEmployeeStatuteActions,
    fetchEmployeeJobStudentActions,
    updateEmployeeJobStudentActions,
    addEmployeeJobStudentActions,
    fetchEmployeeFunctionRisksAndResearchesActions,
    fetchEmployeeStatusActions,
} from './actions';
import { fetchEmployeesActions, fetchEmployeesWithoutEmailActions } from '../employees/actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    IFetchEmployeeDetailsPayload,
    IUpdateEmployeePayload,
    IUpdateEmloyeeByIdPayload,
    IUpdateEmployeeEmploymentPayload,
    ISetEmployeeOutOfServicePayload,
    IUpdateEmployeeRiskPayload,
    IAddEmployeeStatutePayload,
    IAddEmployeeAbsencePayload,
    IUpdateEmployeeAbsencePayload,
    IRemoveEmployeeRiskPayload,
    IRemoveEmployeeAbsencePayload,
    IUpdateEmployeeAllFieldsPayload,
    IFetchEmployeeRisksAndResearchesPayload,
    IFetchEmployeePersonalRisksPayload,
    IAddPersonalRiskPayload,
    IChangeEmployeeOutOfServicePayload,
    IClearEmployeeOutOfServicePayload,
    IFetchEmployeeMedicalExaminationsPayload,
    IUpdateEmployeeCostCenterPayload,
    IUpdateEmployeeStatutePayload,
    IRemoveEmployeeStatutePayload,
    IFetchEmployeeJobStudentPayload,
    IUpdateEmployeeJobStudentPayload,
    IAddEmployeeJobStudentPayload,
    IFetchEmployeeDetailsByInszPayload,
    IFetchEmployeeFunctionRisksAndResearchesPayload,
} from '../../../models/admin/employee';
import {
    getSelectedEmployee, isEmployeeDetailsDataAvailable,
} from './selectors';
import { getQueryParams, getRouteKey, getRoutePayload } from '../../location/selectors';
import { redirectToRoute, navigateTo } from '../../location/actions';
import { ITraceableApiError } from '../../../models/general/error';
import { isToday } from '../../../utils/core/date/isToday';
import { isInThePast } from '../../../utils/core/date/isInThePast';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import { getSelectedSeatCompanyCode, getSelectedCompanySeat } from '../../company/selected/selectors';
import { fetchCompanyCostCentersActions, fetchCompanyMedicalCentersActions } from '../../company/info/actions';
import {
    fetchExecutedMedicalExaminationDetail, fetchExaminationReasons,
} from '../../medicalExamination/actions';
import { areExaminationReasonsAvailable } from '../../medicalExamination/selectors';
import { isOnboardingWizardRoute, getEmployeesWithoutEmail } from '../employees/selectors';
import { fetchEmployeeCoursesActions } from '../../documentCenter/courses/actions';
import { getUpdateEmployeeRequestId } from '../../../views/administration/Employees/EmployeeDetails';
import { IState } from '../../IState';

const ACTION_TYPES_THAT_FETCH_EMPLOYEE_DETAILS_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE);
const EMPLOYEE_DETAIL_ROUTES = [
    ROUTE_KEYS.R_EMPLOYEE_DETAILS,
    ROUTE_KEYS.R_COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL,
    ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL,
    ROUTE_KEYS.R_ONBOARDING_EMPLOYEE_DETAIL,
    ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL,
];

// fetchEmployeeDetailsIfNotAlreadyAvailableEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_EMPLOYEE_DETAILS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => !isEmployeeDetailsDataAvailable(getState()),
    processReturn: ({ action }) => fetchEmployeeDetailsAction({ id: action.payload.id }),
    latest: true,
});

// fetchEmployeeDetailsEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: [
        ...EMPLOYEE_DETAIL_ROUTES,
        FETCH_EMPLOYEE_DETAILS,
    ],
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;
            const id = action.payload.id;

            dispatch(fetchCompanyMedicalCentersActions.trigger({ companyCode }));

            // When in onboarding wizard, we don't need all data, only the details.
            if (!isOnboardingWizardRoute(state)) {
                dispatch(fetchEmployeePersonalRisks({ id }));
                dispatch(fetchEmployeeMedicalExaminations({ id }));
                dispatch(fetchEmployeeCoursesActions.trigger({ id, showFullFamily }));
                dispatch(fetchCompanyCostCentersActions.trigger({ companyCode }));
                dispatch(fetchEmployeeJobStudentActions.trigger({ id }));

                if (!areExaminationReasonsAvailable(state)) {
                    dispatch(fetchExaminationReasons());
                }

                await Promise.all([
                    fetchEmployeeDetails(id, { api, dispatch }),
                    fetchEmployeeStatutes(id, { api, dispatch }),
                    fetchEmployeeAbsences(id, { api, dispatch }),
                ]);

                const stateAfterFetchingEmployeeDetails = getState();
                const employeeDetails = getSelectedEmployee(stateAfterFetchingEmployeeDetails);

                dispatch(fetchEmployeeFunctionRisksAndResearchesActions.trigger({
                    functionId: employeeDetails && employeeDetails.function && employeeDetails.function.id,
                }));
            } else {
                dispatch(fetchEmployeeJobStudentActions.trigger({ id }));
                await fetchEmployeeDetails(id, { api, dispatch });
            }

            done();
        } catch (err) {
            done();
        }
    },
    latest: true,
});

// fetchSmallEmployeeDetailsEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: FETCH_SMALL_EMPLOYEE_DETAILS,
    async processMultiple({ api, action }, dispatch, done) {
        await fetchEmployeeDetails(action.payload.id, { api, dispatch });
        done();
    },
    latest: true,
});

// fetchSmallEmployeeDetailsByInszEpic
createEpic<IFetchEmployeeDetailsByInszPayload>({
    onActionType: FETCH_SMALL_EMPLOYEE_DETAILS_BY_INSZ,
    async processMultiple({ api, action }, dispatch, done) {
        await fetchEmployeeDetailsByInsz(action.payload, { api, dispatch });
        done();
    },
    latest: true,
});

export async function fetchEmployeeDetails(id, { api, dispatch }: IParallelCallInput) {
    try {
        const employee = await api.admin.employee.fetchEmployeeDetails(id);
        const employeeStatus = await api.admin.employee.fetchEmployeeStatus(employee.id);

        dispatch(fetchEmployeeStatusActions.succeeded({ ...employeeStatus, id }));
        dispatch(fetchEmployeeDetailsSucceeded(employee));
    } catch (error) {
        dispatch(fetchEmployeeDetailsFailed(error));
    }
}

export async function fetchEmployeeDetailsByInsz(
    payload: IFetchEmployeeDetailsByInszPayload,
    { api, dispatch }: IParallelCallInput,
) {
    try {
        const employee = await api.admin.employee.fetchEmployeeByInsz({
            nationalRegisterNumber: payload.nationalRegisterNumber,
            selectedCompanyCompanyCode: payload.companyCode,
        });

        dispatch(fetchEmployeeDetailsSucceeded(employee));
    } catch (error) {
        dispatch(fetchEmployeeDetailsFailed(error));
    }
}

async function fetchEmployeeStatutes(id, { api, dispatch }: IParallelCallInput) {
    try {
        const statutes = await api.admin.employee.fetchEmployeeStatutes(id);
        dispatch(fetchEmployeeStatutesSucceeded(statutes));
    } catch (error) {
        dispatch(fetchEmployeeStatutesFailed(error));
    }
}

async function fetchEmployeeAbsences(id, { api, dispatch }: IParallelCallInput) {
    try {
        const absences = await api.admin.employee.fetchEmployeeAbsences(id);
        dispatch(fetchEmployeeAbsencesSucceeded(absences));
    } catch (error) {
        dispatch(fetchEmployeeAbsencesFailed(error));
    }
}

// fetchEmployeeStatutesEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: FETCH_EMPLOYEE_STATUTES,
    async processMultiple({ api, action }, dispatch) {
        return fetchEmployeeStatutes(action.payload.id, { api, dispatch });
    },
    latest: true,
});

// fetchEmployeAbsencesEpic
createEpic<IFetchEmployeeDetailsPayload>({
    onActionType: FETCH_EMPLOYEE_ABSENCES,
    async processMultiple({ api, action }, dispatch) {
        return fetchEmployeeAbsences(action.payload.id, { api, dispatch });
    },
    latest: true,
});

// updateEmployeeEpic
createEpic<IUpdateEmployeePayload>({
    onActionType: UPDATE_EMPLOYEE,
    latest: false, // because each update has it's own async status
    async processMultiple({ api, action, getState }, dispatch, done) {
        await updateEmployee(action.payload, { api, dispatch }, getState);
        done();
    },
});

export async function updateEmployee(
    payload: IUpdateEmployeePayload,
    { api, dispatch }: IParallelCallInput,
    getState: () => IState,
) {
    try {
        const result = await api.admin.employee.updateEmployee(payload);

        dispatch(updateEmployeeSucceeded({
            ...result,
            requestId: payload.requestId,
        }));

        const idTypedPayload = payload as IUpdateEmloyeeByIdPayload;
        if (idTypedPayload.updatedFromAddEmailList) {
            const state = getState();
            const employeesWithoutEmail = getEmployeesWithoutEmail(state);

            const updatedEmployees = employeesWithoutEmail.map((item) => {
                if (item.id === idTypedPayload.id) {
                    return {
                        ...item,
                        ...idTypedPayload.employeeData,
                    };
                }
                return item;
            });

            dispatch(fetchEmployeesWithoutEmailActions.succeeded(updatedEmployees));
        }

    } catch (error) {
        dispatch(updateEmployeeFailed({
            ...error,
            requestId: payload.requestId,
        }));
    }
}

// updateEmployeeAllFieldsEpic
createEpic<IUpdateEmployeeAllFieldsPayload>({
    onActionType: UPDATE_EMPLOYEE_ALL_FIELDS,
    async processReturn({ api, action }) {
        try {
            const result = await api.admin.employee.updateEmployee(action.payload);
            return updateEmployeeAllFieldsSucceeded(result);
        } catch (error) {
            return updateEmployeeAllFieldsFailed(error);
        }
    },
    latest: false,
});

// updateEmployeeEmploymentEpic
createEpic<IUpdateEmployeeEmploymentPayload>({
    onActionType: UPDATE_EMPLOYEE_EMPLOYMENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const {
                companyCode, customerFunctionId,
                dateInFunction, dateInService, id,
                duplicate,
            } = action.payload;
            const shouldUseNewId = isInThePast(dateInFunction) || isToday(dateInFunction);

            const newId = await api.admin.employee.updateEmployeeEmployment({
                id,
                companyCode,
                customerFunctionId,
                dateInFunction,
                dateInService,
            });

            if (duplicate && duplicate.risks) {
                await api.admin.employee.duplicateEmployeeRisks({
                    idFrom: id,
                    idTo: newId,
                    absences: false,
                    risks: duplicate.risks,
                    statutes: false,
                });
            }

            const newState = getState();
            dispatch(updateEmployeeEmploymentSucceeded({
                id: newId,
                shouldUseNewId,
            }));
            const currentRouteKey = getRouteKey(newState);
            if (shouldUseNewId && EMPLOYEE_DETAIL_ROUTES.includes(currentRouteKey)) {
                dispatch(redirectToRoute(
                    currentRouteKey,
                    {
                        ...getRoutePayload(newState),
                        id: newId,
                    },
                    getQueryParams(newState)),
                );
            } else {
                // Refresh the current employee details
                dispatch(fetchEmployeeDetailsAction({
                    id,
                }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            done();
        } catch (error) {
            dispatch(updateEmployeeEmploymentFailed(error));
            done();
        }
    },
    latest: false,
});

// setEmployeeOutOfServiceEpic
createEpic<ISetEmployeeOutOfServicePayload>({
    onActionType: SET_EMPLOYEE_OUT_OF_SERVICE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();

            const employeeToEdit = getSelectedEmployee(state);
            const wasFutureEmployee = employeeToEdit && !isInThePast(employeeToEdit.dateInService);

            await api.admin.employee.setEmployeeOutOfService(action.payload);

            if (wasFutureEmployee) {
                dispatch(navigateTo(ROUTE_KEYS.R_EMPLOYEES));
                dispatch(setEmployeeOutOfServiceSucceeded());
                return done();
            }

            const selectedEmployee = getSelectedEmployee(state);
            if (selectedEmployee) {
                dispatch(fetchEmployeeDetailsAction({ id: selectedEmployee.id }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(setEmployeeOutOfServiceSucceeded());
            done();
        } catch (error) {
            dispatch(setEmployeeOutOfServiceFailed(error));
            done();
        }
    },
    latest: false,
});

// changeEmployeeOutOfServiceEpic
createEpic<IChangeEmployeeOutOfServicePayload>({
    onActionType: CHANGE_EMPLOYEE_OUT_OF_SERVICE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            await api.admin.employee.changeEmployeeOutOfService(action.payload);
            const selectedEmployee = getSelectedEmployee(state);
            if (selectedEmployee) {
                dispatch(fetchEmployeeDetailsAction({ id: selectedEmployee.id }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(changeEmployeeOutOfServiceSucceeded());
            done();
        } catch (error) {
            dispatch(changeEmployeeOutOfServiceFailed(error));
            done();
        }
    },
    latest: false,
});

// clearEmployeeOutOfServiceEpic
createEpic<IClearEmployeeOutOfServicePayload>({
    onActionType: CLEAR_EMPLOYEE_OUT_OF_SERVICE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            await api.admin.employee.clearEmployeeOutOfService(action.payload);
            const selectedEmployee = getSelectedEmployee(state);
            if (selectedEmployee) {
                dispatch(fetchEmployeeDetailsAction({ id: selectedEmployee.id }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(clearEmployeeOutOfServiceSucceeded());
            done();
        } catch (error) {
            dispatch(clearEmployeeOutOfServiceFailed(error));
            done();
        }
    },
    latest: false,
});

// updateEmployeeRiskEpic
createEpic<IUpdateEmployeeRiskPayload>({
    onActionType: UPDATE_EMPLOYEE_RISK,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.admin.risks.updateRisk(action.payload);
            const selectedEmployee = getSelectedEmployee(getState());
            if (selectedEmployee) {
                dispatch(fetchEmployeeStatutesAction({ id: selectedEmployee.id }));
            }
            dispatch(updateEmployeeRiskActions.succeeded({}));
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(updateEmployeeRiskActions.failed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// removeEmployeeRiskEpic
createEpic<IRemoveEmployeeRiskPayload>({
    onActionType: REMOVE_EMPLOYEE_RISK,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.admin.risks.removeRisk(action.payload);
            const selectedEmployee = getSelectedEmployee(getState());
            if (selectedEmployee) {
                dispatch(fetchEmployeeStatutesAction({ id: selectedEmployee.id }));
            }
            dispatch(removeEmployeeRiskActions.succeeded({}));
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(removeEmployeeRiskActions.failed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// updateEmployeeStatuteEpic
createEpic<IUpdateEmployeeStatutePayload>({
    onActionType: UPDATE_EMPLOYEE_STATUTE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.admin.statutes.updateStatute(action.payload);
            const selectedEmployee = getSelectedEmployee(getState());
            if (selectedEmployee) {
                dispatch(fetchEmployeeStatutesAction({ id: selectedEmployee.id }));
            }
            dispatch(updateEmployeeStatutesActions.succeeded({}));
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(updateEmployeeStatutesActions.failed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// removeEmployeeStatuteEpic
createEpic<IRemoveEmployeeStatutePayload>({
    onActionType: REMOVE_EMPLOYEE_STATUTE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            await api.admin.statutes.removeStatute(action.payload);
            const selectedEmployee = getSelectedEmployee(getState());
            if (selectedEmployee) {
                dispatch(fetchEmployeeStatutesAction({ id: selectedEmployee.id }));
            }
            dispatch(removeEmployeeStatuteActions.succeeded({}));
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(removeEmployeeStatuteActions.failed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// addEmployeeStatuteEpic
createEpic<IAddEmployeeStatutePayload>({
    onActionType: ADD_EMPLOYEE_STATUTE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.addEmployeeStatute(payload);
            dispatch(fetchEmployeeStatutesAction({ id: payload.id }));
            dispatch(addEmployeeStatuteSucceeded());
            done();
        } catch (error) {
            dispatch(addEmployeeStatuteFailed(error));
            done();
        }
    },
    latest: false,
});

// addEmployeeAbsenceEpic
createEpic<IAddEmployeeAbsencePayload>({
    onActionType: ADD_EMPLOYEE_ABSENCE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.addEmployeeAbsence(payload);
            dispatch(fetchEmployeeAbsencesAction({ id: payload.id }));
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(addEmployeeAbsenceSucceeded());
            done();
        } catch (error) {
            dispatch(addEmployeeAbsenceFailed(error));
            done();
        }
    },
    latest: false,
});

// updateEmployeeAbsenceEpic
createEpic<IUpdateEmployeeAbsencePayload>({
    onActionType: UPDATE_EMPLOYEE_ABSENCE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.updateEmployeeAbsence(payload);
            dispatch(fetchEmployeeAbsencesAction({ id: payload.id }));
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(updateEmployeeAbsenceSucceeded());
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(updateEmployeeAbsenceFailed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// updateEmployeeCostCenterEpic
createEpic<IUpdateEmployeeCostCenterPayload>({
    onActionType: UPDATE_EMPLOYEE_COST_CENTER,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.updateEmployeeCostCenter(payload);

            // Refresh the current employee details
            dispatch(fetchEmployeeDetailsAction({
                id: payload.id,
            }));

            dispatch(updateEmployeeCostCenterSucceeded());
            done();
        } catch (error) {
            dispatch(updateEmployeeCostCenterFailed(error));
            done();
        }
    },
    latest: false,
});

// removeEmployeeAbsenceEpic
createEpic<IRemoveEmployeeAbsencePayload>({
    onActionType: REMOVE_EMPLOYEE_ABSENCE,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            await api.admin.employee.removeEmployeeAbsence(action.payload);
            const selectedEmployee = getSelectedEmployee(state);
            if (selectedEmployee) {
                dispatch(fetchEmployeeAbsencesAction({ id: selectedEmployee.id }));
            }
            dispatch(fetchEmployeesActions.trigger({
                refreshSources: 'true',
            }));
            dispatch(removeEmployeeAbsenceSucceeded());
            done();
        } catch (error) {
            const apiError = error as ITraceableApiError;
            // Inject the personal id in the error so we can track back for which item the error was thrown
            dispatch(removeEmployeeAbsenceFailed({
                ...apiError,
                extraData: {
                    ...apiError.extraData,
                    personalId: action.payload.personalId,
                },
            }));
            done();
        }
    },
    latest: false,
});

// fetchEmployeeJobStudent
createEpic<IFetchEmployeeJobStudentPayload>({
    onActionType: FETCH_EMPLOYEE_JOB_STUDENT,
    async processReturn({ api, action }) {
        try {
            const jobStudent = await api.admin.employee.fetchEmployeeJobStudent({ id: action.payload.id });
            return fetchEmployeeJobStudentActions.succeeded(jobStudent);
        } catch (error) {
            return fetchEmployeeJobStudentActions.failed(error);
        }
    },
    latest: true,
});

// addEmployeeJobStudent
createEpic<IAddEmployeeJobStudentPayload>({
    onActionType: ADD_EMPLOYEE_JOB_STUDENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            if (action.payload.emailAddressToUpdate) {
                const updateEmployeePayload = {
                    id: action.payload.id,
                    requestId: getUpdateEmployeeRequestId(action.payload.id, 'email'),
                    employeeData: {
                        email: action.payload.emailAddressToUpdate,
                    },
                };
                await updateEmployee(updateEmployeePayload, { api, dispatch }, getState);
            }
            await api.admin.employee.addEmployeeJobStudent(action.payload);
            dispatch(fetchEmployeeJobStudentActions.trigger({ id: action.payload.id }));
            dispatch(addEmployeeJobStudentActions.succeeded({}));
        } catch (error) {
            dispatch(addEmployeeJobStudentActions.failed(error));
        }
        return done();
    },
    latest: true,
});

// updateEmployeeJobStudent
createEpic<IUpdateEmployeeJobStudentPayload>({
    onActionType: UPDATE_EMPLOYEE_JOB_STUDENT,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            if (action.payload.emailAddressToUpdate) {
                const updateEmployeePayload = {
                    id: action.payload.id,
                    requestId: getUpdateEmployeeRequestId(action.payload.id, 'email'),
                    employeeData: {
                        email: action.payload.emailAddressToUpdate,
                    },
                };
                await updateEmployee(updateEmployeePayload, { api, dispatch }, getState);
            }
            await api.admin.employee.updateEmployeeJobStudent(action.payload);
            dispatch(fetchEmployeeJobStudentActions.trigger({ id: action.payload.id }));
            dispatch(updateEmployeeJobStudentActions.succeeded({}));
        } catch (error) {
            dispatch(updateEmployeeJobStudentActions.failed(error));
        }
        return done();
    },
    latest: true,
});

// fetchEmployeeRisksAndResearchesEpic
createEpic<IFetchEmployeeRisksAndResearchesPayload>({
    onActionType: FETCH_EMPLOYEE_RISKS_AND_RESEARCHES,
    async processReturn({ api, action }) {
        try {
            const risksAndResearches = await api.admin.employee
                .fetchEmployeeRisksAndResearches(action.payload.id.toString());
            return fetchEmployeeRisksAndResearchesSucceeded(risksAndResearches);
        } catch (error) {
            return fetchEmployeeRisksAndResearchesFailed(error);
        }
    },
    latest: true,
});

// fetchEmployeeRisksAndResearchesEpic
createEpic<IFetchEmployeeFunctionRisksAndResearchesPayload>({
    onActionType: FETCH_EMPLOYEE_FUNCTION_RISKS_AND_RESEARCHES,
    async processReturn({ api, action }) {
        try {
            const risks = await api.admin.functions
                .fetchCompanyFunctionRisks(action.payload.functionId.toString());
            const researches = await api.admin.functions
                .fetchCompanyFunctionResearches(action.payload.functionId.toString());

            return fetchEmployeeFunctionRisksAndResearchesActions.succeeded({
                risks,
                researches,
            });
        } catch (error) {
            return fetchEmployeeFunctionRisksAndResearchesActions.failed(error);
        }
    },
    latest: true,
});

// fetchEmployeePersonalRisksEpic
createEpic<IFetchEmployeePersonalRisksPayload>({
    onActionType: FETCH_EMPLOYEE_PERSONAL_RISKS,
    async processReturn({ api, action }) {
        try {
            const personalRisks = await api.admin.employee.fetchEmployeePersonalRisks(action.payload.id.toString());
            return fetchEmployeePersonalRisksSucceeded(personalRisks);
        } catch (error) {
            return fetchEmployeePersonalRisksFailed(error);
        }
    },
    latest: true,
});

// addEmployeePersonalRiskEpic
createEpic<IAddPersonalRiskPayload>({
    onActionType: ADD_EMPLOYEE_PERSONAL_RISK,
    async processMultiple({ api, action }, dispatch, done) {
        try {
            const { payload } = action;
            await api.admin.employee.addEmployeePersonalRisk(payload);
            dispatch(fetchEmployeeRisksAndResearches({ id: payload.employee.id }));
            dispatch(fetchEmployeePersonalRisks({ id: payload.employee.id }));
            dispatch(addEmployeePersonalRiskSucceeded());
            done();
        } catch (error) {
            dispatch(addEmployeePersonalRiskFailed(error));
            done();
        }
    },
    latest: false,
});

// fetchEmployeeMedicalExaminationsEpic
createEpic<IFetchEmployeeMedicalExaminationsPayload>({
    onActionType: FETCH_EMPLOYEE_MEDICAL_EXAMINATIONS,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const state = getState();
            const medicalExaminations = await api.admin.employee
                .fetchEmployeeMedicalExaminations({ id: action.payload.id });

            dispatch(fetchEmployeeMedicalExaminationsSucceeded(medicalExaminations));

            if (getRouteKey(state) === ROUTE_KEYS.R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL) {
                dispatch(fetchExecutedMedicalExaminationDetail(getRoutePayload(state)));
            }
        } catch (error) {
            dispatch(fetchEmployeeMedicalExaminationsFailed(error));
        }
        done();
    },
    latest: true,
});
