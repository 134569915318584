import { API_URL, HEADER_NAME } from '../../config/api.config';
import { IAccessLevel } from '../../models/auth/authorisation';
import { ILoggedInUser, IRequestAccountPayload, ICreateAccountPayload } from '../../models/auth/authentication';
import { IUserAccount } from '../../models/user/userAccount';
import { Locales } from '../../config/i18n.config';
import { localeToBffLocale } from '../../utils/formatting/formatLocale';
import { post } from '../../utils/api/requestWrapper';

export const URL = {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REQUEST_ACCOUNT: '/auth/request-account',
    CONVERT_ACCESSLEVEL: '/auth/convert-accesslevel',
};

export const BFF_URL = {
    CREATE_ACCOUNT: '/customeruser/onboarding/profile',
};

export function login({ auth0IdToken }: { auth0IdToken: string; }) {
    return post<ILoggedInUser>({
        url: URL.LOGIN,
        body: {
            jwt: auth0IdToken,
        },
        mapResponse: (data, headers) => {
            return {
                user: {
                    ...data,
                    name: data.lastName,
                },
                csrfToken: getCsrfTokenFromResponseHeader(headers),
            };
        },
    });
}

export function logout() {
    return post({
        url: URL.LOGOUT,
        resetSessionTimerOnSuccess: false,
    });
}

export function requestAccount(payload: IRequestAccountPayload) {
    return post<IUserAccount[]>({
        url: URL.REQUEST_ACCOUNT,
        body: {
            company: payload,
        },
        mapResponse: (data, headers) => data.administrators,
    });
}

export function createAccount(payload: ICreateAccountPayload, locale: Locales) {
    const { name, companyCode, ventureNumber, sexId, ...rest } = payload;

    return post({
        api: API_URL.BFF,
        url: BFF_URL.CREATE_ACCOUNT,
        body: {
            ...rest,
            lastName: name,
            genderId: sexId,
            cbeNumber: ventureNumber,
            organizationalUnitCode: companyCode,
            languageCode: localeToBffLocale(locale),
        },
        headers: {
            [HEADER_NAME.ACCEPT_LANGUAGE]: localeToBffLocale(locale),
        },
        addCorrelationIdHeader: true,
    });
}

export function convertAccessLevelToUserGroupId(payload: IAccessLevel) {
    return post<number>({
        url: URL.CONVERT_ACCESSLEVEL,
        body: payload,
        mapResponse: (data) => data && data['mensura-permission'],
    });
}

function getCsrfTokenFromResponseHeader(headers) {
    return headers && headers[HEADER_NAME.CSRF_TOKEN];
}
