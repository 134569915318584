import React from 'react';
import { IUserMessageDetailAction, UserMessageDetailActionType } from '../../../../../models/user/inbox';
import ROUTE_KEYS from '../../../../../routeKeys';
import LinkToRoute from '../../../../common/navigation/LinkToRoute';
import { createLocationAction } from '../../../../../utils/libs/redux/createAction';
import { getLinkId, formatDateForFilterInput } from './messageBodyUtils';
import FetchDocumentAction from './FetchDocumentAction';
import DownloadAttachmentAction from './DownloadAttachmentAction';

export default function UserMessageDetailAction({ content, action }: {
    content: string;
    action: IUserMessageDetailAction;
}) {
    const { actionType, id } = action;

    switch (actionType) {
        case UserMessageDetailActionType.downloadAttachment: {
            // const { fileName } = action; // should be renamed to filename if we would use it

            return (
                <DownloadAttachmentAction
                    attachmentId={id.toString()}
                    linkText={content}
                />
            );
        }

        case UserMessageDetailActionType.linkEmployee: {
            const { insz } = action;

            return renderPageLinkAction({
                content,
                action,
                routeKey: ROUTE_KEYS.R_EMPLOYEE_DETAILS,
                payload: { id },
                query: { search: insz },
            });
        }

        // rendered within listOfExaminationDocuments
        case UserMessageDetailActionType.linkExaminationDocument: {
            const { text } = action;

            return (
                <FetchDocumentAction
                    documentId={id && id.toString()}
                    documentDescription={text}
                    defaultFilename="examination-document"
                />
            );
        }

        case UserMessageDetailActionType.linkExecutedExam: {
            const formattedDate = formatDateForFilterInput(action.date);

            return renderPageLinkAction({
                content,
                action,
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL,
                payload: { examinationId: id },
                query: {
                    startDate: formattedDate,
                    endDate: formattedDate,
                },
            });
        }

        case UserMessageDetailActionType.linkInvoices: {
            const { invoiceNumber } = action;

            return renderPageLinkAction({
                content,
                action,
                routeKey: ROUTE_KEYS.R_INVOICES_OPEN,
                query: { search: invoiceNumber },
            });
        }

        case UserMessageDetailActionType.linkNoshows: {
            const formattedDate = formatDateForFilterInput(action.date);

            return renderPageLinkAction({
                content,
                action,
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NO_SHOWS,
                query: {
                    startDate: formattedDate,
                    endDate: formattedDate,
                },
            });
        }

        case UserMessageDetailActionType.linkPlannedExam: {
            const formattedDate = formatDateForFilterInput(action.date);

            return renderPageLinkAction({
                content,
                action,
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED_DETAIL,
                payload: { examinationId: id },
                query: {
                    startDate: formattedDate,
                    endDate: formattedDate,
                },
            });
        }
    }

    /* Unexpected type */
    console.log(`Unexpected body action of type "${actionType}"`, action);
    return null;
}

function renderPageLinkAction({
    content,
    action,
    routeKey,
    payload = {},
    query = {},
}: {
    content: string;
    action: IUserMessageDetailAction;
    routeKey: ROUTE_KEYS;
    payload?: {};
    query?: {};
}) {
    const { text } = action;

    return (
        <LinkToRoute
            id={getLinkId(action)}
            to={createLocationAction(
                routeKey,
                payload,
                query,
            )}
        >
            {text || content}
        </LinkToRoute>
    );
}
