import { ICalendarEvent } from '../../models/ui/calendar';
import { getDateWithoutSeconds } from '../core/date/getSpecificDate';

function mergeSimultaniousEventsWithTheSameType<Data extends object>(calendarEvents: ICalendarEvent<Data[]>[]) {
    return calendarEvents.reduce(
        (accumulator, event) => {
            const { start, end } = event;
            const eventWithSameStartAndEndTimeIndex = accumulator.findIndex((item) => {
                return (
                    item.allDay === event.allDay &&
                    item.type === event.type &&
                    getDateWithoutSeconds(item.start).getTime() === getDateWithoutSeconds(start).getTime() &&
                    getDateWithoutSeconds(item.end).getTime() === getDateWithoutSeconds(end).getTime()
                );
            });
            event.isConcatenated = true;
            if (eventWithSameStartAndEndTimeIndex !== -1) {
                accumulator[eventWithSameStartAndEndTimeIndex].data.push(event.data[0]);
            } else {
                accumulator.push(event);
            }
            return accumulator;
        },
        [] as ICalendarEvent<Data[]>[],
    );
}

export default mergeSimultaniousEventsWithTheSameType;
