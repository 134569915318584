import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import { createActionHandlersForType } from '@snipsonian/redux/es/reducer/createActionHandlersForType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    createActionHandler,
    registerReducer,
} from '../index';
import {
    IMedicalExaminationToPlan,
    IPlannedMedicalExamination,
    IExaminationReason,
    IPlanMedicalExaminationWizardPayload,
    IPlanMedicalExaminationWizardData,
    IExecutedMedicalExamination,
    IMedicalExaminationToAdd,
    IMedicalCenterFreeSlots,
    IPeriodicHealthAssessmentAutomaticEntity,
    IMedicalCenterFreeSlotsPayload,
    IMedicalCentersFreeSlotsSuccessPayload,
    IPlanMedicalExaminationMultipleEmployeesBaseEntity,
    IReintegrationManualEntity,
    IMedicalExaminationDocuments,
    INoShowMedicalExamination,
    IValidateEmployeeToPlanData,
} from '../../models/interventions/medicalExaminations';
import { IEmployeeDetails } from '../../models/admin/employee';
import {
    FETCH_MEDICAL_EXAMINATIONS_TO_PLAN,
    FETCH_PLANNED_MEDICAL_EXAMINATIONS,
    FETCH_EXAMINATION_REASONS,
    UPDATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    FETCH_EMPLOYEES_TO_PLAN,
    FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
    FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL,
    UPDATE_TIMESLOT,
    REMOVE_TIMESLOT,
    AUTO_PLAN_EMPLOYEES,
    REMOVE_EXAMINATION_TO_ADD_FROM_ENTITY,
    FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS,
    FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL,
    FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
    SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP,
    RESET_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    REMOVE_UPLOADED_FILE_FROM_RE_INTEGRATION_ENTITY,
    CREATE_RE_INTEGRATION_REQUEST,
    ADD_TIMESLOT,
    FETCH_MEDICAL_EXAMINATION_DOCUMENTS,
    CREATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY,
    FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES,
    FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
    REMOVE_UPLOADED_WORK_POST_CARD_FROM_INTERIM_ENTITY,
    VALIDATE_EMPLOYEE_TO_PLAN,
    REQUEST_MEDICAL_EXAMINATION,
    FETCH_TIME_CELL_PLANNING_CONFIG,
} from './types';
import ROUTE_KEYS from '../../routeKeys';
import { IAsyncDoField, ITraceableAsyncRequestPayload } from '../../models/general/redux';
import { getAsyncDoInitialState, createAsyncDoActionHandlers } from '../../utils/libs/redux/async/asyncReducerUtils';
import {
    IAutoPlanEmployeeSuccessPayload,
    ITimeCell,
    IAddTimeslotSuccessPayload,
    IUpdateTimeslotSuccessPayload,
    ITimeCellPlanningConfiguration,
} from '../../models/interventions/timeslots';
import { getMedExamToAddId } from '../../utils/interventions/medicalExaminations/getMedExamToAddId';
import { ITraceableApiError } from '../../models/general/error';
import isTraceableApiError from '../../utils/api/isTraceableApiError';
import { IDocument } from '../../models/general/documents';
import { DISMISS_DOCUMENT_DIALOG } from '../employee/documents/types';
import { getPlanMedicalExaminatonWizardSteps } from '../../config/navigation/wizardStepsMap';
import { REDUCER_KEYS } from '../../config/redux.config';

export const reducerKey = REDUCER_KEYS.MEDICALEXAMINATION;

export interface IReducerState {
    medicalExaminationsToPlan: IAsyncFetchField<IMedicalExaminationToPlan[]>;
    plannedMedicalExaminations: IAsyncFetchField<IPlannedMedicalExamination[]>;
    executedMedicalExaminations: IAsyncFetchField<IExecutedMedicalExamination[]>;
    noShowsMedicalExaminations: IAsyncFetchField<INoShowMedicalExamination[]>;
    selectedPlannedMedicalExamination: IAsyncFetchField<IPlannedMedicalExamination>;
    selectedExecutedMedicalExamination: IAsyncFetchField<IExecutedMedicalExamination>;
    selectedRisksAndResearches: IAsyncFetchField<{}>; // TODO
    medicalExaminationDocuments: IAsyncFetchField<IMedicalExaminationDocuments>;
    examinationDocumentsFiles: IAsyncFetchField<IDocument[]>;

    examinationReasons: IAsyncFetchField<IExaminationReason[]>;
    // object that will be filled partially during the plan-medical-examination wizard flows
    planMedicalExaminationWizardData: IPlanMedicalExaminationWizardData;
    employeesToPlan: IAsyncFetchField<IMedicalExaminationToPlan[]>;

    addTimeslot: IAsyncDoField;
    updateTimeslot: IAsyncDoField;
    lastUpdatedTimeslotPlanningId: number;
    removeTimeslot: IAsyncDoField;
    autoPlanEmployees: IAsyncDoField;

    companyMedicalCentersFreeSlotsRequests: { [requestId: string]: IAsyncDoField };
    companyMedicalCenterTimeslots: IAsyncFetchField<ITimeCell[]>;

    skipToPlanMedicalExaminationWizardStep: IAsyncDoField;

    createReIntegrationRequest: IAsyncDoField;

    validateEmployeeToPlan: IAsyncFetchField<IValidateEmployeeToPlanData>;
    requestMedicalExamination: IAsyncDoField;
    timeCellPlanningConfiguration: IAsyncFetchField<ITimeCellPlanningConfiguration>;
}

const initialState: IReducerState = {
    medicalExaminationsToPlan: getAsyncFetchInitialState(),
    plannedMedicalExaminations: getAsyncFetchInitialState(),
    executedMedicalExaminations: getAsyncFetchInitialState(),
    noShowsMedicalExaminations: getAsyncFetchInitialState(),
    selectedPlannedMedicalExamination: getAsyncFetchInitialState(),
    selectedExecutedMedicalExamination: getAsyncFetchInitialState(),
    selectedRisksAndResearches: getAsyncFetchInitialState(),
    medicalExaminationDocuments: getAsyncFetchInitialState(),
    examinationDocumentsFiles: getAsyncFetchInitialState(),

    examinationReasons: getAsyncFetchInitialState(),
    planMedicalExaminationWizardData: null,
    employeesToPlan: getAsyncFetchInitialState(),

    addTimeslot: getAsyncDoInitialState(),
    updateTimeslot: getAsyncDoInitialState(),
    lastUpdatedTimeslotPlanningId: null,
    removeTimeslot: getAsyncDoInitialState(),
    autoPlanEmployees: getAsyncDoInitialState(),

    companyMedicalCentersFreeSlotsRequests: {},
    companyMedicalCenterTimeslots: getAsyncFetchInitialState(),

    skipToPlanMedicalExaminationWizardStep: getAsyncDoInitialState(),

    createReIntegrationRequest: getAsyncDoInitialState(),

    validateEmployeeToPlan: getAsyncFetchInitialState(),

    requestMedicalExamination: getAsyncDoInitialState(),
    timeCellPlanningConfiguration: getAsyncFetchInitialState(),
};

const actionHandlers = {
    ...createActionHandlersForType<IReducerState>(DISMISS_DOCUMENT_DIALOG)
        .onTrigger(({ oldState }) => {
            return {
                ...oldState,
                examinationDocumentsFiles: getAsyncFetchInitialState(),
            };
        })
        .create(),
    ...createAsyncFetchActionHandlers<IMedicalExaminationToPlan[], IReducerState, IMedicalExaminationToPlan[]>({
        baseActionType: FETCH_MEDICAL_EXAMINATIONS_TO_PLAN,
        fieldName: 'medicalExaminationsToPlan',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN,
            FETCH_MEDICAL_EXAMINATIONS_TO_PLAN,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IPlannedMedicalExamination[], IReducerState, IPlannedMedicalExamination[]>({
        baseActionType: FETCH_PLANNED_MEDICAL_EXAMINATIONS,
        fieldName: 'plannedMedicalExaminations',
        overrideTriggerActionType: [
            ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
            FETCH_PLANNED_MEDICAL_EXAMINATIONS,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<INoShowMedicalExamination[], IReducerState, INoShowMedicalExamination[]>({
        baseActionType: FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
        fieldName: 'noShowsMedicalExaminations',
        overrideTriggerActionType: [
            FETCH_NO_SHOWS_MEDICAL_EXAMINATIONS,
            ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NO_SHOWS,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IExecutedMedicalExamination[], IReducerState, IExecutedMedicalExamination[]>({
        baseActionType: FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
        fieldName: 'executedMedicalExaminations',
        overrideTriggerActionType: [
            FETCH_EXECUTED_MEDICAL_EXAMINATIONS,
            ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IEmployeeDetails, IReducerState, IEmployeeDetails>({
        baseActionType: FETCH_EXECUTED_MEDICAL_EXAMINATION_DETAIL,
        fieldName: 'selectedExecutedMedicalExamination',
        extraAsyncFetchFieldNamesToResetOnTrigger: [
            'selectedRisksAndResearches', 'medicalExaminationDocuments',
        ],
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IEmployeeDetails, IReducerState, IEmployeeDetails>({
        baseActionType: FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL,
        fieldName: 'selectedPlannedMedicalExamination',
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IExaminationReason[], IReducerState, IExaminationReason[]>({
        baseActionType: FETCH_EXAMINATION_REASONS,
        fieldName: 'examinationReasons',
        overrideTriggerActionType: [
            FETCH_EXAMINATION_REASONS,
            ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON,
        ],
        resetDataOnTrigger: false,
        reducerKey,
    }),
    [RESET_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY]:
        createActionHandler<IReducerState>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    planMedicalExaminationWizardData: null,
                };
            },
        ),
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD]:
        createActionHandler<IReducerState, IPlanMedicalExaminationWizardPayload>(
            ({ oldState, payload }) => {
                const firstStepId = getPlanMedicalExaminatonWizardSteps(payload.wizardType).firstStepId;
                const stepId = payload.step;
                return {
                    ...oldState,
                    planMedicalExaminationWizardData: (firstStepId === stepId || payload.skipToStep)
                        ? {
                            wizardType: payload.wizardType,
                            stepId,
                            // keep existing reason if not provided (e.g. when navigating back from step 2 to 1)
                            examinationReason: payload.reason
                                || (oldState.planMedicalExaminationWizardData
                                    && oldState.planMedicalExaminationWizardData.examinationReason),
                            entity: payload.resetDataEntity
                                ? {}
                                : (oldState.planMedicalExaminationWizardData
                                    && oldState.planMedicalExaminationWizardData.entity) || {},
                        }
                        : {
                            ...oldState.planMedicalExaminationWizardData,
                            stepId,
                        },
                };
            },
        ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP,
        fieldName: 'skipToPlanMedicalExaminationWizardStep',
    }),
    [UPDATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    planMedicalExaminationWizardData: {
                        ...oldState.planMedicalExaminationWizardData,
                        entity: {
                            ...oldState.planMedicalExaminationWizardData.entity,
                            ...payload,
                        },
                    },
                };
            },
        ),
    [CREATE_PLAN_MEDICAL_EXAMINATION_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    planMedicalExaminationWizardData: {
                        ...oldState.planMedicalExaminationWizardData,
                        entity: {
                            ...payload,
                        },
                    },
                };
            },
        ),
    [REMOVE_UPLOADED_FILE_FROM_RE_INTEGRATION_ENTITY]:
        createActionHandler<IReducerState, IReintegrationManualEntity>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    planMedicalExaminationWizardData: {
                        ...oldState.planMedicalExaminationWizardData,
                        entity: {
                            ...oldState.planMedicalExaminationWizardData.entity,
                            certificate: null,
                        },
                    },
                };
            },
        ),
    [REMOVE_UPLOADED_WORK_POST_CARD_FROM_INTERIM_ENTITY]:
        createActionHandler<IReducerState, IReintegrationManualEntity>(
            ({ oldState }) => {
                return {
                    ...oldState,
                    planMedicalExaminationWizardData: {
                        ...oldState.planMedicalExaminationWizardData,
                        entity: {
                            ...oldState.planMedicalExaminationWizardData.entity,
                            workPostCard: null,
                        },
                    },
                };
            },
        ),
    [REMOVE_EXAMINATION_TO_ADD_FROM_ENTITY]:
        createActionHandler<IReducerState, IMedicalExaminationToAdd>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    planMedicalExaminationWizardData: {
                        ...oldState.planMedicalExaminationWizardData,
                        entity: {
                            ...oldState.planMedicalExaminationWizardData.entity,
                            medicalExaminationsToAdd:
                                // eslint-disable-next-line max-len
                                (oldState.planMedicalExaminationWizardData.entity as IPlanMedicalExaminationMultipleEmployeesBaseEntity)
                                    .medicalExaminationsToAdd.filter(
                                        (examination) => getMedExamToAddId(examination) !== getMedExamToAddId(payload),
                                    ),
                        },
                    },
                };
            },
        ),
    ...createAsyncFetchActionHandlers<IMedicalExaminationToPlan[], IReducerState, IMedicalExaminationToPlan[]>({
        baseActionType: FETCH_EMPLOYEES_TO_PLAN,
        fieldName: 'employeesToPlan',
        resetDataOnTrigger: false,
        reducerKey,
    }),

    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: ADD_TIMESLOT,
        fieldName: 'addTimeslot',
        transformStateOnSuccess: updateMedicalExaminationToPlanWizardData,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: UPDATE_TIMESLOT,
        fieldName: 'updateTimeslot',
        transformStateOnSuccess: updateMedicalExaminationToPlanWizardData,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: REMOVE_TIMESLOT,
        fieldName: 'removeTimeslot',
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: AUTO_PLAN_EMPLOYEES,
        fieldName: 'autoPlanEmployees',
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IAutoPlanEmployeeSuccessPayload[];

            const currentMedicalExaminations =
                (oldState.planMedicalExaminationWizardData.entity as IPlanMedicalExaminationMultipleEmployeesBaseEntity)
                    .medicalExaminationsToAdd;

            const newMedicalExaminationsToAdd =
                addResponseParametersToExaminationsToAdd(currentMedicalExaminations, successPayload);

            return {
                ...oldState,
                planMedicalExaminationWizardData: {
                    ...oldState.planMedicalExaminationWizardData,
                    entity: {
                        ...oldState.planMedicalExaminationWizardData.entity,
                        medicalExaminationsToAdd: newMedicalExaminationsToAdd,
                    },
                },
            };
        },
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: FETCH_COMPANY_MEDICAL_CENTERS_FREESLOTS,
        fieldName: 'companyMedicalCentersFreeSlotsRequests',
        transformStateOnTrigger: transformCompanyMedicalCentersFreeSlotsRequestsState,
        transformStateOnFail: transformCompanyMedicalCentersFreeSlotsRequestsState,
        transformStateOnSuccess: ({ oldState, payload }) => {
            const successPayload = payload as IMedicalCentersFreeSlotsSuccessPayload;
            const requestId = successPayload.requestId;

            const entity =
                oldState.planMedicalExaminationWizardData.entity as IPeriodicHealthAssessmentAutomaticEntity;

            const currentAddedLocationFreeSlots = entity.locationFreeSlots || [];

            const updatedAddedLocationFreeSlots =
                addResponseToCurrentFreeSlots(currentAddedLocationFreeSlots, successPayload.medicalCenters);

            return {
                ...oldState,
                companyMedicalCentersFreeSlotsRequests: {
                    ...oldState.companyMedicalCentersFreeSlotsRequests,
                    [requestId]: undefined,
                },
                planMedicalExaminationWizardData: {
                    ...oldState.planMedicalExaminationWizardData,
                    entity: {
                        ...oldState.planMedicalExaminationWizardData.entity,
                        locationFreeSlots: updatedAddedLocationFreeSlots,
                    },
                },
            };
        },
    }),
    ...createAsyncFetchActionHandlers<ITimeCell[], IReducerState>({
        baseActionType: FETCH_COMPANY_MEDICAL_CENTER_TIMESLOTS,
        fieldName: 'companyMedicalCenterTimeslots',
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CREATE_RE_INTEGRATION_REQUEST,
        fieldName: 'createReIntegrationRequest',
    }),
    ...createAsyncFetchActionHandlers<IMedicalExaminationDocuments, IReducerState, IMedicalExaminationDocuments>({
        baseActionType: FETCH_MEDICAL_EXAMINATION_DOCUMENTS,
        fieldName: 'medicalExaminationDocuments',
        resetDataOnTrigger: false,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IDocument[], IReducerState, IDocument[]>({
        baseActionType: FETCH_MEDICAL_EXAMINATION_DOCUMENTS_AND_FILES,
        fieldName: 'examinationDocumentsFiles',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IValidateEmployeeToPlanData, IReducerState, IValidateEmployeeToPlanData>({
        baseActionType: VALIDATE_EMPLOYEE_TO_PLAN,
        fieldName: 'validateEmployeeToPlan',
        resetDataOnTrigger: true,
        reducerKey,
    }),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: REQUEST_MEDICAL_EXAMINATION,
        fieldName: 'requestMedicalExamination',
    }),
    ...createAsyncFetchActionHandlers<ITimeCellPlanningConfiguration, IReducerState, ITimeCellPlanningConfiguration>({
        baseActionType: FETCH_TIME_CELL_PLANNING_CONFIG,
        fieldName: 'timeCellPlanningConfiguration',
        resetDataOnTrigger: true,
        reducerKey,
    }),
};

function addResponseParametersToExaminationsToAdd(
    medicalExaminationsToAdd: IMedicalExaminationToAdd[],
    successPayload: IAutoPlanEmployeeSuccessPayload[],
) {
    return medicalExaminationsToAdd.map((examination) => {
        const newExaminationToAdd = { ...examination };
        const plannedExamination = successPayload && successPayload.find((plannedExam) => (
            getMedExamToAddId(plannedExam) === getMedExamToAddId(newExaminationToAdd)
        ));

        if (plannedExamination) {
            newExaminationToAdd.errorMessage = plannedExamination.errorMessage;
            newExaminationToAdd.oldTimeSlotId = plannedExamination.timeSlotId;
            newExaminationToAdd.timeCell = plannedExamination.timeCell;
            newExaminationToAdd.startDate = plannedExamination.startDate;
            newExaminationToAdd.startTime = plannedExamination.startTime;
            newExaminationToAdd.endTime = plannedExamination.endTime;
            newExaminationToAdd.locationId = plannedExamination.locationId;
            newExaminationToAdd.duration = plannedExamination.duration;
            newExaminationToAdd.employee = {
                ...examination.employee,
                email: plannedExamination.employee.email,
            };
            newExaminationToAdd.planningId = Number(plannedExamination.timeSlotId);
        }

        return newExaminationToAdd;
    });
}

function addResponseToCurrentFreeSlots(
    currentMedicalCentersFreeSlots: IMedicalCenterFreeSlots[] = [],
    medicalCentersWithFreeSlots: IMedicalCenterFreeSlots[],
) {
    const newMedicalCentersWithFreeSlots = [...currentMedicalCentersFreeSlots];
    medicalCentersWithFreeSlots
        .forEach((updatedMedCenter) => {
            const index = newMedicalCentersWithFreeSlots
                .findIndex((currentMedCenter) => currentMedCenter.id === updatedMedCenter.id);

            if (index >= 0) {
                newMedicalCentersWithFreeSlots[index] = updatedMedCenter;
            } else {
                newMedicalCentersWithFreeSlots.push(updatedMedCenter);
            }
        });

    return newMedicalCentersWithFreeSlots;
}

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that plan-medical-examination steps / data are not lost when refreshing
        planMedicalExaminationWizardData: reducerState.planMedicalExaminationWizardData,
        lastUpdatedTimeslotPlanningId: reducerState.lastUpdatedTimeslotPlanningId,
    }),
});

function transformCompanyMedicalCentersFreeSlotsRequestsState({
    oldState,
    payload,
}: {
    oldState: IReducerState,
    payload: IMedicalCenterFreeSlotsPayload | (ITraceableApiError & ITraceableAsyncRequestPayload),
}): IReducerState {
    const requestId = payload.requestId;
    let requestStatus: IAsyncDoField;

    if (isTraceableApiError(payload)) {
        requestStatus = {
            isDoing: false,
            isDone: true,
            error: payload,
        } as IAsyncDoField;
    } else {
        requestStatus = {
            isDoing: true,
            isDone: false,
            error: null,
        } as IAsyncDoField;
    }

    return {
        ...oldState,
        companyMedicalCentersFreeSlotsRequests: {
            ...oldState.companyMedicalCentersFreeSlotsRequests,
            [requestId]: requestStatus,
        },
    };
}

function updateMedicalExaminationToPlanWizardData(
    { oldState, payload }: {
        oldState: IReducerState,
        payload: IAddTimeslotSuccessPayload | IUpdateTimeslotSuccessPayload,
    },
): IReducerState {
    const { planningId } = payload;

    if (oldState.planMedicalExaminationWizardData) {
        const oldEntity =
            oldState.planMedicalExaminationWizardData.entity;

        return {
            ...oldState,
            lastUpdatedTimeslotPlanningId: planningId,
            planMedicalExaminationWizardData: {
                ...oldState.planMedicalExaminationWizardData,
                entity: {
                    ...oldEntity,
                    selectTime: {
                        ...oldEntity.selectTime,
                        planningId,
                    },
                },
            },
        };
    }
    return {
        ...oldState,
        lastUpdatedTimeslotPlanningId: planningId,
    };
}
