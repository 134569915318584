import React from 'react';
import CollapsibleDetailTrigger from './CollapsibleDetailTrigger';
import CollapsibleItem from '../../../../../../../common/widget/CollapsibleItem';
import { IPlannedMedicalExamination } from '../../../../../../../../models/interventions/medicalExaminations';

const TRANSLATION_PREFIX =
    'interventions.medical_examinations.new.steps.select_timeslot.planned_examination_dialog.remarks';

const RemarksTrigger = <CollapsibleDetailTrigger titleTranslationKey={getPrefixedTranslation('title')} />;

interface IPublicPorps {
    baseClassName: string;
    plannedExamination: IPlannedMedicalExamination;
}

export default function RemarksDetails(props: IPublicPorps) {
    return (
        <CollapsibleItem trigger={RemarksTrigger}>
            <pre>
                {(props.plannedExamination && props.plannedExamination.doctorRemark) || '-'}
            </pre>
        </CollapsibleItem>
    );
}

function getPrefixedTranslation(suffix: string) {
    return `${TRANSLATION_PREFIX}.${suffix}`;
}
