import React, { PureComponent } from 'react';
import clone from 'ramda/src/clone';
import './company-info-seats.scss';
import ROUTE_KEYS from '../../../../routeKeys';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, IShouldRenderShowAllButtonProps,
    IRenderDetailContentProps, IRenderDetailHeaderProps,
    IRenderSearchContentProps,
    ITransformToActiveFiltersProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import ListWithSorting from '../../../common/list/ListWithSorting';
import Translate from '../../../common/Translate';
import { ListColumns, ListItem, SortType } from '../../../../models/general/list';
import { ICompanySeat } from '../../../../models/admin/company';
import { formatAddressStreet, formatAddressCity } from '../../../../utils/formatting/formatAddress';
import DetailContent from './detailContent';
import DetailHeader from './detailHeader';
import DetailOverlay, { SeatDetailsOverlayType, TAddressOverlayType } from './detailOverlay';
import {
    DetailHeader as ContactDetailHeader,
    DetailContent as ContactDetailContent,
} from '../Contacts/detail';
import {
    getSelectedCompanyInfoSeat, getSelectedCompanyInfoSeatCompanyCode,
    getSelectedContactId, getCompanySeatContactsAsyncInfo,
    getSelectedCompanySeatContact,
    getUpdateContactAsyncInfo,
    getUpdateCompanyAsyncInfo,
    getFetchCompanySeatsWithEmployeeCountAsyncInfo,
    getFilteredCompanySeatsWithEmployeeCount,
    getSelectedCompanyInfoSeatDetailsAsyncInfo,
} from '../../../../redux/company/info/selectors';
import { IAddressSave } from '../../../../models/general/address';
import { ICompanyContact } from '../../../../models/admin/companyInfo';
import { updateContactActions, updateCompanyActions } from '../../../../redux/company/info/actions';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { IState } from '../../../../redux';
import { AsyncStatus } from '../../../../models/general/redux';

const BASE_NAME = 'company-info-seats';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IColumnNames {
    name: string;
    companyCode: string;
    street: string;
    city: string;
    employeeCount: number;
}

interface IFilterValues {
    search: string;
    isShowAll: boolean;
}

const COLUMNS: ListColumns<IColumnNames> = {
    name: {
        label: <Translate msg="administration.company_info.seats.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 32,
    },
    companyCode: {
        label: <Translate msg="administration.company_info.seats.columns.company_code" />,
        sortable: true,
        sortType: SortType.DotSeparatedNumber,
        percentWidth: 13,
    },
    street: {
        label: <Translate msg="administration.company_info.seats.columns.street" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 24,
    },
    city: {
        label: <Translate msg="administration.company_info.seats.columns.city" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 19,
    },
    employeeCount: {
        label: <Translate msg="administration.company_info.seats.columns.count" />,
        sortable: true,
        sortType: SortType.Number,
        align: 'right',
        percentWidth: 12,
    },
};

interface IComponentState {
    selectedDetailOverlayAddress: IAddressSave;
    selectedDetailOverlayBillingAddress: IAddressSave;
}

export default class Seats extends PureComponent<{}, IComponentState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedDetailOverlayAddress: null,
            selectedDetailOverlayBillingAddress: null,
        };

        this.onChangeDetailOverlayAddressChange = this.onChangeDetailOverlayAddressChange.bind(this);
        this.onSwitchSelectedDetail = this.onSwitchSelectedDetail.bind(this);
    }

    public render() {
        const { selectedDetailOverlayAddress, selectedDetailOverlayBillingAddress } = this.state;

        return (
            <MasterWithDetail
                baseName={BASE_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_COMPANY_INFO_SEATS,
                    asyncInfoSelector: getFetchCompanySeatsWithEmployeeCountAsyncInfo,
                    dataSelector: getFilteredCompanySeatsWithEmployeeCount,
                    transformData: mapCompanySeatsToListItems,
                    transformFilterValuesToActiveFilters,
                    renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <SeatsList {...renderProps} />,
                    clientSideSearchOfListData: {
                        searchFilterName: 'search',
                        columnsConfig: COLUMNS,
                    },
                }}
                detailConfig={{
                    levels: [
                        {
                            level: 1,
                            details: [{
                                routeKey: ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL,
                                asyncInfoSelector: companySeatDetailAsyncInfoSelector,
                                idSelector: getSelectedCompanyInfoSeatCompanyCode,
                                idRouteParamName: 'companyCode',
                                dataSelector: getSelectedCompanyInfoSeat,
                                updateInfo: {
                                    updateAsyncInfoResetAction: updateCompanyActions.reset({}),
                                    updateAsyncInfoSelector: getUpdateCompanyAsyncInfo,
                                    // eslint-disable-next-line max-len
                                    successTranslationKey: 'administration.company_info.seats.detail.action.save_success',
                                },
                                renderHeader: (renderProps: IRenderDetailHeaderProps<ICompanySeat>) =>
                                    <DetailHeader {...renderProps} />,
                                renderContent: (renderProps: IRenderDetailContentProps<ICompanySeat>) =>
                                    <DetailContent
                                        {...renderProps}
                                        selectedDetailOverlayAddress={selectedDetailOverlayAddress}
                                        selectedDetailOverlayBillingAddress={selectedDetailOverlayBillingAddress}
                                    />,
                                renderOverlay: ({
                                    overlayType, closeOverlay,
                                }) =>
                                    <DetailOverlay
                                        overlayType={overlayType as SeatDetailsOverlayType}
                                        closeOverlay={closeOverlay}
                                        onAddressChange={this.onChangeDetailOverlayAddressChange}
                                        selectedSeatSelector={getSelectedCompanyInfoSeat}
                                    />,
                                onSwitchSelectedDetail: this.onSwitchSelectedDetail,
                            }],
                        },
                        {
                            level: 2,
                            details: [{
                                routeKey: ROUTE_KEYS.R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL,
                                asyncInfoSelector: getCompanySeatContactsAsyncInfo,
                                idSelector: getSelectedContactId,
                                dataSelector: getSelectedCompanySeatContact,
                                updateInfo: {
                                    updateAsyncInfoResetAction: updateContactActions.reset({}),
                                    updateAsyncInfoSelector: getUpdateContactAsyncInfo,
                                },
                                isForm: true,
                                renderHeader: (renderProps: IRenderDetailHeaderProps<ICompanyContact>) =>
                                    <ContactDetailHeader {...renderProps} />,
                                renderContent: (renderProps: IRenderDetailContentProps<ICompanyContact>) =>
                                    <ContactDetailContent
                                        {...renderProps}
                                    />,
                            }],
                        },
                    ],
                }}
                headerConfig={{
                    renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                        <SearchContent {...renderProps} />,
                    exportButton: {
                        baseFilename: 'company-seats',
                        listItemIdExtractor: toListId,
                    },
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        );
    }

    private onChangeDetailOverlayAddressChange(overlayType: TAddressOverlayType, newAddress: IAddressSave) {
        if (overlayType === SeatDetailsOverlayType.EditAddress) {
            this.setState({
                selectedDetailOverlayAddress: newAddress,
            });
        }
        if (overlayType === SeatDetailsOverlayType.EditBillingAddress) {
            this.setState({
                selectedDetailOverlayBillingAddress: newAddress,
            });
        }
    }

    private onSwitchSelectedDetail() {
        this.setState({
            selectedDetailOverlayAddress: null,
            selectedDetailOverlayBillingAddress: null,
        });
    }
}

/**
 * We don't get a unique id from the backend, so we generate one ourselves.
 */
function toListId(seat: ICompanySeat) {
    return seat.company.companyCode;
}

function mapCompanySeatsToListItems(masterData: ICompanySeat[]): ListItem<IColumnNames>[] {
    return masterData
        .map((companySeat) => {
            return {
                id: toListId(companySeat),
                columns: {
                    name: companySeat.company.name,
                    companyCode: companySeat.company.companyCode,
                    street: formatAddressStreet(companySeat.address),
                    city: formatAddressCity(companySeat.address),
                    employeeCount: companySeat.employeeCount,
                },
            };
        },
        );
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('administration.company_info.seats.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="administration.company_info.seats.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

class SeatsList extends PureComponent<IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            onItemSelected,
            selectedItemId,
            filterValues,
            footer,
        } = this.props;

        return (
            <ListWithSorting
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemIds={selectedItemId ? [selectedItemId.toString()] : []}
                onItemRowClicked={onItemSelected}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                footer={footer}
            />
        );
    }
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        isShowAll,
    } : {};
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.medical_examinations.no_shows.filter',
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

function companySeatDetailAsyncInfoSelector(state: IState) {
    const seatDetailsAsyncInfo = getSelectedCompanyInfoSeatDetailsAsyncInfo(state);
    if (seatDetailsAsyncInfo.status !== AsyncStatus.Success) {
        return seatDetailsAsyncInfo;
    }
    return getFetchCompanySeatsWithEmployeeCountAsyncInfo(state);
}
