import { createEpic, IState } from '../index';
import { fetchAppointmentsActions, fetchNextAppointmentActions } from './actions';
import ROUTE_KEYS from '../../routeKeys';
import {
    IFetchAppointmentsPayload, IFetchappointmentsResult,
    IFetchNextAppointmentPayload,
    IQueryAppointmentsPayload,
} from '../../models/planning/appointments';
import { getSelectedCompanySeat } from '../company/selected/selectors';
import { formatDateForBackend } from '../../utils/formatting/formatDate';
import { getDate } from '../../utils/core/date/getSpecificDate';
import { getQueryParams, getRouteKey } from '../location/selectors';
import { IAgendaQueryParams } from '../../models/planning/agenda';
import { areAppointmentsAvailableForDateRange } from './selectors';
import getDaysInMonthView from '../../utils/libs/flatpickr/getDaysInMonthView';
import { getLocale } from '../i18n/selectors';
import { FETCH_APPOINTMENTS } from './types';
import {
    getDefaultSearchDates,
} from '../../config/calendar.config';

// fetchAppointmentsEpic
createEpic<{}, IAgendaQueryParams>({
    onActionType: [
        FETCH_APPOINTMENTS,
        ROUTE_KEYS.R_AGENDA,
        ROUTE_KEYS.R_AGENDA_EVENT_DETAILS,
        ROUTE_KEYS.R_AGENDA_SEARCH,
        ROUTE_KEYS.R_AGENDA_SEARCH_EVENT_DETAILS,
    ],
    refreshDataIf: ({ getState, action }) => {
        if (action.type === FETCH_APPOINTMENTS) {
            return true;
        }

        const state = getState();
        const queryParams: Partial<IAgendaQueryParams> = (action.meta && action.meta.query) || {};
        const routeKey = action.type as ROUTE_KEYS;
        const dateRange = getFetchAppointmentsDateRange(state, routeKey, queryParams);

        // Don't fetch when showing the search without a search being triggered yet
        if (isSearchRoute(routeKey) && !queryParams.startDate && !queryParams.endDate) {
            return false;
        }

        if (!areAppointmentsAvailableForDateRange(state, dateRange)) {
            return true;
        }

        return false;
    },
    async processReturn({ api, getState, action }) {
        try {
            const isRouteAction = action.type !== FETCH_APPOINTMENTS;
            const state = getState();
            const routeKey = isRouteAction ? action.type as ROUTE_KEYS : getRouteKey(state);
            const queryParams: Partial<IAgendaQueryParams> = isRouteAction
                ? (action.meta && action.meta.query) || {}
                : getQueryParams<IAgendaQueryParams>(state);
            const { companySeat, isAllSeatsSelected } = getSelectedCompanySeat(state);
            const isOnSearchRoute = isSearchRoute(routeKey);

            const fetchPayload: IFetchAppointmentsPayload = {
                companyCode: companySeat.company.companyCode,
                showFullFamily: isAllSeatsSelected,
                ...getFetchAppointmentsDateRange(state, routeKey, queryParams),
            };

            if (isOnSearchRoute) {
                const searchPayload: IQueryAppointmentsPayload = {
                    ...fetchPayload,
                    searchValue: queryParams.search,
                };

                if (queryParams.search) {
                    const appointments = await api.planning.appointments.queryAppointments(searchPayload);
                    return getFetchAppointmentsSuccess(appointments, searchPayload);
                }

                const appointments = await api.planning.appointments.fetchAppointments(searchPayload);
                return getFetchAppointmentsSuccess(appointments, searchPayload);
            }

            const appointments = await api.planning.appointments.fetchAppointments(fetchPayload);
            return getFetchAppointmentsSuccess(appointments, fetchPayload);
        } catch (error) {
            return fetchAppointmentsActions.failed(error);
        }
    },
    latest: false,
});

function getFetchAppointmentsSuccess(
    appointments: IFetchappointmentsResult,
    fetchPayload: IFetchAppointmentsPayload,
) {
    return fetchAppointmentsActions.succeeded({
        ...appointments,
        dateFrom: fetchPayload.dateFrom,
        dateUntil: fetchPayload.dateUntil,
    });
}

// fetchNextAppointmentEpic
createEpic({
    onActionType: [
        ROUTE_KEYS.R_HOME,
    ],
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const { companySeat, isAllSeatsSelected } = getSelectedCompanySeat(state);
            const payload: IFetchNextAppointmentPayload = {
                companyCode: companySeat.company.companyCode,
                showFullFamily: isAllSeatsSelected,
            };
            const appointment = await api.planning.appointments.fetchNextAppointment(payload);
            return fetchNextAppointmentActions.succeeded(appointment);
        } catch (error) {
            return fetchNextAppointmentActions.failed(error);
        }
    },
    latest: false,
});

function getFetchAppointmentsDateRange(state: IState, routeKey: ROUTE_KEYS, queryParams: Partial<IAgendaQueryParams>) {
    const locale = getLocale(state);
    const isOnSearchRoute = isSearchRoute(routeKey);

    const now = new Date();
    const selectedDate = queryParams.selectedDate
        ? getDate(queryParams.selectedDate) : now;

    if (isOnSearchRoute) {
        const { defaultSearchFrom, defaultSearchUntil } = getDefaultSearchDates(selectedDate);
        return {
            dateFrom: queryParams.startDate || defaultSearchFrom,
            dateUntil: queryParams.endDate || defaultSearchUntil,
        };
    }

    const daysInMonthView = getDaysInMonthView({
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
        locale,
    });
    const dateFrom = daysInMonthView[0];
    const dateUntil = daysInMonthView[daysInMonthView.length - 1];
    return {
        dateFrom: formatDateForBackend(dateFrom),
        dateUntil: formatDateForBackend(dateUntil),
    };
}

function isSearchRoute(routeKey: ROUTE_KEYS) {
    return (
        routeKey === ROUTE_KEYS.R_AGENDA_SEARCH ||
        routeKey === ROUTE_KEYS.R_AGENDA_SEARCH_EVENT_DETAILS
    );
}
