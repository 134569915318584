import React, { Component } from 'react';
import './plan-bufferzone.scss';
import classNames from 'classnames';
import { connect } from '../../../..';
import { ICompanyBufferzone } from '../../../../../models/admin/company';
import {
    getPlanBufferzoneWizardEntity,
    getPlanBufferzoneWizardEntityEmployeesToPlan,
    getPlanBufferzoneExaminationReasonsInEmployeesToPlanMemoized,
    getBufferzonePlanningStepAsyncInfo,
    getBufferzoneFreeTimeslots,
    getBufferzonePlannedTimeslots,
} from '../../../../../redux/intervention/bufferzones/selectors';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import PageHeader from '../../../../appShell/PageHeader';
import Loader from '../../../../common/waiting/Loader';
import StickyFooter from '../../../../common/widget/StickyFooter';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import {
    IMedicalExaminationToAdd,
    IExaminationReason,
    IPlannedMedicalExamination,
} from '../../../../../models/interventions/medicalExaminations';
import {
    getExaminationReasonsAsyncInfo,
} from '../../../../../redux/medicalExamination/selectors';
import { getMedExamToAddId } from '../../../../../utils/interventions/medicalExaminations/getMedExamToAddId';
import BufferzoneInfo from './BufferzoneInfo';
import EmployeeBlock from './EmployeeBlock';
import Translate from '../../../../common/Translate';
import Legend, { TLegendItem } from '../../../../common/widget/Legend';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import Calendar from '../../../../common/widget/Calendar';
import { ICalendarEvent, CalendarEventType, CalendarEventDataType } from '../../../../../models/ui/calendar';
import {
    ITimeCell,
    DroppablePrefix,
    DraggablePrefix,
    IDroppableFreeTimeslotData,
} from '../../../../../models/interventions/timeslots';
import {
    getDate,
    minutesOffsetFromDate,
    hoursOffsetFromNow,
    getDateWithoutSeconds,
} from '../../../../../utils/core/date/getSpecificDate';
import { DragDropContext, ResponderProvided, DropResult, DragStart } from 'react-beautiful-dnd';
import DroppableWrapper, { IDroppableRenderProps } from '../../../../common/widget/dragAndDrop/DroppableWrapper';
import {
    getPrefixFromPrefixedDraggableId,
    getPrefixFromPrefixedDroppableId,
    getIdFromPrefixedDraggableId,
    getIdFromPrefixedDroppableId,
} from '../../../../../utils/libs/react-beautiful-dnd/reactBeautifulDndUtils';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { DEFAULT_MEDICAL_EXAMINATION_DURATION } from '../../../../../config/planning.config';
import { createSelector } from 'reselect';
import { IActiveDraggable } from '../../../../../models/ui/dragAndDrop';
import {
    setActiveDraggable as setActiveDraggableAction,
    clearActiveDraggable as clearActiveDraggableAction,
} from '../../../../../redux/ui/dragAndDrop/actions';
import {
    getMinutesAvailableAfterTimeslot,
} from '../../../../../utils/interventions/medicalExaminations/getMinutesAvailableAfterTimeslot';
import {
    bufferzonePlanningCreateNewPlanningActions,
    bufferzonePlanningMoveExistingPlanningActions,
    bufferzonePlanningCancelExistingPlanningActions,
} from '../../../../../redux/intervention/bufferzones/actions';
import { IAsyncFieldInfo } from '../../../../../models/general/redux';
import FormError from '../../../../common/forms/FormError';
import { isDraggingDraggableWithPrefix } from '../../../../../redux/ui/dragAndDrop/selectors';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../config/medicalExamination.config';
import Icon from '../../../../common/icons/Icon';
import { getCaseManager } from '../../../../../redux/contact/selectors';
import { ICaseManager } from '../../../../../models/contact/caseManager';
import getOverlappingEventsCount from '../../../../../utils/calendar/getOverlappingEventsCount';
import { stringComparerAscending } from '../../../../../utils/list/comparerUtils';
import { isOnSameDay } from '../../../../../utils/core/date/isOnSameDay';
import { getLatestPossibleTimeOnDate } from '../../../../../utils/core/date/getLatestPossibleTimeOnDate';

const CLASS_NAME = 'PlanBufferzone';
const TRANSLATION_PREFIX = 'interventions.plan_bufferzone.steps.planning';

interface IPrivateProps {
    selectedBufferzone: ICompanyBufferzone;
    employeesToPlan: IMedicalExaminationToAdd[];
    examinationReasons: IExaminationReason[];
    events: ICalendarEvent[];
    planEmployee: (employeeToPlan: IMedicalExaminationToAdd, timeslot: ITimeCell) => void;
    cancelPlannedExamination: (examination: IPlannedMedicalExamination) => void;
    movePlannedExamination: (examination: IPlannedMedicalExamination, newTimeslot: ITimeCell) => void;
    setActiveDraggable: (activeDraggable: IActiveDraggable) => void;
    clearActiveDraggable: () => void;
    asyncInfo: IAsyncFieldInfo;
    freeSlotsAmount: number;
    plannedSlotsAmount: number;
    isDraggingPlannedExam: boolean;
    bufferzoneStartsBeforePlanningDeadline: boolean;
    caseManager: ICaseManager;
}

class PlanBufferzone extends Component<IPrivateProps & IStepperStepRenderProps> {
    constructor(props) {
        super(props);

        this.renderEmployeesColumn = this.renderEmployeesColumn.bind(this);
        this.onDragEndHandler = this.onDragEndHandler.bind(this);
        this.onDragStartHandler = this.onDragStartHandler.bind(this);
    }

    public render() {
        const {
            renderStepButtons,
            selectedBufferzone,
            examinationReasons,
            events,
            asyncInfo,
            freeSlotsAmount,
            plannedSlotsAmount,
            isDraggingPlannedExam,
            bufferzoneStartsBeforePlanningDeadline,
            caseManager,
        } = this.props;

        const renderCaseManagerLink = () => (
            caseManager ? (
                <a href={`mailto:${caseManager && caseManager.email}`}>
                    <Translate msg={`${TRANSLATION_PREFIX}.warning.case_manager_link`} />
                </a>
            ) : <Translate msg={`${TRANSLATION_PREFIX}.warning.case_manager_link`} />
        );

        const calendarEvents = events.map((event) => ({
            ...event,
            overlappingEventsCount: getOverlappingEventsCount(event, events),
        }));

        const bufferzoneWithShortTimeslots = Number(selectedBufferzone.duration) === 5;

        return (
            <>
                <DragDropContext onDragStart={this.onDragStartHandler} onDragEnd={this.onDragEndHandler}>
                    <PageHeader
                        title={`${TRANSLATION_PREFIX}.title`}
                        text={`${TRANSLATION_PREFIX}.text`}
                    />
                    <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT, CLASS_NAME)}>
                        <Loader show={asyncInfo.status} />
                        {asyncInfo.error && <FormError className={`${CLASS_NAME}__error`} error={asyncInfo.error} />}
                        {bufferzoneStartsBeforePlanningDeadline && (
                            <div className={`${CLASS_NAME}__warning`}>
                                <Icon typeName="warning" circle colorType="warning" />
                                <div>
                                    <p>
                                        <Translate
                                            msg={`${TRANSLATION_PREFIX}.warning.text`}
                                            placeholders={{
                                                hours: NR_OF_HOURS_BEFORE_EXAM_ALLOWED,
                                            }}
                                        />
                                    </p>
                                    <p>
                                        <Translate
                                            msg={`${TRANSLATION_PREFIX}.warning.contact_text`}
                                            placeholders={{
                                                caseManager: renderCaseManagerLink(),
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className={`${CLASS_NAME}__wrapper`}>
                            <div className="planning-wrapper">
                                <div className="info-calendar-wrapper">
                                    <h5>
                                        <Translate msg={`${TRANSLATION_PREFIX}.columns.info`} />
                                    </h5>
                                    <div className="info-calendar">
                                        <div className="info-wrapper">
                                            <div className="scroller">
                                                <BufferzoneInfo
                                                    bufferzone={selectedBufferzone}
                                                    freeSlots={freeSlotsAmount}
                                                    totalSlots={freeSlotsAmount + plannedSlotsAmount}
                                                />
                                            </div>
                                        </div>
                                        <div className="calendar-wrapper">
                                            <div className="scroll-wrapper">
                                                <div className="scroller">
                                                    <Calendar
                                                        events={calendarEvents}
                                                        selectable={false}
                                                        selectedEvent={null}
                                                        selectedDate={selectedBufferzone.date}
                                                        onNavigate={() => null}
                                                        onSelectEvent={() => null}
                                                        hideTodayButton={true}
                                                        hideViewToggle={true}
                                                        hideCurrentTimeIndicator={true}
                                                        hideToolbar={true}
                                                        view="day"
                                                        inlineDatePicker={false}
                                                        customDayStyles={[]}
                                                        rowHeight={30} // 30px for each 15min
                                                        minTime={selectedBufferzone.startTime}
                                                        maxTime={
                                                            // eslint-disable-next-line max-len
                                                            isOnSameDay(selectedBufferzone.startTime, selectedBufferzone.endTime) ?
                                                                selectedBufferzone.endTime :
                                                                getLatestPossibleTimeOnDate(
                                                                    selectedBufferzone.startTime,
                                                                )
                                                        }
                                                        step={bufferzoneWithShortTimeslots ? 5 : undefined}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="employees-wrapper">
                                    <h5>
                                        <Translate msg={`${TRANSLATION_PREFIX}.columns.employees_to_plan`} />
                                    </h5>
                                    <div className="scroll-wrapper">
                                        <div className="scroller">
                                            <DroppableWrapper
                                                className={classNames(`${CLASS_NAME}__employees-droppable`, {
                                                    ['show-droppable']: isDraggingPlannedExam,
                                                })}
                                                droppableId={DroppablePrefix.Employees}
                                                render={this.renderEmployeesColumn}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="legend-wrapper">
                                <h5><Translate msg={`${TRANSLATION_PREFIX}.columns.legend.title`} /></h5>
                                <TinyLoader asyncInfoSelector={getExaminationReasonsAsyncInfo}>
                                    <Legend
                                        className={`${CLASS_NAME}__legend`}
                                        data={mapExaminationReasonsToLegendData(
                                            examinationReasons,
                                            bufferzoneStartsBeforePlanningDeadline,
                                        )}
                                        small
                                    />
                                </TinyLoader>
                            </div>
                        </div>
                        <StickyFooter className={WIZARDFLOW_CLASSES.ACTIONS}>
                            {renderStepButtons({
                                nextButton: {
                                    translationKey: null,
                                },
                            })}
                        </StickyFooter>
                    </div>
                </DragDropContext>
            </>
        );
    }

    private onDragStartHandler(initial: DragStart, provided: ResponderProvided) {
        const { setActiveDraggable, events, employeesToPlan } = this.props;

        const employeeToPlan = employeesToPlan.find((employee) =>
            getMedExamToAddId(employee) === getIdFromPrefixedDraggableId(initial.draggableId));

        const event = events.find((event) => event.id === getIdFromPrefixedDraggableId(initial.draggableId));

        setActiveDraggable({
            draggableId: initial.draggableId,
            data: employeeToPlan || (event && event.data),
        });
    }

    private onDragEndHandler(result: DropResult, provided: ResponderProvided) {
        const {
            movePlannedExamination,
            cancelPlannedExamination,
            planEmployee,
            events,
            employeesToPlan,
            clearActiveDraggable,
        } = this.props;

        clearActiveDraggable();

        if (!result.destination) {
            return;
        }

        const draggablePrefix = getPrefixFromPrefixedDraggableId(result.draggableId);
        const destinationDroppablePrefix = getPrefixFromPrefixedDroppableId(result.destination.droppableId);

        if (isEmployeeDroppedIntoFreeTimeslot(draggablePrefix, destinationDroppablePrefix)) {
            const employeeToPlan = // eslint-disable-next-line max-len
                employeesToPlan.find((employee) => getMedExamToAddId(employee) === getIdFromPrefixedDraggableId(result.draggableId));
            const event = // eslint-disable-next-line max-len
                events.find((event) => event.id === getIdFromPrefixedDroppableId(result.destination.droppableId)) as ICalendarEvent<IDroppableFreeTimeslotData>;

            if (event && employeeToPlan) {
                planEmployee(employeeToPlan, event.data.timeCell);
            } else if (process.env.NODE_ENV === 'development') { // eslint-disable-next-line max-len
                console.warn('You are trying to plan an employee, but the employee or timeslot event was not found, make sure all draggable and droppable ids are correct.');
            }
        } else if (isPlannedExaminationDroppedIntoFreeTimeslot(draggablePrefix, destinationDroppablePrefix)) {
            const plannedExaminationEvent = // eslint-disable-next-line max-len
                events.find((event) => event.id === getIdFromPrefixedDraggableId(result.draggableId)) as ICalendarEvent<IPlannedMedicalExamination>;
            const freeTimeslotEvent = // eslint-disable-next-line max-len
                events.find((event) => event.id === getIdFromPrefixedDroppableId(result.destination.droppableId)) as ICalendarEvent<IDroppableFreeTimeslotData>;

            if (plannedExaminationEvent && freeTimeslotEvent) {
                movePlannedExamination(plannedExaminationEvent.data, freeTimeslotEvent.data.timeCell);
            } else if (process.env.NODE_ENV === 'development') { // eslint-disable-next-line max-len
                console.warn('You are trying to move a planning, but the planning or destination timeslot event was not found, make sure all draggable and droppable ids are correct.');
            }
        } else if (isPlannedExaminationDroppedIntoEmployees(draggablePrefix, destinationDroppablePrefix)) {
            const plannedExaminationEvent = // eslint-disable-next-line max-len
                events.find((event) => event.id === getIdFromPrefixedDraggableId(result.draggableId)) as ICalendarEvent<IPlannedMedicalExamination>;

            if (plannedExaminationEvent) {
                cancelPlannedExamination(plannedExaminationEvent.data);
            } else if (process.env.NODE_ENV === 'development') { // eslint-disable-next-line max-len
                console.warn('You are trying to cancel a planning, but the planning event was not found, make sure all draggable and droppable ids are correct.');
            }
        }
    }

    private renderEmployeesColumn(renderProps: IDroppableRenderProps) {
        const { employeesToPlan } = this.props;

        return (
            employeesToPlan.map((employeeToPlan, index) => (
                <EmployeeBlock
                    key={getMedExamToAddId(employeeToPlan)}
                    employee={employeeToPlan}
                    index={index}
                />
            ))
        );
    }
}

const getMergedCalendarEventsMemoizedSelector = createSelector(
    getBufferzonePlannedTimeslots,
    getBufferzoneFreeTimeslots,
    (examinations, timeslots) => [
        ...mapPlannedMedicalExaminationsToCalendarEvents(examinations),
        ...mapBufferzoneTimeslotsToCalendarEvents(timeslots),
    ],
);

const getEmployeesToPlanSortedMemoizedSelector = createSelector(
    getPlanBufferzoneWizardEntityEmployeesToPlan,
    (employeesToPlan) => employeesToPlan.sort(
        (a, b) => {
            const comparedByEximationReason = stringComparerAscending(
                a.examinationReason.title,
                b.examinationReason.title,
            );
            if (comparedByEximationReason !== 0) {
                return comparedByEximationReason;
            }

            return stringComparerAscending(
                formatPersonName(a.employee).toLowerCase(),
                formatPersonName(b.employee).toLowerCase(),
            );
        },
    ),
);

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const entity = getPlanBufferzoneWizardEntity(state);

        return {
            selectedBufferzone: entity.selectedBufferzone,
            employeesToPlan: getEmployeesToPlanSortedMemoizedSelector(state),
            examinationReasons: getPlanBufferzoneExaminationReasonsInEmployeesToPlanMemoized(state),
            events: getMergedCalendarEventsMemoizedSelector(state),
            asyncInfo: getBufferzonePlanningStepAsyncInfo(state),
            freeSlotsAmount: getBufferzoneFreeTimeslots(state).length || 0,
            plannedSlotsAmount: getBufferzonePlannedTimeslots(state).length || 0,
            isDraggingPlannedExam: isDraggingDraggableWithPrefix(state, DraggablePrefix.PlannedExamination),
            bufferzoneStartsBeforePlanningDeadline:
                hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED).toDate() >
                getDate(entity.selectedBufferzone.startTime),
            caseManager: getCaseManager(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        const isExaminationCancellable = (code) => {
            const blockedExaminations = [
                'REINT',
                'REINTOPV',
                'REINTTEL',
                'ART34',
                'ART34OPV',
                'ART34TEL',
                'ART34VWD',
                'ART34ADM',
                'REINTADM',
                'REINTCON',
            ];
            return blockedExaminations.indexOf(code) === -1;
        };
        return {
            planEmployee: (employeeToPlan: IMedicalExaminationToAdd, timeslot: ITimeCell) => {
                dispatch(bufferzonePlanningCreateNewPlanningActions.trigger({
                    employeeToPlan,
                    timeslot,
                }));
            },
            movePlannedExamination: (examination: IPlannedMedicalExamination, newTimeslot: ITimeCell) => {
                isExaminationCancellable(examination.examinationReason.code) &&
                    dispatch(bufferzonePlanningMoveExistingPlanningActions.trigger({
                        examination,
                        newTimeslot,
                    }));
            },
            cancelPlannedExamination: (examination: IPlannedMedicalExamination) => {
                isExaminationCancellable(examination.examinationReason.code) &&
                    dispatch(bufferzonePlanningCancelExistingPlanningActions.trigger({
                        examination,
                    }));
            },
            setActiveDraggable: (activeDraggable: IActiveDraggable) => {
                dispatch(setActiveDraggableAction(activeDraggable));
            },
            clearActiveDraggable: () => {
                dispatch(clearActiveDraggableAction());
            },
        };
    },
})(PlanBufferzone);

function mapExaminationReasonsToLegendData(reasons: IExaminationReason[], showFixedPlannedSlots: boolean) {
    const freeSlot: TLegendItem = {
        color: 'green',
        colorClassName: 'free-time-slot',
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.legend.free_time_slot`} />,
    };
    const plannedSlot: TLegendItem = {
        color: '',
        colorClassName: 'planned-time-slot',
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.legend.planned_time_slot`} />,
    };
    const fixedPlannedSlot: TLegendItem = {
        color: '',
        colorClassName: 'fixed-planned-time-slot',
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.legend.fixed_planned_time_slot`} />,
    };

    const legendData = [freeSlot, plannedSlot];

    if (showFixedPlannedSlots) {
        legendData.push(fixedPlannedSlot);
    }

    reasons
        .sort((reasonA, reasonB) => stringComparerAscending(reasonA.title, reasonB.title))
        .map((reason) => {
            const item: TLegendItem = {
                color: reason.colour,
                label: reason.title,
            };
            legendData.push(item);
        });

    return legendData;
}

function mapBufferzoneTimeslotsToCalendarEvents(timeCells: ITimeCell[]) {
    return timeCells.map(
        (timeCell) => {
            const start = getDate(timeCell.time);
            let end = minutesOffsetFromDate(
                start,
                timeCell.duration,
            ).toDate();

            if (!isOnSameDay(start, end)) {
                end = getDate(getLatestPossibleTimeOnDate(timeCell.time));
            }

            const event: ICalendarEvent<IDroppableFreeTimeslotData> = {
                id: `${CalendarEventType.DroppableFreeTimeslot}_${timeCell.id}`,
                start,
                end,
                allDay: false,
                type: CalendarEventType.DroppableFreeTimeslot,
                data: {
                    timeCell,
                    minutesAvailableAfterTimeslot: getMinutesAvailableAfterTimeslot(timeCell, timeCells),
                    amountOfFreeTimeslotsWithSameTime: 1,
                },
                dataType: CalendarEventDataType.TimeCell,
                isConcatenated: false,
                reserved: false,
            };

            return event;
        },
    ).reduce(
        (accumulator, event) => {
            const { start, end } = event;
            const eventWithSameStartAndEndTimeIndex = accumulator.findIndex((item) => {
                return (
                    item.allDay === event.allDay &&
                    item.type === event.type &&
                    getDateWithoutSeconds(item.start).getTime() === getDateWithoutSeconds(start).getTime() &&
                    getDateWithoutSeconds(item.end).getTime() === getDateWithoutSeconds(end).getTime()
                );
            });
            event.isConcatenated = true;
            if (eventWithSameStartAndEndTimeIndex !== -1) {
                const overlappingEvent =
                    accumulator[eventWithSameStartAndEndTimeIndex] as ICalendarEvent<IDroppableFreeTimeslotData>;
                overlappingEvent.data.amountOfFreeTimeslotsWithSameTime += 1;
            } else {
                accumulator.push(event);
            }
            return accumulator;
        },
        [],
    );
}

function mapPlannedMedicalExaminationsToCalendarEvents(examinations: IPlannedMedicalExamination[]) {
    return examinations.map((examination): ICalendarEvent<IPlannedMedicalExamination> => {
        const duration = examination.duration
            ? Number(examination.duration)
            : DEFAULT_MEDICAL_EXAMINATION_DURATION;
        const start = getDate(examination.time);
        let end = minutesOffsetFromDate(start, duration).toDate();

        // BigCalender shows events which are spread over multiple days, as day events (on the top).
        // to prevent an event with startTime Fri ... 23:45 and endTime Sat ... 0:00 we adjust the endTime
        // to to latest possible time on the day of the startdate (Fri 23:59:59)
        if (!isOnSameDay(end, start)) {
            end = getDate(getLatestPossibleTimeOnDate(examination.time));
        }

        return {
            id: `${CalendarEventType.DraggableMedicalExamination}_${examination.id}`,
            start,
            end,
            allDay: false,
            type: CalendarEventType.DraggableMedicalExamination,
            data: examination,
            dataType: CalendarEventDataType.DraggablePlannedMedicalExamination,
            isConcatenated: false,
            title: formatPersonName(examination),
        };
    });
}

function isEmployeeDroppedIntoFreeTimeslot(
    draggablePrefix: DraggablePrefix,
    droppablePrefix: DroppablePrefix,
) {
    return draggablePrefix === DraggablePrefix.Employee && droppablePrefix === DroppablePrefix.FreeTimeslot;
}

function isPlannedExaminationDroppedIntoFreeTimeslot(
    draggablePrefix: DraggablePrefix,
    droppablePrefix: DroppablePrefix,
) {
    return draggablePrefix === DraggablePrefix.PlannedExamination && droppablePrefix === DroppablePrefix.FreeTimeslot;
}

function isPlannedExaminationDroppedIntoEmployees(
    draggablePrefix: DraggablePrefix,
    droppablePrefix: DroppablePrefix,
) {
    return draggablePrefix === DraggablePrefix.PlannedExamination && droppablePrefix === DroppablePrefix.Employees;
}
