import {
    FETCH_RISKS,
    FETCH_COMPANY_MEDICAL_CENTERS,
    FETCH_COMPANY_ADDRESSES,
    FETCH_COMPANY_DETAIL,
    UPDATE_COMPANY,
    UPDATE_COMPANY_SINGLE_FIELD,
    FETCH_COMPANY_AVAILABILITIES_MED_EXAMS, REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS,
    FETCH_COMPANY_AVAILABILITIES_RISK_MGMT, REPLACE_COMPANY_AVAILABILITIES_RISK_MGMT,
    FETCH_COMPANY_HOLIDAYS,
    FETCH_COMPANY_INTERNAL_CONTACTS,
    REMOVE_CONTACT,
    ADD_CONTACT,
    UPDATE_CONTACT,
    FETCH_COMPANY_COST_CENTERS,
    FETCH_COMPANY_RATES,
    FETCH_COMPANY_SEAT_INTERNAL_CONTACTS,
    FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT,
    FETCH_SMALL_COMPANY_DETAIL,
    FETCH_COMPANY_SEAT_DETAIL,
    ADD_COMPANY_SEAT,
    FETCH_COMPANY_BUFFERZONES,
    UPDATE_COMPANY_HOLIDAYS,
} from './types';
import { createAction, createSuccessAction, createFailAction, createResetAction, createTypeActions } from '../../index';
import {
    ICompanyCodePayload, ICompanySeat, ICompanyBufferzone, IFetchCompanyBufferzonePayload,
} from '../../../models/admin/company';
import {
    ICompanyDetail,
    IUpdateCompanyPayload,
    IUpdateCompanySingleFieldPayload,
    ICompanyMedicalCenter,
    CompanyAvailabilityType,
    ICompanyAvailabilities,
    IFetchCompanyAvailabilitiesPayload,
    IReplaceCompanyAvailabilitiesPayload,
    IFetchCompanySeatContactsPayload,
    ICompanyContact,
    IRemoveCompanyContactPayload,
    IAddCompanyContactPayload,
    IUpdateCompanyContactPayload,
    ICompanyAddress,
    IFetchCompanyHolidaysPayload,
    ICompanyHoliday,
    IAddCompanySeatPayload,
    IUpdateCompanyHolidaysPayload,
    IFetchCompanyInternalContactsPayload,
} from '../../../models/admin/companyInfo';
import { IRisk } from '../../../models/admin/risks';
import { ITraceableApiError } from '../../../models/general/error';
import { ITraceableAsyncRequestPayload } from '../../../models/general/redux';
import { ICostCenter } from '../../../models/admin/employee';
import { IRates, IFetchCompanyRatesPayload } from '../../../models/admin/rates';

export function fetchRisksSucceeded(payload: IRisk[]) {
    return createSuccessAction<IRisk[]>(FETCH_RISKS, payload);
}

export function fetchRisksFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_RISKS, error);
}

// Company medical centers

export const fetchCompanyMedicalCentersActions = createTypeActions<ICompanyCodePayload, ICompanyMedicalCenter[]>({
    type: FETCH_COMPANY_MEDICAL_CENTERS,
});

// Company cost centers

export const fetchCompanyCostCentersActions = createTypeActions<ICompanyCodePayload, ICostCenter[]>({
    type: FETCH_COMPANY_COST_CENTERS,
});

// Company addresses

export const fetchCompanyAddressesActions = createTypeActions<ICompanyCodePayload, ICompanyAddress[]>({
    type: FETCH_COMPANY_ADDRESSES,
});

// Company detail

export function fetchCompanyDetailSucceeded(payload: ICompanyDetail) {
    return createSuccessAction<ICompanyDetail>(FETCH_COMPANY_DETAIL, payload);
}

export function fetchCompanyDetailFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_DETAIL, error);
}

export const fetchCompanySeatDetailActions = createTypeActions<{}, ICompanyDetail>({
    type: FETCH_COMPANY_SEAT_DETAIL,
});

// Small Company detail

export function fetchSmallCompanyDetail(payload: ICompanyCodePayload) {
    return createAction<ICompanyCodePayload>(FETCH_SMALL_COMPANY_DETAIL, payload);
}

// Update company.

export const updateCompanyActions = createTypeActions<IUpdateCompanyPayload>({
    type: UPDATE_COMPANY,
});

// Update company single field.

export function updateCompanySingleField(payload: IUpdateCompanySingleFieldPayload) {
    return createAction<IUpdateCompanySingleFieldPayload>(UPDATE_COMPANY_SINGLE_FIELD, payload);
}

export function updateCompanySingleFieldSucceeded(payload: IUpdateCompanySingleFieldPayload) {
    return createSuccessAction(UPDATE_COMPANY_SINGLE_FIELD, payload);
}

export function updateCompanySingleFieldFailed(error: ITraceableApiError & ITraceableAsyncRequestPayload) {
    return createFailAction<ITraceableApiError>(UPDATE_COMPANY_SINGLE_FIELD, error);
}

// Add companySeat.

export const addCompanySeatActions = createTypeActions<IAddCompanySeatPayload>({
    type: ADD_COMPANY_SEAT,
});

// Internal contact persons
export function fetchContacts(payload?: IFetchCompanyInternalContactsPayload) {
    return createAction(FETCH_COMPANY_INTERNAL_CONTACTS, payload ? payload : {});
}

export function fetchContactsSucceeded(payload: ICompanyContact[]) {
    return createSuccessAction<ICompanyContact[]>(FETCH_COMPANY_INTERNAL_CONTACTS, payload);
}

export function fetchContactsFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(FETCH_COMPANY_INTERNAL_CONTACTS, error);
}

export const fetchCompanySeatContactsActions = createTypeActions<IFetchCompanySeatContactsPayload, ICompanyContact[]>({
    type: FETCH_COMPANY_SEAT_INTERNAL_CONTACTS,
});

// Remove internal contact
export function removeContact(payload: IRemoveCompanyContactPayload) {
    return createAction<IRemoveCompanyContactPayload>(REMOVE_CONTACT, payload);
}

export function removeContactSucceeded() {
    return createSuccessAction(REMOVE_CONTACT, {});
}

export function removeContactFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(REMOVE_CONTACT, error);
}

// Add internal contact
export function addContact(payload: IAddCompanyContactPayload) {
    return createAction(ADD_CONTACT, payload);
}

export function addContactSucceeded() {
    return createSuccessAction(ADD_CONTACT, {});
}

export function addContactFailed(error: ITraceableApiError) {
    return createFailAction<ITraceableApiError>(ADD_CONTACT, error);
}

// Update internal contact.
export const updateContactActions = createTypeActions<IUpdateCompanyContactPayload, {},
    ITraceableApiError & ITraceableAsyncRequestPayload>({
        type: UPDATE_CONTACT,
    });

// Fetch company availabilities

export function fetchCompanyAvailabilities({
    availabilityType,
    companyCode,
}: {
    availabilityType: CompanyAvailabilityType,
    companyCode: string,
}) {
    return createAction<IFetchCompanyAvailabilitiesPayload>(
        toCompanyAvailabilitiesActionType(availabilityType),
        {
            companyCode,
        },
    );
}

export function fetchCompanyAvailabilitiesSucceeded(
    availabilityType: CompanyAvailabilityType,
    payload: ICompanyAvailabilities,
) {
    return createSuccessAction<ICompanyAvailabilities>(
        toCompanyAvailabilitiesActionType(availabilityType),
        payload,
    );
}

export function fetchCompanyAvailabilitiesFailed(
    availabilityType: CompanyAvailabilityType,
    error: ITraceableApiError,
) {
    return createFailAction<ITraceableApiError>(
        toCompanyAvailabilitiesActionType(availabilityType),
        error,
    );
}

function toCompanyAvailabilitiesActionType(availabilityType: CompanyAvailabilityType) {
    return availabilityType === CompanyAvailabilityType.MedicalExaminations ?
        FETCH_COMPANY_AVAILABILITIES_MED_EXAMS : FETCH_COMPANY_AVAILABILITIES_RISK_MGMT;
}

// Replace company availabilities

export function replaceCompanyAvailabilities({
    availabilityType,
    payload,
}: {
    availabilityType: CompanyAvailabilityType,
    payload: IReplaceCompanyAvailabilitiesPayload,
}) {
    return createAction<IReplaceCompanyAvailabilitiesPayload>(
        toReplaceCompanyAvailabilitiesActionType(availabilityType),
        payload,
    );
}

export function replaceCompanyAvailabilitiesSucceeded(availabilityType: CompanyAvailabilityType) {
    return createSuccessAction(
        toReplaceCompanyAvailabilitiesActionType(availabilityType),
        {},
    );
}

export function replaceCompanyAvailabilitiesFailed(
    availabilityType: CompanyAvailabilityType,
    error: ITraceableApiError,
) {
    return createFailAction<ITraceableApiError>(
        toReplaceCompanyAvailabilitiesActionType(availabilityType),
        error,
    );
}

export function replaceCompanyAvailabilitiesReset(availabilityType: CompanyAvailabilityType) {
    return createResetAction(
        toReplaceCompanyAvailabilitiesActionType(availabilityType),
        {},
    );
}

function toReplaceCompanyAvailabilitiesActionType(availabilityType: CompanyAvailabilityType) {
    return availabilityType === CompanyAvailabilityType.MedicalExaminations ?
        REPLACE_COMPANY_AVAILABILITIES_MED_EXAMS : REPLACE_COMPANY_AVAILABILITIES_RISK_MGMT;
}

// Fetch company holidays

export const fetchCompanyHolidaysActions = createTypeActions<IFetchCompanyHolidaysPayload, ICompanyHoliday[]>({
    type: FETCH_COMPANY_HOLIDAYS,
});

export const updateCompanyHolidaysActions = createTypeActions<IUpdateCompanyHolidaysPayload>({
    type: UPDATE_COMPANY_HOLIDAYS,
});

// Fetch company rates

export const fetchCompanyRatesActions = createTypeActions<IFetchCompanyRatesPayload, IRates>({
    type: FETCH_COMPANY_RATES,
});

// Company seats with employee count

export const fetchCompanySeatsWithEmployeeCountActions = createTypeActions<{}, ICompanySeat[]>(
    { type: FETCH_COMPANY_SEATS_WITH_EMPLOYEE_COUNT },
);

// Company bufferzones

export const fetchCompanyBufferzones = createTypeActions<IFetchCompanyBufferzonePayload, ICompanyBufferzone[]>(
    { type: FETCH_COMPANY_BUFFERZONES },
);
