import { REDUCER_STORAGE_TYPE } from '@snipsonian/redux/es/config/storageType';
import {
    IAsyncFetchField,
    getAsyncFetchInitialState,
    createAsyncFetchActionHandlers,
    registerReducer,
} from '../../index';
import {
    FETCH_EXECUTED_COMPANY_VISITS,
    FETCH_PLANNED_COMPANY_VISITS,
    FETCH_COMPANY_VISIT_DOCUMENTS,
    UPDATE_COMPANY_VISITS_REQUEST_WIZARD_ENTITY,
    CREATE_COMPANY_VISITS_REQUEST,
} from './types';
import {
    IExecutedCompanyVisit, IPlannedCompanyVisit,
    ICompanyVisitDocument,
    ICompanyVisitRequestWizardData,
    ICompanyVisitRequestWizardPayload,
} from '../../../models/interventions/companyVisits';
import ROUTE_KEYS from '../../../routeKeys';
import { IAsyncDoField } from '../../../models/general/redux';
import { getAsyncDoInitialState, createAsyncDoActionHandlers } from '../../../utils/libs/redux/async/asyncReducerUtils';
import { createActionHandler } from '../../../utils/libs/redux/createActionHandler';
import { getRequestCompanyVisitWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { REDUCER_KEYS } from '../../../config/redux.config';

export const reducerKey = REDUCER_KEYS.COMPANY_VISITS;

export interface IReducerState {
    executedCompanyVisits: IAsyncFetchField<IExecutedCompanyVisit[]>;
    plannedCompanyVisits: IAsyncFetchField<IPlannedCompanyVisit[]>;
    visitDocuments: IAsyncFetchField<ICompanyVisitDocument[]>;
    companyVisitRequestWizardData: ICompanyVisitRequestWizardData;
    createCompanyVisitRequest: IAsyncDoField;
}

const initialState: IReducerState = {
    executedCompanyVisits: getAsyncFetchInitialState(),
    plannedCompanyVisits: getAsyncFetchInitialState(),
    visitDocuments: getAsyncFetchInitialState(),
    companyVisitRequestWizardData: null,
    createCompanyVisitRequest: getAsyncDoInitialState(),
};

const actionHandlers = {
    ...createAsyncFetchActionHandlers<IExecutedCompanyVisit[], IReducerState, IExecutedCompanyVisit[]>({
        baseActionType: FETCH_EXECUTED_COMPANY_VISITS,
        fieldName: 'executedCompanyVisits',
        resetDataOnTrigger: false,
        overrideTriggerActionType: ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED,
        transformStateOnTrigger: ({ oldState }) => {
            return {
                ...oldState,
                visitDocuments: getAsyncFetchInitialState(),
            };
        },
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<IPlannedCompanyVisit[], IReducerState, IPlannedCompanyVisit[]>({
        baseActionType: FETCH_PLANNED_COMPANY_VISITS,
        fieldName: 'plannedCompanyVisits',
        resetDataOnTrigger: false,
        overrideTriggerActionType: ROUTE_KEYS.R_COMPANY_VISITS_PLANNED,
        reducerKey,
    }),
    ...createAsyncFetchActionHandlers<ICompanyVisitDocument[], IReducerState, ICompanyVisitDocument[]>({
        baseActionType: FETCH_COMPANY_VISIT_DOCUMENTS,
        fieldName: 'visitDocuments',
        resetDataOnTrigger: true,
        reducerKey,
    }),

    [ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW]:
        createActionHandler<IReducerState, ICompanyVisitRequestWizardPayload>(
            ({ oldState, payload }) => {
                const stepId = payload.step;
                return {
                    ...oldState,
                    companyVisitRequestWizardData: (stepId === getRequestCompanyVisitWizardSteps().firstStepId)
                        ? {
                            stepId,
                            entity: payload.resetDataEntity
                                ? {}
                                // eslint-disable-next-line max-len
                                : (oldState.companyVisitRequestWizardData && oldState.companyVisitRequestWizardData.entity) || {},
                        }
                        : {
                            ...oldState.companyVisitRequestWizardData,
                            stepId,
                        },
                };
            },
        ),
    [UPDATE_COMPANY_VISITS_REQUEST_WIZARD_ENTITY]:
        createActionHandler<IReducerState, object>(
            ({ oldState, payload }) => {
                return {
                    ...oldState,
                    companyVisitRequestWizardData: {
                        ...oldState.companyVisitRequestWizardData,
                        entity: {
                            ...oldState.companyVisitRequestWizardData.entity,
                            ...payload,
                        },
                    },
                };
            },
        ),
    ...createAsyncDoActionHandlers<IReducerState>({
        baseActionType: CREATE_COMPANY_VISITS_REQUEST,
        fieldName: 'createCompanyVisitRequest',
    }),
};

registerReducer<IReducerState>({
    initialState,
    actionHandlers,
    key: reducerKey,
    reducerStorageType: REDUCER_STORAGE_TYPE.SESSION,
    transformReducerStateForStorage: (reducerState) => ({
        // so that request-company-visits steps are not lost when refreshing
        companyVisitRequestWizardData: reducerState.companyVisitRequestWizardData,
    }),
});
