import React from 'react';
import clone from 'ramda/src/clone';
import './add-emails.scss';
import { connect } from '../../..';
import PageHeader from '../../../appShell/PageHeader';
import ListWithSorting from '../../../common/list/ListWithSorting';
import { ListColumns, SortType, SortOrder, ISortedColumn, ListItem } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import { TEmployeeUpdateFields, IEmployeeWithoutEmail } from '../../../../models/admin/employee';
import { formatPersonNameFormal } from '../../../../utils/formatting/formatPerson';
import formatNationalRegisterNumber from '../../../../utils/formatting/formatNationalRegisterNumber';
import { createSelector } from 'reselect';
import {
    getEmployeesWithoutEmail,
    getEmployeesWithoutEmailAsyncInfo,
} from '../../../../redux/employee/employees/selectors';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import Loader from '../../../common/waiting/Loader';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import ListFooter from '../../../common/list/ListFooter';
import Button from '../../../common/buttons/Button';
import { navigateTo } from '../../../../redux/location/actions';
import ROUTE_KEYS from '../../../../routeKeys';
import { getUpdateEmployeeRequestId } from '../EmployeeDetails';
import { updateEmployee, updateEmployeeReset } from '../../../../redux/employee/info/actions';
import AddEmployeeEmailField from './AddEmployeeEmailField';
import ListActions from '../../../common/list/ListActions';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import filterListItems from '../../../../utils/list/filterListItems';
import debounce, { TDebounced } from '../../../../utils/core/debounce';
import ListActionButton from '../../../common/buttons/ListActionButton';
import exportListDataToCsv from '../../../../utils/file/csv/exportListDataToCsv';
import {
    getMatchingUntransformedMasterDataSoThatWeKeepTheSameClientSideFiltering,
} from '../../../common/widget/MasterWithDetail/Header';

const CLASS_NAME = 'AddEmails';
const BASE_KEY = 'administration.employees.add_emails';
const LIST_NAME = 'add-emails';

interface IPrivateProps {
    employees: IEmployeeWithoutEmail[];
    listItems: ListItem<IColumnNames>[];
    asyncInfo: IAsyncFieldInfo;
    navigateToEmployees: () => void;
    updateEmployeeField: (id: number, fieldName: keyof TEmployeeUpdateFields, value: string) => void;
}

interface IColumnNames {
    name: string;
    insz: string;
    function: string;
    seat: string;
    email: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    name: {
        label: <Translate msg={`${BASE_KEY}.columns.name`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 17,
    },
    insz: {
        label: <Translate msg={`${BASE_KEY}.columns.insz`} />,
        sortable: true,
        sortType: SortType.DotSeparatedNumber,
        percentWidth: 14,
    },
    function: {
        label: <Translate msg={`${BASE_KEY}.columns.function`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
    },
    seat: {
        label: <Translate msg={`${BASE_KEY}.columns.seat`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    email: {
        label: <Translate msg={`${BASE_KEY}.columns.email`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 27,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'name',
    sortOrder: SortOrder.Ascending,
};

interface IState {
    search: string;
}

class AddEmails extends React.Component<IPrivateProps, IState> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);
    private onSearchDebounced: TDebounced<[string]>;

    public constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            search: '',
        };

        this.renderEditEmailInput = this.renderEditEmailInput.bind(this);
        this.onExportData = this.onExportData.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSearchDebounced = debounce(this.onSearch, 500);
    }

    public render() {
        const { listItems, asyncInfo, navigateToEmployees } = this.props;

        this.columns.email.render = this.renderEditEmailInput;

        const filteredListItems = filterListItems(listItems, this.state.search, { columnKeysToIgnore: ['email'] });

        return (
            <TranslatorContext.Consumer>
                {({ translator }) => (
                    <div className={CLASS_NAME}>
                        <PageHeader
                            title={`${BASE_KEY}.header.title`}
                            titlePlaceholders={{ amount: listItems.length }}
                            text={`${BASE_KEY}.header.text`}
                        />
                        <div className="container">
                            <Loader show={asyncInfo.status} />
                            <div className={`${CLASS_NAME}__list-actions`}>
                                <ListActions
                                    name={LIST_NAME}
                                    withSelectAll={false}
                                    withSearch={true}
                                    initialSearchValue=""
                                    searchPlaceholder={translator(`${BASE_KEY}.search_placeholder`)}
                                    onSearchInput={this.onSearchDebounced}
                                />
                                <ListActionButton
                                    id="add-emails-export"
                                    type="text"
                                    iconTypeName="excel"
                                    translationKey="common.master_with_detail.action.export"
                                    onClick={() => this.onExportData(filteredListItems)}
                                />
                            </div>
                            {asyncInfo.error && <ErrorPlaceholder apiError={asyncInfo.error} />}
                            <ListWithSorting
                                name={LIST_NAME}
                                initialSort={INITIAL_SORT}
                                columns={this.columns}
                                items={filteredListItems}
                                footer={<ListFooter right={renderExitButton()} />}
                            />
                        </div>
                    </div>
                )}
            </TranslatorContext.Consumer>
        );

        function renderExitButton() {
            return (
                <Button
                    id="to-employees"
                    typeName="secondary"
                    disabled={asyncInfo.status === AsyncStatus.Busy}
                    onClick={navigateToEmployees}
                >
                    <Translate msg={`${BASE_KEY}.footer.finish`} />
                </Button>
            );
        }
    }

    private onSearch(value: string) {
        this.setState({ search: value });
    }

    private renderEditEmailInput(listItem: ListItem<IColumnNames>) {
        return (
            <AddEmployeeEmailField
                id={listItem.id as number}
                onSave={this.props.updateEmployeeField}
                initialValue={(listItem.columns.email as string) || ''}
            />
        );
    }

    private onExportData(listItems: ListItem<IColumnNames>[]) {
        const { employees } = this.props;
        const data: IEmployeeWithoutEmail[] = getMatchingUntransformedMasterDataSoThatWeKeepTheSameClientSideFiltering(
            listItems,
            employees,
            (item: object) => item['id'],
        ) as IEmployeeWithoutEmail[];
        exportListDataToCsv({
            baseFilename: 'emails',
            listData: data.map((item) => ({
                employee: {
                    name: item.name,
                    firstName: item.firstName,
                },
                nationalRegisterNumber: item.nationalRegisterNumber,
                company: {
                    companyCode: item.company.companyCode,
                    name: item.company.name,
                },
                function: {
                    description: item.function.description,
                },
                email: item.email,
            })),
        });
    }
}

const getListItemsMemoizedSelector = createSelector(getEmployeesWithoutEmail, (employees) => {
    return mapEmployeesToListItems(employees);
});

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            listItems: getListItemsMemoizedSelector(state),
            asyncInfo: getEmployeesWithoutEmailAsyncInfo(state),
            employees: getEmployeesWithoutEmail(state),
        };
    },
    dispatchProps: (dispatch) => {
        return {
            navigateToEmployees: () => {
                // Reset selectedEmployee to prevent DetailFields from crashing when navigating back
                // It crashes because selectedEmployee contains an incomplete employee object
                // after updating from this list
                dispatch(updateEmployeeReset());
                dispatch(navigateTo(ROUTE_KEYS.R_EMPLOYEES));
            },
            updateEmployeeField: (id: number, fieldName: keyof TEmployeeUpdateFields, value: string) => {
                dispatch(
                    updateEmployee({
                        updatedFromAddEmailList: true,
                        requestId: getUpdateEmployeeRequestId(id, fieldName),
                        id,
                        employeeData: {
                            [fieldName]: value,
                        },
                    }),
                );
            },
        };
    },
})(AddEmails);

function mapEmployeesToListItems(employees: IEmployeeWithoutEmail[]): ListItem<IColumnNames>[] {
    return employees.map((employee) => {
        return {
            id: employee.id,
            columns: {
                name: formatPersonNameFormal(employee),
                insz:
                    employee &&
                    employee.nationalRegisterNumber &&
                    formatNationalRegisterNumber(employee.nationalRegisterNumber),
                function: employee && employee.function && employee.function.description,
                seat: employee && employee.company && employee.company.name,
                email: employee && employee.email,
            },
        };
    });
}
