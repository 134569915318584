import { createEpic, IState } from '../../index';
import {
    FETCH_PLANNED_MEDICAL_EXAMINATIONS, FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL,
} from '../types';
import {
    fetchPlannedMedicalExaminationsActions,
    fetchPlannedMedicalExaminationDetailSucceeded,
    fetchPlannedMedicalExaminationDetailFailed,
    fetchPlannedMedicalExaminationDetail,
} from '../actions';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
} from '../../company/selected/selectors';
import {
    fetchSmallEmployeeDetails,
    fetchEmployeeRisksAndResearches,
} from '../../employee/info/actions';
import { getQueryParams, getLocationState } from '../../location/selectors';
import {
    IPlannedMedicalExaminationsFilter, IFetchMedicalExaminationDetailPayload, IPlannedMedicalExaminationsBackendFilter,
} from '../../../models/interventions/medicalExaminations';
import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import {
    DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS,
} from '../../../api/interventions/medicalExaminations.api';
import Api from '../../../api';
import ROUTE_KEYS from '../../../routeKeys';
import { createNotFoundError } from '../../../utils/api/error/createNotFoundError';
import {
    arePlannedMedicalExaminationsAvailable,
    getPlannedMedicalExaminationFromList,
    getSelectedPlannedMedicalExamination,
} from '../selectors';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { REDUCER_KEYS } from '../../../config/redux.config';

const ACTION_TYPES_THAT_FETCH_PLANNED_EXAMINATION_DETAILS_DATA_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE);

// fetchPlannedMedicalExaminationsForRoutesEpic
createEpic<{}, IPlannedMedicalExaminationsFilter>({
    onActionType: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
    refreshDataIf: ({ getState, action }) => {
        if (!arePlannedMedicalExaminationsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_PLANNED_MEDICAL_EXAMINATIONS_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    noDataRefreshOnlyInTheseReducers: [REDUCER_KEYS.MEDICALEXAMINATION],
    processReturn: ({ api, getState }) => {
        const state = getState();
        return fetchPlannedMedicalExaminations({
            api,
            getState,
            filter: getQueryParams(state) as IPlannedMedicalExaminationsFilter,
        });
    },
    latest: false,
});

// fetchPlannedMedicalExaminationsEpic
createEpic<IPlannedMedicalExaminationsBackendFilter>({
    onActionType: FETCH_PLANNED_MEDICAL_EXAMINATIONS,
    processReturn: ({ api, getState, action }) => {
        return fetchPlannedMedicalExaminations({
            api,
            getState,
            filter: action.payload,
        });
    },
    latest: false,
});

async function fetchPlannedMedicalExaminations({ api, getState, filter }:
    { api: typeof Api, getState, filter: IPlannedMedicalExaminationsBackendFilter}) {
    try {
        const state = getState();

        const plannedMedicalExaminations = await doFetchPlannedMedExamsCall(api, state, filter);

        return fetchPlannedMedicalExaminationsActions.succeeded(plannedMedicalExaminations);
    } catch (error) {
        return fetchPlannedMedicalExaminationsActions.failed(error);
    }
}

function doFetchPlannedMedExamsCall(api: typeof Api, state: IState, filter: IPlannedMedicalExaminationsBackendFilter) {
    const companyCode = getSelectedSeatCompanyCode(state);
    const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected;

    return api.interventions.medicalExaminations
        .fetchPlanned(
            companyCode,
            filter,
            showFullFamily,
        );
}

// fetchPlannedMedicalExaminationDetailEpicIfNotAvailableEpic
createEpic<IFetchMedicalExaminationDetailPayload>({
    onActionType: ACTION_TYPES_THAT_FETCH_PLANNED_EXAMINATION_DETAILS_DATA_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState, action }) => {
        const selectedExamination = getSelectedPlannedMedicalExamination(getState());
        return !selectedExamination || selectedExamination.id !== action.payload.examinationId;
    },
    processReturn({ action }) {
        return fetchPlannedMedicalExaminationDetail(action.payload);
    },
    latest: false,
});

// fetchPlannedMedicalExaminationDetailEpic
createEpic<IFetchMedicalExaminationDetailPayload>({
    onActionType: FETCH_PLANNED_MEDICAL_EXAMINATION_DETAIL,
    async processMultiple({ api, action, getState }, dispatch, done) {
        try {
            const { id: employeeId } = action.payload;
            const state = getState();

            const examinationId = typeof action.payload.examinationId === 'string' ?
                Number(action.payload.examinationId) : action.payload.examinationId;

            let plannedMedicalExaminationFromList = getPlannedMedicalExaminationFromList(state, examinationId);

            if (!plannedMedicalExaminationFromList) {
                const filter: IPlannedMedicalExaminationsFilter = getQueryParams(state);

                const plannedMedicalExaminations = await doFetchPlannedMedExamsCall(api, state, filter);
                dispatch(fetchPlannedMedicalExaminationsActions.succeeded(plannedMedicalExaminations));

                plannedMedicalExaminationFromList = plannedMedicalExaminations
                    .find((item) => item.id === examinationId);
            }

            if (plannedMedicalExaminationFromList) {
                const employeeCustomerId = plannedMedicalExaminationFromList.employee.id;

                if (!employeeId) {
                    // This is fetched by the employee details if the employee id is set on the route
                    dispatch(fetchSmallEmployeeDetails({ id: employeeCustomerId }));
                    dispatch(fetchEmployeeRisksAndResearches({ id: employeeCustomerId }));
                }

                dispatch(fetchPlannedMedicalExaminationDetailSucceeded(plannedMedicalExaminationFromList));
            } else {
                dispatch(fetchPlannedMedicalExaminationDetailFailed(createNotFoundError()));
            }

            return done();
        } catch (error) {
            dispatch(fetchPlannedMedicalExaminationDetailFailed(error));
            return done();
        }
    },
    latest: true,
});
