import React, { PureComponent } from 'react';
import clone from 'ramda/src/clone';
import './work-post-cards.scss';
import { IColumnNames } from './typings';
import DownloadActions from './DownloadActions';
import { ICompanyFunction } from '../../../models/admin/companyFunctions';
import { IEmployee, IEmployeeDetails } from '../../../models/admin/employee';
import { ListColumns, ISortedColumn, SortType, SortOrder, ListItem } from '../../../models/general/list';
import {
    IPlannedMedicalExamination,
    IExecutedMedicalExamination,
} from '../../../models/interventions/medicalExaminations';
import {
    getCompanyFunctions,
    getFetchCompanyFunctionsAsyncInfo,
    getSelectedCompanyFunctionId,
    getSelectedCompanyFunction,
} from '../../../redux/company/functions/selectors';
import {
    getSelectedEmployeeAsyncInfo,
    getSelectedEmployee,
    getSelectedEmployeeId,
} from '../../../redux/employee/info/selectors';
import {
    getSelectedPlannedMedicalExaminationAsyncInfo,
    getSelectedPlannedMedicalExamination,
    getSelectedExecutedMedicalExaminationAsyncInfo,
    getSelectedExecutedMedicalExamination,
} from '../../../redux/medicalExamination/selectors';
import { DEFAULT_COMPANY_FUNCTIONS_FILTERS } from '../../../api/admin/functions.api';
import ROUTE_KEYS from '../../../routeKeys';
import PageHeader from '../../appShell/PageHeader';
import {
    EmployeeDetailsHeader,
    EmployeeDetailsOverlay,
    EmployeeDetailsContent,
} from '../../administration/Employees/EmployeeDetails';
import {
    CompanyFunctionFilterContent,
    companyFunctionTransformFilterValuesToActiveFilters,
    companyFunctionClientSideFilterOfListData,
    CompanyFunctionSearchContent,
} from '../../administration/Functions';
import { CompanyFunctionDetailContent, CompanyFunctionDetailHeader } from '../../administration/Functions/detail';
import {
    DetailHeader as DetailHeaderExecuted,
    DetailContent as DetailContentExecuted,
} from '../../interventions/MedicalExaminations/Executed/detail';
import {
    DetailHeader as DetailHeaderPlanned,
    DetailContent as DetailContentPlanned,
} from '../../interventions/MedicalExaminations/Planned/detail';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import ListItemActions from '../../common/list/ListItemActions';
import ListWithSorting from '../../common/list/ListWithSorting';
import {
    IRenderMasterContentProps,
    IRenderSearchContentProps,
    IRenderFilterContentProps,
    IRenderDetailHeaderProps,
    IRenderDetailContentProps,
    IRenderDetailFooterProps,
    IDetailConfig,
} from '../../common/widget/MasterWithDetail/typings';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import Translate from '../../common/Translate';
import workPostCardIllu from '../../assets/img/illustrations/illu_werkpostfiches.svg';
import Icon from '../../common/icons/Icon';
import Button from '../../common/buttons/Button';
import connect from '../../../utils/libs/redux/connect';
import TooltipWithIcon from '../../common/widget/TooltipWithIcon';
import { EmployeeDetailsOverlayType } from '../../administration/Employees/EmployeeDetails/common';
import {
    getCoursesPlannedAsyncInfo,
    getSelectedPlannedCourse,
    getCoursesFollowedAsyncInfo,
    getSelectedFollowedCourse,
} from '../../../redux/documentCenter/courses/selectors';
import { ICourse } from '../../../models/documentCenter/courses';
import DetailContentCoursesPlanned from '../../documentCenter/Courses/Planned/detail/content';
import DetailFooterCoursesPlanned from '../../documentCenter/Courses/Planned/detail/footer';
import DetailHeaderCourses from '../../documentCenter/Courses/shared/header';
import DetailContentCoursesFollowed from '../../documentCenter/Courses/Followed/detail/content';
import DetailFooterCoursesFollowed from '../../documentCenter/Courses/Followed/detail/footer';

const BASE_NAME = 'work-post-cards-list';
const CLASS_NAME = 'WorkPostCards';

const PLANNED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL,
    asyncInfoSelector: getSelectedPlannedMedicalExaminationAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: getSelectedPlannedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IPlannedMedicalExamination>) =>
        <DetailHeaderPlanned {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IPlannedMedicalExamination>) =>
        <DetailContentPlanned {...renderProps} />,
};
const EXECUTED_EXAMINATION_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL,
    asyncInfoSelector: getSelectedExecutedMedicalExaminationAsyncInfo,
    idRouteParamName: 'examinationId',
    dataSelector: getSelectedExecutedMedicalExamination,
    renderHeader: (renderProps: IRenderDetailHeaderProps<IExecutedMedicalExamination>) =>
        <DetailHeaderExecuted {...renderProps} />,
    renderContent: (renderProps: IRenderDetailContentProps<IExecutedMedicalExamination>) =>
        <DetailContentExecuted {...renderProps} />,
};

const PLANNED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_COURSES_PLANNED_DETAIL,
    asyncInfoSelector: getCoursesPlannedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: getSelectedPlannedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) =>
        <DetailContentCoursesPlanned {...renderProps} />,
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesPlanned {...renderProps} />,
};

const FOLLOWED_COURSES_DETAIL_CONFIG: IDetailConfig = {
    routeKey: ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_COURSES_FOLLOWED_DETAIL,
    asyncInfoSelector: getCoursesFollowedAsyncInfo,
    idRouteParamName: 'coursesOrganizedId',
    dataSelector: getSelectedFollowedCourse,
    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) => (
        <DetailContentCoursesFollowed
            {...renderProps}
            followedCoursesAttendantRouteKey={ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL_ATTENDANT}
        />),
    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
        <DetailHeaderCourses {...renderProps} />,
    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
        <DetailFooterCoursesFollowed {...renderProps} />,
};

type TWorkPostCardsListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

const COLUMNS: ListColumns<IColumnNames> = {
    temporary: {
        sortable: false,
        render: renderTemporaryTooltip,
        percentWidth: null,
        minWidth: 50,
    },
    code: {
        label: <Translate msg="document_center.work_post_cards.columns.code" />,
        sortable: true,
        percentWidth: 15,
        sortType: SortType.String,
    },
    function: {
        label: <Translate msg="document_center.work_post_cards.columns.function" />,
        sortable: true,
        percentWidth: 53,
        sortType: SortType.String,
    },
    employeesAssigned: {
        label: <Translate msg="document_center.work_post_cards.columns.employees_assigned" />,
        sortable: true,
        percentWidth: 20,
        sortType: SortType.Number,
        align: 'right',
    },
    download: {
        sortable: false,
        percentWidth: 12,
        align: 'right',
    },
};

interface IFilterValues {
    search: string;
    showUnmanned: 'true' | 'false';
}

interface IPrivateProps {
    selectedCompanyFunctionId: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'function',
    sortOrder: SortOrder.Ascending,
};

class WorkPostCards extends PureComponent<IPrivateProps> {
    public render() {
        const { selectedCompanyFunctionId } = this.props;
        return (
            <div className={CLASS_NAME}>
                <PageHeader
                    breadcrumbs={true}
                    title="document_center.work_post_cards.title"
                    svg={workPostCardIllu}
                    type="grey"
                />
                <MasterWithDetail
                    baseName={BASE_NAME}
                    getDefaultQueryParams={getDefaultQueryParams}
                    masterConfig={{
                        routeKey: ROUTE_KEYS.R_WORK_POST_CARDS,
                        asyncInfoSelector: getFetchCompanyFunctionsAsyncInfo,
                        dataSelector: getCompanyFunctions,
                        transformData: mapWorkPostCardFunctionsToListItems,
                        transformFilterValuesToActiveFilters: companyFunctionTransformFilterValuesToActiveFilters,
                        renderContent: (
                            renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                            <WorkPostCardsList {...renderProps} />,
                        clientSideSearchOfListData: {
                            searchFilterName: 'search',
                            columnsConfig: COLUMNS,
                        },
                        clientSideFilterOfListData: companyFunctionClientSideFilterOfListData,
                    }}
                    headerConfig={{
                        renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                            <CompanyFunctionSearchContent {...renderProps} />,
                        renderFilterContent:
                            (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                <CompanyFunctionFilterContent {...renderProps} />,
                    }}
                    detailConfig={{
                        levels: [
                            {
                                level: 1,
                                details: [{
                                    routeKey: ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL,
                                    asyncInfoSelector: getFetchCompanyFunctionsAsyncInfo,
                                    idSelector: getSelectedCompanyFunctionId,
                                    idRouteParamName: 'functionId',
                                    dataSelector: getSelectedCompanyFunction,
                                    renderHeader: (renderProps: IRenderDetailHeaderProps<ICompanyFunction>) =>
                                        <CompanyFunctionDetailHeader {...renderProps} />,
                                    renderContent: (renderProps: IRenderDetailContentProps<ICompanyFunction>) =>
                                        <CompanyFunctionDetailContent
                                            {...renderProps}
                                            employeeDetailRouteKey={ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL}
                                        />,
                                    renderFooter: (renderProps: IRenderDetailFooterProps<ICompanyFunction>) =>
                                        <WorkPostCardDetailFooter {...renderProps} />,
                                }],
                            },
                            {
                                level: 2,
                                details: [{
                                    routeKey: ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL,
                                    asyncInfoSelector: getSelectedEmployeeAsyncInfo,
                                    idSelector: getSelectedEmployeeId,
                                    dataSelector: getSelectedEmployee,
                                    renderHeader: (
                                        renderProps: IRenderDetailHeaderProps<IEmployee & IEmployeeDetails>,
                                    ) =>
                                        <EmployeeDetailsHeader {...renderProps} />,
                                    renderContent: (
                                        renderProps: IRenderDetailContentProps<IEmployee & IEmployeeDetails>,
                                    ) => selectedCompanyFunctionId &&
                                        <EmployeeDetailsContent
                                            {...renderProps}
                                            // eslint-disable-next-line max-len
                                            plannedMedicalExaminationRouteKey={ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL}
                                            // eslint-disable-next-line max-len
                                            executedMedicalExaminationRouteKey={ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL}
                                            // eslint-disable-next-line max-len
                                            followedCoursesRouteKey={ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_COURSES_FOLLOWED_DETAIL}
                                            // eslint-disable-next-line max-len
                                            plannedCoursesRouteKey={ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_COURSES_PLANNED_DETAIL}
                                            extraRoutePayload={{
                                                functionId: selectedCompanyFunctionId,
                                            }}
                                        />,
                                    renderOverlay: ({
                                        overlayType, closeOverlay, onSave,
                                    }) =>
                                        <EmployeeDetailsOverlay
                                            overlayType={overlayType as EmployeeDetailsOverlayType}
                                            closeOverlay={closeOverlay}
                                            onSave={onSave}
                                        />,
                                }],
                            },
                            {
                                level: 3,
                                details: [
                                    PLANNED_EXAMINATION_DETAIL_CONFIG,
                                    EXECUTED_EXAMINATION_DETAIL_CONFIG,
                                    PLANNED_COURSES_DETAIL_CONFIG,
                                    FOLLOWED_COURSES_DETAIL_CONFIG,
                                ],
                            },
                        ],
                    }}
                />
            </div>
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => ({
        selectedCompanyFunctionId: getSelectedCompanyFunctionId(state),
    }),
})(WorkPostCards);

function mapWorkPostCardFunctionsToListItems(masterData: ICompanyFunction[]): ListItem<IColumnNames>[] {
    return masterData
        .map((item) => {
            return {
                id: item.id,
                columns: {
                    code: item.referenceCode,
                    download: null,
                    employeesAssigned: item.employeesAssigned,
                    function: item.description,
                    temporary: item.isTemporary,
                },
            } as ListItem<IColumnNames>;
        });
}

function getDefaultQueryParams() {
    return DEFAULT_COMPANY_FUNCTIONS_FILTERS;
}

class WorkPostCardsList extends PureComponent<TWorkPostCardsListProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TWorkPostCardsListProps) {
        super(props);

        this.columns.download.render = this.renderDownloadActions.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            onItemSelected,
            footer,
        } = this.props;

        const isAnyFunctionTemporary = clientSideFilteredlistItems.filter((item) => {
            return item.columns.temporary;
        }).length > 0;

        return (
            <>
                {isAnyFunctionTemporary && (
                    <div className={`${CLASS_NAME}__warning`}>
                        <Icon typeName="warning" circle colorType="grey" />
                        <Translate msg="document_center.work_post_cards.temporary_function.label" />
                    </div>
                )}
                <ListWithSorting
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onItemRowClicked={onItemSelected}
                    initialSort={INITIAL_SORT}
                    footer={footer}
                />
            </>
        );
    }

    private renderDownloadActions(listItem: ListItem<IColumnNames>) {

        const isFunctionTemporary = listItem.columns.temporary;

        if (isFunctionTemporary) {
            return <span />;
        }

        const functionId = Number(listItem.id);

        /**
         * The intermediate div is needed to make it work.
         * Otherwise the tooltip does not remain open (at least on chrome) + some styling issues.
         */
        const DownloadIcon = (
            <div>
                <Icon
                    circle
                    typeName="download-file"
                />
            </div>
        );

        return (
            <ListItemActions>
                <DownloadActions
                    functionId={functionId}
                    target={DownloadIcon}
                    placement="left"
                />
            </ListItemActions>
        );
    }
}

function renderTemporaryTooltip(item: ListItem<IColumnNames>) {
    const warningIcon = (
        <Icon typeName="warning" circle colorType="grey"/>
    );

    const isFunctionTemporary = item.columns.temporary;

    if (!isFunctionTemporary) {
        return <span />;
    }

    return (
        <TooltipWithIcon icon={warningIcon} tooltipBubbleIcon={warningIcon} typeName="info-bubble">
            <div>
                <Translate msg={'document_center.work_post_cards.temporary_function.tooltip'} />
            </div>
        </TooltipWithIcon>
    );
}

function WorkPostCardDetailFooter(footerProps: IRenderDetailFooterProps<ICompanyFunction>) {
    const companyFunction = footerProps.detailData;

    if (!companyFunction) {
        return null;
    }

    if (companyFunction.isTemporary) {
        return null;
    }

    const DownloadButton = (
        <div>
            <Button
                id="work-post-card-detail-download"
                typeName="secondary"
            >
                <Icon typeName="download" />
                <span>
                    <Translate msg="document_center.work_post_cards.detail.action.download" />
                </span>
            </Button>
        </div>
    );

    return (
        <div className={`${CLASS_NAME}_WorkPostCardDetailFooter`}>
            <DownloadActions
                functionId={companyFunction.id}
                target={DownloadButton}
                placement="top"
            />
        </div>
    );
}
