import { IMedicalCenter, IEmployee } from '../admin/employee';
import { ICompany, ICompanyCodeWithShowFullFamilyPayload } from '../admin/company';
import { IExaminationReason, IPlannedMedicalExamination } from './medicalExaminations';
import { IAddress } from '../general/address';

interface ITimeslotEmployee {
    id: number;
    employeeId: number;
}

export interface IAddTimeslotPayload {
    remarks: string;
    employee: ITimeslotEmployee;
    planningEntityId: number;
    examinationReason: {
        id: number;
    };
    timeCellId: number;
    company: Pick<ICompany, 'companyCode' | 'id'>;
}

export interface IAddTimeslotByPlanningRecordIdPayload {
    planningEntityId: number;
    planningRecordId: number;
    employee: ITimeslotEmployee;
    timeCellId: number;
    remarks: string;
    company: Pick<ICompany, 'companyCode' | 'id'>;
}

export interface IAddTimeslotSuccessPayload {
    planningId: number;
}

interface IUpdateTimeslotBasePayload
extends Pick<IAddTimeslotPayload, 'timeCellId' | 'company' | 'examinationReason' | 'remarks'> {}

export interface IReplaceEmployeeUpdateTimeslotPayload extends IUpdateTimeslotBasePayload {
    employee: ITimeslotEmployee;
    planningEntityId: number;
    planningRecordId: number; // of the new employee if he/she has a planning record
}

export interface IMoveUpdateTimeslotPayload extends IUpdateTimeslotBasePayload {
    newTimeCellId: number;
}

export type TUpdateTimeslotPayload = IReplaceEmployeeUpdateTimeslotPayload | IMoveUpdateTimeslotPayload;

export interface IUpdateTimeslotSuccessPayload {
    planningId: number;
}

export interface IRemoveTimeslotPayload {
    cancelDate: string;
    timeCellId: number;
    employee: {
        id: number;
    };
}

export interface IAutoPlanEmployeePayload {
    company: Pick<ICompany, 'companyCode'>;
    employee: Pick<IEmployee, 'id'>;
    startDate: string;
    startTime: string;
    endTime: string;
    planningRecordId?: number;
    oldTimeCellId?: number;
    locationId?: number;
    examinationReason: Pick<IExaminationReason, 'id'>;
}

export interface ITimeCell {
    company: {
        name: string;
        companyCode: string;
    };
    doctor: string;
    doctorId: string;
    duration: number;
    id: number;
    medicalCenter: Pick<IMedicalCenter, 'address' | 'id' | 'name'>;
    planningEntityId: number;
    time: string;
    reserved: boolean;
}

export interface ISelectedTimeCell extends ITimeCell {
    duration: number;
}

export interface IAutoPlanEmployeeSuccessPayload {
    employee: {
        id: number;
        email?: string;
    };
    timeSlotId: string;
    errorMessage: string;
    timeCell: ITimeCell;
    planningRecordId: number;
    startDate?: string;
    startTime?: string;
    endTime?: string;
    locationId?: number;
    duration?: number;
    examinationReasonId: number;
}

// Drag and Drop Config
export enum DraggablePrefix {
    Employee = 'draggable-employee',
    PlannedExamination = 'draggable-plannedexamination',
}

export enum DroppablePrefix {
    Employees = 'droppable-employees',
    FreeTimeslot = 'droppable-freetimeslot',
    PlannedExamination = 'droppable-plannedexamination',
}

export interface IDroppableFreeTimeslotData {
    timeCell: ITimeCell;
    minutesAvailableAfterTimeslot: number;
    amountOfFreeTimeslotsWithSameTime: number;
}

export interface IFetchBufferzoneTimeslotsPayload extends ICompanyCodeWithShowFullFamilyPayload {
    medicalCenterId: number;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    duration: number;
    planningEntityId: number;
}

export interface IBufferzoneTimeslots {
    freeTimeslots: ITimeCell[];
    plannedExaminations: IPlannedMedicalExamination[];
}

export interface IFetchTimeCellPlanningConfigurationPayload {
    planningEntityId: number;
}

export interface ITimeCellPlanningConfiguration {
    startTime: string;
    endTime: string;
    address: IAddress;
}
