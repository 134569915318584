import React from 'react';
import CollapsibleDetailTrigger from './CollapsibleDetailTrigger';
import { IAsyncFetchState } from '../../../../../../../../models/general/redux';
import { IEmployeeDetails } from '../../../../../../../../models/admin/employee';
import { IPlannedMedicalExamination } from '../../../../../../../../models/interventions/medicalExaminations';
import Translate from '../../../../../../../common/Translate';
import TinyLoader from '../../../../../../../common/waiting/TinyLoader';
import CollapsibleItem from '../../../../../../../common/widget/CollapsibleItem';
import LabelInfo from '../../../../../../../common/widget/LabelInfo';
import { formatAddress } from '../../../../../../../../utils/formatting/formatAddress';
import { formatDateForDisplay, formatDateInLongFormat } from '../../../../../../../../utils/formatting/formatDate';
import { formatPersonName } from '../../../../../../../../utils/formatting/formatPerson';
import { formatTimeOfDateForDisplay } from '../../../../../../../../utils/formatting/formatTime';

const TRANSLATION_PREFIX =
    'interventions.medical_examinations.new.steps.select_timeslot.planned_examination_dialog.general';

const GeneralTrigger = <CollapsibleDetailTrigger titleTranslationKey={getPrefixedTranslation('title')} />;

interface IPublicPorps {
    baseClassName: string;
    plannedExamination: IPlannedMedicalExamination;
    employeeDetails: IAsyncFetchState<IEmployeeDetails>;
}

export default function PersonalDetails(props: IPublicPorps) {
    const { baseClassName, plannedExamination } = props;
    const { data: details, ...asyncInfo } = props.employeeDetails;

    return (
        <CollapsibleItem trigger={GeneralTrigger} initialOpen={true}>
            <h6><Translate msg={getPrefixedTranslation('personal.title')}/></h6>
            <LabelInfo
                labelKey={getPrefixedTranslation('personal.name')}
                info={(
                    <TinyLoader asyncInfoSelector={asyncInfo}>
                        {details && formatPersonName(details)}
                    </TinyLoader>
                )}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('personal.birthdate')}
                info={(
                    <TinyLoader asyncInfoSelector={asyncInfo}>
                        {details && formatDateForDisplay(details.birthDate)}
                    </TinyLoader>
                )}
            />
            <LabelInfo
                labelKey={getPrefixedTranslation('personal.function')}
                info={(
                    <TinyLoader asyncInfoSelector={asyncInfo}>
                        {details && details.function.description}
                    </TinyLoader>
                )}
            />

            <h6><Translate msg={getPrefixedTranslation('location')}/></h6>
            <div>{plannedExamination.medicalCenter.name}</div>
            <div className={`${baseClassName}__address`}>
                {formatAddress(plannedExamination.medicalCenter.address)}
            </div>

            <h6><Translate msg={getPrefixedTranslation('timeslot')}/></h6>
            {/* eslint-disable-next-line max-len */}
            <div>{formatDateInLongFormat(plannedExamination.time)}, {formatTimeOfDateForDisplay(plannedExamination.time)}</div>
        </CollapsibleItem>
    );
}

function getPrefixedTranslation(suffix: string) {
    return `${TRANSLATION_PREFIX}.${suffix}`;
}
