import { createEpic } from '../../index';
import {
    fetchMedicalExaminationsToPlanSucceeded,
    fetchMedicalExaminationsToPlanFailed,
    fetchExaminationReasons,
    navigateToPlanMedicalExaminationWizardStep,
    resetPlanMedicalExaminationWizardEntity,
    createPlanMedicalExaminationWizardEntity,
    skipToPlanMedicalExaminationWizardStepActions,
    fetchMedicalExaminationsToPlan,
} from '../actions';
import {
    getSelectedCompanySeat,
    getSelectedSeatCompanyCode,
} from '../../company/selected/selectors';
import ROUTE_KEYS from '../../../routeKeys';
import { FETCH_MEDICAL_EXAMINATIONS_TO_PLAN, SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP } from '../types';
import {
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    ISkipToPlanMedicalExaminationWizardStepPayload,
    TPlanMedicalExaminationWizardEntity,
    IPlanMedicalExaminationSingleEmployeeBaseEntity,
} from '../../../models/interventions/medicalExaminations';
import { areMedicalExaminationsToPlanAvailable } from '../selectors';
import { getLocationState } from '../../location/selectors';
import { getPlanMedicalExaminatonWizardSteps } from '../../../config/navigation/wizardStepsMap';
import { getRouteKeysThatBelongToGroup } from '../../../routes';
import { ROUTE_GROUP } from '../../../config/routeGroup.config';
import { createNotFoundError } from '../../../utils/api/error/createNotFoundError';

const ACTION_TYPES_THAT_FETCH_TO_PLAN_EXAMINATIONS_IF_NOT_AVAILABLE_YET =
    getRouteKeysThatBelongToGroup(ROUTE_GROUP.TO_PLAN_EXAMINATIONS_FETCH_IF_NOT_AVAILABLE);

// fetchMedicalExaminationsToPlanEpic
createEpic({
    onActionType: [
        ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN,
        FETCH_MEDICAL_EXAMINATIONS_TO_PLAN,
    ],
    refreshDataIf: ({ getState, action }) => {
        // Always refresh data if not available.
        if (!areMedicalExaminationsToPlanAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type } = getLocationState(getState());
        return type !== action.type;
    },
    async processMultiple({ getState, api }, dispatch, done) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const { isAllSeatsSelected } = getSelectedCompanySeat(state);
            const medicalExaminationsToPlan = await api.interventions.medicalExaminations.fetchToPlan({
                companyCode,
                showFullFamily: isAllSeatsSelected,
            });
            dispatch(fetchMedicalExaminationsToPlanSucceeded(medicalExaminationsToPlan));
            dispatch(fetchExaminationReasons());
        } catch (error) {
            dispatch(fetchMedicalExaminationsToPlanFailed(error));
        }
        done();
    },
    latest: false,
});

// fetchPlannedMedicalExaminationDetailEpicIfNotAvailableEpic
createEpic<{}>({
    onActionType: ACTION_TYPES_THAT_FETCH_TO_PLAN_EXAMINATIONS_IF_NOT_AVAILABLE_YET,
    processFilter: ({ getState }) => {
        return !areMedicalExaminationsToPlanAvailable(getState());
    },
    processReturn() {
        return fetchMedicalExaminationsToPlan();
    },
    latest: false,
});

// skipToPlanMedicalExaminationWizardStepEpic
createEpic<ISkipToPlanMedicalExaminationWizardStepPayload>({
    onActionType: SKIP_TO_PLAN_MEDICAL_EXAMINATION_WIZARD_STEP,
    async processMultiple({ action }, dispatch, done) {
        try {
            const payload = action.payload;
            const wizardPayload = payload.wizardPayload;

            const employee = payload.entity && payload.entity.searchEmployee.selectedEmployee;

            if (!employee) {
                dispatch(skipToPlanMedicalExaminationWizardStepActions.failed(createNotFoundError()));
                return done();
            }

            let entityData: IPlanMedicalExaminationSingleEmployeeBaseEntity = null;

            dispatch(resetPlanMedicalExaminationWizardEntity());
            entityData = {
                ...payload.entity,
            };
            dispatch(createPlanMedicalExaminationWizardEntity(entityData));

            dispatch(navigateToPlanMedicalExaminationWizardStep({
                wizardType: wizardPayload.wizardType as PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
                reason: wizardPayload.reason,
                step: getWizardStepFromPayloadOrGetFirstVisibleStepFromFlow(payload, entityData),
                skipToStep: true,
                resetDataEntity: false,
            }));

            dispatch(skipToPlanMedicalExaminationWizardStepActions.succeeded({}));
        } catch (error) {
            dispatch(skipToPlanMedicalExaminationWizardStepActions.failed(error));
        }
        done();
    },
    latest: false,
});

function getWizardStepFromPayloadOrGetFirstVisibleStepFromFlow(
    payload: ISkipToPlanMedicalExaminationWizardStepPayload,
    entityData: TPlanMedicalExaminationWizardEntity,
) {
    if (payload.wizardPayload.step) {
        return payload.wizardPayload.step;
    }
    const wizardSteps = getPlanMedicalExaminatonWizardSteps(payload.wizardPayload.wizardType).steps;
    const firstVisibleStep = wizardSteps.find((step) => {
        if (typeof step.hide === 'function') {
            return !step.hide(entityData);
        }
        return true;
    });
    return firstVisibleStep && firstVisibleStep.id;
}
