import { createEpic } from '../../index';
import {
    fetchExecutedCompanyVisitsFailed,
    fetchExecutedCompanyVisitsSucceeded,
    fetchPlannedCompanyVisitsSucceeded,
    fetchPlannedCompanyVisitsFailed,
    fetchCompanyVisitDocumentsFailed,
    fetchCompanyVisitDocumentsSucceeded,
    triggerCompanyVisitRequestWizard,
    createCompanyVisitRequestActions,
} from './actions';
import ROUTE_KEYS from '../../../routeKeys';
import {
    getSelectedSeatCompanyCode,
    getSelectedCompanySeat,
} from '../selected/selectors';
import { getQueryParams, getLocationState } from '../../location/selectors';
import {
    IFetchExecutedCompanyVisitsPayload, IExecutedCompanyVisitsFilter,
    IFetchPlannedCompanyVisitsPayload, IPlannedCompanyVisitsFilter,
    IFetchCompanyVisitDocumentsPayload, ICompanyVisitRequestWizardPayload,
    COMPANY_VISITS_REQUEST_WIZARD_STEP_ID,
    ICreateCompanyVisitRequest,
} from '../../../models/interventions/companyVisits';
import {
    DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS, DEFAULT_PLANNED_COMPANY_VISITS_FILTERS,
} from '../../../api/interventions/companyVisits.api';
import { areObjectParamsEqual } from '../../../utils/core/object/diffObjects';
import { FETCH_COMPANY_VISIT_DOCUMENTS, CREATE_COMPANY_VISITS_REQUEST } from './types';
import {
    areExecutedCompanyVisitsAvailable,
    arePlannedCompanyVisitsAvailable,
    getCompanyVisitRequestWizardStepId,
} from './selectors';
import { fetchContacts } from '../info/actions';
import { SubmittedFormActionType } from '../../../config/analytics.config';
import { getRequestCompanyVisitWizardSteps } from '../../../config/navigation/wizardStepsMap';

// fetchExecutedCompanyVisitsEpic
createEpic<IFetchExecutedCompanyVisitsPayload[]>({
    onActionType: ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED,
    refreshDataIf: ({ getState, action }) => {
        if (!areExecutedCompanyVisitsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_EXECUTED_COMPANY_VISITS_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processReturn({ api, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected ? 'true' : 'false';
            const filterFromQuery = getQueryParams(state) as IExecutedCompanyVisitsFilter;

            const result = await api.interventions.companyVisits.fetchExecutedCompanyVisits(
                { companyCode, showFullFamily }, filterFromQuery,
            );
            return fetchExecutedCompanyVisitsSucceeded(result);
        } catch (error) {
            return fetchExecutedCompanyVisitsFailed(error);
        }
    },
    latest: false,
});

// fetchPlannedCompanyVisitsEpic
createEpic<IFetchPlannedCompanyVisitsPayload[]>({
    onActionType: ROUTE_KEYS.R_COMPANY_VISITS_PLANNED,
    refreshDataIf: ({ getState, action }) => {
        if (!arePlannedCompanyVisitsAvailable(getState())) {
            return true;
        }

        // do not refresh if only clientside (query) filtering changed
        const { type, query } = getLocationState(getState());
        const queryWithDefaults = {
            ...DEFAULT_PLANNED_COMPANY_VISITS_FILTERS,
            ...query,
        };
        return type !== action.type
            || !areObjectParamsEqual(queryWithDefaults, action.meta.query, ['startDate', 'endDate']);
    },
    async processReturn({ api, action, getState }) {
        try {
            const state = getState();
            const companyCode = getSelectedSeatCompanyCode(state);
            const showFullFamily = getSelectedCompanySeat(state).isAllSeatsSelected ? 'true' : 'false';
            const filterFromQuery = getQueryParams(state) as IPlannedCompanyVisitsFilter;

            const result = await api.interventions.companyVisits.fetchPlannedCompanyVisits(
                { companyCode, showFullFamily }, filterFromQuery,
            );
            return fetchPlannedCompanyVisitsSucceeded(result);
        } catch (error) {
            return fetchPlannedCompanyVisitsFailed(error);
        }
    },
    latest: false,
});

// fetchCompanyVisitDocumentsEpic
createEpic<IFetchCompanyVisitDocumentsPayload>({
    onActionType: FETCH_COMPANY_VISIT_DOCUMENTS,
    async processReturn({ api, action, getState }) {
        try {
            const documents = await api.interventions.companyVisits.fetchCompanyVisitDocuments(action.payload);

            const files = await api.general.documents.fetchDocuments(
                { ids: documents.map(document => document.id) },
                { routeKeyForErrorLogging: getLocationState(getState()).type },
            );

            return fetchCompanyVisitDocumentsSucceeded(files);
        } catch (error) {
            return fetchCompanyVisitDocumentsFailed(error);
        }
    },
    latest: false,
});

/**
 * If a user does a deep link to a step that is not allowed yet,
 * we will redirect here to the first step of the flow.
 */
// validateCompanyVisitRequestWizardStepIdEpic
createEpic<ICompanyVisitRequestWizardPayload>({
    onActionType: ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW,
    transform: ({ action, getState }, { next }) => {
        // check valid step id
        const requestedStep = action.payload.step;
        const COMPANY_VISITS_REQUEST_WIZARD_STEP_IDS =
            getRequestCompanyVisitWizardSteps().stepIds;
        if (!COMPANY_VISITS_REQUEST_WIZARD_STEP_IDS.includes(requestedStep)) {
            return next(triggerCompanyVisitRequestWizard());
        }

        // check no step skipped
        const currentStep = getCompanyVisitRequestWizardStepId(getState());
        const currentStepIndex = COMPANY_VISITS_REQUEST_WIZARD_STEP_IDS.indexOf(currentStep); // -1 if no current step
        const requestedStepIndex = COMPANY_VISITS_REQUEST_WIZARD_STEP_IDS.indexOf(requestedStep);
        if (requestedStepIndex > currentStepIndex + 1) {
            return next(triggerCompanyVisitRequestWizard());
        }

        // requested step is valid
        return next(action);
    },
    latest: false,
});

// fetchDataDuringCompanyVisitRequestWizardEpic
createEpic<ICompanyVisitRequestWizardPayload>({
    onActionType: ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW,
    async processMultiple({ action }, dispatch, done) {
        const { step } = action.payload;

        if (step === COMPANY_VISITS_REQUEST_WIZARD_STEP_ID.CONTACT) {
            dispatch(fetchContacts());

            return done();
        }

        done();
    },
    latest: false,
});

// createCompanyVisitRequestEpic
createEpic<ICreateCompanyVisitRequest>({
    onActionType: CREATE_COMPANY_VISITS_REQUEST,
    async processReturn({ action, getState, api }) {
        try {
            const companyCode = getSelectedSeatCompanyCode(getState());

            await api.interventions.companyVisits.createCompanyVisitRequest(companyCode, action.payload);

            return createCompanyVisitRequestActions.succeeded(
                {},
                {
                    logFormSubmissionEvent: SubmittedFormActionType.COMPANY_VISIT_REQUESTED,
                },
            );
        } catch (error) {
            return createCompanyVisitRequestActions.failed(error);
        }
    },
    latest: false,
});
