import React from 'react';
import {
    IRenderDetailHeaderProps,
    IRenderDetailContentProps,
} from '../../../common/widget/MasterWithDetail/typings';
import { IPlannedMedicalExamination } from '../../../../models/interventions/medicalExaminations';
import Translate from '../../../common/Translate';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import CollapsibleItem from '../../../common/widget/CollapsibleItem/index';
import ListItem from '../../../common/list/ListItem/index';
import { formatDateInLongFormat } from '../../../../utils/formatting/formatDate';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { formatTimeOfDateForDisplay } from '../../../../utils/formatting/formatTime';
import connect from '../../../../utils/libs/redux/connect';
import {
    IEmployeeDetails, IEmployeeRisksAndResearches,
    IEmployeeRiskAndResearch,
} from '../../../../models/admin/employee';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import Loader from '../../../common/waiting/Loader/index';
import TinyLoader from '../../../common/waiting/TinyLoader';
import {
    getEmployeeRisksAndResearches, getEmployeeRisksAndResearchesAsyncInfo,
    getSelectedEmployeeAsyncInfo, getSelectedEmployee,
} from '../../../../redux/employee/info/selectors';
import {
    getCreateConvocationsAsyncInfo,
} from '../../../../redux/employee/documents/selectors';
import Button from '../../../common/buttons/Button';
import { createConvocationsActions } from '../../../../redux/employee/documents/actions';
import isEmptyObject from '../../../../utils/core/object/isEmptyObject';

const CLASS_NAME = 'MedicalExaminationsPlannedDetailContent';

interface IDetailHeaderPrivateProps {
    employeeDetail: IEmployeeDetails;
}

interface IDetailContentPrivateProps {
    employeeRisksAndResearches: IEmployeeRisksAndResearches;
    createConvocation: (selectedExamination: IPlannedMedicalExamination) => void;
    createConvocationAsyncInfo: IAsyncFieldInfo;
}

const DetailHeaderComp = (props: IDetailHeaderPrivateProps & IRenderDetailHeaderProps<IPlannedMedicalExamination>) => {
    const {
        detailAsyncInfo,
        detailData: selectedExamination,
        employeeDetail,
    } = props;

    return (
        <Loader show={detailAsyncInfo.status === AsyncStatus.Busy}>
            <h1>
                {detailAsyncInfo.error
                    ? <Translate msg="error.title" />
                    : selectedExamination && formatPersonName(selectedExamination)
                }
            </h1>
            <TinyLoader asyncInfoSelector={getSelectedEmployeeAsyncInfo}>
                {(selectedExamination && employeeDetail) &&
                    <p>
                        <strong>{employeeDetail.function.description}</strong><br />
                        {selectedExamination.company.name}
                    </p>
                }
            </TinyLoader>
        </Loader>
    );
};

const DetailContentComp = (
    props: IDetailContentPrivateProps & IRenderDetailContentProps<IPlannedMedicalExamination>,
) => {
    const {
        detailData: selectedExamination,
        detailAsyncInfo,
        employeeRisksAndResearches,
        createConvocation,
        createConvocationAsyncInfo,
    } = props;

    if (!selectedExamination) {
        return null;
    }

    const showLoader = detailAsyncInfo.status === AsyncStatus.Busy ||
        createConvocationAsyncInfo.status === AsyncStatus.Busy;

    return (
        <div className={`${CLASS_NAME}`}>
            <Loader show={showLoader} />
            <CollapsibleItem
                trigger="interventions.medical_examinations.executed.detail.research.title"
                initialOpen
            >
                <ListItem
                    title={<Translate msg="interventions.medical_examinations.executed.detail.research.reason" />}
                    text={selectedExamination.examinationReason.title}
                    arrow={false}
                />
                <ListItem
                    title={<Translate msg="interventions.medical_examinations.executed.detail.research.location" />}
                    text={
                        <span>
                            {selectedExamination.medicalCenter.name}
                            <br />
                            {formatAddress(selectedExamination.medicalCenter.address)}
                        </span>
                    }
                    arrow={false}
                />
                <ListItem
                    title={<Translate msg="interventions.medical_examinations.executed.detail.research.date" />}
                    text={formatDateInLongFormat(selectedExamination.time)}
                    arrow={false}
                />
                <ListItem
                    title={<Translate msg="interventions.medical_examinations.executed.detail.research.start" />}
                    text={formatTimeOfDateForDisplay(selectedExamination.time)}
                    arrow={false}
                />
            </CollapsibleItem>
            <CollapsibleItem
                // eslint-disable-next-line max-len
                trigger="interventions.medical_examinations.planned.detail.risks_and_researches.title"
            >
                <TinyLoader asyncInfoSelector={getEmployeeRisksAndResearchesAsyncInfo}>
                    {!isEmptyObject(employeeRisksAndResearches) ?
                        <>
                            {
                                Object.keys(employeeRisksAndResearches).map((riskCode, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            title={employeeRisksAndResearches[riskCode][0].riskDescription}
                                            text={
                                                // eslint-disable-next-line max-len
                                                employeeRisksAndResearches[riskCode].map((item: IEmployeeRiskAndResearch, index) =>
                                                    item.researchDescription,
                                                )
                                            }
                                            arrow={true}
                                        />
                                    );
                                })
                            }
                        </> :
                        <p>
                            <Translate
                                // eslint-disable-next-line max-len
                                msg="interventions.medical_examinations.planned.detail.risks_and_researches.no_results"
                            />
                        </p>
                    }
                </TinyLoader>
            </CollapsibleItem>
            <div className="button-container">
                <Button
                    id="download_employee_convocation"
                    onClick={() => createConvocation(selectedExamination)}
                    className="DownloadEmployeeConvocationButton"
                    typeName="secondary"
                >
                    <span>
                        <Translate
                            msg={'interventions.medical_examinations.planned.detail.download_invitation'}
                        />
                    </span>
                </Button>
            </div>
        </div>
    );
};

export const DetailContent =
    connect<IDetailContentPrivateProps, IRenderDetailContentProps<IPlannedMedicalExamination>>({
        stateProps: (state) => {
            const employeeRisksAndResearches = getEmployeeRisksAndResearches(state);

            return {
                employeeRisksAndResearches,
                createConvocationAsyncInfo: getCreateConvocationsAsyncInfo(state),
            };
        },
        dispatchProps: (dispatch) => {
            return {
                createConvocation: (selectedExamination: IPlannedMedicalExamination) => {
                    dispatch(createConvocationsActions.trigger(
                        [{
                            employeeId: selectedExamination.employee.id,
                            planningId: selectedExamination.id,
                            downloadOnly: true,
                        }],
                    ));
                },
            };
        },
    })(DetailContentComp);

export const DetailHeader = connect<IDetailHeaderPrivateProps, IRenderDetailHeaderProps<IPlannedMedicalExamination>>({
    stateProps: (state) => {
        const employeeDetail = getSelectedEmployee(state);

        return {
            employeeDetail,
        };
    },
})(DetailHeaderComp);
