import templates from '../appShell/templates';
import { IWizardTemplateProps } from '../appShell/templates/WizardTemplate';
import InterventionsDashboard from './InterventionsDashboard';
import MedicalExaminationsTemplate from './MedicalExaminations/template';
import VaccinationsTemplate from './Vaccinations/index';
import { PlannedMedicalExaminations } from './MedicalExaminations/Planned/index/PlannedMedicalExaminations.component';
import MedicalExaminationsToPlan from './MedicalExaminations/ToPlan';
import PlanMedicalExaminationWizard from './PlanMedicalExamination/PlanMedicalExaminationWizard';
import ExecutedMedicalExaminations from './MedicalExaminations/Executed';
import ExecutedCompanyVisits from './CompanyVisits/Executed';
import CompanyVisitsTemplate from './CompanyVisits/template';
import PlannedCompanyVisits from './CompanyVisits/Planned/index';
import CompanyVisitRequestWizard from './CompanyVisitRequestWizard';
import ExecutedInterventions from './ExecutedInterventions';
import FluVaccinesOrders from './FluVaccinesOrders';
import FluVaccinesOrderWizard from './FluVaccinesOrderWizard';
import InterventionRequestWizard from './InterventionRequestWizard';
import SelectExaminationReason from './PlanMedicalExamination/SelectExaminationReason';
import NoShowsMedicalExaminations from './MedicalExaminations/NoShows';
import AdministeredVaccinations from './Vaccinations/Administered/index';
import ToAdministerVaccinations from './Vaccinations/ToAdminister/index';
import WorkAccidents from './WorkAccidents';
import ROUTE_KEYS from '../../routeKeys';
import { IRoutes } from '../../utils/routing/typings';
import { IState } from '../../redux';
import { getPrestationCodeQueryParam } from '../../redux/intervention/executed/selectors';
import {
    getPlanMedicalExaminationFunnelTypeForAnalytics,
    getPlanMedicalExaminationWizardReason,
    getPlanMedicalExaminationWizardStepConfig,
} from '../../redux/medicalExamination/selectors';
import { getTranslatorDeprecated } from '../../redux/i18n/selectors';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import { ITranslator } from '../../models/general/i18n';
import { IExaminationReason } from '../../models/interventions/medicalExaminations';
import PlanIntervention from './PlanIntervention';
import { getPropertyFromRoutePayload } from '../../redux/location/selectors';
import PolicyAdvice from './PolicyAdvice';
import Bufferzones from './MedicalExaminations/Bufferzones';
import PlanBufferzoneWizard from './PlanBufferzoneWizard';
import {
    SelectBufferzoneOption,
} from './PlanMedicalExamination/SelectBufferzoneOption/SelectBufferzoneOption.component';
import {
    overrideParentRouteKeyByLastDashboardRoute,
    overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
} from '../overrideParentRouteKey';
import { IAccessLevel } from '../../models/auth/authorisation';

export const BASE_ROUTE = '/interventions';
const COMPANY_VISITS_ROUTE = `${BASE_ROUTE}/company-visits`;
const MEDICAL_EXAMINATIONS_ROUTE = `${BASE_ROUTE}/medical-examinations`;
const VACCINES_ROUTE = `${BASE_ROUTE}/vaccines`;
const FLU_VACCINES_ORDERS_ROUTE = `${BASE_ROUTE}/flu-orders`;
const FLU_VACCINES_ORDERS_DETAIL_ROUTE = `${BASE_ROUTE}/flu-orders/:id`;
const REDIRECT_ROUTE_KEY = {
    IF_BUFFERZONE_BUT_INTERIM: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
};

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_INTERVENTIONS]: {
        path: BASE_ROUTE,
        page: {
            key: 'INTERVENTIONS',
            component: InterventionsDashboard,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'interventions',
        requiredAccessLevels: [
            { company: 'R' },
            { employee: 'R' },
            { interventions: 'R' },
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.overview.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_DASHBOARD,
        ],
    },
    [ROUTE_KEYS.R_VACCINATIONS_TO_ADMINISTER]: {
        path: `${VACCINES_ROUTE}/to-administer`,
        page: {
            key: 'VACCINATIONS_TO_ADMINISTER',
            component: ToAdministerVaccinations,
        },
        template: VaccinationsTemplate,
        virtualPage: 'vaccinations_to_administer',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.vaccinations.to_administer.breadcrumbs_label',
        },
        redirectToRouteIfInterim: ROUTE_KEYS.R_VACCINATIONS_ADMINISTERED,
    },
    [ROUTE_KEYS.R_VACCINATIONS_ADMINISTERED]: {
        path: `${VACCINES_ROUTE}/administered`,
        page: {
            key: 'VACCINES_ADMINISTERED',
            component: AdministeredVaccinations,
        },
        template: VaccinationsTemplate,
        virtualPage: 'vaccines_administered',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.vaccinations.administered.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_TO_PLAN]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/to-plan`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_TO_PLAN',
            component: MedicalExaminationsToPlan,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_to_plan',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.to_plan.breadcrumbs_label',
        },
        redirectToRouteIfInterim: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/planned`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_PLANNED',
            component: PlannedMedicalExaminations,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_planned',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.TO_PLAN_EXAMINATIONS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED_DETAIL]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/planned/:examinationId`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
            component: PlannedMedicalExaminations,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_planned_detail',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.planned.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NO_SHOWS]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/no-shows`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_NO_SHOWS',
            component: NoShowsMedicalExaminations,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_no_shows',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.no_shows.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/bufferzones`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_BUFFERZONES',
            component: Bufferzones,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_bufferzones',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.bufferzones.breadcrumbs_label',
        },
        redirectToRouteIfInterim: REDIRECT_ROUTE_KEY.IF_BUFFERZONE_BUT_INTERIM,
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/bufferzones/:planningEntityId`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL',
            component: Bufferzones,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_bufferzones_detail',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.bufferzones.detail.breadcrumbs_label',
        },
        redirectToRouteIfInterim: REDIRECT_ROUTE_KEY.IF_BUFFERZONE_BUT_INTERIM,
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/bufferzones/:planningEntityId/employee/:id`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL',
            component: Bufferzones,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_bufferzones_detail_employee_detail',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.bufferzones.detail.employee_detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE,
        ],
        redirectToRouteIfInterim: REDIRECT_ROUTE_KEY.IF_BUFFERZONE_BUT_INTERIM,
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL]: {
        // eslint-disable-next-line max-len
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/bufferzones/:planningEntityId/employee/:id/medical-examinations/executed/:examinationId`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
            component: Bufferzones,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_bufferzones_detail_medical_examinations_executed_detail',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.medical_examination.executed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EXECUTED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
        redirectToRouteIfInterim: REDIRECT_ROUTE_KEY.IF_BUFFERZONE_BUT_INTERIM,
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL]: {
        // eslint-disable-next-line max-len
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/bufferzones/:planningEntityId/employee/:id/medical-examinations/planned/:examinationId`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
            component: Bufferzones,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_bufferzones_detail_medical_examinations_planned_detail',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.medical_examination.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
        redirectToRouteIfInterim: REDIRECT_ROUTE_KEY.IF_BUFFERZONE_BUT_INTERIM,
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL]: {
        // eslint-disable-next-line max-len
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/bufferzones/:planningEntityId/employee/:id/courses/planned/:coursesOrganizedId`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL',
            component: Bufferzones,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_bufferzones_detail_courses_planned_detail',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
        redirectToRouteIfInterim: REDIRECT_ROUTE_KEY.IF_BUFFERZONE_BUT_INTERIM,
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL]: {
        // eslint-disable-next-line max-len
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/bufferzones/:planningEntityId/employee/:id/courses/followed/:coursesOrganizedId`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL',
            component: Bufferzones,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_bufferzones_detail_courses_followed_detail',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.followed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_BUFFERZONE_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
        redirectToRouteIfInterim: REDIRECT_ROUTE_KEY.IF_BUFFERZONE_BUT_INTERIM,
    },
    [ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/bufferzones/new/:step`,
        page: {
            key: 'PLAN_BUFFERZONE_NEW',
            component: PlanBufferzoneWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'interventions.plan_bufferzone.title',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'plan_bufferzone_new',
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        requiredAccessLevels: [
            { planning: 'W' },
        ],
        redirectToRouteIfInterim: REDIRECT_ROUTE_KEY.IF_BUFFERZONE_BUT_INTERIM,
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/executed`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_EXECUTED',
            component: ExecutedMedicalExaminations,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_executed',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.executed.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/executed/:examinationId`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
            component: ExecutedMedicalExaminations,
        },
        template: MedicalExaminationsTemplate,
        virtualPage: 'medical_examinations_executed_detail',
        requiredAccessLevels: [
            { planning: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.executed.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.EXECUTED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/new/select-reason`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_NEW',
            component: SelectExaminationReason,
        },
        virtualPage: 'medical_examinations_new_select_examination_reason',
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'interventions.medical_examinations.new.title',
            },
        } as IWizardTemplateProps,
        requiredAccessLevels: [
            { planning: 'W' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.new.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/new/select-bufferzones`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES',
            component: SelectBufferzoneOption,
        },
        virtualPage: 'medical_examinations_new_select_bufferzones',
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'interventions.medical_examinations.new_select_bufferzones.wizard_title',
            },
        } as IWizardTemplateProps,
        requiredAccessLevels: [
            { planning: 'W' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.new_select_bufferzones.breadcrumbs_label',
        },
        /* No redirectToRouteIfInterim here because otherwise the related top task would be filtered.
           Instead the conditional rerendering is done in an epic. */
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NEW_WIZARD]: {
        path: `${MEDICAL_EXAMINATIONS_ROUTE}/new/:wizardType/:step/:action?`,
        page: {
            key: 'MEDICAL_EXAMINATIONS_NEW_WIZARD',
            component: PlanMedicalExaminationWizard,
        },
        virtualPage: [
            'medical_examinations_new',
            (state) => getPlanMedicalExaminationFunnelTypeForAnalytics(state),
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        template: templates.WizardTemplate,
        templateProps: {
            getActiveStepConfigSelector: getPlanMedicalExaminationWizardStepConfig,
            wizardTitle: {
                getTitleCreatorCustomInputSelector: getMedExamTitleCreatorCustomInputSelector,
                titleCreator: medExamWizardTitleCreator,
            },
        } as IWizardTemplateProps,
        requiredAccessLevels: [
            { planning: 'W' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.medical_examinations.new.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_COMPANY_VISITS_EXECUTED]: {
        path: `${COMPANY_VISITS_ROUTE}/executed`,
        page: {
            key: 'EXECUTED_COMPANY_VISITS',
            component: ExecutedCompanyVisits,
        },
        template: CompanyVisitsTemplate,
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        virtualPage: 'executed_company_visits',
        breadcrumbsLabel: {
            translationKey: 'interventions.company_visits.breadcrumbs_label',
        },
        overrideParentRouteKey: overrideParentRouteKeyByLastDashboardRoute,
    },
    [ROUTE_KEYS.R_COMPANY_VISITS_PLANNED]: {
        path: `${COMPANY_VISITS_ROUTE}/planned`,
        page: {
            key: 'PLANNED_COMPANY_VISITS',
            component: PlannedCompanyVisits,
        },
        template: CompanyVisitsTemplate,
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        virtualPage: 'planned_company_visits',
        breadcrumbsLabel: {
            translationKey: 'interventions.company_visits.breadcrumbs_label',
        },
        overrideParentRouteKey: overrideParentRouteKeyByLastDashboardRoute,
    },
    [ROUTE_KEYS.R_COMPANY_VISITS_REQUEST_NEW]: {
        path: `${COMPANY_VISITS_ROUTE}/new/:step`,
        page: {
            key: 'COMPANY_VISITS_REQUEST_NEW',
            component: CompanyVisitRequestWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'interventions.company_visits_new.title',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'request_company_visit',
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        requiredAccessLevels: [
            alsoRequirePermissionForContactUpdate({ interventions: 'W' }),
        ],
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_FLU_VACCINES_ORDERS]: {
        path: FLU_VACCINES_ORDERS_ROUTE,
        page: {
            key: 'FLU_ORDERS',
            component: FluVaccinesOrders,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'flu_orders',
        // if the access level 'company R' would get changed, then also check
        // the requiredAccessLevels of the R_INTERVENTIONS route !!
        requiredAccessLevels: [
            { company: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.flu_orders.breadcrumbs_label',
        },
        groups: [ROUTE_GROUP.COMPANY_CONTACTS_FETCH_IF_NOT_AVAILABLE],
    },
    [ROUTE_KEYS.R_FLU_VACCINES_ORDERS_DETAIL]: {
        path: FLU_VACCINES_ORDERS_DETAIL_ROUTE,
        page: {
            key: 'FLU_ORDERS_DETAILS',
            component: FluVaccinesOrders,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'flu_order_detail',
        // if the access level 'company R' would get changed, then also check
        // the requiredAccessLevels of the R_INTERVENTIONS route !!
        requiredAccessLevels: [
            { company: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.flu_orders_detail.breadcrumbs_label',
        },
        groups: [ROUTE_GROUP.COMPANY_CONTACTS_FETCH_IF_NOT_AVAILABLE],
    },
    [ROUTE_KEYS.R_FLU_VACCINES_ORDERS_NEW]: {
        path: `${FLU_VACCINES_ORDERS_ROUTE}/new/:step`,
        page: {
            key: 'FLU_ORDERS_NEW',
            component: FluVaccinesOrderWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'interventions.flu_orders_new.title',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'flu_order_new',
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        requiredAccessLevels: [
            alsoRequirePermissionForContactUpdate({ company: 'W' }),
        ],
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_EXECUTED_INTERVENTIONS]: {
        path: `${BASE_ROUTE}/executed/:prestationCode`,
        page: {
            key: 'EXECUTED_INTERVENTIONS',
            component: ExecutedInterventions,
        },
        template: templates.WithMenuTemplate,
        virtualPage: [
            'executed_interventions',
            (state) => getPropertyFromRoutePayload(state, 'prestationCode'),
        ],
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.executed.breadcrumbs_label',
            translationPlaceholdersSelector: (state: IState) => {
                const translator = getTranslatorDeprecated(state);
                const prestationCode = getPrestationCodeQueryParam(state);

                return {
                    type: translator({
                        msg: `interventions.overview.main.${prestationCode}_interventions`,
                    }),
                };
            },
        },
    },
    [ROUTE_KEYS.R_INTERVENTION_REQUEST_NEW]: {
        path: `${BASE_ROUTE}/new/:step`,
        page: {
            key: 'INTERVENTION_REQUEST_NEW',
            component: InterventionRequestWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'interventions.intervention_request_new.title',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'request_intervention',
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        requiredAccessLevels: [
            alsoRequirePermissionForContactUpdate({ interventions: 'W' }),
        ],
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_PLAN_INTERVENTION]: {
        path: `${BASE_ROUTE}/plan`,
        page: {
            key: 'PLAN_INTERVENTION',
            component: PlanIntervention,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'plan_intervention',
        requiredAccessLevels: [
            { interventions: 'W' },
            { planning: 'W' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.plan_intervention.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_WORK_ACCIDENTS]: {
        path: `${BASE_ROUTE}/work-accidents`,
        page: {
            key: 'WORK_ACCIDENTS',
            component: WorkAccidents,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'work_accidents',
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'interventions.work_accidents.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_POLICY_ADVICE]: {
        path: `${BASE_ROUTE}/policy-advice`,
        page: {
            key: 'POLICY_ADVICE',
            component: PolicyAdvice,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'policy_advice',
        breadcrumbsLabel: {
            translationKey: 'interventions.policy_advice.breadcrumbs_label',
        },
        requiredAccessLevels: [
            { company: 'R' },
        ],
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_FLAT_FEE,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_POLICY_ADVICE_DETAIL]: {
        path: `${BASE_ROUTE}/policy-advice/:id`,
        page: {
            key: 'POLICY_ADVICE_DETAIL',
            component: PolicyAdvice,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'policy_advice_detail',
        breadcrumbsLabel: {
            translationKey: 'interventions.policy_advice_detail.breadcrumbs_label',
        },
        requiredAccessLevels: [
            { company: 'R' },
        ],
        groups: [
            ROUTE_GROUP.ONLY_ALLOWED_IF_ONCE_FLAT_FEE,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
};

export default ROUTES;

interface IMedExamWizardTitleCreatorCustomInput {
    examinationReason: IExaminationReason;
}

function getMedExamTitleCreatorCustomInputSelector(state: IState): IMedExamWizardTitleCreatorCustomInput {
    return {
        examinationReason: getPlanMedicalExaminationWizardReason(state),
    };
}

function medExamWizardTitleCreator(
    routePayload,
    translator: ITranslator,
    customInput: IMedExamWizardTitleCreatorCustomInput,
) {
    return translator({
        msg: 'interventions.medical_examinations.new.title_type_specific',
        placeholders: {
            type: customInput.examinationReason ? customInput.examinationReason.title : '',
        },
    });
}

/**
 * In some wizards (e.g. request-company-visit), when the user selects the contact, he/she can also
 * update that contact info. So for those wizards, besides the permission needed for the request itself,
 * also (AND relation) the company-wright permission is needed to be able to update the contact info.
 * See KZUAT-1528
 */
function alsoRequirePermissionForContactUpdate(accessLevel: Partial<IAccessLevel>): Partial<IAccessLevel> {
    return {
        ...accessLevel,
        company: 'W',
    };
}
