import React, { PureComponent } from 'react';
import clone from 'ramda/src/clone';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, IShouldRenderShowAllButtonProps,
    IRenderSearchContentProps, IRenderFilterContentProps,
    ITransformToActiveFiltersProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../../routeKeys';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import { DEFAULT_PLANNED_COMPANY_VISITS_FILTERS } from '../../../../api/interventions/companyVisits.api';
import ListWithSorting from '../../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import {
    getPlannedCompanyVisitsAsyncInfo, getPlannedCompanyVisits,
} from '../../../../redux/company/visits/selectors';
import { IPlannedCompanyVisit } from '../../../../models/interventions/companyVisits';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { formatCompanyWithCity } from '../../../../utils/formatting/formatCompany';
import { formatPersonName } from '../../../../utils/formatting/formatPerson';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import { ITranslator } from '../../../../models/general/i18n';
import { getPlannedCompanyVisitTimeFormatted } from '../../../../utils/interventions/companyVisits';

const BASE_NAME = 'planned-company-visits';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IColumnNames {
    visitDate: string;
    visitDateSort: string;
    visitDateTime: string;
    visitCode: string;
    company: string;
    visitor: string;
    description: string;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'visitDate',
    sortOrder: SortOrder.Ascending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    visitDate: {
        label: <Translate msg="interventions.company_visits.planned.columns.visit_date" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.visitDateSort,
        percentWidth: 13,
    },
    visitDateSort: {
        hide: true,
        percentWidth: null,
    },
    visitDateTime: {
        label: <Translate msg="interventions.company_visits.planned.columns.visit_date_time" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 12,
    },
    visitCode: {
        label: <Translate msg="interventions.company_visits.planned.columns.visit_code" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 10,
    },
    company: {
        label: <Translate msg="interventions.company_visits.planned.columns.company" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    visitor: {
        label: <Translate msg="interventions.company_visits.planned.columns.visitor" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 15,
    },
    description: {
        label: <Translate msg="interventions.company_visits.planned.columns.description" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
};

export default function PlannedCompanyVisits() {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_COMPANY_VISITS_PLANNED,
                asyncInfoSelector: getPlannedCompanyVisitsAsyncInfo,
                dataSelector: getPlannedCompanyVisits,
                transformData: mapCompanyVisitsToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                    <CompanyVisitsList {...renderProps} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'planned-company-visits',
                    listItemIdExtractor: toListId,
                    mapListRowForExport,
                },
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
}

/**
 * We don't get a unique id from the backend, so we generate one ourselves.
 */
function toListId(companyVisit: IPlannedCompanyVisit) {
    return `${companyVisit.id}_${companyVisit.visitDate}`;
}

function mapCompanyVisitsToListItems(
    masterData: IPlannedCompanyVisit[],
    translator: ITranslator,
): ListItem<IColumnNames>[] {
    return masterData
        .map((companyVisit) => ({
            id: toListId(companyVisit),
            columns: {
                visitDate: formatDateInLongFormat(companyVisit.visitDate),
                visitDateTime: getPlannedCompanyVisitTimeFormatted(companyVisit, translator),
                visitDateSort: companyVisit.visitDate,
                visitCode: companyVisit.id,
                company: formatCompanyWithCity(companyVisit.company.name, companyVisit.city),
                visitor: formatPersonName(companyVisit.employee),
                description: companyVisit.visitKind,
            },
        }));
}

function mapListRowForExport(companyVisit: IPlannedCompanyVisit) {
    const {
        id,
        visitDate,
        company: { name },
        company: { companyCode },
        employee,
        visitKind,
    } = companyVisit;

    return {
        company: {
            companyCode,
            name,
        },
        employee: formatPersonName(employee),
        visitCode: id,
        visitDate,
        visitKind,
    };
}

class CompanyVisitsList extends PureComponent<IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
        } = this.props;

        return (
            <ListWithSorting
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemIds={selectedItemId ? [selectedItemId] : []}
                initialSort={INITIAL_SORT}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                footer={footer}
            />
        );
    }
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.company_visits.planned.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.company_visits.planned.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
    } = renderProps;

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix="interventions.company_visits.planned.filter"
                formRenderProps={formRenderProps}
            />
        </div>
    );
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_PLANNED_COMPANY_VISITS_FILTERS,
        isShowAll,
    } : DEFAULT_PLANNED_COMPANY_VISITS_FILTERS;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.company_visits.planned.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_PLANNED_COMPANY_VISITS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_PLANNED_COMPANY_VISITS_FILTERS.startDate,
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}
