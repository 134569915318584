import isSet from '@snipsonian/core/es/is/isSet';
import { IState } from '../../IState';
import getEntitiesReducerState from '../getEntitiesReducerState';
import { getPropertyFromRoutePayload } from '../../location/selectors';
import { AsyncStatus } from '@snipsonian/redux-features/es/entities/types';

// Document center templates (= standaard documenten)

export const getAsyncTemplates = (state: IState) =>
    getEntitiesReducerState(state).templates;

export const getSelectedTemplatesCategoryId = (state: IState): number => {
    const categoryId = getPropertyFromRoutePayload(state, 'categoryId');
    return isSet(categoryId) ? Number(categoryId) : undefined;
};

export const getSelectedTemplatesCategory = (state: IState) => {
    const categoryId = getSelectedTemplatesCategoryId(state);

    if (!categoryId) {
        return undefined;
    }

    const asyncTemplatesCategories = getAsyncTemplates(state);
    if (!asyncTemplatesCategories
        || asyncTemplatesCategories.fetch.status !== AsyncStatus.Success
        || !asyncTemplatesCategories.data
    ) {
        return undefined;
    }

    return asyncTemplatesCategories.data
        .find((templatesCategory) => templatesCategory.id === categoryId);
};

export const getSelectedTemplatesCategoryTitle = (state: IState) => {
    const selectedCategory = getSelectedTemplatesCategory(state);

    if (!selectedCategory) {
        return '';
    }

    return selectedCategory.title;
};

export const getAsyncTemplatesCategory = (state: IState) =>
    getEntitiesReducerState(state).templatesCategory;
