import React, { PureComponent } from 'react';
import Collapsible from 'react-collapsible';
import './planned-examination-dialog.scss';
import PlannedExaminationDialogDetail from './PlannedExaminationDialogDetail';
import {
    IPlannedMedicalExamination,
} from '../../../../../../../models/interventions/medicalExaminations';
import Dialog from '../../../../../../common/modals/Dialog';
import { IDialogHeaderProps } from '../../../../../../common/modals/Dialog/DialogHeader';
import Icon from '../../../../../../common/icons/Icon';
import Translate from '../../../../../../common/Translate';
import { formatPersonName } from '../../../../../../../utils/formatting/formatPerson';
import classNames from 'classnames';

interface IPublicProps {
    plannedExaminations: IPlannedMedicalExamination[];
    unselectPlannedExaminations: () => void;
}

interface IComponentState {
    activeExaminationKeys: number[];
}

const CLASS_NAME = 'PlannedExaminationDialog';

export default class PlannedExaminationDialog extends PureComponent<IPublicProps, IComponentState> {
    constructor(props: IPublicProps) {
        super(props);

        this.state = {
            activeExaminationKeys: [],
        };

        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.toggleCollapseOfPlannedExamination = this.toggleCollapseOfPlannedExamination.bind(this);
    }

    public render() {
        const { plannedExaminations } = this.props;
        const { activeExaminationKeys } = this.state;

        const show = plannedExaminations.length > 0;

        return (
            <Dialog
                className={classNames(CLASS_NAME, {
                    'detail-open': activeExaminationKeys.length > 0,
                })}
                show={show}
                onCloseIntent={this.onCloseDialog}
                header={this.getDialogHeaderProps()}
                showAtTop={true}
            >
                {plannedExaminations.length === 1 ? (
                    <PlannedExaminationDialogDetail plannedExamination={plannedExaminations[0]} />
                ) : (
                        plannedExaminations.map((plannedExamination, index) => {
                            const trigger = (
                                <>
                                    <h4>{formatPersonName(plannedExamination)}</h4>
                                    <Icon typeName="chevron-down" />
                                </>
                            );

                            return (
                                <Collapsible
                                    key={`planned-examination-${index}`}
                                    trigger={trigger}
                                    classParentString={`${CLASS_NAME}__collapsible`}
                                    transitionTime={300}
                                    easing="ease-out"
                                    handleTriggerClick={() => this.toggleCollapseOfPlannedExamination(index)}
                                    open={activeExaminationKeys.includes(index)}
                                    lazyRender={true}
                                >
                                    <PlannedExaminationDialogDetail plannedExamination={plannedExamination} />
                                </Collapsible>
                            );
                        })
                    )}
            </Dialog>
        );
    }

    private onCloseDialog() {
        this.setState({
            activeExaminationKeys: [],
        });

        this.props.unselectPlannedExaminations();
    }

    private toggleCollapseOfPlannedExamination(index: number) {
        const { activeExaminationKeys } = this.state;
        if (activeExaminationKeys.includes(index)) {
            const itemIndex = activeExaminationKeys.indexOf(index);
            activeExaminationKeys.splice(itemIndex, 1);
        } else {
            activeExaminationKeys.push(index);
        }
        this.setState({
            activeExaminationKeys: activeExaminationKeys.slice(),
        });
    }

    private getDialogHeaderProps(): IDialogHeaderProps {
        const { plannedExaminations } = this.props;

        return {
            color: 'primary',
            children: (
                <h4>
                    {plannedExaminations.length === 1 ? (
                        <span>{formatPersonName(plannedExaminations[0])}</span>
                    ) : (
                            // eslint-disable-next-line max-len
                            <Translate msg={'interventions.medical_examinations.new.steps.select_timeslot.planned_examination_dialog.title'} />
                        )}
                </h4>
            ),
        };
    }
}
