import { get } from '../../utils/api/requestWrapper';
import {
    IFetchTemplatesCategories,
    ITemplatesCategory,
    IFetchTemplatesCategoryDetail,
    ITemplatesCategoryDetail,
} from '../../models/documentCenter/templates';

export const URL = {
    TEMPLATE_CATEGORIES: '/companies/{companyCode}/documents/categories',
    TEMPLATE_CATEGORY_DOCUMENTS: '/companies/{companyCode}/documents/categories/{categoryId}',
};

export function fetchTemplatesCategories({
    companyCode,
}: IFetchTemplatesCategories) {
    return get<ITemplatesCategory[]>({
        url: URL.TEMPLATE_CATEGORIES,
        pathParams: {
            companyCode,
        },
        mapResponse: (response) => response['categories'],
    });
}

export function fetchTemplatesCategoryDocuments({
    companyCode,
    categoryId,
}: IFetchTemplatesCategoryDetail) {
    return get<ITemplatesCategoryDetail>({
        url: URL.TEMPLATE_CATEGORY_DOCUMENTS,
        pathParams: {
            companyCode,
            categoryId,
        },
    });
}
