import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../..';
import { IStepperStepRenderProps } from '../../../../../models/general/stepper';
import MasterWithDetail from '../../../../common/widget/MasterWithDetail';
import PageHeader from '../../../../appShell/PageHeader';
import { WIZARDFLOW_CLASSES } from '../../../../common/navigation/Wizard';
import ROUTE_KEYS from '../../../../../routeKeys';
import { getRoutePayload } from '../../../../../redux/location/selectors';
import { IPlanBufferzoneWizardPayload } from '../../../../../models/interventions/bufferzones';
import { IRenderMasterContentProps } from '../../../../common/widget/MasterWithDetail/typings';
import ListWithSorting from '../../../../common/list/ListWithSorting';
import clone from 'ramda/src/clone';
import { ListColumns, SortType, SortOrder, ISortedColumn, ListItem } from '../../../../../models/general/list';
import Translate from '../../../../common/Translate';
import Checkbox from '../../../../common/input/Checkbox';
import {
    getBufferzonePlannedTimeslots,
    getBufferzoneTimeslotsAsyncInfo,
    getPlanBufferzoneModifiedTimeCellIds,
} from '../../../../../redux/intervention/bufferzones/selectors';
import { IPlannedMedicalExamination } from '../../../../../models/interventions/medicalExaminations';
import { formatPersonName } from '../../../../../utils/formatting/formatPerson';
import { formatTimeOfDateForDisplay } from '../../../../../utils/formatting/formatTime';
import { formatDateForDisplay } from '../../../../../utils/formatting/formatDate';
import { createSelector } from 'reselect';
import ListActionButton from '../../../../common/buttons/ListActionButton';
import {
    mapListRowForExport as mapPlannedMedicalExaminationForExport,
} from '../../../MedicalExaminations/Planned/index/PlannedMedicalExaminations.helper';
import exportListDataToCsv from '../../../../../utils/file/csv/exportListDataToCsv';
import { resetPlanBufferzoneWizardEntity } from '../../../../../redux/intervention/bufferzones/actions';
import { getWizardExitLinkActionMemoized } from '../../../../../redux/ui/history/selectors';
import ConvocationRecipients
    from '../../../PlanMedicalExamination/PlanMedicalExaminationWizard/shared/ConvocationRecipients';

const BASE_NAME = 'PlanBufferzoneOverview';
const TRANSLATION_PREFIX = 'interventions.plan_bufferzone.steps.overview';

interface IPrivateProps {
    currentRoutePayload: IPlanBufferzoneWizardPayload;
    modifiedExamsListItems: ListItem<IColumnNames>[];
    plannedExamsListItems: ListItem<IColumnNames>[];
    plannedExams: IPlannedMedicalExamination[];
    modifiedExams: IPlannedMedicalExamination[];
    finishWizard: () => void;
}

interface IComponentState {
    showOnlyModifiedTimeslots: boolean;
}

class Overview extends Component<IPrivateProps & IStepperStepRenderProps, IComponentState> {
    public constructor(props: IPrivateProps & IStepperStepRenderProps) {
        super(props);

        this.state = {
            showOnlyModifiedTimeslots: false,
        };

        this.toggleView = this.toggleView.bind(this);
        this.onExport = this.onExport.bind(this);
    }

    public render() {
        const { currentRoutePayload, renderStepButtons, modifiedExams, plannedExams, finishWizard } = this.props;
        const { showOnlyModifiedTimeslots } = this.state;

        const disableExport = showOnlyModifiedTimeslots ?
            modifiedExams.length <= 0 : plannedExams.length <= 0;

        return (
            <>
                <PageHeader
                    title={`${TRANSLATION_PREFIX}.title`}
                    text={`${TRANSLATION_PREFIX}.text`}
                />
                <div className={classNames('container', WIZARDFLOW_CLASSES.CONTENT)}>
                    <MasterWithDetail
                        baseName={BASE_NAME}
                        masterConfig={{
                            routeKey: ROUTE_KEYS.R_PLAN_BUFFERZONE_NEW,
                            routePayload: currentRoutePayload,
                            asyncInfoSelector: getBufferzoneTimeslotsAsyncInfo,
                            // ListItems rendered directly in OverviewList
                            dataSelector: () => [],
                            renderContent: (
                                renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[]>,
                            ) => <OverviewList {...this.props} {...renderProps} {...this.state} />,
                        }}
                        headerConfig={{
                            renderActionContentOnTheLeft: () => (
                                <div>
                                    <Checkbox
                                        name="toggle-view"
                                        checked={showOnlyModifiedTimeslots}
                                        onChange={this.toggleView}
                                    >
                                        <Translate msg={`${TRANSLATION_PREFIX}.toggle_view`} />
                                    </Checkbox>
                                </div>
                            ),
                            renderActionContent: () => (
                                <ListActionButton
                                    id="bufferzone-planned-medexams-export"
                                    type="text"
                                    iconTypeName="excel"
                                    translationKey="common.master_with_detail.action.export"
                                    onClick={this.onExport}
                                    disabled={disableExport}
                                />
                            ),
                        }}
                        footerConfig={{
                            renderActionsRight: () =>
                                renderStepButtons({
                                    nextButton: {
                                        alwaysEnabled: true,
                                        onClick: finishWizard,
                                        translationKey: `${TRANSLATION_PREFIX}.finish_planning`,
                                    },
                                }),
                        }}
                    />
                </div>
            </>
        );
    }

    private toggleView(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ showOnlyModifiedTimeslots: e.target.checked });
    }

    private onExport() {
        const { plannedExams, modifiedExams } = this.props;
        const { showOnlyModifiedTimeslots } = this.state;

        const itemsToExport = showOnlyModifiedTimeslots ? modifiedExams : plannedExams;

        const exportItems = itemsToExport.map(mapPlannedMedicalExaminationForExport);

        exportListDataToCsv({
            // eslint-disable-next-line max-len
            baseFilename: `MyMensura-bufferzone-${showOnlyModifiedTimeslots ? 'new' : 'all'}-medical-examinations-export`,
            listData: exportItems,
        });
    }
}

/* List */

interface IColumnNames {
    employee: string;
    company: string;
    location: string;
    date: string;
    dateSort: string;
    time: string;
}

const COLUMNS: ListColumns<IColumnNames> = {
    employee: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.employee`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
    },
    company: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.company`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 22,
    },
    location: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.location`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 28,
    },
    date: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.date`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 16,
        sortValue: (listItem) => listItem.columns.dateSort,
    },
    dateSort: {
        hide: true,
        percentWidth: null,
    },
    time: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.start_hour`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 12,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'time',
    sortOrder: SortOrder.Ascending,
};

type TListProps =
    IRenderMasterContentProps<ListItem<IColumnNames>[]> & IPrivateProps & IStepperStepRenderProps & IComponentState;

class OverviewList extends Component<TListProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TListProps) {
        super(props);
    }

    public render() {
        const {
            footer,
            modifiedExamsListItems,
            showOnlyModifiedTimeslots,
            plannedExamsListItems,
        } = this.props;

        return (
            <ListWithSorting
                name={BASE_NAME}
                initialSort={INITIAL_SORT}
                columns={this.columns}
                items={showOnlyModifiedTimeslots ? modifiedExamsListItems : plannedExamsListItems}
                selectedItemIds={[]}
                footer={
                    <>
                        <ConvocationRecipients />
                        {footer}
                    </>
                }
            />
        );
    }
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            currentRoutePayload: getRoutePayload<IPlanBufferzoneWizardPayload>(state),
            plannedExamsListItems: plannedExaminationsAsListItemsMemoizedSelector(state),
            modifiedExamsListItems: modifiedExaminationsAsListItemsMemoizedSelector(state),
            plannedExams: getBufferzonePlannedTimeslots(state),
            modifiedExams: modifiedExaminationsMemoizedSelector(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            finishWizard: () => {
                dispatch(getWizardExitLinkActionMemoized(getState()));
                dispatch(resetPlanBufferzoneWizardEntity());
            },
        };
    },
})(Overview);

const modifiedExaminationsMemoizedSelector = createSelector(
    getBufferzonePlannedTimeslots,
    getPlanBufferzoneModifiedTimeCellIds,
    (examinations, modifiedTimeCellIds) => {
        return examinations.filter((exam) => modifiedTimeCellIds.includes(exam.timeCell.id));
    },
);

const modifiedExaminationsAsListItemsMemoizedSelector = createSelector(
    getBufferzonePlannedTimeslots,
    getPlanBufferzoneModifiedTimeCellIds,
    (examinations, modifiedTimeCellIds) => {
        const filteredExams = examinations.filter((exam) => modifiedTimeCellIds.includes(exam.timeCell.id));
        return mapBufferzonePlannedSlotsToListItems(filteredExams);
    },
);

const plannedExaminationsAsListItemsMemoizedSelector = createSelector(
    getBufferzonePlannedTimeslots,
    (examinations) => mapBufferzonePlannedSlotsToListItems(examinations),
);

function mapBufferzonePlannedSlotsToListItems(examinations: IPlannedMedicalExamination[]) {
    return examinations.map((exam) => {
        const item: ListItem<IColumnNames> = {
            id: exam.id,
            columns: {
                company: exam.company && exam.company.name,
                date: formatDateForDisplay(exam.time),
                dateSort: exam.time,
                employee: formatPersonName(exam),
                location: exam.medicalCenter.name,
                time: formatTimeOfDateForDisplay(exam.time),
            },
        };
        return item;
    });
}
