import React from 'react';
import './medical-examination.scss';
import classNames from 'classnames';
import { IEventProps } from '../../../TimeGridEvent';
import Icon from '../../../../../icons/Icon';
import { CALENDAR_EVENT_TYPES_CONFIG } from '../../../../../../../config/calendar.config';
import { CalendarEventType } from '../../../../../../../models/ui/calendar';
import { IPlannedMedicalExamination } from '../../../../../../../models/interventions/medicalExaminations';

const CLASS_NAME = 'MedicalExaminationPlanning';

function MedicalExamination(props: IEventProps) {
    const className = classNames(
        'rbc-event',
        CALENDAR_EVENT_TYPES_CONFIG[CalendarEventType.MedicalExamination].cssClassName,
        CLASS_NAME,
        {
            'rbc-selected': props.selected,
        },
    );
    const eventData = (props.event.data || []) as IPlannedMedicalExamination[];

    return (
        <span className={className}>
            <Icon typeName="check" />
            <span>{eventData.length > 1 || props.event.overlappingEventsCount > 0
                ? `(${eventData.length})` : props.title}</span>
        </span>
    );
}

export default MedicalExamination;
