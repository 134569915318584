import { IPlannedCompanyVisit } from '../../models/interventions/companyVisits';
import { ITranslator } from '../../models/general/i18n';
import { formatTimeOfDateForDisplay } from '../formatting/formatTime';

export function getPlannedCompanyVisitTimeFormatted(visit: IPlannedCompanyVisit, translator: ITranslator) {
    const isPeriod = visit.afterNoon !== undefined;

    if (isPeriod) {
        if (visit.afterNoon) {
            return translator('common.calendar.afternoon');
        }
        return translator('common.calendar.morning');
    }

    return formatTimeOfDateForDisplay(visit.visitDate);
}
