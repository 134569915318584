import React, { PureComponent, MouseEvent } from 'react';
import clone from 'ramda/src/clone';
import connect from '../../../../utils/libs/redux/connect';
import { IRenderMasterContentProps } from '../../../common/widget/MasterWithDetail/typings';
import Translate from '../../../common/Translate';
import { SortType, ListColumns, ListItem } from '../../../../models/general/list';
import ListWithSorting from '../../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import { BASE_NAME, IColumnNames } from './common';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import ListActions from '../../../common/list/ListActions';
import {
    getFetchCompanyUsersAsyncInfo,
    getRemoveCompanyUserAsyncInfo,
} from '../../../../redux/company/users/selectors';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';
import { ITranslator } from '../../../../models/general/i18n';
import filterListItems from '../../../../utils/list/filterListItems';
import ListItemActions from '../../../common/list/ListItemActions';
import Icon from '../../../common/icons/Icon';
import ConfirmationDialog from '../../../common/modals/ConfirmationDialog';
import FormError from '../../../common/forms/FormError';
import { getMyUsername } from '../../../../redux/auth/selectors';
import { IRemoveUserAccountPayload } from '../../../../models/user/userAccount';
import { removeCompanyUserActions } from '../../../../redux/company/users/actions';

interface IPrivateProps {
    translator: ITranslator;
    disableSearchInput: boolean;
    removeAsyncInfo: IAsyncFieldInfo;
    myUsername: string;
    removeUser: (payload: IRemoveUserAccountPayload) => void;
    resetRemoveUser: () => void;
}

const COLUMNS: ListColumns<IColumnNames> = {
    username: {
        label: <Translate msg="account.account_settings.manage_users.columns.username" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    email: {
        hide: true,
        percentWidth: null,
    },
    name: {
        label: <Translate msg="account.account_settings.manage_users.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
    accountType: {
        label: <Translate msg="account.account_settings.manage_users.columns.account_type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 40,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
};

type TCompanyUsersListProps = IRenderMasterContentProps<ListItem<IColumnNames>[]>;

interface IComponentState {
    searchValue: string;
    isConfirmDeleteDialogOpen: boolean;
    usernameToDelete: string;
    emailToDelete: string;
}

class CompanyUsersList extends PureComponent<TCompanyUsersListProps & IPrivateProps, IComponentState> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TCompanyUsersListProps & IPrivateProps) {
        super(props);

        this.state = {
            searchValue: '',
            isConfirmDeleteDialogOpen: false,
            usernameToDelete: null,
            emailToDelete: null,
        };

        this.onSearchInput = this.onSearchInput.bind(this);
        this.onDeleteConfirmClicked = this.onDeleteConfirmClicked.bind(this);
        this.onCloseConfirmDeleteDialog = this.onCloseConfirmDeleteDialog.bind(this);

        this.columns.actions.render = this.renderListItemActions.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: listItems,
            selectedItemId,
            footer,
            translator,
            disableSearchInput,
            onItemSelected,
            removeAsyncInfo,
        } = this.props;
        const { searchValue, isConfirmDeleteDialogOpen, usernameToDelete } = this.state;

        const filteredListItems = filterListItems(
            listItems,
            searchValue,
            { columnsConfig: COLUMNS },
        );

        return (
            <>
                <ListActions
                    name={BASE_NAME}
                    withSelectAll={false}
                    withSearch={true}
                    searchPlaceholder={translator('account.account_settings.manage_users.search_placeholder')}
                    onSearchInput={this.onSearchInput}
                    searchDisabled={disableSearchInput}
                />
                <ListWithSorting
                    columns={this.columns}
                    items={filteredListItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    footer={footer}
                    onItemRowClicked={onItemSelected}
                />
                <ConfirmationDialog
                    show={isConfirmDeleteDialogOpen}
                    showSuccess={removeAsyncInfo.status === AsyncStatus.Success}
                    onConfirm={this.onDeleteConfirmClicked}
                    onCancel={this.onCloseConfirmDeleteDialog}
                    onSuccess={this.onCloseConfirmDeleteDialog}
                    showLoader={removeAsyncInfo.status === AsyncStatus.Busy}
                    type="error"
                    header={translator({
                        msg: 'account.account_settings.manage_users.remove.confirm_delete.title',
                        placeholders: {
                            username: usernameToDelete,
                        },
                    })}
                    successHeader={translator({
                        msg: 'account.account_settings.manage_users.remove.confirm_delete_success.title',
                        placeholders: {
                            username: usernameToDelete,
                        },
                    })}
                >
                    <p>
                        <Translate
                            msg="account.account_settings.manage_users.remove.confirm_delete.subtitle"
                        />
                    </p>
                    {removeAsyncInfo.error &&
                        <FormError error={removeAsyncInfo.error} />
                    }
                </ConfirmationDialog>
            </>
        );
    }

    private onSearchInput(searchValue: string) {
        this.setState({
            searchValue,
        });
    }

    private renderListItemActions(listItem: ListItem<IColumnNames>) {
        const username = listItem.id as string;
        if (this.props.myUsername === username) {
            return <span />;
        }

        const email = listItem.columns.email as string;
        return (
            <ListItemActions>
                <Icon circle typeName="bin" onClick={(e) => this.onDelete(e, username, email)} />
            </ListItemActions>
        );
    }

    private onDeleteConfirmClicked() {
        this.props.removeUser({
            email: this.state.emailToDelete,
        });
    }

    private onCloseConfirmDeleteDialog() {
        this.props.resetRemoveUser();
        this.setState({
            isConfirmDeleteDialogOpen: false,
        });
    }

    private onDelete(e: MouseEvent<HTMLElement>, username: string, email?: string) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isConfirmDeleteDialogOpen: true,
            usernameToDelete: username,
            emailToDelete: email || username,
        });
    }
}

export default connect<IPrivateProps, TCompanyUsersListProps>({
    stateProps: (state) => ({
        translator: getTranslatorDeprecated(state),
        disableSearchInput: getFetchCompanyUsersAsyncInfo(state).status === AsyncStatus.Busy,
        removeAsyncInfo: getRemoveCompanyUserAsyncInfo(state),
        myUsername: getMyUsername(state),
    }),
    dispatchProps: (dispatch) => ({
        removeUser: (payload: IRemoveUserAccountPayload) => {
            dispatch(removeCompanyUserActions.trigger(payload));
        },
        resetRemoveUser: () => {
            dispatch(removeCompanyUserActions.reset({}));
        },
    }),
})(CompanyUsersList);
