import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './select-free-timeslot.scss';
import FilterableCalendar from '../../../../../common/widget/FilterableCalendar';
import PageHeader from '../../../../../appShell/PageHeader';
import { connect } from '../../../../..';
import {
    getPlanMedicalExaminationWizardEntity,
    getMedicalExaminationToPlanInfoByEmployeeId,
    getMedicalExaminationsToPlanAsyncInfo,
    getPlanMedicalExaminationWizardReason,
    getCompanyMedicalCenterTimeslotsAsyncInfo,
    getPlannedMedicalExaminationsAsyncInfo,
    getDefaultSelectedMedicalCenterId,
    getConcatenatedPlannedMedicalExaminationsAsCalendarEvents,
    getConcatenatedCompanyMedicalCenterTimeslotsAsCalendarEvents,
    getPlanMedicalExaminationWizardType,
    dateRangeToFetchMemoizedSelector,
    getBufferzonesWithinPlanningCalendarRangeMemoized,
} from '../../../../../../redux/medicalExamination/selectors';
import {
    IFetchMedicalCenterTimeslotsPayload,
    IMedicalExaminationToPlan,
    IPlannedMedicalExaminationsFilter,
    IPlannedMedicalExamination,
    IPlanMedicalExaminationBaseEntity,
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
} from '../../../../../../models/interventions/medicalExaminations';
import { formatPersonName } from '../../../../../../utils/formatting/formatPerson';
import { getSelectedEmployeeAsyncInfo, getSelectedEmployee } from '../../../../../../redux/employee/info/selectors';
import {
    getFetchCompanyMedicalCentersAsyncInfo,
    getCompanyMedicalCenters,
    getFetchCompanyBufferzonesAsyncInfo,
    getCompanyBufferzonesForMedicalCenterId,
} from '../../../../../../redux/company/info/selectors';
import { IState, NO_RERENDER } from '../../../../../../redux';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../../../models/general/redux';
import {
    updatePlanMedicalExaminationWizardEntity,
    fetchCompanyMedicalCenterTimeslotsActions,
    fetchPlannedMedicalExaminationsActions,
} from '../../../../../../redux/medicalExamination/actions';
import ErrorPlaceholder from '../../../../../common/error/ErrorPlaceholder';
import { isInThePast } from '../../../../../../utils/core/date/isInThePast';
import {
    formatDateForBackend, formatDateForBackendFull,
} from '../../../../../../utils/formatting/formatDate';
import { ITimeCell } from '../../../../../../models/interventions/timeslots';
import {
    now, hoursOffsetFromNow, monthOffsetFromDate,
} from '../../../../../../utils/core/date/getSpecificDate';
import { ICalendarEvent, CalendarEventType } from '../../../../../../models/ui/calendar';
import Toolbar from './Toolbar';
import HeaderItem from './HeaderItem';
import PlannedExaminationDialog from './PlannedExaminationDialog';
import { IEmployee } from '../../../../../../models/admin/employee';
import { DEFAULT_MEDICAL_EXAMINATION_DURATION } from '../../../../../../config/planning.config';
import { BASE_CLASS_NAME } from './common';
import Legend from '../../../../../common/widget/Legend';
import { ColorHex } from '../../../../../../models/general/colors';
import Translate from '../../../../../common/Translate';
import BufferzoneDialog from './BufferzoneDialog';
import { ICompanyBufferzone } from '../../../../../../models/admin/company';
import { isDateBetweenInclusive } from '../../../../../../utils/core/date/isDateBetween';
import { NR_OF_HOURS_BEFORE_EXAM_ALLOWED } from '../../../../../../config/medicalExamination.config';
import Button from '../../../../../common/buttons/Button';
import RequestTimeslotDialog from './RequestTimeslotDialog';

const TRANSLATION_PREFIX = 'interventions.medical_examinations.new.steps.select_timeslot';
const OFFSET_UPCOMING_BUFFERZONES_IN_MONTHS = 6;

interface ISelectFreeTimeslotProps {
    selectedEmployee: Pick<IEmployee, 'id' | 'firstName' | 'name'>;
    onTimeslotSelected?: (timeslot: ITimeCell) => void;
    hideDatepicker?: boolean;
}

interface IPrivateProps {
    isEmployeeDataAvailable: boolean;
    employeeName: string;
    selectedMedicalCenterId: number;
    selectedMedicalCenterHasCabinets: boolean;
    selectedMedicalCenterCode: string;
    selectedDate: string;
    selectedExaminationReasonId: number;
    selectedPlannedExaminations: IPlannedMedicalExamination[];
    unselectPlannedExaminations: () => void;
    updateWizardData: (values: Partial<IPlanMedicalExaminationBaseEntity>) => void;
    fetchEmployeeDetailsAsyncInfo: IAsyncFieldInfo;
    fetchMedicalExaminationsToPlanAsyncInfo: IAsyncFieldInfo;
    timeslotsAsyncInfo: IAsyncFieldInfo;
    fetchMedicalCentersAsyncInfo: IAsyncFieldInfo;
    fetchBufferzonesAsyncInfo: IAsyncFieldInfo;
    fetchTimeslots: (payload: IFetchMedicalCenterTimeslotsPayload) => void;
    extraToPlanInfo: IMedicalExaminationToPlan;
    selectedEventId: string;
    fetchPlannedExaminations: (payload: IPlannedMedicalExaminationsFilter) => void;
    resetPlannedExaminations: () => void;
    selectPlannedExaminations: (plannedExaminationsToSelect: IPlannedMedicalExamination[]) => void;
    dateRangeToFetch: {
        start: string;
        end: string;
    };
    minDate: string;
    maxDate: string;
    titleTranslationKeySuffix: 'title_move' | 'title' | 'title_replan';
    bufferzones: ICompanyBufferzone[];
}

interface IComponentState {
    hasStartedFetchingTimeslotsData: boolean;
    isBufferzoneDialogOpen: boolean;
    isRequestTimeslotOpen: boolean;
}

class SelectFreeTimeslot extends PureComponent<ISelectFreeTimeslotProps & IPrivateProps, IComponentState> {
    private previousAsyncFetchError: IAsyncFieldInfo = null;

    constructor(props: ISelectFreeTimeslotProps & IPrivateProps) {
        super(props);

        this.state = {
            hasStartedFetchingTimeslotsData: false,
            isBufferzoneDialogOpen: false,
            isRequestTimeslotOpen: false,
        };

        this.getAsyncInfo = this.getAsyncInfo.bind(this);
        this.dataSelector = this.dataSelector.bind(this);
        this.onSelectedDateChanged = this.onSelectedDateChanged.bind(this);
        this.onEventSelected = this.onEventSelected.bind(this);
        this.renderLegend = this.renderLegend.bind(this);
        this.renderToolbar = this.renderToolbar.bind(this);
        this.onCloseBufferzonesDialog = this.onCloseBufferzonesDialog.bind(this);
        this.onOpenBufferzonesDialog = this.onOpenBufferzonesDialog.bind(this);
        this.onOpenRequestTimeslotDialog = this.onOpenRequestTimeslotDialog.bind(this);
        this.onCloseRequestTimeslotDialog = this.onCloseRequestTimeslotDialog.bind(this);
    }

    public render() {
        const {
            employeeName, fetchEmployeeDetailsAsyncInfo, fetchMedicalExaminationsToPlanAsyncInfo,
            selectedDate, selectedEventId, minDate, maxDate, titleTranslationKeySuffix, hideDatepicker,
            unselectPlannedExaminations, selectedPlannedExaminations, timeslotsAsyncInfo,
        } = this.props;

        const { isBufferzoneDialogOpen, isRequestTimeslotOpen } = this.state;

        const titleTranslationKey =
            `${TRANSLATION_PREFIX}.${titleTranslationKeySuffix}`;

        return (
            <>
                <PageHeader
                    title={titleTranslationKey}
                    titlePlaceholders={{
                        name: employeeName,
                    }}
                />
                <div className={classNames('container', BASE_CLASS_NAME)}>
                    {fetchEmployeeDetailsAsyncInfo.error && (
                        <>
                            <ErrorPlaceholder apiError={fetchEmployeeDetailsAsyncInfo.error} />
                            <br />
                        </>
                    )}
                    {fetchMedicalExaminationsToPlanAsyncInfo.error &&
                        <ErrorPlaceholder apiError={fetchMedicalExaminationsToPlanAsyncInfo.error} />}
                    <FilterableCalendar
                        asyncInfoSelector={this.getAsyncInfo}
                        dataSelector={this.dataSelector}
                        selectedDate={selectedDate}
                        selectedEventId={selectedEventId}
                        minDate={minDate}
                        maxDate={maxDate}
                        hideTodayButton={true}
                        hideViewToggle={true}
                        hideCurrentTimeIndicator={true}
                        hideAllDaySection={true}
                        toolbarChildrenComponent={this.renderToolbar}
                        headerItemComponent={HeaderItem}
                        onSelectedDateChanged={this.onSelectedDateChanged}
                        onEventSelected={this.onEventSelected}
                        hideDatepicker={hideDatepicker}
                        highlightDatepickerType="free-timeslots"
                        renderLegend={this.renderLegend}
                    />

                    <PlannedExaminationDialog
                        plannedExaminations={selectedPlannedExaminations}
                        unselectPlannedExaminations={unselectPlannedExaminations}
                    />
                    <BufferzoneDialog
                        show={isBufferzoneDialogOpen}
                        showLoader={timeslotsAsyncInfo.status === AsyncStatus.Busy}
                        onClose={this.onCloseBufferzonesDialog}
                    />
                    <RequestTimeslotDialog
                        show={isRequestTimeslotOpen}
                        onClose={this.onCloseRequestTimeslotDialog}
                    />
                </div>
            </>
        );
    }

    public componentDidMount() {
        this.resetDataWhichPotentiallyDoesNotMatchTheCurrentMedicalCenter();
    }

    public componentDidUpdate(prevProps: ISelectFreeTimeslotProps & IPrivateProps) {
        const {
            selectedMedicalCenterId,
            fetchMedicalExaminationsToPlanAsyncInfo,
            dateRangeToFetch, selectedDate, timeslotsAsyncInfo,
            fetchEmployeeDetailsAsyncInfo, fetchMedicalCentersAsyncInfo,
            fetchBufferzonesAsyncInfo, bufferzones, minDate,
        } = this.props;

        const hasFinishedFetchingToPlan =
            prevProps.fetchMedicalExaminationsToPlanAsyncInfo.status === AsyncStatus.Busy &&
            fetchMedicalExaminationsToPlanAsyncInfo.status === AsyncStatus.Success;
        const hasFinishedFetchingEmployeeDetails =
            (prevProps.fetchEmployeeDetailsAsyncInfo.status === AsyncStatus.Busy ||
                prevProps.fetchEmployeeDetailsAsyncInfo.status === AsyncStatus.Initial) &&
            fetchEmployeeDetailsAsyncInfo.status === AsyncStatus.Success;
        const hasFinishedFetchingMedicalCenters =
            prevProps.fetchMedicalCentersAsyncInfo.status === AsyncStatus.Busy &&
            fetchMedicalCentersAsyncInfo.status === AsyncStatus.Success;

        const hasFinishedFetchingBufferzones =
            prevProps.fetchBufferzonesAsyncInfo.status === AsyncStatus.Busy &&
            fetchBufferzonesAsyncInfo.status === AsyncStatus.Success;

        // Wait for timeCells until bufferzones call is started
        const hasStartedFetchingBufferzonesData =
            (prevProps.fetchBufferzonesAsyncInfo.status === AsyncStatus.Initial &&
                fetchBufferzonesAsyncInfo.status === AsyncStatus.Busy) ||
            (prevProps.fetchBufferzonesAsyncInfo.status === AsyncStatus.Busy &&
                fetchBufferzonesAsyncInfo.status === AsyncStatus.Success);

        // Only auto open if there are any bufferzones in the next 'n' months
        const hasBufferZoneInUpcomingMonths = bufferzones
            .some((item) => isDateBetweenInclusive(
                new Date(item.date),
                new Date(minDate),
                monthOffsetFromDate(new Date(minDate), OFFSET_UPCOMING_BUFFERZONES_IN_MONTHS),
            ));

        if (hasFinishedFetchingBufferzones && bufferzones.length > 0 && hasBufferZoneInUpcomingMonths) {
            this.setState({ isBufferzoneDialogOpen: true });
        }

        if (
            hasFinishedFetchingToPlan ||
            hasFinishedFetchingEmployeeDetails ||
            hasFinishedFetchingMedicalCenters ||
            !prevProps.selectedDate && selectedDate ||
            dateRangeToFetch.start !== prevProps.dateRangeToFetch.start ||
            dateRangeToFetch.end !== prevProps.dateRangeToFetch.end ||
            selectedMedicalCenterId !== prevProps.selectedMedicalCenterId ||
            (
                selectedDate && timeslotsAsyncInfo.status === AsyncStatus.Initial &&
                !this.state.hasStartedFetchingTimeslotsData
            ) ||
            (hasStartedFetchingBufferzonesData && !this.state.hasStartedFetchingTimeslotsData)
        ) {
            this.setSelectedDateOrFetchTimeslots();
        }
    }

    private renderToolbar() {
        return (
            <Toolbar onOpenBufferzones={this.onOpenBufferzonesDialog} />
        );
    }

    private onOpenBufferzonesDialog() {
        this.setState({ isBufferzoneDialogOpen: true });
    }

    private onCloseBufferzonesDialog() {
        this.setState({ isBufferzoneDialogOpen: false });
    }

    private onOpenRequestTimeslotDialog() {
        this.setState({ isRequestTimeslotOpen: true });
    }

    private onCloseRequestTimeslotDialog() {
        this.setState({ isRequestTimeslotOpen: false });
    }

    private setSelectedDateOrFetchTimeslots() {
        const {
            selectedMedicalCenterId, fetchTimeslots, selectedDate,
            extraToPlanInfo, updateWizardData, fetchMedicalExaminationsToPlanAsyncInfo,
            fetchEmployeeDetailsAsyncInfo, fetchPlannedExaminations, dateRangeToFetch,
            selectedMedicalCenterHasCabinets, fetchMedicalCentersAsyncInfo,
            isEmployeeDataAvailable, minDate, selectedMedicalCenterCode,
            selectedExaminationReasonId,
        } = this.props;

        if (
            !isEmployeeDataAvailable ||
            fetchEmployeeDetailsAsyncInfo.status !== AsyncStatus.Success ||
            fetchMedicalExaminationsToPlanAsyncInfo.status !== AsyncStatus.Success
        ) {
            return;
        }

        if (!selectedDate) {
            if (
                extraToPlanInfo &&
                !isInThePast(extraToPlanInfo.toBePlannedDate) &&
                !minDate
            ) {
                return updateWizardData({
                    selectTime: {
                        filter: {
                            selectedDate: extraToPlanInfo.toBePlannedDate,
                            selectedMedicalCenterId,
                            dateRangeToFetch,
                        },
                    },
                });
            }
            return updateWizardData({
                selectTime: {
                    filter: {
                        selectedDate: formatDateForBackend(minDate ? minDate : now()),
                        selectedMedicalCenterId,
                        dateRangeToFetch,
                    },
                },
            });
        }

        if (
            !selectedMedicalCenterId ||
            fetchMedicalCentersAsyncInfo.status !== AsyncStatus.Success
        ) {
            return;
        }

        const startDate = dateRangeToFetch.start;
        const endDate = dateRangeToFetch.end;
        fetchPlannedExaminations({
            startDate,
            endDate,
            medicalCenterCode: selectedMedicalCenterCode,
        });
        fetchTimeslots({
            medicalCenterId: selectedMedicalCenterId,
            startDate,
            endDate,
            examinationReasonId: selectedExaminationReasonId,
            hasCabinets: selectedMedicalCenterHasCabinets,
        });
        updateWizardData({
            selectTime: {
                filter: {
                    dateRangeToFetch,
                    selectedMedicalCenterId,
                },
            },
        });

        this.setState({
            hasStartedFetchingTimeslotsData: true,
        });
    }

    private resetDataWhichPotentiallyDoesNotMatchTheCurrentMedicalCenter() {
        this.props.resetPlannedExaminations();
    }

    private getAsyncInfo(state: IState) {
        const fetchMedicalCentersAsyncInfo = getFetchCompanyMedicalCentersAsyncInfo(state);
        const fetchEmployeeDetailsAsyncInfo = getSelectedEmployeeAsyncInfo(state);
        const fetchMedicalExaminationsToPlanAsyncInfo = getMedicalExaminationsToPlanAsyncInfo(state);
        const fetchPlannedMedicalExaminationsAsyncInfo = getPlannedMedicalExaminationsAsyncInfo(state);
        const fetchCompanyBufferzonesAsyncInfo = getFetchCompanyBufferzonesAsyncInfo(state);

        const error = (
            fetchMedicalCentersAsyncInfo.error ||
            fetchMedicalExaminationsToPlanAsyncInfo.error ||
            fetchEmployeeDetailsAsyncInfo.error ||
            fetchPlannedMedicalExaminationsAsyncInfo.error ||
            fetchCompanyBufferzonesAsyncInfo.error
        );
        if (error) {
            if (!this.previousAsyncFetchError || this.previousAsyncFetchError.error !== error) {
                this.previousAsyncFetchError = {
                    status: AsyncStatus.Error,
                    error,
                };
            }
            return this.previousAsyncFetchError;
        }

        if (!this.state.hasStartedFetchingTimeslotsData) {
            return NO_RERENDER.ASYNC_FETCH_BUSY;
        }

        if (fetchMedicalCentersAsyncInfo.status === AsyncStatus.Busy) {
            return fetchMedicalCentersAsyncInfo;
        }
        if (fetchMedicalExaminationsToPlanAsyncInfo.status === AsyncStatus.Busy) {
            return fetchMedicalExaminationsToPlanAsyncInfo;
        }
        if (fetchEmployeeDetailsAsyncInfo.status === AsyncStatus.Busy) {
            return fetchEmployeeDetailsAsyncInfo;
        }
        if (fetchPlannedMedicalExaminationsAsyncInfo.status === AsyncStatus.Busy) {
            return fetchPlannedMedicalExaminationsAsyncInfo;
        }
        if (fetchCompanyBufferzonesAsyncInfo.status === AsyncStatus.Busy) {
            return fetchCompanyBufferzonesAsyncInfo;
        }

        return getCompanyMedicalCenterTimeslotsAsyncInfo(state);
    }

    private dataSelector(state: IState) {
        const { extraToPlanInfo, minDate } = this.props;
        const duration = extraToPlanInfo && extraToPlanInfo.duration;
        return [
            ...getConcatenatedPlannedMedicalExaminationsAsCalendarEvents(state),
            ...getConcatenatedCompanyMedicalCenterTimeslotsAsCalendarEvents(
                state, duration, minDate,
            ),
        ];
    }

    private onSelectedDateChanged(newSelectedDate: string, datesShowInMontview: { start: string, end: string }) {
        const { updateWizardData } = this.props;
        updateWizardData({
            selectTime: {
                filter: {
                    selectedDate: newSelectedDate,
                    dateRangeToFetch: {
                        start: datesShowInMontview.start,
                        end: datesShowInMontview.end,
                    },
                },
            },
        });
    }

    private onEventSelected(event: ICalendarEvent) {
        const { updateWizardData, onTimeslotSelected, extraToPlanInfo } = this.props;
        if (event.type === CalendarEventType.FreeTimeslot) {
            const duration = extraToPlanInfo && extraToPlanInfo.duration
                ? extraToPlanInfo.duration : DEFAULT_MEDICAL_EXAMINATION_DURATION;
            // Take the first timecell
            const timeslotData = (event.data as ITimeCell[]);
            if (Array.isArray(timeslotData) && timeslotData[0]) {
                const timeslot = timeslotData[0];
                updateWizardData({
                    selectTime: {
                        selectedEventId: event.id,
                        selectedTimeslot: {
                            ...timeslot,
                            duration,
                        },
                    },
                });
                if (typeof onTimeslotSelected === 'function') {
                    onTimeslotSelected(timeslot);
                }
            }
        } else {
            this.props.selectPlannedExaminations(event.data as IPlannedMedicalExamination[]);
        }
    }

    private renderLegend() {
        return (
            <>
                <Legend
                    className={`${BASE_CLASS_NAME}__legend`}
                    data={[{
                        color: ColorHex.Orange,
                        label: <Translate
                            msg="interventions.medical_examinations.new.steps.select_timeslot.legend.reserved_slots"
                        />,
                    }, {
                        color: ColorHex.Blue,
                        label: <Translate
                            msg="interventions.medical_examinations.new.steps.select_timeslot.legend.free_slots"
                        />,
                    }, {
                        color: ColorHex.Green,
                        label: <Translate
                            msg="interventions.medical_examinations.new.steps.select_timeslot.legend.selected_week"
                        />,
                    }]}
                />
                <Button
                    id="open-request-timeslot-form"
                    typeName="text"
                    onClick={this.onOpenRequestTimeslotDialog}
                    className={`${BASE_CLASS_NAME}__request-timeslot-button`}
                >
                    <Translate msg={`${TRANSLATION_PREFIX}.request_timeslot.request_button`} />
                </Button>
            </>
        );
    }
}

export default connect<IPrivateProps, ISelectFreeTimeslotProps>({
    statePropsDeprecated: (state, publicProps) => {
        const { selectedEmployee } = publicProps;
        const examinationReason = getPlanMedicalExaminationWizardReason(state);
        const isAutoPlan = getPlanMedicalExaminationWizardType(state) ===
            PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC;
        const wizardEntity =
            getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);

        const selectedMedicalCenterId = (wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.filter.selectedMedicalCenterId) || getDefaultSelectedMedicalCenterId(state);
        const selectedDate = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.filter.selectedDate;
        const selectedEventId = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.selectedEventId;
        const minDate = (wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.minDate) ||
            formatDateForBackendFull(hoursOffsetFromNow(NR_OF_HOURS_BEFORE_EXAM_ALLOWED));
        const maxDate = wizardEntity && wizardEntity.selectTime
            && wizardEntity.selectTime.maxDate;
        const movePlanning = wizardEntity && !!wizardEntity.movePlanning;
        const selectedPlannedExaminations = wizardEntity && wizardEntity.selectedPlannedExaminations;

        const extraToPlanInfo = (wizardEntity && wizardEntity.selectedMedicalExaminationToPlan)
            || (selectedEmployee && getMedicalExaminationToPlanInfoByEmployeeId(
                state,
                { employeeId: selectedEmployee.id, examinationReason },
            ));

        const selectedEmployeeDetails = getSelectedEmployee(state);
        let selectedMedicalCenterData = getCompanyMedicalCenters(state)
            .find((item) => item.id === selectedMedicalCenterId);

        if (!selectedMedicalCenterData && selectedMedicalCenterId) {
            // When selectedMedicalCenterData isn't set, it is probably a cabinet/location which doesn't
            // exist in de list of medical-centers. In this case we try to find the medicalCenter in
            // the list of bufferzones.
            const bufferzonesForMedicalCenter = getCompanyBufferzonesForMedicalCenterId(state, selectedMedicalCenterId);
            const [firstBufferZone] = bufferzonesForMedicalCenter;
            selectedMedicalCenterData = firstBufferZone && firstBufferZone.medicalCenter;
        }

        return {
            isEmployeeDataAvailable: !!(
                selectedEmployee && selectedEmployeeDetails &&
                selectedEmployee.id === selectedEmployeeDetails.id
            ),
            employeeName: selectedEmployee && formatPersonName(selectedEmployee),
            selectedMedicalCenterId,
            selectedMedicalCenterHasCabinets: selectedMedicalCenterData && selectedMedicalCenterData.hasCabinets,
            selectedMedicalCenterCode: selectedMedicalCenterData && selectedMedicalCenterData.code,
            selectedExaminationReasonId: (extraToPlanInfo && extraToPlanInfo.examinationReason.id)
                || examinationReason && examinationReason.id,
            selectedDate,
            selectedPlannedExaminations: selectedPlannedExaminations || NO_RERENDER.EMPTY_LIST,
            fetchEmployeeDetailsAsyncInfo: getSelectedEmployeeAsyncInfo(state),
            fetchMedicalExaminationsToPlanAsyncInfo: getMedicalExaminationsToPlanAsyncInfo(state),
            fetchMedicalCentersAsyncInfo: getFetchCompanyMedicalCentersAsyncInfo(state),
            timeslotsAsyncInfo: getCompanyMedicalCenterTimeslotsAsyncInfo(state),
            fetchBufferzonesAsyncInfo: getFetchCompanyBufferzonesAsyncInfo(state),
            bufferzones: getBufferzonesWithinPlanningCalendarRangeMemoized(state),
            extraToPlanInfo,
            selectedEventId,
            dateRangeToFetch: dateRangeToFetchMemoizedSelector(state),
            minDate,
            maxDate,
            titleTranslationKeySuffix: movePlanning ? 'title_move' : isAutoPlan ? 'title_replan' : 'title',
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            updateWizardData: (values) => {
                const state = getState();
                const wizardEntity =
                    getPlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>(state);
                const selectTimeData = wizardEntity && wizardEntity.selectTime;
                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>({
                    selectTime: {
                        ...selectTimeData,
                        ...values.selectTime,
                        filter: selectTimeData ? {
                            ...selectTimeData.filter,
                            ...values.selectTime.filter,
                        } : values.selectTime.filter,
                    },
                }));
            },
            fetchTimeslots: (payload: IFetchMedicalCenterTimeslotsPayload) => {
                dispatch(fetchCompanyMedicalCenterTimeslotsActions.trigger(payload));
            },
            fetchPlannedExaminations: (payload: IPlannedMedicalExaminationsFilter) => {
                dispatch(fetchPlannedMedicalExaminationsActions.trigger(payload));
            },
            resetPlannedExaminations: () => {
                dispatch(fetchPlannedMedicalExaminationsActions.reset({}));
            },
            selectPlannedExaminations: (plannedExaminationsToSelect: IPlannedMedicalExamination[]) => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>({
                    selectedPlannedExaminations: plannedExaminationsToSelect,
                }));
            },
            unselectPlannedExaminations: () => {
                dispatch(updatePlanMedicalExaminationWizardEntity<IPlanMedicalExaminationBaseEntity>({
                    selectedPlannedExaminations: [],
                }));
            },
        };
    },
})(SelectFreeTimeslot);
