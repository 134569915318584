enum ROUTE_KEYS {
    // Auth Routes
    R_LOGIN = 'R_LOGIN',
    R_LOGIN_REDIRECT = 'R_LOGIN_REDIRECT',
    R_LOGOUT = 'R_LOGOUT',
    R_REQUEST_ACCOUNT = 'R_REQUEST_ACCOUNT',
    R_NEW_FEATURES_CAROUSEL = 'R_NEW_FEATURES_CAROUSEL',

    // Account routes
    R_COMPANY_SELECTION = 'R_COMPANY_SELECTION',
    R_SEATS_SELECTION = 'R_SEATS_SELECTION',
    R_ACCOUNT_SETTINGS = 'R_ACCOUNT_SETTINGS',
    R_ACCOUNT_SETTINGS_UPDATE_AVATAR = 'R_ACCOUNT_SETTINGS_UPDATE_AVATAR',
    R_ACCOUNT_MANAGE_USERS = 'R_ACCOUNT_MANAGE_USERS',
    R_ACCOUNT_MANAGE_USERS_DETAIL = 'R_ACCOUNT_MANAGE_USERS_DETAIL',
    R_ACCOUNT_MANAGE_USERS_ADD = 'R_ACCOUNT_MANAGE_USERS_ADD',
    R_ACCOUNT_MANAGE_USERS_ADD_INTERNAL = 'R_ACCOUNT_MANAGE_USERS_ADD_INTERNAL',
    R_ACCOUNT_MANAGE_USERS_ADD_EXTERNAL = 'R_ACCOUNT_MANAGE_USERS_ADD_EXTERNAL',
    R_MESSAGE_CENTER = 'R_MESSAGE_CENTER',
    R_MESSAGE_CENTER_DETAIL = 'R_MESSAGE_CENTER_DETAIL',

    // Home Routes
    R_HOME = 'R_HOME',
    R_EDIT_TOP_TASKS = 'R_EDIT_TOP_TASKS',

    // Intervention Routes
    R_INTERVENTIONS = 'R_INTERVENTIONS',
    R_INTERVENTION_REQUEST_NEW = 'R_INTERVENTION_REQUEST_NEW',
    R_EXECUTED_INTERVENTIONS = 'R_EXECUTED_INTERVENTIONS',
    R_VACCINATIONS_ADMINISTERED = 'R_VACCINATIONS_ADMINISTERED',
    R_VACCINATIONS_TO_ADMINISTER = 'R_VACCINATIONS_TO_ADMINISTER',
    R_MEDICAL_EXAMINATIONS_TO_PLAN = 'R_MEDICAL_EXAMINATIONS_TO_PLAN',
    R_MEDICAL_EXAMINATIONS_PLANNED = 'R_MEDICAL_EXAMINATIONS_PLANNED',
    R_MEDICAL_EXAMINATIONS_PLANNED_DETAIL = 'R_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
    R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON = 'R_MEDICAL_EXAMINATIONS_NEW_SELECT_REASON',
    R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES = 'R_MEDICAL_EXAMINATIONS_NEW_SELECT_BUFFERZONES',
    R_MEDICAL_EXAMINATIONS_NEW_WIZARD = 'R_MEDICAL_EXAMINATIONS_NEW_WIZARD',
    R_MEDICAL_EXAMINATIONS_EXECUTED = 'R_MEDICAL_EXAMINATIONS_EXECUTED',
    R_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL = 'R_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
    R_MEDICAL_EXAMINATIONS_NO_SHOWS = 'R_MEDICAL_EXAMINATIONS_NO_SHOWS',
    R_MEDICAL_EXAMINATIONS_BUFFERZONES = 'R_MEDICAL_EXAMINATIONS_BUFFERZONES',
    R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL = 'R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL',
    // eslint-disable-next-line max-len
    R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL = 'R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_EMPLOYEE_DETAIL',
    // eslint-disable-next-line max-len
    R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL = 'R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
    // eslint-disable-next-line max-len
    R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL = 'R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
    // eslint-disable-next-line max-len
    R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL = 'R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_PLANNED_DETAIL',
    // eslint-disable-next-line max-len
    R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL = 'R_MEDICAL_EXAMINATIONS_BUFFERZONES_DETAIL_COURSES_FOLLOWED_DETAIL',
    R_WORK_ACCIDENTS = 'R_WORK_ACCIDENTS',
    R_PLAN_INTERVENTION = 'R_PLAN_INTERVENTION',
    R_POLICY_ADVICE = 'R_POLICY_ADVICE',
    R_POLICY_ADVICE_DETAIL = 'R_POLICY_ADVICE_DETAIL',

    // Administration Routes
    R_ADMINISTRATION = 'R_ADMINISTRATION',
    R_EMPLOYEES = 'R_EMPLOYEES',
    R_EMPLOYEES_ADD_EMAILS = 'R_EMPLOYEES_ADD_EMAILS',
    R_EMPLOYEE_DETAILS = 'R_EMPLOYEE_DETAILS',
    R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL = 'R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
    R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_PLANNED_DETAIL = 'R_EMPLOYEE_DETAILS_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
    R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL = 'R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL',
    R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL_ATTENDANT = 'R_EMPLOYEE_DETAILS_COURSES_FOLLOWED_DETAIL_ATTENDANT',
    R_EMPLOYEE_DETAILS_COURSES_PLANNED_DETAIL = 'R_EMPLOYEE_DETAILS_COURSES_PLANNED_DETAIL',
    R_EMPLOYEES_ADD_WIZARD = 'R_EMPLOYEES_ADD_WIZARD',
    R_COMPANY_FUNCTIONS = 'R_COMPANY_FUNCTIONS',
    R_COMPANY_FUNCTIONS_ADD = 'R_COMPANY_FUNCTIONS_ADD',
    R_COMPANY_FUNCTION_DETAIL = 'R_COMPANY_FUNCTION_DETAIL',
    R_COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL = 'R_COMPANY_FUNCTION_DETAIL_EMPLOYEE_DETAIL',
    // eslint-disable-next-line max-len
    R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL = 'R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
    // eslint-disable-next-line max-len
    R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL = 'R_COMPANY_FUNCTION_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
    R_COMPANY_FUNCTION_DETAIL_COURSES_PLANNED_DETAIL = 'R_COMPANY_FUNCTION_DETAIL_COURSES_PLANNED_DETAIL',
    R_COMPANY_FUNCTION_DETAIL_COURSES_FOLLOWED_DETAIL = 'R_COMPANY_FUNCTION_DETAIL_COURSES_FOLLOWED_DETAIL',
    R_COMPANY_RISKS = 'R_COMPANY_RISKS',
    R_COMPANY_RATES = 'R_COMPANY_RATES',
    R_COMPANY_RATES_PARAMETERS = 'R_COMPANY_RATES_PARAMETERS',
    R_COMPANY_INFO_GENERAL = 'R_COMPANY_INFO_GENERAL',
    R_COMPANY_INFO_SEATS = 'R_COMPANY_INFO_SEATS',
    R_COMPANY_INFO_SEATS_DETAIL = 'R_COMPANY_INFO_SEATS_DETAIL',
    R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL = 'R_COMPANY_INFO_SEATS_DETAIL_CONTACT_DETAIL',
    R_COMPANY_INFO_CONTACTS = 'R_COMPANY_INFO_CONTACTS',
    R_COMPANY_INFO_CONTACT_DETAIL = 'R_COMPANY_INFO_CONTACT_DETAIL',
    R_PREVENTION_UNITS_OVERVIEW = 'R_PREVENTION_UNITS_OVERVIEW',
    R_PREVENTION_UNITS_OVERVIEW_DETAIL = 'R_PREVENTION_UNITS_OVERVIEW_DETAIL',
    R_PREVENTION_UNITS_PREVIOUS_YEAR = 'R_PREVENTION_UNITS_PREVIOUS_YEAR',
    R_PREVENTION_UNITS_PREVIOUS_YEAR_DETAIL = 'R_PREVENTION_UNITS_PREVIOUS_YEAR_DETAIL',
    R_PREVENTION_UNITS_ARCHIVE = 'R_PREVENTION_UNITS_ARCHIVE',
    R_QUESTIONNAIRES_SCREENWORK = 'R_QUESTIONNAIRES_SCREENWORK',
    R_QUESTIONNAIRES_SCREENWORK_DETAIL = 'R_QUESTIONNAIRES_SCREENWORK_DETAIL',
    R_QUESTIONNAIRES_JOB_STUDENTS = 'R_QUESTIONNAIRES_JOB_STUDENTS',
    R_QUESTIONNAIRES_JOB_STUDENTS_DETAIL = 'R_QUESTIONNAIRES_JOB_STUDENTS_DETAIL',
    R_FLAT_FEE_OVERVIEW = 'R_FLAT_FEE_OVERVIEW',
    R_FLAT_FEE_DETAIL = 'R_FLAT_FEE_DETAIL',
    R_COMPANY_VISITS_EXECUTED = 'R_COMPANY_VISITS_EXECUTED',
    R_COMPANY_VISITS_PLANNED = 'R_COMPANY_VISITS_PLANNED',
    R_COMPANY_VISITS_REQUEST_NEW = 'R_COMPANY_VISITS_REQUEST_NEW',
    R_INVOICES_PAID = 'R_INVOICES_PAID',
    R_INVOICES_OPEN = 'R_INVOICES_OPEN',
    R_PERFORMANCE_DASHBOARD = 'R_PERFORMANCE_DASHBOARD',

    // Flu vaccine Routes
    R_FLU_VACCINES_ORDERS = 'R_FLU_VACCINES_ORDERS',
    R_FLU_VACCINES_ORDERS_DETAIL = 'R_FLU_VACCINES_ORDERS_DETAIL',
    R_FLU_VACCINES_ORDERS_NEW = 'R_FLU_VACCINES_ORDERS_NEW',

    // Plan Bufferzone routes
    R_PLAN_BUFFERZONE_NEW = 'R_PLAN_BUFFERZONE_NEW',

    // Contact Routes
    R_CONTACT = 'R_CONTACT',
    R_CONTACT_DETAIL = 'R_CONTACT_DETAIL',
    R_FAQ_OVERVIEW = 'R_FAQ_OVERVIEW',
    R_FAQ_DETAIL = 'R_FAQ_DETAIL',

    // Document Center Routes
    R_DOCUMENT_CENTER = 'R_DOCUMENT_CENTER',
    R_MEDICAL_DOCUMENTS = 'R_MEDICAL_DOCUMENTS',
    R_RISK_MGMT_DOCUMENTS = 'R_RISK_MGMT_DOCUMENTS',
    R_WORK_POST_CARDS = 'R_WORK_POST_CARDS',
    R_WORK_POST_CARDS_DETAIL = 'R_WORK_POST_CARDS_DETAIL',
    R_WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL = 'R_WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL',
    // eslint-disable-next-line max-len
    R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL = 'R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
    // eslint-disable-next-line max-len
    R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL = 'R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
    R_WORK_POST_CARDS_DETAIL_COURSES_PLANNED_DETAIL = 'R_WORK_POST_CARDS_DETAIL_COURSES_PLANNED_DETAIL',
    R_WORK_POST_CARDS_DETAIL_COURSES_FOLLOWED_DETAIL = 'R_WORK_POST_CARDS_DETAIL_COURSES_FOLLOWED_DETAIL',
    R_WORK_POST_CARD_NEW = 'R_WORK_POST_CARD_NEW',
    R_MANAGE_IDENTIFICATION_DOCUMENT = 'R_MANAGE_IDENTIFICATION_DOCUMENT',
    R_ACCESSION_AGREEMENT = 'R_ACCESSION_AGREEMENT',
    R_COMPANY_AND_EMPLOYEE_REPORT = 'R_COMPANY_AND_EMPLOYEE_REPORT',
    R_ACTIVITY_REPORT = 'R_ACTIVITY_REPORT',
    // Courses routes
    R_COURSES_OVERVIEW = 'R_COURSES_OVERVIEW',
    R_COURSES_DETAIL_INTRO = 'R_COURSES_DETAIL_INTRO',
    R_COURSES_DETAIL_COURSE = 'R_COURSES_DETAIL_COURSE',
    R_COURSES_PLANNED = 'R_COURSES_PLANNED',
    R_COURSES_PLANNED_DETAIL = 'R_COURSES_PLANNED_DETAIL',
    R_COURSES_FOLLOWED = 'R_COURSES_FOLLOWED',
    R_COURSES_FOLLOWED_DETAIL = 'R_COURSES_FOLLOWED_DETAIL',
    R_COURSES_FOLLOWED_DETAIL_ATTENDANT = 'R_COURSES_FOLLOWED_DETAIL_ATTENDANT',
    // R_COURSES_CERTIFICATES = 'R_COURSES_CERTIFICATES',
    // R_COURSES_CERTIFICATES_DETAIL = 'R_COURSES_CERTIFICATES_DETAIL',
    R_COURSE_ENROLL_NEW = 'R_COURSE_ENROLL_NEW',
    R_TEMPLATES = 'R_TEMPLATES',
    R_TEMPLATES_CATEGORY_DETAIL = 'R_TEMPLATES_CATEGORY_DETAIL',

    // Planning Routes
    R_AGENDA = 'R_AGENDA',
    R_AGENDA_EVENT_DETAILS = 'R_AGENDA_EVENT_DETAILS',
    R_AGENDA_SEARCH = 'R_AGENDA_SEARCH',
    R_AGENDA_SEARCH_EVENT_DETAILS = 'R_AGENDA_SEARCH_EVENT_DETAILS',

    // Onboarding routes
    R_ONBOARDING_START = 'R_ONBOARDING_START',
    R_ONBOARDING_NEW = 'R_ONBOARDING_NEW',
    R_ONBOARDING_FINISH = 'R_ONBOARDING_FINISH',
    R_ONBOARDING_EMPLOYEE_DETAIL = 'R_ONBOARDING_EMPLOYEE_DETAIL',

    // Dismissed company routes (inactive company)
    R_DISMISSED_COMPANY_DASHBOARD = 'R_DISMISSED_COMPANY_DASHBOARD',

    // Other Routes
    R_INITIALIZATION_ERROR = 'R_INITIALIZATION_ERROR',
    R_NO_ACCES = 'R_NO_ACCES',
    R_NOT_FOUND = 'R_NOT_FOUND',
    R_UNEXPECTED_ERROR = 'R_UNEXPECTED_ERROR',
    // Using hardcoded string value here
    // https://github.com/faceyspacey/redux-first-router/blob/f736d27e56da3a476d3010183b33958d58ab7ea6/src/index.js#L21
    // It's not possible to use computed values inside an enum
    '@@redux-first-router/NOT_FOUND' = '@@redux-first-router/NOT_FOUND',
}

export default ROUTE_KEYS;
